/* eslint-disable max-len */

import dayjs, { Dayjs } from 'dayjs';
import { TranslateResult } from 'vue-i18n';
import { FormField } from '@/application/types';
import i18n from '@/plugins/vue-i18n';
import { useAppStore } from '@/application/common/app/store';
import { convertStringToNumber, isEditorTextFieldFilled, isTextFieldFilled } from '@/helpers/form-helpers';
import { Languages, PositiveNumberRuleMessageKeys, TranslatedText } from '@/types/global';
import { Decimal } from '@/models/decimal';

export function requiredRule(messageKey = 'helpers.formRules.required'): (value: any) => boolean|TranslateResult {
  return (value) => ((typeof value === 'boolean') || (typeof value !== 'string' && !!value) || (typeof value === 'string' && !!value.trim()) || value === 0) || i18n.t(messageKey);
}

export function requiredWysiwygRule(messageKey = 'helpers.formRules.required'): (value: any) => boolean|TranslateResult {
  return (value) => ((typeof value !== 'string' && !!value) || (typeof value === 'string' && !!value.trim() && value.trim() !== '<p></p>') || value === 0) || i18n.t(messageKey);
}

export function requiredCheckboxRule(messageKey = 'helpers.formRules.requiredCheckbox'): (value: any) => boolean|TranslateResult {
  return (value) => !!value || i18n.t(messageKey);
}

export function hexCodeRule(messageKey = 'helpers.formRules.requiredHexColorRule'): (value: any) => boolean|TranslateResult {

  return (value) => {
    // Matches a # followed by a 6 symbol hex-code. Allows upper and lower letters.
    const pattern = '^#([A-Fa-f0-9]{6})$';
    const regex = new RegExp(pattern);

    return !value || regex.test(value) || i18n.t(messageKey);
  };

}

export function dependentTextFieldFilledRule(
  formField: FormField<string>,
  dependentFormField: FormField<string>,
  messageKey: string
): () => boolean|TranslateResult {
  return () => {
    if (!isTextFieldFilled(formField)) { return true; }

    return isTextFieldFilled(dependentFormField) ? true : i18n.t(messageKey);
  };
}

export function dependentEditorTextFieldFilledRule(
  formField: FormField<string>,
  dependentFormField: FormField<string>,
  messageKey: string
): () => boolean|TranslateResult {
  return () => {
    if (!isEditorTextFieldFilled(formField)) { return true; }

    return isEditorTextFieldFilled(dependentFormField) ? true : i18n.t(messageKey);
  };
}

export function conditionalRequiredByBooleanRule(
  formFieldToRequire: FormField<boolean>,
  messageKey: string
): (value: any) => boolean|TranslateResult {
  return (value) => {
    if (!formFieldToRequire.value) {
      return true;
    }

    return ((typeof value !== 'string' && !!value) || (typeof value === 'string' && !!value.trim()) || value === 0) || i18n.t(messageKey);
  };
}

export function showRequiredAsterixConditionalByBoolean(
  formFieldToRequire: FormField<boolean>
): () => boolean {
  return () => formFieldToRequire.value;
}

export function conditionalRequiredByStringTypeRule<T = string>(
  formFieldToRequire: FormField<T>,
  requiredValue: T,
  messageKey: string
): (value: any) => boolean|TranslateResult {
  return (value) => {
    if (!formFieldToRequire.value || formFieldToRequire.value !== requiredValue) {
      return true;
    }

    return ((typeof value !== 'string' && !!value) || (typeof value === 'string' && !!value.trim()) || value === 0) || i18n.t(messageKey);
  };
}

export function showRequiredAsterixConditionalByStringType<T = string>(
  formFieldToRequire: FormField<T>,
  requiredValue: T
): () => boolean {
  return () => !!formFieldToRequire.value && formFieldToRequire.value === requiredValue;
}

// If one of the given form fields contains a value, then all have to contain a value
export function requiredTogetherRule(
  formFields: FormField<string>[],
  messageKey: string
): () => boolean|TranslateResult {
  return () => {
    const isEveryFieldEmpty = formFields.every((formField) => !formField.value);
    const isEveryFieldFilledWithAValue = formFields.every((formField) => !!formField.value);

    return isEveryFieldEmpty || isEveryFieldFilledWithAValue || i18n.t(messageKey);
  };
}

export function requiresValueInFieldIfTriggerAvailableRule(
  formFieldToValidate: FormField<any[]>,
  triggerValue: string,
  requiredValue: string,
  messageKey: string
): (value: any) => (boolean | TranslateResult) {
  return () => {
    if (!formFieldToValidate.value.includes(triggerValue)) {
      return true;
    }

    return formFieldToValidate.value.includes(requiredValue) || i18n.t(messageKey);
  };
}

export function emailRule(messageKey = 'helpers.formRules.email'): (value: any) => boolean|TranslateResult {
  return (value) => !value || /.+@.+\..+/.test(value) || i18n.t(messageKey);
}

export function phoneNumberCountryCodeRule(messageKey = 'helpers.formRules.phoneCountryCode'): (value: any) => boolean|TranslateResult {
  return (value) => !value || /^(([0-9]{1,6})|([0-9]{1,3}[-][0-9]{3,4})){1}$/.test(value) || i18n.t(messageKey);
}

export function phoneRule(messageKey = 'helpers.formRules.phone'): (value: any) => boolean|TranslateResult {
  return (value) => !value || /^([(]{1}[0-9]{1,6}[)])?([0-9 .-]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(value) || i18n.t(messageKey);
}

export function passwordRule(messageKey = 'helpers.formRules.password'): (value: any) => boolean|TranslateResult {
  return (value) => !value || /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value) || i18n.t(messageKey);
}

export function numberStringRule(messageKey = 'helpers.formRules.notANumber'): (value: any) => boolean|TranslateResult {
  return (value) => {
    if (!value) {
      return true;
    }

    const regex = useAppStore().selectedLanguage === Languages.en
      ? /^-?(\d)+([,\d])*(\.\d*)?$/
      : /^-?(\d)+([.\d])*(,\d*)?$/;

    if (regex.test(value)) {
      return true;
    }

    return i18n.t(messageKey);
  };
}

export function positiveNumberRule(
  decimalPlaces = 0,
  messageKeys: PositiveNumberRuleMessageKeys = {
    noDecimalPoints: 'helpers.formRules.positiveNumber.noDecimalPoints',
    notMoreThanDecimalPoints: 'helpers.formRules.positiveNumber.notMoreThanDecimalPoints',
    noNegative: 'helpers.formRules.positiveNumber.noNegative',
  }
): (value: any) => boolean|TranslateResult {
  const appStore = useAppStore();
  let messageKey = messageKeys.noDecimalPoints;
  let regex = /^\d+$/;

  if (decimalPlaces > 0) {
    const decimalSeparator = useAppStore().selectedLanguage === Languages.en ? '.' : ',';
    regex = new RegExp(`^\\d*\\${decimalSeparator}?(\\d{1,${decimalPlaces}})?$`);
    // eslint-disable-next-line no-param-reassign
    messageKey = messageKeys.notMoreThanDecimalPoints;
  }

  return (value) => {
    if (!value) {
      return true;
    }

    // check if value is negative
    const valueAsNumber = (typeof value === 'string')
      ? convertStringToNumber(value)
      : value;

    if (valueAsNumber < 0) {
      return i18n.t(messageKeys.noNegative);
    }

    // remove thousand separators and test regex
    const thousandSeparator = appStore.selectedLanguage === Languages.en ? ',' : '.';
    const valueToTest = typeof value === 'string' ? value.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '') : value;

    if (regex.test(valueToTest)) {
      return true;
    }

    return i18n.t(messageKey, { decimalPlaces });
  };
}

export function minNumberRule(min: number, messageKey = 'helpers.formRules.minNumber'): (value: any) => boolean|TranslateResult {
  return (value) => {
    if (!value && value !== 0) {
      return true;
    }

    const valueAsNumber = (typeof value === 'string')
      ? convertStringToNumber(value)
      : value;

    if (valueAsNumber >= min) {
      return true;
    }

    return i18n.t(messageKey, { min });
  };
}

export function maxNumberRule(max: number, messageKey = 'helpers.formRules.maxNumber'): (value: any) => boolean|TranslateResult {
  return (value) => {
    if (!value && value !== 0) {
      return true;
    }

    const valueAsNumber = (typeof value === 'string')
      ? convertStringToNumber(value)
      : value;

    if (valueAsNumber <= max) {
      return true;
    }

    return i18n.t(messageKey, { max });
  };
}

export function maxLengthRule(max: number, messageKey = 'helpers.formRules.maxLength'): (value: any) => boolean|TranslateResult {
  return (value) => (!value || value.length <= max) || i18n.t(messageKey, { max });
}

export function notNullNumberRule(messageKey = 'helpers.formRules.notNullNumber'): (value: any) => boolean|TranslateResult {
  return (value) => ((!value && value !== 0) || parseInt(value, 10) !== 0) || i18n.t(messageKey);
}

export function notEmptyArrayRule(messageKey = 'helpers.formRules.notEmptyArray'): (value: any) => boolean|TranslateResult {
  return (value) => (!value || (Array.isArray(value) && value.length > 0)) || i18n.t(messageKey);
}

export function formFieldContainsElementInArrayRule(formField: FormField<any[]>, requiredElement: any, messageKey: string): () => boolean|TranslateResult {
  return () => (Array.isArray(formField.value) && formField.value.includes(requiredElement) || i18n.t(messageKey));
}

export function formFieldContainsAtLeastOneElementInArrayRule(formField: FormField<any[]>, messageKey: string): () => boolean|TranslateResult {
  return () => (Array.isArray(formField.value) && formField.value.length > 0 || i18n.t(messageKey));
}

export function translatedTextRule(
  messageKey = 'helpers.formRules.notValidTranslatedText'
): (value: TranslatedText | null) => boolean|TranslateResult {
  return (value) => (value === null || value[Languages.en] !== undefined || i18n.t(messageKey));
}

export function translatedTextMaxLengthRule(
  max: number,
  messageKey = 'helpers.formRules.translatedTextMaxLength'
): (value: any) => boolean|TranslateResult {
  return (value) => {
    if (value === null) {
      return true;
    }

    for (const [language, text] of Object.entries(value as TranslatedText)) {
      if (text.length > max) {
        return i18n.t(messageKey, { max, language: i18n.t(`helpers.data.languages.${language}`) });
      }
    }

    return true;
  };
}

export function requiredTranslatedWysiwygRule(messageKey = 'helpers.formRules.required'): (value: TranslatedText | null) => boolean|TranslateResult {
  return (value) => (!!value
        && !!value[Languages.en]
        && (value![Languages.en]!.trim() !== '')
        && (value![Languages.en]!.trim() !== '<p></p>')
  )
      || i18n.t(messageKey);
}

export function notEmptyFormFieldArrayRule(
  formField: FormField<any[]>,
  messageKey = 'helpers.formRules.notEmptyArray'
): (value: any) => boolean|TranslateResult {
  return () => (!formField.value || (Array.isArray(formField.value) && formField.value.length > 0)) || i18n.t(messageKey);
}

export function activeOptionsBetweenRule(min: number, max: number, messageKey = 'helpers.formRules.activeOptionsBetween'): (value: any) => boolean|TranslateResult {
  return (value) => (!value || (Array.isArray(value) && value.length >= min && value.length <= max)) || i18n.t(messageKey);
}

export function passwordsMustBeIdenticalRule(
  passwordField: FormField<string>,
  passwordRepeatField: FormField<string>,
  messageKey: string = 'helpers.formRules.passwordsIdentical'
): (value: any) => boolean|TranslateResult {
  return () => (!passwordField.value || passwordField.value === passwordRepeatField.value) || i18n.t(messageKey);
}

export function valueMustBeGreater(
  formFieldA: FormField<Decimal|null>,
  formFieldB: FormField<Decimal|null>,
  messageKey: string = 'helpers.formRules.valueMustBeGreater'
): () => boolean|TranslateResult {
  return () => (!formFieldA.value || !formFieldB.value) || (+formFieldA.value!.toNumber() < +formFieldB.value!.toNumber()) || i18n.t(messageKey);
}

export function csvCompliantRule(messageKey = 'helpers.formRules.csvCompliant'): (value: any) => boolean|TranslateResult {
  return (value) => (!value || !value.match('[,;"\']')) || i18n.t(messageKey);
}

export function minimumFieldsToBeCheckedRule(
  fields: FormField<boolean>[],
  minAmountOfFields: number,
  messageKey: string
): (value: any) => boolean|TranslateResult {
  return () => (fields.filter((field) => field.value).length >= minAmountOfFields) || i18n.t(messageKey);
}

export function oneOfFieldsToBeCheckedRule(
  fields: FormField<boolean>[],
  messageKey: string
): (value: any) => boolean|TranslateResult {
  return () => (fields.filter((field) => field.value).length >= 1) || i18n.t(messageKey);
}

export function distinctSelectValuesRule(
  fields: FormField<any>[],
  messageKey: string
): (value: any) => boolean|TranslateResult {
  return () => {
    const values = fields.map((field) => field.value).filter((value) => value !== null);
    return (new Set(values).size === values.length) || i18n.t(messageKey);
  };
}

export function maxDateRule(maxDate: Dayjs, messageKey: string): (value: any) => boolean|TranslateResult {
  return (value: any) => {
    if (!value) {
      return true;
    }

    const selectedDate = dayjs(value);

    return selectedDate.isBefore(maxDate, 'day') || i18n.t(messageKey);
  };
}

export function urlRule(
  messageKey = 'helpers.formRules.mustBeUrl'
): (value: any) => boolean|TranslateResult {
  return (value) => {

    const pattern = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';
    const regex = new RegExp(pattern);

    return !value || regex.test(value) || i18n.t(messageKey);
  };
}
