var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-room",attrs:{"data-cy":"data-room"}},[_c('v-data-table',{staticClass:"clickable-rows fixed-layout mb-0",attrs:{"disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":"","loading-text":_vm.translate('table.loadingText'),"no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.documentsTabStore.dueDiligenceDataRoomUNodes,"item-key":"id","loading":_vm.documentsTabStore.isGetUNodesForDueDiligenceDataRoomProcessing,"mobile-breakpoint":960},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.isDirectory(item))?_c('div',{staticClass:"d-flex align-center clickable-row-highlight"},[_c('font-awesome-icon',{staticClass:"mr-3",attrs:{"icon":['fas', 'folder-open']}}),_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])],1):_c('div',{staticClass:"d-flex align-center clickable-row-highlight"},[_c('font-awesome-icon',{staticClass:"mr-3",attrs:{"icon":['fas', 'file']}}),_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatBytes")(item.fileSize))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isDirectory(item))?[(!_vm.isDownloading(item))?_c('a-icon-button',{attrs:{"small":"","icon":['far', 'download'],"tooltip":_vm.translate('table.downloadDirectoryButtonTooltip')},on:{"click":function($event){$event.stopPropagation();return _vm.downloadDirectoryButtonClicked(item)}}}):_vm._e(),(_vm.isDownloading(item))?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"primary","size":16,"width":3}}):_vm._e()]:[(!_vm.isDownloading(item))?_c('a-icon-button',{attrs:{"small":"","icon":['far', 'download'],"tooltip":_vm.translate('table.downloadFileButtonTooltip')},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFileButtonClicked(item)}}}):_vm._e(),(_vm.isDownloading(item))?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"primary","size":16,"width":3}}):_vm._e()]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }