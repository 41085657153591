import { render, staticRenderFns } from "./onboarded-product-info.vue?vue&type=template&id=515e3194&scoped=true&"
import script from "./onboarded-product-info.vue?vue&type=script&lang=ts&"
export * from "./onboarded-product-info.vue?vue&type=script&lang=ts&"
import style0 from "./onboarded-product-info.vue?vue&type=style&index=0&id=515e3194&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515e3194",
  null
  
)

export default component.exports