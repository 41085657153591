import { render, staticRenderFns } from "./personal-information.vue?vue&type=template&id=b092a900&scoped=true&"
import script from "./personal-information.vue?vue&type=script&lang=ts&"
export * from "./personal-information.vue?vue&type=script&lang=ts&"
import style0 from "./personal-information.vue?vue&type=style&index=0&id=b092a900&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b092a900",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VForm,VRow,VTextField})
