var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAnyActionPermissionAvailable)?_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"6","bottom":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a-icon-button',_vm._g({attrs:{"small":"","color":"grey","icon":['fas', 'ellipsis-h']}},on))]}}],null,false,4227433000)},[_c('v-list',{attrs:{"dense":"","data-cy":"user-actions-menu"}},[(_vm.isGeneralPartnerUser)?[(_vm.isUpdateGeneralPartnerUserMasterDataVisible)?_c('update-general-partner-user-master-data-dialog',{attrs:{"user":_vm.user}}):_vm._e(),(_vm.isUpdateGeneralPartnerUserPermissionGroupVisible)?_c('update-general-partner-permission-group-for-user-dialog',{attrs:{"user":_vm.user}}):_vm._e(),(_vm.isUpdateGeneralPartnerUserProductAccessVisible)?_c('update-general-partner-user-product-access-dialog',{attrs:{"user":_vm.user}}):_vm._e()]:_vm._e(),(_vm.isInvestorUser)?[(_vm.isImpersonateInvestorUserVisible)?_c('confirmation-dialog',{attrs:{"confirm-button-color":"warning","title":_vm.translate('impersonateInvestorUserDialog.dialogTitle'),"confirm-button-text":_vm.translate('impersonateInvestorUserDialog.dialogConfirmButton'),"disabled":_vm.isImpersonateInvestorUserDisabled},on:{"confirmed":function($event){return _vm.impersonateInvestorUserConfirmed()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('impersonateInvestorUserDialog.dialogContent', {
          firstName: _vm.user.name.firstName,
          lastName: _vm.user.name.lastName,
        }))}}),_c('v-alert',{attrs:{"color":"warning","icon":false}},[_vm._v(" "+_vm._s(_vm.translate('impersonateInvestorUserDialog.warning'))+" ")])]},proxy:true}],null,false,3892581024)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.isImpersonateInvestorUserDisabled}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'user-secret']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('actions.impersonateInvestorUser'))+" ")])],1)],1):_vm._e(),(_vm.isUpdateInvestorUserMasterDataAndManagementAccessVisible)?_c('update-investor-user-master-data-and-management-access-dialog',{attrs:{"user":_vm.user}}):_vm._e()]:_vm._e(),(_vm.isLockUserVisible)?_c('confirmation-dialog',{attrs:{"confirm-button-color":"primary","title":_vm.translate('lockUserDialog.dialogTitle'),"confirm-button-text":_vm.translate('lockUserDialog.dialogConfirmButton'),"disabled":_vm.isLockOrUnlockUserDisabled},on:{"confirmed":function($event){return _vm.lockUserConfirmed()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('lockUserDialog.dialogContent', {
          firstName: _vm.user.name.firstName,
          lastName: _vm.user.name.lastName,
        }))}}),_c('v-alert',{attrs:{"color":"info","icon":false}},[_vm._v(" "+_vm._s(_vm.translate('lockUserDialog.warning'))+" ")])]},proxy:true}],null,false,2032286404)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.isLockOrUnlockUserDisabled}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'lock']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('actions.lockUser'))+" ")])],1)],1):_vm._e(),(_vm.isUnlockUserVisible)?_c('confirmation-dialog',{attrs:{"confirm-button-color":"primary","title":_vm.translate('unlockUserDialog.dialogTitle'),"confirm-button-text":_vm.translate('unlockUserDialog.dialogConfirmButton'),"disabled":_vm.isLockOrUnlockUserDisabled},on:{"confirmed":function($event){return _vm.unlockUserConfirmed()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('unlockUserDialog.dialogContent', {
          firstName: _vm.user.name.firstName,
          lastName: _vm.user.name.lastName,
        }))}})]},proxy:true}],null,false,231448666)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.isLockOrUnlockUserDisabled}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'unlock']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('actions.unlockUser'))+" ")])],1)],1):_vm._e(),(_vm.isDeleteUserVisible)?_c('confirmation-dialog',{attrs:{"confirm-button-color":"error","title":_vm.translate('deleteUserDialog.dialogTitle'),"confirm-button-text":_vm.translate('deleteUserDialog.dialogConfirmButton'),"disabled":_vm.isDeleteUserDisabled},on:{"confirmed":function($event){return _vm.deleteUserConfirmed()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('deleteUserDialog.dialogContent', {
          firstName: _vm.user.name.firstName,
          lastName: _vm.user.name.lastName,
        }))}}),_c('v-alert',{attrs:{"color":"error","icon":false}},[_vm._v(" "+_vm._s(_vm.translate('deleteUserDialog.warning'))+" ")])]},proxy:true}],null,false,3079069266)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.isDeleteUserDisabled}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"red--text",attrs:{"icon":['far', 'times']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('actions.deleteUser'))+" ")])],1)],1):_vm._e()],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }