import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartner } from '@/helpers/default-parameter-helper';
import { CreateInvestorUserAccountCommand, GetInvestorUserInviteByTokenQuery, InvestorUserInvite } from './types';

export function getInvestorUserInviteByToken(query: GetInvestorUserInviteByTokenQuery): Promise<InvestorUserInvite> {
  const url = generateApiUrl('/api/unauthorized/investor/get-user-invite-by-token-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(query) });
}

export function createInvestorUserAccount(command: CreateInvestorUserAccountCommand): Promise<void> {
  const url = generateApiUrl('/api/unauthorized/investor/create-user-account-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(command) });
}
