import { RouteConfig } from 'vue-router';
import { AccountTypes, GeneralPartnerPermissions } from '@/types/global';
import UsersTabs from './users-tabs/components/users-tabs.vue';
import AllUsersTab from './users-tabs/components/all-users-tab.vue';
import GeneralPartnerUsersTab from './users-tabs/components/general-partner-users-tab.vue';
import InvestorUsersTab from './users-tabs/components/investor-users-tab.vue';
import PendingInvitationsTab from './users-tabs/components/pending-invitations-tab.vue';
import PermissionGroupsTab from './users-tabs/components/permission-groups-tab.vue';
import UserDetailsTabs from './user-details/components/user-details-tabs.vue';
import UserContactDetailsTab from './user-details/components/user-contact-details-tab.vue';
import UserActivityTab from './user-details/components/user-activity-tab.vue';

export const generalPartnerUsersRoutes: RouteConfig[] = [
  {
    name: 'general-partner-users',
    path: '/general-partner/users',
    redirect: { name: 'general-partner-users-all-users' },
  },
  {
    path: '/general-partner/users',
    component: UsersTabs,
    children: [
      {
        path: '',
        redirect: 'all-users',
      },
      {
        name: 'general-partner-users-all-users',
        path: 'all-users',
        component: AllUsersTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_USER,
          styleClass: 'has-sticky-tab-header',
        },
      },
      {
        name: 'general-partner-users-investor-users',
        path: 'investor-users/:watcherTrigger?',
        component: InvestorUsersTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_USER,
          styleClass: 'has-sticky-tab-header',
        },
      },
      {
        name: 'general-partner-users-general-partner-users',
        path: 'general-partner-users/:watcherTrigger?',
        component: GeneralPartnerUsersTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_USER,
          styleClass: 'has-sticky-tab-header',
        },
      },
      {
        name: 'general-partner-users-pending-invitations',
        path: 'pending-invitations',
        component: PendingInvitationsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_USER,
          styleClass: 'has-sticky-tab-header',
        },
      },
      {
        name: 'general-partner-users-permission-groups',
        path: 'permission-groups',
        component: PermissionGroupsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.WRITE_GENERAL_PARTNER_PERMISSION_GROUPS,
          styleClass: 'has-sticky-tab-header',
        },
      },
    ],
  },
  {
    name: 'general-partner-users-user-details',
    path: '/general-partner/users/user-details/:userId',
    redirect: { name: 'general-partner-users-user-details-user-activity' },
  },
  {
    path: '/general-partner/users/user-details/:userId',
    component: UserDetailsTabs,
    children: [
      {
        path: '',
        redirect: 'user-activity-tab',
      },
      {
        name: 'general-partner-users-user-details-user-activity',
        path: 'user-activity-tab',
        component: UserActivityTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_USER,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
      {
        name: 'general-partner-users-user-details-user-contact-details',
        path: 'userContactDetailsTab',
        component: UserContactDetailsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_USER,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  },
];
