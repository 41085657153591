import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { AccountTypes, Languages } from '@/types/global';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useImpersonationHintStore } from '@/application/whitelabel/app/components/impersonation-hint/store';
import { useAppStore } from '@/application/common/app/store';
import { CreateGeneralPartnerPermissionGroupCommand, DeleteGeneralPartnerPermissionGroupCommand, DeleteUserCommand, GeneralPartnerPermissionGroup, GetInvestorUserAuthenticationQuery, InviteGeneralPartnerUserCommand, InviteInvestorUserCommand, LockUserCommand, ResendUserInviteCommand, UnlockUserCommand, UpdateGeneralPartnerPermissionGroupCommand, UpdateGeneralPartnerPermissionGroupForUserCommand, UpdateGeneralPartnerUserMasterDataCommand, UpdateGeneralPartnerUserProductAccessCommand, UpdateInvestorUserMasterDataAndManagementAccessCommand, User, UserInvite, WithdrawUserInviteCommand } from './types';
import { createGeneralPartnerPermissionGroup, deleteGeneralPartnerPermissionGroup, deleteUser, getGeneralPartnerPermissionGroups, getInvestorUserAuthentication, getUserInvites, getUsers, inviteGeneralPartnerUser, inviteInvestorUser, lockUser, resendUserInvite, unlockUser, updateGeneralPartnerPermissionGroup, updateGeneralPartnerPermissionGroupForUser, updateGeneralPartnerUserMasterData, updateGeneralPartnerUserProductAccess, updateInvestorUserMasterDataAndManagementAccess, withdrawUserInvite } from './service';

interface GeneralPartnerUsersState {
  users: User[];
  userInvites: UserInvite[];
  generalPartnerPermissionGroups: GeneralPartnerPermissionGroup[];
  searchTerm: string | null;

  getUsersStatus: ActionStatus
  inviteGeneralPartnerUserStatus: ActionStatus;
  inviteInvestorUserStatus: ActionStatus;
  updateInvestorUserMasterDataAndManagementAccessStatus: ActionStatus;
  updateGeneralPartnerUserMasterDataStatus: ActionStatus;
  updateGeneralPartnerPermissionGroupForUserStatus: ActionStatus;
  getUserInvitesStatus: ActionStatus;
  resendUserInviteStatus: ActionStatus;
  withdrawUserInviteStatus: ActionStatus;
  deleteUserStatus: ActionStatus;
  lockUserStatus: ActionStatus;
  unlockUserStatus: ActionStatus;
  impersonateInvestorUserStatus: ActionStatus;
  getGeneralPartnerPermissionGroupsStatus: ActionStatus;
  createGeneralPartnerPermissionGroupStatus: ActionStatus;
  updateGeneralPartnerPermissionGroupStatus: ActionStatus;
  deleteGeneralPartnerPermissionGroupStatus: ActionStatus;
  updateGeneralPartnerUserProductAccessStatus: ActionStatus;
}

function initialState(): GeneralPartnerUsersState {
  return {
    users: [],
    userInvites: [],
    generalPartnerPermissionGroups: [],
    searchTerm: null,

    getUsersStatus: ActionStatus.None,
    inviteGeneralPartnerUserStatus: ActionStatus.None,
    inviteInvestorUserStatus: ActionStatus.None,
    updateInvestorUserMasterDataAndManagementAccessStatus: ActionStatus.None,
    updateGeneralPartnerUserMasterDataStatus: ActionStatus.None,
    updateGeneralPartnerPermissionGroupForUserStatus: ActionStatus.None,
    getUserInvitesStatus: ActionStatus.None,
    resendUserInviteStatus: ActionStatus.None,
    withdrawUserInviteStatus: ActionStatus.None,
    deleteUserStatus: ActionStatus.None,
    lockUserStatus: ActionStatus.None,
    unlockUserStatus: ActionStatus.None,
    impersonateInvestorUserStatus: ActionStatus.None,
    getGeneralPartnerPermissionGroupsStatus: ActionStatus.None,
    createGeneralPartnerPermissionGroupStatus: ActionStatus.None,
    updateGeneralPartnerPermissionGroupStatus: ActionStatus.None,
    deleteGeneralPartnerPermissionGroupStatus: ActionStatus.None,
    updateGeneralPartnerUserProductAccessStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerUsersStore = defineStore('generalPartnerUsers', {
  state: (): GeneralPartnerUsersState => initialState(),
  getters: {
    isGetUsersProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.getUsersStatus === ActionStatus.InProgress,
    isInviteGeneralPartnerUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.inviteGeneralPartnerUserStatus === ActionStatus.InProgress,
    isInviteInvestorUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.inviteInvestorUserStatus === ActionStatus.InProgress,
    isUpdateInvestorUserMasterDataAndManagementAccessProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.updateInvestorUserMasterDataAndManagementAccessStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerUserMasterDataProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.updateGeneralPartnerUserMasterDataStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerPermissionGroupForUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.updateGeneralPartnerPermissionGroupForUserStatus === ActionStatus.InProgress,
    isGetUserInvitesProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.getUserInvitesStatus === ActionStatus.InProgress,
    isResendUserInviteProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.resendUserInviteStatus === ActionStatus.InProgress,
    isWithdrawUserInviteProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.withdrawUserInviteStatus === ActionStatus.InProgress,
    isDeleteUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.deleteUserStatus === ActionStatus.InProgress,
    isLockUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.lockUserStatus === ActionStatus.InProgress,
    isUnlockUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.unlockUserStatus === ActionStatus.InProgress,
    isImpersonateInvestorUserProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.impersonateInvestorUserStatus === ActionStatus.InProgress,
    isGetGeneralPartnerPermissionGroupsProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.getGeneralPartnerPermissionGroupsStatus === ActionStatus.InProgress,
    isCreateGeneralPartnerPermissionGroupProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.createGeneralPartnerPermissionGroupStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerPermissionGroupProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.updateGeneralPartnerPermissionGroupStatus === ActionStatus.InProgress,
    isDeleteGeneralPartnerPermissionGroupProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.deleteGeneralPartnerPermissionGroupStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerUserProductAccessProcessing: (state: GeneralPartnerUsersState): boolean =>
      state.updateGeneralPartnerUserProductAccessStatus === ActionStatus.InProgress,
    permissionGroups(): GeneralPartnerPermissionGroup[] {
      const searchTerm = this.searchTerm ?? '';

      return this.generalPartnerPermissionGroups.filter(
        (group) => group.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    pendingInvitations(): UserInvite[] {
      const searchTerm = this.searchTerm ?? '';

      return this.userInvites.filter(
        (invite) => `${invite.name.firstName} ${invite.name.lastName} ${invite.emailAddress} ${invite.investor?.name || '-'}`
          .toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    investorUsers(): User[] {
      const searchTerm = this.searchTerm ?? '';

      return this.users.filter(
        (user) => user.accountType === AccountTypes.INVESTOR
          && `${user.name.firstName} ${user.name.lastName} ${user.emailAddress} ${user.investor!.name}`
            .toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    generalPartnerUsers(): User[] {
      const searchTerm = this.searchTerm ?? '';

      return this.users.filter(
        (user) => user.accountType === AccountTypes.GENERAL_PARTNER
          && `${user.name.firstName} ${user.name.lastName} ${user.emailAddress}`
            .toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    allUsers(): User[] {
      const searchTerm = this.searchTerm ?? '';

      return this.users.filter(
        (user) => `${user.name.firstName} ${user.name.lastName} ${user.emailAddress} ${user.investor?.name || '-'}`
          .toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
  },
  actions: {

    // -- State management

    updateSearchTerm(term: string): Promise<void> {
      this.searchTerm = term;

      return Promise.resolve();
    },

    // -- Queries

    getUsers(): Promise<void> {
      const { getUsersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersStatus,
        () => getUsers()
          .then((users) => {
            this.users = users;
          })
      );
    },

    getUserInvites(): Promise<void> {
      const { getUserInvitesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserInvitesStatus,
        () => getUserInvites()
          .then((userInvites) => {
            this.userInvites = userInvites;
          })
      );
    },

    getGeneralPartnerPermissionGroups(): Promise<void> {
      const { getGeneralPartnerPermissionGroupsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerPermissionGroupsStatus,
        () => getGeneralPartnerPermissionGroups()
          .then((generalPartnerPermissionGroups) => {
            this.generalPartnerPermissionGroups = generalPartnerPermissionGroups;
          })
      );
    },

    impersonateInvestorUser(targetUserId: string): Promise<void> {
      const query: GetInvestorUserAuthenticationQuery = {
        targetUserId,
      };

      const { impersonateInvestorUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        impersonateInvestorUserStatus,
        () => getInvestorUserAuthentication(query)
          .then(async (authentication) => {
            await useAuthenticationStore().impersonateUser(authentication);
            await useImpersonationHintStore().activateHint();
            await useAppStore().updateAccountType(AccountTypes.INVESTOR);
            useAppStore().updateLanguage(authentication.user.language ?? Languages.en);
          })
      );
    },

    // -- Commands

    inviteGeneralPartnerUser(command: InviteGeneralPartnerUserCommand): Promise<void> {
      const { inviteGeneralPartnerUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteGeneralPartnerUserStatus,
        () => inviteGeneralPartnerUser(command)
          .then(() => this.getUserInvites())
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    inviteInvestorUser(command: InviteInvestorUserCommand): Promise<void> {
      const { inviteInvestorUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteInvestorUserStatus,
        () => inviteInvestorUser(command)
          .then(() => this.getUserInvites())
      );
    },

    updateInvestorUserMasterDataAndManagementAccess(command: UpdateInvestorUserMasterDataAndManagementAccessCommand): Promise<void> {
      const { updateInvestorUserMasterDataAndManagementAccessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestorUserMasterDataAndManagementAccessStatus,
        () => updateInvestorUserMasterDataAndManagementAccess(command)
          .then(() => this.getUsers())
      );
    },

    updateGeneralPartnerUserMasterData(command: UpdateGeneralPartnerUserMasterDataCommand): Promise<void> {
      const { updateGeneralPartnerUserMasterDataStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerUserMasterDataStatus,
        () => updateGeneralPartnerUserMasterData(command)
          .then(() => this.getUsers())
      );
    },

    resendUserInvite(command: ResendUserInviteCommand): Promise<void> {
      const { resendUserInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        resendUserInviteStatus,
        () => resendUserInvite(command)
      );
    },

    withdrawUserInvite(command: WithdrawUserInviteCommand): Promise<void> {
      const { resendUserInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        resendUserInviteStatus,
        () => withdrawUserInvite(command)
          .then(() => this.getUserInvites())
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    updateGeneralPartnerPermissionGroupForUser(command: UpdateGeneralPartnerPermissionGroupForUserCommand): Promise<void> {
      const { updateGeneralPartnerPermissionGroupForUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerPermissionGroupForUserStatus,
        () => updateGeneralPartnerPermissionGroupForUser(command)
          .then(() => this.getUsers())
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    deleteUser(command: DeleteUserCommand): Promise<void> {
      const { deleteUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteUserStatus,
        () => deleteUser(command)
          .then(() => this.getUsers())
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    lockUser(command: LockUserCommand): Promise<void> {
      const { lockUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        lockUserStatus,
        () => lockUser(command)
          .then(() => this.getUsers())
      );
    },

    unlockUser(command: UnlockUserCommand): Promise<void> {
      const { unlockUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unlockUserStatus,
        () => unlockUser(command)
          .then(() => this.getUsers())
      );
    },

    createGeneralPartnerPermissionGroup(command: CreateGeneralPartnerPermissionGroupCommand): Promise<void> {
      const { createGeneralPartnerPermissionGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createGeneralPartnerPermissionGroupStatus,
        () => createGeneralPartnerPermissionGroup(command)
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    updateGeneralPartnerPermissionGroup(command: UpdateGeneralPartnerPermissionGroupCommand): Promise<void> {
      const { updateGeneralPartnerPermissionGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerPermissionGroupStatus,
        () => updateGeneralPartnerPermissionGroup(command)
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    deleteGeneralPartnerPermissionGroup(command: DeleteGeneralPartnerPermissionGroupCommand): Promise<void> {
      const { deleteGeneralPartnerPermissionGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteGeneralPartnerPermissionGroupStatus,
        () => deleteGeneralPartnerPermissionGroup(command)
          .then(() => this.getGeneralPartnerPermissionGroups())
      );
    },

    updateGeneralPartnerUserProductAccess(command: UpdateGeneralPartnerUserProductAccessCommand): Promise<void> {
      const { updateGeneralPartnerUserProductAccessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerUserProductAccessStatus,
        () => updateGeneralPartnerUserProductAccess(command)
          .then(() => this.getUsers())
      );
    },

  },
});
