import { defineStore, storeToRefs } from 'pinia';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { formatCurrencyWithoutCentDigits } from '@/filters/currencies';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { CurrencySymbols, KPI, KPITypes, SubscriptionProcessStatuses } from '@/types/global';
import { confirmOnboardedProductSubscription, confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcess, continueOnboardedProductSubscriptionProcessAsNaturalPerson, copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachments, createOnboardedProductSubscriptionDocument, deleteOnboardedProductKYCDocumentAttachment, deleteOnboardedProductSubscriptionDocument, getInvestorKYCDocuments, getOnboardedProductForCurrentSubscriptionProcess, getOnboardedProductKYCDocumentAttachmentFileForDownload, getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcess, getOnboardedProductSubscriptionDocumentFileForDownload, getOnboardedProductSubscriptionDocumentsForSubscriptionProcess, getOnboardedProductSubscriptionDocumentTemplateFileForDownload, getOnboardedProductSubscriptionAgreementTemplatesToPrefill, getOnboardedProductSubscriptionProcessForCurrentProduct, selectInvestmentProfileForOnboardedProductSubscriptionProcess, specifyOnboardedProductSubscriptionInvestmentAmount, startOnboardedProductSubscriptionProcess, getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownload, getOnboardedProductControllingPersonTemplatesToPrefillForProduct, getPrefilledOnboardedProductControllingPersonTemplateForDownload } from './service';
import { ConfirmOnboardedProductSubscriptionCommand, ConfirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessCommand, ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand, CopyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsCommand, CreateOnboardedProductSubscriptionDocumentCommand, DeleteOnboardedProductKYCDocumentAttachmentCommand, DeleteOnboardedProductSubscriptionDocumentCommand, GetOnboardedProductControllingPersonTemplatesToPrefillForProductQuery, GetOnboardedProductForCurrentSubscriptionProcessQuery, GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery, GetOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessQuery, GetOnboardedProductSubscriptionAgreementTemplatesToPrefillQuery, GetOnboardedProductSubscriptionDocumentFileForDownloadQuery, GetOnboardedProductSubscriptionDocumentsForSubscriptionProcessQuery, GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery, GetOnboardedProductSubscriptionProcessForCurrentProductQuery, GetPrefilledOnboardedProductControllingPersonTemplateForDownloadQuery, GetPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadQuery, KYCDocument, OnboardedProductControllingPersonTemplateToPrefill, OnboardedProductForCurrentSubscriptionProcess, OnboardedProductKYCDocumentAttachment, OnboardedProductSubscriptionAgreementTemplateToPrefill, OnboardedProductSubscriptionDocument, OnboardedProductSubscriptionProcess, SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand, SpecifyOnboardedProductSubscriptionInvestmentAmountCommand, StartOnboardedProductSubscriptionProcessCommand, SubscriptionProcessStep } from './types';

function calculateNextSubscriptionProcessStep(
  onboardedProductSubscriptionProcess: OnboardedProductSubscriptionProcess | null
): SubscriptionProcessStep {
  if (!onboardedProductSubscriptionProcess) {
    return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
  }

  if (onboardedProductSubscriptionProcess.status === SubscriptionProcessStatuses.IN_REVIEW) {
    return SubscriptionProcessStep.REVIEW;
  }

  if (onboardedProductSubscriptionProcess.isInvestmentProfileReviewed || onboardedProductSubscriptionProcess.isNaturalPersonSelected) {
    return SubscriptionProcessStep.REVIEW;
  }

  if (onboardedProductSubscriptionProcess.selectedInvestmentProfileId) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW;
  }

  if (onboardedProductSubscriptionProcess.investmentAmount) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION;
  }

  return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
}

interface InvestorOnboardedProductSubscriptionProcessDialogState {
  currentProductId: string | null;
  currentSubscriptionProcessId: string | null;
  currentSubscriptionProcessStep: SubscriptionProcessStep | null;
  currentSubscriptionProcess: OnboardedProductSubscriptionProcess | null;
  onboardedProductSubscriptionDocuments: OnboardedProductSubscriptionDocument[];
  onboardedProductSubscriptionAgreementTemplateToPrefill: OnboardedProductSubscriptionAgreementTemplateToPrefill[];
  onboardedProductControllingPersonTemplatesToPrefill:OnboardedProductControllingPersonTemplateToPrefill[];
  currentProduct: OnboardedProductForCurrentSubscriptionProcess | null;
  investorKYCDocuments: KYCDocument[];
  onboardedProductKYCDocumentAttachments: OnboardedProductKYCDocumentAttachment[];

  getOnboardedProductForCurrentSubscriptionProcessStatus: ActionStatus;
  getSubscriptionProcessForCurrentProductStatus: ActionStatus;
  startOnboardedProductSubscriptionProcessStatus: ActionStatus;
  specifyOnboardedProductSubscriptionInvestmentAmountStatus: ActionStatus;
  selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus: ActionStatus;
  confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus: ActionStatus;
  agreeToSubscriptionDataProcessingStatus: ActionStatus;
  confirmOnboardedProductSubscriptionStatus: ActionStatus;
  getOnboardedProductSubscriptionDocumentFileForDownloadStatus: ActionStatus;
  getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus;
  createOnboardedProductSubscriptionDocumentsStatus: ActionStatus;
  getOnboardedProductSubscriptionDocumentsForSubscriptionProcessStatus: ActionStatus;
  getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessStatus: ActionStatus;
  getInvestorKYCDocumentsStatus: ActionStatus;
  copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsStatus: ActionStatus;
  getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus: ActionStatus;
  deleteOnboardedProductKYCDocumentAttachmentStatus: ActionStatus;
  deleteOnboardedProductSubscriptionDocumentStatus: ActionStatus;
  continueSubscriptionProcessAsNaturalPersonStatus: ActionStatus;
  getOnboardedProductSubscriptionAgreementTemplatesToPrefillStatus: ActionStatus;
  getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadStatus: ActionStatus;
  getOnboardedProductControllingPersonTemplatesToPrefillForProductStatus: ActionStatus;
  getPrefilledOnboardedProductControllingPersonTemplateForDownloadStatus: ActionStatus;
}

function initialState(): InvestorOnboardedProductSubscriptionProcessDialogState {
  return {
    currentProductId: null,
    currentSubscriptionProcessId: null,
    currentSubscriptionProcessStep: SubscriptionProcessStep.AMOUNT_SPECIFICATION,
    currentSubscriptionProcess: null,
    onboardedProductSubscriptionDocuments: [],
    onboardedProductSubscriptionAgreementTemplateToPrefill: [],
    onboardedProductControllingPersonTemplatesToPrefill: [],
    currentProduct: null,
    investorKYCDocuments: [],
    onboardedProductKYCDocumentAttachments: [],

    getOnboardedProductForCurrentSubscriptionProcessStatus: ActionStatus.None,
    getSubscriptionProcessForCurrentProductStatus: ActionStatus.None,
    startOnboardedProductSubscriptionProcessStatus: ActionStatus.None,
    specifyOnboardedProductSubscriptionInvestmentAmountStatus: ActionStatus.None,
    selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus: ActionStatus.None,
    confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus: ActionStatus.None,
    agreeToSubscriptionDataProcessingStatus: ActionStatus.None,
    confirmOnboardedProductSubscriptionStatus: ActionStatus.None,
    getOnboardedProductSubscriptionDocumentFileForDownloadStatus: ActionStatus.None,
    getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus.None,
    createOnboardedProductSubscriptionDocumentsStatus: ActionStatus.None,
    getOnboardedProductSubscriptionDocumentsForSubscriptionProcessStatus: ActionStatus.None,
    getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessStatus: ActionStatus.None,
    getInvestorKYCDocumentsStatus: ActionStatus.None,
    copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsStatus: ActionStatus.None,
    getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus: ActionStatus.None,
    deleteOnboardedProductKYCDocumentAttachmentStatus: ActionStatus.None,
    deleteOnboardedProductSubscriptionDocumentStatus: ActionStatus.None,
    continueSubscriptionProcessAsNaturalPersonStatus: ActionStatus.None,
    getOnboardedProductSubscriptionAgreementTemplatesToPrefillStatus: ActionStatus.None,
    getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadStatus: ActionStatus.None,
    getOnboardedProductControllingPersonTemplatesToPrefillForProductStatus: ActionStatus.None,
    getPrefilledOnboardedProductControllingPersonTemplateForDownloadStatus: ActionStatus.None,
  };
}

export const useInvestorOnboardedProductSubscriptionProcessDialogStore
  = defineStore('investorOnboardedProductSubscriptionProcessDialog', {
    state: (): InvestorOnboardedProductSubscriptionProcessDialogState => initialState(),
    getters: {
      isGetOnboardedProductForCurrentSubscriptionProcessProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductForCurrentSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetSubscriptionProcessForCurrentProductProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getSubscriptionProcessForCurrentProductStatus === ActionStatus.InProgress,
      isStartOnboardedProductSubscriptionProcessProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.startOnboardedProductSubscriptionProcessStatus === ActionStatus.InProgress,
      isSpecifyOnboardedProductSubscriptionInvestmentAmountProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.specifyOnboardedProductSubscriptionInvestmentAmountStatus === ActionStatus.InProgress,
      isSelectInvestmentProfileForOnboardedProductSubscriptionProcessProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus === ActionStatus.InProgress,
      isConfirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus === ActionStatus.InProgress,
      isAgreeToSubscriptionDataProcessingProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.agreeToSubscriptionDataProcessingStatus === ActionStatus.InProgress,
      isConfirmOnboardedProductSubscriptionProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.confirmOnboardedProductSubscriptionStatus === ActionStatus.InProgress,
      isGetOnboardedProductSubscriptionDocumentFileForDownloadProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductSubscriptionDocumentFileForDownloadStatus === ActionStatus.InProgress,
      isGetOnboardedProductSubscriptionDocumentTemplateFileForDownloadProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus === ActionStatus.InProgress,
      isCreateOnboardedProductSubscriptionDocumentsProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.createOnboardedProductSubscriptionDocumentsStatus === ActionStatus.InProgress,
      isGetOnboardedProductSubscriptionDocumentsForSubscriptionProcessProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductSubscriptionDocumentsForSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetInvestorKYCDocumentsProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getInvestorKYCDocumentsStatus === ActionStatus.InProgress,
      isCopyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsStatus === ActionStatus.InProgress,
      isGetOnboardedProductKYCDocumentAttachmentFileForDownloadProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus === ActionStatus.InProgress,
      isDeleteOnboardedProductKYCDocumentAttachmentProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.deleteOnboardedProductKYCDocumentAttachmentStatus === ActionStatus.InProgress,
      isDeleteOnboardedProductSubscriptionDocumentProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.deleteOnboardedProductSubscriptionDocumentStatus === ActionStatus.InProgress,
      isContinueSubscriptionProcessAsNaturalPersonProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.continueSubscriptionProcessAsNaturalPersonStatus === ActionStatus.InProgress,
      isGetOnboardedProductSubscriptionDocumentTemplatesToPrefillProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getOnboardedProductSubscriptionAgreementTemplatesToPrefillStatus === ActionStatus.InProgress,
      isGetPrefilledOnboardedProductSubscriptionDocumentTemplateForDownloadProcessing:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          state.getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadStatus === ActionStatus.InProgress,
      hasSubscriptionDocumentTemplates: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.subscriptionDocumentTemplates
      && state.currentSubscriptionProcess.subscriptionDocumentTemplates.length > 0,
      isPrefilledSubscriptionDocumentTemplatesVisible: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.selectedInvestmentProfileId
      && state.onboardedProductSubscriptionAgreementTemplateToPrefill.length > 0,
      currencySymbol: (state: InvestorOnboardedProductSubscriptionProcessDialogState): string =>
        state.currentProduct
          ? CurrencySymbols[state.currentProduct.currency]
          : '',
      isNaturalPersonSubscriptionProcess: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && state.currentSubscriptionProcess.isNaturalPersonSelected,
      hasKYCDocumentAttachments: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && !!state.onboardedProductKYCDocumentAttachments
      && state.onboardedProductKYCDocumentAttachments.length > 0,
      isInvestmentAmountSpecified: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.investmentAmount,
      hasUploadedDocuments: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.onboardedProductSubscriptionDocuments.length > 0,
      hasUploadedKYCDocumentAttachments: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.onboardedProductKYCDocumentAttachments.length > 0,
      isProductAndSubscriptionProcessAvailable: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentProduct
      && !!state.currentSubscriptionProcess,
      isSubscriptionProcessInReview: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.currentSubscriptionProcess?.status === SubscriptionProcessStatuses.IN_REVIEW,
      isDocumentManagementForSubscriptionProcessEnabled: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && state.currentSubscriptionProcess.isDocumentManagementForSubscriptionProcessEnabled || false,
      isInvestmentAmountDefined: (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.investmentAmount,
      formattedInvestmentAmount: (state: InvestorOnboardedProductSubscriptionProcessDialogState): string =>
        state.currentSubscriptionProcess
      && state.currentSubscriptionProcess.investmentAmount
      && state.currentProduct
          ? formatCurrencyWithoutCentDigits(
          state.currentSubscriptionProcess.investmentAmount!,
          state.currentProduct.currency
          )
          : '',
      nextSubscriptionProcessStep: (state: InvestorOnboardedProductSubscriptionProcessDialogState): SubscriptionProcessStep =>
        calculateNextSubscriptionProcessStep(state.currentSubscriptionProcess),
      currentProductMinimumInvestmentSizeKPI(state: InvestorOnboardedProductSubscriptionProcessDialogState): KPI|null {
        if (state.currentProduct) {
          const kpis = getKPIsFromTermSheet(state.currentProduct.termSheet);
          return kpis.find((kpi) => kpi.kpiType === KPITypes.MINIMUM_INVESTMENT_SIZE) || null;
        }
        return null;
      },
      isPrefilledOnboardedProductControllingPersonTemplatesVisible:
        (state: InvestorOnboardedProductSubscriptionProcessDialogState): boolean =>
          !!state.currentSubscriptionProcess
          && !!state.currentSubscriptionProcess.selectedInvestmentProfileId
          && state.onboardedProductControllingPersonTemplatesToPrefill.length > 0,
    },
    actions: {

      // -- State management

      updateCurrentProductId(currentProductId: string): Promise<void> {
        this.currentProductId = currentProductId;

        return Promise.resolve();
      },

      updateCurrentSubscriptionProcessStep(currentStep: SubscriptionProcessStep): Promise<void> {
        this.currentSubscriptionProcessStep = currentStep;

        return Promise.resolve();
      },

      // -- Queries

      getOnboardedProductForCurrentSubscriptionProcess(): Promise<void> {
        const query: GetOnboardedProductForCurrentSubscriptionProcessQuery = {
          productId: this.currentProductId!,
        };

        const { getOnboardedProductForCurrentSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductForCurrentSubscriptionProcessStatus,
          () => getOnboardedProductForCurrentSubscriptionProcess(query)
            .then((product) => {
              this.currentProduct = product;
            })
        );
      },

      getOnboardedProductSubscriptionDocumentsForSubscriptionProcess(): Promise<void> {
        const query : GetOnboardedProductSubscriptionDocumentsForSubscriptionProcessQuery = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { getOnboardedProductSubscriptionDocumentsForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductSubscriptionDocumentsForSubscriptionProcessStatus,
          () => getOnboardedProductSubscriptionDocumentsForSubscriptionProcess(query)
            .then((onboardedProductSubscriptionDocuments) => {
              this.onboardedProductSubscriptionDocuments = onboardedProductSubscriptionDocuments;
            })
        );
      },

      getOnboardedProductSubscriptionProcessForCurrentProduct(): Promise<void> {
        const query: GetOnboardedProductSubscriptionProcessForCurrentProductQuery = {
          productId: this.currentProductId!,
        };

        const { getSubscriptionProcessForCurrentProductStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getSubscriptionProcessForCurrentProductStatus,
          () => getOnboardedProductSubscriptionProcessForCurrentProduct(query)
            .then((onboardedProductSubscriptionProcess) => {
              this.currentSubscriptionProcessId = onboardedProductSubscriptionProcess.subscriptionProcessId;
              this.currentSubscriptionProcess = onboardedProductSubscriptionProcess;
              this.currentSubscriptionProcessStep = calculateNextSubscriptionProcessStep(onboardedProductSubscriptionProcess);
            })
            .catch((error) => {
              if (error.response?.status === 404) {
                return this.startOnboardedProductSubscriptionProcess()
                  .then(() => this.getOnboardedProductSubscriptionProcessForCurrentProduct());
              }

              return Promise.reject(error);
            })
        );
      },

      getOnboardedProductSubscriptionDocumentFileForDownload(
        query: GetOnboardedProductSubscriptionDocumentFileForDownloadQuery
      ): Promise<FileResponse> {
        const { getOnboardedProductSubscriptionDocumentFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductSubscriptionDocumentFileForDownloadStatus,
          () => getOnboardedProductSubscriptionDocumentFileForDownload(query)
        );
      },

      getOnboardedProductSubscriptionDocumentTemplateFileForDownload(
        query: GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery
      ): Promise<FileResponse> {
        const { confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus,
          () => getOnboardedProductSubscriptionDocumentTemplateFileForDownload(query)
        );
      },

      getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownload(
        query: GetPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadQuery
      ): Promise<FileResponse> {
        const { getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadStatus,
          () => getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownload(query)
        );
      },

      getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcess(): Promise<void> {
        const query: GetOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessQuery = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessStatus,
          () => getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcess(query)
            .then((onboardedProductKYCDocumentAttachments) => {
              this.onboardedProductKYCDocumentAttachments = onboardedProductKYCDocumentAttachments;
            })
        );
      },

      getInvestorKYCDocuments(): Promise<void> {
        const { getInvestorKYCDocumentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestorKYCDocumentsStatus,
          () => getInvestorKYCDocuments()
            .then((kycDocuments) => {
              this.investorKYCDocuments = kycDocuments;
            })
        );
      },

      getOnboardedProductSubscriptionAgreementTemplatesToPrefill(
        query: GetOnboardedProductSubscriptionAgreementTemplatesToPrefillQuery
      ): Promise<void> {
        const { getOnboardedProductSubscriptionAgreementTemplatesToPrefillStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductSubscriptionAgreementTemplatesToPrefillStatus,
          () => getOnboardedProductSubscriptionAgreementTemplatesToPrefill(query)
            .then((onboardedProductSubscriptionAgreementTemplateToPrefill) => {
              this.onboardedProductSubscriptionAgreementTemplateToPrefill = onboardedProductSubscriptionAgreementTemplateToPrefill;
            })
        );
      },

      getOnboardedProductKYCDocumentAttachmentFileForDownload(
        query: GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery
      ): Promise<FileResponse> {
        const { getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus,
          () => getOnboardedProductKYCDocumentAttachmentFileForDownload(query)
        );
      },

      getOnboardedProductControllingPersonTemplatesToPrefillForProduct(
        query: GetOnboardedProductControllingPersonTemplatesToPrefillForProductQuery
      ): Promise<void> {
        const { getOnboardedProductControllingPersonTemplatesToPrefillForProductStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductControllingPersonTemplatesToPrefillForProductStatus,
          () => getOnboardedProductControllingPersonTemplatesToPrefillForProduct(query)
            .then((onboardedProductControllingPersonTemplatesToPrefill) => {
              this.onboardedProductControllingPersonTemplatesToPrefill = onboardedProductControllingPersonTemplatesToPrefill;
            })
        );
      },

      getPrefilledOnboardedProductControllingPersonTemplateForDownload(
        query: GetPrefilledOnboardedProductControllingPersonTemplateForDownloadQuery
      ): Promise<FileResponse> {
        const { getPrefilledOnboardedProductControllingPersonTemplateForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getPrefilledOnboardedProductControllingPersonTemplateForDownloadStatus,
          () => getPrefilledOnboardedProductControllingPersonTemplateForDownload(query)
        );
      },

      // -- Commands

      startOnboardedProductSubscriptionProcess(): Promise<void> {
        const command: StartOnboardedProductSubscriptionProcessCommand = {
          productId: this.currentProductId!,
        };

        const { startOnboardedProductSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          startOnboardedProductSubscriptionProcessStatus,
          () => startOnboardedProductSubscriptionProcess(command)
        );
      },

      specifyOnboardedProductSubscriptionInvestmentAmount(command: SpecifyOnboardedProductSubscriptionInvestmentAmountCommand):
      Promise<void> {
        const { specifyOnboardedProductSubscriptionInvestmentAmountStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          specifyOnboardedProductSubscriptionInvestmentAmountStatus,
          () => specifyOnboardedProductSubscriptionInvestmentAmount(command)
            .then(() => this.getOnboardedProductSubscriptionProcessForCurrentProduct())
        );
      },

      selectInvestmentProfileForOnboardedProductSubscriptionProcess(
        command: SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand
      ): Promise<void> {
        const { selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus,
          () => selectInvestmentProfileForOnboardedProductSubscriptionProcess(command)
            .then(() => this.getOnboardedProductSubscriptionProcessForCurrentProduct())
        );
      },

      confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcess(
        command: ConfirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessCommand
      ): Promise<void> {
        const { confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessStatus,
          () => confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcess(command)
            .then(() => this.getOnboardedProductSubscriptionProcessForCurrentProduct())
        );
      },

      createOnboardedProductSubscriptionDocuments(files: File[]): Promise<void[]> {
        const queuedFileUploads = files.map((file) => {
          const command: CreateOnboardedProductSubscriptionDocumentCommand = {
            body: {
              subscriptionProcessId: this.currentSubscriptionProcessId!,
            },
            files: { file },
          };

          const payload: AddItemToUploadQueuePayload = {
            fileName: file.name,
            serviceFunction: createOnboardedProductSubscriptionDocument,
            command,
          };

          return useFileUploadQueueStore().addItem(payload)
            .then(() => this.getOnboardedProductSubscriptionDocumentsForSubscriptionProcess());
        });

        const { createOnboardedProductSubscriptionDocumentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createOnboardedProductSubscriptionDocumentsStatus,
          () => Promise.all(queuedFileUploads)
        );
      },

      confirmOnboardedProductSubscription(
        command: ConfirmOnboardedProductSubscriptionCommand
      ): Promise<void> {
        const { confirmOnboardedProductSubscriptionStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmOnboardedProductSubscriptionStatus,
          () => confirmOnboardedProductSubscription(command)
            .then(() => this.getOnboardedProductSubscriptionProcessForCurrentProduct())
        );
      },

      continueOnboardedProductSubscriptionProcessAsNaturalPerson(): Promise<void> {
        const command: ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { continueSubscriptionProcessAsNaturalPersonStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          continueSubscriptionProcessAsNaturalPersonStatus,
          () => continueOnboardedProductSubscriptionProcessAsNaturalPerson(command)
            .then(() => this.getOnboardedProductSubscriptionProcessForCurrentProduct())
        );
      },

      deleteOnboardedProductSubscriptionDocument(command: DeleteOnboardedProductSubscriptionDocumentCommand): Promise<void> {
        const { deleteOnboardedProductSubscriptionDocumentStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteOnboardedProductSubscriptionDocumentStatus,
          () => deleteOnboardedProductSubscriptionDocument(command)
            .then(() => this.getOnboardedProductSubscriptionDocumentsForSubscriptionProcess())
        );
      },

      deleteOnboardedProductKYCDocumentAttachment(
        command: DeleteOnboardedProductKYCDocumentAttachmentCommand
      ): Promise<void> {
        const { deleteOnboardedProductKYCDocumentAttachmentStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteOnboardedProductKYCDocumentAttachmentStatus,
          () => deleteOnboardedProductKYCDocumentAttachment(command)
        );
      },

      copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachments(
        command: CopyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsCommand
      ): Promise<void> {
        const { copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsStatus,
          () => copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachments(command)
        );
      },

    },
  });
