import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { DeleteInvestmentForInvestorCommand, UpdateInvestmentForInvestorCommand, GetInvestmentsOfInvestorForProductQuery, InvestmentsOfInvestorForProductResult, CreateTransactionCommand, DeleteTransactionCommand, UpdateTransactionCommand, GetAccessiblePublishedProductsForInvestorQuery, ProductDetails } from './types';

export function getInvestmentsOfInvestorForProduct(
  query: GetInvestmentsOfInvestorForProductQuery
): Promise<InvestmentsOfInvestorForProductResult> {
  const url = generateApiUrl('/api/general-partner/get-investments-of-investor-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getAccessiblePublishedProductsForInvestor(
  query: GetAccessiblePublishedProductsForInvestorQuery
): Promise<ProductDetails[]> {
  const url = generateApiUrl('/api/general-partner/get-accessible-published-products-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function updateInvestmentForInvestor(command: UpdateInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteInvestmentForInvestor(command: DeleteInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createTransactionForInvestor(command: CreateTransactionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-transaction-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateTransactionForInvestor(command: UpdateTransactionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-transaction-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteTransactionForInvestor(command: DeleteTransactionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-transaction-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
