export class Deferred<T> {

  promise: Promise<T>;

  reject!: (reason?: any) => void;

  resolve!: (reason: T) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
