import { defineStore, storeToRefs } from 'pinia';
import { GetProductsForComparisonQuery, Product } from '@/domain/investor/products/product-comparison/types';
import { ActionStatus } from '@/application/types';
import { KPI, KPIsByKPICategoryId, StaticKPIType, StaticKPITypes } from '@/types/global';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { wrapActionWithProgress } from '@/store';
import { getProductsForComparison } from '@/domain/investor/products/product-comparison/service';

interface InvestorProductComparisonState {
  products: Product[];

  getProductsForComparisonStatus: ActionStatus;
}

function initialState(): InvestorProductComparisonState {
  return {
    products: [],
    getProductsForComparisonStatus: ActionStatus.None,
  };
}

export const useInvestorProductComparisonStore = defineStore('investorProductComparison', {
  state: (): InvestorProductComparisonState => initialState(),
  getters: {
    areProductsAvailable: (state: InvestorProductComparisonState): boolean =>
      state.products.length > 0,
    nonStaticKPIs(): KPI[] {
      let nonStaticKPIs: KPI[] = [];

      Object.values(this.kpisByProducts).forEach((kpis) => {
        nonStaticKPIs = [
          ...nonStaticKPIs,
          ...kpis.filter((kpi) => !Object.values(StaticKPITypes).includes(kpi.kpiType as StaticKPIType)),
        ];
      });

      return nonStaticKPIs;
    },
    kpisByProducts(): KPIsByKPICategoryId {
      const kpisByProducts: KPIsByKPICategoryId = {};

      this.products.forEach((product) => {
        kpisByProducts[product.productId] = getKPIsFromTermSheet(product.termSheet);
      });

      return kpisByProducts;
    },
  },
  actions: {
    // -- Queries

    getProductsForComparison(query: GetProductsForComparisonQuery): Promise<void> {
      const { getProductsForComparisonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductsForComparisonStatus,
        () => getProductsForComparison(query)
          .then((products) => {
            this.products = products;
          })
      );
    },
  },
});
