import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CreateProductReportCommand, DeleteProductReportCommand, GetProductReportForDownloadQuery, GetProductReportsQuery, ProductReport, UpdateProductReportCommand } from './types';

export function getProductReports(query: GetProductReportsQuery): Promise<ProductReport[]> {
  const url = generateApiUrl('/api/general-partner/get-product-reports-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductReportForDownload(query: GetProductReportForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-product-report-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function createProductReport(command: CreateProductReportCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-product-report-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function updateProductReport(command: UpdateProductReportCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-report-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function deleteProductReport(command: DeleteProductReportCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-report-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
