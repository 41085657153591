var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"all-users-tab"}},[_c('v-data-table',{staticClass:"mt-4 clickable-rows",attrs:{"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":"","loading-text":_vm.translate('table.loadingText'),"no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.store.allUsers,"item-key":"userId","loading":_vm.store.isGetUsersProcessing,"mobile-breakpoint":960,"must-sort":true,"data-cy":"all-users-tab-table"},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-block d-md-flex"},[_c('div',[_c('a-pill',{staticClass:"mb-1 mb-md-0 mr-md-2 whitespace-nowrap",attrs:{"text":_vm.accountTypeLabel(item),"color":_vm.accountTypePillColor(item)}})],1),_c('div',[_c('div',{class:{
              'grey--text text--darken-4': !item.isLocked,
              'grey--text text--lighten-1': item.isLocked,
            }},[_vm._v(" "+_vm._s(item.name.firstName)+" "+_vm._s(item.name.lastName)+" ")]),_c('div',{staticClass:"body-3",class:{
              'grey--text text--darken-2': !item.isLocked,
              'grey--text text--lighten-1': item.isLocked,
            }},[_vm._v(" "+_vm._s(item.emailAddress)+" ")]),(item.isLocked)?_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"color":"red","text":_vm.translate('table.lockedUser')}}):_vm._e()],1)])]}},{key:"item.investorName",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:{
          'grey--text text--darken-4': !item.isLocked,
          'grey--text text--lighten-1': item.isLocked,
        }},[_vm._v(" "+_vm._s(_vm.investorName(item.investor))+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('user-actions-menu',{attrs:{"user":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }