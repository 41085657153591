import { RawLocation, Route, Location, RouteMeta } from 'vue-router';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useAppStore } from '@/application/common/app/store';
import { useGeneralPartnerStore } from '@/application/whitelabel/general-partner/store';
import { AccountType, Feature } from '@/types/global';
import { AuthenticatedUser } from '@/application/whitelabel/authentication/types';

function getRedirectRouteForAuthenticatedUser(): Location {
  return { name: 'default' };
}

function getRedirectRouteForUnauthenticatedUser(): Location {
  return { name: 'root' };
}

export async function accessGuard(
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: any) => any) | void) => void
) {
  if (!to.meta) {
    throw Error('The route meta is undefined');
  }

  const authenticationStore = useAuthenticationStore();
  const generalPartnerStore = useGeneralPartnerStore();
  const appStore = useAppStore();

  if (!authenticationStore.wasInitialAuthenticationAttempted
    || !generalPartnerStore.generalPartner
  ) {
    await Promise.all([
      generalPartnerStore.getGeneralPartner()
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {}),
      authenticationStore.getInitialAuthentication()
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {}),
    ]);
  }

  const enabledFeaturesOfGeneralPartner = generalPartnerStore.generalPartner!.enabledFeatures;
  // Account type is set through the `getAuthentication` call
  const accountType = appStore.accountType;
  const user = authenticationStore.user;

  const redirectLocation = nextPotentialRedirectLocation(
    to.meta,
    accountType,
    enabledFeaturesOfGeneralPartner,
    user
  );
  if (redirectLocation === null) {
    next();
  } else {
    next(redirectLocation);
  }
}

export function nextPotentialRedirectLocation(
  meta: RouteMeta,
  accountType: AccountType | null,
  enabledFeaturesOfGeneralPartner: Feature[],
  user: AuthenticatedUser | null
): Location | null {
  // Route is always accessible
  if (meta.alwaysAccessible) {
    return null;
  }

  const isAuthenticated = !!user;

  // User is authenticated but route is just accessible as unauthenticated user
  if (isAuthenticated
    && !meta.requiresAuth
  ) {
    return getRedirectRouteForAuthenticatedUser();
  }

  // User is unauthenticated but route is just accessible as authenticated user
  if (!isAuthenticated
    && meta.requiresAuth
  ) {
    return getRedirectRouteForUnauthenticatedUser();
  }

  // If user permissions is required to access the route
  if (meta.requiresPermission) {
    const hasUserPermission = user?.permissions.includes(meta.requiresPermission) ?? false;

    if (!hasUserPermission) {
      return getRedirectRouteForAuthenticatedUser();
    }
  }

  // If enabled features are required to access the route
  if (meta.requiresFeatures) {
    for (const feature of meta.requiresFeatures) {
      const hasGeneralPartnerEnabledFeature = enabledFeaturesOfGeneralPartner.includes(feature) ?? false;

      if (!hasGeneralPartnerEnabledFeature) {
        return getRedirectRouteForAuthenticatedUser();
      }
    }
  }

  if (meta.requiresAccountType) {
    if (accountType
      && meta.requiresAccountType !== accountType
    ) {
      return getRedirectRouteForAuthenticatedUser();
    }
  }

  return null;
}
