import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachBrainiac } from '@/helpers/default-parameter-helper';
import { CreatePlatformNewsCommand, PlatformNews, UpdatePlatformNewsCommand } from './types';

export function createPlatformNews(command: CreatePlatformNewsCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/create-platform-news-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function getPlatformNews(): Promise<PlatformNews[]> {
  const url = generateApiUrl('/brain-api/get-platform-news-query');
  return performApiRequest<PlatformNews[]>({ url, method: 'POST', data: attachBrainiac({}) });
}

export function updatePlatformNews(command: UpdatePlatformNewsCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-platform-news-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
