import { RouteConfig } from 'vue-router';
import { AccountTypes, Features } from '@/types/global';
import Products from './products/components/products.vue';
import Product from './product/components/product.vue';
import AboutProductTab from './product/components/about-product-tab.vue';
import ProductDetailsTab from './product/components/product-details-tab.vue';
import ProductDocumentsTab from './product/components/product-documents-tab/product-documents-tab.vue';
import ProductNewsTab from './product/components/product-news-tab/product-news-tab.vue';

export const generalPartnerNetworkRoutes: RouteConfig[] = [
  {
    name: 'general-partner-products-from-network',
    path: '/general-partner/network/products',
    component: Products,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.GENERAL_PARTNER,
      requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
    },
  },
  {
    path: '/general-partner/network/product/:productId',
    component: Product,
    children: [
      {
        path: '',
        redirect: 'about',
      }, {
        name: 'general-partner-product-from-network-about',
        path: 'about',
        component: AboutProductTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-from-network-details',
        path: 'details',
        component: ProductDetailsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-from-network-documents',
        path: 'documents',
        component: ProductDocumentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-from-network-news',
        path: 'news',
        component: ProductNewsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  },
];
