import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { useGeneralPartnerProductManagementGeneralStore } from '@/domain/general-partner/products/product-management/store';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { BreadCrumb, CreateDueDiligenceDirectoryCommand, CreateDueDiligenceFileCommand, CreateDueDiligenceFileWithVisibleLanguagesForClientCommand, CreatePrivateDueDiligenceFileCommand, CreateProductNDACommand, DeleteDueDiligenceDirectoryCommand, DeleteDueDiligenceFileCommand, DeleteProductNDACommand, DirectoryForChangesPreview, DueDiligenceUNode, FileForChangesPreview, FileWithRelativePathAndVisibleLanguageForClient, GetAvailableDueDiligenceFilesForProductQuery, GetBreadCrumbsForDueDiligenceDataRoomQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetPreviewForDueDiligenceFileAndDirectoryChangesQuery, GetPreviewForDueDiligenceFileChangesQuery, GetProductNDAForDownloadQuery, GetUNodesForDueDiligenceDataRoomQuery, PinDueDiligenceFileCommand, SendMessageToInvestorUsersCommand, UNode, UnpinDueDiligenceFileCommand, UpdateDueDiligenceDirectoryNameAndVisibleLanguagesForClientCommand, UpdateDueDiligenceFileCommand, UpdateDueDiligenceFileVisibleLanguagesForClientCommand, UpdateDueDiligenceFileWithVisibleLanguagesForClientCommand, UpdatePrivateDueDiligenceFileCommand, UpdateProductPinnedDueDiligenceFilesOrderCommand, UpdateUsersWithExplicitAccessForFileCommand, UploadDueDiligenceFileWithPathCommand } from './types';
import { createDueDiligenceDirectory, createDueDiligenceFile, createDueDiligenceFileWithVisibleLanguagesForClient, createPrivateDueDiligenceFile, createProductNDA, deleteDueDiligenceDirectory, deleteDueDiligenceFile, deleteProductNDA, getAvailableDueDiligenceFilesForProduct, getBreadCrumbsForDueDiligenceDataRoom, getDueDiligenceDirectoryForDownload, getDueDiligenceFileForDownload, getPreviewForDueDiligenceFileAndDirectoryChanges, getPreviewForDueDiligenceFileChanges, getProductNDAForDownload, getUNodesForDueDiligenceDataRoom, pinDueDiligenceFile, sendMessageToInvestorUsers, unpinDueDiligenceFile, updateDueDiligenceDirectoryNameAndVisibleLanguagesForClient, updateDueDiligenceFile, updateDueDiligenceFileVisibibleLanguageForClient, updateDueDiligenceFileWithVisibleLanguagesForClient, updatePrivateDueDiligenceFile, updateProductPinnedDueDiligenceFilesOrder, updateUsersWithExplicitAccessForFile, uploadDueDiligenceFileWithPath } from './services';

interface GeneralPartnerProductManagementDocumentsTabState {
  uNodesForAvailableDueDiligenceFiles: DueDiligenceUNode[];
  dueDiligenceDataRoomBreadCrumbs: BreadCrumb[];
  dueDiligenceDataRoomUNodes: UNode[];
  dueDiligenceDataRoomDirectoryId: string | null;
  idsOfInvestorUsersWithExplicitAccess: string[];

  getDueDiligenceFileForDownloadStatus: ActionStatus;
  updateProductPinnedDueDiligenceFilesOrderStatus: ActionStatus;
  pinDueDiligenceFileStatus: ActionStatus;
  unpinDueDiligenceFileStatus: ActionStatus;
  getAvailableDueDiligenceFilesForProductStatus: ActionStatus;
  getBreadCrumbsForDueDiligenceDataRoomStatus: ActionStatus;
  getUNodesForDueDiligenceDataRoomStatus: ActionStatus;
  getDueDiligenceDirectoryForDownloadStatus: ActionStatus;
  createDueDiligenceDirectoryStatus: ActionStatus;
  createDueDiligenceFileStatus: ActionStatus;
  createDueDiligenceFileWithVisibleLanguagesForClientStatus: ActionStatus;
  createPrivateDueDiligenceFileStatus: ActionStatus;
  updateDueDiligenceFileStatus: ActionStatus;
  updateDueDiligenceFileWithVisibleLanguagesForClientStatus: ActionStatus;
  updatePrivateDueDiligenceFileStatus: ActionStatus;
  getPreviewForDueDiligenceFileAndDirectoryChangesStatus: ActionStatus;
  getPreviewForDueDiligenceFileChangesStatus: ActionStatus;
  uploadDueDiligenceFilesWithPathStatus: ActionStatus;
  uploadPrivateDueDiligenceFilesWithPathStatus: ActionStatus;
  updateDueDiligenceDirectoryNameAndVisibleLanguagesForClientStatus: ActionStatus;
  updateDueDiligenceFileVisibibleLanguageForClientStatus: ActionStatus;
  deleteDueDiligenceFileStatus: ActionStatus;
  deleteDueDiligenceDirectoryStatus: ActionStatus;
  sendMessageToInvestorUsersStatus: ActionStatus;
  updateUsersWithExplicitAccessForFileStatus: ActionStatus;
  createProductNDAStatus: ActionStatus;
  deleteProductNDAStatus: ActionStatus;
  getProductNDAForDownloadStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementDocumentsTabState {
  return {
    uNodesForAvailableDueDiligenceFiles: [],
    dueDiligenceDataRoomBreadCrumbs: [],
    dueDiligenceDataRoomUNodes: [],
    dueDiligenceDataRoomDirectoryId: null,
    idsOfInvestorUsersWithExplicitAccess: [],

    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    updateProductPinnedDueDiligenceFilesOrderStatus: ActionStatus.None,
    pinDueDiligenceFileStatus: ActionStatus.None,
    unpinDueDiligenceFileStatus: ActionStatus.None,
    getAvailableDueDiligenceFilesForProductStatus: ActionStatus.None,
    getBreadCrumbsForDueDiligenceDataRoomStatus: ActionStatus.None,
    getUNodesForDueDiligenceDataRoomStatus: ActionStatus.None,
    getDueDiligenceDirectoryForDownloadStatus: ActionStatus.None,
    createDueDiligenceDirectoryStatus: ActionStatus.None,
    createDueDiligenceFileStatus: ActionStatus.None,
    createDueDiligenceFileWithVisibleLanguagesForClientStatus: ActionStatus.None,
    createPrivateDueDiligenceFileStatus: ActionStatus.None,
    updateDueDiligenceFileStatus: ActionStatus.None,
    updateDueDiligenceFileWithVisibleLanguagesForClientStatus: ActionStatus.None,
    updatePrivateDueDiligenceFileStatus: ActionStatus.None,
    getPreviewForDueDiligenceFileAndDirectoryChangesStatus: ActionStatus.None,
    getPreviewForDueDiligenceFileChangesStatus: ActionStatus.None,
    uploadDueDiligenceFilesWithPathStatus: ActionStatus.None,
    uploadPrivateDueDiligenceFilesWithPathStatus: ActionStatus.None,
    updateDueDiligenceDirectoryNameAndVisibleLanguagesForClientStatus: ActionStatus.None,
    updateDueDiligenceFileVisibibleLanguageForClientStatus: ActionStatus.None,
    deleteDueDiligenceFileStatus: ActionStatus.None,
    deleteDueDiligenceDirectoryStatus: ActionStatus.None,
    sendMessageToInvestorUsersStatus: ActionStatus.None,
    updateUsersWithExplicitAccessForFileStatus: ActionStatus.None,
    createProductNDAStatus: ActionStatus.None,
    deleteProductNDAStatus: ActionStatus.None,
    getProductNDAForDownloadStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementDocumentsTabStore = defineStore('generalPartnerProductManagementDocumentsTab', {
  state: (): GeneralPartnerProductManagementDocumentsTabState => initialState(),
  getters: {
    isGetDueDiligenceFileForDownloadProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getDueDiligenceFileForDownloadStatus === ActionStatus.InProgress,
    isUpdateProductPinnedDueDiligenceFilesOrderProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.updateProductPinnedDueDiligenceFilesOrderStatus === ActionStatus.InProgress,
    isPinDueDiligenceFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.pinDueDiligenceFileStatus === ActionStatus.InProgress,
    isUnpinDueDiligenceFileStatusProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.unpinDueDiligenceFileStatus === ActionStatus.InProgress,
    isGetAvailableDueDiligenceFilesForProductProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getAvailableDueDiligenceFilesForProductStatus === ActionStatus.InProgress,
    isGetBreadCrumbsForDueDiligenceDataRoomProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getBreadCrumbsForDueDiligenceDataRoomStatus === ActionStatus.InProgress,
    isGetUNodesForDueDiligenceDataRoomProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getUNodesForDueDiligenceDataRoomStatus === ActionStatus.InProgress,
    isGetDueDiligenceDirectoryForDownloadProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getDueDiligenceDirectoryForDownloadStatus === ActionStatus.InProgress,
    isCreateDueDiligenceDirectoryProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.createDueDiligenceDirectoryStatus === ActionStatus.InProgress,
    isCreateDueDiligenceFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.createDueDiligenceFileStatus === ActionStatus.InProgress,
    isCreateDueDiligenceFileWithVisibleLanguagesForClientProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.createDueDiligenceFileWithVisibleLanguagesForClientStatus === ActionStatus.InProgress,
    isCreatePrivateDueDiligenceFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.createPrivateDueDiligenceFileStatus === ActionStatus.InProgress,
    isUpdateDueDiligenceFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.updateDueDiligenceFileStatus === ActionStatus.InProgress,
    isUpdateDueDiligenceFileWithVisibleLanguagesForClientProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.updateDueDiligenceFileWithVisibleLanguagesForClientStatus === ActionStatus.InProgress,
    isUpdatePrivateDueDiligenceFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.updatePrivateDueDiligenceFileStatus === ActionStatus.InProgress,
    isGetPreviewForDueDiligenceFileAndDirectoryChangesProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getPreviewForDueDiligenceFileAndDirectoryChangesStatus === ActionStatus.InProgress,
    isGetPreviewForDueDiligenceFileChangesProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getPreviewForDueDiligenceFileChangesStatus === ActionStatus.InProgress,
    isUploadDueDiligenceFilesWithPathProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.uploadDueDiligenceFilesWithPathStatus === ActionStatus.InProgress,
    isUploadPrivateDueDiligenceFilesWithPathProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.uploadPrivateDueDiligenceFilesWithPathStatus === ActionStatus.InProgress,
    isUpdateDueDiligenceDirectoryNameAndVisibleLanguagesForClientProcessing:
      (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
        state.updateDueDiligenceDirectoryNameAndVisibleLanguagesForClientStatus === ActionStatus.InProgress,
    isUpdateDueDiligenceFileVisibibleLanguageForClientProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.updateDueDiligenceFileVisibibleLanguageForClientStatus === ActionStatus.InProgress,
    isDeleteDueDiligenceFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.deleteDueDiligenceFileStatus === ActionStatus.InProgress,
    isDeleteDueDiligenceDirectoryProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.deleteDueDiligenceDirectoryStatus === ActionStatus.InProgress,
    isSendMessageToInvestorUsersProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.sendMessageToInvestorUsersStatus === ActionStatus.InProgress,
    isUpdateUsersWithExplicitAccessForFileProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.updateUsersWithExplicitAccessForFileStatus === ActionStatus.InProgress,
    isCreateProductNDAProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.createProductNDAStatus === ActionStatus.InProgress,
    isDeleteProductNDAProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.deleteProductNDAStatus === ActionStatus.InProgress,
    isGetProductNDAForDownloadProcessing: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.getProductNDAForDownloadStatus === ActionStatus.InProgress,
    hasAvailableDueDiligenceFiles: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.uNodesForAvailableDueDiligenceFiles.length > 0,
    areAtLeastTwoBreadCrumbsAvailable: (state: GeneralPartnerProductManagementDocumentsTabState): boolean =>
      state.dueDiligenceDataRoomBreadCrumbs.length > 1,
    hasDueDiligenceFileWithSameName: (state: GeneralPartnerProductManagementDocumentsTabState):(fileName: string) => boolean =>
      (fileName: string) => state.dueDiligenceDataRoomUNodes.some((uNode) => uNode.name === fileName),
  },
  actions: {

    // -- State managment

    updateDueDiligenceDataRoomDirectory(directoryId?: string): Promise<void> {
      this.dueDiligenceDataRoomDirectoryId = directoryId || null;

      return this.getBreadCrumbsForDueDiligenceDataRoom()
        .then(() => this.getUNodesForDueDiligenceDataRoom());
    },

    // -- Queries

    getAvailableDueDiligenceFilesForProduct(): Promise<void> {
      const query: GetAvailableDueDiligenceFilesForProductQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
        language: useGeneralPartnerProductManagementGeneralStore().selectedLanguage!,
      };

      const { getAvailableDueDiligenceFilesForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAvailableDueDiligenceFilesForProductStatus,
        () => getAvailableDueDiligenceFilesForProduct(query)
          .then((files) => {
            this.uNodesForAvailableDueDiligenceFiles = files;
          })
      );
    },

    getBreadCrumbsForDueDiligenceDataRoom(): Promise<void> {
      const query: GetBreadCrumbsForDueDiligenceDataRoomQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
      };

      if (this.dueDiligenceDataRoomDirectoryId) {
        query.directoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const { getBreadCrumbsForDueDiligenceDataRoomStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBreadCrumbsForDueDiligenceDataRoomStatus,
        () => getBreadCrumbsForDueDiligenceDataRoom(query)
          .then((breadCrumbs) => {
            this.dueDiligenceDataRoomBreadCrumbs = breadCrumbs;
          })
      );
    },

    getUNodesForDueDiligenceDataRoom(): Promise<void> {
      const query: GetUNodesForDueDiligenceDataRoomQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
      };

      if (this.dueDiligenceDataRoomDirectoryId) {
        query.directoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const { getUNodesForDueDiligenceDataRoomStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUNodesForDueDiligenceDataRoomStatus,
        () => getUNodesForDueDiligenceDataRoom(query)
          .then((uNodes) => {
            this.dueDiligenceDataRoomUNodes = uNodes;
          })
      );
    },

    getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceDirectoryForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceDirectoryForDownloadStatus,
        () => getDueDiligenceDirectoryForDownload(query)
      );
    },

    getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceFileForDownloadStatus,
        () => getDueDiligenceFileForDownload(query)
      );
    },

    getPreviewForDueDiligenceFileAndDirectoryChanges(
      query: GetPreviewForDueDiligenceFileAndDirectoryChangesQuery
    ): Promise<(FileForChangesPreview|DirectoryForChangesPreview)[]> {
      const { getPreviewForDueDiligenceFileAndDirectoryChangesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPreviewForDueDiligenceFileAndDirectoryChangesStatus,
        () => getPreviewForDueDiligenceFileAndDirectoryChanges(query)
      );
    },

    getPreviewForDueDiligenceFileChanges(query: GetPreviewForDueDiligenceFileChangesQuery): Promise<(FileForChangesPreview)[]> {
      const { getPreviewForDueDiligenceFileChangesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPreviewForDueDiligenceFileChangesStatus,
        () => getPreviewForDueDiligenceFileChanges(query)
      );
    },

    getProductNDAForDownload(query: GetProductNDAForDownloadQuery): Promise<FileResponse> {
      const { getProductNDAForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductNDAForDownloadStatus,
        () => getProductNDAForDownload(query)
      );
    },

    // -- Commands

    pinDueDiligenceFile(command: PinDueDiligenceFileCommand): Promise<void> {
      const { pinDueDiligenceFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        pinDueDiligenceFileStatus,
        () => pinDueDiligenceFile(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    unpinDueDiligenceFile(command: UnpinDueDiligenceFileCommand): Promise<void> {
      const { unpinDueDiligenceFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpinDueDiligenceFileStatus,
        () => unpinDueDiligenceFile(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductPinnedDueDiligenceFilesOrder(command: UpdateProductPinnedDueDiligenceFilesOrderCommand): Promise<void> {
      const { updateProductPinnedDueDiligenceFilesOrderStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductPinnedDueDiligenceFilesOrderStatus,
        () => updateProductPinnedDueDiligenceFilesOrder(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    createDueDiligenceDirectory(command: CreateDueDiligenceDirectoryCommand): Promise<void> {
      const { createDueDiligenceDirectoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createDueDiligenceDirectoryStatus,
        () => createDueDiligenceDirectory(command)
          .then(() => this.getUNodesForDueDiligenceDataRoom())
      );
    },

    createDueDiligenceFile(file: File): Promise<void> {
      this.createDueDiligenceFileStatus = ActionStatus.InProgress;

      const command: CreateDueDiligenceFileCommand = {
        body: {
          productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
          visibleForClientLanguage: useGeneralPartnerProductManagementGeneralStore().selectedLanguage,
        },
        files: { file },
      };

      if (this.dueDiligenceDataRoomDirectoryId) {
        command.body.parentDirectoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const payload: AddItemToUploadQueuePayload = {
        fileName: file.name,
        serviceFunction: createDueDiligenceFile,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.createDueDiligenceFileStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createDueDiligenceFileStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    updateDueDiligenceFile(file: File): Promise<void> {
      this.updateDueDiligenceFileStatus = ActionStatus.InProgress;

      const fileWithSameName = this.dueDiligenceDataRoomUNodes.find((uNode) => uNode.name === file.name)!;
      const command: UpdateDueDiligenceFileCommand = {
        body: {
          fileId: fileWithSameName.id,
          visibleForClientLanguage: useGeneralPartnerProductManagementGeneralStore().selectedLanguage,
        },
        files: { file },
      };

      const payload: AddItemToUploadQueuePayload = {
        fileName: file.name,
        serviceFunction: updateDueDiligenceFile,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.updateDueDiligenceFileStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.updateDueDiligenceFileStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    createDueDiligenceFileWithVisibleLanguagesForClient(file: FileWithRelativePathAndVisibleLanguageForClient): Promise<void> {
      this.createDueDiligenceFileWithVisibleLanguagesForClientStatus = ActionStatus.InProgress;

      const command: CreateDueDiligenceFileWithVisibleLanguagesForClientCommand = {
        body: {
          productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
          visibleForClientLanguages: file.visibleForClientLanguages!,
        },
        files: { file },
      };

      if (this.dueDiligenceDataRoomDirectoryId) {
        command.body.parentDirectoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const payload: AddItemToUploadQueuePayload = {
        fileName: file.name,
        serviceFunction: createDueDiligenceFileWithVisibleLanguagesForClient,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.createDueDiligenceFileWithVisibleLanguagesForClientStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createDueDiligenceFileWithVisibleLanguagesForClientStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    createPrivateDueDiligenceFile(command: CreatePrivateDueDiligenceFileCommand): Promise<void> {
      this.createPrivateDueDiligenceFileStatus = ActionStatus.InProgress;

      if (this.dueDiligenceDataRoomDirectoryId) {
        command.body.parentDirectoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const payload: AddItemToUploadQueuePayload = {
        fileName: command.files.file.name,
        serviceFunction: createPrivateDueDiligenceFile,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.createPrivateDueDiligenceFileStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createPrivateDueDiligenceFileStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    updateDueDiligenceFileWithVisibleLanguagesForClient(file: FileWithRelativePathAndVisibleLanguageForClient): Promise<void> {
      this.updateDueDiligenceFileWithVisibleLanguagesForClientStatus = ActionStatus.InProgress;

      const fileWithSameName = this.dueDiligenceDataRoomUNodes.find((uNode) => uNode.name === file.name)!;
      const command: UpdateDueDiligenceFileWithVisibleLanguagesForClientCommand = {
        body: {
          fileId: fileWithSameName.id,
          visibleForClientLanguages: file.visibleForClientLanguages!,
        },
        files: { file },
      };

      const payload: AddItemToUploadQueuePayload = {
        fileName: file.name,
        serviceFunction: updateDueDiligenceFileWithVisibleLanguagesForClient,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.updateDueDiligenceFileWithVisibleLanguagesForClientStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.updateDueDiligenceFileWithVisibleLanguagesForClientStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    updatePrivateDueDiligenceFile(command: UpdatePrivateDueDiligenceFileCommand): Promise<void> {
      this.updatePrivateDueDiligenceFileStatus = ActionStatus.InProgress;

      const payload: AddItemToUploadQueuePayload = {
        fileName: command.files.file.name,
        serviceFunction: updatePrivateDueDiligenceFile,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.updatePrivateDueDiligenceFileStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.updatePrivateDueDiligenceFileStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    updateDueDiligenceFileVisibibleLanguageForClient(command: UpdateDueDiligenceFileVisibleLanguagesForClientCommand): Promise<void> {
      const { updateDueDiligenceFileVisibibleLanguageForClientStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDueDiligenceFileVisibibleLanguageForClientStatus,
        () => updateDueDiligenceFileVisibibleLanguageForClient(command)
      );
    },

    uploadDueDiligenceFilesWithPath(files: FileWithRelativePathAndVisibleLanguageForClient[]): Promise<void[]> {
      const fileUploadQueueStore = useFileUploadQueueStore();

      const queuedFileUploads = files.map((file) => {
        /**
         * The relative path of the file includes the filename with the file extension.
         * To only get the directory path everything after the last slash gets removed.
         */
        const relativeDirectoryPath = file.relativePath
          ? file.relativePath.substring(0, file.relativePath.lastIndexOf('/'))
          : '/';

        const command: UploadDueDiligenceFileWithPathCommand = {
          body: {
            productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
            relativeDirectoryPath,
            visibleForClientLanguages: file.visibleForClientLanguages,
          },
          files: { file },
        };

        if (this.dueDiligenceDataRoomDirectoryId) {
          command.body.parentDirectoryId = this.dueDiligenceDataRoomDirectoryId;
        }

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: uploadDueDiligenceFileWithPath,
          command,
        };

        return fileUploadQueueStore.addItem(payload)
          .then(() => this.getUNodesForDueDiligenceDataRoom());
      });

      const { uploadDueDiligenceFilesWithPathStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        uploadDueDiligenceFilesWithPathStatus,
        () => Promise.all(queuedFileUploads)
      );
    },

    updateDueDiligenceDirectoryNameAndVisibleLanguagesForClient(
      command: UpdateDueDiligenceDirectoryNameAndVisibleLanguagesForClientCommand
    ): Promise<void> {
      const { updateDueDiligenceDirectoryNameAndVisibleLanguagesForClientStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDueDiligenceDirectoryNameAndVisibleLanguagesForClientStatus,
        () => updateDueDiligenceDirectoryNameAndVisibleLanguagesForClient(command)
          .then(() => this.getUNodesForDueDiligenceDataRoom())
      );
    },

    deleteDueDiligenceFile(command: DeleteDueDiligenceFileCommand): Promise<void> {
      const { deleteDueDiligenceFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteDueDiligenceFileStatus,
        () => deleteDueDiligenceFile(command)
          .then(() => this.getUNodesForDueDiligenceDataRoom())
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    deleteDueDiligenceDirectory(command: DeleteDueDiligenceDirectoryCommand): Promise<void> {
      const { deleteDueDiligenceDirectoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteDueDiligenceDirectoryStatus,
        () => deleteDueDiligenceDirectory(command)
          .then(() => this.getUNodesForDueDiligenceDataRoom())
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    sendMessageToInvestorUsers(command: SendMessageToInvestorUsersCommand): Promise<void> {
      const { sendMessageToInvestorUsersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        sendMessageToInvestorUsersStatus,
        () => sendMessageToInvestorUsers(command)
      );
    },

    updateUsersWithExplicitAccessForFile(command: UpdateUsersWithExplicitAccessForFileCommand): Promise<void> {
      const { updateUsersWithExplicitAccessForFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUsersWithExplicitAccessForFileStatus,
        () => updateUsersWithExplicitAccessForFile(command)
          .then(() => this.getUNodesForDueDiligenceDataRoom())
      );
    },

    createProductNDA(file: File): Promise<void> {
      this.createProductNDAStatus = ActionStatus.InProgress;

      const command: CreateProductNDACommand = {
        body: {
          productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
          visibleForClientLanguage: useGeneralPartnerProductManagementGeneralStore().selectedLanguage,
        },
        files: { productNDA: file },
      };

      const payload: AddItemToUploadQueuePayload = {
        fileName: file.name,
        serviceFunction: createProductNDA,
        command,
      };

      return useFileUploadQueueStore().addItem(payload)
        .then(() => this.getUNodesForDueDiligenceDataRoom())
        .then(() => {
          this.createProductNDAStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createProductNDAStatus = ActionStatus.Failed;

          return Promise.reject(error);
        }) || Promise.resolve();
    },

    deleteProductNDA(command: DeleteProductNDACommand): Promise<void> {
      const { deleteProductNDAStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductNDAStatus,
        () => deleteProductNDA(command)
          .then(() => this.getUNodesForDueDiligenceDataRoom())
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

  },
});
