var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"max-width":_vm.menuWidth,"min-width":_vm.menuWidth,"max-height":_vm.menuMaxHeight,"offset-y":"","nudge-bottom":6,"close-on-content-click":false,"content-class":"summary-item-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.TooltipWidth.SMALL,"disabled":!_vm.store.isChangingProductDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, tooltip)),[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":"","disabled":_vm.store.isChangingProductDisabled}},'v-btn',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(_vm.translate('openMenu'))+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('domain.generalPartner.products.productManagement.noProductUpdateAllowedTooltip'))+" ")])]}}]),model:{value:(_vm.isMenuVisible),callback:function ($$v) {_vm.isMenuVisible=$$v},expression:"isMenuVisible"}},[_c('div',{attrs:{"data-cy":"add-summary-item-menu"}},[_c('div',{staticClass:"search-container pa-2"},[_c('v-text-field',{attrs:{"label":_vm.translate('searchLabel'),"placeholder":_vm.translate('searchLabel'),"solo":"","hide-details":"","dense":"","flat":"","outlined":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('font-awesome-icon',{staticClass:"search-icon grey--text text--darken-2",attrs:{"icon":['far', 'search']}})]},proxy:true}]),model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[(_vm.store.areUnselectedSummaryItemsAvailable)?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.translate('allItemsAdded'))+" ")])],1):_vm._e(),_vm._l((_vm.filteredSummaryItems),function(option){return _c('v-list-item',{key:option.value.kpiId,on:{"change":function($event){return _vm.summaryChanged(option.value)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(option.text))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.isSelected(option.value)}})],1)]}}],null,true)})})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }