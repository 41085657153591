import axios, { AxiosRequestConfig, AxiosRequestHeaders, CancelTokenSource } from 'axios';
import merge from 'lodash-es/merge';
import { CommandWithFiles, FileResponse } from '@/application/types';
import { fromJSON, toJSON } from '@/helpers/json-helpers';

// At the moment the api url is empty as we have different hosts depending on the general partner
export const appUrl = '';

export function generateApiUrl(requestUri: string): string {
  return `${appUrl}${requestUri}`;
}

// eslint-disable-next-line no-unused-vars
function transformRequest(data: unknown, headers?: AxiosRequestHeaders): unknown {
  // Catch calls of transform function with unexpected payload (string|undefined) to avoid double-encoding.
  if (typeof data !== 'object'
    || data === null
  ) {
    return data;
  }

  return toJSON(data);
}

// eslint-disable-next-line no-unused-vars
function transformRequestWithFiles(command: CommandWithFiles, headers?: AxiosRequestHeaders): unknown {
  const data = new FormData();
  data.append('body', toJSON(command.body) as string);
  Object.keys(command.files).forEach((key) => {
    const file = command.files[key];
    // We need to filter files with name `body` as they otherwise would overwrite the other JSON serialized content we want to send
    if (key !== 'body' && file) {
      data.append(key, file);
    }
  });

  return data;
}

// eslint-disable-next-line no-unused-vars
function transformResponse(data: string, headers?: AxiosRequestHeaders): string {
  return (data.length > 0)
    ? fromJSON(data)
    : data;
}

function defaultHeaders(): AxiosRequestHeaders {
  return {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
}

function formDataHeaders(): AxiosRequestHeaders {
  return {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'multipart/form-data',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
}

function buildRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: {
      ...defaultHeaders(),
      ...config.headers,
    },
    transformRequest,
    transformResponse,
    withCredentials: true,
  };

  return merge(defaults, config);
}

function buildRequestWithFiles(config: AxiosRequestConfig): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: {
      ...formDataHeaders(),
      ...config.headers,
    },
    transformRequest: transformRequestWithFiles,
    transformResponse,
    withCredentials: true,
  };

  return merge(defaults, config);
}

function buildRequestForBinary(config: AxiosRequestConfig): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: {
      ...defaultHeaders(),
      ...config.headers,
    },
    transformRequest,
    withCredentials: true,
    responseType: 'blob',
  };

  return merge(defaults, config);
}

export function performApiRequest<T = void>(config: AxiosRequestConfig): Promise<T> {
  return axios.request(buildRequest(config))
    .then((response) => response.data);
}

const cancelTokens: Record<string, CancelTokenSource> = {};

export function performApiRequestWithPreviousCancellation<T = void>(config: AxiosRequestConfig): Promise<T> {
  const url = config.url!;

  // Cancel previous request if there is one for the url
  const cancelTokenForRequest = cancelTokens[url];
  if (cancelTokenForRequest) {
    cancelTokenForRequest.cancel(url);
  }

  // Create new cancel token for url and attach to request
  const cancelTokenSource = axios.CancelToken.source();
  const cancelToken = cancelTokenSource.token;

  cancelTokens[url] = cancelTokenSource;

  const request = {
    ...buildRequest(config),
    cancelToken,
  };

  return axios.request(request)
    .then((response) => {
      delete cancelTokens[url];
      return response.data;
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        delete cancelTokens[url];
      }
      return Promise.reject(error);
    });
}

export function performApiRequestWithFiles<T = void>(config: AxiosRequestConfig): Promise<T> {
  return axios.request(buildRequestWithFiles(config))
    .then((response) => response.data);
}

export function performUnauthorizedApiRequest<T>(request: AxiosRequestConfig): Promise<T> {
  return axios.request(request)
    .then((response) => response.data);
}

export function performApiRequestForFile(config: AxiosRequestConfig): Promise<FileResponse> {
  return axios.request(buildRequestForBinary(config))
    .then((response) => ({
      data: response.data,
      contentType: response.headers['content-type'],
    }))
    .catch(async (error) => {
      if (error.response.data instanceof Blob) {
        error.response.data = JSON.parse(await error.response.data.text());
      }

      return Promise.reject(error);
    });
}

export function isCancelledError(error: any): boolean {
  return axios.isCancel(error);
}
