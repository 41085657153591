import { Language } from '@/types/global';
import { languageSelectItems } from '@/helpers/form-helpers';

export function languageName(shortCode: Language): string {

  const language = languageSelectItems().find((language) => language.value === shortCode);
  if (!language) {
    return shortCode;
  }

  return language.text.toString();
}
