import { render, staticRenderFns } from "./investments-tab.vue?vue&type=template&id=84d66d32&scoped=true&"
import script from "./investments-tab.vue?vue&type=script&lang=ts&"
export * from "./investments-tab.vue?vue&type=script&lang=ts&"
import style0 from "./investments-tab.vue?vue&type=style&index=0&id=84d66d32&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84d66d32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAlert,VDataTable,VList,VListItem,VListItemIcon,VListItemTitle,VMenu})
