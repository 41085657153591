import { render, staticRenderFns } from "./general-partner-permissions-form-fields.vue?vue&type=template&id=5f7362d2&"
import script from "./general-partner-permissions-form-fields.vue?vue&type=script&lang=ts&"
export * from "./general-partner-permissions-form-fields.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
