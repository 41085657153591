import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { CreateKYCDocumentAttachmentForInvestmentCommand, CreateSubscriptionDocumentForInvestmentCommand, GetKYCDocumentAttachmentFileForDownloadQuery, GetKYCDocumentAttachmentsForInvestmentQuery, GetSubscriptionDocumentFileForDownloadQuery, GetSubscriptionDocumentsForInvestmentQuery, KYCDocumentAttachment, SubscriptionDocument, DeleteKYCDocumentAttachmentForInvestmentCommand, DeleteSubscriptionDocumentForInvestmentCommand } from './types';

// Commands

export function createSubscriptionDocumentForInvestment(
  command: CreateSubscriptionDocumentForInvestmentCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-subscription-document-for-investment-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteSubscriptionDocumentForInvestment(command: DeleteSubscriptionDocumentForInvestmentCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-subscription-document-for-investment-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createKYCDocumentAttachmentForInvestment(
  command: CreateKYCDocumentAttachmentForInvestmentCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-kyc-document-attachment-for-investment-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteKYCDocumentAttachmentForInvestment(command: DeleteKYCDocumentAttachmentForInvestmentCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-kyc-document-attachment-for-investment-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

// Queries

export function getSubscriptionDocumentsForInvestment(query: GetSubscriptionDocumentsForInvestmentQuery): Promise<SubscriptionDocument[]> {
  const url = generateApiUrl('/api/general-partner/get-subscription-documents-for-investment-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getKYCDocumentAttachmentsForInvestment(
  query: GetKYCDocumentAttachmentsForInvestmentQuery
): Promise<KYCDocumentAttachment[]> {
  const url = generateApiUrl('/api/general-partner/get-kyc-document-attachments-for-investment-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionDocumentFileForDownload(query: GetSubscriptionDocumentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-subscription-document-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getKYCDocumentAttachmentFileForDownload(query: GetKYCDocumentAttachmentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-kyc-document-attachment-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
