import { RouteConfig } from 'vue-router';
import { AccountTypes, Features } from '@/types/global';
import KYCManagement from './components/kyc-management.vue';

export const investorKYCManagementRoutes: RouteConfig[] = [
  {
    name: 'investor-kyc-management',
    path: '/investor/kyc-management',
    component: KYCManagement,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.INVESTOR,
      requiresFeatures: [Features.SUBSCRIPTION_PROCESS],
    },
  },
];
