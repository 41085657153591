import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { GetInvestorDetailsQuery, InvestorDetails, UpdateInvestorDetailsCommand, GetInvestorContactsQuery, InvestorContact } from './types';
import { getInvestorDetails, updateInvestorDetails, getInvestorContacts } from './service';

interface GeneralPartnerUpdateInvestorDetailsState {
  investorId: string | null;
  investorDetails: InvestorDetails | null;
  investorContacts: InvestorContact[];

  getInvestorDetailsStatus: ActionStatus;
  getProductsWithAccessForInvestorStatus: ActionStatus;
  updateInvestorDetailsStatus: ActionStatus;
  getInvestorContactsStatus: ActionStatus;
}

function initialState(): GeneralPartnerUpdateInvestorDetailsState {
  return {
    investorId: null,
    investorDetails: null,
    investorContacts: [],

    getInvestorDetailsStatus: ActionStatus.None,
    getProductsWithAccessForInvestorStatus: ActionStatus.None,
    updateInvestorDetailsStatus: ActionStatus.None,
    getInvestorContactsStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerUpdateInvestorDetailsStore = defineStore('generalPartnerUpdateInvestorDetails', {
  state: (): GeneralPartnerUpdateInvestorDetailsState => initialState(),
  getters: {
    isGetInvestorDetailsProcessing: (state: GeneralPartnerUpdateInvestorDetailsState): boolean =>
      state.getInvestorDetailsStatus === ActionStatus.InProgress,
    isGetProductsWithAccessForInvestorProcessing: (state: GeneralPartnerUpdateInvestorDetailsState): boolean =>
      state.getProductsWithAccessForInvestorStatus === ActionStatus.InProgress,
    isUpdateInvestorDetailsProcessing: (state: GeneralPartnerUpdateInvestorDetailsState): boolean =>
      state.updateInvestorDetailsStatus === ActionStatus.InProgress,
    isGetInvestorContactsProcessing: (state: GeneralPartnerUpdateInvestorDetailsState): boolean =>
      state.getInvestorContactsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentInvestorId(currentInvestorId: string): Promise<void> {
      this.investorId = currentInvestorId;

      return Promise.resolve();
    },

    createInvestorContact(contact: InvestorContact): Promise<void> {
      this.investorContacts.push(contact);

      return Promise.resolve();
    },

    updateInvestorContact(contact: InvestorContact): Promise<void> {
      const index = this.investorContacts.findIndex((investorContact) => investorContact.id === contact.id);
      this.investorContacts.splice(index, 1, contact);

      return Promise.resolve();
    },

    deleteInvestorContact(contactId: string): Promise<void> {
      const index = this.investorContacts.findIndex((investorContact) => investorContact.id === contactId);
      this.investorContacts.splice(index, 1);

      return Promise.resolve();
    },

    resetInvestorContacts(): Promise<void> {
      this.investorContacts = [];

      return Promise.resolve();
    },

    // -- Queries

    getInvestorDetails(): Promise<void> {
      const query: GetInvestorDetailsQuery = {
        investorId: this.investorId!,
      };

      const { getInvestorDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorDetailsStatus,
        () => getInvestorDetails(query)
          .then((investorDetails) => {
            this.investorDetails = investorDetails;
          })
      );
    },

    getInvestorContacts(): Promise<void> {
      const query: GetInvestorContactsQuery = {
        investorId: this.investorId!,
      };

      const { getInvestorContactsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorContactsStatus,
        () => getInvestorContacts(query)
          .then((contacts) => {
            this.investorContacts = contacts;
          })
      );
    },

    // -- Commands

    updateInvestorDetails(command: UpdateInvestorDetailsCommand): Promise<void> {
      const { updateInvestorDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestorDetailsStatus,
        () => updateInvestorDetails(command)
          .then(() => this.getInvestorDetails())
      );
    },

  },
});
