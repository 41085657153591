import { RouteConfig } from 'vue-router';
import NotificationCenter from './components/notification-center.vue';
import NotificationNoSelection from './components/notification-no-selection.vue';
import NotificationDetails from './components/notification-details.vue';

export const commonNotificationCenterRoutes: RouteConfig[] = [
  {
    path: '/notification-center',
    component: NotificationCenter,
    children: [
      {
        path: '',
        redirect: 'no-selection',
      }, {
        name: 'notification-center-no-selection',
        path: 'no-selection',
        component: NotificationNoSelection,
        meta: {
          requiresAuth: true,
          needsReferrer: true,
        },
      }, {
        name: 'notification-center-details',
        path: ':notificationId',
        component: NotificationDetails,
        meta: {
          requiresAuth: true,
          needsReferrer: true,
        },
      },
    ],
  },
];
