var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"subscription-processes"}},[_c('div',{staticClass:"table-container px-4 py-8 py-sm-4 mx-n4 mx-md-0"},[_c('a-headline',{attrs:{"medium":"","title":_vm.translate('title')}}),(!_vm.store.isGetSubscriptionProcessesProcessing)?_c('div',[_c('v-data-table',{staticClass:"clickable-rows transparent",attrs:{"disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.store.subscriptionProcesses,"item-key":"subscriptionProcessId","loading":_vm.store.isGetSubscriptionProcessesProcessing,"mobile-breakpoint":960},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row my-3"},[_c('a-img',{staticClass:"product-image mt-3 mt-sm-0 mr-sm-4",attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(_vm._s(item.productName))]),_c('div',{staticClass:"body-3"},[_vm._v(" "+_vm._s(_vm.$t(("helpers.data.assetClasses." + (item.assetClass))))+" • "+_vm._s(_vm.$t(("helpers.data.subAssetClasses." + (item.subAssetClass))))+" "),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("• "+_vm._s(_vm.$t(("helpers.data.investmentStructures." + (item.investmentStructure)))))])])])],1)]}},{key:"item.investmentAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-md-right body-2 black--text"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.investmentAmount,item.currency, true))+" ")])]}},{key:"item.submittedOn",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-2 black--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSubscriptionProcessDialogVisible(item))?_c('product-subscription-process-dialog',{attrs:{"product-id":item.productId},on:{"subscription-process-dialog-closed":function($event){return _vm.subscriptionProcessDialogClosed()}}},[_c('a-icon-button',{attrs:{"small":"","icon":['fas', 'chevron-right'],"tooltip":_vm.getSubscribeButtonTooltip(item)}})],1):_vm._e()]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"totals-row d-flex flex-column d-md-table-row"},[_c('td',{staticClass:"d-none d-md-table-cell"},[_c('div',{staticClass:"body-2 black--text"},[_vm._v(" "+_vm._s(_vm.translate('table.total'))+" ")])]),_c('td',{staticClass:"text-right pt-2 pt-md-0"},[_c('div',{staticClass:"d-flex justify-space-between justify-md-end"},[_c('div',{staticClass:"d-md-none body-3 black--text"},[_vm._v(_vm._s(_vm.translate('table.total')))]),_c('div',_vm._l((_vm.store.investmentAmountsByCurrency),function(value,key){return _c('div',{staticClass:"body-2 black--text"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm._f("convertDecimalValueToNumber")(("" + value),_vm.DECIMALS.SUBSCRIPTION_PROCESS.investmentAmount),key))+" ")])}),0)])])])]},proxy:true}],null,false,9637909)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }