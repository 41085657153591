import { RouteConfig } from 'vue-router';
import DefaultRedirect from '@/application/whitelabel/default-redirect/components/default-redirect.vue';
import { legalRoutes } from '@/application/whitelabel/legal/routes';
import { authenticationRoutes } from '@/application/whitelabel/authentication/routes';
import { generalPartnerProductsRoutes } from '@/domain/general-partner/products/routes';
import { investorDashboardRoutes } from '@/domain/investor/dashboard/routes';
import { commonProfileRoutes } from '@/domain/common/profile/routes';
import { investorProductsRoutes } from '@/domain/investor/products/routes';
import { generalPartnerUsersRoutes } from '@/domain/general-partner/users/routes';
import { generalPartnerInvestorsRoutes } from '@/domain/general-partner/investors/routes';
import { commonNotificationCenterRoutes } from '@/domain/common/notification-center/routes';
import { generalPartnerInvestmentsRoutes } from '@/domain/general-partner/investments/routes';
import { createGeneralPartnerUserAccountRoutes } from '@/domain/general-partner/create-account/routes';
import { createInvestorUserAccountRoutes } from '@/domain/investor/create-account/routes';
import { investorKYCManagementRoutes } from '@/domain/investor/kyc-management/routes';
import { generalPartnerNetworkRoutes } from '@/domain/general-partner/network/routes';

export const routes: RouteConfig[] = [
  {
    name: 'root',
    path: '/',
    redirect: { name: 'authentication/login' },
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
    },
  },
  {
    name: 'default',
    path: '/default',
    component: DefaultRedirect,
    meta: {
      requiresAuth: true,
    },
  },
  ...legalRoutes,
  ...authenticationRoutes,
  ...investorDashboardRoutes,
  ...commonProfileRoutes,
  ...commonNotificationCenterRoutes,
  ...investorProductsRoutes,
  ...generalPartnerProductsRoutes,
  ...generalPartnerUsersRoutes,
  ...generalPartnerInvestorsRoutes,
  ...generalPartnerInvestmentsRoutes,
  ...generalPartnerNetworkRoutes,
  ...createGeneralPartnerUserAccountRoutes,
  ...createInvestorUserAccountRoutes,
  ...investorKYCManagementRoutes,
];
