import { RawLocation } from 'vue-router';
import { router } from '@/router/whitelabel';

export function navigate(location: RawLocation): void {
  router.push(location)
    .catch((error: Error) => {
      if (!error || error.name === 'NavigationDuplicated') {
        return;
      }

      throw error;
    });
}
