import { RouteConfig } from 'vue-router';
import DefaultRedirect from '@/application/brain/default-redirect/components/default-redirect.vue';
import { authenticationRoutes } from '@/application/brain/authentication/routes';
import { generalPartnersRoutes } from '@/domain/brain/general-partners/routes';
import { platformNewsRoutes } from '@/domain/brain/platform-news/routes';
import { termSheetTemplatesRoutes } from '@/domain/brain/term-sheet-templates/routes';
import { subscriptionDocumentTemplatesRoutes } from '@/domain/brain/subscription-document-templates/routes';
import { networkProductsRoutes } from '@/domain/brain/network-products/routes';

export const routes: RouteConfig[] = [
  {
    name: 'root',
    path: '/',
    redirect: { name: 'authentication/login' },
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
    },
  },
  {
    name: 'default',
    path: '/default',
    component: DefaultRedirect,
    meta: {
      requiresAuth: true,
    },
  },
  ...authenticationRoutes,
  ...generalPartnersRoutes,
  ...platformNewsRoutes,
  ...termSheetTemplatesRoutes,
  ...subscriptionDocumentTemplatesRoutes,
  ...networkProductsRoutes,
];
