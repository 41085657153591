import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { KPI, KPIValue, Language } from '@/types/global';

export interface Command {}
export interface Query {}

export interface CommandWithFiles {
  body: Record<string, unknown>;
  files: Partial<Record<string, File>>;
}

export interface FileResponse {
  data: File;
  contentType: string;
}

export enum ActionStatus {
  None,
  InProgress,
  Successful,
  Failed,
}

// Stricter than InputValidationRule from vuetify/types
export type FormValidationRule<T> = (value: T) => boolean|TranslateResult;

export interface FormField<T> {
  id?: string;
  label?: TranslateResult;
  value: T;
  rules: FormValidationRule<T>[];
  isIndicatedAsRequired?: boolean | (() => boolean);
}

export type KPIFormFieldRules<T> = FormValidationRule<T>[] | Record<Language, FormValidationRule<T>[]> | KPIFormFieldRangeRules<T>;
export interface KPIFormFieldRangeRules<T> {
  from: FormValidationRule<T>[];
  to: FormValidationRule<T>[];
}
export interface KPIFormField {
  value: KPI;
  rules: KPIFormFieldRules<KPIValue>;
  isIndicatedAsRequired: boolean | (() => boolean);
}

export interface DynamicFormDefinition {
  valid: boolean;
  fields: KPIFormField[] | Record<string, KPIFormField[]>;
}

export type StrictFormFieldType<T> =
  | FormField<T>
  | FormField<T>[]
  | Record<string, FormField<T>[]>
  | Record<string, FormField<T>>[];

export interface StrictFormDefinition {
  valid: boolean;
  fields?: Record<string, StrictFormFieldType<any>>;
}

// Stand-in for Validatable from vuetify/lib/mixins/validatable
export interface VuetifyValidatable {
  errorCount: number;
  errorBucket: string[];
  inputs: Vue[];
  validate(force?: boolean): () => boolean;
  resetValidation(): () => void;
}

// Stand-in for Overlayable from vuetify/lib/mixins/overlayable
export interface VuetifyOverlayable {
  showScroll(): () => void;
}

// Structure expected by VSelect when not providing callbacks
export interface VuetifySelectItem<T> {
  text: string | TranslateResult;
  value: T;
}

export interface VuetifyTreeView {
  updateAll: (val: boolean) => void;
}
