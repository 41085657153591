import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { useGeneralPartnerProductManagementGeneralStore } from '../../store';
import { FundraisingAnalytics, FundraisingOverview, Investor, GetFundraisingAnalyticsForProductQuery, GetFundraisingOverviewForProductQuery, GetTopTenInvestorsForProductQuery } from './types';
import { getFundraisingAnalyticsForProduct, getFundraisingOverviewForProduct, getTopTenInvestorsForProduct } from './service';

interface GeneralPartnerProductManagementAnalyticsTabState {
  fundraisingOverview: FundraisingOverview | null;
  fundraisingAnalytics: FundraisingAnalytics | null;
  topTenInvestors: Investor[];

  getFundraisingOverviewForCurrentProductStatus: ActionStatus;
  getFundraisingAnalyticsForCurrentProductStatus: ActionStatus;
  getTopTenInvestorsForCurrentProductStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementAnalyticsTabState {
  return {
    fundraisingOverview: null,
    fundraisingAnalytics: null,
    topTenInvestors: [],

    getFundraisingOverviewForCurrentProductStatus: ActionStatus.None,
    getFundraisingAnalyticsForCurrentProductStatus: ActionStatus.None,
    getTopTenInvestorsForCurrentProductStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementAnalyticsTabStore = defineStore('generalPartnerProductManagementAnalyticsTab', {
  state: (): GeneralPartnerProductManagementAnalyticsTabState => initialState(),
  getters: {
    isGetFundraisingOverviewForCurrentProductProcessing: (state: GeneralPartnerProductManagementAnalyticsTabState): boolean =>
      state.getFundraisingOverviewForCurrentProductStatus === ActionStatus.InProgress,
    isGetFundraisingAnalyticsForCurrentProductProcessing: (state: GeneralPartnerProductManagementAnalyticsTabState): boolean =>
      state.getFundraisingAnalyticsForCurrentProductStatus === ActionStatus.InProgress,
    isGetTopTenInvestorsForCurrentProductProcessing: (state: GeneralPartnerProductManagementAnalyticsTabState): boolean =>
      state.getTopTenInvestorsForCurrentProductStatus === ActionStatus.InProgress,

    hasFundraisingAnalytics: (state: GeneralPartnerProductManagementAnalyticsTabState): boolean =>
      !!state.fundraisingAnalytics,
    hasFundraisingOverview: (state: GeneralPartnerProductManagementAnalyticsTabState): boolean =>
      !!state.fundraisingOverview,
    committedCapitalInPercent: (state: GeneralPartnerProductManagementAnalyticsTabState): number | null => {
      if (!state.fundraisingOverview) {
        return null;
      }

      return state.fundraisingOverview.committedCapitalInPercent
        ? state.fundraisingOverview.committedCapitalInPercent.toNumber()
        : 0;
    },
    isCapitalMissing: (state: GeneralPartnerProductManagementAnalyticsTabState): boolean => {
      if (!state.fundraisingOverview) {
        return true;
      }

      return state.fundraisingOverview.committedCapitalInPercent
        ? state.fundraisingOverview.committedCapitalInPercent.toNumber() < 100
        : true;
    },
  },
  actions: {

    // -- Queries

    getFundraisingOverviewForCurrentProduct(): Promise<void> {
      const query: GetFundraisingOverviewForProductQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
      };

      const { getFundraisingOverviewForCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFundraisingOverviewForCurrentProductStatus,
        () => getFundraisingOverviewForProduct(query)
          .then((fundraisingOverview) => {
            this.fundraisingOverview = fundraisingOverview;
          })
      );
    },

    getFundraisingAnalyticsForCurrentProduct(pageUrl: string): Promise<void> {
      const query: GetFundraisingAnalyticsForProductQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
        pageUrl,
      };

      const { getFundraisingAnalyticsForCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFundraisingAnalyticsForCurrentProductStatus,
        () => getFundraisingAnalyticsForProduct(query)
          .then((fundraisingAnalytics) => {
            this.fundraisingAnalytics = fundraisingAnalytics;
          })
      );
    },

    getTopTenInvestorsForCurrentProduct(): Promise<void> {
      const query: GetTopTenInvestorsForProductQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
      };

      const { getTopTenInvestorsForCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTopTenInvestorsForCurrentProductStatus,
        () => getTopTenInvestorsForProduct(query)
          .then((topTenInvestors) => {
            this.topTenInvestors = topTenInvestors;
          })
      );
    },

  },
});
