import { defineStore } from 'pinia';
import { serializer } from '@/store';

interface ImpersonationHintState {
  isImpersonationActive: boolean;
}

function initialState(): ImpersonationHintState {
  return {
    isImpersonationActive: false,
  };
}

export const useImpersonationHintStore = defineStore('impersonationHint', {
  state: (): ImpersonationHintState => initialState(),
  persist: {
    serializer,
  },
  actions: {

    // -- State management

    activateHint(): Promise<void> {
      this.isImpersonationActive = true;

      return Promise.resolve();
    },

  },
});
