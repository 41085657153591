import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { countrySelectItems } from '@/helpers/form-helpers';
import { Languages } from '@/types/global';
import { wrapActionWithProgress } from '@/store';
import { CancelPhoneNumberVerificationForTwoFactorAuthenticationCommand, ConfirmEmailAddressChangeCommand, ConfirmPhoneNumberForTwoFactorAuthenticationCommand, DeleteVerifiedPhoneNumberForTwoFactorAuthenticationCommand, PhoneNumberVerificationStep, PhoneNumberVerificationSteps, Profile, RequestEmailAddressChangeCommand, StartPhoneNumberVerificationForTwoFactorAuthenticationCommand, UpdateCountryCommand, UpdateNameCommand, UpdatePasswordCommand, UpdatePhoneNumberCommand } from './types';
import { cancelPhoneNumberVerificationForTwoFactorAuthentication, confirmEmailAddressChange, confirmPhoneNumberForTwoFactorAuthentication, deleteVerifiedPhoneNumberForTwoFactorAuthentication, getProfile, requestEmailAddressChange, startPhoneNumberVerificationForTwoFactorAuthentication, updateCountry, updateName, updatePassword, updatePhoneNumber } from './service';

interface ProfileState {
  profile: Profile | null;
  currentPhoneNumberVerificationForTwoFactorAuthenticationStep: PhoneNumberVerificationStep;

  getProfileStatus: ActionStatus;
  requestEmailAddressChangeStatus: ActionStatus;
  confirmEmailAddressChangeStatus: ActionStatus;
  updatePasswordStatus: ActionStatus;
  updateNameStatus: ActionStatus;
  updatePhoneNumberStatus: ActionStatus;
  updateCountryStatus: ActionStatus;
  startPhoneNumberVerificationForTwoFactorAuthenticationStatus: ActionStatus;
  confirmPhoneNumberForTwoFactorAuthenticationStatus: ActionStatus;
  deleteVerifiedPhoneNumberForTwoFactorAuthenticationStatus: ActionStatus;
  cancelPhoneNumberVerificationForTwoFactorAuthenticationStatus: ActionStatus;
}

function initialState(): ProfileState {
  return {
    profile: null,
    currentPhoneNumberVerificationForTwoFactorAuthenticationStep: PhoneNumberVerificationSteps.REQUEST,

    getProfileStatus: ActionStatus.None,
    requestEmailAddressChangeStatus: ActionStatus.None,
    confirmEmailAddressChangeStatus: ActionStatus.None,
    updatePasswordStatus: ActionStatus.None,
    updateNameStatus: ActionStatus.None,
    updatePhoneNumberStatus: ActionStatus.None,
    updateCountryStatus: ActionStatus.None,
    startPhoneNumberVerificationForTwoFactorAuthenticationStatus: ActionStatus.None,
    confirmPhoneNumberForTwoFactorAuthenticationStatus: ActionStatus.None,
    deleteVerifiedPhoneNumberForTwoFactorAuthenticationStatus: ActionStatus.None,
    cancelPhoneNumberVerificationForTwoFactorAuthenticationStatus: ActionStatus.None,
  };
}

export const useProfileStore = defineStore('profile', {
  state: (): ProfileState => initialState(),
  getters: {
    isGetProfileProcessing: (state: ProfileState): boolean =>
      state.getProfileStatus === ActionStatus.InProgress,
    isRequestEmailAddressChangeProcessing: (state: ProfileState): boolean =>
      state.requestEmailAddressChangeStatus === ActionStatus.InProgress,
    isConfirmEmailAddressChangeProcessing: (state: ProfileState): boolean =>
      state.confirmEmailAddressChangeStatus === ActionStatus.InProgress,
    isUpdatePasswordProcessing: (state: ProfileState): boolean =>
      state.updatePasswordStatus === ActionStatus.InProgress,
    isUpdateNameProcessing: (state: ProfileState): boolean =>
      state.updateNameStatus === ActionStatus.InProgress,
    isUpdatePhoneNumberProcessing: (state: ProfileState): boolean =>
      state.updatePhoneNumberStatus === ActionStatus.InProgress,
    isUpdateCountryProcessing: (state: ProfileState): boolean =>
      state.updateCountryStatus === ActionStatus.InProgress,
    isStartPhoneNumberVerificationForTwoFactorAuthenticationProcessing: (state: ProfileState): boolean =>
      state.startPhoneNumberVerificationForTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    isConfirmPhoneNumberForTwoFactorAuthenticationProcessing: (state: ProfileState): boolean =>
      state.confirmPhoneNumberForTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    isDeleteVerifiedPhoneNumberForTwoFactorAuthenticationProcessing: (state: ProfileState): boolean =>
      state.deleteVerifiedPhoneNumberForTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    isCancelPhoneNumberVerificationForTwoFactorAuthenticationProcessing: (state: ProfileState): boolean =>
      state.cancelPhoneNumberVerificationForTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    userImageUrl: (state: ProfileState): string|undefined =>
      state.profile
        ? state.profile.imageUrl
        : undefined,
    isProfileAvailable: (state: ProfileState): boolean =>
      !!state.profile,
    fullName: (state: ProfileState): string =>
      state.profile
        ? `${state.profile.name.firstName} ${state.profile.name.lastName}`
        : '',
    countryName: (state: ProfileState): string => {
      const shortCode = state.profile
        ? state.profile.countryOfResidence
        : Languages.en;
      const country = countrySelectItems().find((language) => language.value === shortCode);
      if (!country) {
        return shortCode;
      }

      return country.text.toString();
    },
    hasVerifiedPhoneNumberForTwoFactorAuthentication: (state: ProfileState): boolean =>
      !!state.profile
      && !!state.profile.verifiedPhoneNumberForTwoFactorAuthentication,
  },
  actions: {

    // -- Queries

    getProfile(): Promise<void> {
      const { getProfileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProfileStatus,
        () => getProfile()
          .then((profile) => {
            this.profile = profile;
            this.currentPhoneNumberVerificationForTwoFactorAuthenticationStep = !profile.phoneNumberVerificationRequest
              ? PhoneNumberVerificationSteps.REQUEST
              : PhoneNumberVerificationSteps.CONFIRM;
          })
      );
    },

    // -- Commands

    requestEmailAddressChange(command: RequestEmailAddressChangeCommand): Promise<void> {
      const { requestEmailAddressChangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestEmailAddressChangeStatus,
        () => requestEmailAddressChange(command)
      );
    },

    confirmEmailAddressChange(command: ConfirmEmailAddressChangeCommand): Promise<void> {
      const { confirmEmailAddressChangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmEmailAddressChangeStatus,
        () => confirmEmailAddressChange(command)
      );
    },

    updatePassword(command: UpdatePasswordCommand): Promise<void> {
      const { updatePasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePasswordStatus,
        () => updatePassword(command)
      );
    },

    updateName(command: UpdateNameCommand): Promise<void> {
      const { updateNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameStatus,
        () => updateName(command)
          .then(() => this.getProfile())
      );
    },

    updatePhoneNumber(command: UpdatePhoneNumberCommand): Promise<void> {
      const { updatePhoneNumberStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePhoneNumberStatus,
        () => updatePhoneNumber(command)
          .then(() => this.getProfile())
      );
    },

    updateCountry(command: UpdateCountryCommand): Promise<void> {
      const { updateCountryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCountryStatus,
        () => updateCountry(command)
          .then(() => this.getProfile())
      );
    },

    startPhoneNumberVerificationForTwoFactorAuthentication(
      command: StartPhoneNumberVerificationForTwoFactorAuthenticationCommand
    ): Promise<void> {
      const { startPhoneNumberVerificationForTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        startPhoneNumberVerificationForTwoFactorAuthenticationStatus,
        () => startPhoneNumberVerificationForTwoFactorAuthentication(command)
          .then(() => this.getProfile())
      );
    },

    confirmPhoneNumberForTwoFactorAuthentication(
      command: ConfirmPhoneNumberForTwoFactorAuthenticationCommand
    ): Promise<void> {
      const { confirmPhoneNumberForTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmPhoneNumberForTwoFactorAuthenticationStatus,
        () => confirmPhoneNumberForTwoFactorAuthentication(command)
          .then(() => this.getProfile())
      );
    },

    deleteVerifiedPhoneNumberForTwoFactorAuthentication(
      command: DeleteVerifiedPhoneNumberForTwoFactorAuthenticationCommand
    ): Promise<void> {
      const { deleteVerifiedPhoneNumberForTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteVerifiedPhoneNumberForTwoFactorAuthenticationStatus,
        () => deleteVerifiedPhoneNumberForTwoFactorAuthentication(command)
          .then(() => this.getProfile())
      );
    },

    cancelPhoneNumberVerificationForTwoFactorAuthentication(
      command: CancelPhoneNumberVerificationForTwoFactorAuthenticationCommand
    ): Promise<void> {
      const { cancelPhoneNumberVerificationForTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelPhoneNumberVerificationForTwoFactorAuthenticationStatus,
        () => cancelPhoneNumberVerificationForTwoFactorAuthentication(command)
          .then(() => this.getProfile())
      );
    },

  },
});
