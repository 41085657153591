import { RouteConfig } from 'vue-router';
import { AccountTypes, Features, GeneralPartnerPermissions } from '@/types/global';
import InvestmentsTabs from './investments-tabs/components/investments-tabs.vue';
import InvestmentsTab from './investments-tabs/components/investments-tab.vue';
import SubscriptionProcessesTab from './investments-tabs/components/subscription-processes-tab.vue';
import OnboardedProductInvestmentsTab from './investments-tabs/components/onboarded-product-investments-tab.vue';
import OnboardedProductSubscriptionProcessesTab from './investments-tabs/components/onboarded-product-subscription-processes-tab.vue';

export const generalPartnerInvestmentsRoutes: RouteConfig[] = [
  {
    name: 'general-partner-investments',
    path: '/general-partner/investments',
    redirect: { name: 'general-partner-investments-investments' },
  }, {
    path: '/general-partner/investments',
    component: InvestmentsTabs,
    children: [
      {
        path: '',
        redirect: 'investments',
      }, {
        name: 'general-partner-investments-investments',
        path: 'investments',
        component: InvestmentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTMENT,
          requiresFeatures: [Features.INVESTMENTS],
          styleClass: 'has-sticky-tab-header',
        },
      }, {
        name: 'general-partner-investments-subscription-processes',
        path: 'subscription-processes',
        component: SubscriptionProcessesTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTMENT,
          requiresFeatures: [Features.INVESTMENTS, Features.SUBSCRIPTION_PROCESS],
          styleClass: 'has-sticky-tab-header',
        },
      }, {
        name: 'general-partner-investments-onboarded-product-investments',
        path: 'onboarded-product-investments',
        component: OnboardedProductInvestmentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTMENT,
          requiresFeatures: [Features.INVESTMENTS, Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
        },
      }, {
        name: 'general-partner-investments-onboarded-product-subscription-processes',
        path: 'onboarded-product-subscription-processes',
        component: OnboardedProductSubscriptionProcessesTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTMENT,
          requiresFeatures: [Features.INVESTMENTS, Features.SUBSCRIPTION_PROCESS, Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
        },
      },
    ],
  },
];
