import { performApiRequest, performApiRequestForFile, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { BreadCrumb, Product, GetBreadCrumbsQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetDueDiligenceDataRoomForDownloadQuery, GetProductForDueDiligenceDataRoomQuery, GetUNodesQuery, UNode } from './types';

export function getProductForDueDiligenceDataRoom(query: GetProductForDueDiligenceDataRoomQuery): Promise<Product> {
  const url = generateApiUrl('/api/investor/get-product-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getBreadCrumbs(query: GetBreadCrumbsQuery): Promise<BreadCrumb[]> {
  const url = generateApiUrl('/api/investor/get-bread-crumbs-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getUNodes(query: GetUNodesQuery): Promise<UNode[]> {
  const url = generateApiUrl('/api/investor/get-u-nodes-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-due-diligence-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-due-diligence-directory-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceDataRoomForDownload(query: GetDueDiligenceDataRoomForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-due-diligence-data-room-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
