import { render, staticRenderFns } from "./tiptap-editor.vue?vue&type=template&id=67f54772&scoped=true&"
import script from "./tiptap-editor.vue?vue&type=script&lang=ts&"
export * from "./tiptap-editor.vue?vue&type=script&lang=ts&"
import style0 from "./tiptap-editor.vue?vue&type=style&index=0&id=67f54772&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f54772",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
