import { Dayjs } from 'dayjs';
import { Command, Query } from '@/application/types';
import { Language, LanguageDependentData, Name, TranslatedText, KPIType, KPIConfiguration, TermSheet, FileReference, SubscriptionAgreementTemplateToPrefillStatus, ControllingPersonTemplateToPrefillStatus } from '@/types/global';

export interface InvestorWithAccess {
  investorId: string;
  name: string;
  hasAccessToProduct: boolean;
}

export interface GeneralPartnerUserWithAccess {
  id: string;
  name: Name;
  hasExplicitAccessToProduct: boolean;
}

export interface ProductVideo {
  id: string;
  title: string;
  videoUrl: string;
  uploadAndTranscodeCompleted: boolean;
}

export interface Product {
  productId: string;
  productName: TranslatedText;
  description?: TranslatedText;
  imageUrl?: string;
  pinnedDueDiligenceFiles: LanguageDependentData<PinnedDueDiligenceFile[]>;
  managers: Manager[];
  contactEmailAddress?: string;
  trackRecordImageUrl?: LanguageDependentData<string>;
  productVideo?: LanguageDependentData<ProductVideo>;
  subscriptionDocumentTemplates: SubscriptionDocumentTemplateFile[];
  subscriptionAgreementTemplatesToPrefill: SubscriptionAgreementTemplateToPrefill[];
  controllingPersonTemplatesToPrefill: ControllingPersonTemplateToPrefill[];
  subscriptionInstruction?: TranslatedText;
  kycInstruction?: TranslatedText;
  idsOfKPIsForProductInfo: string[];
  idsOfKPIsForSummary: string[];
  termSheet: TermSheet;
  termSheetTemplate: TermSheetTemplate;
  isPublished: boolean;
  isInvestable: boolean;
  unpublishedProductUpdate: UnpublishedProductUpdate;
  assetClass: string;
  isDocumentManagementForSubscriptionProcessEnabled: boolean;
  isShared: boolean;
  isOnboardedProduct: boolean;
  productPresentationUrl?: LanguageDependentData<string>;
  productNDA?: LanguageDependentData<FileReference>;
}

export interface UnpublishedProductUpdate {
  productId: string;
  productName: TranslatedText;
  description?: TranslatedText;
  imageUrl?: string;
  pinnedDueDiligenceFiles: LanguageDependentData<PinnedDueDiligenceFile[]>;
  managers: Manager[];
  contactEmailAddress?: string;
  trackRecordImageUrl?: LanguageDependentData<string>;
  productVideo?: LanguageDependentData<ProductVideo>;
  idsOfKPIsForProductInfo: string[];
  idsOfKPIsForSummary: string[];
  termSheet: TermSheet;
  kycInstruction?: TranslatedText;
  productPresentationUrl?: LanguageDependentData<string>;
  productNDA?: LanguageDependentData<FileReference>;
  updatedProductSectionsList: ProductUpdatedSectionsListType[];
  kpiCategoryStatus: KPICategoryStatus[];
}

export type ProductUpdatedSectionsListType =
  | 'PRODUCT_IMAGE_UPDATED'
  | 'PRODUCT_DESCRIPTION_UPDATED'
  | 'PRODUCT_SUMMARY_ITEM_UPDATED'
  | 'PRODUCT_MANAGERS_LIST_UPDATED'
  | 'PRODUCT_TRACK_RECORD_IMAGE_UPDATED'
  | 'PRODUCT_PRESENTATION_UPDATED'
  | 'PRODUCT_VIDEO_UPDATED'
  | 'PRODUCT_EMAIL_ADDRESS_UPDATED'
  | 'PRODUCT_NDA_UPDATED'
  | 'PRODUCT_PINNED_DUE_DILIGENCE_FILE_UPDATED';

export const ProductUpdatedSectionsListTypes: Record<ProductUpdatedSectionsListType, ProductUpdatedSectionsListType> = {
  PRODUCT_IMAGE_UPDATED: 'PRODUCT_IMAGE_UPDATED',
  PRODUCT_DESCRIPTION_UPDATED: 'PRODUCT_DESCRIPTION_UPDATED',
  PRODUCT_SUMMARY_ITEM_UPDATED: 'PRODUCT_SUMMARY_ITEM_UPDATED',
  PRODUCT_MANAGERS_LIST_UPDATED: 'PRODUCT_MANAGERS_LIST_UPDATED',
  PRODUCT_TRACK_RECORD_IMAGE_UPDATED: 'PRODUCT_TRACK_RECORD_IMAGE_UPDATED',
  PRODUCT_PRESENTATION_UPDATED: 'PRODUCT_PRESENTATION_UPDATED',
  PRODUCT_VIDEO_UPDATED: 'PRODUCT_VIDEO_UPDATED',
  PRODUCT_EMAIL_ADDRESS_UPDATED: 'PRODUCT_EMAIL_ADDRESS_UPDATED',
  PRODUCT_NDA_UPDATED: 'PRODUCT_NDA_UPDATED',
  PRODUCT_PINNED_DUE_DILIGENCE_FILE_UPDATED: 'PRODUCT_PINNED_DUE_DILIGENCE_FILE_UPDATED',
};

export interface KPICategoryStatus {
  categoryId: string;
  hasChanges: boolean;
}

export interface TermSheetTemplate {
  kpiCategories: KPICategory[];
  kpiDefinitions: KPIDefinition[];
}

export interface KPICategory {
  kpiCategoryId: string;
  label: TranslatedText;
}

export interface KPIDefinition {
  kpiDefinitionId: string;
  kpiCategoryId: string;
  kpiType: KPIType;
  label: TranslatedText;
  kpiConfiguration?: KPIConfiguration;
  prefix?: TranslatedText;
  suffix?: TranslatedText;
  position: number;
}

export interface SubscriptionDocumentTemplateFile {
  id: string;
  name: string;
  uploadedAt: Dayjs;
}

export interface PinnedDueDiligenceFile {
  id: string;
  name: string;
  uploadedAt: Dayjs;
}

export interface Manager {
  id: string;
  name: string;
  jobTitle: TranslatedText;
  description: TranslatedText;
  profileImageUrl: string;
}

export interface SubscriptionAgreementTemplateToPrefill {
  subscriptionAgreementTemplateToPrefillId: string;
  original: FileReference;
  status: SubscriptionAgreementTemplateToPrefillStatus;
  language: Language;
  createdAt: Dayjs;
}

export interface ControllingPersonTemplateToPrefill {
  controllingPersonTemplateToPrefillId: string;
  original: FileReference;
  status: ControllingPersonTemplateToPrefillStatus;
  language: Language;
  createdAt: Dayjs;
}

// -- Queries

export interface GetProductForProductManagementQuery extends Query {
  productId: string;
  language: Language;
}

export interface GetProductPrintPDFForDownloadQuery extends Query {
  productId: string;
  language: string;
}

export interface GetInvestorsWithAccessForProductQuery extends Query {
  productId: string;
}

export interface GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery extends Query {
  productId: string;
}

// -- Commands

export interface PublishProductUpdateCommand extends Command {
  productId: string;
}

export interface DiscardProductUpdateCommand extends Command {
  productId: string;
}

export interface UpdateInvestorsWithAccessForProductCommand extends Command {
  idsOfInvestorsWithAccess: string[];
  productId: string;
}

export interface UpdateGeneralPartnerUsersAccessForProductCommand extends Command {
  idsOfGeneralPartnerUsersWithExplicitAccess: string[];
  productId: string;
}

export interface UpdateProductNameCommand extends Command {
  productId: string;
  productName: TranslatedText;
}

export interface UpdateProductPreviewCommand extends Command {
  productId: string;
  previewIds: string[];
}

export interface PublishProductCommand extends Command {
  productId: string;
  currentPassword: string;
}

export interface UnpublishProductCommand extends Command {
  productId: string;
  currentPassword: string;
}

export interface EnableProductInvestabilityCommand extends Command {
  productId: string;
}

export interface DisableProductInvestabilityCommand extends Command {
  productId: string;
}

export interface ShareProductCommand extends Command {
  productId: string;
  currentPassword: string;
}

export interface DeleteProductCommand extends Command {
  productId: string;
  currentPassword: string;
}

export interface UnshareProductCommand extends Command {
  productId: string;
  currentPassword: string;
}
