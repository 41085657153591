import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
// Regular icons
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons/faSignIn';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTruck } from '@fortawesome/pro-regular-svg-icons/faTruck';
import { faBox } from '@fortawesome/pro-regular-svg-icons/faBox';
import { faTasks } from '@fortawesome/pro-regular-svg-icons/faTasks';
import { faBullseyePointer } from '@fortawesome/pro-regular-svg-icons/faBullseyePointer';
import { faConveyorBelt } from '@fortawesome/pro-regular-svg-icons/faConveyorBelt';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faAt } from '@fortawesome/pro-regular-svg-icons/faAt';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons/faPlusSquare';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faExclamationSquare } from '@fortawesome/pro-regular-svg-icons/faExclamationSquare';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faMoneyBillWave } from '@fortawesome/pro-regular-svg-icons/faMoneyBillWave';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faUnlock } from '@fortawesome/pro-regular-svg-icons/faUnlock';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons/faCheckDouble';
import { faUserSecret } from '@fortawesome/pro-regular-svg-icons/faUserSecret';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faUserShield } from '@fortawesome/pro-regular-svg-icons/faUserShield';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faPresentation } from '@fortawesome/pro-regular-svg-icons/faPresentation';
import { faNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper';
import { faFileChartLine } from '@fortawesome/pro-regular-svg-icons/faFileChartLine';
import { faArrowsV } from '@fortawesome/pro-regular-svg-icons';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faChartNetwork } from '@fortawesome/pro-regular-svg-icons/faChartNetwork';
import { faFileLock } from '@fortawesome/pro-regular-svg-icons/faFileLock';
import { faFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons/faFileSpreadsheet';
// Light icons
import { faImage as falImage } from '@fortawesome/pro-light-svg-icons/faImage';
import { faPauseCircle as falPauseCircle } from '@fortawesome/pro-light-svg-icons/faPauseCircle';
import { faFileAlt as falFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileCheck as falFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faFileUpload as falFileUpload } from '@fortawesome/pro-light-svg-icons/faFileUpload';
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCog as falCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faSignOut as falSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPhone as falPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faNewspaper as falNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { faFileVideo as falFileVideo } from '@fortawesome/pro-light-svg-icons/faFileVideo';
// Solid icons
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faExclamation as fasExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faBell as fasBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp as fasChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheckSquare as fasCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faFunnelDollar as fasFunnelDollar } from '@fortawesome/pro-solid-svg-icons/faFunnelDollar';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faUniversity as fasUniversity } from '@fortawesome/pro-solid-svg-icons/faUniversity';
import { faUserTie as fasUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckDouble as fasCheckDouble } from '@fortawesome/pro-solid-svg-icons/faCheckDouble';
import { faPrint as fasPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';
import { faEllipsisH as fasEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faEllipsisV as fasEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faFolderOpen as fasFolderOpen } from '@fortawesome/pro-solid-svg-icons/faFolderOpen';
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faFolder as fasFolder } from '@fortawesome/pro-solid-svg-icons/faFolder';
import { faGripLines as fasGripLines } from '@fortawesome/pro-solid-svg-icons/faGripLines';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faHandHoldingUsd as fasHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons/faHandHoldingUsd';
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faSortUp as fasSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faSortDown as fasSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import { faSignOut as fasSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faExchange as fasExchange } from '@fortawesome/pro-solid-svg-icons/faExchange';
import { faWallet as fasWallet } from '@fortawesome/pro-solid-svg-icons/faWallet';
import { faLightbulbDollar as fasLightbulbDollar } from '@fortawesome/pro-solid-svg-icons/faLightbulbDollar';
import { faUpload as fasUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faAddressBook as fasAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook';
import { faSync as fasSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faFileChartLine as fasFileChartLine } from '@fortawesome/pro-solid-svg-icons/faFileChartLine';
import { faHeading as fasHeading } from '@fortawesome/pro-solid-svg-icons/faHeading';
import { faAnalytics as fasAnalytics } from '@fortawesome/pro-solid-svg-icons/faAnalytics';
import { faBold as fasBold } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faItalic as fasItalic } from '@fortawesome/pro-solid-svg-icons/faItalic';
import { faListUl as fasListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faListOl as fasListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faUnlink as fasUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink';
import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faFileAlt as fasFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faArrowLeft as fasArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faArchive as fasArchive } from '@fortawesome/pro-solid-svg-icons/faArchive';
import { faMinusSquare as fasMinusSquare } from '@fortawesome/pro-solid-svg-icons/faMinusSquare';
import { faGears as fasGears } from '@fortawesome/pro-solid-svg-icons/faGears';
import { faSection as fasSection } from '@fortawesome/pro-solid-svg-icons/faSection';
// Duotone icons
import { faExclamationCircle as fadExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';

// Regular
library.add(
  faCircle,
  faDotCircle,
  faSignOut,
  faSignIn,
  faInfoCircle,
  faSearch,
  faTruck,
  faBox,
  faTasks,
  faBullseyePointer,
  faConveyorBelt,
  faSquare,
  faFolder,
  faAt,
  faLock,
  faUser,
  faFileUpload,
  faCheck,
  faPencil,
  faDownload,
  faPaperPlane,
  faBan,
  faClone,
  faPlusSquare,
  faCheckSquare,
  faExclamationSquare,
  faExclamationCircle,
  faUpload,
  faBell,
  faMoneyBillWave,
  faPaperclip,
  faUnlock,
  faCheckDouble,
  faUnlock,
  faUserSecret,
  faHome,
  faList,
  faFile,
  faUserSecret,
  faStar,
  faTrashAlt,
  faTimes,
  faPlus,
  faFilter,
  faUserShield,
  faEnvelope,
  faPresentation,
  faNewspaper,
  faFileChartLine,
  faArrowsV,
  faFileAlt,
  faLanguage,
  faEye,
  faChartNetwork,
  faFileLock,
  faFileSpreadsheet
);

// Light
library.add(
  falImage,
  falPauseCircle,
  falFileAlt,
  falFileCheck,
  falPauseCircle,
  falTimes,
  falFileUpload,
  falCheck,
  falCog,
  falSignOut,
  falEnvelope,
  falPhone,
  falNewspaper,
  falFileVideo
);

// Solid
library.add(
  fasCopy,
  fasBars,
  fasBell,
  fasChevronDown,
  fasChevronUp,
  fasUser,
  fasCaretDown,
  fasCheckSquare,
  fasFunnelDollar,
  fasTimes,
  fasUniversity,
  fasUserTie,
  fasChevronLeft,
  fasChevronRight,
  fasCheck,
  fasCheckDouble,
  fasPrint,
  fasEllipsisH,
  fasEllipsisV,
  fasTimesCircle,
  fasFolderOpen,
  fasFile,
  fasFolder,
  fasGripLines,
  fasExclamationTriangle,
  fasHandHoldingUsd,
  fasUsers,
  fasSortUp,
  fasSortDown,
  fasSignOut,
  fasExchange,
  fasWallet,
  fasLightbulbDollar,
  fasUpload,
  fasAddressBook,
  fasSync,
  fasFileChartLine,
  fasHeading,
  fasAnalytics,
  fasBold,
  fasItalic,
  fasListUl,
  fasListOl,
  fasLink,
  fasUnlink,
  fasCog,
  fasFileAlt,
  fasArrowLeft,
  fasArrowRight,
  fasPhone,
  fasEnvelope,
  fasArchive,
  fasMinusSquare,
  fasExclamation,
  fasGears,
  fasSection
);

// Duotone
library.add(
  fadExclamationCircle
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
