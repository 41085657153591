import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { KPI } from '@/types/global';
import { useGeneralPartnerProductManagementGeneralStore } from '@/domain/general-partner/products/product-management/store';
import { CreateProductTermSheetCategoryCommand, DeleteProductTermSheetCategoryCommand, UpdateProductTermSheetCategoryCommand } from './types';
import { createProductTermSheetCategory, deleteProductTermSheetCategory, updateProductTermSheetCategory } from './service';

interface GeneralPartnerProductManagementDetailsTabState {
  customKPIs: KPI[];

  createProductTermSheetCategoryStatus: ActionStatus;
  updateProductTermSheetCategoryStatus: ActionStatus;
  deleteProductTermSheetCategoryStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementDetailsTabState {
  return {
    customKPIs: [],

    createProductTermSheetCategoryStatus: ActionStatus.None,
    updateProductTermSheetCategoryStatus: ActionStatus.None,
    deleteProductTermSheetCategoryStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementDetailsTabStore = defineStore('generalPartnerProductManagementDetailsTab', {
  state: (): GeneralPartnerProductManagementDetailsTabState => initialState(),
  getters: {
    isCreateProductTermSheetCategoryProcessing: (state: GeneralPartnerProductManagementDetailsTabState): boolean =>
      state.createProductTermSheetCategoryStatus === ActionStatus.InProgress,
    isupdateProductTermSheetCategoryProcessing: (state: GeneralPartnerProductManagementDetailsTabState): boolean =>
      state.updateProductTermSheetCategoryStatus === ActionStatus.InProgress,
    isdeleteProductTermSheetCategoryProcessing: (state: GeneralPartnerProductManagementDetailsTabState): boolean =>
      state.deleteProductTermSheetCategoryStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    addCustomKPI(customKPI: KPI): Promise<void> {
      this.customKPIs.push(customKPI);

      return Promise.resolve();
    },

    updateCustomKPI(updatedCustomKPI: KPI): Promise<void> {
      this.customKPIs = this.customKPIs
        .map((customKPI) => customKPI.kpiId === updatedCustomKPI.kpiId
          ? updatedCustomKPI
          : customKPI);

      return Promise.resolve();
    },

    removeCustomKPI(customKPIToRemove: KPI): Promise<void> {
      this.customKPIs = this.customKPIs
        .filter((customKPI) => customKPI.kpiId !== customKPIToRemove.kpiId);

      return Promise.resolve();
    },

    updateCustomKPIs(customKPIs: KPI[]): Promise<void> {
      this.customKPIs = customKPIs;

      return Promise.resolve();
    },

    // -- Commands

    createProductTermSheetCategory(command: CreateProductTermSheetCategoryCommand): Promise<void> {
      const { createProductTermSheetCategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createProductTermSheetCategoryStatus,
        () => createProductTermSheetCategory(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductTermSheetCategory(command: UpdateProductTermSheetCategoryCommand): Promise<void> {
      const { updateProductTermSheetCategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductTermSheetCategoryStatus,
        () => updateProductTermSheetCategory(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    deleteProductTermSheetCategory(command: DeleteProductTermSheetCategoryCommand): Promise<void> {
      const { deleteProductTermSheetCategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductTermSheetCategoryStatus,
        () => deleteProductTermSheetCategory(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

  },
});
