import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { CreateProductTermSheetCategoryCommand, DeleteProductTermSheetCategoryCommand, UpdateProductTermSheetCategoryCommand } from './types';

export function createProductTermSheetCategory(command: CreateProductTermSheetCategoryCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-product-term-sheet-category-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductTermSheetCategory(command: UpdateProductTermSheetCategoryCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-term-sheet-category-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteProductTermSheetCategory(command: DeleteProductTermSheetCategoryCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-term-sheet-category-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
