import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { KeyFactKPITypes, KPI } from '@/types/global';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { contactProductContactPerson, getDueDiligenceFileForDownload, getProduct, getProductReportForDownload, getAllProductReportsForProductForDownload, getProductPrintPDFForDownload, getProductNewsArticleAttachmentForDownload, acceptNDA } from './service';
import { ContactProductContactPersonCommand, Product, GetAllProductReportsForProductForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetProductQuery, GetProductPrintPDFForDownloadQuery, GetProductReportForDownloadQuery, GetProductNewsArticleAttachmentForDownloadQuery, AcceptNDACommand } from './types';

interface InvestorProductState {
  currentProductId: string | null;
  currentProduct: Product | null;

  getCurrentProductStatus: ActionStatus;
  getDueDiligenceFileForDownloadStatus: ActionStatus;
  contactProductContactPersonStatus: ActionStatus;
  getProductReportForDownloadStatus: ActionStatus;
  getAllProductReportsForProductForDownloadStatus: ActionStatus;
  getProductPrintPDFForDownloadStatus: ActionStatus;
  getProductNewsArticleAttachmentForDownloadStatus: ActionStatus;
  acceptNDAStatus: ActionStatus;
}

function initialState(): InvestorProductState {
  return {
    currentProductId: null,
    currentProduct: null,

    getCurrentProductStatus: ActionStatus.None,
    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    contactProductContactPersonStatus: ActionStatus.None,
    getProductReportForDownloadStatus: ActionStatus.None,
    getAllProductReportsForProductForDownloadStatus: ActionStatus.None,
    getProductPrintPDFForDownloadStatus: ActionStatus.None,
    getProductNewsArticleAttachmentForDownloadStatus: ActionStatus.None,
    acceptNDAStatus: ActionStatus.None,
  };
}

export const useInvestorProductStore = defineStore('investorProduct', {
  state: (): InvestorProductState => initialState(),
  getters: {
    isGetCurrentProductProcessing: (state: InvestorProductState): boolean =>
      state.getCurrentProductStatus === ActionStatus.InProgress,
    isGetDueDiligenceFileForDownloadProcessing: (state: InvestorProductState): boolean =>
      state.getDueDiligenceFileForDownloadStatus === ActionStatus.InProgress,
    isContactProductContactPersonProcessing: (state: InvestorProductState): boolean =>
      state.contactProductContactPersonStatus === ActionStatus.InProgress,
    isGetProductReportForDownloadProcessing: (state: InvestorProductState): boolean =>
      state.getProductReportForDownloadStatus === ActionStatus.InProgress,
    isGetAllProductReportsForProductForDownloadProcessing: (state: InvestorProductState): boolean =>
      state.getAllProductReportsForProductForDownloadStatus === ActionStatus.InProgress,
    isGetProductPrintPDFForDownloadProcessing: (state: InvestorProductState): boolean =>
      state.getProductPrintPDFForDownloadStatus === ActionStatus.InProgress,
    isGetProductNewsArticleAttachmentForDownloadProcessing: (state: InvestorProductState): boolean =>
      state.getProductNewsArticleAttachmentForDownloadStatus === ActionStatus.InProgress,
    isAcceptNDAProcessing: (state: InvestorProductState): boolean =>
      state.acceptNDAStatus === ActionStatus.InProgress,

    keyFactKPIs: (state: InvestorProductState): KPI[] =>
      state.currentProduct
        ? KeyFactKPITypes.map((kpiType) => getKPIsFromTermSheet(state.currentProduct!.termSheet)
          .find((kpi) => kpi.kpiType === kpiType))
          .filter((kpi) => !!kpi) as KPI[]
        : [],
    hasHoldings: (state: InvestorProductState): boolean =>
      !!state.currentProduct?.holdings,
    hasProductReports: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && state.currentProduct?.holdings
      && state.currentProduct.holdings.productReports.length > 0 || false,
    hasTransactions: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && state.currentProduct.investorTransactions
      && state.currentProduct.investorTransactions.length > 0 || false,
    hasDueDiligenceFiles: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && state.currentProduct.dueDiligenceFiles.length > 0,
    summaryKPIs: (state: InvestorProductState): KPI[] =>
      state.currentProduct
        ? state.currentProduct.idsOfKPIsForSummary
          .map((kpiId) => getKPIsFromTermSheet(state.currentProduct!.termSheet)
            .find((kpi) => kpi.kpiId === kpiId)!)
        : [],
    hasProductVideo: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && !!state.currentProduct.productVideo,
    hasManagers: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && state.currentProduct.managers.length > 0,
    hasSummaryKPIs: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && (state.currentProduct.idsOfKPIsForSummary.length || 0) > 0,
    hasProductNewsArticles: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && state.currentProduct.newsArticles.length > 0 || false,
    hasInvestorHoldings: (state: InvestorProductState): boolean =>
      !!state.currentProduct?.holdings,
    isProductAvailable: (state: InvestorProductState): boolean =>
      !!state.currentProduct
      && state.currentProduct.productId === state.currentProductId,
    productKPIs: (state: InvestorProductState): KPI[] =>
      state.currentProduct
        ? getKPIsFromTermSheet(state.currentProduct!.termSheet)
        : [],
  },
  actions: {

    // -- State management

    updateCurrentProduct(currentProductId: string): Promise<void> {
      this.currentProductId = currentProductId;

      return this.getCurrentProduct();
    },

    // -- Queries

    getCurrentProduct(): Promise<void> {
      const query: GetProductQuery = {
        productId: this.currentProductId!,
      };

      const { getCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentProductStatus,
        () => getProduct(query)
          .then((product) => {
            this.currentProduct = product;
          })
      );
    },

    getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceFileForDownloadStatus,
        () => getDueDiligenceFileForDownload(query)
      );
    },

    getProductReportForDownload(query: GetProductReportForDownloadQuery): Promise<FileResponse> {
      const { getProductReportForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductReportForDownloadStatus,
        () => getProductReportForDownload(query)
      );
    },

    getAllProductReportsForProductForDownload(): Promise<FileResponse> {
      const query: GetAllProductReportsForProductForDownloadQuery = {
        productId: this.currentProduct!.productId,
      };

      const { getAllProductReportsForProductForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAllProductReportsForProductForDownloadStatus,
        () => getAllProductReportsForProductForDownload(query)
      );
    },

    getProductPrintPDFForDownload(query: GetProductPrintPDFForDownloadQuery): Promise<FileResponse> {
      const { getProductPrintPDFForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductPrintPDFForDownloadStatus,
        () => getProductPrintPDFForDownload(query)
      );
    },

    getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
      const { getProductNewsArticleAttachmentForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductNewsArticleAttachmentForDownloadStatus,
        () => getProductNewsArticleAttachmentForDownload(query)
      );
    },

    // -- Commands

    contactProductContactPerson(command: ContactProductContactPersonCommand): Promise<void> {
      const { contactProductContactPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        contactProductContactPersonStatus,
        () => contactProductContactPerson(command)
      );
    },

    acceptNDA(command: AcceptNDACommand): Promise<void> {
      const { acceptNDAStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acceptNDAStatus,
        () => acceptNDA(command)
          .then(() => this.getCurrentProduct())
      );
    },

  },
});
