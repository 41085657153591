import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachBrainiac } from '@/helpers/default-parameter-helper';
import { GeneralPartner, UpdateEnabledLanguagesForGeneralPartnerCommand, UpdateEnabledLanguagesForProductsOfGeneralPartnerCommand, UpdateGeneralPartnerDetailsCommand, UpdateGeneralPartnerFeaturesCommand } from './types';

// -- Queries

export function getGeneralPartnersForOverview(): Promise<GeneralPartner[]> {
  const url = generateApiUrl('/brain-api/get-general-partners-for-overview-query');
  return performApiRequest<GeneralPartner[]>({ url, method: 'POST', data: attachBrainiac({}) });
}

// -- Commands

export function updateGeneralPartnerDetails(command: UpdateGeneralPartnerDetailsCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-details-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerFeatures(command: UpdateGeneralPartnerFeaturesCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-features-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateEnabledLanguagesForGeneralPartner(command: UpdateEnabledLanguagesForGeneralPartnerCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-enabled-languages-for-general-partner-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateEnabledLanguagesForProductsOfGeneralPartner(
  command: UpdateEnabledLanguagesForProductsOfGeneralPartnerCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-enabled-languages-for-products-of-general-partner-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
