import { defineStore } from 'pinia';
import { RawLocation } from 'vue-router';
import dayjs from 'dayjs';
import i18n from '@/plugins/vue-i18n';
import { serializer } from '@/store';
import { AccountType, AccountTypes, Language } from '@/types/global';

interface AppState {
  accountType: AccountType | null;
  selectedLanguage: Language | null;
  parentLocation: RawLocation | null;
  isOutdated: boolean;
}

function initialState(): AppState {
  return {
    accountType: null,
    selectedLanguage: null,
    parentLocation: null,
    isOutdated: false,
  };
}

export const useAppStore = defineStore('app', {
  state: (): AppState => initialState(),
  persist: {
    serializer,
  },
  getters: {
    isAuthenticatedUserInvestor: (state: AppState): boolean =>
      state.accountType === AccountTypes.INVESTOR,
    isAuthenticatedUserGeneralPartner: (state: AppState): boolean =>
      state.accountType === AccountTypes.GENERAL_PARTNER,
  },
  actions: {

    // -- State management

    partialReset(): Promise<void> {
      // Language does never get cleared
      this.parentLocation = null;
      this.isOutdated = false;
      this.accountType = null;

      return Promise.resolve();
    },

    updateLanguage(language: Language): void {
      this.selectedLanguage = language;
      i18n.locale = language;
      dayjs.locale(language);
    },

    updateAccountType(accountType: AccountType): Promise<void> {
      this.accountType = accountType;

      return Promise.resolve();
    },

    updateParentLocation(parentLocation: RawLocation): Promise<void> {
      this.parentLocation = parentLocation;

      return Promise.resolve();
    },

    validateAppVersion(serverVersion: string): Promise<void> {
      if (process.env.SOURCE_VERSION !== serverVersion) {
        this.isOutdated = true;
      }

      return Promise.resolve();
    },

    refreshApp(): Promise<void> {
      this.isOutdated = false;
      window.location.reload();

      return Promise.resolve();
    },

  },
});
