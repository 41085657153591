import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { CurrencySymbols, SubscriptionProcessStatuses } from '@/types/global';
import { formatCurrencyWithoutCentDigits } from '@/filters/currencies';
import { createInvestmentForSubscriptionProcess, cancelSubscriptionProcess, getSubscriptionProcess, getSubscriptionDocumentFileForDownload, specifySubscriptionInvestmentAmount, getSubscriptionDocumentTemplateFileForDownload, createSubscriptionDocumentForSubscriptionProcess, confirmSubscription, deleteSubscriptionDocument, reopenSubscriptionProcess, getKYCDocumentAttachmentFileForDownload, createKYCDocumentAttachmentForSubscriptionProcess, deleteKYCDocumentAttachment, selectInvestmentProfileForSubscriptionProcess, getInvestmentProfiles, confirmSelectedInvestmentProfileForSubscriptionProcess, getInvestmentProfile, continueSubscriptionProcessAsNaturalPerson } from './service';
import { CancelSubscriptionProcessCommand, CreateInvestmentForSubscriptionProcessCommand, GetSubscriptionProcessQuery, GetSubscriptionDocumentFileForDownloadQuery, SubscriptionProcess, SpecifySubscriptionInvestmentAmountCommand, GetSubscriptionDocumentTemplateFileForDownloadQuery, CreateSubscriptionDocumentForSubscriptionProcessCommand, SubscriptionProcessStep, DeleteSubscriptionDocumentCommand, ReopenSubscriptionProcessCommand, GetKYCDocumentAttachmentFileForDownloadQuery, CreateKYCDocumentAttachmentForSubscriptionProcessCommand, ConfirmSubscriptionCommand, DeleteKYCDocumentAttachmentCommand, SelectInvestmentProfileForSubscriptionProcessCommand, InvestmentProfile, GetInvestmentProfilesForInvestorQuery, ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand, GetInvestmentProfileQuery, InvestmentProfileDetails, ContinueSubscriptionProcessAsNaturalPersonCommand, SubscriptionDocument } from './types';

function calculateNextSubscriptionProcessStep(subscriptionProcess: SubscriptionProcess | null): SubscriptionProcessStep {
  if (!subscriptionProcess) {
    return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
  }

  if (subscriptionProcess.status === SubscriptionProcessStatuses.IN_REVIEW) {
    return SubscriptionProcessStep.COMPLETION;
  }

  if (subscriptionProcess.isInvestmentProfileReviewed || subscriptionProcess.isNaturalPersonSelected) {
    return SubscriptionProcessStep.REVIEW;
  }

  if (subscriptionProcess.selectedInvestmentProfileId) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW;
  }

  if (subscriptionProcess.investmentAmount) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION;
  }

  return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
}

interface GeneralPartnerSubscriptionProcessDialogState {
  currentSubscriptionProcessId: string | null;
  currentSubscriptionProcess: SubscriptionProcess | null;
  investmentProfiles: InvestmentProfile[];
  currentSubscriptionProcessStep: SubscriptionProcessStep;
  currentInvestmentProfileId: string | null;
  currentInvestmentProfile: InvestmentProfileDetails | null;

  getSubscriptionProcessStatus: ActionStatus;
  getSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus;
  getSubscriptionDocumentFileForDownloadStatus: ActionStatus;
  specifySubscriptionInvestmentAmountStatus: ActionStatus;
  selectInvestmentProfileForSubscriptionProcessStatus: ActionStatus;
  createSubscriptionDocumentsStatus: ActionStatus;
  confirmSubscriptionStatus: ActionStatus;
  createInvestmentForSubscriptionProcessStatus: ActionStatus;
  reopenSubscriptionProcessStatus: ActionStatus;
  cancelSubscriptionProcessStatus: ActionStatus;
  deleteSubscriptionDocumentStatus: ActionStatus;
  getKYCDocumentAttachmentFileForDownloadStatus: ActionStatus;
  createKYCDocumentAttachmentsStatus: ActionStatus;
  deleteKYCDocumentAttachmentStatus: ActionStatus;
  getInvestmentProfilesStatus: ActionStatus;
  confirmSelectedInvestmentProfileForSubscriptionProcessStatus: ActionStatus;
  getCurrentInvestmentProfileStatus: ActionStatus;
  continueSubscriptionProcessAsNaturalPersonStatus: ActionStatus;
}

function initialState(): GeneralPartnerSubscriptionProcessDialogState {
  return {
    currentSubscriptionProcessId: null,
    currentSubscriptionProcess: null,
    investmentProfiles: [],
    currentSubscriptionProcessStep: SubscriptionProcessStep.AMOUNT_SPECIFICATION,
    currentInvestmentProfileId: null,
    currentInvestmentProfile: null,

    getSubscriptionProcessStatus: ActionStatus.None,
    getSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus.None,
    getSubscriptionDocumentFileForDownloadStatus: ActionStatus.None,
    specifySubscriptionInvestmentAmountStatus: ActionStatus.None,
    selectInvestmentProfileForSubscriptionProcessStatus: ActionStatus.None,
    createSubscriptionDocumentsStatus: ActionStatus.None,
    confirmSubscriptionStatus: ActionStatus.None,
    createInvestmentForSubscriptionProcessStatus: ActionStatus.None,
    reopenSubscriptionProcessStatus: ActionStatus.None,
    cancelSubscriptionProcessStatus: ActionStatus.None,
    deleteSubscriptionDocumentStatus: ActionStatus.None,
    getKYCDocumentAttachmentFileForDownloadStatus: ActionStatus.None,
    createKYCDocumentAttachmentsStatus: ActionStatus.None,
    deleteKYCDocumentAttachmentStatus: ActionStatus.None,
    getInvestmentProfilesStatus: ActionStatus.None,
    confirmSelectedInvestmentProfileForSubscriptionProcessStatus: ActionStatus.None,
    getCurrentInvestmentProfileStatus: ActionStatus.None,
    continueSubscriptionProcessAsNaturalPersonStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerSubscriptionProcessDialogStore = defineStore(
  'generalPartnerSubscriptionProcessDialog',
  {
    state: (): GeneralPartnerSubscriptionProcessDialogState => initialState(),
    getters: {
      isGetSubscriptionProcessProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.getSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetSubscriptionDocumentTemplateFileForDownloadProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.getSubscriptionDocumentTemplateFileForDownloadStatus === ActionStatus.InProgress,
      isGetSubscriptionDocumentFileForDownloadProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.getSubscriptionDocumentFileForDownloadStatus === ActionStatus.InProgress,
      isSpecifySubscriptionInvestmentAmountProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.specifySubscriptionInvestmentAmountStatus === ActionStatus.InProgress,
      isSelectInvestmentProfileForSubscriptionProcessProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.selectInvestmentProfileForSubscriptionProcessStatus === ActionStatus.InProgress,
      isCreateSubscriptionDocumentsProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.createSubscriptionDocumentsStatus === ActionStatus.InProgress,
      isConfirmSubscriptionProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.confirmSubscriptionStatus === ActionStatus.InProgress,
      isCreateInvestmentForSubscriptionProcessProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.createInvestmentForSubscriptionProcessStatus === ActionStatus.InProgress,
      isReopenSubscriptionProcessProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.reopenSubscriptionProcessStatus === ActionStatus.InProgress,
      isCancelSubscriptionProcessProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.cancelSubscriptionProcessStatus === ActionStatus.InProgress,
      isDeleteSubscriptionDocumentProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.deleteSubscriptionDocumentStatus === ActionStatus.InProgress,
      isGetKYCDocumentAttachmentFileForDownloadProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.getKYCDocumentAttachmentFileForDownloadStatus === ActionStatus.InProgress,
      isCreateKYCDocumentAttachmentsProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.createKYCDocumentAttachmentsStatus === ActionStatus.InProgress,
      isDeleteKYCDocumentAttachmentProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.deleteKYCDocumentAttachmentStatus === ActionStatus.InProgress,
      isGetInvestmentProfilesProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.getInvestmentProfilesStatus === ActionStatus.InProgress,
      isConfirmSelectedInvestmentProfileForSubscriptionProcessProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.confirmSelectedInvestmentProfileForSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetCurrentInvestmentProfileProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.getCurrentInvestmentProfileStatus === ActionStatus.InProgress,
      isContinueSubscriptionProcessAsNaturalPersonProcessing: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.continueSubscriptionProcessAsNaturalPersonStatus === ActionStatus.InProgress,

      currentSubscriptionProcessHasSubscriptionDocuments: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
          && state.currentSubscriptionProcess.subscriptionDocuments.length > 0,
      subscriptionDocuments: (state: GeneralPartnerSubscriptionProcessDialogState): SubscriptionDocument[] =>
        !!state.currentSubscriptionProcess
          && state.currentSubscriptionProcess.subscriptionDocuments
        || [],
      hasSubscriptionDocumentTemplates: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
          && !!state.currentSubscriptionProcess.subscriptionDocumentTemplates
          && state.currentSubscriptionProcess.subscriptionDocumentTemplates.length > 0,
      currencySymbol: (state: GeneralPartnerSubscriptionProcessDialogState): string =>
        state.currentSubscriptionProcess
        && CurrencySymbols[state.currentSubscriptionProcess.productCurrency] || '',
      completedInvestmentProfiles: (state: GeneralPartnerSubscriptionProcessDialogState): InvestmentProfile[] =>
        state.investmentProfiles
          .filter((investmentProfile: InvestmentProfile) => investmentProfile.isComplete),
      hasCompletedInvestmentProfiles: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.investmentProfiles
          .filter((investmentProfile: InvestmentProfile) => investmentProfile.isComplete)
          .length > 0,
      isNaturalPersonSubscriptionProcess: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
        && state.currentSubscriptionProcess.isNaturalPersonSelected,
      nextStep: (state: GeneralPartnerSubscriptionProcessDialogState): SubscriptionProcessStep => {
        if (!state.currentSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled) {
          return SubscriptionProcessStep.REVIEW;
        }

        if (state.currentSubscriptionProcess?.investorHasKYCAccess) {
          return SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS;
        }

        return SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS;
      },
      currentSubscriptionProcessHasKYCDocumentAttachments: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
        && state.currentSubscriptionProcess.kycDocumentAttachments.length > 0,
      isSubscriptionProcessInReview: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
        && state.currentSubscriptionProcess.status === SubscriptionProcessStatuses.IN_REVIEW,
      isInvestmentAmountSpecified: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
        && !!state.currentSubscriptionProcess.investmentAmount,
      productCurrency: (state: GeneralPartnerSubscriptionProcessDialogState): string =>
        state.currentSubscriptionProcess
          && CurrencySymbols[state.currentSubscriptionProcess.productCurrency] || '',
      hasPreviousInvestments: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
        && state.currentSubscriptionProcess.investmentSummary.investments.length > 0,
      isSubscriptionProcessAvailable: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcessId
          && !!state.currentSubscriptionProcess,
      stepNumberForSubscriptionDocumentsStep: (state: GeneralPartnerSubscriptionProcessDialogState): number =>
        state.currentSubscriptionProcess?.investorHasKYCAccess
          ? 5
          : 4,
      stepNumberForConfirmSubscriptionStep: (state: GeneralPartnerSubscriptionProcessDialogState): number => {
        if (!(state.currentSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled ?? false)) {
          return 4;
        }

        return state.currentSubscriptionProcess?.investorHasKYCAccess
          ? 6
          : 5;
      },
      stepNumberForCompletionStep: (state: GeneralPartnerSubscriptionProcessDialogState): number => {
        let step;
        if (!(state.currentSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled ?? false)) {
          step = 4;
        } else {
          step = state.currentSubscriptionProcess?.investorHasKYCAccess
            ? 6
            : 5;
        }

        return step + 1;
      },
      isDocumentManagementForSubscriptionProcessEnabled: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        state.currentSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled ?? false,
      hasInvestorKYCAccess: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess?.investorHasKYCAccess,
      isInvestmentAmountDefined: (state: GeneralPartnerSubscriptionProcessDialogState): boolean =>
        !!state.currentSubscriptionProcess
        && !!state.currentSubscriptionProcess.investmentAmount,
      formattedInvestmentAmount: (state: GeneralPartnerSubscriptionProcessDialogState): string =>
        state.currentSubscriptionProcess
          ? formatCurrencyWithoutCentDigits(
          state.currentSubscriptionProcess.investmentAmount!,
          state.currentSubscriptionProcess.productCurrency
          ) : '',
      nextSubscriptionProcessStep: (state: GeneralPartnerSubscriptionProcessDialogState): SubscriptionProcessStep =>
        calculateNextSubscriptionProcessStep(state.currentSubscriptionProcess),
    },
    actions: {

      // -- State management

      updateCurrentSubscriptionProcessId(currentSubscriptionProcessId: string): Promise<void> {
        this.currentSubscriptionProcessId = currentSubscriptionProcessId;

        return Promise.resolve();
      },

      goToNextStep(): Promise<void> {
        return this.updateCurrentSubscriptionProcessStep(this.nextStep);
      },

      updateCurrentSubscriptionProcessStep(currentStep: SubscriptionProcessStep): Promise<void> {
        this.currentSubscriptionProcessStep = currentStep;

        return Promise.resolve();
      },

      setCurrentInvestmentProfileId(id: string): Promise<void> {
        this.currentInvestmentProfileId = id;

        return Promise.resolve();
      },

      resetCurrentInvestmentProfile(): Promise<void> {
        this.currentInvestmentProfileId = null;
        this.currentInvestmentProfile = null;

        return Promise.resolve();
      },

      // -- Queries

      getSubscriptionProcess(): Promise<void> {
        const query: GetSubscriptionProcessQuery = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { getSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getSubscriptionProcessStatus,
          () => getSubscriptionProcess(query)
            .then((subscriptionProcess) => {
              this.currentSubscriptionProcess = subscriptionProcess;
              this.currentSubscriptionProcessStep = calculateNextSubscriptionProcessStep(subscriptionProcess);
            })
        );
      },

      getSubscriptionDocumentTemplateFileForDownload(query: GetSubscriptionDocumentTemplateFileForDownloadQuery): Promise<FileResponse> {
        const { getSubscriptionDocumentTemplateFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getSubscriptionDocumentTemplateFileForDownloadStatus,
          () => getSubscriptionDocumentTemplateFileForDownload(query)
        );
      },

      getSubscriptionDocumentFileForDownload(query: GetSubscriptionDocumentFileForDownloadQuery): Promise<FileResponse> {
        const { getSubscriptionDocumentFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getSubscriptionDocumentFileForDownloadStatus,
          () => getSubscriptionDocumentFileForDownload(query)
        );
      },

      getKYCDocumentAttachmentFileForDownload(query: GetKYCDocumentAttachmentFileForDownloadQuery): Promise<FileResponse> {
        const { getKYCDocumentAttachmentFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getKYCDocumentAttachmentFileForDownloadStatus,
          () => getKYCDocumentAttachmentFileForDownload(query)
        );
      },

      getInvestmentProfiles(query: GetInvestmentProfilesForInvestorQuery): Promise<void> {
        const { getInvestmentProfilesStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestmentProfilesStatus,
          () => getInvestmentProfiles(query)
            .then((investmentProfiles) => {
              this.investmentProfiles = investmentProfiles;
            })
        );
      },

      getCurrentInvestmentProfile(): Promise<void> {
        const query: GetInvestmentProfileQuery = {
          investmentProfileId: this.currentInvestmentProfileId!,
        };

        const { getCurrentInvestmentProfileStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getCurrentInvestmentProfileStatus,
          () => getInvestmentProfile(query)
            .then((investmentProfile) => {
              this.currentInvestmentProfile = investmentProfile;
            })
        );
      },

      // -- Commands

      specifySubscriptionInvestmentAmount(command: SpecifySubscriptionInvestmentAmountCommand): Promise<void> {
        const { specifySubscriptionInvestmentAmountStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          specifySubscriptionInvestmentAmountStatus,
          () => specifySubscriptionInvestmentAmount(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      createSubscriptionDocuments(files: File[]): Promise<void[]> {
        const queuedFileUploads = files.map((file) => {
          const command: CreateSubscriptionDocumentForSubscriptionProcessCommand = {
            body: {
              subscriptionProcessId: this.currentSubscriptionProcessId!,
            },
            files: { file },
          };

          const payload: AddItemToUploadQueuePayload = {
            fileName: file.name,
            serviceFunction: createSubscriptionDocumentForSubscriptionProcess,
            command,
          };

          return useFileUploadQueueStore().addItem(payload)
            .then(() => this.getSubscriptionProcess());
        });

        const { createSubscriptionDocumentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createSubscriptionDocumentsStatus,
          () => Promise.all(queuedFileUploads)
        );
      },

      confirmSubscription(command: ConfirmSubscriptionCommand): Promise<void> {
        const { confirmSubscriptionStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmSubscriptionStatus,
          () => confirmSubscription(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      createInvestmentForSubscriptionProcess(command: CreateInvestmentForSubscriptionProcessCommand): Promise<void> {
        const { createInvestmentForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createInvestmentForSubscriptionProcessStatus,
          () => createInvestmentForSubscriptionProcess(command)
        );
      },

      reopenSubscriptionProcess(): Promise<void> {
        const command: ReopenSubscriptionProcessCommand = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { reopenSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          reopenSubscriptionProcessStatus,
          () => reopenSubscriptionProcess(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      cancelSubscriptionProcess(): Promise<void> {
        const command: CancelSubscriptionProcessCommand = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { cancelSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          cancelSubscriptionProcessStatus,
          () => cancelSubscriptionProcess(command)
        );
      },

      createKYCDocumentAttachments(files: File[]): Promise<void[]> {
        const queuedFileUploads = files.map((file) => {
          const command: CreateKYCDocumentAttachmentForSubscriptionProcessCommand = {
            body: {
              subscriptionProcessId: this.currentSubscriptionProcessId!,
            },
            files: { file },
          };

          const payload: AddItemToUploadQueuePayload = {
            fileName: file.name,
            serviceFunction: createKYCDocumentAttachmentForSubscriptionProcess,
            command,
          };

          return useFileUploadQueueStore().addItem(payload)
            .then(() => this.getSubscriptionProcess());
        });

        const { createKYCDocumentAttachmentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createKYCDocumentAttachmentsStatus,
          () => Promise.all(queuedFileUploads)
        );
      },

      deleteSubscriptionDocument(command: DeleteSubscriptionDocumentCommand): Promise<void> {
        const { deleteSubscriptionDocumentStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteSubscriptionDocumentStatus,
          () => deleteSubscriptionDocument(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      deleteKYCDocumentAttachment(command: DeleteKYCDocumentAttachmentCommand): Promise<void> {
        const { deleteKYCDocumentAttachmentStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteKYCDocumentAttachmentStatus,
          () => deleteKYCDocumentAttachment(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      selectInvestmentProfileForSubscriptionProcess(command: SelectInvestmentProfileForSubscriptionProcessCommand): Promise<void> {
        const { selectInvestmentProfileForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          selectInvestmentProfileForSubscriptionProcessStatus,
          () => selectInvestmentProfileForSubscriptionProcess(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      confirmSelectedInvestmentProfileForSubscriptionProcess(
        command: ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand
      ): Promise<void> {
        const { confirmSelectedInvestmentProfileForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmSelectedInvestmentProfileForSubscriptionProcessStatus,
          () => confirmSelectedInvestmentProfileForSubscriptionProcess(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

      continueSubscriptionProcessAsNaturalPerson(): Promise<void> {
        const command: ContinueSubscriptionProcessAsNaturalPersonCommand = {
          subscriptionProcessId: this.currentSubscriptionProcessId!,
        };

        const { continueSubscriptionProcessAsNaturalPersonStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          continueSubscriptionProcessAsNaturalPersonStatus,
          () => continueSubscriptionProcessAsNaturalPerson(command)
            .then(() => this.getSubscriptionProcess())
        );
      },

    },
  }
);
