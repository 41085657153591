import { defineStore, storeToRefs } from 'pinia';
import dayjs from 'dayjs';
import i18n from '@/plugins/vue-i18n';
import { serializer, wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { AccountTypes } from '@/types/global';
import { useAppStore } from '@/application/common/app/store';
import { LOCAL_STORAGE_USER_ID, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { useRefreshedSessionStore } from '@/application/common/refreshed-session-dialog/store';
import { AuthenticatedUser, Authentication, ConfirmTwoFactorAuthenticationCommand, isTwoFactorAuthenticationLoginResponse, LoginCommand, LoginType, RequestPasswordResetCommand, ResetPasswordCommand, UpdateUserLanguageCommand } from './types';
import { confirmTwoFactorAuthentication, getAuthentication, login, logout, requestPasswordReset, resetPassword, updateUserLanguage } from './service';

interface AuthenticationState {
  user: AuthenticatedUser | null;
  twoFactorAuthenticationToken: string | null;
  isTwoFactorProcessVisible: boolean;
  wasInitialAuthenticationAttempted: boolean;

  loginStatus: ActionStatus;
  confirmTwoFactorAuthenticationStatus: ActionStatus;
  logoutStatus: ActionStatus;
  requestPasswordResetStatus: ActionStatus;
  resetPasswordStatus: ActionStatus;
  getAuthenticationStatus: ActionStatus;
  updateUserLanguageStatus: ActionStatus;
}

function initialState(): AuthenticationState {
  return {
    user: null,
    twoFactorAuthenticationToken: null,
    isTwoFactorProcessVisible: false,
    wasInitialAuthenticationAttempted: false,

    loginStatus: ActionStatus.None,
    confirmTwoFactorAuthenticationStatus: ActionStatus.None,
    logoutStatus: ActionStatus.None,
    requestPasswordResetStatus: ActionStatus.None,
    resetPasswordStatus: ActionStatus.None,
    getAuthenticationStatus: ActionStatus.None,
    updateUserLanguageStatus: ActionStatus.None,
  };
}

export const useAuthenticationStore = defineStore('authentication', {
  state: (): AuthenticationState => initialState(),
  persist: {
    serializer,
  },
  getters: {
    isLoginProcessing: (state: AuthenticationState): boolean =>
      state.loginStatus === ActionStatus.InProgress,
    isConfirmTwoFactorAuthenticationProcessing: (state: AuthenticationState): boolean =>
      state.confirmTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    isLogoutProcessing: (state: AuthenticationState): boolean =>
      state.logoutStatus === ActionStatus.InProgress,
    isRequestPasswordResetProcessing: (state: AuthenticationState): boolean =>
      state.requestPasswordResetStatus === ActionStatus.InProgress,
    isResetPasswordProcessing: (state: AuthenticationState): boolean =>
      state.resetPasswordStatus === ActionStatus.InProgress,
    isGetAuthenticationProcessing: (state: AuthenticationState): boolean =>
      state.getAuthenticationStatus === ActionStatus.InProgress,
    isUpdateUserLanguageProcessing: (state: AuthenticationState): boolean =>
      state.updateUserLanguageStatus === ActionStatus.InProgress,

    hasAccessToKYCManagement: (state: AuthenticationState): boolean =>
      state.user?.hasAccessToKYCManagement ?? false,
    isAuthenticated: (state: AuthenticationState): boolean =>
      !!state.user,
  },
  actions: {

    // -- State management

    partialReset(): Promise<void> {
      // Was initial authentication attempted is never reset.
      this.user = null;
      this.twoFactorAuthenticationToken = null;
      this.isTwoFactorProcessVisible = false;

      this.loginStatus = ActionStatus.None;
      this.confirmTwoFactorAuthenticationStatus = ActionStatus.None;
      this.logoutStatus = ActionStatus.None;
      this.requestPasswordResetStatus = ActionStatus.None;
      this.resetPasswordStatus = ActionStatus.None;
      this.getAuthenticationStatus = ActionStatus.None;

      return Promise.resolve();
    },

    login(command: LoginCommand): Promise<LoginType> {
      const { loginStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        loginStatus,
        () => login(command)
          .then(async (loginResponse) => {
            /**
             * Resolve two-factor authentication.
             */
            if (isTwoFactorAuthenticationLoginResponse(loginResponse)) {
              this.isTwoFactorProcessVisible = true;
              this.twoFactorAuthenticationToken = loginResponse.token;

              return Promise.resolve(loginResponse.loginType);
            }

            const appStore = useAppStore();

            /**
             * Resolve direct login.
             */
            if (loginResponse.authentication!.user.managementAccessForInvestorId
              && !loginResponse.authentication!.user.managementAccessForGeneralPartnerId) {
              await appStore.updateAccountType(AccountTypes.INVESTOR);
            }

            if (loginResponse.authentication!.user.managementAccessForGeneralPartnerId
              && !loginResponse.authentication!.user.managementAccessForInvestorId) {
              await appStore.updateAccountType(AccountTypes.GENERAL_PARTNER);
            }

            await this.updateUserSession(loginResponse.authentication!.user.userId);

            appStore.updateLanguage(loginResponse.authentication!.user.language!);

            i18n.locale = loginResponse.authentication!.user.language!;
            dayjs.locale(loginResponse.authentication!.user.language!);

            this.user = loginResponse.authentication!.user;

            return Promise.resolve(loginResponse.loginType);
          })
      );
    },

    updateTwoFactorProcessVisibility(isTwoFactorProcessVisible: boolean): Promise<void> {
      this.isTwoFactorProcessVisible = isTwoFactorProcessVisible;

      return Promise.resolve();
    },

    confirmTwoFactorAuthentication(command: ConfirmTwoFactorAuthenticationCommand): Promise<void> {
      const { confirmTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmTwoFactorAuthenticationStatus,
        () => confirmTwoFactorAuthentication(command)
          .then(async (authentication) => {
            const appStore = useAppStore();
            if (authentication.user.managementAccessForInvestorId
              && !authentication.user.managementAccessForGeneralPartnerId) {
              await appStore.updateAccountType(AccountTypes.INVESTOR);
            }

            if (authentication.user.managementAccessForGeneralPartnerId
              && !authentication.user.managementAccessForInvestorId) {
              await appStore.updateAccountType(AccountTypes.GENERAL_PARTNER);
            }

            await this.updateUserSession(authentication.user.userId);

            appStore.updateLanguage(authentication.user.language!);

            i18n.locale = authentication.user.language!;
            dayjs.locale(authentication.user.language!);

            this.user = authentication.user;
          })
      );
    },

    requestPasswordReset(command: RequestPasswordResetCommand): Promise<void> {
      const { requestPasswordResetStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestPasswordResetStatus,
        () => requestPasswordReset(command)
      );
    },

    resetPassword(command: ResetPasswordCommand): Promise<void> {
      const { resetPasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        resetPasswordStatus,
        () => resetPassword(command)
      );
    },

    logout(): Promise<void> {
      const { logoutStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        logoutStatus,
        () => logout()
          .then(() => {
            this.user = null;
          })
      );
    },

    getInitialAuthentication(): Promise<void> {
      return this.getAuthentication()
        .finally(() => {
          this.wasInitialAuthenticationAttempted = true;
        });
    },

    getAuthentication(): Promise<void> {
      const { getAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAuthenticationStatus,
        () => getAuthentication()
          .then(async (authentication) => {
            const appStore = useAppStore();
            if (authentication.user.managementAccessForInvestorId && !authentication.user.managementAccessForGeneralPartnerId) {
              await appStore.updateAccountType(AccountTypes.INVESTOR);
            }

            if (authentication.user.managementAccessForGeneralPartnerId && !authentication.user.managementAccessForInvestorId) {
              await appStore.updateAccountType(AccountTypes.GENERAL_PARTNER);
            }

            await this.updateUserSession(authentication.user.userId);

            appStore.updateLanguage(authentication!.user.language!);

            i18n.locale = authentication.user.language!;
            dayjs.locale(authentication.user.language!);

            this.user = authentication.user;
          })
      );
    },

    async impersonateUser(authentication: Authentication): Promise<void> {
      await this.updateUserSession(authentication.user.userId);
      i18n.locale = authentication.user.language!;
      dayjs.locale(authentication.user.language!);

      this.user = authentication.user;
    },

    async updateUserSession(userId: string): Promise<void> {
      await useRefreshedSessionStore().updateLastAuthenticatedUserId(userId);

      // Outdated session is triggered through local storage update
      setLocalStorageItem(LOCAL_STORAGE_USER_ID, userId);
    },

    updateUserLanguage(command: UpdateUserLanguageCommand): Promise<void> {
      i18n.locale = command.language;
      dayjs.locale(command.language);

      const { updateUserLanguageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserLanguageStatus,
        () => updateUserLanguage(command)
          .then(() => useAppStore().updateLanguage(command.language))
      );
    },

  },
});
