type Amount = number;
export interface CalculatePercentageInputMap {
  [id: string]: Amount;
}

type Percentage = number;
export interface CalculatePercentageOutputMap {
  [id: string]: Percentage;
}

interface CalculatePercentageInternalMap {
  [id: string]: {
    percentageAsInt: number;
    relevantDecimalPlaces: number;
    incremented: boolean;
  };
}

/**
 * This algorithm will make the sum of rounded percentages add up to 100
 *
 * Here is a separate explanation of the algorithm:
 * https://stackoverflow.com/questions/13483430/how-to-make-rounded-percentages-add-up-to-100/13483710#13483710
 * I adjusted it to also work with decimal numbers and not run into the floating point problem
 *
 * 1. Get the sum of the amounts given as input
 * 2. Calculate an internal map
 * 3. Use that internal map to ensure the sum of the percentageAsInt will be 100%
 * 4. Build an output map from the internal map with
 *    - the identifiers given from the input as key
 *    - and the calculated percentage wrapped in an object as value
 */
export function calculatePercentage(calculatePercentageInput: CalculatePercentageInputMap): CalculatePercentageOutputMap {
  const totalAmount: number = getTotalAmount(calculatePercentageInput);

  const calculatePercentageInternalMap: CalculatePercentageInternalMap = getCalculatePercentageInternalMap(
    totalAmount,
    calculatePercentageInput
  );

  ensurePercentageWillMatch100(calculatePercentageInternalMap);

  return buildCalculatePercentageOutputMap(calculatePercentageInternalMap);
}

// Returns the sum of the input map amounts
function getTotalAmount(calculatePercentageInput: CalculatePercentageInputMap): number {
  let totalCommittedAmount = 0;

  for (const value of Object.values(calculatePercentageInput)) {
    totalCommittedAmount += value;
  }

  return totalCommittedAmount;
}

/**
 * Calculates an internal map that holds additional attributes
 * - percentageAsInt
 *   - is a floored value so the sum never is higher than 100
 *   - is an integer so we don't run into the floating point problem
 * - relevantDecimalPlaces
 *   - are the two decimal places that are later used to determine which of the percentage numbers should be increased
 * - incremented
 *   - will be used to track whether or not this number has already been incremented. We don't want to increment a number twice
 *
 * example:
 * - the number 12.345678 will have a percentageAsInt value of 1234 relevantDecimalPlaces value of .56
 * - the number 23.456789 will have a percentageAsInt value of 2345 relevantDecimalPlaces value of .67
 * - later we want to increment the numbers with the highest relevant decimal places first, in that case that would be the second one
 * - we then mark this number as incremented so we don't increment it again
 */
function getCalculatePercentageInternalMap(
  totalAmount: number,
  calculatePercentageInput: CalculatePercentageInputMap
): CalculatePercentageInternalMap {
  const calculatePercentageInternalMap: CalculatePercentageInternalMap = {};

  for (const [key, value] of Object.entries(calculatePercentageInput)) {
    calculatePercentageInternalMap[key] = {
      percentageAsInt: Math.floor(value / totalAmount * 100 * 100),
      relevantDecimalPlaces: +(value / totalAmount * 100 * 100 % 1).toFixed(2),
      incremented: false,
    };
  }

  return calculatePercentageInternalMap;
}

/**
 * The sum of the internal map's percentageAsInt values should be 100% (100000 as int)
 * As long as the sum is not 100%, we find the map entry with the highest relevant decimal places that has not been incremented yet
 * Increment that number, set the incremented flag to true and then repeat the process
 */
function ensurePercentageWillMatch100(
  calculatePercentageInternalMap: CalculatePercentageInternalMap
): void {
  let percentageAsIntSum = getPercentageAsIntSum(calculatePercentageInternalMap);

  while (percentageAsIntSum !== 10000) {
    const mapEntryKeyWithHighestDecimalPlace = getNonIncrementedMapEntryKeyWithHighestRelevantDecimalPlace(calculatePercentageInternalMap);
    calculatePercentageInternalMap[mapEntryKeyWithHighestDecimalPlace].percentageAsInt++;
    calculatePercentageInternalMap[mapEntryKeyWithHighestDecimalPlace].incremented = true;

    percentageAsIntSum = getPercentageAsIntSum(calculatePercentageInternalMap);
  }
}

// Returns the sum of the internal map percentageAsInt values
function getPercentageAsIntSum(calculatePercentageInternalMap: CalculatePercentageInternalMap): number {
  let totalPercentageWithFourDecimalPlacesIntegerizedSum = 0;

  for (const value of Object.values(calculatePercentageInternalMap)) {
    totalPercentageWithFourDecimalPlacesIntegerizedSum += value.percentageAsInt;
  }

  return totalPercentageWithFourDecimalPlacesIntegerizedSum;
}

// Returns the key of the map entry with the highest relevant decimal place that has not been incremented yet
function getNonIncrementedMapEntryKeyWithHighestRelevantDecimalPlace(
  calculatePercentageInternalMap: CalculatePercentageInternalMap
): string {
  let resultKey = null;

  for (const [currentKey, currentValue] of Object.entries(calculatePercentageInternalMap)) {
    if (!currentValue.incremented) {
      if (!resultKey) {
        resultKey = currentKey;
      } else if (currentValue.relevantDecimalPlaces > calculatePercentageInternalMap[resultKey].relevantDecimalPlaces) {
        resultKey = currentKey;
      }
    }
  }

  return resultKey!;
}

// Converts the internal map to an output map
function buildCalculatePercentageOutputMap(calculatePercentageInternalMap: CalculatePercentageInternalMap): CalculatePercentageOutputMap {
  const calculatePercentageOutputMap: CalculatePercentageOutputMap = {};

  for (const [currentKey, currentValue] of Object.entries(calculatePercentageInternalMap)) {
    calculatePercentageOutputMap[currentKey] = currentValue.percentageAsInt / 100;
  }

  return calculatePercentageOutputMap;
}
