var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status-block",attrs:{"data-cy":"process-status"}},[_c('div',{staticClass:"step d-flex whitespace-nowrap align-center mb-4",class:{
      'current-step': _vm.isCurrentStep(_vm.SubscriptionProcessStep.AMOUNT_SPECIFICATION),
      'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.AMOUNT_SPECIFICATION),
    },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.AMOUNT_SPECIFICATION)}}},[(_vm.isCurrentStep(_vm.SubscriptionProcessStep.AMOUNT_SPECIFICATION))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 1 ")]):_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'check']}})],1),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.investmentAmount'))+" "),(_vm.store.isInvestmentAmountDefined)?[_vm._v("("+_vm._s(_vm.store.formattedInvestmentAmount)+")")]:_vm._e()],2)]),_c('div',{staticClass:"step d-flex whitespace-nowrap align-center mb-4",class:{
      'current-step': _vm.isCurrentStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION),
      'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION),
    },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION)}}},[(_vm.isCurrentStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 2 ")]):(_vm.isStepComplete(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'check']}})],1):_c('div',{staticClass:"step-icon d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 2 ")]),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.investmentProfileSelection'))+" ")])]),_c('div',{staticClass:"step d-flex whitespace-nowrap align-center mb-4",class:{
      'current-step': _vm.isCurrentStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW),
      'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW),
    },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW)}}},[(_vm.isCurrentStep(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 3 ")]):(_vm.isStepComplete(_vm.SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'check']}})],1):_c('div',{staticClass:"step-icon d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 3 ")]),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.investmentProfileReview'))+" ")])]),(_vm.store.isDocumentManagementForSubscriptionProcessEnabled)?[(_vm.store.hasInvestorKYCAccess)?[_c('div',{staticClass:"step d-flex whitespace-nowrap align-center mb-4",class:{
          'current-step': _vm.isCurrentStep(_vm.SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS),
          'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS),
        },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS)}}},[(_vm.isCurrentStep(_vm.SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 4 ")]):(_vm.isStepComplete(_vm.SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'check']}})],1):_c('div',{staticClass:"step-icon d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" 4 ")]),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.kycDocumentAttachments'))+" ")])])]:_vm._e(),_c('div',{staticClass:"step d-flex whitespace-nowrap align-center mb-4",class:{
        'current-step': _vm.isCurrentStep(_vm.SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS),
        'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS),
      },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS)}}},[(_vm.isCurrentStep(_vm.SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" "+_vm._s(_vm.store.stepNumberForSubscriptionDocumentsStep)+" ")]):(_vm.isStepComplete(_vm.SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'check']}})],1):_c('div',{staticClass:"step-icon d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" "+_vm._s(_vm.store.stepNumberForSubscriptionDocumentsStep)+" ")]),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.uploadSubscriptionDocuments'))+" ")])])]:_vm._e(),_c('div',{staticClass:"step d-flex whitespace-nowrap align-center mb-4",class:{
      'current-step':_vm.isCurrentStep(_vm.SubscriptionProcessStep.REVIEW),
      'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.REVIEW),
    },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.REVIEW)}}},[(_vm.isCurrentStep(_vm.SubscriptionProcessStep.REVIEW))?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" "+_vm._s(_vm.store.stepNumberForConfirmSubscriptionStep)+" ")]):(_vm.store.isSubscriptionProcessInReview)?_c('div',{staticClass:"step-icon completed-step d-flex flex-shrink-0 align-center justify-center body-3"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'check']}})],1):_c('div',{staticClass:"step-icon d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" "+_vm._s(_vm.store.stepNumberForConfirmSubscriptionStep)+" ")]),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.confirmSubscription'))+" ")])]),_c('div',{staticClass:"step d-flex whitespace-nowrap align-center",class:{
      'current-step':_vm.isCurrentStep(_vm.SubscriptionProcessStep.COMPLETION),
      'link': _vm.canChangeStep(_vm.SubscriptionProcessStep.COMPLETION),
    },on:{"click":function($event){return _vm.changeCurrentStep(_vm.SubscriptionProcessStep.COMPLETION)}}},[_c('div',{staticClass:"step-icon d-flex flex-shrink-0 align-center justify-center body-3"},[_vm._v(" "+_vm._s(_vm.store.stepNumberForCompletionStep)+" ")]),_c('div',{staticClass:"label body-2"},[_vm._v(" "+_vm._s(_vm.translate('subscriptionSteps.completeSubscription'))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }