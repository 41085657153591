import { CreateGeneralPartnerCommand, CreateGeneralPartnerUserAccountCommand } from '@/domain/brain/general-partners/create-general-partner/types';
import { generateApiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachBrainiac, attachBrainiacToCommandWithFiles } from '@/helpers/default-parameter-helper';

export function createGeneralPartner(command: CreateGeneralPartnerCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/create-general-partner-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachBrainiacToCommandWithFiles(command) });
}

export function createGeneralPartnerUserAccount(command: CreateGeneralPartnerUserAccountCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/create-general-partner-user-account-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
