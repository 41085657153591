const LOCAL_STORAGE_HELPER_CHECK = 'local-storage-helper-check';

export const LOCAL_STORAGE_GENERAL_PARTNER_ID = 'general-partner-id';
export const LOCAL_STORAGE_USER_ID = 'user-id';
export const LOCAL_STORAGE_BRAINIAC_ID = 'brainiac-id';

export function isLocalStorageAvailable(): boolean {
  try {
    localStorage.setItem(LOCAL_STORAGE_HELPER_CHECK, '1');
    localStorage.removeItem(LOCAL_STORAGE_HELPER_CHECK);
    return true;
  } catch (e) {
    return false;
  }
}

export function getLocalStorageItem(key: string): string|null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // We return null as a case where local storage is not available
    return null;
  }
}

export function setLocalStorageItem(key: string, value: string): void {
  try {
    // This will trigger window.storage event in all but the active tabs (this is for example used to validate outdated sessions).
    localStorage.setItem(key, value);
  } catch (e) {
    // We don't handle a case where local storage is not available
  }
}

export function removeLocalStorageItem(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // We don't handle a case where local storage is not available
  }
}
