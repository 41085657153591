import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { GetUserActivityLogEntriesForProductQuery, ProductUserActivityLogEntries } from './types';

export function getUserActivityLogEntriesForProduct(
  query: GetUserActivityLogEntriesForProductQuery
): Promise<ProductUserActivityLogEntries> {
  const url = generateApiUrl('/api/general-partner/get-user-activity-log-entries-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
