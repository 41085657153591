export const SESSION_STORAGE_WAS_SESSION_REFRESHED = 'was-session-refreshed';

export function getSessionStorageItem(key: string): string|null {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    // We return null as a case where session storage is not available
    return null;
  }
}

export function setSessionStorageItem(key: string, value: string): void {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    // We don't handle a case where session storage is not available
  }
}

export function removeSessionStorageItem(key: string): void {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    // We don't handle a case where session storage is not available
  }
}
