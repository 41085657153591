import { render, staticRenderFns } from "./onboarded-product-kyc-document-attachments-list.vue?vue&type=template&id=3ab3a32a&scoped=true&"
import script from "./onboarded-product-kyc-document-attachments-list.vue?vue&type=script&lang=ts&"
export * from "./onboarded-product-kyc-document-attachments-list.vue?vue&type=script&lang=ts&"
import style0 from "./onboarded-product-kyc-document-attachments-list.vue?vue&type=style&index=0&id=3ab3a32a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab3a32a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
