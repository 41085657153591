import { RouteConfig } from 'vue-router';
import { Layout } from '@/application/whitelabel/app/types';
import Login from './components/login.vue';
import RequestPasswordReset from './components/request-password-reset.vue';
import ResetPassword from './components/reset-password.vue';

export const authenticationRoutes: RouteConfig[] = [
  {
    name: 'authentication/login',
    path: '/login/:language?/:token?/:code?',
    component: Login,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      layout: Layout.PUBLIC,
    },
  },
  {
    name: 'authentication/request-password-reset',
    path: '/request-password-reset',
    component: RequestPasswordReset,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      layout: Layout.PUBLIC,
    },
  },
  {
    name: 'authentication/reset-password',
    path: '/reset-password/:passwordResetRequestId',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      layout: Layout.PUBLIC,
    },
  },
];
