import { Dayjs } from 'dayjs';
import { Command, CommandWithFiles, Query } from '@/application/types';
import { Language, UNodeType } from '@/types/global';
import { Decimal } from '@/models/decimal';

export type FilePreviewStatus = 'CREATE' | 'UPDATE';
export type DirectoryPreviewStatus = 'NO_CHANGE' | 'CREATE';

export type VisibilityOption = 'PUBLIC' | 'SHARED';
export const VisibilityOptions: Record<VisibilityOption, VisibilityOption> = {
  PUBLIC: 'PUBLIC',
  SHARED: 'SHARED',
};

export interface UNodeForChangesPreview {
  type: UNodeType;
  name: string;
}

export interface FileForChangesPreview extends UNodeForChangesPreview {
  status: FilePreviewStatus;
  relativeFilePath: string;
  visibleForClientLanguages: Language[];
}

export interface DirectoryForChangesPreview extends UNodeForChangesPreview {
  /**
   * The id is only set when the directory already exists
   * and it isn't a preview for a new node which will be created
   */
  id?: string;
  status: DirectoryPreviewStatus;
  children: (FileForChangesPreview|DirectoryForChangesPreview)[]
}

export interface FileWithRelativePathAndVisibleLanguageForClient extends FileWithRelativePath {
  visibleForClientLanguages: Language[];
}

export interface DueDiligenceDirectory {
  id: string;
  name: string;
  type: UNodeType;
  children: DueDiligenceUNode[];
}

export interface DueDiligenceFile {
  id: string;
  name: string;
  type: UNodeType;
}

export type DueDiligenceUNode = DueDiligenceDirectory|DueDiligenceFile;

export interface UNode {
  id: string;
  name: string;
  fileSize: Decimal;
  type: UNodeType;
  visibleForClientLanguages: Language[];
}

export interface UNodeFile extends UNode {
  updatedAt: Dayjs;
  visibilityOption: VisibilityOption;
  usersWithExplicitAccess: string[],
}

export interface UNodeDirectory extends UNode {}

export interface BreadCrumb {
  name: string;
  directoryId?: string;
}

// -- Queries

export interface GetAvailableDueDiligenceFilesForProductQuery extends Query {
  productId: string;
  language: Language;
}

export interface GetDueDiligenceFileForDownloadQuery extends Query {
  fileId: string;
}

export interface GetPreviewForDueDiligenceFileAndDirectoryChangesQuery extends Query {
  productId: string;
  parentDirectoryId?: string;
  relativeFilePaths: string[];
  selectedLanguage: Language;
}

export interface GetPreviewForDueDiligenceFileChangesQuery extends Query {
  productId: string;
  parentDirectoryId?: string;
  fileNames: string[];
  selectedLanguage: Language;
}

export interface GetBreadCrumbsForDueDiligenceDataRoomQuery extends Query {
  productId?: string;
  dataRoom?: string;
  directoryId?: string;
}

export interface GetUNodesForDueDiligenceDataRoomQuery extends Query {
  productId: string;
  directoryId?: string;
}

export interface GetDueDiligenceDirectoryForDownloadQuery extends Query {
  directoryId: string;
}

export interface GetProductNDAForDownloadQuery extends Query {
  productId: string;
  language: Language;
  isPreviewFileRequested: boolean;
}

// -- Commands

export interface UpdateProductPinnedDueDiligenceFilesOrderCommand extends Command {
  productId: string;
  fileIds: string[];
  language: string;
}

export interface UnpinDueDiligenceFileCommand extends Command {
  productId: string;
  fileId: string;
  language: string;
}

export interface PinDueDiligenceFileCommand extends Command {
  productId: string;
  fileId: string;
  language: string;
}

export interface CreateDueDiligenceDirectoryCommand extends Command {
  productId: string;
  parentDirectoryId?: string;
  name: string;
  visibleForClientLanguages: Language[];
}

export interface CreateDueDiligenceFileCommand extends CommandWithFiles {
  body: {
    productId: string;
    parentDirectoryId?: string;
    visibleForClientLanguage: Language;
  };
  files: {
    file: File;
  };
}

export interface CreateDueDiligenceFileWithVisibleLanguagesForClientCommand extends CommandWithFiles {
  body: {
    productId: string;
    parentDirectoryId?: string;
    visibleForClientLanguages: Language[];
  };
  files: {
    file: File;
  };
}

export interface CreatePrivateDueDiligenceFileCommand extends CommandWithFiles {
  body: {
    productId: string;
    parentDirectoryId?: string;
    visibleForClientLanguages: Language[];
    idsOfInvestorUsersWithExplicitAccess: string[];
  };
  files: {
    file: File;
  };
}

export interface UpdateDueDiligenceFileCommand extends CommandWithFiles {
  body: {
    fileId: string;
    visibleForClientLanguage: Language;
  };
  files: {
    file: File;
  };
}

export interface UpdateDueDiligenceFileWithVisibleLanguagesForClientCommand extends CommandWithFiles {
  body: {
    fileId: string;
    visibleForClientLanguages: Language[];
  };
  files: {
    file: File;
  };
}
export interface UpdatePrivateDueDiligenceFileCommand extends CommandWithFiles {
  body: {
    fileId: string;
    visibleForClientLanguages: Language[];
    idsOfInvestorUsersWithExplicitAccess: string[];
  };
  files: {
    file: File;
  };
}

export interface UploadDueDiligenceFileWithPathCommand extends CommandWithFiles {
  body: {
    productId: string;
    parentDirectoryId?: string;
    relativeDirectoryPath: string;
    visibleForClientLanguages: Language[];
  };
  files: {
    file: FileWithRelativePath;
  };
}

export interface CreateProductNDACommand extends CommandWithFiles {
  body: {
    productId: string;
    visibleForClientLanguage: Language;
  };
  files: {
    productNDA: File;
  };
}

export interface UpdateDueDiligenceDirectoryNameAndVisibleLanguagesForClientCommand extends Command {
  directoryId: string;
  name: string;
  visibleForClientLanguages: Language[];
}

export interface UpdateDueDiligenceFileVisibleLanguagesForClientCommand extends Command {
  fileId: string;
  visibleForClientLanguages: Language[];
}

export interface SendMessageToInvestorUsersCommand extends Command {
  productId: string;
  message: string;
  fileNames: string[];
  idsOfInvestorUsersWithExplicitAccess: string[];
}

export interface UpdateUsersWithExplicitAccessForFileCommand extends Command {
  fileId: string;
  idsOfInvestorUsersWithExplicitAccess: string[];
}

export interface DeleteDueDiligenceFileCommand extends Command {
  fileId: string;
}

export interface DeleteDueDiligenceDirectoryCommand extends Command {
  directoryId: string;
}

export interface DeleteProductNDACommand extends Command {
  productId: string;
  language: Language;
}
