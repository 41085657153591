import { render, staticRenderFns } from "./pill.vue?vue&type=template&id=4e046500&scoped=true&"
import script from "./pill.vue?vue&type=script&lang=ts&"
export * from "./pill.vue?vue&type=script&lang=ts&"
import style0 from "./pill.vue?vue&type=style&index=0&id=4e046500&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e046500",
  null
  
)

export default component.exports