import Vue from 'vue';
import Headline from '@/components/headline.vue';
import Tooltip from '@/components/tooltip.vue';
import FormRules from '@/components/form-rules.vue';
import IconButton from '@/components/icon-button.vue';
import Autocomplete from '@/components/autocomplete.vue';
import StringNumberInput from '@/components/string-number-input.vue';
import StringNumberRangeInput from '@/components/string-number-range-input.vue';
import DeprecatedForm from '@/components/deprecated-form.vue';
import TextInput from '@/components/text-input.vue';
import PhoneNumberInputs from '@/components/phone-number-inputs.vue';
import FilteredMultiCheckboxField from '@/components/filtered-multi-checkbox-field.vue';
import Pill from '@/components/pill.vue';
import TiptapEditor from '@/components/tiptap-editor.vue';
import DatePicker from '@/components/date-picker.vue';
import MonthPicker from '@/components/month-picker.vue';
import Img from '@/components/img.vue';
import KPIValueFormField from '@/components/kpi-value-form-field.vue';
import InputUploadButton from '@/components/input-upload-button.vue';
import TranslatedTextInput from '@/components/translated-text-input.vue';
import TranslatedTextAreaInput from '@/components/translated-textarea-input.vue';
import TranslatedTipTapEditor from '@/components/translated-tiptap-editor.vue';
import FileSelectorInput from '@/components/file-selector-input.vue';

Vue.component('a-headline', Headline);
Vue.component('a-tooltip', Tooltip);
Vue.component('a-form-rules', FormRules);
Vue.component('a-icon-button', IconButton);
Vue.component('d-form', DeprecatedForm); // Deprecated, separate name due to naming conflict
Vue.component('a-autocomplete', Autocomplete);
Vue.component('a-string-number-input', StringNumberInput);
Vue.component('a-text-input', TextInput);
Vue.component('a-kpi-value-form-field', KPIValueFormField);
Vue.component('a-string-number-range-input', StringNumberRangeInput);
Vue.component('a-phone-number-inputs', PhoneNumberInputs);
Vue.component('a-filtered-multi-checkbox-field', FilteredMultiCheckboxField);
Vue.component('a-pill', Pill);
Vue.component('a-tiptap-editor', TiptapEditor);
Vue.component('a-date-picker', DatePicker);
Vue.component('a-month-picker', MonthPicker);
Vue.component('a-img', Img);
Vue.component('a-input-upload-button', InputUploadButton);
Vue.component('a-translated-text-input', TranslatedTextInput);
Vue.component('a-translated-textarea-input', TranslatedTextAreaInput);
Vue.component('a-translated-tiptap-editor', TranslatedTipTapEditor);
Vue.component('a-file-selector-input', FileSelectorInput);
