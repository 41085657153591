import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { createInvestorUserAccount, getInvestorUserInviteByToken } from './service';
import { CreateAccountFormValues, CreateAccountStep, InvestorUserInvite, MoveToAcceptAgreementsStepCommand, MoveToSecureAccountStepCommand, CreateInvestorUserAccountCommand, GetInvestorUserInviteByTokenQuery } from './types';

interface InvestorCreateAccountState {
  currentCreateAccountStep: CreateAccountStep;
  createAccountFormValues: CreateAccountFormValues;
  investorUserInvite: InvestorUserInvite | null;

  getInvestorUserInviteByTokenStatus: ActionStatus;
  createInvestorUserAccountStatus: ActionStatus;
}

function initialState(): InvestorCreateAccountState {
  return {
    currentCreateAccountStep: CreateAccountStep.ACCEPT_INVITATION,
    createAccountFormValues: {},
    investorUserInvite: null,

    getInvestorUserInviteByTokenStatus: ActionStatus.None,
    createInvestorUserAccountStatus: ActionStatus.None,
  };
}

export const useInvestorCreateAccountStore = defineStore('investorCreateAccount', {
  state: (): InvestorCreateAccountState => initialState(),
  getters: {
    isCreateInvestorUserAccountProcessing: (state: InvestorCreateAccountState): boolean =>
      state.createInvestorUserAccountStatus === ActionStatus.InProgress,
    isGetInvestorUserInviteByTokenProcessing: (state: InvestorCreateAccountState): boolean =>
      state.getInvestorUserInviteByTokenStatus === ActionStatus.InProgress,
    isAcceptInvitationStep: (state: InvestorCreateAccountState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.ACCEPT_INVITATION,
    isPersonalInformationStep: (state: InvestorCreateAccountState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.PERSONAL_INFORMATION,
    isSecureAccountStep: (state: InvestorCreateAccountState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.SECURE_ACCOUNT,
    isAcceptAgreementsStep: (state: InvestorCreateAccountState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.ACCEPT_AGREEMENTS,
  },
  actions: {

    moveToPersonalInformationStep(): Promise<void> {
      this.currentCreateAccountStep = CreateAccountStep.PERSONAL_INFORMATION;

      return Promise.resolve();
    },
    moveToSecureAccountStep(command: MoveToSecureAccountStepCommand): Promise<void> {
      this.currentCreateAccountStep = CreateAccountStep.SECURE_ACCOUNT;
      this.createAccountFormValues = {
        ...this.createAccountFormValues,
        ...command.createAccountFormValues,
      };

      return Promise.resolve();
    },
    moveToAcceptAgreementsStep(command: MoveToAcceptAgreementsStepCommand): Promise<void> {
      this.currentCreateAccountStep = CreateAccountStep.ACCEPT_AGREEMENTS;
      this.createAccountFormValues = {
        ...this.createAccountFormValues,
        ...command.createAccountFormValues,
      };

      return Promise.resolve();
    },

    // -- Queries

    getInvestorUserInviteByToken(query: GetInvestorUserInviteByTokenQuery): Promise<void> {
      const { createInvestorUserAccountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createInvestorUserAccountStatus,
        () => getInvestorUserInviteByToken(query)
          .then((investorUserInvite) => {
            this.investorUserInvite = investorUserInvite;
          })
      );
    },

    // -- Commands

    createInvestorUserAccount(command: CreateInvestorUserAccountCommand): Promise<void> {
      const { createInvestorUserAccountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createInvestorUserAccountStatus,
        () => createInvestorUserAccount(command)
      );
    },

  },
});
