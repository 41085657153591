import { DataOptions } from 'vuetify';

export interface PaginationOptions {
  limit: number;
  offset: number;
}

export function getDefaultTableOptions(): DataOptions {
  return {
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
}

export function getPaginationOptionsFromTableOptions(tableOptions: DataOptions): PaginationOptions {
  const limit = tableOptions.itemsPerPage;
  const offset = tableOptions.page !== 1
    ? limit * (tableOptions.page - 1)
    : 0;

  return { limit, offset };
}
