var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"investments mr-md-8",attrs:{"data-cy":"investments"}},[_c('h4',[_vm._v(_vm._s(_vm.translate('title')))]),_c('v-data-table',{attrs:{"disable-filtering":"","disable-pagination":"","hide-default-footer":"","headers":_vm.investmentsTableHeaders,"items":_vm.store.investments,"item-key":"investmentId","loading":_vm.store.isGetInvestmentsOfInvestorForOnboardedProductProcessing,"mobile-breakpoint":960},scopedSlots:_vm._u([{key:"item.closingDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(_vm._f("formatDate")(item.closingDate)))]),_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"text":_vm.getInvestmentTypeLabel(item),"color":_vm.getPillColorForInvestmentType(item)}})]}},{key:"item.committedCapital",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-md-right my-3"},[_c('div',{staticClass:"body-3 grey--text text--darken-2"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.shares,null, true, _vm.DISPLAY_DECIMALS.INVESTMENT.shares))+" "+_vm._s(_vm.translate('shares')))]),_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.committedCapital,_vm.store.productDetails.currency)))])])]}},{key:"item.entryPricePerShare",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.entryPricePerShare,_vm.store.productDetails.currency))+" ")]}},{key:"item.placementAgent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.placementAgent)+" ")]}},{key:"body.append",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"d-table-cell body-2 black--text"},[_vm._v(" "+_vm._s(_vm.translate('total'))+" ")]),_c('td',{staticClass:"d-table-cell text-right body-2 black--text"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.store.totalCommittedCapital,_vm.store.productDetails.currency))+" ")]),_c('td',{staticClass:"d-table-cell text-right body-2 black--text whitespace-nowrap"},[_vm._v(" ∅ "+_vm._s(_vm._f("formatCurrency")(_vm.store.averageEntryPrice,_vm.store.productDetails.currency))+" ")]),_c('td',{staticClass:"d-table-cell body-2 black--text",attrs:{"colspan":"2"}})]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }