import { render, staticRenderFns } from "./investment-profiles-list.vue?vue&type=template&id=3ff54973&"
import script from "./investment-profiles-list.vue?vue&type=script&lang=ts&"
export * from "./investment-profiles-list.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VDataTable,VList,VListItem,VListItemIcon,VListItemTitle,VMenu})
