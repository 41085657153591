import { Decimal, Money } from '@/models/decimal';

export function sortByDecimals(itemA: Decimal, itemB: Decimal): number {
  return (itemA.toNumber() > itemB.toNumber()) ? 1 : -1;
}

export function sortByMoney(itemA: Money[], itemB: Money[]): number {
  const aValue = itemA.reduce((amount, money) => money.amount.toNumber() + amount, 0);
  const bValue = itemB.reduce((amount, money) => money.amount.toNumber() + amount, 0);

  return aValue > bValue ? 1 : -1;
}
