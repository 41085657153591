import { defineStore, storeToRefs } from 'pinia';
import { serializer, wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { ColorScheme } from '@/types/color-scheme';
import { getAppColorSchemeByBaseColors } from '@/helpers/color-helpers';
import { LOCAL_STORAGE_GENERAL_PARTNER_ID, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { currentYear } from '@/helpers/date-helpers';
import { getLogoUrl, getPublicHeroImageUrl } from './url-helper';
import { GeneralPartner } from './types';
import { getGeneralPartner } from './service';

interface GeneralPartnerState {
  generalPartner: GeneralPartner | null;
  colorScheme: ColorScheme | null;

  getGeneralPartnerStatus: ActionStatus;
}

function initialState(): GeneralPartnerState {
  return {
    generalPartner: null,
    colorScheme: null,
    getGeneralPartnerStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerStore = defineStore('generalPartner', {
  state: (): GeneralPartnerState => initialState(),
  persist: {
    serializer,
  },
  getters: {
    isGetGeneralPartnerProcessing: (state: GeneralPartnerState): boolean =>
      state.getGeneralPartnerStatus === ActionStatus.InProgress,

    copyRightText: (state: GeneralPartnerState): string =>
      `© ${currentYear()} ${state.generalPartner?.title}`,
    logoUrl: (state: GeneralPartnerState): string =>
      state.generalPartner
        ? getLogoUrl(state.generalPartner.generalPartnerId)
        : '',
    publicHeroImageUrl: (state: GeneralPartnerState): string =>
      state.generalPartner
        ? getPublicHeroImageUrl(state.generalPartner.generalPartnerId)
        : '',
    requestPlatformAccessUrl: (state: GeneralPartnerState): string =>
      state.generalPartner
        ? state.generalPartner.requestPlatformAccessUrl!
        : '',
  },
  actions: {

    // -- Queries

    getGeneralPartner(): Promise<void> {
      const { getGeneralPartnerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerStatus,
        () => getGeneralPartner()
          .then((generalPartner) => {
            setLocalStorageItem(LOCAL_STORAGE_GENERAL_PARTNER_ID, generalPartner.generalPartnerId);
            this.generalPartner = generalPartner;
            this.colorScheme = getAppColorSchemeByBaseColors(
              generalPartner.colors.primary,
              generalPartner.colors.secondary,
              generalPartner.colors.green,
              generalPartner.colors.success,
              generalPartner.colors.red,
              generalPartner.colors.error,
              generalPartner.colors.yellow,
              generalPartner.colors.warning,
              generalPartner.colors.grey,
              generalPartner.colors.info,
              generalPartner.colors.purple,
              generalPartner.colors.cyan
            );
          })
      );
    },

  },
});
