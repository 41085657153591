import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { getAdvisor, sendAdvisorMessage } from './service';
import { Advisor, SendMessageToAdvisorCommand } from './types';

interface InvestorAdvisorInfoState {
  advisor: Advisor | null;

  getAdvisorStatus: ActionStatus;
  sendAdvisorMessageStatus: ActionStatus;
}

function initialState(): InvestorAdvisorInfoState {
  return {
    advisor: null,
    getAdvisorStatus: ActionStatus.None,
    sendAdvisorMessageStatus: ActionStatus.None,
  };
}

export const useInvestorAdvisorInfoStore = defineStore('investorAdvisorInfo', {
  state: (): InvestorAdvisorInfoState => initialState(),
  getters: {
    isGetAdvisorProcessing: (state: InvestorAdvisorInfoState): boolean =>
      state.getAdvisorStatus === ActionStatus.InProgress,
    isSendAdvisorMessageProcessing: (state: InvestorAdvisorInfoState): boolean =>
      state.sendAdvisorMessageStatus === ActionStatus.InProgress,

    hasAdvisor: (state: InvestorAdvisorInfoState): boolean =>
      !!state.advisor,
    hasPhoneNumber: (state: InvestorAdvisorInfoState): boolean =>
      !!state.advisor?.phoneNumber,
    completePhoneNumber: (state: InvestorAdvisorInfoState): string =>
      state.advisor
        ? (state.advisor.phoneNumber.countryCode + state.advisor.phoneNumber.phoneNumber)
          .split(' ')
          .join('')
        : '',
  },
  actions: {

    // -- Queries

    getAdvisor(): Promise<void> {
      const { getAdvisorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAdvisorStatus,
        () => getAdvisor()
          .then((advisor) => {
            this.advisor = advisor;
          })
      );
    },

    // -- Commands

    sendAdvisorMessage(command: SendMessageToAdvisorCommand): Promise<void> {
      const { sendAdvisorMessageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        sendAdvisorMessageStatus,
        () => sendAdvisorMessage(command)
      );
    },

  },
});
