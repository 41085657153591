import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { GeneralPartnerConfigurations, GeneralPartnerContactDetails, GeneralPartnerLegals, GetGeneralPartnerConfigurationsQuery, GetGeneralPartnerContactDetailsQuery, GetGeneralPartnerLegalsQuery, ToggleTwoFactorAuthenticationCommand, ToggleTwoFactorAuthenticationWithSMSCommand, UpdateGeneralPartnerColorSchemeCommand, UpdateGeneralPartnerContactDetailsCommand, UpdateGeneralPartnerDataProtectionCommand, UpdateGeneralPartnerDefaultProductContactEmailAddressCommand, UpdateGeneralPartnerHostCommand, UpdateGeneralPartnerImprintCommand, UpdateGeneralPartnerLogoCommand, UpdateGeneralPartnerMailerDSNCommand, UpdateGeneralPartnerMailerSenderEmailCommand, UpdateGeneralPartnerPublicHeroImageCommand, UpdateGeneralPartnerTitleCommand } from './types';
import { getGeneralPartnerConfigurations, getGeneralPartnerContactDetails, getGeneralPartnerLegals, toggleTwoFactorAuthentication, toggleTwoFactorAuthenticationWithSms, updateGeneralPartnerColorScheme, updateGeneralPartnerContactDetails, updateGeneralPartnerDataProtection, updateGeneralPartnerDefaultProductContactEmailAddress, updateGeneralPartnerHost, updateGeneralPartnerImprint, updateGeneralPartnerLogo, updateGeneralPartnerMailerDSN, updateGeneralPartnerMailerSenderEmail, updateGeneralPartnerPublicHeroImage, updateGeneralPartnerTitle } from './service';

interface GeneralPartnerDetailsState {
  generalPartnerId: string| null;
  generalPartnerContactDetails: GeneralPartnerContactDetails|null;
  generalPartnerConfigurations: GeneralPartnerConfigurations|null;
  generalPartnerLegals: GeneralPartnerLegals|null;

  getGeneralPartnerContactDetailsStatus: ActionStatus;
  updateGeneralPartnerContactDetailsStatus: ActionStatus;
  getGeneralPartnerConfigurationsStatus: ActionStatus;
  getGeneralPartnerLegalsStatus: ActionStatus;
  updateGeneralPartnerDefaultProductContactEmailAddressStatus: ActionStatus;
  toggleTwoFactorAuthenticationStatus: ActionStatus;
  toggleTwoFactorAuthenticationWithSMSStatus: ActionStatus;
  updateGeneralPartnerColorSchemeStatus: ActionStatus;
  updateGeneralPartnerDataProtectionStatus: ActionStatus;
  updateGeneralPartnerImprintStatus: ActionStatus;
  updateGeneralPartnerTitleStatus: ActionStatus;
  updateGeneralPartnerLogoStatus: ActionStatus;
  updateGeneralPartnerPublicHeroImageStatus: ActionStatus;
  updateGeneralPartnerHostStatus: ActionStatus;
  updateGeneralPartnerMailerDSNStatus: ActionStatus;
  updateGeneralPartnerMailerSenderEmailStatus: ActionStatus;
}

function initialState(): GeneralPartnerDetailsState {
  return {
    generalPartnerId: null,
    generalPartnerContactDetails: null,
    generalPartnerConfigurations: null,
    generalPartnerLegals: null,

    getGeneralPartnerContactDetailsStatus: ActionStatus.None,
    updateGeneralPartnerContactDetailsStatus: ActionStatus.None,
    getGeneralPartnerConfigurationsStatus: ActionStatus.None,
    getGeneralPartnerLegalsStatus: ActionStatus.None,
    updateGeneralPartnerDefaultProductContactEmailAddressStatus: ActionStatus.None,
    toggleTwoFactorAuthenticationStatus: ActionStatus.None,
    toggleTwoFactorAuthenticationWithSMSStatus: ActionStatus.None,
    updateGeneralPartnerColorSchemeStatus: ActionStatus.None,
    updateGeneralPartnerDataProtectionStatus: ActionStatus.None,
    updateGeneralPartnerImprintStatus: ActionStatus.None,
    updateGeneralPartnerTitleStatus: ActionStatus.None,
    updateGeneralPartnerLogoStatus: ActionStatus.None,
    updateGeneralPartnerPublicHeroImageStatus: ActionStatus.None,
    updateGeneralPartnerHostStatus: ActionStatus.None,
    updateGeneralPartnerMailerDSNStatus: ActionStatus.None,
    updateGeneralPartnerMailerSenderEmailStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerDetailsStore = defineStore('generalPartnerDetails', {
  state: (): GeneralPartnerDetailsState => initialState(),
  getters: {
    isGetGeneralPartnerContactDetailsProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.getGeneralPartnerContactDetailsStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerContactDetailsProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerContactDetailsStatus === ActionStatus.InProgress,
    isGetGeneralPartnerConfigurationsProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.getGeneralPartnerConfigurationsStatus === ActionStatus.InProgress,
    isGetGeneralPartnerLegalsProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.getGeneralPartnerLegalsStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerDefaultProductContactEmailAddressProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerDefaultProductContactEmailAddressStatus === ActionStatus.InProgress,
    isToggleTwoFactorAuthenticationStatusProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.toggleTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    isToggleTwoFactorAuthenticationWithSMSStatusProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.toggleTwoFactorAuthenticationWithSMSStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerColorSchemeProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerColorSchemeStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerDataProtectionProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerDataProtectionStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerImprintProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerImprintStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerTitleProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerTitleStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerLogoProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerLogoStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerPublicHeroImageProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerPublicHeroImageStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerHostProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerHostStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerMailerDSNProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerMailerDSNStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerMailerSenderEmailProcessing: (state: GeneralPartnerDetailsState): boolean =>
      state.updateGeneralPartnerMailerSenderEmailStatus === ActionStatus.InProgress,
  },
  actions: {
    // -- State management

    updateCurrentGeneralPartnerId(currentGeneralPartnerId: string): Promise<void> {
      this.generalPartnerId = currentGeneralPartnerId;

      return Promise.resolve();
    },

    // -- Queries

    getGeneralPartnerContactDetails(): Promise<void> {
      const query: GetGeneralPartnerContactDetailsQuery = {
        generalPartnerId: this.generalPartnerId!,
      };

      const { getGeneralPartnerContactDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerContactDetailsStatus,
        () => getGeneralPartnerContactDetails(query)
          .then((generalPartnerContactDetails) => {
            this.generalPartnerContactDetails = generalPartnerContactDetails;
          })
      );
    },

    getGeneralPartnerConfigurations(): Promise<void> {
      const query: GetGeneralPartnerConfigurationsQuery = {
        generalPartnerId: this.generalPartnerId!,
      };

      const { getGeneralPartnerConfigurationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerConfigurationsStatus,
        () => getGeneralPartnerConfigurations(query)
          .then((generalPartnerConfigurations) => {
            this.generalPartnerConfigurations = generalPartnerConfigurations;
          })
      );
    },

    getGeneralPartnerLegals(): Promise<void> {
      const query: GetGeneralPartnerLegalsQuery = {
        generalPartnerId: this.generalPartnerId!,
      };

      const { getGeneralPartnerLegalsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerLegalsStatus,
        () => getGeneralPartnerLegals(query)
          .then((generalPartnerLegals) => {
            this.generalPartnerLegals = generalPartnerLegals;
          })
      );
    },

    // -- Commands

    updateGeneralPartnerContactDetails(command: UpdateGeneralPartnerContactDetailsCommand): Promise<void> {
      const { updateGeneralPartnerContactDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerContactDetailsStatus,
        () => updateGeneralPartnerContactDetails(command)
          .then(() => this.getGeneralPartnerContactDetails())
      );
    },

    updateGeneralPartnerDefaultProductContactEmailAddress(
      command: UpdateGeneralPartnerDefaultProductContactEmailAddressCommand
    ): Promise<void> {
      const { updateGeneralPartnerDefaultProductContactEmailAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerDefaultProductContactEmailAddressStatus,
        () => updateGeneralPartnerDefaultProductContactEmailAddress(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    toggleTwoFactorAuthentication(
      command: ToggleTwoFactorAuthenticationCommand
    ): Promise<void> {
      const { toggleTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        toggleTwoFactorAuthenticationStatus,
        () => toggleTwoFactorAuthentication(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    toggleTwoFactorAuthenticationWithSms(
      command: ToggleTwoFactorAuthenticationWithSMSCommand
    ): Promise<void> {
      const { toggleTwoFactorAuthenticationWithSMSStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        toggleTwoFactorAuthenticationWithSMSStatus,
        () => toggleTwoFactorAuthenticationWithSms(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerColorScheme(
      command: UpdateGeneralPartnerColorSchemeCommand
    ): Promise<void> {
      const { updateGeneralPartnerColorSchemeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerColorSchemeStatus,
        () => updateGeneralPartnerColorScheme(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerDataProtection(command: UpdateGeneralPartnerDataProtectionCommand): Promise<void> {
      const { updateGeneralPartnerDataProtectionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerDataProtectionStatus,
        () => updateGeneralPartnerDataProtection(command)
          .then(() => this.getGeneralPartnerLegals())
      );
    },

    updateGeneralPartnerImprint(command: UpdateGeneralPartnerImprintCommand): Promise<void> {
      const { updateGeneralPartnerImprintStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerImprintStatus,
        () => updateGeneralPartnerImprint(command)
          .then(() => this.getGeneralPartnerLegals())
      );
    },

    updateGeneralPartnerTitle(command: UpdateGeneralPartnerTitleCommand): Promise<void> {
      const { updateGeneralPartnerTitleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerTitleStatus,
        () => updateGeneralPartnerTitle(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerLogo(command: UpdateGeneralPartnerLogoCommand): Promise<void> {
      const { updateGeneralPartnerLogoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerLogoStatus,
        () => updateGeneralPartnerLogo(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerPublicHeroImage(command: UpdateGeneralPartnerPublicHeroImageCommand): Promise<void> {
      const { updateGeneralPartnerPublicHeroImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerPublicHeroImageStatus,
        () => updateGeneralPartnerPublicHeroImage(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerHost(command: UpdateGeneralPartnerHostCommand): Promise<void> {
      const { updateGeneralPartnerHostStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerHostStatus,
        () => updateGeneralPartnerHost(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerMailerDSN(command: UpdateGeneralPartnerMailerDSNCommand): Promise<void> {
      const { updateGeneralPartnerMailerDSNStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerMailerDSNStatus,
        () => updateGeneralPartnerMailerDSN(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

    updateGeneralPartnerMailerSenderEmail(command: UpdateGeneralPartnerMailerSenderEmailCommand): Promise<void> {
      const { updateGeneralPartnerMailerSenderEmailStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerMailerSenderEmailStatus,
        () => updateGeneralPartnerMailerSenderEmail(command)
          .then(() => this.getGeneralPartnerConfigurations())
      );
    },

  },
});
