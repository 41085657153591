export const fallbackTimezone = 'Europe/Berlin';

export const DialogWidth = {
  SMALL: 500,
  LARGE: 800,
};

export const TooltipWidth = {
  SMALL: 200,
  LARGE: 280,
};

export const MenuWidth = {
  SMALL: 250,
  MEDIUM: 290,
  LARGE: 400,
};

export const MenuHeight = {
  MEDIUM: 350,
};

// The maximum number JSON encoded in non-scientific notation (1e+20)
// < 2^53 to avoid Javascript precision issues
export const MAX_NUMBER_AMOUNT = 1000000000000000;

export const fallbackCountryCode = 'DE';

export const DISPLAY_DECIMALS = {
  INVESTMENT: {
    shares: 2,
  },
  HOLDINGS: {
    totalShares: 2,
  },
};

export const DECIMALS = {
  PRODUCT: {
    targetSize: 2,
    minimumInvestmentSize: 2,
  },
  PRODUCT_REPORT: {
    fileSize: 0,
    reportYear: 0,
    reportQuarter: 0,
    reportMonth: 0,
  },
  INVESTMENT_PROFILE: {
    CONTROLLING_PERSON: {
      PERCENTAGE_OF_OWNERSHIP: 0,
    },
  },
  INVESTOR: {
    openTicket: 2,
  },
  INVESTMENT: {
    entryPricePerShare: 2,
    shares: 9,
    committedCapital: 2,
    latestValuePerShare: 2,
  },
  SUBSCRIPTION_PROCESS: {
    investmentAmount: 2,
  },
  UNODE: {
    fileSize: 0,
  },
  ANALYTICS: {
    totalVisits: 0,
    uniqueVisits: 0,
  },
  FUNDRAISING: {
    committedCapital: 2,
    committedCapitalInPercent: 0,
    missingCapital: 2,
    amountInvestors: 0,
    changeCurrentMonth: 2,
    amount: 2,
  },
  HOLDINGS: {
    averageEntryPricePerShare: 2,
    totalCommittedCapital: 2,
    totalShares: 9,
    portfolioShareInPercent: 0,
  },
  IN_APP_NOTIFICATIONS: {
    unseenCount: 0,
    totalCount: 0,
    limit: 0,
  },
  TRANSACTIONS: {
    amount: 2,
  },
};
