import Vue from 'vue';
// -- Form helpers
import CancelActionButton from '@/components/form/cancel-action-button.vue';
import FormControlMessages from '@/components/form/form-control-messages.vue';
import Form from '@/components/form/form.vue';
import SubmitActionButton from '@/components/form/submit-action-button.vue';
// -- Form controls (alphabetically)
import CountryFormControl from '@/components/form/country-form-control.vue';
import EmailAddressFormControl from '@/components/form/email-address-form-control.vue';
import EnabledLanguagesFormControl from '@/components/form/enabled-languages-form-control.vue';
import PasswordFormControl from '@/components/form/password-form-control.vue';
import PhoneNumberFormControl from '@/components/form/phone-number-form-control.vue';
import SalutationFormControl from '@/components/form/salutation-form-control.vue';
import TextFormControl from '@/components/form/text-form-control.vue';

Vue.component('a-cancel-action-button', CancelActionButton);
Vue.component('a-form-control-messages', FormControlMessages);
Vue.component('a-form', Form);
Vue.component('a-submit-action-button', SubmitActionButton);

Vue.component('a-country-form-control', CountryFormControl);
Vue.component('a-email-address-form-control', EmailAddressFormControl);
Vue.component('a-enabled-language-form-control', EnabledLanguagesFormControl);
Vue.component('a-password-form-control', PasswordFormControl);
Vue.component('a-phone-number-form-control', PhoneNumberFormControl);
Vue.component('a-salutation-form-control', SalutationFormControl);
Vue.component('a-text-form-control', TextFormControl);
