import { LocaleMessage } from 'vue-i18n';
import { RawLocation } from 'vue-router';
import i18n from '@/plugins/vue-i18n';
import { InAppNotificationTypes } from '@/types/global';
import { InAppNotification } from '@/application/whitelabel/in-app-notifications/types';

export interface NotificationLocation {
  type: 'navigate' | 'location';
  location: RawLocation | string;
}

export function getNotificationButtonLabel(notification: InAppNotification): LocaleMessage {
  switch (notification.type) {
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_CREATED:
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_PUBLISHED:
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_UNPUBLISHED:
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED:
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED:
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED:
    case InAppNotificationTypes.LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED:
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_PUBLISHED:
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_REPORT_CREATED:
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_ACCESS_GRANTED:
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED:
      return i18n.t('helpers.data.notificationLocationLabels.product');
    case InAppNotificationTypes.GP_MESSAGE_LP_ACCOUNT_ACTIVATED:
      return i18n.t('helpers.data.notificationLocationLabels.users');
    case InAppNotificationTypes.GP_MESSAGE_INDICATED_INTEREST:
    case InAppNotificationTypes.GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED:
      return i18n.t('helpers.data.notificationLocationLabels.investor');
    case InAppNotificationTypes.LP_MESSAGE_INVESTMENT_CREATED:
    case InAppNotificationTypes.LP_MESSAGE_INCOMING_TRANSACTION_CREATED:
    case InAppNotificationTypes.LP_MESSAGE_OUTGOING_TRANSACTION_CREATED:
      return i18n.t('helpers.data.notificationLocationLabels.holdings');
    case InAppNotificationTypes.LP_MESSAGE_ACCOUNT_ACTIVATED:
      return i18n.t('helpers.data.notificationLocationLabels.email');
    default:
      return '';
  }
}

export function getNotificationLocation(notification: InAppNotification): NotificationLocation | null {
  switch (notification.type) {
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_CREATED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-product-management-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_PUBLISHED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-product-management-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_UNPUBLISHED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-product-management-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-product-management-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-product-management-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_LP_ACCOUNT_ACTIVATED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-users' },
      };
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-investor-view-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_INDICATED_INTEREST:
      return {
        type: 'navigate',
        location: { name: 'general-partner-investor-details', params: { investorId: notification.meta.investorId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_PLATFORM_NEWS_PUBLISHED:
      return {
        type: 'navigate',
        location: { name: 'notification-center-details', params: { notificationId: notification.id } },
      };
    case InAppNotificationTypes.LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_PUBLISHED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_REPORT_CREATED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-holdings', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_INVESTMENT_CREATED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-holdings', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED:
      return {
        type: 'navigate',
        location: { name: 'general-partner-investor-details', params: { investorId: notification.meta.investorId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-about', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_ACCOUNT_ACTIVATED:
      return {
        type: 'location',
        location: `mailto:${notification.meta.generalPartnerMail}`,
      };
    case InAppNotificationTypes.LP_MESSAGE_INCOMING_TRANSACTION_CREATED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-holdings', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_OUTGOING_TRANSACTION_CREATED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-holdings', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_PRIVATE_PRODUCT_DOCUMENTS_CREATED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-documents', params: { productId: notification.meta.productId } },
      };
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_ACCESS_GRANTED:
      return {
        type: 'navigate',
        location: { name: 'investor-product-about', params: { productId: notification.meta.productId } },
      };
    default:
      return null;
  }
}

export function getNotificationText(notification: InAppNotification, type: 'title' | 'message'): LocaleMessage {
  if (notification.platformNews) {
    return type === 'title' ? notification.platformNews.title : notification.platformNews.content;
  }

  const key = type === 'title' ? 'notificationTitles' : 'notificationMessages';

  switch (notification.type) {
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_CREATED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_CREATED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_PUBLISHED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_PUBLISHED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_UNPUBLISHED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_UNPUBLISHED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_DELETED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_DELETED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_LP_ACCOUNT_ACTIVATED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_LP_ACCOUNT_ACTIVATED`, {
        firstName: notification.meta.firstName,
        lastName: notification.meta.lastName,
      });
    case InAppNotificationTypes.LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED`, {
        productName: notification.meta.productName,
        investorName: notification.meta.investorName,
      });
    case InAppNotificationTypes.GP_MESSAGE_INDICATED_INTEREST:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_INDICATED_INTEREST`, {
        productName: notification.meta.productName,
        investorName: notification.meta.investorName,
        investmentAmount: notification.meta.investmentAmount,
      });
    case InAppNotificationTypes.GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED:
      return i18n.t(`helpers.data.${key}.GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_PUBLISHED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_PRODUCT_PUBLISHED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_REPORT_CREATED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_PRODUCT_REPORT_CREATED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.LP_MESSAGE_INVESTMENT_CREATED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_INVESTMENT_CREATED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.LP_MESSAGE_ACCOUNT_ACTIVATED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_ACCOUNT_ACTIVATED`, {
        generalPartnerMail: notification.meta.generalPartnerMail,
        generalPartnerTitle: notification.meta.generalPartnerTitle,
      });
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED`, {
        productName: notification.meta.productName,
        committedCapital: notification.meta.committedCapital,
      });
    case InAppNotificationTypes.LP_MESSAGE_INCOMING_TRANSACTION_CREATED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_INCOMING_TRANSACTION_CREATED`, {
        productName: notification.meta.productName,
        transactionAmount: notification.meta.transactionAmount,
      });
    case InAppNotificationTypes.LP_MESSAGE_OUTGOING_TRANSACTION_CREATED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_OUTGOING_TRANSACTION_CREATED`, {
        transactionAmount: notification.meta.transactionAmount,
      });
    case InAppNotificationTypes.LP_MESSAGE_PRIVATE_PRODUCT_DOCUMENTS_CREATED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_PRIVATE_PRODUCT_DOCUMENTS_CREATED`, {
        productName: notification.meta.productName,
      });
    case InAppNotificationTypes.LP_MESSAGE_PRODUCT_ACCESS_GRANTED:
      return i18n.t(`helpers.data.${key}.LP_MESSAGE_PRODUCT_ACCESS_GRANTED`, {
        productName: notification.meta.productName,
      });
    default:
      return i18n.t(`application.inAppNotifications.unknownMessageType`);
  }
}
