import { performApiRequest, performApiRequestForFile, performApiRequestWithFiles, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { AgreeToSubscriptionDataProcessingCommand, ConfirmSubscriptionCommand, CreateSubscriptionDocumentCommand, ProductForCurrentSubscriptionProcess, GetProductForCurrentSubscriptionProcessQuery, GetSubscriptionDocumentFileForDownloadQuery, GetSubscriptionDocumentsForSubscriptionProcessQuery, GetSubscriptionProcessForCurrentProductQuery, SubscriptionDocument, SubscriptionProcess, SpecifySubscriptionInvestmentAmountCommand, GetKYCDocumentAttachmentsForSubscriptionProcessQuery, KYCDocumentAttachment, KYCDocument, CopyInvestorKYCDocumentsToKYCDocumentAttachmentsCommand, GetKYCDocumentAttachmentFileForDownloadQuery, DeleteKYCDocumentAttachmentCommand, DeleteSubscriptionDocumentCommand, SelectInvestmentProfileForSubscriptionProcessCommand, ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand, ContinueSubscriptionProcessAsNaturalPersonCommand, GetControllingPersonTemplatesToPrefillForProductQuery, ControllingPersonTemplateToPrefill, GetPrefilledControllingPersonTemplateForDownloadQuery, GetSubscriptionAgreementTemplatesToPrefillForProductQuery, SubscriptionAgreementTemplateToPrefill, GetPrefilledSubscriptionAgreementTemplateForDownloadQuery, GetSubscriptionAgreementTemplateFileForDownloadQuery } from './types';

// -- Queries

export function getSubscriptionProcessForCurrentProduct(query: GetSubscriptionProcessForCurrentProductQuery): Promise<SubscriptionProcess> {
  const url = generateApiUrl('/api/investor/get-subscription-process-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionDocumentFileForDownload(query: GetSubscriptionDocumentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-subscription-document-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionAgreementTemplateFileForDownload(
  query: GetSubscriptionAgreementTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-subscription-agreement-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionDocumentsForSubscriptionProcess(
  query: GetSubscriptionDocumentsForSubscriptionProcessQuery
): Promise<SubscriptionDocument[]> {
  const url = generateApiUrl('/api/investor/get-subscription-documents-for-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductForCurrentSubscriptionProcess(
  query: GetProductForCurrentSubscriptionProcessQuery
): Promise<ProductForCurrentSubscriptionProcess> {
  const url = generateApiUrl('/api/investor/get-product-for-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getKYCDocumentAttachmentsForSubscriptionProcess(
  query: GetKYCDocumentAttachmentsForSubscriptionProcessQuery
): Promise<KYCDocumentAttachment[]> {
  const url = generateApiUrl('/api/investor/get-kyc-document-attachments-for-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestorKYCDocuments(): Promise<KYCDocument[]> {
  const url = generateApiUrl('/api/investor/get-kyc-documents-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getKYCDocumentAttachmentFileForDownload(query: GetKYCDocumentAttachmentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-kyc-document-attachment-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionAgreementTemplatesToPrefillForProduct(
  query: GetSubscriptionAgreementTemplatesToPrefillForProductQuery
): Promise<SubscriptionAgreementTemplateToPrefill[]> {
  const url = generateApiUrl('/api/investor/get-subscription-agreement-templates-to-prefill-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
export function getControllingPersonTemplatesToPrefillForProduct(
  query: GetControllingPersonTemplatesToPrefillForProductQuery
): Promise<ControllingPersonTemplateToPrefill[]> {
  const url = generateApiUrl('/api/investor/get-controlling-person-templates-to-prefill-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getPrefilledSubscriptionAgreementTemplateForDownload(
  query: GetPrefilledSubscriptionAgreementTemplateForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-prefilled-subscription-agreement-template-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getPrefilledControllingPersonTemplateForDownload(
  query: GetPrefilledControllingPersonTemplateForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-prefilled-controlling-person-template-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

// -- Commands

export function startSubscriptionProcess(query: GetSubscriptionProcessForCurrentProductQuery): Promise<void> {
  const url = generateApiUrl('/api/investor/start-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function specifySubscriptionInvestmentAmount(command: SpecifySubscriptionInvestmentAmountCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/specify-subscription-investment-amount-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function selectInvestmentProfileForSubscriptionProcess(
  command: SelectInvestmentProfileForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/select-investment-profile-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function confirmSelectedInvestmentProfileForSubscriptionProcess(
  command: ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/confirm-selected-investment-profile-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function agreeToSubscriptionDataProcessing(command: AgreeToSubscriptionDataProcessingCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/agree-to-subscription-data-processing-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createSubscriptionDocument(
  command: CreateSubscriptionDocumentCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/investor/create-subscription-document-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function confirmSubscription(command: ConfirmSubscriptionCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/confirm-subscription-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function copyInvestorKYCDocumentsToKYCDocumentAttachments(
  command: CopyInvestorKYCDocumentsToKYCDocumentAttachmentsCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/copy-kyc-documents-to-kyc-document-attachments-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteKYCDocumentAttachment(command: DeleteKYCDocumentAttachmentCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/delete-kyc-document-attachment-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteSubscriptionDocument(command: DeleteSubscriptionDocumentCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/delete-subscription-document-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function continueSubscriptionProcessAsNaturalPerson(command: ContinueSubscriptionProcessAsNaturalPersonCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/continue-subscription-process-as-natural-person-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
