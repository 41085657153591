import { defineStore, storeToRefs } from 'pinia';
import { isEqual } from 'lodash-es';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse, VuetifySelectItem } from '@/application/types';
import { PublishedProduct, CreateInvestorCommand, DeleteInvestorCommand, Investor, InvestorsOverviewFilters, LockInvestorCommand, UnlockInvestorCommand, InvestorContact } from './types';
import { createInvestor, deleteInvestor, getInvestors, lockInvestor, unlockInvestor, getPublishedProducts, exportFullInvestorsVersionAsXLSX, exportShortInvestorsVersionAsXLSX } from './service';

export const defaultInvestorsOverviewFilters = {
  hasInitialSubscriptionProcess: false,
  hasTopUpProcess: false,
  hasInvestments: false,
};

interface GeneralPartnerInvestorsOverviewState {
  investors: Investor[];
  publishedProducts: PublishedProduct[];
  investorContacts: InvestorContact[];
  investorsOverviewFilters: InvestorsOverviewFilters;

  getInvestorsStatus: ActionStatus;
  createInvestorStatus: ActionStatus;
  getPublishedProductsStatus: ActionStatus;
  deleteInvestorStatus: ActionStatus;
  lockInvestorStatus: ActionStatus;
  unlockInvestorStatus: ActionStatus;
  exportShortInvestorsVersionAsXLSXStatus: ActionStatus;
  exportFullInvestorsVersionAsXLSXStatus: ActionStatus;
}

function initialState(): GeneralPartnerInvestorsOverviewState {
  return {
    investors: [],
    publishedProducts: [],
    investorContacts: [],
    investorsOverviewFilters: defaultInvestorsOverviewFilters,

    getInvestorsStatus: ActionStatus.None,
    createInvestorStatus: ActionStatus.None,
    getPublishedProductsStatus: ActionStatus.None,
    deleteInvestorStatus: ActionStatus.None,
    lockInvestorStatus: ActionStatus.None,
    unlockInvestorStatus: ActionStatus.None,
    exportShortInvestorsVersionAsXLSXStatus: ActionStatus.None,
    exportFullInvestorsVersionAsXLSXStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerInvestorsOverviewStore = defineStore('generalPartnerInvestorsOverview', {
  state: (): GeneralPartnerInvestorsOverviewState => initialState(),
  getters: {
    isGetInvestorsProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.getInvestorsStatus === ActionStatus.InProgress,
    isCreateInvestorProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.createInvestorStatus === ActionStatus.InProgress,
    isGetPublishedProductsProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.getPublishedProductsStatus === ActionStatus.InProgress,
    isDeleteInvestorProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.deleteInvestorStatus === ActionStatus.InProgress,
    isLockInvestorProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.lockInvestorStatus === ActionStatus.InProgress,
    isUnlockInvestorProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.unlockInvestorStatus === ActionStatus.InProgress,
    isExportShortInvestorsVersionAsXLSXProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.exportShortInvestorsVersionAsXLSXStatus === ActionStatus.InProgress,
    isExportFullInvestorsVersionAsXLSXProcessing: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      state.exportFullInvestorsVersionAsXLSXStatus === ActionStatus.InProgress,

    publishedProductItems: (state: GeneralPartnerInvestorsOverviewState): VuetifySelectItem<string>[] =>
      state.publishedProducts.map((publishedProduct: PublishedProduct) => ({
        text: publishedProduct.productName,
        value: publishedProduct.productId,
      })),
    areFiltersActive: (state: GeneralPartnerInvestorsOverviewState): boolean =>
      isEqual(state.investorsOverviewFilters, defaultInvestorsOverviewFilters),
  },
  actions: {

    // -- State management

    updateInvestorsOverviewFilters(investorsOverviewFilters: InvestorsOverviewFilters): Promise<void> {
      this.investorsOverviewFilters = investorsOverviewFilters;

      return Promise.resolve();
    },

    createInvestorContact(contact: InvestorContact): Promise<void> {
      this.investorContacts.push(contact);

      return Promise.resolve();
    },

    updateInvestorContact(contact: InvestorContact): Promise<void> {
      const index = this.investorContacts.findIndex((investorContact) => investorContact.id === contact.id);
      this.investorContacts.splice(index, 1, contact);

      return Promise.resolve();
    },

    deleteInvestorContact(contactId: string): Promise<void> {
      const index = this.investorContacts.findIndex((investorContact) => investorContact.id === contactId);
      this.investorContacts.splice(index, 1);

      return Promise.resolve();
    },

    resetInvestorContacts(): Promise<void> {
      this.investorContacts = [];

      return Promise.resolve();
    },

    // -- Queries

    exportShortInvestorsVersionAsXLSX(): Promise<FileResponse> {
      const { exportFullInvestorsVersionAsXLSXStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        exportFullInvestorsVersionAsXLSXStatus,
        () => exportShortInvestorsVersionAsXLSX(this.investorsOverviewFilters)
      );
    },

    exportFullInvestorsVersionAsXLSX(): Promise<FileResponse> {
      const { exportFullInvestorsVersionAsXLSXStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        exportFullInvestorsVersionAsXLSXStatus,
        () => exportFullInvestorsVersionAsXLSX(this.investorsOverviewFilters)
      );
    },

    getPublishedProducts(): Promise<void> {
      const { getPublishedProductsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPublishedProductsStatus,
        () => getPublishedProducts()
          .then((publishedProducts) => {
            this.publishedProducts = publishedProducts;
          })
      );
    },

    getInvestors(): Promise<void> {
      const { getInvestorsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorsStatus,
        () => getInvestors(this.investorsOverviewFilters)
          .then((investors) => {
            this.investors = investors;
          })
      );
    },

    // -- Commands

    createInvestor(command: CreateInvestorCommand): Promise<void> {
      const { createInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createInvestorStatus,
        () => createInvestor(command)
          .then(() => this.getInvestors())
      );
    },

    deleteInvestor(command: DeleteInvestorCommand): Promise<void> {
      const { deleteInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteInvestorStatus,
        () => deleteInvestor(command)
          .then(() => this.getInvestors())
      );
    },

    lockInvestor(command: LockInvestorCommand): Promise<void> {
      const { lockInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        lockInvestorStatus,
        () => lockInvestor(command)
          .then(() => this.getInvestors())
      );
    },

    unlockInvestor(command: UnlockInvestorCommand): Promise<void> {
      const { unlockInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unlockInvestorStatus,
        () => unlockInvestor(command)
          .then(() => this.getInvestors())
      );
    },

  },
});
