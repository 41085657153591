import Vue from 'vue';
import { Language, TranslatedText } from '@/types/global';

export function translateWithFallback(
  translation?: TranslatedText,
  language?: Language
): string {
  if (!translation) {
    return '';
  }

  if (language) {
    return translation[language] || translation.en;
  }

  return translation.en;
}

Vue.filter('translateWithFallback', translateWithFallback);
