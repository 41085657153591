import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CancelSubscriptionProcessCommand, CreateInvestmentForSubscriptionProcessCommand, GetSubscriptionProcessQuery, GetSubscriptionDocumentFileForDownloadQuery, SubscriptionProcess, SpecifySubscriptionInvestmentAmountCommand, GetSubscriptionDocumentTemplateFileForDownloadQuery, CreateSubscriptionDocumentForSubscriptionProcessCommand, ConfirmSubscriptionCommand, DeleteSubscriptionDocumentCommand, ReopenSubscriptionProcessCommand, CreateKYCDocumentAttachmentForSubscriptionProcessCommand, GetKYCDocumentAttachmentFileForDownloadQuery, DeleteKYCDocumentAttachmentCommand, SelectInvestmentProfileForSubscriptionProcessCommand, InvestmentProfile, GetInvestmentProfilesForInvestorQuery, ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand, GetInvestmentProfileQuery, InvestmentProfileDetails, ContinueSubscriptionProcessAsNaturalPersonCommand } from './types';

export function specifySubscriptionInvestmentAmount(command: SpecifySubscriptionInvestmentAmountCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/specify-subscription-investment-amount-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestmentProfiles(query: GetInvestmentProfilesForInvestorQuery): Promise<InvestmentProfile[]> {
  const url = generateApiUrl('/api/general-partner/get-investment-profiles-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function selectInvestmentProfileForSubscriptionProcess(
  command: SelectInvestmentProfileForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/select-investment-profile-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestmentProfile(command: GetInvestmentProfileQuery): Promise<InvestmentProfileDetails> {
  const url = generateApiUrl('/api/general-partner/get-investment-profile-query');
  return performApiRequest<InvestmentProfileDetails>({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function confirmSelectedInvestmentProfileForSubscriptionProcess(
  command: ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/confirm-selected-investment-profile-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createSubscriptionDocumentForSubscriptionProcess(
  command: CreateSubscriptionDocumentForSubscriptionProcessCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-subscription-document-for-subscription-process-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function confirmSubscription(command: ConfirmSubscriptionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/confirm-subscription-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createInvestmentForSubscriptionProcess(command: CreateInvestmentForSubscriptionProcessCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-investment-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function reopenSubscriptionProcess(command: ReopenSubscriptionProcessCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/reopen-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function cancelSubscriptionProcess(command: CancelSubscriptionProcessCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/cancel-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteSubscriptionDocument(command: DeleteSubscriptionDocumentCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-subscription-document-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createKYCDocumentAttachmentForSubscriptionProcess(
  command: CreateKYCDocumentAttachmentForSubscriptionProcessCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-kyc-document-attachment-for-subscription-process-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteKYCDocumentAttachment(command: DeleteKYCDocumentAttachmentCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-kyc-document-attachment-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getSubscriptionProcess(query: GetSubscriptionProcessQuery): Promise<SubscriptionProcess> {
  const url = generateApiUrl('/api/general-partner/get-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionDocumentFileForDownload(query: GetSubscriptionDocumentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-subscription-document-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionDocumentTemplateFileForDownload(
  query: GetSubscriptionDocumentTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-subscription-document-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getKYCDocumentAttachmentFileForDownload(query: GetKYCDocumentAttachmentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-kyc-document-attachment-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function continueSubscriptionProcessAsNaturalPerson(command: ContinueSubscriptionProcessAsNaturalPersonCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/continue-subscription-process-as-natural-person-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
