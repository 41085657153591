import { RouteConfig } from 'vue-router';
import { Layout } from '@/application/whitelabel/app/types';
import CreateInvestorUserAccount from './components/create-account.vue';

export const createInvestorUserAccountRoutes: RouteConfig[] = [
  {
    name: 'create-investor-user-account',
    path: '/create-investor-user-account/:language/:token',
    component: CreateInvestorUserAccount,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      layout: Layout.PUBLIC,
    },
  },
];
