var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"investments"}},[_c('a-headline',{attrs:{"medium":"","title":_vm.translate('title')}}),(!_vm.store.isGetInvestmentOverviewsByProductProcessing)?_c('div',[_c('v-data-table',{staticClass:"clickable-rows",attrs:{"disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.store.investmentOverviewsByProduct,"item-key":"productId","loading":_vm.store.isGetInvestmentOverviewsByProductProcessing,"mobile-breakpoint":960,"item-class":_vm.rowClasses,"id":"investments-table","data-cy":"investments-table"},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row my-3"},[_c('a-img',{staticClass:"product-image mt-3 mt-sm-0 mr-sm-4",attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(_vm._s(item.productName))]),_c('div',{staticClass:"body-3"},[_vm._v(" "+_vm._s(_vm.$t(("helpers.data.assetClasses." + (item.assetClass))))+" • "+_vm._s(_vm.$t(("helpers.data.subAssetClasses." + (item.subAssetClass))))+" "),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("• "+_vm._s(_vm.$t(("helpers.data.investmentStructures." + (item.investmentStructure)))))])])])],1)]}},{key:"item.committedCapital",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-md-right"},[_c('div',{staticClass:"body-3 grey--text text--darken-2"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.shares,null, true, _vm.DISPLAY_DECIMALS.INVESTMENT.shares))+" "+_vm._s(_vm.translate('table.shares')))]),_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm._f("formatCurrencyWithoutCentDigits")(item.committedCapital,item.currency)))])])]}},{key:"item.closingDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"grey--text text--darken-4"},[_vm._v(_vm._s(_vm._f("formatDate")(item.closingDate)))])]}},{key:"item.allocation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"allocation-percentage mr-4 grey--text text--darken-4"},[_vm._v(_vm._s(_vm.store.investmentOverviewPercentages[item.productId])+"%")]),_c('v-progress-linear',{staticClass:"allocation-progress-bar",attrs:{"color":"primary","value":_vm.store.investmentOverviewPercentages[item.productId]}})],1)]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"totals-row d-flex flex-column d-md-table-row"},[_c('td',{staticClass:"d-none d-md-table-cell"},[_c('div',{staticClass:"body-2 black--text"},[_vm._v(" "+_vm._s(_vm.translate('table.total'))+" ")])]),_c('td',{staticClass:"text-right pt-2 pt-md-0"},[_c('div',{staticClass:"d-flex justify-space-between justify-md-end"},[_c('div',{staticClass:"d-md-none body-3 black--text"},[_vm._v(_vm._s(_vm.translate('table.total')))]),_c('div',_vm._l((_vm.store.committedCapitalByCurrency),function(value,key){return _c('div',{staticClass:"body-2 black--text"},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyWithoutCentDigits")(value,key))+" ")])}),0)])]),_c('td',{staticClass:"d-none d-md-table-cell"}),_c('td',{staticClass:"d-none d-md-table-cell text-right"},[_c('div',{staticClass:"d-flex justify-md-start justify-end"},[_c('div',{staticClass:"body-2 black--text mr-4"},[_vm._v("100%")]),_c('div',{staticClass:"allocation-progress-bar"})])])])]},proxy:true}],null,false,4235199890)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }