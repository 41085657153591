import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { AssetClass } from '@/types/global';
import { CreateKPICategoryCommand, CreateKPIDefinitionCommand, DeleteKPICategoryCommand, DeleteKPIDefinitionCommand, GetTermSheetTemplateForAssetClassQuery, MoveKPIDefinitionToCategoryCommand, TermSheetTemplate, UpdateKPICategoriesOrderCommand, UpdateKPICategoryCommand, UpdateKPIDefinitionCommand, UpdateKPIDefinitionsOrderCommand } from './types';
import { createKPICategory, createKPIDefinition, deleteKPICategory, deleteKPIDefinition, getTermSheetTemplate, moveKPIDefinitionToCategory, updateKPICategoriesOrder, updateKPICategory, updateKPIDefinition, updateKPIDefinitionsOrder } from './service';

interface BrainTermSheetTemplatesState {
  assetClass: AssetClass | null;
  termSheetTemplate: TermSheetTemplate | null;

  getTermSheetTemplateStatus: ActionStatus;
  createKPICategoryStatus: ActionStatus;
  updateKPICategoryStatus: ActionStatus;
  updateKPICategoriesOrderStatus: ActionStatus;
  deleteKPICategoryStatus: ActionStatus;
  createKPIDefinitionStatus: ActionStatus;
  updateKPIDefinitionStatus: ActionStatus;
  deleteKPIDefinitionStatus: ActionStatus;
  updateKPIDefinitionsOrderStatus: ActionStatus;
  moveKPIDefinitionToCategoryStatus: ActionStatus;
}

function initialState(): BrainTermSheetTemplatesState {
  return {
    assetClass: null,
    termSheetTemplate: null,

    getTermSheetTemplateStatus: ActionStatus.None,
    createKPICategoryStatus: ActionStatus.None,
    updateKPICategoryStatus: ActionStatus.None,
    updateKPICategoriesOrderStatus: ActionStatus.None,
    deleteKPICategoryStatus: ActionStatus.None,
    createKPIDefinitionStatus: ActionStatus.None,
    updateKPIDefinitionStatus: ActionStatus.None,
    deleteKPIDefinitionStatus: ActionStatus.None,
    updateKPIDefinitionsOrderStatus: ActionStatus.None,
    moveKPIDefinitionToCategoryStatus: ActionStatus.None,
  };
}

export const useBrainTermSheetTemplatesStore = defineStore('brainTermSheetTemplates', {
  state: (): BrainTermSheetTemplatesState => initialState(),
  getters: {
    isGetTermSheetTemplateProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.getTermSheetTemplateStatus === ActionStatus.InProgress,
    isCreateKPICategoryProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.createKPICategoryStatus === ActionStatus.InProgress,
    isUpdateKPICategoryProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.updateKPICategoryStatus === ActionStatus.InProgress,
    isUpdateKPICategoriesOrderProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.updateKPICategoriesOrderStatus === ActionStatus.InProgress,
    isDeleteKPICategoryProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.deleteKPICategoryStatus === ActionStatus.InProgress,
    isCreateKPIDefinitionProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.createKPIDefinitionStatus === ActionStatus.InProgress,
    isUpdateKPIDefinitionProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.updateKPIDefinitionStatus === ActionStatus.InProgress,
    isDeleteKPIDefinitionProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.deleteKPIDefinitionStatus === ActionStatus.InProgress,
    isUpdateKPIDefinitionsOrderProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.updateKPIDefinitionsOrderStatus === ActionStatus.InProgress,
    isMoveKPIDefinitionToCategoryProcessing: (state: BrainTermSheetTemplatesState): boolean =>
      state.moveKPIDefinitionToCategoryStatus === ActionStatus.InProgress,
  },
  actions: {
    // -- State management

    updateAssetClass(assetClass: AssetClass): Promise<void> {
      this.assetClass = assetClass;

      return Promise.resolve();
    },

    // -- Queries

    getTermSheetTemplate(): Promise<void> {
      const query: GetTermSheetTemplateForAssetClassQuery = {
        assetClass: this.assetClass!,
      };

      const { getTermSheetTemplateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTermSheetTemplateStatus,
        () => getTermSheetTemplate(query)
          .then((termSheetTemplate) => {
            this.termSheetTemplate = termSheetTemplate;
          })
      );
    },

    // -- Commands

    createKPICategory(command: CreateKPICategoryCommand): Promise<void> {
      const { createKPICategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createKPICategoryStatus,
        () => createKPICategory(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    updateKPICategory(command: UpdateKPICategoryCommand): Promise<void> {
      const { updateKPICategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateKPICategoryStatus,
        () => updateKPICategory(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    updateKPICategoriesOrder(command: UpdateKPICategoriesOrderCommand): Promise<void> {
      const { updateKPICategoriesOrderStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateKPICategoriesOrderStatus,
        () => updateKPICategoriesOrder(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    deleteKPICategory(command: DeleteKPICategoryCommand): Promise<void> {
      const { deleteKPICategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteKPICategoryStatus,
        () => deleteKPICategory(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    createKPIDefinition(command: CreateKPIDefinitionCommand): Promise<void> {
      const { createKPIDefinitionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createKPIDefinitionStatus,
        () => createKPIDefinition(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    updateKPIDefinition(command: UpdateKPIDefinitionCommand): Promise<void> {
      const { updateKPIDefinitionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateKPIDefinitionStatus,
        () => updateKPIDefinition(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    deleteKPIDefinition(command: DeleteKPIDefinitionCommand): Promise<void> {
      const { deleteKPIDefinitionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteKPIDefinitionStatus,
        () => deleteKPIDefinition(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    updateKPIDefinitionsOrder(command: UpdateKPIDefinitionsOrderCommand): Promise<void> {
      const { updateKPIDefinitionsOrderStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateKPIDefinitionsOrderStatus,
        () => updateKPIDefinitionsOrder(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

    moveKPIDefinitionToCategory(command: MoveKPIDefinitionToCategoryCommand): Promise<void> {
      const { moveKPIDefinitionToCategoryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveKPIDefinitionToCategoryStatus,
        () => moveKPIDefinitionToCategory(command)
          .then(() => this.getTermSheetTemplate())
      );
    },

  },
});
