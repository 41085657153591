import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { ProductAccess } from '@/domain/general-partner/product-restriction-hint/types';
import { getProductAccessForUser } from '@/domain/general-partner/product-restriction-hint/service';

interface GeneralPartnerProductRestrictionHintState {
  productAccess: ProductAccess | null;
  getProductAccessStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductRestrictionHintState {
  return {
    productAccess: null,
    getProductAccessStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductRestrictionHintStore = defineStore('generalPartnerProductRestrictionHint', {
  state: (): GeneralPartnerProductRestrictionHintState => initialState(),
  getters: {
    isGetProductAccessProcessing: (state: GeneralPartnerProductRestrictionHintState): boolean =>
      state.getProductAccessStatus === ActionStatus.InProgress,
    hasAccessToAllProducts: (state: GeneralPartnerProductRestrictionHintState): boolean =>
      state.productAccess
        ? !state.productAccess.hasAccessToAllProducts
        : false,
    productRestrictionText: (state: GeneralPartnerProductRestrictionHintState): string =>
      state.productAccess && state.productAccess.explicitlyAccessibleProducts
        ? state.productAccess.explicitlyAccessibleProducts
          .map((explicitlyAccessibleProduct) => explicitlyAccessibleProduct.productName)
          .join(', ')
        : '',
  },
  actions: {
    // -- Queries

    getProductAccessForUser(): Promise<void> {
      const { getProductAccessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductAccessStatus,
        () => getProductAccessForUser()
          .then((productAccess) => {
            this.productAccess = productAccess;
          })
      );
    },

  },
});
