import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { useGeneralPartnerProductManagementGeneralStore } from '@/domain/general-partner/products/product-management/store';
import { getProductNewsArticleAttachmentForDownload } from '@/domain/investor/products/product/service';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { CreateProductNewsArticleAttachmentCommand, CreateProductNewsArticleAttachmentsCommand, CreateProductNewsArticleCommand, DeleteProductNewsArticleAttachmentsCommand, DeleteProductNewsArticleCommand, GetProductNewsArticleAttachmentForDownloadQuery, GetProductNewsArticlesQuery, ProductNewsArticle, UpdateProductNewsArticleCommand } from './types';
import { createProductNewsArticle, createProductNewsArticleAttachment, deleteProductNewsArticle, deleteProductNewsArticleAttachments, getProductNewsArticles, updateProductNewsArticle } from './services';

interface GeneralPartnerProductManagementNewsTabState {
  productNewsArticles: ProductNewsArticle[];

  getProductNewsArticlesStatus: ActionStatus;
  createProductNewsArticleStatus: ActionStatus;
  deleteProductNewsArticleStatus: ActionStatus;
  updateProductNewsArticleStatus: ActionStatus;
  getProductNewsArticleAttachmentForDownloadStatus: ActionStatus;
  createProductNewsArticleAttachmentsStatus: ActionStatus;
  deleteProductNewsArticleAttachmentsStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementNewsTabState {
  return {
    productNewsArticles: [],

    getProductNewsArticlesStatus: ActionStatus.None,
    createProductNewsArticleStatus: ActionStatus.None,
    deleteProductNewsArticleStatus: ActionStatus.None,
    updateProductNewsArticleStatus: ActionStatus.None,
    getProductNewsArticleAttachmentForDownloadStatus: ActionStatus.None,
    createProductNewsArticleAttachmentsStatus: ActionStatus.None,
    deleteProductNewsArticleAttachmentsStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementNewsTabStore = defineStore('generalPartnerProductManagementNewsTab', {
  state: (): GeneralPartnerProductManagementNewsTabState => initialState(),
  getters: {
    isGetProductNewsArticlesProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.getProductNewsArticlesStatus === ActionStatus.InProgress,
    isCreateProductNewsArticleProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.createProductNewsArticleStatus === ActionStatus.InProgress,
    isDeleteProductNewsArticleProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.deleteProductNewsArticleStatus === ActionStatus.InProgress,
    isUpdateProductNewsArticleProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.updateProductNewsArticleStatus === ActionStatus.InProgress,
    isGetProductNewsArticleAttachmentForDownloadProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.getProductNewsArticleAttachmentForDownloadStatus === ActionStatus.InProgress,
    isCreateProductNewsArticleAttachmentsProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.createProductNewsArticleAttachmentsStatus === ActionStatus.InProgress,
    isDeleteProductNewsArticleAttachmentsProcessing: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.deleteProductNewsArticleAttachmentsStatus === ActionStatus.InProgress,
    hasNewsInSelectedLanguage: (state: GeneralPartnerProductManagementNewsTabState): boolean =>
      state.productNewsArticles.length > 0,
  },
  actions: {

    // -- Queries

    getProductNewsArticles(): Promise<void> {
      const query: GetProductNewsArticlesQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
      };

      const { getProductNewsArticlesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductNewsArticlesStatus,
        () => getProductNewsArticles(query)
          .then((productNewsArticles) => {
            this.productNewsArticles = productNewsArticles;
          })
      );
    },

    getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
      const { getProductNewsArticleAttachmentForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductNewsArticleAttachmentForDownloadStatus,
        () => getProductNewsArticleAttachmentForDownload(query)
      );
    },

    // -- Commands

    createProductNewsArticle(command: CreateProductNewsArticleCommand): Promise<void> {
      const { createProductNewsArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createProductNewsArticleStatus,
        () => createProductNewsArticle(command)
          .then(() => this.getProductNewsArticles())
      );
    },

    deleteProductNewsArticle(command: DeleteProductNewsArticleCommand): Promise<void> {
      const { deleteProductNewsArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductNewsArticleStatus,
        () => deleteProductNewsArticle(command)
          .then(() => this.getProductNewsArticles())
      );
    },

    updateProductNewsArticle(command: UpdateProductNewsArticleCommand): Promise<void> {
      const { updateProductNewsArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductNewsArticleStatus,
        () => updateProductNewsArticle(command)
          .then(() => this.getProductNewsArticles())
      );
    },

    async createProductNewsArticleAttachments(command: CreateProductNewsArticleAttachmentsCommand): Promise<void> {
      this.createProductNewsArticleAttachmentsStatus = ActionStatus.InProgress;

      for (const provisionalAttachment of command.attachments) {
        const createSingleAttachmentCommand: CreateProductNewsArticleAttachmentCommand = {
          body: {
            productNewsArticleId: command.productNewsArticleId,
            language: command.language,
            productNewsArticleAttachmentId: provisionalAttachment.internalFileName,
          },
          files: { attachment: provisionalAttachment.file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: provisionalAttachment.file.name,
          serviceFunction: createProductNewsArticleAttachment,
          command: createSingleAttachmentCommand,
        };

        await useFileUploadQueueStore().addItem(payload);
      }

      return this.getProductNewsArticles()
        .then(() => {
          this.createProductNewsArticleAttachmentsStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createProductNewsArticleAttachmentsStatus = ActionStatus.Failed;
          return Promise.reject(error);
        });
    },

    deleteProductNewsArticleAttachments(command: DeleteProductNewsArticleAttachmentsCommand): Promise<void> {
      const { deleteProductNewsArticleAttachmentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductNewsArticleAttachmentsStatus,
        () => deleteProductNewsArticleAttachments(command)
          .then(() => this.getProductNewsArticles())
      );
    },

  },
});
