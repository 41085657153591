import { generateApiUrl } from '@/infrastructure/api-requests';

export function getLogoUrl(generalPartnerId: string): string {
  return generateApiUrl(`/api/assets/get-general-partner-logo/${generalPartnerId}`);
}

export function getPublicHeroImageUrl(generalPartnerId: string): string {
  return generateApiUrl(`/api/assets/get-general-partner-public-hero-image/${generalPartnerId}`);
}

export function getGeneralPartnerNonDisclosureAgreementUrl(generalPartnerId: string, language: string): string {
  return generateApiUrl(`/api/assets/get-general-partner-non-disclosure-agreement/${generalPartnerId}/${language}`);
}

export function getGeneralPartnerTermsAndConditionsUrl(generalPartnerId: string, language: string): string {
  return generateApiUrl(`/api/assets/get-general-partner-terms-and-conditions/${generalPartnerId}/${language}`);
}
