import { Dayjs } from 'dayjs';
import { Command, CommandWithFiles, Query } from '@/application/types';
import { Currency, FileReference, SubscriptionProcessStatus, TermSheet } from '@/types/global';
import { Decimal } from '@/models/decimal';

export enum SubscriptionProcessStep {
  AMOUNT_SPECIFICATION = 1,
  INVESTMENT_PROFILE_SELECTION = 2,
  INVESTMENT_PROFILE_REVIEW = 3,
  KYC_DOCUMENT_ATTACHMENTS = 4,
  SUBSCRIPTION_DOCUMENTS = 5,
  REVIEW = 6,
}

// -- State

export interface OnboardedProductForCurrentSubscriptionProcess {
  productId: string;
  productName: string;
  imageUrl?: string;
  idsOfKPIsForProductInfo: string[];
  idsOfKPIsForSummary: string[];
  subscriptionInstruction?: string;
  kycInstruction?: string;
  termSheet: TermSheet;
  currency: Currency;
}

export interface OnboardedProductSubscriptionProcess {
  subscriptionProcessId: string;
  investmentAmount?: Decimal;
  status: SubscriptionProcessStatus;
  subscriptionDocumentTemplates?: OnboardedProductSubscriptionDocumentTemplate[];
  isDocumentManagementForSubscriptionProcessEnabled: boolean;
  selectedInvestmentProfileId?: string;
  isInvestmentProfileReviewed: boolean;
  isNaturalPersonSelected: boolean;
}

export interface OnboardedProductSubscriptionDocumentTemplate {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface OnboardedProductSubscriptionDocument {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface OnboardedProductSubscriptionAgreementTemplateToPrefill {
  subscriptionAgreementTemplateToPrefillId: string;
  original: FileReference;
  createdAt: Dayjs;
}

export interface OnboardedProductControllingPersonTemplateToPrefill {
  controllingPersonTemplateToPrefillId: string;
  controllingPersonId: string;
  fullName: string;
  original: FileReference;
  createdAt: Dayjs;
}

export interface KYCDocument {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface OnboardedProductKYCDocumentAttachment {
  id: string;
  kycDocumentId?: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

// -- Command

export interface StartOnboardedProductSubscriptionProcessCommand extends Command {
  productId: string;
}

export interface SpecifyOnboardedProductSubscriptionInvestmentAmountCommand extends Command {
  subscriptionProcessId: string;
  investmentAmount: Decimal;
}

export interface SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
  investmentProfileId: string;
}

export interface ConfirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
}

export interface CreateOnboardedProductSubscriptionDocumentCommand extends CommandWithFiles {
  body: {
    subscriptionProcessId: string;
  };
  files: {
    file: File;
  };
}

export interface ConfirmOnboardedProductSubscriptionCommand extends Command {
  subscriptionProcessId: string;
}

export interface CopyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsCommand extends Command {
  subscriptionProcessId: string;
  investorKYCDocuments: string[];
}

export interface DeleteOnboardedProductKYCDocumentAttachmentCommand extends Command {
  subscriptionProcessId: string;
  documentAttachmentId: string;
}

export interface DeleteOnboardedProductSubscriptionDocumentCommand extends Command {
  subscriptionProcessId: string;
  documentId: string;
}

// -- Queries

export interface GetOnboardedProductSubscriptionProcessForCurrentProductQuery extends Query {
  productId: string;
}

export interface GetOnboardedProductSubscriptionDocumentFileForDownloadQuery extends Query {
  subscriptionDocumentId: string;
}

export interface GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery extends Query {
  fileId: string;
}

export interface GetOnboardedProductSubscriptionDocumentsForSubscriptionProcessQuery extends Query {
  subscriptionProcessId: string;
}

export interface GetOnboardedProductForCurrentSubscriptionProcessQuery extends Query {
  productId: string;
}

export interface GetOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessQuery extends Query {
  subscriptionProcessId: string;
}

export interface GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery extends Query {
  kycDocumentAttachmentId: string;
}

export interface GetOnboardedProductSubscriptionAgreementTemplatesToPrefillQuery extends Query {
  productId: string;
}

export interface GetOnboardedProductControllingPersonTemplatesToPrefillForProductQuery extends Query {
  productId: string;
  investmentProfileId: string;
}

export interface GetPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadQuery extends Query {
  subscriptionAgreementTemplateToPrefillId: string;
  subscriptionProcessId: string;
  investmentProfileId: string;
}

export interface ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand extends Query {
  subscriptionProcessId: string;
}

export interface GetPrefilledOnboardedProductControllingPersonTemplateForDownloadQuery extends Query {
  controllingPersonTemplateToPrefillId: string;
  controllingPersonId: string;
  investmentProfileId: string;
}
