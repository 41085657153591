import { defineStore } from 'pinia';
import { ActionStatus } from '@/application/types';
import { isCancelledError } from '@/infrastructure/api-requests';
import { search } from './service';
import { GlobalSearchQuery, SearchResults } from './types';

interface GeneralPartnerGlobalSearchState {
  searchResults: SearchResults | null;

  searchStatus: ActionStatus;
}

function initialState(): GeneralPartnerGlobalSearchState {
  return {
    searchResults: null,

    searchStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerGlobalSearchStore = defineStore('generalPartnerGlobalSearch', {
  state: (): GeneralPartnerGlobalSearchState => initialState(),
  getters: {
    isSearchProcessing: (state: GeneralPartnerGlobalSearchState): boolean =>
      state.searchStatus === ActionStatus.InProgress,

    areAnySearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
        && (
          state.searchResults.productSearchResults.length > 0
          || state.searchResults.investorSearchResults.length > 0
          || state.searchResults.investorContactSearchResults.length > 0
          || state.searchResults.generalPartnerUserSearchResults.length > 0
          || state.searchResults.investorUserSearchResults.length > 0
          || state.searchResults.fileSearchResults.length > 0
          || state.searchResults.subscriptionDocumentSearchResults.length > 0
          || state.searchResults.productReportSearchResults.length > 0
        ),
    areProductSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
        && state.searchResults.productSearchResults.length > 0,
    areInvestorSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.investorSearchResults.length > 0,
    areInvestorContactSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.investorContactSearchResults.length > 0,
    areGeneralPartnerUserSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.generalPartnerUserSearchResults.length > 0,
    areInvestorUserSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.investorUserSearchResults.length > 0,
    areFileSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.fileSearchResults.length > 0,
    areSubscriptionDocumentSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.subscriptionDocumentSearchResults.length > 0,
    areProductReportSearchResultsAvailable: (state: GeneralPartnerGlobalSearchState): boolean =>
      !!state.searchResults
      && state.searchResults.productReportSearchResults.length > 0,
  },
  actions: {

    // -- State management

    resetSearchResults(): Promise<void> {
      this.searchResults = null;

      return Promise.resolve();
    },

    // -- Queries

    search(query: GlobalSearchQuery): Promise<void> {
      this.searchStatus = ActionStatus.InProgress;
      return search(query)
        .then((searchResults) => {
          this.searchResults = searchResults;
          this.searchStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          if (!isCancelledError(error)) {
            this.searchStatus = ActionStatus.Failed;
          }
        });
    },

  },
});
