import { Dayjs } from 'dayjs';
import type { Decimal } from '@/models/decimal';

export type SourceOfFundsType =
  | 'PROFESSIONAL_EARNINGS'
  | 'LOAN_PROCEEDS'
  | 'PROPERTY_SALE'
  | 'INHERITANCE'
  | 'SAVINGS'
  | 'DONATION'
  | 'OTHER';
export const SourceOfFundsTypes: Record<SourceOfFundsType, SourceOfFundsType> = {
  PROFESSIONAL_EARNINGS: 'PROFESSIONAL_EARNINGS',
  LOAN_PROCEEDS: 'LOAN_PROCEEDS',
  PROPERTY_SALE: 'PROPERTY_SALE',
  INHERITANCE: 'INHERITANCE',
  SAVINGS: 'SAVINGS',
  DONATION: 'DONATION',
  OTHER: 'OTHER',
};

export type InvestmentProfileControllingPersonType =
  | 'CONTROL_BY_OWNERSHIP'
  | 'SENIOR_MANAGING_OFFICIAL'
  | 'SETTLOR'
  | 'TRUSTEE'
  | 'PROTECTOR'
  | 'BENEFICIARY'
  | 'OTHER';
// eslint-disable-next-line max-len
export const InvestmentProfileControllingPersonTypes: Record<InvestmentProfileControllingPersonType, InvestmentProfileControllingPersonType> = {
  CONTROL_BY_OWNERSHIP: 'CONTROL_BY_OWNERSHIP',
  SENIOR_MANAGING_OFFICIAL: 'SENIOR_MANAGING_OFFICIAL',
  SETTLOR: 'SETTLOR',
  TRUSTEE: 'TRUSTEE',
  PROTECTOR: 'PROTECTOR',
  BENEFICIARY: 'BENEFICIARY',
  OTHER: 'OTHER',
};

export type InvestmentProfileType =
  | 'LEGAL_ENTITY'
  | 'NATURAL_PERSON';
export const InvestmentProfileTypes: Record<InvestmentProfileType, InvestmentProfileType> = {
  LEGAL_ENTITY: 'LEGAL_ENTITY',
  NATURAL_PERSON: 'NATURAL_PERSON',
};

export type InvestmentProfileEntityType =
  | 'CORPORATION'
  | 'LIMITED_PARTNERSHIP'
  | 'TRUST'
  | 'CUSTODIAL_ACCOUNT'
  | 'BANK'
  | 'NOMINEE'
  | 'PENSION_FUND'
  | 'FOUNDATION_OR_ASSOCIATION'
  | 'GOVERNMENT_ENTITY'
  | 'INSURANCE_COMPANY'
  | 'FIDUCIARY'
  | 'OTHER';
export const InvestmentProfileEntityTypes: Record<InvestmentProfileEntityType, InvestmentProfileEntityType> = {
  CORPORATION: 'CORPORATION',
  LIMITED_PARTNERSHIP: 'LIMITED_PARTNERSHIP',
  TRUST: 'TRUST',
  CUSTODIAL_ACCOUNT: 'CUSTODIAL_ACCOUNT',
  BANK: 'BANK',
  NOMINEE: 'NOMINEE',
  PENSION_FUND: 'PENSION_FUND',
  FOUNDATION_OR_ASSOCIATION: 'FOUNDATION_OR_ASSOCIATION',
  GOVERNMENT_ENTITY: 'GOVERNMENT_ENTITY',
  INSURANCE_COMPANY: 'INSURANCE_COMPANY',
  FIDUCIARY: 'FIDUCIARY',
  OTHER: 'OTHER',
};

export type Permission =
  | 'READ_PRODUCT'
  | 'CREATE_PRODUCT'
  | 'UPDATE_PRODUCT'
  | 'PUBLISH_PRODUCT_UPDATE'
  | 'PUBLISH_PRODUCT'
  | 'SHARE_PRODUCT'
  | 'DELETE_PRODUCT'
  | 'READ_INVESTOR'
  | 'WRITE_INVESTOR'
  | 'LOCK_INVESTOR'
  | 'DELETE_INVESTOR'
  | 'READ_INVESTMENT'
  | 'WRITE_INVESTMENT'
  | 'DELETE_INVESTMENT'
  | 'READ_USER'
  | 'INVITE_INVESTOR_USER'
  | 'WRITE_INVESTOR_USER'
  | 'LOCK_INVESTOR_USER'
  | 'IMPERSONATE_INVESTOR_USER'
  | 'DELETE_INVESTOR_USER'
  | 'INVITE_GENERAL_PARTNER_USER'
  | 'WRITE_GENERAL_PARTNER_USER'
  | 'LOCK_GENERAL_PARTNER_USER'
  | 'DELETE_GENERAL_PARTNER_USER'
  | 'GRANT_GENERAL_PARTNER_USER_PERMISSIONS'
  | 'WRITE_GENERAL_PARTNER_PERMISSION_GROUPS';
export const GeneralPartnerPermissions: Record<Permission, Permission> = {
  READ_PRODUCT: 'READ_PRODUCT',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  PUBLISH_PRODUCT_UPDATE: 'PUBLISH_PRODUCT_UPDATE',
  PUBLISH_PRODUCT: 'PUBLISH_PRODUCT',
  SHARE_PRODUCT: 'SHARE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  READ_INVESTOR: 'READ_INVESTOR',
  WRITE_INVESTOR: 'WRITE_INVESTOR',
  LOCK_INVESTOR: 'LOCK_INVESTOR',
  DELETE_INVESTOR: 'DELETE_INVESTOR',
  READ_INVESTMENT: 'READ_INVESTMENT',
  WRITE_INVESTMENT: 'WRITE_INVESTMENT',
  DELETE_INVESTMENT: 'DELETE_INVESTMENT',
  READ_USER: 'READ_USER',
  INVITE_INVESTOR_USER: 'INVITE_INVESTOR_USER',
  WRITE_INVESTOR_USER: 'WRITE_INVESTOR_USER',
  LOCK_INVESTOR_USER: 'LOCK_INVESTOR_USER',
  IMPERSONATE_INVESTOR_USER: 'IMPERSONATE_INVESTOR_USER',
  DELETE_INVESTOR_USER: 'DELETE_INVESTOR_USER',
  INVITE_GENERAL_PARTNER_USER: 'INVITE_GENERAL_PARTNER_USER',
  WRITE_GENERAL_PARTNER_USER: 'WRITE_GENERAL_PARTNER_USER',
  LOCK_GENERAL_PARTNER_USER: 'LOCK_GENERAL_PARTNER_USER',
  DELETE_GENERAL_PARTNER_USER: 'DELETE_GENERAL_PARTNER_USER',
  GRANT_GENERAL_PARTNER_USER_PERMISSIONS: 'GRANT_GENERAL_PARTNER_USER_PERMISSIONS',
  WRITE_GENERAL_PARTNER_PERMISSION_GROUPS: 'WRITE_GENERAL_PARTNER_PERMISSION_GROUPS',
};

export type Feature =
  | 'PORTFOLIO'
  | 'SUBSCRIPTION_PROCESS'
  | 'INVESTMENTS'
  | 'REQUEST_PLATFORM_ACCESS'
  | 'SHARE_PRODUCTS'
  | 'USE_PRODUCTS_FROM_NETWORK';
export const Features: Record<Feature, Feature> = {
  PORTFOLIO: 'PORTFOLIO',
  SUBSCRIPTION_PROCESS: 'SUBSCRIPTION_PROCESS',
  INVESTMENTS: 'INVESTMENTS',
  REQUEST_PLATFORM_ACCESS: 'REQUEST_PLATFORM_ACCESS',
  SHARE_PRODUCTS: 'SHARE_PRODUCTS',
  USE_PRODUCTS_FROM_NETWORK: 'USE_PRODUCTS_FROM_NETWORK',
};

export type AccountType = 'INVESTOR' | 'GENERAL_PARTNER';
export const AccountTypes: Record<AccountType, AccountType> = {
  INVESTOR: 'INVESTOR',
  GENERAL_PARTNER: 'GENERAL_PARTNER',
};

export type Industry =
  | 'REAL_ESTATE'
  | 'TECHNOLOGY_MEDIA_TELECOMMUNICATIONS'
  | 'FINANCIAL_SERVICES'
  | 'CONSUMER_INDUSTRIAL_PRODUCTS'
  | 'LIFE_SCIENCES_HEALTH_CARE';
export const Industries: Record<Industry, Industry> = {
  REAL_ESTATE: 'REAL_ESTATE',
  TECHNOLOGY_MEDIA_TELECOMMUNICATIONS: 'TECHNOLOGY_MEDIA_TELECOMMUNICATIONS',
  FINANCIAL_SERVICES: 'FINANCIAL_SERVICES',
  CONSUMER_INDUSTRIAL_PRODUCTS: 'CONSUMER_INDUSTRIAL_PRODUCTS',
  LIFE_SCIENCES_HEALTH_CARE: 'LIFE_SCIENCES_HEALTH_CARE',
};

export type Geography =
  | 'GLOBAL'
  | 'EUROPE'
  | 'ASIA'
  | 'NORTH_AMERICA'
  | 'SOUTH_AMERICA'
  | 'AFRICA'
  | 'AUSTRALIA_OCEANIA';
export const Geographies: Record<Geography, Geography> = {
  GLOBAL: 'GLOBAL',
  EUROPE: 'EUROPE',
  ASIA: 'ASIA',
  NORTH_AMERICA: 'NORTH_AMERICA',
  SOUTH_AMERICA: 'SOUTH_AMERICA',
  AFRICA: 'AFRICA',
  AUSTRALIA_OCEANIA: 'AUSTRALIA_OCEANIA',
};

export type Currency =
  | 'EUR'
  | 'USD'
  | 'CHF';
export const Currencies: Record<Currency, Currency> = {
  EUR: 'EUR',
  USD: 'USD',
  CHF: 'CHF',
};

export const CurrencySymbols: Record<Currency, string> = {
  EUR: '€',
  USD: '$',
  CHF: 'CHF',
};

export type Language = 'en' | 'de';
export const Languages: Record<Language, Language> = {
  en: 'en',
  de: 'de',
};

export type MimeType =
  | 'image/jpeg'
  | 'image/gif'
  | 'image/png'
  | 'image/webp'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.ms-excel.sheet.binary.macroenabled.12'
  | 'application/vnd.ms-excel'
  | 'application/vnd.ms-excel.sheet.macroenabled.12'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/zip'
  | 'application/vnd.rar'
  | 'application/x-rar'
  | 'application/x-rar-compressed'
  | 'video/mp4'
  | 'video/mpeg'
  | 'video/x-msvideo'
  | 'video/quicktime'
export type MimeTypeKeys =
  | 'JPEG'
  | 'GIF'
  | 'PNG'
  | 'WEBP'
  | 'PDF'
  | 'DOC'
  | 'DOCX'
  | 'XLSX'
  | 'XLSB'
  | 'XLS'
  | 'XLSM'
  | 'PPT'
  | 'PPTX'
  | 'ZIP'
  | 'RAR_01'
  | 'RAR_02'
  | 'RAR_03'
  | 'MP4'
  | 'MPEG'
  | 'AVI'
  | 'MOV';
export const MimeTypes: Record<MimeTypeKeys, MimeType> = {
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
  PNG: 'image/png',
  WEBP: 'image/webp',
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLSB: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  XLS: 'application/vnd.ms-excel',
  XLSM: 'application/vnd.ms-excel.sheet.macroenabled.12',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ZIP: 'application/zip',
  RAR_01: 'application/vnd.rar',
  RAR_02: 'application/x-rar',
  RAR_03: 'application/x-rar-compressed',
  MP4: 'video/mp4',
  MPEG: 'video/mpeg',
  AVI: 'video/x-msvideo',
  MOV: 'video/quicktime',
};

export type Salutation = 'MR' | 'MS' | 'MX';
export const Salutations: Record<Salutation, Salutation> = {
  MR: 'MR',
  MS: 'MS',
  MX: 'MX',
};

export type DataRoomType = 'DUE_DILIGENCE' | 'SUBSCRIPTION';

export type UNodeType = 'FILE' | 'DIRECTORY';
export const UNodeTypes: Record<UNodeType, UNodeType> = {
  FILE: 'FILE',
  DIRECTORY: 'DIRECTORY',
};

export type SubscriptionProcessStatus =
  | 'STARTED'
  | 'IN_PROGRESS'
  | 'IN_REVIEW';
export const SubscriptionProcessStatuses: Record<SubscriptionProcessStatus, SubscriptionProcessStatus> = {
  STARTED: 'STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
};

export type ProductReportType = 'ANNUAL_REPORT' | 'QUARTERLY_REPORT' | 'MONTHLY_REPORT';
export const ProductReportTypes: Record<ProductReportType, ProductReportType> = {
  ANNUAL_REPORT: 'ANNUAL_REPORT',
  QUARTERLY_REPORT: 'QUARTERLY_REPORT',
  MONTHLY_REPORT: 'MONTHLY_REPORT',
};

export type ApplicationType = 'WEB' | 'NATIVE';
export const ApplicationTypes: Record<ApplicationType, ApplicationType> = {
  WEB: 'WEB',
  NATIVE: 'NATIVE',
};

export interface PositiveNumberRuleMessageKeys {
  noDecimalPoints: string;
  notMoreThanDecimalPoints: string;
  noNegative: string;
}

export type SubscriptionProcessType = 'INITIAL' | 'TOP_UP';
export const SubscriptionProcessTypes: Record<SubscriptionProcessType, SubscriptionProcessType> = {
  INITIAL: 'INITIAL',
  TOP_UP: 'TOP_UP',
};

export type TransactionType = 'INCOMING' | 'OUTGOING';
export const TransactionTypes: Record<TransactionType, TransactionType> = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
};

export interface PhoneNumber {
  countryCode: string;
  phoneNumber: string;
}

export interface Name {
  firstName: string;
  lastName: string;
}

export type InAppNotificationType =
  | 'GP_MESSAGE_PRODUCT_CREATED'
  | 'GP_MESSAGE_PRODUCT_PUBLISHED'
  | 'GP_MESSAGE_PRODUCT_UNPUBLISHED'
  | 'GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED'
  | 'GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED'
  | 'GP_MESSAGE_PRODUCT_DELETED'
  | 'GP_MESSAGE_LP_ACCOUNT_ACTIVATED'
  | 'GP_MESSAGE_INDICATED_INTEREST'
  | 'GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED'
  | 'GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED'
  | 'GP_MESSAGE_PLATFORM_NEWS_PUBLISHED'
  | 'LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED'
  | 'LP_MESSAGE_PRODUCT_PUBLISHED'
  | 'LP_MESSAGE_PRODUCT_REPORT_CREATED'
  | 'LP_MESSAGE_INVESTMENT_CREATED'
  | 'LP_MESSAGE_ACCOUNT_ACTIVATED'
  | 'LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED'
  | 'LP_MESSAGE_INCOMING_TRANSACTION_CREATED'
  | 'LP_MESSAGE_OUTGOING_TRANSACTION_CREATED'
  | 'LP_MESSAGE_PRIVATE_PRODUCT_DOCUMENTS_CREATED'
  | 'LP_MESSAGE_PRODUCT_ACCESS_GRANTED';
export const InAppNotificationTypes: Record<InAppNotificationType, InAppNotificationType> = {
  GP_MESSAGE_PRODUCT_CREATED: 'GP_MESSAGE_PRODUCT_CREATED',
  GP_MESSAGE_PRODUCT_PUBLISHED: 'GP_MESSAGE_PRODUCT_PUBLISHED',
  GP_MESSAGE_PRODUCT_UNPUBLISHED: 'GP_MESSAGE_PRODUCT_UNPUBLISHED',
  GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED: 'GP_MESSAGE_PRODUCT_INVESTABILITY_ENABLED',
  GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED: 'GP_MESSAGE_PRODUCT_INVESTABILITY_DISABLED',
  GP_MESSAGE_PRODUCT_DELETED: 'GP_MESSAGE_PRODUCT_DELETED',
  GP_MESSAGE_LP_ACCOUNT_ACTIVATED: 'GP_MESSAGE_LP_ACCOUNT_ACTIVATED',
  GP_MESSAGE_INDICATED_INTEREST: 'GP_MESSAGE_INDICATED_INTEREST',
  GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED: 'GP_MESSAGE_PRODUCT_UPDATE_PUBLISHED',
  GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED: 'GP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED',
  GP_MESSAGE_PLATFORM_NEWS_PUBLISHED: 'GP_MESSAGE_PLATFORM_NEWS_PUBLISHED',
  LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED: 'LP_MESSAGE_PRODUCT_SUBSCRIPTION_CONFIRMED',
  LP_MESSAGE_PRODUCT_PUBLISHED: 'LP_MESSAGE_PRODUCT_PUBLISHED',
  LP_MESSAGE_PRODUCT_REPORT_CREATED: 'LP_MESSAGE_PRODUCT_REPORT_CREATED',
  LP_MESSAGE_INVESTMENT_CREATED: 'LP_MESSAGE_INVESTMENT_CREATED',
  LP_MESSAGE_ACCOUNT_ACTIVATED: 'LP_MESSAGE_ACCOUNT_ACTIVATED',
  LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED: 'LP_MESSAGE_SUBSCRIPTION_DOCUMENTS_SUBMITTED',
  LP_MESSAGE_INCOMING_TRANSACTION_CREATED: 'LP_MESSAGE_INCOMING_TRANSACTION_CREATED',
  LP_MESSAGE_OUTGOING_TRANSACTION_CREATED: 'LP_MESSAGE_OUTGOING_TRANSACTION_CREATED',
  LP_MESSAGE_PRIVATE_PRODUCT_DOCUMENTS_CREATED: 'LP_MESSAGE_PRIVATE_PRODUCT_DOCUMENTS_CREATED',
  LP_MESSAGE_PRODUCT_ACCESS_GRANTED: 'LP_MESSAGE_PRODUCT_ACCESS_GRANTED',
};

export type AssetClass =
  | 'DIGITAL_ASSETS'
  | 'HEDGE_FUNDS'
  | 'INFRASTRUCTURE'
  | 'NATURAL_RESOURCES'
  | 'PRIVATE_DEBT'
  | 'PRIVATE_EQUITY'
  | 'REAL_ESTATE'
  | 'VENTURE_CAPITAL';

export const AssetClasses: Record<AssetClass, AssetClass> = {
  DIGITAL_ASSETS: 'DIGITAL_ASSETS',
  HEDGE_FUNDS: 'HEDGE_FUNDS',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  NATURAL_RESOURCES: 'NATURAL_RESOURCES',
  PRIVATE_DEBT: 'PRIVATE_DEBT',
  PRIVATE_EQUITY: 'PRIVATE_EQUITY',
  REAL_ESTATE: 'REAL_ESTATE',
  VENTURE_CAPITAL: 'VENTURE_CAPITAL',
};

export type PrivateEquitySubAssetClass =
  | 'BUYOUT'
  | 'GROWTH'
  | 'SECONDARIES'
  | 'DISTRESSED'
  | 'SPECIAL_SITUATIONS'
  | 'OTHER';
export const PrivateEquitySubAssetClasses: Record<PrivateEquitySubAssetClass, PrivateEquitySubAssetClass> = {
  BUYOUT: 'BUYOUT',
  GROWTH: 'GROWTH',
  SECONDARIES: 'SECONDARIES',
  DISTRESSED: 'DISTRESSED',
  SPECIAL_SITUATIONS: 'SPECIAL_SITUATIONS',
  OTHER: 'OTHER',
};

export type PrivateDebtSubAssetClass =
  | 'DIRECT_LENDING'
  | 'DISTRESSED_DEBT'
  | 'MEZZANINE_DEBT'
  | 'SENIOR_DEBT'
  | 'SPECIAL_SITUATIONS'
  | 'VENTURE_DEBT'
  | 'OTHER';
export const PrivateDebtSubAssetClasses: Record<PrivateDebtSubAssetClass, PrivateDebtSubAssetClass> = {
  DIRECT_LENDING: 'DIRECT_LENDING',
  DISTRESSED_DEBT: 'DISTRESSED_DEBT',
  MEZZANINE_DEBT: 'MEZZANINE_DEBT',
  SENIOR_DEBT: 'SENIOR_DEBT',
  SPECIAL_SITUATIONS: 'SPECIAL_SITUATIONS',
  VENTURE_DEBT: 'VENTURE_DEBT',
  OTHER: 'OTHER',
};

export type VentureCapitalSubAssetClass =
  | 'SEED_STAGE'
  | 'EARLY_STAGE'
  | 'EXPANSION'
  | 'LATE_STAGE'
  | 'BRIDGE_FINANCING'
  | 'OTHER';
export const VentureCapitalSubAssetClasses: Record<VentureCapitalSubAssetClass, VentureCapitalSubAssetClass> = {
  SEED_STAGE: 'SEED_STAGE',
  EARLY_STAGE: 'EARLY_STAGE',
  EXPANSION: 'EXPANSION',
  LATE_STAGE: 'LATE_STAGE',
  BRIDGE_FINANCING: 'BRIDGE_FINANCING',
  OTHER: 'OTHER',
};

export type InfrastructureSubAssetClass =
  | 'UTILITIES_AND_TRANSPORTATION'
  | 'TOLL_ROADS'
  | 'AIRPORTS'
  | 'BRIDGES'
  | 'ELECTRICITY_AND_GAS_NETWORK'
  | 'PIPELINES'
  | 'HOSPITALS'
  | 'RENEWABLE_ENERGY'
  | 'OTHER';
export const InfrastructureSubAssetClasses: Record<InfrastructureSubAssetClass, InfrastructureSubAssetClass> = {
  UTILITIES_AND_TRANSPORTATION: 'UTILITIES_AND_TRANSPORTATION',
  TOLL_ROADS: 'TOLL_ROADS',
  AIRPORTS: 'AIRPORTS',
  BRIDGES: 'BRIDGES',
  ELECTRICITY_AND_GAS_NETWORK: 'ELECTRICITY_AND_GAS_NETWORK',
  PIPELINES: 'PIPELINES',
  HOSPITALS: 'HOSPITALS',
  RENEWABLE_ENERGY: 'RENEWABLE_ENERGY',
  OTHER: 'OTHER',
};

export type RealEstateSubAssetClass =
  | 'RESIDENTIAL'
  | 'HOSPITALITY'
  | 'SELF_STORAGE'
  | 'LOGISTICS'
  | 'INDUSTRIAL'
  | 'OFFICE'
  | 'RETAIL'
  | 'DIVERSIFIED'
  | 'HEALTH_CARE'
  | 'DATA_CENTERS'
  | 'LAND'
  | 'LAND_BANKING'
  | 'OTHER';
export const RealEstateSubAssetClasses: Record<RealEstateSubAssetClass, RealEstateSubAssetClass> = {
  RESIDENTIAL: 'RESIDENTIAL',
  HOSPITALITY: 'HOSPITALITY',
  SELF_STORAGE: 'SELF_STORAGE',
  LOGISTICS: 'LOGISTICS',
  INDUSTRIAL: 'INDUSTRIAL',
  OFFICE: 'OFFICE',
  RETAIL: 'RETAIL',
  DIVERSIFIED: 'DIVERSIFIED',
  HEALTH_CARE: 'HEALTH_CARE',
  DATA_CENTERS: 'DATA_CENTERS',
  LAND: 'LAND',
  LAND_BANKING: 'LAND_BANKING',
  OTHER: 'OTHER',
};

export type HedgeFundSubAssetClass =
  | 'RELATIVE_VALUE'
  | 'EVENT_DRIVEN'
  | 'OPPORTUNISTIC'
  | 'MANAGED_FUTURES'
  | 'MULTI_STRATEGY'
  | 'OTHER';
export const HedgeFundSubAssetClasses: Record<HedgeFundSubAssetClass, HedgeFundSubAssetClass> = {
  RELATIVE_VALUE: 'RELATIVE_VALUE',
  EVENT_DRIVEN: 'EVENT_DRIVEN',
  OPPORTUNISTIC: 'OPPORTUNISTIC',
  MANAGED_FUTURES: 'MANAGED_FUTURES',
  MULTI_STRATEGY: 'MULTI_STRATEGY',
  OTHER: 'OTHER',
};

export type SubscriptionAgreementTemplateToPrefillStatus =
  | 'ORIGINAL_UPLOADED'
  | 'DOCUMENT_WITH_PLACEHOLDERS_UPLOADED'
  | 'RELEASED';
export const SubscriptionAgreementTemplateToPrefillStatuses: Record<
  SubscriptionAgreementTemplateToPrefillStatus, SubscriptionAgreementTemplateToPrefillStatus
> = {
  ORIGINAL_UPLOADED: 'ORIGINAL_UPLOADED',
  DOCUMENT_WITH_PLACEHOLDERS_UPLOADED: 'DOCUMENT_WITH_PLACEHOLDERS_UPLOADED',
  RELEASED: 'RELEASED',
};

export type ControllingPersonTemplateToPrefillStatus =
  | 'ORIGINAL_UPLOADED'
  | 'DOCUMENT_WITH_PLACEHOLDERS_UPLOADED'
  | 'RELEASED';
export const ControllingPersonTemplateToPrefillStatuses: Record<
  ControllingPersonTemplateToPrefillStatus, ControllingPersonTemplateToPrefillStatus
> = {
  ORIGINAL_UPLOADED: 'ORIGINAL_UPLOADED',
  DOCUMENT_WITH_PLACEHOLDERS_UPLOADED: 'DOCUMENT_WITH_PLACEHOLDERS_UPLOADED',
  RELEASED: 'RELEASED',
};

export type NaturalResourcesSubAssetClass =
  | 'AGRICULTURE'
  | 'ENERGY'
  | 'METALS_AND_MINING'
  | 'TIMBERLAND'
  | 'WATER'
  | 'OTHER';
export const NaturalResourcesSubAssetClasses: Record<NaturalResourcesSubAssetClass, NaturalResourcesSubAssetClass> = {
  AGRICULTURE: 'AGRICULTURE',
  ENERGY: 'ENERGY',
  METALS_AND_MINING: 'METALS_AND_MINING',
  TIMBERLAND: 'TIMBERLAND',
  WATER: 'WATER',
  OTHER: 'OTHER',
};

export type DigitalAssetsSubAssetClass =
  | 'DEFI_AND_CRYPTOCURRENCIES'
  | 'NFTS'
  | 'SECURITY_TOKENS'
  | 'UTILITY_TOKENS'
  | 'COMMODITY_TOKENS'
  | 'OTHER';
export const DigitalAssetsSubAssetClasses: Record<DigitalAssetsSubAssetClass, DigitalAssetsSubAssetClass> = {
  DEFI_AND_CRYPTOCURRENCIES: 'DEFI_AND_CRYPTOCURRENCIES',
  NFTS: 'NFTS',
  SECURITY_TOKENS: 'SECURITY_TOKENS',
  UTILITY_TOKENS: 'UTILITY_TOKENS',
  COMMODITY_TOKENS: 'COMMODITY_TOKENS',
  OTHER: 'OTHER',
};

export type SubAssetClass =
  | PrivateEquitySubAssetClass
  | PrivateDebtSubAssetClass
  | VentureCapitalSubAssetClass
  | InfrastructureSubAssetClass
  | RealEstateSubAssetClass
  | HedgeFundSubAssetClass
  | NaturalResourcesSubAssetClass
  | DigitalAssetsSubAssetClass;

export type InvestmentStructure =
  | 'CONVERTIBLE_DEBT'
  | 'COMMON_EQUITY'
  | 'PREFERRED_EQUITY'
  | 'CO_INVESTMENT'
  | 'FUND_OF_FUNDS'
  | 'CLOSE_ENDED_FUND'
  | 'OPEN_ENDED_FUND'
  | 'DEBT';
export const InvestmentStructures: Record<InvestmentStructure, InvestmentStructure> = {
  CONVERTIBLE_DEBT: 'CONVERTIBLE_DEBT',
  COMMON_EQUITY: 'COMMON_EQUITY',
  PREFERRED_EQUITY: 'PREFERRED_EQUITY',
  CO_INVESTMENT: 'CO_INVESTMENT',
  FUND_OF_FUNDS: 'FUND_OF_FUNDS',
  CLOSE_ENDED_FUND: 'CLOSE_ENDED_FUND',
  OPEN_ENDED_FUND: 'OPEN_ENDED_FUND',
  DEBT: 'DEBT',
};

export type StaticKPIType =
  | 'ASSET_CLASS'
  | 'CURRENCY'
  | 'GEOGRAPHY'
  | 'INVESTMENT_STRUCTURE'
  | 'INDUSTRY'
  | 'TARGET_SIZE'
  | 'MINIMUM_INVESTMENT_SIZE'
  | 'SUB_ASSET_CLASS';

export type DynamicKPIType =
  | 'BOOLEAN'
  | 'NUMBER'
  | 'NUMBER_CURRENCY'
  | 'NUMBER_CURRENCY_RANGE'
  | 'NUMBER_PERCENTAGE'
  | 'TEXT'
  | 'YEAR_MONTH';

export type CustomKPIType = 'CUSTOM';

export type KPIType =
  | StaticKPIType
  | DynamicKPIType
  | CustomKPIType;

export const DynamicKPITypes: Record<DynamicKPIType, DynamicKPIType> = {
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  NUMBER_CURRENCY: 'NUMBER_CURRENCY',
  NUMBER_CURRENCY_RANGE: 'NUMBER_CURRENCY_RANGE',
  NUMBER_PERCENTAGE: 'NUMBER_PERCENTAGE',
  TEXT: 'TEXT',
  YEAR_MONTH: 'YEAR_MONTH',
};

export const RequiredStaticKPITypes: Partial<Record<StaticKPIType, StaticKPIType>> = {
  ASSET_CLASS: 'ASSET_CLASS',
  CURRENCY: 'CURRENCY',
  GEOGRAPHY: 'GEOGRAPHY',
  INVESTMENT_STRUCTURE: 'INVESTMENT_STRUCTURE',
  SUB_ASSET_CLASS: 'SUB_ASSET_CLASS',
};

export const StaticKPITypes: Record<StaticKPIType, StaticKPIType> = {
  ASSET_CLASS: 'ASSET_CLASS',
  CURRENCY: 'CURRENCY',
  GEOGRAPHY: 'GEOGRAPHY',
  INDUSTRY: 'INDUSTRY',
  INVESTMENT_STRUCTURE: 'INVESTMENT_STRUCTURE',
  SUB_ASSET_CLASS: 'SUB_ASSET_CLASS',
  TARGET_SIZE: 'TARGET_SIZE',
  MINIMUM_INVESTMENT_SIZE: 'MINIMUM_INVESTMENT_SIZE',
};

export const KPITypes: Record<KPIType, KPIType> = {
  ...StaticKPITypes,
  ...DynamicKPITypes,
  CUSTOM: 'CUSTOM',
};

export const KeyFactKPITypes: KPIType[] = [
  KPITypes.TARGET_SIZE,
  KPITypes.MINIMUM_INVESTMENT_SIZE,
  KPITypes.INDUSTRY,
  KPITypes.INVESTMENT_STRUCTURE,
  KPITypes.GEOGRAPHY,
];

export interface KPIConfiguration {
  kpiType: KPIType;
  meta: TermSheetKPINumberMeta
    | TermSheetKPINumberCurrencyMeta
    | TermSheetKPINumberPercentageMeta;
}

export interface TermSheetKPINumberMeta {
  decimals: number;
}

export interface TermSheetKPINumberCurrencyMeta {
  decimals: number;
}

export interface TermSheetKPINumberPercentageMeta {
  decimals: number;
}

export type LanguageDependentData<T> = { en: T } & Partial<Record<Language, T>>;

export type TranslatedText = LanguageDependentData<string>;
export type NumberRange = {
  from: Decimal|null;
  to: Decimal|null;
}

export interface FileReference {
  createdAt: Dayjs;
  fileSize: Decimal;
  internalFileName: string;
  mimeType: string;
  originalFileName: string;
}

export type KPIValue = null|string|boolean|Decimal|TranslatedText|NumberRange;
export interface KPI {
  kpiId: string;
  kpiType: KPIType;
  kpiDefinitionId?: string;
  kpiConfiguration?: KPIConfiguration;
  label: TranslatedText;
  kpiValue: KPIValue;
  prefix?: TranslatedText;
  suffix?: TranslatedText;
}
export type KPIsByKPICategoryId = Record<string, KPI[]>;

export interface TermSheet {
  kpiCategories: KPICategory[];
}

export interface KPICategory {
  kpiCategoryId: string;
  label: TranslatedText;
  kpis: KPI[];
}

export type UserActivityLogEntryType =
  | 'GENERAL_PARTNER_USER_LOGGED_IN'
  | 'GENERAL_PARTNER_USER_INVITED_GENERAL_PARTNER_USER'
  | 'GENERAL_PARTNER_USER_ACCEPTED_INVITATION'
  | 'GENERAL_PARTNER_USER_INVITED_INVESTOR_USER'
  | 'GENERAL_PARTNER_USER_ENABLED_PRODUCT_INVESTABILITY'
  | 'GENERAL_PARTNER_USER_DISABLED_PRODUCT_INVESTABILITY'
  | 'GENERAL_PARTNER_USER_PUBLISHED_PRODUCT'
  | 'GENERAL_PARTNER_USER_UNPUBLISHED_PRODUCT'
  | 'GENERAL_PARTNER_USER_SHARED_PRODUCT'
  | 'GENERAL_PARTNER_USER_UNSHARED_PRODUCT'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT'
  | 'GENERAL_PARTNER_USER_UPDATED_GENERAL_PARTNER_USERS_WITH_ACCESS_FOR_PRODUCT'
  | 'GENERAL_PARTNER_USER_UPDATED_INVESTORS_WITH_ACCESS_FOR_PRODUCT'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_PREVIEW'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_NAME'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_DESCRIPTION'
  | 'GENERAL_PARTNER_USER_ADDED_PRODUCT_SUMMARY_ITEM'
  | 'GENERAL_PARTNER_USER_REMOVED_PRODUCT_SUMMARY_ITEM'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_IMAGE'
  | 'GENERAL_PARTNER_USER_ADDED_PRODUCT_MANAGER'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGER'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT_MANAGER'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGERS_ORDER'
  | 'GENERAL_PARTNER_USER_UPDATED_TRACK_RECORD_IMAGE'
  | 'GENERAL_PARTNER_USER_DELETED_TRACK_RECORD_IMAGE'
  | 'GENERAL_PARTNER_USER_CREATED_VIMEO_VIDEO'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT_VIDEO'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_CONTACT_EMAIL_ADDRESS'
  | 'GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_NEWS_ARTICLE'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE'
  | 'GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE_ATTACHMENT'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE_ATTACHMENT'
  | 'GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_NEWS_ARTICLE_ATTACHMENT'
  | 'GENERAL_PARTNER_USER_CREATED_PRODUCT_REPORT'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_REPORT'
  | 'GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_REPORT'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT_REPORT'
  | 'GENERAL_PARTNER_USER_ENABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS'
  | 'GENERAL_PARTNER_USER_DISABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS'
  | 'GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_DOCUMENT_TEMPLATE'
  | 'GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_DOCUMENT_TEMPLATE'
  | 'GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL'
  | 'GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL'
  | 'GENERAL_PARTNER_USER_CREATED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL'
  | 'GENERAL_PARTNER_USER_DELETED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL'
  | 'GENERAL_PARTNER_USER_UPDATED_SUBSCRIPTION_INSTRUCTION'
  | 'GENERAL_PARTNER_USER_UPDATED_KYC_INSTRUCTION'
  | 'GENERAL_PARTNER_USER_CREATED_PRODUCT_TERM_SHEET_CATEGORY'
  | 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_TERM_SHEET_CATEGORY'
  | 'GENERAL_PARTNER_USER_DELETED_PRODUCT_TERM_SHEET_CATEGORY'
  | 'GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_DIRECTORY'
  | 'GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_FILE'
  | 'GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_FILE'
  | 'GENERAL_PARTNER_USER_UPLOADED_DUE_DILIGENCE_FILE_WITH_PATH'
  | 'GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_DIRECTORY_NAME'
  | 'GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_DIRECTORY'
  | 'GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_FILE'
  | 'GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_DIRECTORY'
  | 'GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_FILE'
  | 'GENERAL_PARTNER_USER_PINNED_DUE_DILIGENCE_FILE'
  | 'GENERAL_PARTNER_USER_UNPINNED_DUE_DILIGENCE_FILE'
  | 'GENERAL_PARTNER_USER_CHANGED_DUE_DILIGENCE_FILES_ORDER'
  | 'GENERAL_PARTNER_USER_IMPERSONATED_INVESTOR_USER'
  | 'INVESTOR_USER_LOGGED_IN'
  | 'INVESTOR_USER_DOWNLOADED_DATA_ROOM'
  | 'INVESTOR_USER_DOWNLOADED_FILE_IN_DATA_ROOM'
  | 'INVESTOR_USER_DOWNLOADED_DIRECTORY_IN_DATA_ROOM'
  | 'INVESTOR_USER_ACCEPTED_PRODUCT_NDA'
  | 'INVESTOR_USER_ACCEPTED_INVITATION'
  | 'USER_UPDATED_OWN_NAME'
  | 'USER_REQUESTED_NEW_EMAIL_ADDRESS'
  | 'USER_CONFIRMED_NEW_EMAIL_ADDRESS'
  | 'USER_UPDATED_OWN_PHONE_NUMBER'
  | 'USER_UPDATED_OWN_COUNTRY_OF_RESIDENCE'
  | 'USER_UPDATED_OWN_LANGUAGE'
  | 'USER_UPDATED_OWN_PASSWORD';

export const UserActivityLogEntryTypes: Record<UserActivityLogEntryType, UserActivityLogEntryType> = {
  GENERAL_PARTNER_USER_LOGGED_IN: 'GENERAL_PARTNER_USER_LOGGED_IN',
  GENERAL_PARTNER_USER_INVITED_GENERAL_PARTNER_USER: 'GENERAL_PARTNER_USER_INVITED_GENERAL_PARTNER_USER',
  GENERAL_PARTNER_USER_ACCEPTED_INVITATION: 'GENERAL_PARTNER_USER_ACCEPTED_INVITATION',
  GENERAL_PARTNER_USER_INVITED_INVESTOR_USER: 'GENERAL_PARTNER_USER_INVITED_INVESTOR_USER',
  GENERAL_PARTNER_USER_ENABLED_PRODUCT_INVESTABILITY: 'GENERAL_PARTNER_USER_ENABLED_PRODUCT_INVESTABILITY',
  GENERAL_PARTNER_USER_DISABLED_PRODUCT_INVESTABILITY: 'GENERAL_PARTNER_USER_DISABLED_PRODUCT_INVESTABILITY',
  GENERAL_PARTNER_USER_PUBLISHED_PRODUCT: 'GENERAL_PARTNER_USER_PUBLISHED_PRODUCT',
  GENERAL_PARTNER_USER_SHARED_PRODUCT: 'GENERAL_PARTNER_USER_SHARED_PRODUCT',
  GENERAL_PARTNER_USER_UNSHARED_PRODUCT: 'GENERAL_PARTNER_USER_UNSHARED_PRODUCT',
  GENERAL_PARTNER_USER_UNPUBLISHED_PRODUCT: 'GENERAL_PARTNER_USER_UNPUBLISHED_PRODUCT',
  GENERAL_PARTNER_USER_DELETED_PRODUCT: 'GENERAL_PARTNER_USER_DELETED_PRODUCT',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_UPDATED_GENERAL_PARTNER_USERS_WITH_ACCESS_FOR_PRODUCT: 'GENERAL_PARTNER_USER_UPDATED_GENERAL_PARTNER_USERS_WITH_ACCESS_FOR_PRODUCT',
  GENERAL_PARTNER_USER_UPDATED_INVESTORS_WITH_ACCESS_FOR_PRODUCT: 'GENERAL_PARTNER_USER_UPDATED_INVESTORS_WITH_ACCESS_FOR_PRODUCT',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_PREVIEW: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_PREVIEW',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_NAME: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_NAME',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_DESCRIPTION: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_DESCRIPTION',
  GENERAL_PARTNER_USER_ADDED_PRODUCT_SUMMARY_ITEM: 'GENERAL_PARTNER_USER_ADDED_PRODUCT_SUMMARY_ITEM',
  GENERAL_PARTNER_USER_REMOVED_PRODUCT_SUMMARY_ITEM: 'GENERAL_PARTNER_USER_REMOVED_PRODUCT_SUMMARY_ITEM',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_IMAGE: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_IMAGE',
  GENERAL_PARTNER_USER_ADDED_PRODUCT_MANAGER: 'GENERAL_PARTNER_USER_ADDED_PRODUCT_MANAGER',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGER: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGER',
  GENERAL_PARTNER_USER_DELETED_PRODUCT_MANAGER: 'GENERAL_PARTNER_USER_DELETED_PRODUCT_MANAGER',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGERS_ORDER: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGERS_ORDER',
  GENERAL_PARTNER_USER_UPDATED_TRACK_RECORD_IMAGE: 'GENERAL_PARTNER_USER_UPDATED_TRACK_RECORD_IMAGE',
  GENERAL_PARTNER_USER_DELETED_TRACK_RECORD_IMAGE: 'GENERAL_PARTNER_USER_DELETED_TRACK_RECORD_IMAGE',
  GENERAL_PARTNER_USER_CREATED_VIMEO_VIDEO: 'GENERAL_PARTNER_USER_CREATED_VIMEO_VIDEO',
  GENERAL_PARTNER_USER_DELETED_PRODUCT_VIDEO: 'GENERAL_PARTNER_USER_DELETED_PRODUCT_VIDEO',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_CONTACT_EMAIL_ADDRESS: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_CONTACT_EMAIL_ADDRESS',
  GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE: 'GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_NEWS_ARTICLE: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_NEWS_ARTICLE',
  GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE: 'GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE',
  GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE_ATTACHMENT: 'GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE_ATTACHMENT',
  GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE_ATTACHMENT: 'GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE_ATTACHMENT',
  GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_NEWS_ARTICLE_ATTACHMENT: 'GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_NEWS_ARTICLE_ATTACHMENT',
  GENERAL_PARTNER_USER_CREATED_PRODUCT_REPORT: 'GENERAL_PARTNER_USER_CREATED_PRODUCT_REPORT',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_REPORT: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_REPORT',
  GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_REPORT: 'GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_REPORT',
  GENERAL_PARTNER_USER_DELETED_PRODUCT_REPORT: 'GENERAL_PARTNER_USER_DELETED_PRODUCT_REPORT',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_ENABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS: 'GENERAL_PARTNER_USER_ENABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_DISABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS: 'GENERAL_PARTNER_USER_DISABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS',
  GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_DOCUMENT_TEMPLATE: 'GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_DOCUMENT_TEMPLATE',
  GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_DOCUMENT_TEMPLATE: 'GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_DOCUMENT_TEMPLATE',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL: 'GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL: 'GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_CREATED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL: 'GENERAL_PARTNER_USER_CREATED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL',
  // eslint-disable-next-line max-len
  GENERAL_PARTNER_USER_DELETED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL: 'GENERAL_PARTNER_USER_DELETED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL',
  GENERAL_PARTNER_USER_UPDATED_SUBSCRIPTION_INSTRUCTION: 'GENERAL_PARTNER_USER_UPDATED_SUBSCRIPTION_INSTRUCTION',
  GENERAL_PARTNER_USER_UPDATED_KYC_INSTRUCTION: 'GENERAL_PARTNER_USER_UPDATED_KYC_INSTRUCTION',
  GENERAL_PARTNER_USER_CREATED_PRODUCT_TERM_SHEET_CATEGORY: 'GENERAL_PARTNER_USER_CREATED_PRODUCT_TERM_SHEET_CATEGORY',
  GENERAL_PARTNER_USER_UPDATED_PRODUCT_TERM_SHEET_CATEGORY: 'GENERAL_PARTNER_USER_UPDATED_PRODUCT_TERM_SHEET_CATEGORY',
  GENERAL_PARTNER_USER_DELETED_PRODUCT_TERM_SHEET_CATEGORY: 'GENERAL_PARTNER_USER_DELETED_PRODUCT_TERM_SHEET_CATEGORY',
  GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_DIRECTORY: 'GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_DIRECTORY',
  GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_FILE: 'GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_FILE',
  GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_FILE: 'GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_FILE',
  GENERAL_PARTNER_USER_UPLOADED_DUE_DILIGENCE_FILE_WITH_PATH: 'GENERAL_PARTNER_USER_UPLOADED_DUE_DILIGENCE_FILE_WITH_PATH',
  GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_DIRECTORY_NAME: 'GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_DIRECTORY_NAME',
  GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_DIRECTORY: 'GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_DIRECTORY',
  GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_FILE: 'GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_FILE',
  GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_DIRECTORY: 'GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_DIRECTORY',
  GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_FILE: 'GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_FILE',
  GENERAL_PARTNER_USER_PINNED_DUE_DILIGENCE_FILE: 'GENERAL_PARTNER_USER_PINNED_DUE_DILIGENCE_FILE',
  GENERAL_PARTNER_USER_UNPINNED_DUE_DILIGENCE_FILE: 'GENERAL_PARTNER_USER_UNPINNED_DUE_DILIGENCE_FILE',
  GENERAL_PARTNER_USER_CHANGED_DUE_DILIGENCE_FILES_ORDER: 'GENERAL_PARTNER_USER_CHANGED_DUE_DILIGENCE_FILES_ORDER',
  GENERAL_PARTNER_USER_IMPERSONATED_INVESTOR_USER: 'GENERAL_PARTNER_USER_IMPERSONATED_INVESTOR_USER',
  INVESTOR_USER_LOGGED_IN: 'INVESTOR_USER_LOGGED_IN',
  INVESTOR_USER_DOWNLOADED_DATA_ROOM: 'INVESTOR_USER_DOWNLOADED_DATA_ROOM',
  INVESTOR_USER_DOWNLOADED_FILE_IN_DATA_ROOM: 'INVESTOR_USER_DOWNLOADED_FILE_IN_DATA_ROOM',
  INVESTOR_USER_DOWNLOADED_DIRECTORY_IN_DATA_ROOM: 'INVESTOR_USER_DOWNLOADED_DIRECTORY_IN_DATA_ROOM',
  INVESTOR_USER_ACCEPTED_PRODUCT_NDA: 'INVESTOR_USER_ACCEPTED_PRODUCT_NDA',
  INVESTOR_USER_ACCEPTED_INVITATION: 'INVESTOR_USER_ACCEPTED_INVITATION',
  USER_UPDATED_OWN_NAME: 'USER_UPDATED_OWN_NAME',
  USER_REQUESTED_NEW_EMAIL_ADDRESS: 'USER_REQUESTED_NEW_EMAIL_ADDRESS',
  USER_CONFIRMED_NEW_EMAIL_ADDRESS: 'USER_CONFIRMED_NEW_EMAIL_ADDRESS',
  USER_UPDATED_OWN_PHONE_NUMBER: 'USER_UPDATED_OWN_PHONE_NUMBER',
  USER_UPDATED_OWN_COUNTRY_OF_RESIDENCE: 'USER_UPDATED_OWN_COUNTRY_OF_RESIDENCE',
  USER_UPDATED_OWN_LANGUAGE: 'USER_UPDATED_OWN_LANGUAGE',
  USER_UPDATED_OWN_PASSWORD: 'USER_UPDATED_OWN_PASSWORD',
};

export type UserActivityLogEntryPayload =
  GeneralPartnerUserLoggedInPayload
  | GeneralPartnerUserInvitedGeneralPartnerUserPayload
  | GeneralPartnerUserAcceptedInvitationPayload
  | GeneralPartnerUserInvitedInvestorUserPayload
  | GeneralPartnerUserEnabledProductInvestabilityPayload
  | GeneralPartnerUserCreatedVimeoVideoPayload
  | GeneralPartnerUserDeletedProductVideoPayload
  | GeneralPartnerUserDisabledProductInvestabilityPayload
  | GeneralPartnerUserUpdatedProductContactEmailAddressPayload
  | GeneralPartnerUserUpdatedProductManagersOrderPayload
  | GeneralPartnerUserUpdatedTrackRecordImagePayload
  | GeneralPartnerUserDeletedTrackRecordImagePayload
  | GeneralPartnerUserPublishedProductPayload
  | GeneralPartnerUserUnpublishedProductPayload
  | GeneralPartnerUserDeletedProductPayload
  | GeneralPartnerUserUpdatedProductDescriptionPayload
  | GeneralPartnerUserAddedProductSummaryItemPayload
  | GeneralPartnerUserRemovedProductSummaryItemPayload
  | GeneralPartnerUserUpdatedProductImagePayload
  | GeneralPartnerUserUpdatedInvestorsWithAccessForProductPayload
  | GeneralPartnerUserUpdatedGeneralPartnerUsersWithAccessForProductPayload
  | GeneralPartnerUserUpdatedProductPreviewPayload
  | GeneralPartnerUserUpdatedProductNamePayload
  | GeneralPartnerUserAddedProductManagerPayload
  | GeneralPartnerUserUpdatedProductManagerPayload
  | GeneralPartnerUserDeletedProductManagerPayload
  | GeneralPartnerUserCreatedProductNewsArticlePayload
  | GeneralPartnerUserUpdatedProductNewsArticlePayload
  | GeneralPartnerUserDeletedProductNewsArticlePayload
  | GeneralPartnerUserCreatedProductNewsArticleAttachmentPayload
  | GeneralPartnerUserDeletedProductNewsArticleAttachmentPayload
  | GeneralPartnerUserDownloadedProductNewsArticleAttachmentPayload
  | GeneralPartnerUserEnabledDocumentManagementForSubscriptionProcessPayload
  | GeneralPartnerUserDisabledDocumentManagementForSubscriptionProcessPayload
  | GeneralPartnerUserCreatedSubscriptionDocumentTemplatePayload
  | GeneralPartnerUserDeletedSubscriptionDocumentTemplatePayload
  | GeneralPartnerUserUpdatedSubscriptionInstructionPayload
  | GeneralPartnerUserUpdatedKYCInstructionPayload
  | GeneralPartnerUserCreatedProductTermSheetCategoryPayload
  | GeneralPartnerUserUpdatedProductTermSheetCategoryPayload
  | GeneralPartnerUserDeletedProductTermSheetCategoryPayload
  | GeneralPartnerUserCreatedDueDiligenceDirectoryPayload
  | GeneralPartnerUserCreatedDueDiligenceFilePayload
  | GeneralPartnerUserUpdatedDueDiligenceFilePayload
  | GeneralPartnerUserUploadedDueDiligenceFileWithPathPayload
  | GeneralPartnerUserUpdatedDueDiligenceDirectoryNamePayload
  | GeneralPartnerUserDownloadedDueDiligenceDirectoryPayload
  | GeneralPartnerUserDownloadedDueDiligenceFilePayload
  | GeneralPartnerUserDeletedDueDiligenceDirectoryPayload
  | GeneralPartnerUserDeletedDueDiligenceFilePayload
  | GeneralPartnerUserPinnedDueDiligenceFilePayload
  | GeneralPartnerUserUnpinnedDueDiligenceFilePayload
  | GeneralPartnerUserChangedDueDiligenceFilesOrderPayload
  | GeneralPartnerUserImpersonatedInvestorUserPayload
  | ProductReportdocument
  | GeneralPartnerUserCreatedProductReportPayload
  | GeneralPartnerUserUpdatedProductReportPayload
  | GeneralPartnerUserDownloadedProductReportPayload
  | GeneralPartnerUserDeletedProductReportPayload
  | InvestorUserDownloadedDataRoomPayload
  | InvestorUserDownloadedDirectoryInDataRoomPayload
  | InvestorUserDownloadedFileInDataRoomPayload
  | InvestorUserAcceptedInvitationPayload
  | UserUpdatedOwnNamePayload
  | UserRequestedNewEmailAddressPayload
  | UserConfirmedNewEmailAddressPayload
  | UserUpdatedOwnPhoneNumberPayload
  | UserUpdatedOwnCountryOfResidencePayload
  | UserUpdatedOwnLanguagePayload;

export interface InvestorUserLoggedInPayload {
  applicationType: ApplicationType;
}

export interface GeneralPartnerUserLoggedInPayload {
  applicationType: ApplicationType;
}

export interface GeneralPartnerUserInvitedGeneralPartnerUserPayload {
  generalPartnerUserName: Name;
  invitedEmailAddress: string;
}

export interface GeneralPartnerUserAcceptedInvitationPayload {
  generalPartnerUserName: Name;
}

export interface GeneralPartnerUserInvitedInvestorUserPayload {
  generalPartnerUserName: Name;
  invitedEmailAddress: string;
}

export interface GeneralPartnerUserEnabledProductInvestabilityPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserCreatedVimeoVideoPayload {
  productId: string;
  productName: TranslatedText;
  videoTitle: string;
  language: Language;
}

export interface GeneralPartnerUserDeletedProductVideoPayload {
  productId: string;
  productName: TranslatedText;
  videoTitle: string;
  language: Language;
}

export interface GeneralPartnerUserDisabledProductInvestabilityPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserUpdatedProductContactEmailAddressPayload {
  productId: string;
  productName: TranslatedText;
  previousProductContactEmailAddress: string;
  newContactEmailAddress: string;
}

export interface GeneralPartnerUserUpdatedProductManagersOrderPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserUpdatedTrackRecordImagePayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserDeletedTrackRecordImagePayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserPublishedProductPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserUnpublishedProductPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserSharedProductPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserUnsharedProductPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserDeletedProductPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserUpdatedProductDescriptionPayload {
  productId: string;
  productName: TranslatedText;
  previousProductDescription: TranslatedText;
  newProductDescription: TranslatedText;
}

export interface GeneralPartnerUserAddedProductSummaryItemPayload {
  productId: string;
  productName: TranslatedText;
  kpiLabel: TranslatedText;
}

export interface GeneralPartnerUserRemovedProductSummaryItemPayload {
  productId: string;
  productName: TranslatedText;
  kpiLabel: TranslatedText;
}

export interface GeneralPartnerUserUpdatedProductImagePayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserUpdatedInvestorsWithAccessForProductPayload {
  productId: string;
  productName: TranslatedText;
  investorNamesWithRemovedAccess: string[];
  investorNamesWithGrantedAccess: string[];
  updateState: string;
}

export interface GeneralPartnerUserUpdatedGeneralPartnerUsersWithAccessForProductPayload {
  productId: string;
  productName: TranslatedText;
  generalPartnerUserNamesWithRemovedAccess: Name[];
  generalPartnerUserNamesWithGrantedAccess: Name[];
  updateState: string;
}

export interface GeneralPartnerUserUpdatedProductPreviewPayload {
  productId: string;
  productName: TranslatedText;
  previousKPILabels: TranslatedText[];
  newKPILabels: TranslatedText[];
}

export interface GeneralPartnerUserUpdatedProductNamePayload {
  productId: string;
  productName: TranslatedText;
  previousProductName: TranslatedText;
  newProductName: TranslatedText;
}

export interface GeneralPartnerUserAddedProductManagerPayload {
  productId: string;
  productName: TranslatedText;
  managerName: string;
  jobTitle: TranslatedText;
  description: TranslatedText;
}

export interface GeneralPartnerUserUpdatedProductManagerPayload {
  productId: string;
  productName: TranslatedText;
  managerName: string;
  previousJobTitle: TranslatedText;
  newJobTitle: TranslatedText;
  previousDescription: TranslatedText;
  newDescription: TranslatedText;
}

export interface GeneralPartnerUserDeletedProductManagerPayload {
  productId: string;
  productName: TranslatedText;
  managerName: string;
}

export interface GeneralPartnerUserCreatedProductNewsArticlePayload {
  productId: string;
  productName: TranslatedText;
  productNewsArticleTitle: TranslatedText;
  productNewsArticleContent: TranslatedText;
}

export interface GeneralPartnerUserUpdatedProductNewsArticlePayload {
  productId: string;
  productName: TranslatedText;
  previousProductNewsArticleTitle: TranslatedText;
  previousProductNewsArticleContent: TranslatedText;
  newProductNewsArticleTitle: TranslatedText;
  newProductNewsArticleContent: TranslatedText;
}

export interface GeneralPartnerUserDeletedProductNewsArticlePayload {
  productId: string;
  productName: TranslatedText;
  productNewsArticleTitle: TranslatedText;
}

export interface GeneralPartnerUserCreatedProductNewsArticleAttachmentPayload {
  productId: string;
  productName: TranslatedText;
  productNewsArticleTitle: TranslatedText;
  productNewsArticleAttachmentName: string;
}

export interface GeneralPartnerUserDeletedProductNewsArticleAttachmentPayload {
  productId: string;
  productName: TranslatedText;
  productNewsArticleTitle: TranslatedText;
  originalFileNames: string[];
}

export interface GeneralPartnerUserDownloadedProductNewsArticleAttachmentPayload {
  productId: string;
  productName: TranslatedText;
  productNewsArticleTitle: TranslatedText;
  originalFileName: string;
}

export interface GeneralPartnerUserEnabledDocumentManagementForSubscriptionProcessPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserDisabledDocumentManagementForSubscriptionProcessPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserCreatedSubscriptionDocumentTemplatePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
}

export interface GeneralPartnerUserDeletedSubscriptionDocumentTemplatePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
}

export interface GeneralPartnerUserCreatedSubscriptionAgreementTemplateToPrefillPayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
}

export interface GeneralPartnerUserDeletedSubscriptionAgreementTemplateToPrefillPayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
}

export interface GeneralPartnerUserCreatedControllingPersonTemplateToPrefillPayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
}

export interface GeneralPartnerUserDeletedControllingPersonTemplateToPrefillPayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
}

export interface GeneralPartnerUserUpdatedSubscriptionInstructionPayload {
  productId: string;
  productName: TranslatedText;
  previousSubscriptionInstruction: TranslatedText;
  newSubscriptionInstruction: TranslatedText;
}

export interface GeneralPartnerUserUpdatedKYCInstructionPayload {
  productId: string;
  productName: TranslatedText;
  previousKYCInstruction: TranslatedText;
  newKYCInstruction: TranslatedText;
}

export interface GeneralPartnerUserCreatedProductTermSheetCategoryPayload {
  productId: string;
  productName: TranslatedText;
  productCurrency: Currency,
  categoryLabel: TranslatedText;
  kpis: KPI[];
}

export interface GeneralPartnerUserUpdatedProductTermSheetCategoryPayload {
  productId: string;
  productName: TranslatedText;
  previousProductCurrency: Currency,
  newProductCurrency: Currency,
  categoryLabel: TranslatedText;
  previousKPIs: KPI[];
  newKPIs: KPI[];
}

export interface GeneralPartnerUserDeletedProductTermSheetCategoryPayload {
  productId: string;
  productName: TranslatedText;
  categoryLabel: TranslatedText;
  kpiLabels: TranslatedText[];
}

export interface GeneralPartnerUserCreatedDueDiligenceDirectoryPayload {
  productId: string;
  productName: TranslatedText;
  directoryName: string;
  directoryPath: string;
}

export interface GeneralPartnerUserCreatedDueDiligenceFilePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  fileDirectoryPath: string;
}

export interface GeneralPartnerUserUpdatedDueDiligenceFilePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  fileDirectoryPath: string;
}

export interface GeneralPartnerUserUploadedDueDiligenceFileWithPathPayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  directoryName: string;
  fileDirectoryPath: string;
}

export interface GeneralPartnerUserUpdatedDueDiligenceDirectoryNamePayload {
  productId: string;
  productName: TranslatedText;
  previousDirectoryName: string;
  newDirectoryName: string;
  directoryPath: string;
}

export interface GeneralPartnerUserDownloadedDueDiligenceDirectoryPayload {
  productId: string;
  productName: TranslatedText;
  directoryName: string;
  directoryPath: string;
}

export interface GeneralPartnerUserDownloadedDueDiligenceFilePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  fileDirectoryPath: string;
}

export interface GeneralPartnerUserDeletedDueDiligenceDirectoryPayload {
  productId: string;
  productName: TranslatedText;
  directoryName: string;
  directoryPath: string;
}

export interface GeneralPartnerUserDeletedDueDiligenceFilePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  fileDirectoryPath: string;
}

export interface GeneralPartnerUserPinnedDueDiligenceFilePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  language: Language;
}

export interface GeneralPartnerUserUnpinnedDueDiligenceFilePayload {
  productId: string;
  productName: TranslatedText;
  fileName: string;
  language: Language;
}

export interface GeneralPartnerUserChangedDueDiligenceFilesOrderPayload {
  productId: string;
  productName: TranslatedText;
}

export interface GeneralPartnerUserImpersonatedInvestorUserPayload {
  investorUserId: string;
  name: Name;
}

export interface ProductReportdocument {
  fileName: string,
  language: Language,
}

export interface GeneralPartnerUserCreatedProductReportPayload {
  productId: string;
  productName: TranslatedText;
  productReportDocuments: ProductReportdocument[],
  productReportType: ProductReportType,
  productReportYear: Decimal,
  productReportQuarter?: Decimal,
  productReportMonth?: Decimal,
}

export interface GeneralPartnerUserUpdatedProductReportPayload {
  productId: string;
  productName: TranslatedText;
  productReportDocuments: ProductReportdocument[],
  productReportType: ProductReportType,
  productReportYear: Decimal,
  productReportQuarter?: Decimal,
  productReportMonth?: Decimal,
}

export interface GeneralPartnerUserDownloadedProductReportPayload {
  productId: string;
  productName: TranslatedText;
  productReportType: ProductReportType,
  productReportYear: Decimal,
  productReportQuarter?: Decimal,
  productReportMonth?: Decimal,
}

export interface GeneralPartnerUserDeletedProductReportPayload {
  productId: string;
  productName: TranslatedText;
  productReportType: ProductReportType,
  productReportYear: Decimal,
  productReportQuarter?: Decimal,
  productReportMonth?: Decimal,
}

export interface InvestorUserDownloadedDataRoomPayload {
  productId: string;
  productName: TranslatedText;
}

export interface InvestorUserDownloadedDirectoryInDataRoomPayload {
  directoryId: string;
  directoryName: string;
  folderPath?: string;
  productId: string;
  productName: TranslatedText;
}

export interface InvestorUserAcceptedProductNDAPayload {
  productId: string;
  productName: TranslatedText;
}

export interface InvestorUserDownloadedFileInDataRoomPayload {
  fileId: string;
  fileName: string;
  folderPath?: string;
  productId: string;
  productName: TranslatedText;
}

export interface InvestorUserAcceptedInvitationPayload {
  investorUserId: string;
  investorUserName: Name;
}

export interface UserUpdatedOwnNamePayload {
  previousSalutation: Salutation;
  newSalutation: Salutation;
  previousName: Name;
  newName: Name;
}

export interface UserRequestedNewEmailAddressPayload {
  previousEmailAddress: string;
  newEmailAddress: string;
}

export interface UserConfirmedNewEmailAddressPayload {
  newEmailAddress: string;
}

export interface UserUpdatedOwnPhoneNumberPayload {
  previousPhoneNumber: PhoneNumber;
  newPhoneNumber: PhoneNumber;
}

export interface UserUpdatedOwnCountryOfResidencePayload {
  previousCountryOfResidence: string;
  newCountryOfResidence: string;
}

export interface UserUpdatedOwnLanguagePayload {
  previousLanguage: Language;
  newLanguage: Language;
}

export function hasGeneralPartnerUserLoggedInPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserLoggedInPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_LOGGED_IN;
}

export function hasGeneralPartnerUserAcceptedInvitationPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserAcceptedInvitationPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_ACCEPTED_INVITATION;
}

export function hasGeneralPartnerUserInvitedGeneralPartnerUserPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserInvitedInvestorUserPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_INVITED_GENERAL_PARTNER_USER;
}

export function hasGeneralPartnerUserInvitedInvestorUserPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserInvitedInvestorUserPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_INVITED_INVESTOR_USER;
}

export function hasGeneralPartnerUserEnabledProductInvestabilityPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserEnabledProductInvestabilityPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_ENABLED_PRODUCT_INVESTABILITY;
}

export function hasGeneralPartnerUserDisabledProductInvestabilityPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDisabledProductInvestabilityPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DISABLED_PRODUCT_INVESTABILITY;
}

export function hasGeneralPartnerUserCreatedVimeoVideoPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedVimeoVideoPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_VIMEO_VIDEO;
}

export function hasGeneralPartnerUserDeletedProductVideoPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedProductVideoPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT_VIDEO;
}

export function hasGeneralPartnerUserUpdatedProductContactEmailAddressPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductContactEmailAddressPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_CONTACT_EMAIL_ADDRESS;
}

export function hasGeneralPartnerUserPublishedProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserPublishedProductPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_PUBLISHED_PRODUCT;
}

export function hasGeneralPartnerUserUnpublishedProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUnpublishedProductPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UNPUBLISHED_PRODUCT;
}

export function hasGeneralPartnerUserSharedProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserSharedProductPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_SHARED_PRODUCT;
}

export function hasGeneralPartnerUserUnsharedProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUnsharedProductPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UNSHARED_PRODUCT;
}

export function hasGeneralPartnerUserDeletedProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedProductPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT;
}

export function hasGeneralPartnerUserUpdatedGeneralPartnerUsersWithAccessForProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
    type: UserActivityLogEntryType,
    payload: GeneralPartnerUserUpdatedGeneralPartnerUsersWithAccessForProductPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_GENERAL_PARTNER_USERS_WITH_ACCESS_FOR_PRODUCT;
}

export function hasGeneralPartnerUserUpdatedInvestorsWithAccessForProductPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedInvestorsWithAccessForProductPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_INVESTORS_WITH_ACCESS_FOR_PRODUCT;
}

export function hasGeneralPartnerUserUpdatedProductPreviewPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductPreviewPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_PREVIEW;
}

export function hasGeneralPartnerUserUpdatedProductNamePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductNamePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_NAME;
}

export function hasGeneralPartnerUserUpdatedProductDescriptionPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductDescriptionPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_DESCRIPTION;
}

export function hasGeneralPartnerUserAddedProductSummaryItemPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserAddedProductSummaryItemPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_ADDED_PRODUCT_SUMMARY_ITEM;
}

export function hasGeneralPartnerUserRemovedProductSummaryItemPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserRemovedProductSummaryItemPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_REMOVED_PRODUCT_SUMMARY_ITEM;
}

export function hasGeneralPartnerUserUpdatedProductImagePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductImagePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_IMAGE;
}

export function hasGeneralPartnerUserAddedProductManagerPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserAddedProductManagerPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_ADDED_PRODUCT_MANAGER;
}

export function hasGeneralPartnerUserUpdatedProductManagerPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductManagerPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGER;
}

export function hasGeneralPartnerUserDeletedProductManagerPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedProductManagerPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT_MANAGER;
}

export function hasGeneralPartnerUserUpdatedProductManagersOrderPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductManagersOrderPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_MANAGERS_ORDER;
}

export function hasGeneralPartnerUserUpdatedTrackRecordImagePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedTrackRecordImagePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_TRACK_RECORD_IMAGE;
}

export function hasGeneralPartnerUserDeletedTrackRecordImagePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedTrackRecordImagePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_TRACK_RECORD_IMAGE;
}

export function hasGeneralPartnerUserCreatedProductNewsArticlePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedProductNewsArticlePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE;
}

export function hasGeneralPartnerUserUpdatedProductNewsArticlePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductNewsArticlePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_NEWS_ARTICLE;
}

export function hasGeneralPartnerUserDeletedProductNewsArticlePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedProductNewsArticlePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE;
}

export function hasGeneralPartnerUserCreatedProductNewsArticleAttachmentPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedProductNewsArticleAttachmentPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_PRODUCT_NEWS_ARTICLE_ATTACHMENT;
}

export function hasGeneralPartnerUserDeletedProductNewsArticleAttachmentPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedProductNewsArticleAttachmentPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT_NEWS_ARTICLE_ATTACHMENT;
}

export function hasGeneralPartnerUserDownloadedProductNewsArticleAttachmentPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDownloadedProductNewsArticleAttachmentPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_NEWS_ARTICLE_ATTACHMENT;
}

export function hasGeneralPartnerUserCreatedProductReportPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedProductReportPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_PRODUCT_REPORT;
}

export function hasGeneralPartnerUserUpdatedProductReportPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedProductReportPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_REPORT;
}

export function hasGeneralPartnerUserDownloadedProductReportPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDownloadedProductReportPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DOWNLOADED_PRODUCT_REPORT;
}

export function hasGeneralPartnerUserDeletedProductReportPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedProductReportPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT_REPORT;
}

export function hasGeneralPartnerUserEnabledDocumentManagementForSubscriptionProcessPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
  type: UserActivityLogEntryType,
  payload: GeneralPartnerUserEnabledDocumentManagementForSubscriptionProcessPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_ENABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS;
}

export function hasGeneralPartnerUserDisabledDocumentManagementForSubscriptionProcessPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
  type: UserActivityLogEntryType,
  payload: GeneralPartnerUserDisabledDocumentManagementForSubscriptionProcessPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DISABLED_DOCUMENT_MANAGEMENT_FOR_SUBSCRIPTION_PROCESS;
}

export function hasGeneralPartnerUserCreatedSubscriptionDocumentTemplatePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedSubscriptionDocumentTemplatePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_DOCUMENT_TEMPLATE;
}

export function hasGeneralPartnerUserDeletedSubscriptionDocumentTemplatePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedSubscriptionDocumentTemplatePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_DOCUMENT_TEMPLATE;
}

export function hasGeneralPartnerUserCreatedSubscriptionAgreementTemplateToPrefillPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
  type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedSubscriptionAgreementTemplateToPrefillPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL;
}

export function hasGeneralPartnerUserDeletedSubscriptionAgreementTemplateToPrefillPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
  type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedSubscriptionAgreementTemplateToPrefillPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_SUBSCRIPTION_AGREEMENT_TEMPLATE_TO_PREFILL;
}

export function hasGeneralPartnerUserCreatedControllingPersonTemplateToPrefillPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
  type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedControllingPersonTemplateToPrefillPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL;
}

export function hasGeneralPartnerUserDeletedControllingPersonTemplateToPrefillPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is {
  type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedControllingPersonTemplateToPrefillPayload
} {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_CONTROLLING_PERSON_TEMPLATE_TO_PREFILL;
}

export function hasGeneralPartnerUserUpdatedSubscriptionInstructionPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedSubscriptionInstructionPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_SUBSCRIPTION_INSTRUCTION;
}

export function hasGeneralPartnerUserUpdatedKYCInstructionPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedKYCInstructionPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_KYC_INSTRUCTION;
}

export function hasGeneralPartnerUserCreatedProductTermSheetCategoryPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload:GeneralPartnerUserCreatedProductTermSheetCategoryPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_PRODUCT_TERM_SHEET_CATEGORY;
}

export function hasGeneralPartnerUserUpdatedProductTermSheetCategoryPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload:GeneralPartnerUserUpdatedProductTermSheetCategoryPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_PRODUCT_TERM_SHEET_CATEGORY;
}

export function hasGeneralPartnerUserDeletedProductTermSheetCategoryPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload:GeneralPartnerUserDeletedProductTermSheetCategoryPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_PRODUCT_TERM_SHEET_CATEGORY;
}

export function hasGeneralPartnerUserCreatedDueDiligenceDirectoryPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedDueDiligenceDirectoryPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_DIRECTORY;
}

export function hasGeneralPartnerUserCreatedDueDiligenceFilePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserCreatedDueDiligenceFilePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CREATED_DUE_DILIGENCE_FILE;
}

export function hasGeneralPartnerUserUpdatedDueDiligenceFilePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedDueDiligenceFilePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_FILE;
}

export function hasGeneralPartnerUserUploadedDueDiligenceFileWithPathPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUploadedDueDiligenceFileWithPathPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPLOADED_DUE_DILIGENCE_FILE_WITH_PATH;
}

export function hasGeneralPartnerUserUpdatedDueDiligenceDirectoryNamePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUpdatedDueDiligenceDirectoryNamePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UPDATED_DUE_DILIGENCE_DIRECTORY_NAME;
}

export function hasGeneralPartnerUserDownloadedDueDiligenceDirectoryPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDownloadedDueDiligenceDirectoryPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_DIRECTORY;
}

export function hasGeneralPartnerUserDownloadedDueDiligenceFilePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDownloadedDueDiligenceFilePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DOWNLOADED_DUE_DILIGENCE_FILE;
}

export function hasGeneralPartnerUserDeletedDueDiligenceDirectoryPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedDueDiligenceDirectoryPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_DIRECTORY;
}

export function hasGeneralPartnerUserDeletedDueDiligenceFilePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserDeletedDueDiligenceFilePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_DELETED_DUE_DILIGENCE_FILE;
}

export function hasGeneralPartnerUserPinnedDueDiligenceFilePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserPinnedDueDiligenceFilePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_PINNED_DUE_DILIGENCE_FILE;
}

export function hasGeneralPartnerUserUnpinnedDueDiligenceFilePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserUnpinnedDueDiligenceFilePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_UNPINNED_DUE_DILIGENCE_FILE;
}

export function hasGeneralPartnerUserChangedDueDiligenceFilesOrderPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserChangedDueDiligenceFilesOrderPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_CHANGED_DUE_DILIGENCE_FILES_ORDER;
}

export function hasGeneralPartnerUserImpersonatedInvestorUserPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: GeneralPartnerUserImpersonatedInvestorUserPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.GENERAL_PARTNER_USER_IMPERSONATED_INVESTOR_USER;
}

export function hasInvestorUserLoggedInPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: InvestorUserLoggedInPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.INVESTOR_USER_LOGGED_IN;
}

export function hasInvestorUserDownloadedDataRoomPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: InvestorUserDownloadedDataRoomPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.INVESTOR_USER_DOWNLOADED_DATA_ROOM;
}

export function hasInvestorUserDownloadedFileInDataRoomPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: InvestorUserDownloadedFileInDataRoomPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.INVESTOR_USER_DOWNLOADED_FILE_IN_DATA_ROOM;
}

export function hasInvestorUserDownloadedDirectoryInDataRoomPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: InvestorUserDownloadedDirectoryInDataRoomPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.INVESTOR_USER_DOWNLOADED_DIRECTORY_IN_DATA_ROOM;
}
export function hasInvestorUserAcceptedProductNDAPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: InvestorUserAcceptedProductNDAPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.INVESTOR_USER_ACCEPTED_PRODUCT_NDA;
}

export function hasInvestorUserAcceptedInvitationPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: InvestorUserAcceptedInvitationPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.INVESTOR_USER_ACCEPTED_INVITATION;
}

export function hasUserUpdatedOwnNamePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: UserUpdatedOwnNamePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.USER_UPDATED_OWN_NAME;
}

export function hasUserRequestedNewEmailAddressPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: UserRequestedNewEmailAddressPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.USER_REQUESTED_NEW_EMAIL_ADDRESS;
}

export function hasUserConfirmedNewEmailAddressPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: UserConfirmedNewEmailAddressPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.USER_CONFIRMED_NEW_EMAIL_ADDRESS;
}

export function hasUserUpdatedOwnPhoneNumberPayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: UserUpdatedOwnPhoneNumberPayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.USER_UPDATED_OWN_PHONE_NUMBER;
}

export function hasUserUpdatedOwnCountryOfResidencePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: UserUpdatedOwnCountryOfResidencePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.USER_UPDATED_OWN_COUNTRY_OF_RESIDENCE;
}

export function hasUserUpdatedOwnLanguagePayload(
  userActivityLogEntry: { type: UserActivityLogEntryType, payload?: UserActivityLogEntryPayload }
): userActivityLogEntry is { type: UserActivityLogEntryType, payload: UserUpdatedOwnLanguagePayload } {
  return userActivityLogEntry.type === UserActivityLogEntryTypes.USER_UPDATED_OWN_LANGUAGE;
}
