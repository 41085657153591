import { defineStore, storeToRefs } from 'pinia';
import { Dayjs } from 'dayjs';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, VuetifySelectItem } from '@/application/types';
import { AccountTypes, Languages } from '@/types/global';
import { getEarliestDate } from '@/helpers/date-helpers';
import { useAppStore } from '@/application/common/app/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useImpersonationHintStore } from '@/application/whitelabel/app/components/impersonation-hint/store';
import { CreateInvestmentForInvestorCommand, CreateInvestorContactCommand, DeleteInvestmentForInvestorCommand, DeleteInvestorContactCommand, DeleteUserCommand, GetAccessiblePublishedProductsForInvestorQuery, GetInvestmentsForInvestorQuery, GetInvestorActivityQuery, GetInvestorContactsQuery, GetInvestorDetailsQuery, GetInvestorUserAuthenticationQuery, GetSubscriptionProcessesForInvestorQuery, GetUsersForInvestorQuery, Investment, InvestorContact, InvestorDetails, LockUserCommand, Product, ResendUserInviteCommand, StartSubscriptionProcessForInvestorCommand, SubscriptionProcess, UnlockUserCommand, UpdateInvestmentForInvestorCommand, UpdateInvestorContactCommand, UpdateInvestorUserMasterDataAndManagementAccessCommand, User, UserActivityLogEntry, WithdrawUserInviteCommand } from './types';
import { createInvestmentForInvestor, createInvestorContact, deleteInvestmentForInvestor, deleteInvestorContact, deleteUser, getInvestmentsForInvestor, getInvestorActivity, getInvestorContacts, getInvestorDetails, getInvestorUserAuthentication, getSubscriptionProcessesForInvestor, getUsersForInvestor, lockUser, resendUserInvite, startSubscriptionProcessForInvestor, unlockUser, updateInvestmentForInvestor, updateInvestorContact, updateInvestorUserMasterDataAndManagementAccess, withdrawUserInvite, getAccessiblePublishedProductsForInvestor } from './service';

interface GeneralPartnerInvestorDetailsState {
  investorId: string | null;
  investorDetails: InvestorDetails | null;
  investments: Investment[];
  subscriptionProcesses: SubscriptionProcess[];
  usersForInvestor: User[];
  investorContacts: InvestorContact[];
  investorActivityLogEntries: UserActivityLogEntry[];
  investorActivityLogEntriesTotalCount: number;
  accessiblePublishedProductsForInvestor: Product[];

  resendUserInviteStatus: ActionStatus;
  withdrawUserInviteStatus: ActionStatus;
  unlockUserStatus: ActionStatus;
  impersonateInvestorUserStatus: ActionStatus;
  lockUserStatus: ActionStatus;
  deleteUserStatus: ActionStatus;
  updateInvestorUserMasterDataAndManagementAccessStatus: ActionStatus;
  getUsersForInvestorStatus: ActionStatus;
  getSubscriptionProcessesForInvestorStatus: ActionStatus;
  getInvestorDetailsStatus: ActionStatus;
  getInvestmentsForInvestorStatus: ActionStatus;
  createInvestmentForInvestorStatus: ActionStatus;
  updateInvestmentForInvestorStatus: ActionStatus;
  deleteInvestmentForInvestorStatus: ActionStatus;
  getInvestorContactsStatus: ActionStatus;
  createInvestorContactStatus: ActionStatus;
  updateInvestorContactStatus: ActionStatus;
  deleteInvestorContactStatus: ActionStatus;
  startSubscriptionProcessForInvestorStatus: ActionStatus;
  getInvestorActivityStatus: ActionStatus;
  getAccessiblePublishedProductsForInvestorStatus: ActionStatus;
}

function initialState(): GeneralPartnerInvestorDetailsState {
  return {
    investorId: null,
    investorDetails: null,
    investments: [],
    subscriptionProcesses: [],
    usersForInvestor: [],
    investorContacts: [],
    investorActivityLogEntries: [],
    investorActivityLogEntriesTotalCount: 0,
    accessiblePublishedProductsForInvestor: [],

    resendUserInviteStatus: ActionStatus.None,
    withdrawUserInviteStatus: ActionStatus.None,
    unlockUserStatus: ActionStatus.None,
    impersonateInvestorUserStatus: ActionStatus.None,
    lockUserStatus: ActionStatus.None,
    deleteUserStatus: ActionStatus.None,
    updateInvestorUserMasterDataAndManagementAccessStatus: ActionStatus.None,
    getUsersForInvestorStatus: ActionStatus.None,
    getSubscriptionProcessesForInvestorStatus: ActionStatus.None,
    getInvestorDetailsStatus: ActionStatus.None,
    getInvestmentsForInvestorStatus: ActionStatus.None,
    createInvestmentForInvestorStatus: ActionStatus.None,
    updateInvestmentForInvestorStatus: ActionStatus.None,
    deleteInvestmentForInvestorStatus: ActionStatus.None,
    getInvestorContactsStatus: ActionStatus.None,
    createInvestorContactStatus: ActionStatus.None,
    updateInvestorContactStatus: ActionStatus.None,
    deleteInvestorContactStatus: ActionStatus.None,
    startSubscriptionProcessForInvestorStatus: ActionStatus.None,
    getInvestorActivityStatus: ActionStatus.None,
    getAccessiblePublishedProductsForInvestorStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerInvestorDetailsStore = defineStore(
  'generalPartnerInvestorDetails',
  {
    state: (): GeneralPartnerInvestorDetailsState => initialState(),
    getters: {
      isResendUserInviteProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.resendUserInviteStatus === ActionStatus.InProgress,
      isWithdrawUserInviteProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.withdrawUserInviteStatus === ActionStatus.InProgress,
      isUnlockUserProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.unlockUserStatus === ActionStatus.InProgress,
      isImpersonateInvestorUserProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.impersonateInvestorUserStatus === ActionStatus.InProgress,
      isLockUserProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.lockUserStatus === ActionStatus.InProgress,
      isDeleteUserProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.deleteUserStatus === ActionStatus.InProgress,
      isUpdateInvestorUserMasterDataAndManagementAccessProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.updateInvestorUserMasterDataAndManagementAccessStatus === ActionStatus.InProgress,
      isGetUsersForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getUsersForInvestorStatus === ActionStatus.InProgress,
      isGetSubscriptionProcessesForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getSubscriptionProcessesForInvestorStatus === ActionStatus.InProgress,
      isGetInvestorDetailsProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getInvestorDetailsStatus === ActionStatus.InProgress,
      isGetInvestmentsForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getInvestmentsForInvestorStatus === ActionStatus.InProgress,
      isCreateInvestmentForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.createInvestmentForInvestorStatus === ActionStatus.InProgress,
      isUpdateInvestmentForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.updateInvestmentForInvestorStatus === ActionStatus.InProgress,
      isDeleteInvestmentForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.deleteInvestmentForInvestorStatus === ActionStatus.InProgress,
      isGetInvestorContactsProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getInvestorContactsStatus === ActionStatus.InProgress,
      isCreateInvestorContactProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.createInvestorContactStatus === ActionStatus.InProgress,
      isUpdateInvestorContactProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.updateInvestorContactStatus === ActionStatus.InProgress,
      isDeleteInvestorContactProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.deleteInvestorContactStatus === ActionStatus.InProgress,
      isStartSubscriptionProcessForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.startSubscriptionProcessForInvestorStatus === ActionStatus.InProgress,
      isGetInvestorActivityProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getInvestorActivityStatus === ActionStatus.InProgress,
      isGetAccessiblePublishedProductsForInvestorProcessing: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.getAccessiblePublishedProductsForInvestorStatus === ActionStatus.InProgress,

      publishedProductsWithoutSubscriptionProcess: (state: GeneralPartnerInvestorDetailsState): VuetifySelectItem<string>[] => {
        // eslint-disable-next-line arrow-body-style
        const publishedProductsWithoutSubscriptionProcess = state.accessiblePublishedProductsForInvestor
          // eslint-disable-next-line max-len
          .filter((publishedProduct: Product) => !state.subscriptionProcesses.some((subscriptionProcess: SubscriptionProcess) => subscriptionProcess.productId === publishedProduct.productId));

        return publishedProductsWithoutSubscriptionProcess.map((publishedProduct: Product) => ({
          text: publishedProduct.productName,
          value: publishedProduct.productId,
        }));
      },
      hasCommittedCapital: (state: GeneralPartnerInvestorDetailsState): boolean =>
        !!state.investorDetails
        && state.investorDetails.committedCapital.length > 0,
      hasInvestments: (state: GeneralPartnerInvestorDetailsState): boolean =>
        state.investments.length > 0,
      investedSince: (state: GeneralPartnerInvestorDetailsState): Dayjs => {
        const investmentClosingDates = state.investments
          .map((investment) => investment.closingDate);

        return getEarliestDate(investmentClosingDates);
      },
      publishedProductItems: (state: GeneralPartnerInvestorDetailsState): VuetifySelectItem<string>[] =>
        state.accessiblePublishedProductsForInvestor.map((publishedProduct: Product) => ({
          text: publishedProduct.productName,
          value: publishedProduct.productId,
        })),
    },
    actions: {

      // -- State management

      updateCurrentInvestorId(currentInvestorId: string): Promise<void> {
        this.investorId = currentInvestorId;

        return Promise.resolve();
      },

      // -- Queries

      impersonateInvestorUser(targetUserId: string): Promise<void> {
        const query: GetInvestorUserAuthenticationQuery = {
          targetUserId,
        };

        const { impersonateInvestorUserStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          impersonateInvestorUserStatus,
          () => getInvestorUserAuthentication(query)
            .then(async (authentication) => {
              await useAuthenticationStore().impersonateUser(authentication);
              await useAppStore().updateAccountType(AccountTypes.INVESTOR);
              useAppStore().updateLanguage(authentication.user.language ?? Languages.en);
            })
            .then(() => useImpersonationHintStore().activateHint())
        );
      },

      getUsersForInvestor(): Promise<void> {
        const query: GetUsersForInvestorQuery = {
          investorId: this.investorId!,
        };

        const { getUsersForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getUsersForInvestorStatus,
          () => getUsersForInvestor(query)
            .then((users) => {
              this.usersForInvestor = users;
            })
        );
      },

      getInvestorDetails(): Promise<void> {
        const query: GetInvestorDetailsQuery = {
          investorId: this.investorId!,
        };

        const { getInvestorDetailsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestorDetailsStatus,
          () => getInvestorDetails(query)
            .then((investorDetails) => {
              this.investorDetails = investorDetails;
            })
        );
      },

      getSubscriptionProcessesForInvestor(): Promise<void> {
        const query: GetSubscriptionProcessesForInvestorQuery = {
          investorId: this.investorId!,
        };

        const { getSubscriptionProcessesForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getSubscriptionProcessesForInvestorStatus,
          () => getSubscriptionProcessesForInvestor(query)
            .then((subscriptionProcesses) => {
              this.subscriptionProcesses = subscriptionProcesses;
            })
        );
      },

      getInvestmentsForInvestor(): Promise<void> {
        const query: GetInvestmentsForInvestorQuery = {
          investorId: this.investorId!,
        };

        const { getInvestmentsForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestmentsForInvestorStatus,
          () => getInvestmentsForInvestor(query)
            .then((investments) => {
              this.investments = investments;
            })
        );
      },

      getInvestorContacts(): Promise<void> {
        const query: GetInvestorContactsQuery = {
          investorId: this.investorId!,
        };

        const { getInvestorContactsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestorContactsStatus,
          () => getInvestorContacts(query)
            .then((contacts) => {
              this.investorContacts = contacts;
            })
        );
      },

      getInvestorActivity(query: GetInvestorActivityQuery): Promise<void> {
        const { getInvestorActivityStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestorActivityStatus,
          () => getInvestorActivity(query)
            .then((investorActivityLogEntries) => {
              this.investorActivityLogEntries = investorActivityLogEntries.entries;
              this.investorActivityLogEntriesTotalCount = investorActivityLogEntries.totalCount;
            })
        );
      },

      getAccessiblePublishedProductsForInvestor(): Promise<void> {
        const query: GetAccessiblePublishedProductsForInvestorQuery = {
          investorId: this.investorId!,
        };

        const { getAccessiblePublishedProductsForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getAccessiblePublishedProductsForInvestorStatus,
          () => getAccessiblePublishedProductsForInvestor(query)
            .then((accessiblePublishedProducts) => {
              this.accessiblePublishedProductsForInvestor = accessiblePublishedProducts;
            })
        );
      },

      // -- Commands

      updateInvestorUserMasterDataAndManagementAccess(command: UpdateInvestorUserMasterDataAndManagementAccessCommand): Promise<void> {
        const { updateInvestorUserMasterDataAndManagementAccessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          updateInvestorUserMasterDataAndManagementAccessStatus,
          () => updateInvestorUserMasterDataAndManagementAccess(command)
            .then(() => this.getUsersForInvestor())
        );
      },

      deleteUser(command: DeleteUserCommand): Promise<void> {
        const { deleteUserStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteUserStatus,
          () => deleteUser(command)
            .then(() => this.getUsersForInvestor())
        );
      },

      lockUser(command: LockUserCommand): Promise<void> {
        const { lockUserStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          lockUserStatus,
          () => lockUser(command)
            .then(() => this.getUsersForInvestor())
        );
      },

      unlockUser(command: UnlockUserCommand): Promise<void> {
        const { unlockUserStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          unlockUserStatus,
          () => unlockUser(command)
            .then(() => this.getUsersForInvestor())
        );
      },

      resendUserInvite(command: ResendUserInviteCommand): Promise<void> {
        const { resendUserInviteStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          resendUserInviteStatus,
          () => resendUserInvite(command)
        );
      },

      withdrawUserInvite(command: WithdrawUserInviteCommand): Promise<void> {
        const { withdrawUserInviteStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          withdrawUserInviteStatus,
          () => withdrawUserInvite(command)
            .then(() => this.getUsersForInvestor())
        );
      },

      createInvestmentForInvestor(command: CreateInvestmentForInvestorCommand): Promise<void> {
        const { createInvestmentForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createInvestmentForInvestorStatus,
          () => createInvestmentForInvestor(command)
            .then(() => this.getInvestorDetails())
            .then(() => this.getInvestmentsForInvestor())
        );
      },

      updateInvestmentForInvestor(command: UpdateInvestmentForInvestorCommand): Promise<void> {
        const { updateInvestmentForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          updateInvestmentForInvestorStatus,
          () => updateInvestmentForInvestor(command)
            .then(() => this.getInvestorDetails())
            .then(() => this.getInvestmentsForInvestor())
        );
      },

      deleteInvestmentForInvestor(command: DeleteInvestmentForInvestorCommand): Promise<void> {
        const { deleteInvestmentForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteInvestmentForInvestorStatus,
          () => deleteInvestmentForInvestor(command)
            .then(() => this.getInvestorDetails())
            .then(() => this.getInvestmentsForInvestor())
        );
      },

      createInvestorContact(command: CreateInvestorContactCommand): Promise<void> {
        const { createInvestorContactStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createInvestorContactStatus,
          () => createInvestorContact(command)
            .then(() => this.getInvestorContacts())
        );
      },

      updateInvestorContact(command: UpdateInvestorContactCommand): Promise<void> {
        const { updateInvestorContactStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          updateInvestorContactStatus,
          () => updateInvestorContact(command)
            .then(() => this.getInvestorContacts())
        );
      },

      deleteInvestorContact(command: DeleteInvestorContactCommand): Promise<void> {
        const { deleteInvestorContactStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteInvestorContactStatus,
          () => deleteInvestorContact(command)
            .then(() => this.getInvestorContacts())
        );
      },

      startSubscriptionProcessForInvestor(command: StartSubscriptionProcessForInvestorCommand): Promise<void[]> {
        const { startSubscriptionProcessForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          startSubscriptionProcessForInvestorStatus,
          () => startSubscriptionProcessForInvestor(command)
            .then(() => Promise.all([
              this.getInvestorDetails(),
              this.getSubscriptionProcessesForInvestor(),
            ]))
        );
      },

    },
  }
);
