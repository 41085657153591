import { RouteConfig } from 'vue-router';
import SubscriptionDocumentTemplateOverview from './components/subscription-document-template-overview.vue';

export const subscriptionDocumentTemplatesRoutes: RouteConfig[] = [
  {
    name: 'subscription-document-templates-overview',
    path: '/subscription-document-templates/overview',
    component: SubscriptionDocumentTemplateOverview,
    meta: {
      requiresAuth: true,
    },
  },
];
