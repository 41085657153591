import { generateApiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { AddProductManagerCommand, AddProductSummaryItemCommand, CreateVimeoVideoCommand, DeleteProductManagerCommand, DeleteProductPresentationCommand, DeleteProductTrackRecordImageCommand, DeleteProductVideoCommand, RemoveProductSummaryItemCommand, UpdateProductContactEmailAddressCommand, UpdateProductDescriptionCommand, UpdateProductImageCommand, UpdateProductManagerCommand, UpdateProductManagersOrderCommand, UpdateProductPresentationCommand, UpdateProductTrackRecordImageCommand, UpdateProductVideoCommand, UploadVimeoVideoChunkCommand } from './types';

export function updateProductImage(command: UpdateProductImageCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-image-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function updateProductDescription(command: UpdateProductDescriptionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-description-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function addProductSummaryItem(command: AddProductSummaryItemCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/add-product-summary-item-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function removeProductSummaryItem(command: RemoveProductSummaryItemCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/remove-product-summary-item-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function addProductManager(command: AddProductManagerCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/add-product-manager-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function deleteProductManager(command: DeleteProductManagerCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-manager-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductManagersOrder(command: UpdateProductManagersOrderCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-managers-order-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductManager(command: UpdateProductManagerCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-manager-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function updateProductTrackRecordImage(command: UpdateProductTrackRecordImageCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-track-record-image-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}
export function deleteProductTrackRecordImage(command: DeleteProductTrackRecordImageCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-track-record-image-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteProductPresentation(command: DeleteProductPresentationCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-presentation-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductPresentation(command: UpdateProductPresentationCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-presentation-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function createVimeoVideo(command: CreateVimeoVideoCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-vimeo-video-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductVideo(command: UpdateProductVideoCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-video-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function uploadVimeoVideoChunk(
  command: UploadVimeoVideoChunkCommand,
  onUploadProgress: (progressEvent: any, chunkOrder: number) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/upload-vimeo-video-chunk-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, command.body.chunkOrder),
  });
}

export function deleteProductVideo(command: DeleteProductVideoCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-video-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductContactEmailAddress(command: UpdateProductContactEmailAddressCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-contact-email-address-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
