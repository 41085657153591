import i18n from '@/plugins/vue-i18n';
import { FormControl, FormControlRule, FormControlValue } from './types';

export function requiredRule(message?: string): FormControlRule<FormControlValue<unknown>> {
  return (value) => (
    value !== null
  ) ? true
    : message
      ?? i18n.t('form.rules.requiredRule') as string;
}

export function minLengthRule(min: number, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
      || value.length >= min
  ) ? true
    : message
      ?? i18n.t('form.rules.minLengthRule', { min }) as string;
}

export function maxLengthRule(max: number, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
      || value.length <= max
  ) ? true
    : message
      ?? i18n.t('form.rules.maxLengthRule', { max }) as string;
}

export function identicalStringFormControlRule(
  formControl: FormControl<string>,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === formControl.value
  ) ? true
    : message
      ?? i18n.t('form.rules.identicalStringFormControlRule', { formControlLabel: formControl.label }) as string;
}
