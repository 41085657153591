import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { Deferred } from '@/helpers/deferred';
import { AddChunkedItemToUploadQueuePayload, AddItemToUploadQueuePayload, UploadQueueItem, UploadStatus } from './types';

interface FileUploadQueueState {
  queueItems: UploadQueueItem[];
}

function initialState(): FileUploadQueueState {
  return {
    queueItems: [],
  };
}

export const useFileUploadQueueStore = defineStore('fileUploadQueue', {
  state: (): FileUploadQueueState => initialState(),
  getters: {
    hasFilesInQueue: (state: FileUploadQueueState): boolean =>
      state.queueItems.length > 0,
    queueItemsWithoutFailed: (state: FileUploadQueueState): UploadQueueItem[] =>
      state.queueItems
        .filter((queueItem) => queueItem.status !== UploadStatus.Failed),
  },
  actions: {

    // -- State management

    addItem(payload: AddItemToUploadQueuePayload): Promise<void> {
      const queueItemId = uuidv4();
      const deferred = new Deferred<void>();
      const queueItem: UploadQueueItem = {
        id: queueItemId,
        fileName: payload.fileName,
        status: UploadStatus.Waiting,
        uploadFile: () => {
          for (const queueItem of this.queueItems) {
            if (queueItem.id === queueItemId) {
              queueItem.status = UploadStatus.Uploading;
              break;
            }
          }

          const onUploadProgress = (progressEvent: any) => {
            const uploadProgressInPercent = (progressEvent.loaded * 100) / progressEvent.total;

            for (const queueItem of this.queueItems) {
              if (queueItem.id === queueItemId) {
                this.queueItems = this.queueItems.map((mappedQueueItem) => {
                  if (mappedQueueItem.id === queueItem.id) {
                    return {
                      ...mappedQueueItem,
                      uploadProgressInPercent,
                    };
                  }

                  return mappedQueueItem;
                });
              }
            }
          };

          payload.serviceFunction(payload.command, onUploadProgress)
            .then(() => {
              this.queueItems = this.queueItems
                .filter((queueItem) => queueItem.id !== queueItemId);
              deferred.resolve();
            })
            .catch((error) => {
              for (const queueItem of this.queueItems) {
                if (queueItem.id === queueItemId) {
                  queueItem.status = UploadStatus.Failed;
                  break;
                }
              }
              deferred.reject(error);
            });
        },
      };

      this.queueItems.push(queueItem);

      return deferred.promise;
    },

    addChunkedItem(payload: AddChunkedItemToUploadQueuePayload): Promise<void> {
      const queueItemId = uuidv4();
      const deferred = new Deferred<void>();
      const queueItem: UploadQueueItem = {
        id: queueItemId,
        fileName: payload.fileName,
        status: UploadStatus.Waiting,
        uploadFile: async () => {
          for (const queueItem of this.queueItems) {
            if (queueItem.id === queueItemId) {
              queueItem.status = UploadStatus.Uploading;
              break;
            }
          }

          const onUploadProgress = (progressEvent: any, chunkOrder: number) => {
            const percentagePerChunk = 100 / payload.commands.length;
            const chunkProgress = (progressEvent.loaded * 100) / progressEvent.total;
            const uploadProgressInPercent = (chunkOrder * percentagePerChunk) + (percentagePerChunk * (chunkProgress / 100));

            for (const queueItem of this.queueItems) {
              if (queueItem.id === queueItemId) {
                this.queueItems = this.queueItems.map((mappedQueueItem) => {
                  if (mappedQueueItem.id === queueItem.id) {
                    return {
                      ...mappedQueueItem,
                      uploadProgressInPercent,
                    };
                  }

                  return mappedQueueItem;
                });
              }
            }
          };

          await payload.commands.reduce((promise: Promise<void>, command: any) => promise
            .then(() => payload.serviceFunction(command, onUploadProgress))
            .catch((error) => {
              for (const queueItem of this.queueItems) {
                if (queueItem.id === queueItemId) {
                  queueItem.status = UploadStatus.Failed;
                  break;
                }
              }
              deferred.reject(error);
            }), Promise.resolve());

          this.queueItems = this.queueItems
            .filter((queueItem) => queueItem.id !== queueItemId);
          deferred.resolve();
        },
      };

      this.queueItems.push(queueItem);

      return deferred.promise;
    },

    removeItem(queueItemId: string): Promise<void> {
      this.queueItems = this.queueItems
        .filter((queueItem) => queueItem.id !== queueItemId);

      return Promise.resolve();
    },

  },
});
