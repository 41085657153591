import { Dayjs } from 'dayjs';
import { Command, CommandWithFiles, Query } from '@/application/types';
import { Currency, FileReference, SubscriptionProcessStatus, TermSheet } from '@/types/global';
import { Decimal } from '@/models/decimal';

export enum SubscriptionProcessStep {
  AMOUNT_SPECIFICATION = 1,
  INVESTMENT_PROFILE_SELECTION = 2,
  INVESTMENT_PROFILE_REVIEW = 3,
  KYC_DOCUMENT_ATTACHMENTS = 4,
  SUBSCRIPTION_DOCUMENTS = 5,
  REVIEW = 6,
}

// -- State

export interface ProductForCurrentSubscriptionProcess {
  productId: string;
  productName: string;
  imageUrl?: string;
  idsOfKPIsForProductInfo: string[];
  idsOfKPIsForSummary: string[];
  subscriptionInstruction?: string;
  kycInstruction?: string;
  termSheet: TermSheet;
  currency: Currency;
}

export interface SubscriptionProcess {
  subscriptionProcessId: string;
  investmentAmount?: Decimal;
  status: SubscriptionProcessStatus;
  subscriptionAgreementTemplates?: SubscriptionAgreementTemplate[];
  isDocumentManagementForSubscriptionProcessEnabled: boolean;
  selectedInvestmentProfileId?: string;
  isInvestmentProfileReviewed: boolean;
  isNaturalPersonSelected: boolean;
}

export interface SubscriptionAgreementTemplate {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface SubscriptionDocument {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface SubscriptionDocumentTemplate {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface SubscriptionAgreementTemplateToPrefill {
  subscriptionAgreementTemplateToPrefillId: string;
  original: FileReference;
  createdAt: Dayjs;
}

export interface ControllingPersonTemplateToPrefill {
  controllingPersonTemplateToPrefillId: string;
  controllingPersonId: string;
  fullName: string;
  original: FileReference;
  createdAt: Dayjs;
}

export interface KYCDocument {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface KYCDocumentAttachment {
  id: string;
  kycDocumentId?: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

// -- Command

export interface StartSubscriptionProcessCommand extends Command {
  productId: string;
}

export interface SpecifySubscriptionInvestmentAmountCommand extends Command {
  subscriptionProcessId: string;
  investmentAmount: Decimal;
}

export interface SelectInvestmentProfileForSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
  investmentProfileId: string;
}

export interface ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
}

export interface AgreeToSubscriptionDataProcessingCommand extends Command {
  subscriptionProcessId: string;
}

export interface CreateSubscriptionDocumentCommand extends CommandWithFiles {
  body: {
    subscriptionProcessId: string;
  };
  files: {
    file: File;
  };
}

export interface ConfirmSubscriptionCommand extends Command {
  subscriptionProcessId: string;
}

export interface CopyInvestorKYCDocumentsToKYCDocumentAttachmentsCommand extends Command {
  subscriptionProcessId: string;
  investorKYCDocuments: string[];
}

export interface DeleteKYCDocumentAttachmentCommand extends Command {
  subscriptionProcessId: string;
  documentAttachmentId: string;
}

export interface DeleteSubscriptionDocumentCommand extends Command {
  subscriptionProcessId: string;
  documentId: string;
}

export interface ContinueSubscriptionProcessAsNaturalPersonCommand extends Query {
  subscriptionProcessId: string;
}

// -- Queries

export interface GetSubscriptionProcessForCurrentProductQuery extends Query {
  productId: string;
}

export interface GetSubscriptionDocumentFileForDownloadQuery extends Query {
  subscriptionDocumentId: string;
}

export interface GetSubscriptionAgreementTemplateFileForDownloadQuery extends Query {
  fileId: string;
}

export interface GetSubscriptionDocumentsForSubscriptionProcessQuery extends Query {
  subscriptionProcessId: string;
}

export interface GetProductForCurrentSubscriptionProcessQuery extends Query {
  productId: string;
}

export interface GetKYCDocumentAttachmentsForSubscriptionProcessQuery extends Query {
  subscriptionProcessId: string;
}

export interface GetKYCDocumentAttachmentFileForDownloadQuery extends Query {
  kycDocumentAttachmentId: string;
}

export interface GetSubscriptionAgreementTemplatesToPrefillForProductQuery extends Query {
  productId: string;
}

export interface GetControllingPersonTemplatesToPrefillForProductQuery extends Query {
  productId: string;
  investmentProfileId: string;
}

export interface GetPrefilledSubscriptionAgreementTemplateForDownloadQuery extends Query {
  subscriptionAgreementTemplateToPrefillId: string;
  subscriptionProcessId: string;
  investmentProfileId: string;
}

export interface GetPrefilledControllingPersonTemplateForDownloadQuery extends Query {
  controllingPersonTemplateToPrefillId: string;
  controllingPersonId: string;
  investmentProfileId: string;
}
