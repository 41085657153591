import { render, staticRenderFns } from "./confirm-onboarded-product-subscription.vue?vue&type=template&id=ad417d8e&"
import script from "./confirm-onboarded-product-subscription.vue?vue&type=script&lang=ts&"
export * from "./confirm-onboarded-product-subscription.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VDivider})
