import { RouteConfig } from 'vue-router';
import PlatformNews from './components/platform-news.vue';

export const platformNewsRoutes: RouteConfig[] = [
  {
    name: 'platform-news',
    path: '/platform-news',
    component: PlatformNews,
    meta: {
      requiresAuth: true,
    },
  },
];
