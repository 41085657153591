import { Command } from '@/application/types';
import { Name, PhoneNumber, Salutation } from '@/types/global';

// -- State

export interface Profile {
  emailAddress: string;
  salutation: Salutation,
  name: Name;
  phoneNumber: PhoneNumber;
  countryOfResidence: string;
  timeZone: string;
  imageUrl?: string;
  verifiedPhoneNumberForTwoFactorAuthentication?: PhoneNumber;
  phoneNumberVerificationRequest?: PhoneNumberVerificationRequest;
}

export interface PhoneNumberVerificationRequest {
  phoneNumberVerificationRequestId: string;
  phoneNumber: PhoneNumber;
}

export type PhoneNumberVerificationStep = 'REQUEST' | 'CONFIRM';
export const PhoneNumberVerificationSteps: Record<PhoneNumberVerificationStep, PhoneNumberVerificationStep> = {
  REQUEST: 'REQUEST',
  CONFIRM: 'CONFIRM',
};

// -- Commands

export interface RequestEmailAddressChangeCommand extends Command {
  emailAddress: string;
  currentPassword: string;
}

export interface ConfirmEmailAddressChangeCommand extends Command {
  emailAddressChangeRequestId: string;
}

export interface UpdatePasswordCommand extends Command {
  newPassword: string;
  currentPassword: string;
}

export interface UpdateNameCommand extends Command {
  salutation: Salutation;
  name: Name;
}

export interface UpdatePhoneNumberCommand extends Command {
  phoneNumber: PhoneNumber;
}

export interface UpdateCountryCommand extends Command {
  countryOfResidence: string;
}

export interface StartPhoneNumberVerificationForTwoFactorAuthenticationCommand extends Command {
  phoneNumber: PhoneNumber;
  currentPassword: string;
}

export interface ConfirmPhoneNumberForTwoFactorAuthenticationCommand extends Command {
  phoneNumberVerificationRequestId: string;
  code: string;
}

export interface DeleteVerifiedPhoneNumberForTwoFactorAuthenticationCommand extends Command {
  currentPassword: string;
}

export interface CancelPhoneNumberVerificationForTwoFactorAuthenticationCommand extends Command {
  phoneNumberVerificationRequestId: string;
}
