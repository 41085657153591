import { performApiRequest, performApiRequestForFile, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { Product, GetProductQuery, GetDueDiligenceFileForDownloadQuery, GetProductPrintPDFForDownloadQuery, GetProductNewsArticleAttachmentForDownloadQuery, OnboardProductCommand, OffboardProductCommand } from './types';

// -- Commands

export function onboardProduct(command: OnboardProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/onboard-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function offboardProduct(command: OffboardProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/offboard-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

// -- Queries

export function getProduct(query: GetProductQuery): Promise<Product> {
  const url = generateApiUrl('/api/general-partner/get-product-from-network-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-due-diligence-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductPrintPDFForDownload(query: GetProductPrintPDFForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-product-print-pdf-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-product-news-article-attachment-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
