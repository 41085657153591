import { App, URLOpenListenerEvent } from '@capacitor/app';
import { router as whitelabelRouter } from '@/router/whitelabel';

export function initDeepLinks(): void {
  App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    const url = new URL(event.url);
    const routePath = decodeURI(`${url.pathname}${url.search}`);

    const isRootUrl = routePath.length === 0 || routePath === '/';
    const isOnCurrentRoute = whitelabelRouter.currentRoute.path === routePath;

    if (!isRootUrl && !isOnCurrentRoute) {
      whitelabelRouter.push({
        path: routePath,
      });
    }
  });
}
