import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { GetInvestorDetailsQuery, InvestorDetails, UpdateInvestorDetailsCommand, GetInvestorContactsQuery, InvestorContact } from './types';

export function getInvestorDetails(query: GetInvestorDetailsQuery): Promise<InvestorDetails> {
  const url = generateApiUrl('/api/general-partner/get-investor-details-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function updateInvestorDetails(command: UpdateInvestorDetailsCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investor-details-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestorContacts(query: GetInvestorContactsQuery): Promise<InvestorContact[]> {
  const url = generateApiUrl('/api/general-partner/get-investor-contacts-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
