import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { Product, Investment, UpdateInvestmentForInvestorCommand, DeleteInvestmentForInvestorCommand, SubscriptionProcess, GetInvestmentsQuery, GetSubscriptionProcessesQuery, GetAccessiblePublishedProductsForInvestorQuery, GetOnboardedProductSubscriptionProcessesQuery, OnboardedProductSubscriptionProcess, GetOnboardedProductInvestmentsQuery, OnboardedProductInvestment } from './types';

export function getPublishedProducts(): Promise<Product[]> {
  const url = generateApiUrl('/api/general-partner/get-published-products-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getAccessiblePublishedProductsForInvestor(query: GetAccessiblePublishedProductsForInvestorQuery): Promise<Product[]> {
  const url = generateApiUrl('/api/general-partner/get-accessible-published-products-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestments(query: GetInvestmentsQuery): Promise<Investment[]> {
  const url = generateApiUrl('/api/general-partner/get-investments-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductInvestments(query: GetOnboardedProductInvestmentsQuery): Promise<OnboardedProductInvestment[]> {
  const url = generateApiUrl('/api/general-partner/get-onboarded-product-investments-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionProcesses(query: GetSubscriptionProcessesQuery): Promise<SubscriptionProcess[]> {
  const url = generateApiUrl('/api/general-partner/get-subscription-processes-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionProcesses(
  query: GetOnboardedProductSubscriptionProcessesQuery
): Promise<OnboardedProductSubscriptionProcess[]> {
  const url = generateApiUrl('/api/general-partner/get-onboarded-product-subscription-processes-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function updateInvestmentForInvestor(command: UpdateInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteInvestmentForInvestor(command: DeleteInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
