import FileSaver from 'file-saver';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import i18n from '@/plugins/vue-i18n';
import { isMobileApplication } from '@/helpers/detection-helpers';
import { showError, showSuccess } from '@/application/common/snackbar/service';

export async function downloadFile(data: Blob, contentType: string, fileName: string): Promise<void> {
  if (isMobileApplication()) {
    await saveFileForMobile(data, fileName);
  } else {
    FileSaver.saveAs(data, fileName);
  }
}

/**
 * A file is stored into the documents directory on iOS and the downloads directory in Android.
 * On iOS we're able to open the file afterwards, so we do. For .zip files we open the Files app on the directory instead.
 * On Android we're not able to open the file afterwards, so we simply inform the user that the file has been placed into the directory.
 */
async function saveFileForMobile(data: Blob, fileName: string): Promise<void> {
  let wasPermissionGranted = false;
  try {
    const permissionStatus = await Filesystem.checkPermissions();

    if (permissionStatus.publicStorage === 'denied') {
      showError({ message: i18n.t('helpers.fileDownloadHelper.permissionDenied') });
    } else {
      wasPermissionGranted = true;
    }
  } catch (error) {
    showError({ message: i18n.t('helpers.fileDownloadHelper.permissionCheckFailed') });
  }

  if (!wasPermissionGranted) {
    return Promise.reject();
  }

  const isPlatformIOS = Capacitor.getPlatform() === 'ios';

  const base64Data = await convertBlobToBase64(data);

  const directory = isPlatformIOS
    ? Directory.Documents
    : Directory.ExternalStorage;

  const filePath = isPlatformIOS
    ? fileName
    : `Download/${fileName}`;

  return Filesystem.writeFile({
    path: filePath,
    data: base64Data,
    directory,
  }).then(
    (result) => {
      if (isPlatformIOS) {
        window.open(sharedPathFromUri(result.uri));
      } else {
        showSuccess({ message: i18n.t('helpers.fileDownloadHelper.androidSuccessMessage') });
      }
    },
    () => showError({ message: i18n.t('helpers.fileDownloadHelper.downloadFailed') })
  );
}

function sharedPathFromUri(uri: string): string {
  const sharedPath = uri.replace('file://', 'shareddocuments://');

  return sharedPath.endsWith('zip')
    ? sharedPath.substr(0, sharedPath.lastIndexOf('/') + 1)
    : sharedPath;
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onabort = reject;
    reader.onload = () => {
      const result = reader.result as string;
      const base64Data = result.split(',')[1];

      resolve(base64Data);
    };
    reader.readAsDataURL(blob);
  });
}
