import { defineStore, storeToRefs } from 'pinia';
import { isEqual } from 'lodash-es';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, VuetifySelectItem } from '@/application/types';
import { PublishedProduct } from '@/domain/general-partner/investors/investors-overview/types';
import { Product, DeleteInvestmentForInvestorCommand, Investment, UpdateInvestmentForInvestorCommand, SubscriptionProcess, InvestmentsFilters, GetAccessiblePublishedProductsForInvestorQuery, OnboardedProductSubscriptionProcess, OnboardedProductInvestment } from './types';
import { deleteInvestmentForInvestor, getInvestments, getSubscriptionProcesses, updateInvestmentForInvestor, getPublishedProducts, getAccessiblePublishedProductsForInvestor, getOnboardedProductSubscriptionProcesses, getOnboardedProductInvestments } from './service';

interface GeneralPartnerInvestmentsState {
  publishedProducts: Product[];
  accessiblePublishedProductsForInvestor: Product[];
  investmentsFilters: InvestmentsFilters;
  investments: Investment[];
  onboardedProductInvestments: OnboardedProductInvestment[];
  subscriptionProcesses: SubscriptionProcess[];
  onboardedProductSubscriptionProcesses: OnboardedProductSubscriptionProcess[];

  getPublishedProductsStatus: ActionStatus;
  getAccessiblePublishedProductsForInvestorStatus: ActionStatus;
  getInvestmentsStatus: ActionStatus;
  getSubscriptionProcessesStatus: ActionStatus;
  updateInvestmentForInvestorStatus: ActionStatus;
  deleteInvestmentForInvestorStatus: ActionStatus;
  getOnboardedProductInvestmentsStatus: ActionStatus;
  getOnboardedProductSubscriptionProcessesStatus: ActionStatus;
}

function initialState(): GeneralPartnerInvestmentsState {
  return {
    publishedProducts: [],
    accessiblePublishedProductsForInvestor: [],
    investmentsFilters: {},
    investments: [],
    onboardedProductInvestments: [],
    subscriptionProcesses: [],
    onboardedProductSubscriptionProcesses: [],

    getPublishedProductsStatus: ActionStatus.None,
    getAccessiblePublishedProductsForInvestorStatus: ActionStatus.None,
    getInvestmentsStatus: ActionStatus.None,
    getSubscriptionProcessesStatus: ActionStatus.None,
    updateInvestmentForInvestorStatus: ActionStatus.None,
    deleteInvestmentForInvestorStatus: ActionStatus.None,
    getOnboardedProductInvestmentsStatus: ActionStatus.None,
    getOnboardedProductSubscriptionProcessesStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerInvestmentsStore = defineStore('generalPartnerInvestments', {
  state: (): GeneralPartnerInvestmentsState => initialState(),
  getters: {
    isGetPublishedProductsProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.getPublishedProductsStatus === ActionStatus.InProgress,
    isGetAccessiblePublishedProductsForInvestorProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.getAccessiblePublishedProductsForInvestorStatus === ActionStatus.InProgress,
    isGetInvestmentsProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.getInvestmentsStatus === ActionStatus.InProgress,
    isGetSubscriptionProcessesProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.getSubscriptionProcessesStatus === ActionStatus.InProgress,
    isUpdateInvestmentForInvestorProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.updateInvestmentForInvestorStatus === ActionStatus.InProgress,
    isDeleteInvestmentForInvestorProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.deleteInvestmentForInvestorStatus === ActionStatus.InProgress,
    isGetOnboardedProductInvestmentsProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.getOnboardedProductInvestmentsStatus === ActionStatus.InProgress,
    isGetOnboardedProductSubscriptionProcessesProcessing: (state: GeneralPartnerInvestmentsState): boolean =>
      state.getOnboardedProductSubscriptionProcessesStatus === ActionStatus.InProgress,

    accessiblePublishedProductsForInvestorItems: (state: GeneralPartnerInvestmentsState): VuetifySelectItem<string>[] =>
      state.accessiblePublishedProductsForInvestor.map((publishedProduct: Product) => ({
        text: publishedProduct.productName,
        value: publishedProduct.productId,
      })),
    publishedProductItems: (state: GeneralPartnerInvestmentsState): VuetifySelectItem<string>[] =>
      state.publishedProducts.map((publishedProduct: PublishedProduct) => ({
        text: publishedProduct.productName,
        value: publishedProduct.productId,
      })),
    areFiltersActive: (state: GeneralPartnerInvestmentsState): boolean =>
      isEqual(state.investmentsFilters, {}),
  },
  actions: {

    // -- State management

    updateInvestmentsFilters(investmentFilters: InvestmentsFilters): Promise<void[]> {
      this.investmentsFilters = investmentFilters;

      return Promise.all([
        this.getSubscriptionProcesses(),
        this.getInvestments(),
      ]);
    },

    // -- Queries

    getPublishedProducts(): Promise<void> {
      const { getPublishedProductsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPublishedProductsStatus,
        () => getPublishedProducts()
          .then((publishedProducts) => {
            this.publishedProducts = publishedProducts;
          })
      );
    },

    getAccessiblePublishedProductsForInvestor(query: GetAccessiblePublishedProductsForInvestorQuery): Promise<void> {
      const { getAccessiblePublishedProductsForInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAccessiblePublishedProductsForInvestorStatus,
        () => getAccessiblePublishedProductsForInvestor(query)
          .then((accessibleProducts) => {
            this.accessiblePublishedProductsForInvestor = accessibleProducts;
          })
      );
    },

    getInvestments(): Promise<void> {
      const { getInvestmentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestmentsStatus,
        () => getInvestments(this.investmentsFilters)
          .then((investments) => {
            this.investments = investments;
          })
      );
    },

    getSubscriptionProcesses(): Promise<void> {
      const { getSubscriptionProcessesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionProcessesStatus,
        () => getSubscriptionProcesses(this.investmentsFilters)
          .then((subscriptionProcesses) => {
            this.subscriptionProcesses = subscriptionProcesses;
          })
      );
    },

    getOnboardedProductSubscriptionProcesses(): Promise<void> {
      const { getOnboardedProductSubscriptionProcessesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOnboardedProductSubscriptionProcessesStatus,
        () => getOnboardedProductSubscriptionProcesses(this.investmentsFilters)
          .then((onboardedProductSubscriptionProcesses) => {
            this.onboardedProductSubscriptionProcesses = onboardedProductSubscriptionProcesses;
          })
      );
    },

    getOnboardedProductInvestments(): Promise<void> {
      const { getOnboardedProductInvestmentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOnboardedProductInvestmentsStatus,
        () => getOnboardedProductInvestments(this.investmentsFilters)
          .then((onboardedProductInvestments) => {
            this.onboardedProductInvestments = onboardedProductInvestments;
          })
      );
    },

    // -- Commands

    updateInvestmentForInvestor(command: UpdateInvestmentForInvestorCommand): Promise<void> {
      const { updateInvestmentForInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentForInvestorStatus,
        () => updateInvestmentForInvestor(command)
          .then(() => this.getInvestments())
      );
    },

    deleteInvestmentForInvestor(command: DeleteInvestmentForInvestorCommand): Promise<void> {
      const { deleteInvestmentForInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteInvestmentForInvestorStatus,
        () => deleteInvestmentForInvestor(command)
          .then(() => this.getInvestments())
      );
    },

  },
});
