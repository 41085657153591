import axios from 'axios';
import { clearAllStates } from '@/helpers/store-reset-helper';
import { showError, showErrorResponse } from '@/application/common/snackbar/service';
import { router } from '@/router/whitelabel';
import i18n from '@/plugins/vue-i18n';
import { navigate } from '@/helpers/whitelabel/navigation-helpers';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';

function handleAuthenticationError(error: any): Promise<any> {
  const isUnauthorizedError = (error.response?.status === 401);
  const isForbiddenError = (error.response?.status === 403);
  const isAuthenticationRequiredError = (error.response?.data.identifier === 'authentication-required');
  const isUserDisabledError = (error.response?.data.identifier === 'user-disabled');
  const isOnLoginPage = router.currentRoute.name === 'authentication/login';

  // On first authentication try the route path will always be "/" as it's triggered before the app is initialized
  const isRelevantRoute = router.currentRoute.fullPath !== '/';
  const isAlwaysAccessibleRoute = router.currentRoute.meta!.alwaysAccessible ?? false;

  if (isUnauthorizedError && isRelevantRoute && !isAlwaysAccessibleRoute) {
    clearAllStates();
    navigate({ name: 'authentication/login' });

    if (isAuthenticationRequiredError) {
      // Error has to be thrown first, as all stores (including the snackbar store) are cleared on logout
      showError({ message: i18n.t('application.authentication.authenticationRequired') });
    }
    if (isUserDisabledError) {
      showError({ message: i18n.t('application.authentication.userDisabled') });
    }
  }

  if (isForbiddenError && !isOnLoginPage) {
    const authenticationStore = useAuthenticationStore();

    authenticationStore.logout()
      .catch((error) => showErrorResponse(error))
      .finally(() => {
        if (router.currentRoute.name !== 'authentication/login') {
          router.push({ name: 'authentication/login' });
        }
      });
  }

  return Promise.reject(error);
}

export default function initAuthenticationInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    handleAuthenticationError
  );
}
