import { Name } from '@/types/global';

export function getNameStringFromName(name: Name): string {
  return `${name.lastName} ${name.firstName}`;
}

export function getFormattedNamesFromNamesIntoLine(names: Name[]): string {
  let namesAsFormatedString = '';

  for (const name of names) {
    namesAsFormatedString += `<br> ${getNameStringFromName(name)}`;
  }

  return namesAsFormatedString;
}
