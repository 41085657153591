import { Command, Query } from '@/application/types';
import { AssetClass, KPIConfiguration, KPIType, Language, TranslatedText } from '@/types/global';

export type AssetClassURLParameter =
  | 'private-equity'
  | 'private-debt'
  | 'venture-capital'
  | 'infrastructure'
  | 'real-estate'
  | 'hedge-funds'
  | 'natural-resources'
  | 'digital-assets';

export const URLParameterByAssetClass: Record<AssetClass, AssetClassURLParameter> = {
  PRIVATE_EQUITY: 'private-equity',
  PRIVATE_DEBT: 'private-debt',
  VENTURE_CAPITAL: 'venture-capital',
  INFRASTRUCTURE: 'infrastructure',
  REAL_ESTATE: 'real-estate',
  HEDGE_FUNDS: 'hedge-funds',
  NATURAL_RESOURCES: 'natural-resources',
  DIGITAL_ASSETS: 'digital-assets',
};

export const AssetClassByUrlParameter: Record<AssetClassURLParameter, AssetClass> = {
  'private-equity': 'PRIVATE_EQUITY',
  'private-debt': 'PRIVATE_DEBT',
  'venture-capital': 'VENTURE_CAPITAL',
  infrastructure: 'INFRASTRUCTURE',
  'real-estate': 'REAL_ESTATE',
  'hedge-funds': 'HEDGE_FUNDS',
  'natural-resources': 'NATURAL_RESOURCES',
  'digital-assets': 'DIGITAL_ASSETS',
};

export interface TermSheetTemplate {
  kpiCategories: KPICategory[];
}

export interface KPICategory {
  kpiCategoryId: string;
  label: Record<Language, string>;
  kpiDefinitions: KPIDefinition[];
}

export interface KPIDefinition {
  kpiDefinitionId: string;
  kpiType: KPIType;
  label: TranslatedText;
  kpiConfiguration?: KPIConfiguration;
  prefix?: TranslatedText;
  suffix?: TranslatedText;
}

// -- Queries

export interface GetTermSheetTemplateForAssetClassQuery extends Query {
  assetClass: AssetClass;
}

// -- Command

export interface CreateKPICategoryCommand extends Command {
  assetClass: AssetClass;
  label: TranslatedText;
}

export interface UpdateKPICategoryCommand extends Command {
  kpiCategoryId: string;
  label: TranslatedText;
}

export interface UpdateKPICategoriesOrderCommand extends Command {
  kpiCategoryIds: string[];
}

export interface DeleteKPICategoryCommand extends Command {
  kpiCategoryId: string;
}

export interface CreateKPIDefinitionCommand extends Command {
  kpiCategoryId: string;
  kpiType: KPIType;
  label: TranslatedText;
  kpiConfiguration?: KPIConfiguration;
  prefix?: TranslatedText;
  suffix?: TranslatedText;
}

export interface UpdateKPIDefinitionCommand extends Command {
  kpiDefinitionId: string;
  label: TranslatedText;
  kpiConfiguration?: KPIConfiguration;
  prefix?: TranslatedText;
  suffix?: TranslatedText;
}

export interface DeleteKPIDefinitionCommand extends Command {
  kpiDefinitionId: string;
}

export interface UpdateKPIDefinitionsOrderCommand extends Command {
  kpiDefinitionIds: string[];
}

export interface MoveKPIDefinitionToCategoryCommand extends Command {
  kpiDefinitionId: string;
  kpiCategoryId: string;
}
