import { Command } from '@/application/types';
import { Language, Name, Permission, PhoneNumber } from '@/types/global';

export interface Authentication {
  user: AuthenticatedUser;
}

export interface AuthenticatedUser {
  userId: string;
  name: Name;
  initials: string;
  permissions: Permission[];
  language: Language | null;
  timeZone: string;
  managementAccessForGeneralPartnerId?: string;
  managementAccessForInvestorId?: string;
  investorName?: string;
  hasAccessToKYCManagement?: boolean;
  verifiedPhoneNumberForTwoFactorAuthentication?: PhoneNumber;
}

export type LoginType = 'DIRECT' | 'TWO_FACTOR_AUTHENTICATION';
export const LoginTypes: Record<LoginType, LoginType> = {
  DIRECT: 'DIRECT',
  TWO_FACTOR_AUTHENTICATION: 'TWO_FACTOR_AUTHENTICATION',
};

export interface LoginResponse {
  loginType: LoginType;
}

export interface DirectLoginResponse extends LoginResponse {
  loginType: 'DIRECT';
  authentication: Authentication;
}

export interface TwoFactorAuthenticationLoginResponse extends LoginResponse {
  loginType: 'TWO_FACTOR_AUTHENTICATION';
  token: string;
}

export function isTwoFactorAuthenticationLoginResponse(
  loginResponse: DirectLoginResponse|TwoFactorAuthenticationLoginResponse
): loginResponse is TwoFactorAuthenticationLoginResponse {
  return loginResponse.loginType === LoginTypes.TWO_FACTOR_AUTHENTICATION;
}

// -- Commands

export interface LoginCommand {
  emailAddress: string;
  password: string;
}

export interface ConfirmTwoFactorAuthenticationCommand {
  token: string;
  code: string;
}

export interface ResetPasswordCommand {
  password: string;
  passwordResetRequestId: string;
}

export interface RequestPasswordResetCommand {
  emailAddress: string;
}

export interface UpdateUserLanguageCommand extends Command {
  language: Language;
}
