import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { GeneralPartner, UpdateEnabledLanguagesForGeneralPartnerCommand, UpdateEnabledLanguagesForProductsOfGeneralPartnerCommand, UpdateGeneralPartnerDetailsCommand, UpdateGeneralPartnerFeaturesCommand } from './types';
import { getGeneralPartnersForOverview, updateEnabledLanguagesForGeneralPartner, updateEnabledLanguagesForProductsOfGeneralPartner, updateGeneralPartnerDetails, updateGeneralPartnerFeatures } from './service';

interface BrainGeneralPartnerOverviewState {
  generalPartners: GeneralPartner[];

  getGeneralPartnersForOverviewStatus: ActionStatus;
  updateGeneralPartnerDetailsStatus: ActionStatus;
  updateGeneralPartnerFeaturesStatus: ActionStatus;
  updateEnabledLanguagesForGeneralPartnerStatus: ActionStatus;
  updateEnabledLanguagesForProductsOfGeneralPartnerStatus: ActionStatus;
}

function initialState(): BrainGeneralPartnerOverviewState {
  return {
    generalPartners: [],

    getGeneralPartnersForOverviewStatus: ActionStatus.None,
    updateGeneralPartnerDetailsStatus: ActionStatus.None,
    updateGeneralPartnerFeaturesStatus: ActionStatus.None,
    updateEnabledLanguagesForGeneralPartnerStatus: ActionStatus.None,
    updateEnabledLanguagesForProductsOfGeneralPartnerStatus: ActionStatus.None,
  };
}

export const useBrainGeneralPartnerOverviewStore = defineStore('brainGeneralPartnerOverview', {
  state: (): BrainGeneralPartnerOverviewState => initialState(),
  getters: {
    isGetGeneralPartnersForOverviewProcessing: (state: BrainGeneralPartnerOverviewState): boolean =>
      state.getGeneralPartnersForOverviewStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerDetailsProcessing: (state: BrainGeneralPartnerOverviewState): boolean =>
      state.updateGeneralPartnerDetailsStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerFeaturesProcessing: (state: BrainGeneralPartnerOverviewState): boolean =>
      state.updateGeneralPartnerFeaturesStatus === ActionStatus.InProgress,
    isUpdateEnabledLanguagesForGeneralPartnerProcessing: (state: BrainGeneralPartnerOverviewState): boolean =>
      state.updateEnabledLanguagesForGeneralPartnerStatus === ActionStatus.InProgress,
    isUpdateEnabledLanguagesForProductsOfGeneralPartnerProcessing: (state: BrainGeneralPartnerOverviewState): boolean =>
      state.updateEnabledLanguagesForProductsOfGeneralPartnerStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getGeneralPartnersForOverview(): Promise<void> {
      const { getGeneralPartnersForOverviewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnersForOverviewStatus,
        () => getGeneralPartnersForOverview()
          .then((generalPartners) => {
            this.generalPartners = generalPartners;
          })
      );
    },

    // -- Commands

    updateGeneralPartnerDetails(command: UpdateGeneralPartnerDetailsCommand): Promise<void> {
      const { updateGeneralPartnerDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerDetailsStatus,
        () => updateGeneralPartnerDetails(command)
          .then(() => this.getGeneralPartnersForOverview())
      );
    },

    updateGeneralPartnerFeatures(command: UpdateGeneralPartnerFeaturesCommand): Promise<void> {
      const { updateGeneralPartnerFeaturesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerFeaturesStatus,
        () => updateGeneralPartnerFeatures(command)
          .then(() => this.getGeneralPartnersForOverview())
      );
    },

    updateEnabledLanguagesForGeneralPartner(command: UpdateEnabledLanguagesForGeneralPartnerCommand): Promise<void> {
      const { updateEnabledLanguagesForGeneralPartnerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEnabledLanguagesForGeneralPartnerStatus,
        () => updateEnabledLanguagesForGeneralPartner(command)
          .then(() => this.getGeneralPartnersForOverview())
      );
    },

    updateEnabledLanguagesForProductsOfGeneralPartner(
      command: UpdateEnabledLanguagesForProductsOfGeneralPartnerCommand
    ): Promise<void> {
      const { updateEnabledLanguagesForProductsOfGeneralPartnerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEnabledLanguagesForProductsOfGeneralPartnerStatus,
        () => updateEnabledLanguagesForProductsOfGeneralPartner(command)
          .then(() => this.getGeneralPartnersForOverview())
      );
    },

  },
});
