import { Command, Query } from '@/application/types';
import { AssetClass, KPI, KPIConfiguration, KPIType, Language, TranslatedText } from '@/types/global';
import { Decimal, Money } from '@/models/decimal';

// -- State

export type ProductCreationStep =
  | 'DEFINE_MASTER_DATA'
  | 'DEFINE_TERM_SHEET'
  | 'DEFINE_GENERAL_PARTNER_USER_ACCESS'
  | 'DEFINE_INVESTOR_ACCESS'
  | 'DEFINE_PRODUCT_INFO';
export const ProductCreationSteps: Record<ProductCreationStep, ProductCreationStep> = {
  DEFINE_MASTER_DATA: 'DEFINE_MASTER_DATA',
  DEFINE_TERM_SHEET: 'DEFINE_TERM_SHEET',
  DEFINE_GENERAL_PARTNER_USER_ACCESS: 'DEFINE_GENERAL_PARTNER_USER_ACCESS',
  DEFINE_INVESTOR_ACCESS: 'DEFINE_INVESTOR_ACCESS',
  DEFINE_PRODUCT_INFO: 'DEFINE_PRODUCT_INFO',
};

export type TermSheetItem = KPIDefinition | KPI;

export type KPIValue = null|string|boolean|TranslatedKPIValue|KPIWithRangeValue|Decimal;
export type TranslatedKPIValue = Record<Language, string>;
export interface KPIWithRangeValue {
  from: Decimal|null;
  to: Decimal|null;
}

export interface Investor {
  investorId: string;
  name: string;
  openTicket: Money[];
  committedCapital: Money[];
  isLocked: boolean;
}

export interface TermSheetTemplate {
  kpiCategories: KPICategory[];
  kpiDefinitions: KPIDefinition[];
}

export interface KPICategory {
  kpiCategoryId: string;
  label: TranslatedText;
}

export interface KPIDefinition {
  kpiDefinitionId: string;
  kpiCategoryId: string;
  kpiType: KPIType;
  label: TranslatedText;
  kpiConfiguration?: KPIConfiguration;
  prefix?: TranslatedText;
  suffix?: TranslatedText;
  position: number;
}

export interface MasterDataFormValues {
  productName: TranslatedText|null;
  assetClass: AssetClass;
}

export interface KPICategoryWithKPIs {
  kpiCategoryId: string;
  label: TranslatedText;
  kpis: KPI[];
}

export interface TermSheetFormValues {
  kpiCategories: KPICategoryWithKPIs[];
}

export interface ProductDataFormValues {
  masterData: MasterDataFormValues;
  termSheet: TermSheetFormValues;
  idsOfInvestorsWithAccess: string[];
  idsOfGeneralPartnerUsersWithExplicitAccess: string[];
  kpiIdsForProductInfo: string[];
}

// -- Queries

export interface GetTermSheetTemplateForAssetClassQuery extends Query {
  assetClass: AssetClass;
}

// -- Commands

export interface AddCustomKPICommand extends Command {
  customKPI: KPI;
  kpiCategoryId: string;
}

export interface CreateProductCommand extends Command {
  assetClass: AssetClass;
  productName: TranslatedText;
  idsOfInvestorsWithAccess: string[];
  idsOfGeneralPartnerUsersWithExplicitAccess: string[];
  kpiCategories: KPICategoryWithKPIs[];
  idsOfKPIsForProductInfo: string[];
}
