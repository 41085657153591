import { RouteConfig } from 'vue-router';
import { AccountTypes, Features } from '@/types/global';
import Dashboard from './components/dashboard.vue';

export const investorDashboardRoutes: RouteConfig[] = [
  {
    name: 'investor-dashboard',
    path: '/investor/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.INVESTOR,
      requiresFeatures: [Features.PORTFOLIO],
    },
  },
];
