import { defineStore, storeToRefs } from 'pinia';
import dayjs from 'dayjs';
import i18n from '@/plugins/vue-i18n';
import { serializer, wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { useAppStore } from '@/application/common/app/store';
import { LOCAL_STORAGE_BRAINIAC_ID, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { useRefreshedSessionStore } from '@/application/common/refreshed-session-dialog/store';
import { Brainiac, ConfirmTwoFactorAuthenticationCommand, isTwoFactorAuthenticationLoginResponse, LoginCommand, LoginType } from './types';
import { confirmTwoFactorAuthentication, getAuthentication, login, logout } from './service';

interface BrainAuthenticationState {
  brainiac: Brainiac | null;
  twoFactorAuthenticationToken: string | null;
  isTwoFactorProcessVisible: boolean;
  wasInitialAuthenticationAttempted: boolean;

  loginStatus: ActionStatus;
  confirmTwoFactorAuthenticationStatus: ActionStatus;
  logoutStatus: ActionStatus;
  getAuthenticationStatus: ActionStatus;
}

function initialState(): BrainAuthenticationState {
  return {
    brainiac: null,
    twoFactorAuthenticationToken: null,
    isTwoFactorProcessVisible: false,
    wasInitialAuthenticationAttempted: false,

    loginStatus: ActionStatus.None,
    confirmTwoFactorAuthenticationStatus: ActionStatus.None,
    logoutStatus: ActionStatus.None,
    getAuthenticationStatus: ActionStatus.None,
  };
}

export const useBrainAuthenticationStore = defineStore('brainAuthentication', {
  state: (): BrainAuthenticationState => initialState(),
  persist: {
    serializer,
  },
  getters: {
    isLoginProcessing: (state: BrainAuthenticationState): boolean =>
      state.loginStatus === ActionStatus.InProgress,
    isConfirmTwoFactorAuthenticationProcessing: (state: BrainAuthenticationState): boolean =>
      state.confirmTwoFactorAuthenticationStatus === ActionStatus.InProgress,
    isLogoutProcessing: (state: BrainAuthenticationState): boolean =>
      state.logoutStatus === ActionStatus.InProgress,
    isGetAuthenticationProcessing: (state: BrainAuthenticationState): boolean =>
      state.getAuthenticationStatus === ActionStatus.InProgress,

    isAuthenticated: (state: BrainAuthenticationState): boolean =>
      !!state.brainiac,
  },
  actions: {

    // -- State management

    partialReset(): Promise<void> {
      // Was initial authentication attempted is never reset.
      this.brainiac = null;
      this.twoFactorAuthenticationToken = null;
      this.isTwoFactorProcessVisible = false;

      this.loginStatus = ActionStatus.None;
      this.confirmTwoFactorAuthenticationStatus = ActionStatus.None;
      this.logoutStatus = ActionStatus.None;
      this.getAuthenticationStatus = ActionStatus.None;

      return Promise.resolve();
    },

    login(command: LoginCommand): Promise<LoginType> {
      const { loginStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        loginStatus,
        () => login(command)
          .then(async (loginResponse) => {
            /**
             * Resolve two-factor authentication.
             */
            if (isTwoFactorAuthenticationLoginResponse(loginResponse)) {
              this.isTwoFactorProcessVisible = true;
              this.twoFactorAuthenticationToken = loginResponse.token;

              return Promise.resolve(loginResponse.loginType);
            }

            /**
             * Resolve direct login.
             */
            await this.updateUserSession(loginResponse.authentication!.brainiac.brainiacId);

            useAppStore().updateLanguage(loginResponse.authentication!.brainiac.language);

            i18n.locale = loginResponse.authentication!.brainiac.language!;
            dayjs.locale(loginResponse.authentication!.brainiac.language!);

            this.brainiac = loginResponse.authentication!.brainiac;

            return Promise.resolve(loginResponse.loginType);
          })
      );
    },

    updateTwoFactorProcessVisibility(isTwoFactorProcessVisible: boolean): Promise<void> {
      this.isTwoFactorProcessVisible = isTwoFactorProcessVisible;

      return Promise.resolve();
    },

    confirmTwoFactorAuthentication(command: ConfirmTwoFactorAuthenticationCommand): Promise<void> {
      const { confirmTwoFactorAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmTwoFactorAuthenticationStatus,
        () => confirmTwoFactorAuthentication(command)
          .then(async (authentication) => {
            const appStore = useAppStore();
            await this.updateUserSession(authentication.brainiac.brainiacId);

            appStore.updateLanguage(authentication.brainiac.language);

            i18n.locale = authentication.brainiac.language;
            dayjs.locale(authentication.brainiac.language);

            this.brainiac = authentication.brainiac;
          })
      );
    },

    logout(): Promise<void> {
      const { logoutStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        logoutStatus,
        () => logout()
          .then(() => {
            this.brainiac = null;
          })
      );
    },

    getInitialAuthentication(): Promise<void> {
      return this.getAuthentication()
        .finally(() => {
          this.wasInitialAuthenticationAttempted = true;
        });
    },

    getAuthentication(): Promise<void> {
      const { getAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAuthenticationStatus,
        () => getAuthentication()
          .then(async (authentication) => {
            const appStore = useAppStore();
            await this.updateUserSession(authentication.brainiac.brainiacId);

            appStore.updateLanguage(authentication!.brainiac.language);

            i18n.locale = authentication.brainiac.language;
            dayjs.locale(authentication.brainiac.language);

            this.brainiac = authentication.brainiac;
          })
      );
    },

    async updateUserSession(brainiacId: string): Promise<void> {
      await useRefreshedSessionStore().updateLastAuthenticatedBrainiacId(brainiacId);

      // Outdated session is triggered through local storage update
      setLocalStorageItem(LOCAL_STORAGE_BRAINIAC_ID, brainiacId);
    },

  },
});
