import { RouteConfig } from 'vue-router';
import { AccountTypes } from '@/types/global';
import Products from './products/components/products.vue';
import Product from './product/components/product.vue';
import AboutProductTab from './product/components/about-product-tab.vue';
import ProductDetailsTab from './product/components/product-details-tab.vue';
import ProductDocumentsTab from './product/components/product-documents-tab/product-documents-tab.vue';
import ProductHoldingsTab from './product/components/product-holdings-tab.vue';
import ProductNewsTab from './product/components/product-news-tab/product-news-tab.vue';
import ProductComparison from './product-comparison/components/product-comparison.vue';

export const investorProductsRoutes: RouteConfig[] = [
  {
    name: 'investor-products',
    path: '/investor/products',
    component: Products,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.INVESTOR,
    },
  }, {
    path: '/investor/product/:productId',
    component: Product,
    children: [
      {
        path: '',
        redirect: 'about',
      }, {
        name: 'investor-product-about',
        path: 'about',
        component: AboutProductTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'investor-product-details',
        path: 'details',
        component: ProductDetailsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'investor-product-documents',
        path: 'documents',
        component: ProductDocumentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'investor-product-news',
        path: 'news',
        component: ProductNewsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'investor-product-holdings',
        path: 'holdings',
        component: ProductHoldingsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  }, {
    name: 'investor-product-comparison',
    path: '/investor/product-comparison/',
    component: ProductComparison,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.INVESTOR,
    },
  },
];
