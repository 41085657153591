import { render, staticRenderFns } from "./subscription-processes-tab.vue?vue&type=template&id=a4625a7a&scoped=true&"
import script from "./subscription-processes-tab.vue?vue&type=script&lang=ts&"
export * from "./subscription-processes-tab.vue?vue&type=script&lang=ts&"
import style0 from "./subscription-processes-tab.vue?vue&type=style&index=0&id=a4625a7a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4625a7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VBtnToggle,VDataTable})
