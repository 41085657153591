import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { useGeneralPartnerProductManagementGeneralStore } from '../../store';
import { updateProductManagersOrder, addProductManager, deleteProductManager, updateProductManager, updateProductImage, updateProductDescription, addProductSummaryItem, removeProductSummaryItem, updateProductTrackRecordImage, deleteProductTrackRecordImage, createVimeoVideo, updateProductVideo, deleteProductVideo, updateProductContactEmailAddress, updateProductPresentation, deleteProductPresentation } from './services';
import { AddProductManagerCommand, AddProductSummaryItemCommand, CreateVimeoVideoCommand, DeleteProductManagerCommand, DeleteProductPresentationCommand, DeleteProductTrackRecordImageCommand, DeleteProductVideoCommand, RemoveProductSummaryItemCommand, UpdateProductContactEmailAddressCommand, UpdateProductDescriptionCommand, UpdateProductImageCommand, UpdateProductManagerCommand, UpdateProductManagersOrderCommand, UpdateProductPresentationCommand, UpdateProductTrackRecordImageCommand, UpdateProductVideoCommand } from './types';

interface GeneralPartnerProductManagementAboutTabState {
  updateProductImageStatus: ActionStatus;
  updateProductDescriptionStatus: ActionStatus;
  addProductSummaryItemStatus: ActionStatus;
  removeProductSummaryItemStatus: ActionStatus;
  addProductManagerStatus: ActionStatus;
  deleteProductManagerStatus: ActionStatus;
  updateProductManagerStatus: ActionStatus;
  updateProductManagersOrderStatus: ActionStatus;
  updateProductTrackRecordImageStatus: ActionStatus;
  deleteProductTrackRecordImageStatus: ActionStatus;
  deleteProductPresentationStatus: ActionStatus;
  updateProductPresentationStatus: ActionStatus;
  createVimeoVideoStatus: ActionStatus;
  updateProductVideoStatus: ActionStatus;
  deleteProductVideoStatus: ActionStatus;
  updateProductContactEmailAddressStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementAboutTabState {
  return {
    updateProductImageStatus: ActionStatus.None,
    updateProductDescriptionStatus: ActionStatus.None,
    addProductSummaryItemStatus: ActionStatus.None,
    removeProductSummaryItemStatus: ActionStatus.None,
    addProductManagerStatus: ActionStatus.None,
    deleteProductManagerStatus: ActionStatus.None,
    updateProductManagerStatus: ActionStatus.None,
    updateProductManagersOrderStatus: ActionStatus.None,
    updateProductTrackRecordImageStatus: ActionStatus.None,
    deleteProductTrackRecordImageStatus: ActionStatus.None,
    deleteProductPresentationStatus: ActionStatus.None,
    updateProductPresentationStatus: ActionStatus.None,
    createVimeoVideoStatus: ActionStatus.None,
    updateProductVideoStatus: ActionStatus.None,
    deleteProductVideoStatus: ActionStatus.None,
    updateProductContactEmailAddressStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementAboutTabStore = defineStore('generalPartnerProductManagementAboutTab', {
  state: (): GeneralPartnerProductManagementAboutTabState => initialState(),
  getters: {
    isUpdateProductImageProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductImageStatus === ActionStatus.InProgress,
    isUpdateProductDescriptionProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductDescriptionStatus === ActionStatus.InProgress,
    isAddProductSummaryItemProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.addProductSummaryItemStatus === ActionStatus.InProgress,
    isRemoveProductSummaryItemProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.removeProductSummaryItemStatus === ActionStatus.InProgress,
    isAddProductManagerProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.addProductManagerStatus === ActionStatus.InProgress,
    isDeleteProductManagerProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.deleteProductManagerStatus === ActionStatus.InProgress,
    isUpdateProductManagerProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductManagerStatus === ActionStatus.InProgress,
    isUpdateProductManagersOrderProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductManagersOrderStatus === ActionStatus.InProgress,
    isUpdateProductTrackRecordImageProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductTrackRecordImageStatus === ActionStatus.InProgress,
    isDeleteProductTrackRecordImageProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.deleteProductTrackRecordImageStatus === ActionStatus.InProgress,
    isDeleteProductPresentationProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.deleteProductPresentationStatus === ActionStatus.InProgress,
    isUpdateProductPresentationProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductPresentationStatus === ActionStatus.InProgress,
    isCreateVimeoVideoProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.createVimeoVideoStatus === ActionStatus.InProgress,
    isUpdateProductVideoProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductVideoStatus === ActionStatus.InProgress,
    isDeleteProductVideoProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.deleteProductVideoStatus === ActionStatus.InProgress,
    isUpdateProductContactEmailAddressProcessing: (state: GeneralPartnerProductManagementAboutTabState): boolean =>
      state.updateProductContactEmailAddressStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Commands

    updateProductImage(command: UpdateProductImageCommand): Promise<void> {
      const { updateProductImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductImageStatus,
        () => updateProductImage(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductDescription(command: UpdateProductDescriptionCommand): Promise<void> {
      const { updateProductDescriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductDescriptionStatus,
        () => updateProductDescription(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    addProductSummaryItem(command: AddProductSummaryItemCommand): Promise<void> {
      const { addProductSummaryItemStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addProductSummaryItemStatus,
        () => addProductSummaryItem(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    removeProductSummaryItem(command: RemoveProductSummaryItemCommand): Promise<void> {
      const { removeProductSummaryItemStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeProductSummaryItemStatus,
        () => removeProductSummaryItem(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    addProductManager(command: AddProductManagerCommand): Promise<void> {
      const { addProductManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addProductManagerStatus,
        () => addProductManager(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    deleteProductManager(command: DeleteProductManagerCommand): Promise<void> {
      const { deleteProductManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductManagerStatus,
        () => deleteProductManager(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductManager(command: UpdateProductManagerCommand): Promise<void> {
      const { updateProductManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductManagerStatus,
        () => updateProductManager(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductManagersOrder(command: UpdateProductManagersOrderCommand): Promise<void> {
      const { updateProductManagersOrderStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductManagersOrderStatus,
        () => updateProductManagersOrder(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductTrackRecordImage(command: UpdateProductTrackRecordImageCommand): Promise<void> {
      const { updateProductTrackRecordImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductTrackRecordImageStatus,
        () => updateProductTrackRecordImage(command)
      );
    },

    deleteProductTrackRecordImage(command: DeleteProductTrackRecordImageCommand): Promise<void> {
      const { deleteProductTrackRecordImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductTrackRecordImageStatus,
        () => deleteProductTrackRecordImage(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    deleteProductPresentation(command: DeleteProductPresentationCommand): Promise<void> {
      const { deleteProductPresentationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductPresentationStatus,
        () => deleteProductPresentation(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductPresentation(command: UpdateProductPresentationCommand): Promise<void> {
      const { updateProductPresentationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductPresentationStatus,
        () => updateProductPresentation(command)
      );
    },

    createVimeoVideo(command: CreateVimeoVideoCommand): Promise<void> {
      const { createVimeoVideoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createVimeoVideoStatus,
        () => createVimeoVideo(command)
      );
    },

    updateProductVideo(command: UpdateProductVideoCommand): Promise<void> {
      const { updateProductVideoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductVideoStatus,
        () => updateProductVideo(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    deleteProductVideo(command: DeleteProductVideoCommand): Promise<void> {
      const { deleteProductVideoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductVideoStatus,
        () => deleteProductVideo(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

    updateProductContactEmailAddress(command: UpdateProductContactEmailAddressCommand): Promise<void> {
      const { updateProductContactEmailAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductContactEmailAddressStatus,
        () => updateProductContactEmailAddress(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
          .then(() => useGeneralPartnerProductManagementGeneralStore().updateIsPreviewVisible(true))
      );
    },

  },
});
