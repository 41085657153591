import { render, staticRenderFns } from "./subscription-processes.vue?vue&type=template&id=67e90c8d&scoped=true&"
import script from "./subscription-processes.vue?vue&type=script&lang=ts&"
export * from "./subscription-processes.vue?vue&type=script&lang=ts&"
import style0 from "./subscription-processes.vue?vue&type=style&index=0&id=67e90c8d&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e90c8d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
