import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { KPI, Language, Languages } from '@/types/global';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { getDueDiligenceFileForDownload, getProduct, getProductPrintPDFForDownload, getProductNewsArticleAttachmentForDownload, onboardProduct, offboardProduct } from './service';
import { Product, GetDueDiligenceFileForDownloadQuery, GetProductQuery, GetProductPrintPDFForDownloadQuery, GetProductNewsArticleAttachmentForDownloadQuery, DueDiligenceFile, ProductVideo, OnboardProductCommand, OffboardProductCommand } from './types';

interface GeneralPartnerProductFromNetworkState {
  currentProductId: string | null;
  currentProduct: Product | null;
  selectedLanguage: Language;

  getCurrentProductStatus: ActionStatus;
  getDueDiligenceFileForDownloadStatus: ActionStatus;
  getProductPrintPDFForDownloadStatus: ActionStatus;
  getProductNewsArticleAttachmentForDownloadStatus: ActionStatus;
  onboardProductStatus: ActionStatus;
  offboardProductStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductFromNetworkState {
  return {
    currentProductId: null,
    currentProduct: null,
    selectedLanguage: Languages.en,

    getCurrentProductStatus: ActionStatus.None,
    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    getProductPrintPDFForDownloadStatus: ActionStatus.None,
    getProductNewsArticleAttachmentForDownloadStatus: ActionStatus.None,
    onboardProductStatus: ActionStatus.None,
    offboardProductStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductFromNetworkStore = defineStore('generalPartnerProductFromNetwork', {
  state: (): GeneralPartnerProductFromNetworkState => initialState(),
  getters: {
    isGetCurrentProductProcessing: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.getCurrentProductStatus === ActionStatus.InProgress,
    isGetDueDiligenceFileForDownloadProcessing: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.getDueDiligenceFileForDownloadStatus === ActionStatus.InProgress,
    isGetProductPrintPDFForDownloadProcessing: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.getProductPrintPDFForDownloadStatus === ActionStatus.InProgress,
    isGetProductNewsArticleAttachmentForDownloadProcessing: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.getProductNewsArticleAttachmentForDownloadStatus === ActionStatus.InProgress,
    isOnboardProductProcessing: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.onboardProductStatus === ActionStatus.InProgress,
    isOffboardProductProcessing: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.offboardProductStatus === ActionStatus.InProgress,

    productName: (state: GeneralPartnerProductFromNetworkState): string => {
      if (!state.currentProduct) {
        return '';
      }

      return state.currentProduct.productName[state.selectedLanguage]
        || state.currentProduct.productName.en;
    },
    productPinnedDueDiligenceFilesWithFallback: (state: GeneralPartnerProductFromNetworkState): DueDiligenceFile[] =>
      state.currentProduct?.pinnedDueDiligenceFiles[state.selectedLanguage]
        ?? state.currentProduct?.pinnedDueDiligenceFiles.en
        ?? [],
    hasPinnedDueDiligenceFiles: (state: GeneralPartnerProductFromNetworkState): boolean =>
      (
        state.currentProduct?.pinnedDueDiligenceFiles[state.selectedLanguage]
        ?? state.currentProduct?.pinnedDueDiligenceFiles.en
        ?? []
      ).length > 0,
    summaryKPIs: (state: GeneralPartnerProductFromNetworkState): KPI[] =>
      state.currentProduct
        ? state.currentProduct.idsOfKPIsForSummary.map((kpiId) => getKPIsFromTermSheet(state.currentProduct!.termSheet)
          .find((kpi) => kpi.kpiId === kpiId)!)
        : [],
    hasProductVideo: (state: GeneralPartnerProductFromNetworkState): boolean =>
      !!state.currentProduct
        && !!state.currentProduct.productVideo,
    productVideo: (state: GeneralPartnerProductFromNetworkState): ProductVideo|null => {
      if (!state.currentProduct) {
        return null;
      }

      const productVideo = state.currentProduct.productVideo
        && (state.currentProduct.productVideo[state.selectedLanguage!]
          || state.currentProduct.productVideo.en);

      return productVideo || null;
    },
    hasManagers: (state: GeneralPartnerProductFromNetworkState): boolean =>
      !!state.currentProduct
        && state.currentProduct.managers.length > 0,
    hasSummaryKPIs: (state: GeneralPartnerProductFromNetworkState): boolean =>
      !!state.currentProduct
        && (state.currentProduct.idsOfKPIsForSummary?.length ?? 0) > 0,
    hasProductNewsArticles: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.currentProduct
        && state.currentProduct.productNewsArticles.length > 0
        || false,
    isProductAvailable: (state: GeneralPartnerProductFromNetworkState): boolean =>
      state.currentProduct !== null
        && state.currentProduct.productId === state.currentProductId,
    productKPIs: (state: GeneralPartnerProductFromNetworkState): KPI[] =>
      state.currentProduct
        ? getKPIsFromTermSheet(state.currentProduct.termSheet)
        : [],
  },
  actions: {

    // -- State management

    updateCurrentProductId(currentProductId: string): Promise<void> {
      this.currentProductId = currentProductId;

      return Promise.resolve();
    },

    updateLanguage(language: Language): Promise<void> {
      this.selectedLanguage = language;

      return Promise.resolve();
    },

    // -- Queries

    getCurrentProduct(): Promise<void> {
      const query: GetProductQuery = {
        productId: this.currentProductId!,
      };

      const { getCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentProductStatus,
        () => getProduct(query)
          .then((product) => {
            this.currentProduct = product;
          })
      );
    },

    getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceFileForDownloadStatus,
        () => getDueDiligenceFileForDownload(query)
      );
    },

    getProductPrintPDFForDownload(query: GetProductPrintPDFForDownloadQuery): Promise<FileResponse> {
      const { getProductPrintPDFForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductPrintPDFForDownloadStatus,
        () => getProductPrintPDFForDownload(query)
      );
    },

    getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
      const { getProductNewsArticleAttachmentForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductNewsArticleAttachmentForDownloadStatus,
        () => getProductNewsArticleAttachmentForDownload(query)
      );
    },

    // -- Commands

    onboardProduct(): Promise<void> {
      const command: OnboardProductCommand = {
        productId: this.currentProductId!,
      };

      const { onboardProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        onboardProductStatus,
        () => onboardProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    offboardProduct(): Promise<void> {
      const command: OffboardProductCommand = {
        productId: this.currentProductId!,
      };

      const { offboardProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        offboardProductStatus,
        () => offboardProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

  },
});
