import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { CurrencySymbols, SubscriptionProcessStatuses } from '@/types/global';
import { formatCurrencyWithoutCentDigits } from '@/filters/currencies';
import { createInvestmentForSubscriptionProcess, getOnboardedProductSubscriptionProcess, getOnboardedProductSubscriptionDocumentFileForDownload, specifyOnboardedProductSubscriptionInvestmentAmount, getOnboardedProductSubscriptionDocumentTemplateFileForDownload, createOnboardedProductSubscriptionDocumentForSubscriptionProcess, confirmOnboardedProductSubscription, deleteOnboardedProductSubscriptionDocument, deleteOnboardedProductKYCDocumentAttachment, selectInvestmentProfileForOnboardedProductSubscriptionProcess, getInvestmentProfiles, confirmSelectedInvestmentProfileForSubscriptionProcess, getInvestmentProfile, continueOnboardedProductSubscriptionProcessAsNaturalPerson, createKYCDocumentAttachmentForOnboardedProductSubscriptionProcess, getOnboardedProductKYCDocumentAttachmentFileForDownload } from './service';
import { CreateInvestmentForSubscriptionProcessCommand, CreateOnboardedProductSubscriptionDocumentForSubscriptionProcessCommand, SubscriptionProcessStep, InvestmentProfile, GetInvestmentProfilesForInvestorQuery, ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand, GetInvestmentProfileQuery, InvestmentProfileDetails, GetOnboardedProductSubscriptionProcessQuery, SpecifyOnboardedProductSubscriptionInvestmentAmountCommand, SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand, ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand, CreateKYCDocumentAttachmentForOnboardedProductSubscriptionProcessCommand, DeleteOnboardedProductKYCDocumentAttachmentCommand, GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery, GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery, GetOnboardedProductSubscriptionDocumentFileForDownloadQuery, DeleteOnboardedProductSubscriptionDocumentCommand, ConfirmOnboardedProductSubscriptionCommand, OnboardedProductSubscriptionDocument, OnboardedProductSubscriptionProcess } from './types';

function calculateNextSubscriptionProcessStep(
  onboardedProductSubscriptionProcess: OnboardedProductSubscriptionProcess | null
): SubscriptionProcessStep {
  if (!onboardedProductSubscriptionProcess) {
    return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
  }

  if (onboardedProductSubscriptionProcess.isInvestmentProfileReviewed || onboardedProductSubscriptionProcess.isNaturalPersonSelected) {
    return SubscriptionProcessStep.REVIEW;
  }

  if (onboardedProductSubscriptionProcess.selectedInvestmentProfileId) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW;
  }

  if (onboardedProductSubscriptionProcess.investmentAmount) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION;
  }

  return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
}

interface GeneralPartnerOnboardedProductSubscriptionProcessDialogState {
  currentOnboardedProductSubscriptionProcessId: string | null;
  currentOnboardedProductSubscriptionProcess: OnboardedProductSubscriptionProcess | null;
  investmentProfiles: InvestmentProfile[];
  currentOnboardedProductSubscriptionProcessStep: SubscriptionProcessStep;
  currentInvestmentProfileId: string | null;
  currentInvestmentProfile: InvestmentProfileDetails | null;

  getOnboardedProductSubscriptionProcessStatus: ActionStatus;
  getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus;
  getOnboardedProductSubscriptionDocumentFileForDownloadStatus: ActionStatus;
  specifyOnboardedProductSubscriptionInvestmentAmountStatus: ActionStatus;
  selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus: ActionStatus;
  createOnboardedProductSubscriptionDocumentsStatus: ActionStatus;
  confirmOnboardedProductSubscriptionStatus: ActionStatus;
  createInvestmentForSubscriptionProcessStatus: ActionStatus;
  reopenSubscriptionProcessStatus: ActionStatus;
  cancelSubscriptionProcessStatus: ActionStatus;
  deleteOnboardedProductSubscriptionDocumentStatus: ActionStatus;
  getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus: ActionStatus;
  createOnboardedProductKYCDocumentAttachmentsStatus: ActionStatus;
  deleteOnboardedProductKYCDocumentAttachmentStatus: ActionStatus;
  getInvestmentProfilesStatus: ActionStatus;
  confirmSelectedInvestmentProfileForSubscriptionProcessStatus: ActionStatus;
  getCurrentInvestmentProfileStatus: ActionStatus;
  continueOnboardedProductSubscriptionProcessAsNaturalPersonStatus: ActionStatus;
}

function initialState(): GeneralPartnerOnboardedProductSubscriptionProcessDialogState {
  return {
    currentOnboardedProductSubscriptionProcessId: null,
    currentOnboardedProductSubscriptionProcess: null,
    investmentProfiles: [],
    currentOnboardedProductSubscriptionProcessStep: SubscriptionProcessStep.AMOUNT_SPECIFICATION,
    currentInvestmentProfileId: null,
    currentInvestmentProfile: null,

    getOnboardedProductSubscriptionProcessStatus: ActionStatus.None,
    getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus.None,
    getOnboardedProductSubscriptionDocumentFileForDownloadStatus: ActionStatus.None,
    specifyOnboardedProductSubscriptionInvestmentAmountStatus: ActionStatus.None,
    selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus: ActionStatus.None,
    createOnboardedProductSubscriptionDocumentsStatus: ActionStatus.None,
    confirmOnboardedProductSubscriptionStatus: ActionStatus.None,
    createInvestmentForSubscriptionProcessStatus: ActionStatus.None,
    reopenSubscriptionProcessStatus: ActionStatus.None,
    cancelSubscriptionProcessStatus: ActionStatus.None,
    deleteOnboardedProductSubscriptionDocumentStatus: ActionStatus.None,
    getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus: ActionStatus.None,
    createOnboardedProductKYCDocumentAttachmentsStatus: ActionStatus.None,
    deleteOnboardedProductKYCDocumentAttachmentStatus: ActionStatus.None,
    getInvestmentProfilesStatus: ActionStatus.None,
    confirmSelectedInvestmentProfileForSubscriptionProcessStatus: ActionStatus.None,
    getCurrentInvestmentProfileStatus: ActionStatus.None,
    continueOnboardedProductSubscriptionProcessAsNaturalPersonStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerOnboardedProductSubscriptionProcessDialogStore = defineStore(
  'generalPartnerOnboardedProductSubscriptionProcessDialog',
  {
    state: (): GeneralPartnerOnboardedProductSubscriptionProcessDialogState => initialState(),
    getters: {
      isGetOnboardedProductSubscriptionProcessProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.getOnboardedProductSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetOnboardedProductSubscriptionDocumentTemplateFileForDownloadProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus === ActionStatus.InProgress,
      isGetOnboardedProductSubscriptionDocumentFileForDownloadProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.getOnboardedProductSubscriptionDocumentFileForDownloadStatus === ActionStatus.InProgress,
      isSpecifyOnboardedProductSubscriptionInvestmentAmountProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.specifyOnboardedProductSubscriptionInvestmentAmountStatus === ActionStatus.InProgress,
      isSelectInvestmentProfileForOnboardedProductSubscriptionProcessProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus === ActionStatus.InProgress,
      isCreateOnboardedProductSubscriptionDocumentsProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.createOnboardedProductSubscriptionDocumentsStatus === ActionStatus.InProgress,
      isConfirmOnboardedProductSubscriptionProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.confirmOnboardedProductSubscriptionStatus === ActionStatus.InProgress,
      isCreateInvestmentForSubscriptionProcessProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.createInvestmentForSubscriptionProcessStatus === ActionStatus.InProgress,
      isReopenSubscriptionProcessProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.reopenSubscriptionProcessStatus === ActionStatus.InProgress,
      isCancelSubscriptionProcessProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.cancelSubscriptionProcessStatus === ActionStatus.InProgress,
      isDeleteOnboardedProductSubscriptionDocumentProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.deleteOnboardedProductSubscriptionDocumentStatus === ActionStatus.InProgress,
      isGetOnboardedProductKYCDocumentAttachmentFileForDownloadProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus === ActionStatus.InProgress,
      isCreateOnboardedProductKYCDocumentAttachmentsProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.createOnboardedProductKYCDocumentAttachmentsStatus === ActionStatus.InProgress,
      isDeleteOnboardedProductKYCDocumentAttachmentProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.deleteOnboardedProductKYCDocumentAttachmentStatus === ActionStatus.InProgress,
      isGetInvestmentProfilesProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.getInvestmentProfilesStatus === ActionStatus.InProgress,
      isConfirmSelectedInvestmentProfileForSubscriptionProcessProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.confirmSelectedInvestmentProfileForSubscriptionProcessStatus === ActionStatus.InProgress,
      isGetCurrentInvestmentProfileProcessing: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.getCurrentInvestmentProfileStatus === ActionStatus.InProgress,
      isContinueOnboardedProductSubscriptionProcessAsNaturalPersonProcessing: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        state.continueOnboardedProductSubscriptionProcessAsNaturalPersonStatus === ActionStatus.InProgress,

      currentOnboardedProductSubscriptionProcessHasSubscriptionDocuments: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        !!state.currentOnboardedProductSubscriptionProcess
          && state.currentOnboardedProductSubscriptionProcess.onboardedProductSubscriptionDocuments.length > 0,
      onboardedProductSubscriptionDocuments: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): OnboardedProductSubscriptionDocument[] =>
        state.currentOnboardedProductSubscriptionProcess?.onboardedProductSubscriptionDocuments ?? [],
      hasSubscriptionDocumentTemplates: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentOnboardedProductSubscriptionProcess
          && !!state.currentOnboardedProductSubscriptionProcess.onboardedProductSubscriptionDocumentTemplates
          && state.currentOnboardedProductSubscriptionProcess.onboardedProductSubscriptionDocumentTemplates.length > 0,
      currencySymbol: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): string =>
        state.currentOnboardedProductSubscriptionProcess
          && CurrencySymbols[state.currentOnboardedProductSubscriptionProcess.productCurrency]
          || '',
      completedInvestmentProfiles: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): InvestmentProfile[] =>
        state.investmentProfiles
          .filter((investmentProfile: InvestmentProfile) => investmentProfile.isComplete),
      hasCompletedInvestmentProfiles: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.investmentProfiles
          .filter((investmentProfile: InvestmentProfile) => investmentProfile.isComplete)
          .length > 0,
      isNaturalPersonSubscriptionProcess: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.currentOnboardedProductSubscriptionProcess?.isNaturalPersonSelected ?? false,
      nextStep: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): SubscriptionProcessStep => {
        if (!state.currentOnboardedProductSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled) {
          return SubscriptionProcessStep.REVIEW;
        }

        if (state.currentOnboardedProductSubscriptionProcess?.investorHasKYCAccess) {
          return SubscriptionProcessStep.KYC_DOCUMENT_ATTACHMENTS;
        }

        return SubscriptionProcessStep.SUBSCRIPTION_DOCUMENTS;
      },
      currentOnboardedProductSubscriptionProcessHasKYCDocumentAttachments: (
        state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState
      ): boolean =>
        !!state.currentOnboardedProductSubscriptionProcess
          && state.currentOnboardedProductSubscriptionProcess.onboardedProductKYCDocumentAttachments.length > 0,
      isSubscriptionProcessInReview: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentOnboardedProductSubscriptionProcess
          && state.currentOnboardedProductSubscriptionProcess.status === SubscriptionProcessStatuses.IN_REVIEW,
      isInvestmentAmountSpecified: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentOnboardedProductSubscriptionProcess?.investmentAmount ?? false,
      productCurrency: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): string =>
        state.currentOnboardedProductSubscriptionProcess
          && CurrencySymbols[state.currentOnboardedProductSubscriptionProcess.productCurrency]
          || '',
      isSubscriptionProcessAvailable: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentOnboardedProductSubscriptionProcessId
          && !!state.currentOnboardedProductSubscriptionProcess,
      stepNumberForSubscriptionDocumentsStep: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): number =>
        state.currentOnboardedProductSubscriptionProcess?.investorHasKYCAccess
          ? 5
          : 4,
      stepNumberForConfirmSubscriptionStep: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): number => {
        if (!(state.currentOnboardedProductSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled ?? false)) {
          return 4;
        }

        return state.currentOnboardedProductSubscriptionProcess?.investorHasKYCAccess
          ? 6
          : 5;
      },
      isDocumentManagementForSubscriptionProcessEnabled: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.currentOnboardedProductSubscriptionProcess?.isDocumentManagementForSubscriptionProcessEnabled ?? false,
      hasInvestorKYCAccess: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        state.currentOnboardedProductSubscriptionProcess?.investorHasKYCAccess ?? false,
      isInvestmentAmountDefined: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): boolean =>
        !!state.currentOnboardedProductSubscriptionProcess?.investmentAmount,
      formattedInvestmentAmount: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): string =>
        state.currentOnboardedProductSubscriptionProcess
          && state.currentOnboardedProductSubscriptionProcess.investmentAmount
          ? formatCurrencyWithoutCentDigits(
            state.currentOnboardedProductSubscriptionProcess.investmentAmount,
            state.currentOnboardedProductSubscriptionProcess.productCurrency
          )
          : '',
      nextSubscriptionProcessStep: (state: GeneralPartnerOnboardedProductSubscriptionProcessDialogState): SubscriptionProcessStep =>
        calculateNextSubscriptionProcessStep(state.currentOnboardedProductSubscriptionProcess),
    },
    actions: {

      // -- State management

      updateCurrentSubscriptionProcessId(currentOnboardedProductSubscriptionProcessId: string): Promise<void> {
        this.currentOnboardedProductSubscriptionProcessId = currentOnboardedProductSubscriptionProcessId;

        return Promise.resolve();
      },

      goToNextStep(): Promise<void> {
        return this.updateCurrentSubscriptionProcessStep(this.nextStep);
      },

      updateCurrentSubscriptionProcessStep(currentStep: SubscriptionProcessStep): Promise<void> {
        this.currentOnboardedProductSubscriptionProcessStep = currentStep;

        return Promise.resolve();
      },

      setCurrentInvestmentProfileId(id: string): Promise<void> {
        this.currentInvestmentProfileId = id;

        return Promise.resolve();
      },

      resetCurrentInvestmentProfile(): Promise<void> {
        this.currentInvestmentProfileId = null;
        this.currentInvestmentProfile = null;

        return Promise.resolve();
      },

      // -- Queries

      getOnboardedProductSubscriptionProcess(): Promise<void> {
        const query: GetOnboardedProductSubscriptionProcessQuery = {
          subscriptionProcessId: this.currentOnboardedProductSubscriptionProcessId!,
        };

        const { getOnboardedProductSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductSubscriptionProcessStatus,
          () => getOnboardedProductSubscriptionProcess(query)
            .then((onboardedProductSubscriptionProcess) => {
              this.currentOnboardedProductSubscriptionProcess = onboardedProductSubscriptionProcess;
              this.currentOnboardedProductSubscriptionProcessStep = calculateNextSubscriptionProcessStep(
                onboardedProductSubscriptionProcess
              );
            })
        );
      },

      getOnboardedProductSubscriptionDocumentTemplateFileForDownload(
        query: GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery
      ): Promise<FileResponse> {
        const { getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductSubscriptionDocumentTemplateFileForDownloadStatus,
          () => getOnboardedProductSubscriptionDocumentTemplateFileForDownload(query)
        );
      },

      getOnboardedProductSubscriptionDocumentFileForDownload(
        query: GetOnboardedProductSubscriptionDocumentFileForDownloadQuery
      ): Promise<FileResponse> {
        const { getOnboardedProductSubscriptionDocumentFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductSubscriptionDocumentFileForDownloadStatus,
          () => getOnboardedProductSubscriptionDocumentFileForDownload(query)
        );
      },

      getOnboardedProductKYCDocumentAttachmentFileForDownload(
        query: GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery
      ): Promise<FileResponse> {
        const { getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getOnboardedProductKYCDocumentAttachmentFileForDownloadStatus,
          () => getOnboardedProductKYCDocumentAttachmentFileForDownload(query)
        );
      },

      getInvestmentProfiles(query: GetInvestmentProfilesForInvestorQuery): Promise<void> {
        const { getInvestmentProfilesStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestmentProfilesStatus,
          () => getInvestmentProfiles(query)
            .then((investmentProfiles) => {
              this.investmentProfiles = investmentProfiles;
            })
        );
      },

      getCurrentInvestmentProfile(): Promise<void> {
        const query: GetInvestmentProfileQuery = {
          investmentProfileId: this.currentInvestmentProfileId!,
        };

        const { getCurrentInvestmentProfileStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getCurrentInvestmentProfileStatus,
          () => getInvestmentProfile(query)
            .then((investmentProfile) => {
              this.currentInvestmentProfile = investmentProfile;
            })
        );
      },

      // -- Commands

      specifyOnboardedProductSubscriptionInvestmentAmount(
        command: SpecifyOnboardedProductSubscriptionInvestmentAmountCommand
      ): Promise<void> {
        const { specifyOnboardedProductSubscriptionInvestmentAmountStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          specifyOnboardedProductSubscriptionInvestmentAmountStatus,
          () => specifyOnboardedProductSubscriptionInvestmentAmount(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

      createOnboardedProductSubscriptionDocuments(files: File[]): Promise<void[]> {
        const queuedFileUploads = files.map((file) => {
          const command: CreateOnboardedProductSubscriptionDocumentForSubscriptionProcessCommand = {
            body: {
              subscriptionProcessId: this.currentOnboardedProductSubscriptionProcessId!,
            },
            files: { file },
          };

          const payload: AddItemToUploadQueuePayload = {
            fileName: file.name,
            serviceFunction: createOnboardedProductSubscriptionDocumentForSubscriptionProcess,
            command,
          };

          return useFileUploadQueueStore().addItem(payload)
            .then(() => this.getOnboardedProductSubscriptionProcess());
        });

        const { createOnboardedProductSubscriptionDocumentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createOnboardedProductSubscriptionDocumentsStatus,
          () => Promise.all(queuedFileUploads)
        );
      },

      confirmOnboardedProductSubscription(command: ConfirmOnboardedProductSubscriptionCommand): Promise<void> {
        const { confirmOnboardedProductSubscriptionStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmOnboardedProductSubscriptionStatus,
          () => confirmOnboardedProductSubscription(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

      createInvestmentForSubscriptionProcess(command: CreateInvestmentForSubscriptionProcessCommand): Promise<void> {
        const { createInvestmentForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createInvestmentForSubscriptionProcessStatus,
          () => createInvestmentForSubscriptionProcess(command)
        );
      },

      createOnboardedProductKYCDocumentAttachments(files: File[]): Promise<void[]> {
        const queuedFileUploads = files.map((file) => {
          const command: CreateKYCDocumentAttachmentForOnboardedProductSubscriptionProcessCommand = {
            body: {
              subscriptionProcessId: this.currentOnboardedProductSubscriptionProcessId!,
            },
            files: { file },
          };

          const payload: AddItemToUploadQueuePayload = {
            fileName: file.name,
            serviceFunction: createKYCDocumentAttachmentForOnboardedProductSubscriptionProcess,
            command,
          };

          return useFileUploadQueueStore().addItem(payload)
            .then(() => this.getOnboardedProductSubscriptionProcess());
        });

        const { createOnboardedProductKYCDocumentAttachmentsStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createOnboardedProductKYCDocumentAttachmentsStatus,
          () => Promise.all(queuedFileUploads)
        );
      },

      deleteOnboardedProductSubscriptionDocument(command: DeleteOnboardedProductSubscriptionDocumentCommand): Promise<void> {
        const { deleteOnboardedProductSubscriptionDocumentStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteOnboardedProductSubscriptionDocumentStatus,
          () => deleteOnboardedProductSubscriptionDocument(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

      deleteOnboardedProductKYCDocumentAttachment(command: DeleteOnboardedProductKYCDocumentAttachmentCommand): Promise<void> {
        const { deleteOnboardedProductKYCDocumentAttachmentStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteOnboardedProductKYCDocumentAttachmentStatus,
          () => deleteOnboardedProductKYCDocumentAttachment(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

      selectInvestmentProfileForOnboardedProductSubscriptionProcess(
        command: SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand
      ): Promise<void> {
        const { selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          selectInvestmentProfileForOnboardedProductSubscriptionProcessStatus,
          () => selectInvestmentProfileForOnboardedProductSubscriptionProcess(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

      confirmSelectedInvestmentProfileForSubscriptionProcess(
        command: ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand
      ): Promise<void> {
        const { confirmSelectedInvestmentProfileForSubscriptionProcessStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          confirmSelectedInvestmentProfileForSubscriptionProcessStatus,
          () => confirmSelectedInvestmentProfileForSubscriptionProcess(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

      continueOnboardedProductSubscriptionProcessAsNaturalPerson(): Promise<void> {
        const command: ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand = {
          subscriptionProcessId: this.currentOnboardedProductSubscriptionProcessId!,
        };

        const { continueOnboardedProductSubscriptionProcessAsNaturalPersonStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          continueOnboardedProductSubscriptionProcessAsNaturalPersonStatus,
          () => continueOnboardedProductSubscriptionProcessAsNaturalPerson(command)
            .then(() => this.getOnboardedProductSubscriptionProcess())
        );
      },

    },
  }
);
