import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { Authentication } from '@/application/whitelabel/authentication/types';
import { ResendUserInviteCommand, WithdrawUserInviteCommand, UnlockUserCommand, GetInvestorUserAuthenticationQuery, LockUserCommand, DeleteUserCommand, UpdateInvestorUserMasterDataAndManagementAccessCommand, User, SubscriptionProcess, GetInvestorDetailsQuery, InvestorDetails, GetInvestmentsForInvestorQuery, Investment, Product, CreateInvestmentForInvestorCommand, UpdateInvestmentForInvestorCommand, DeleteInvestmentForInvestorCommand, GetSubscriptionProcessesForInvestorQuery, GetUsersForInvestorQuery, GetInvestorContactsQuery, InvestorContact, UpdateInvestorContactCommand, DeleteInvestorContactCommand, CreateInvestorContactCommand, StartSubscriptionProcessForInvestorCommand, GetInvestorActivityQuery, GetAccessiblePublishedProductsForInvestorQuery, UserActivityLogEntries } from './types';

// -- Queries

export function getInvestorDetails(query: GetInvestorDetailsQuery): Promise<InvestorDetails> {
  const url = generateApiUrl('/api/general-partner/get-investor-details-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestmentsForInvestor(query: GetInvestmentsForInvestorQuery): Promise<Investment[]> {
  const url = generateApiUrl('/api/general-partner/get-investments-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionProcessesForInvestor(query: GetSubscriptionProcessesForInvestorQuery): Promise<SubscriptionProcess[]> {
  const url = generateApiUrl('/api/general-partner/get-subscription-processes-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getUsersForInvestor(query: GetUsersForInvestorQuery): Promise<User[]> {
  const url = generateApiUrl('/api/general-partner/get-users-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestorUserAuthentication(query: GetInvestorUserAuthenticationQuery): Promise<Authentication> {
  const url = generateApiUrl(`/api/general-partner/get-investor-user-authentication-query`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestorContacts(query: GetInvestorContactsQuery): Promise<InvestorContact[]> {
  const url = generateApiUrl('/api/general-partner/get-investor-contacts-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestorActivity(query: GetInvestorActivityQuery): Promise<UserActivityLogEntries> {
  const url = generateApiUrl('/api/general-partner/get-investor-activity-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getAccessiblePublishedProductsForInvestor(query: GetAccessiblePublishedProductsForInvestorQuery): Promise<Product[]> {
  const url = generateApiUrl('/api/general-partner/get-accessible-published-products-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

// -- Commands

export function createInvestmentForInvestor(command: CreateInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentForInvestor(command: UpdateInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteInvestmentForInvestor(command: DeleteInvestmentForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-investment-for-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestorUserMasterDataAndManagementAccess(
  command: UpdateInvestorUserMasterDataAndManagementAccessCommand
): Promise<void> {
  const url = generateApiUrl(`/api/general-partner/update-investor-user-master-data-and-management-access-command`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteUser(command: DeleteUserCommand): Promise<void> {
  const url = generateApiUrl(`/api/general-partner/delete-user-command`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function lockUser(command: LockUserCommand): Promise<void> {
  const url = generateApiUrl(`/api/general-partner/lock-user-command`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function unlockUser(command: UnlockUserCommand): Promise<void> {
  const url = generateApiUrl(`/api/general-partner/unlock-user-command`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function resendUserInvite(command: ResendUserInviteCommand): Promise<void> {
  const url = generateApiUrl(`/api/general-partner/resend-user-invite-command`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function withdrawUserInvite(command: WithdrawUserInviteCommand): Promise<void> {
  const url = generateApiUrl(`/api/general-partner/withdraw-user-invite-command`);
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createInvestorContact(command: CreateInvestorContactCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-investor-contact-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestorContact(command: UpdateInvestorContactCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investor-contact-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteInvestorContact(command: DeleteInvestorContactCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-investor-contact-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function startSubscriptionProcessForInvestor(command: StartSubscriptionProcessForInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/start-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
