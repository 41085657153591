/* eslint-disable @typescript-eslint/no-empty-function */
import { LOCAL_STORAGE_BRAINIAC_ID, LOCAL_STORAGE_USER_ID, removeLocalStorageItem } from '@/helpers/local-storage-helper';
import { useAppStore } from '@/application/common/app/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useInvestorProductComparisonSelectMenuStore } from '@/domain/investor/products/product-comparison-select-menu/store';
import { useInvestorProductsStore } from '@/domain/investor/products/products/store';
import { useBrainAuthenticationStore } from '@/application/brain/authentication/store';
import { useInvestorAdvisorInfoStore } from '@/domain/investor/advisor-info/store';
import { useInvestorProductStore } from '@/domain/investor/products/product/store';
import { useInvestorCreateAccountStore } from '@/domain/investor/create-account/store';
import { useInvestorKYCDocumentsStore } from '@/domain/investor/kyc-management/store';
import { useGeneralPartnerUserAccountCreationStore } from '@/domain/general-partner/create-account/store';
import { useGeneralPartnerGlobalSearchStore } from '@/domain/general-partner/global-search/store';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { useGeneralPartnerInvestmentDocumentsStore } from '@/domain/general-partner/investments/investment-documents-dialog/store';
import { useGeneralPartnerInvestmentsStore } from '@/domain/general-partner/investments/investments-tabs/store';
import { useGeneralPartnerOnboardedProductSubscriptionProcessDialogStore } from '@/domain/general-partner/investments/onboarded-product-subscription-process-dialog/store';
import { useInvestorOnboardedProductSubscriptionProcessDialogStore } from '@/domain/investor/products/onboarded-product-subscription-process-dialog/store';
import { useInvestorProductComparisonStore } from '@/domain/investor/products/product-comparison/store';
import { useInvestorDashboardStore } from '@/domain/investor/dashboard/store';
import { useInvestorInvestmentProfilesStore } from '@/domain/investor/investment-profiles/store';
import { useGeneralPartnerSubscriptionProcessDialogStore } from '@/domain/general-partner/investments/product-subscription-process-dialog/store';
import { useInvestorSubscriptionProcessDialogStore } from '@/domain/investor/products/product-subscription-process-dialog/store';
import { useGeneralPartnerInvestmentsOfInvestorForOnboardedProductStore } from '@/domain/general-partner/investors/investments-of-investor-for-onboarded-product/store';
import { useProfileStore } from '@/domain/common/profile/store';
import { useGeneralPartnerInvestmentsOfInvestorForProductStore } from '@/domain/general-partner/investors/investments-of-investor-for-product/store';
import { useImpersonationHintStore } from '@/application/whitelabel/app/components/impersonation-hint/store';
import { useInAppNotificationsStore } from '@/application/whitelabel/in-app-notifications/store';
import { useSnackbarStore } from '@/application/common/snackbar/store';
import { useGeneralPartnerInvestorDetailsStore } from '@/domain/general-partner/investors/investor-details/store';
import { useRefreshedSessionStore } from '@/application/common/refreshed-session-dialog/store';
import { useGeneralPartnerInvestorsOverviewStore } from '@/domain/general-partner/investors/investors-overview/store';
import { useBrainPlatformNewsStore } from '@/domain/brain/platform-news/store';
import { useGeneralPartnerUpdateInvestorDetailsStore } from '@/domain/general-partner/investors/update-investor-details-dialog/store';
import { useGeneralPartnerProductFromNetworkStore } from '@/domain/general-partner/network/product/store';
import { useGeneralPartnerProductsFromNetworkStore } from '@/domain/general-partner/network/products/store';
import { useGeneralPartnerProductFromNetworkDocumentsTabStore } from '@/domain/general-partner/network/product/components/product-documents-tab/store';
import { useBrainGeneralPartnerOverviewStore } from '@/domain/brain/general-partners/general-partner-overview/store';
import { useInvestorDueDiligenceDataRoomStore } from '@/domain/investor/products/product/components/product-due-diligence-data-room-dialog/store';
import { useBrainGeneralPartnerCreationStore } from '@/domain/brain/general-partners/create-general-partner/store';
import { useGeneralPartnerProductCreationStore } from '@/domain/general-partner/products/create-product/store';
import { useGeneralPartnerProductRestrictionHintStore } from '@/domain/general-partner/product-restriction-hint/store';
import { useGeneralPartnerOnboardedProductFromNetworkStore } from '@/domain/general-partner/products/onboarded-product-from-network/store';
import { useGeneralPartnerProductManagementGeneralStore } from '@/domain/general-partner/products/product-management/store';
import { useGeneralPartnerProductManagementAboutTabStore } from '@/domain/general-partner/products/product-management/components/product-about-tab/store';
import { useGeneralPartnerProductManagementAnalyticsTabStore } from '@/domain/general-partner/products/product-management/components/product-analytics-tab/store';
import { useGeneralPartnerOnboardedProductFromNetworkDocumentsTabStore } from '@/domain/general-partner/products/onboarded-product-from-network/components/onboarded-product-documents-tab/store';
import { useGeneralPartnerProductManagementDetailsTabStore } from '@/domain/general-partner/products/product-management/components/product-details-tab/store';
import { useGeneralPartnerProductManagementDocumentsTabStore } from '@/domain/general-partner/products/product-management/components/product-documents-tab/store';
import { useGeneralPartnerProductManagementNewsTabStore } from '@/domain/general-partner/products/product-management/components/product-news-tab/store';
import { useGeneralPartnerProductManagementSubscriptionTab } from '@/domain/general-partner/products/product-management/components/subscription-tab/store';
import { useGeneralPartnerProductManagementReportingTabStore } from '@/domain/general-partner/products/product-management/components/product-reporting-tab/store';
import { useGeneralPartnerUserDetailsStore } from '@/domain/general-partner/users/user-details/store';
import { useGeneralPartnerReferencesStore } from '@/domain/general-partner/references/store';
import { useGeneralPartnerUploadImageStore } from '@/domain/general-partner/users/update-user-image-dialog/store';
import { useGeneralPartnerUsersStore } from '@/domain/general-partner/users/users-tabs/store';

// General Partner state and general partner id does never get cleared
export function clearAllStates(): void {
  // Common
  useSnackbarStore().$reset();
  useRefreshedSessionStore().$reset();

  // Whitelabel
  useAppStore().partialReset().catch(() => {});
  useImpersonationHintStore().$reset();
  useAuthenticationStore().partialReset().catch(() => {});
  useFileUploadQueueStore().$reset();
  useInAppNotificationsStore().$reset();
  useProfileStore().$reset();
  useGeneralPartnerSubscriptionProcessDialogStore().$reset();
  useGeneralPartnerGlobalSearchStore().$reset();
  useGeneralPartnerUserAccountCreationStore().$reset();
  useGeneralPartnerProductCreationStore().$reset();
  useGeneralPartnerProductManagementGeneralStore().$reset();
  useGeneralPartnerProductManagementAboutTabStore().$reset();
  useGeneralPartnerProductManagementAnalyticsTabStore().$reset();
  useGeneralPartnerProductManagementDetailsTabStore().$reset();
  useGeneralPartnerProductManagementDocumentsTabStore().$reset();
  useGeneralPartnerProductManagementNewsTabStore().$reset();
  useGeneralPartnerProductManagementReportingTabStore().$reset();
  useGeneralPartnerProductManagementSubscriptionTab().$reset();
  useGeneralPartnerUserDetailsStore().$reset();
  useInvestorDueDiligenceDataRoomStore().$reset();
  useGeneralPartnerInvestmentsStore().$reset();
  useGeneralPartnerInvestorDetailsStore().$reset();
  useGeneralPartnerInvestorsOverviewStore().$reset();
  useGeneralPartnerUpdateInvestorDetailsStore().$reset();
  useGeneralPartnerReferencesStore().$reset();
  useGeneralPartnerUsersStore().$reset();
  useGeneralPartnerUploadImageStore().$reset();
  useGeneralPartnerInvestmentDocumentsStore().$reset();
  useGeneralPartnerProductRestrictionHintStore().$reset();
  useGeneralPartnerProductsFromNetworkStore().$reset();
  useGeneralPartnerProductFromNetworkStore().$reset();
  useGeneralPartnerProductFromNetworkDocumentsTabStore().$reset();
  useGeneralPartnerOnboardedProductFromNetworkStore().$reset();
  useGeneralPartnerOnboardedProductFromNetworkDocumentsTabStore().$reset();
  useInvestorAdvisorInfoStore().$reset();
  useInvestorCreateAccountStore().$reset();
  useInvestorDashboardStore().$reset();
  useInvestorProductComparisonStore().$reset();
  useInvestorProductComparisonSelectMenuStore().$reset();
  useInvestorProductStore().$reset();
  useInvestorSubscriptionProcessDialogStore().$reset();
  useInvestorProductsStore().$reset();
  useGeneralPartnerInvestmentsOfInvestorForProductStore().$reset();
  useInvestorKYCDocumentsStore().$reset();
  useInvestorInvestmentProfilesStore().$reset();
  useGeneralPartnerInvestmentsOfInvestorForOnboardedProductStore().$reset();
  useGeneralPartnerOnboardedProductSubscriptionProcessDialogStore().$reset();
  useInvestorOnboardedProductSubscriptionProcessDialogStore().$reset();

  removeLocalStorageItem(LOCAL_STORAGE_USER_ID);

  // Brain
  useBrainAuthenticationStore().partialReset().catch(() => {});
  useBrainGeneralPartnerOverviewStore().$reset();
  useBrainPlatformNewsStore().$reset();
  useBrainGeneralPartnerCreationStore().$reset();

  removeLocalStorageItem(LOCAL_STORAGE_BRAINIAC_ID);
}
