import { generateApiUrl, performApiRequestWithPreviousCancellation } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { SearchResults, GlobalSearchQuery } from './types';

export function search(query: GlobalSearchQuery): Promise<SearchResults> {
  const url = generateApiUrl('/api/general-partner/get-global-search-results-query');
  return performApiRequestWithPreviousCancellation({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUser(query),
  });
}
