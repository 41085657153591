import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { Authentication } from '@/application/whitelabel/authentication/types';
import { User, InviteGeneralPartnerUserCommand, UserInvite, ResendUserInviteCommand, WithdrawUserInviteCommand, InviteInvestorUserCommand, UpdateGeneralPartnerPermissionGroupForUserCommand, UpdateInvestorUserMasterDataAndManagementAccessCommand, DeleteUserCommand, LockUserCommand, UnlockUserCommand, UpdateGeneralPartnerUserMasterDataCommand, GeneralPartnerPermissionGroup, CreateGeneralPartnerPermissionGroupCommand, GetInvestorUserAuthenticationQuery, UpdateGeneralPartnerPermissionGroupCommand, DeleteGeneralPartnerPermissionGroupCommand, UpdateGeneralPartnerUserProductAccessCommand } from './types';

export function getUsers(): Promise<User[]> {
  const url = generateApiUrl('/api/general-partner/get-users-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function inviteGeneralPartnerUser(command: InviteGeneralPartnerUserCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/invite-general-partner-user-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function inviteInvestorUser(command: InviteInvestorUserCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/invite-investor-user-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestorUserMasterDataAndManagementAccess(
  command: UpdateInvestorUserMasterDataAndManagementAccessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investor-user-master-data-and-management-access-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateGeneralPartnerUserMasterData(command: UpdateGeneralPartnerUserMasterDataCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-general-partner-user-master-data-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getUserInvites(): Promise<UserInvite[]> {
  const url = generateApiUrl('/api/general-partner/get-user-invites-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function resendUserInvite(command: ResendUserInviteCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/resend-user-invite-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function withdrawUserInvite(command: WithdrawUserInviteCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/withdraw-user-invite-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateGeneralPartnerPermissionGroupForUser(command: UpdateGeneralPartnerPermissionGroupForUserCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-general-partner-permission-group-for-user-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestorUserAuthentication(query: GetInvestorUserAuthenticationQuery): Promise<Authentication> {
  const url = generateApiUrl('/api/general-partner/get-investor-user-authentication-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function deleteUser(command: DeleteUserCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-user-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function lockUser(command: LockUserCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/lock-user-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function unlockUser(command: UnlockUserCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/unlock-user-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getGeneralPartnerPermissionGroups(): Promise<GeneralPartnerPermissionGroup[]> {
  const url = generateApiUrl('/api/general-partner/get-general-partner-permission-groups-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function createGeneralPartnerPermissionGroup(command: CreateGeneralPartnerPermissionGroupCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-general-partner-permission-group-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateGeneralPartnerPermissionGroup(command: UpdateGeneralPartnerPermissionGroupCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-general-partner-permission-group-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteGeneralPartnerPermissionGroup(command: DeleteGeneralPartnerPermissionGroupCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-general-partner-permission-group-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateGeneralPartnerUserProductAccess(command: UpdateGeneralPartnerUserProductAccessCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-general-partner-user-product-access-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
