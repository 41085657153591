import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { CreateProductCommand, GetTermSheetTemplateForAssetClassQuery, TermSheetTemplate } from './types';

export function getTermSheetTemplateForAssetClass(query: GetTermSheetTemplateForAssetClassQuery): Promise<TermSheetTemplate> {
  const url = generateApiUrl('/api/general-partner/get-term-sheet-template-for-asset-class-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function createProduct(command: CreateProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
