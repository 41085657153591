import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartner } from '@/helpers/default-parameter-helper';
import { CreateGeneralPartnerUserAccountCommand, GeneralPartnerUserInvite, GetGeneralPartnerUserInviteByTokenQuery } from './types';

export function getGeneralPartnerUserInviteByToken(query: GetGeneralPartnerUserInviteByTokenQuery): Promise<GeneralPartnerUserInvite> {
  const url = generateApiUrl('/api/unauthorized/general-partner/get-user-invite-by-token-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(query) });
}

export function createGeneralPartnerUserAccount(command: CreateGeneralPartnerUserAccountCommand): Promise<void> {
  const url = generateApiUrl('/api/unauthorized/general-partner/create-user-account-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(command) });
}
