import Vue, { PluginObject } from 'vue';
import { isValueAvailable } from '@/helpers/value-helpers';

const GlobalTemplateHelpers: PluginObject<boolean> = {
  install: (VueInstance) => {
    VueInstance.prototype.$isValueAvailable = isValueAvailable;
  },
};

Vue.use(GlobalTemplateHelpers);
