import { performApiRequest, generateApiUrl, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachBrainiac, attachBrainiacToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery, AddDocumentWithPlaceholdersToControllingPersonTemplatesToPrefillCommand, ControllingPersonTemplateToPrefill, GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery, ReleaseControllingPersonTemplateToPrefillCommand, SubscriptionAgreementTemplateToPrefill, ReleaseSubscriptionAgreementTemplateToPrefillCommand, AddDocumentWithPlaceholdersToSubscriptionAgreementTemplatesToPrefillCommand, GetSamplePrefilledSubscriptionAgreementTemplateFileForDownloadQuery, GetSamplePrefilledControllingPersonTemplateFileForDownloadQuery } from './types';

// -- Queries

export function getSubscriptionAgreementTemplatesToPrefill(): Promise<SubscriptionAgreementTemplateToPrefill[]> {
  const url = generateApiUrl('/brain-api/get-subscription-agreement-templates-to-prefill-query');
  return performApiRequest<SubscriptionAgreementTemplateToPrefill[]>({ url, method: 'POST', data: attachBrainiac({}) });
}

export function getControllingPersonTemplatesToPrefill(): Promise<ControllingPersonTemplateToPrefill[]> {
  const url = generateApiUrl('/brain-api/get-controlling-person-templates-to-prefill-query');
  return performApiRequest<ControllingPersonTemplateToPrefill[]>({ url, method: 'POST', data: attachBrainiac({}) });
}

export function getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload(
  query: GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/brain-api/get-subscription-agreement-template-to-prefill-original-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachBrainiac(query) });
}

export function getControllingPersonTemplateToPrefillOriginalFileForDownload(
  query: GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/brain-api/get-controlling-person-template-to-prefill-original-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachBrainiac(query) });
}

export function getSamplePrefilledSubscriptionAgreementTemplateFileForDownload(
  query: GetSamplePrefilledSubscriptionAgreementTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/brain-api/get-sample-prefilled-subscription-agreement-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachBrainiac(query) });
}

export function getSamplePrefilledControllingPersonTemplateFileForDownload(
  query: GetSamplePrefilledControllingPersonTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/brain-api/get-sample-prefilled-controlling-person-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachBrainiac(query) });
}

// -- Commands

export function addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefill(
  command: AddDocumentWithPlaceholdersToSubscriptionAgreementTemplatesToPrefillCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/add-document-with-placeholders-to-subscription-agreement-template-to-prefill-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachBrainiacToCommandWithFiles(command) });
}

export function addDocumentWithPlaceholdersToControllingPersonTemplateToPrefill(
  command: AddDocumentWithPlaceholdersToControllingPersonTemplatesToPrefillCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/add-document-with-placeholders-to-controlling-person-template-to-prefill-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachBrainiacToCommandWithFiles(command) });
}

export function releaseSubscriptionAgreementTemplateToPrefill(
  command: ReleaseSubscriptionAgreementTemplateToPrefillCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/release-subscription-agreement-template-to-prefill-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function releaseControllingPersonTemplateToPrefill(command: ReleaseControllingPersonTemplateToPrefillCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/release-controlling-person-template-to-prefill-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
