var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-8",attrs:{"data-cy":"product-activity-tab"}},[_c('a-headline',{attrs:{"small":"","title":_vm.translate('title')}}),_c('v-data-table',{class:{ 'no-data': _vm.productActivityTabStore.productActivityLogEntriesTotalCount === 0 },attrs:{"disable-filtering":"","disable-sort":"","disable-items-per-page":"","no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.productActivityTabStore.productUserActivityLogEntries,"server-items-length":_vm.productActivityTabStore.productActivityLogEntriesTotalCount,"options":_vm.tableOptions,"item-key":"id","loading":_vm.productActivityTabStore.isGetUserActivityLogEntriesForProductProcessing,"mobile-breakpoint":960},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.accountType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.accountTypeLabel(item))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.user.name.firstName)+" "+_vm._s(item.user.name.lastName)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(_vm.userActivityLogEntryDescription(item))}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }