import { render, staticRenderFns } from "./investor-details-tabs.vue?vue&type=template&id=73d7dd59&"
import script from "./investor-details-tabs.vue?vue&type=script&lang=ts&"
export * from "./investor-details-tabs.vue?vue&type=script&lang=ts&"
import style0 from "./investor-details-tabs.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VContainer,VTab,VTabs,VTabsItems})
