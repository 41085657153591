import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { Product } from './types';
import { getProducts } from './service';

interface GeneralPartnerProductsFromNetworkState {
  products: Product[];

  getProductsStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductsFromNetworkState {
  return {
    products: [],

    getProductsStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductsFromNetworkStore = defineStore('generalPartnerProductsFromNetwork', {
  state: (): GeneralPartnerProductsFromNetworkState => initialState(),
  getters: {
    isGetProductsProcessing: (state: GeneralPartnerProductsFromNetworkState): boolean =>
      state.getProductsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getProducts(): Promise<void> {
      const { getProductsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductsStatus,
        () => getProducts()
          .then((products) => {
            this.products = products;
          })
      );
    },

  },
});
