import { render, staticRenderFns } from "./onboarded-product-kyc-document-attachments.vue?vue&type=template&id=e1e84f36&scoped=true&"
import script from "./onboarded-product-kyc-document-attachments.vue?vue&type=script&lang=ts&"
export * from "./onboarded-product-kyc-document-attachments.vue?vue&type=script&lang=ts&"
import style0 from "./onboarded-product-kyc-document-attachments.vue?vue&type=style&index=0&id=e1e84f36&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1e84f36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VDivider})
