import axios, { AxiosRequestConfig } from 'axios';
import { useAppStore } from '@/application/common/app/store';

function addDefaultLanguageHeader(request: AxiosRequestConfig) {
  request.headers = {
    ...request.headers,
    'X-FALLBACK-LANGUAGE': useAppStore().selectedLanguage!,
  };
  return request;
}

export default function initDefaultLanguageInterceptor() {
  axios.interceptors.request.use(
    addDefaultLanguageHeader,
    (error) => Promise.reject(error)
  );
}
