import { FileResponse } from '@/application/types';
import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { CreateKYCDocumentCommand, DeleteKYCDocumentCommand, GetKYCDocumentFileForDownloadQuery, KYCDocument } from './types';

// -- Commands

export function createKYCDocument(command: CreateKYCDocumentCommand, onUploadProgress: (progressEvent: any) => void): Promise<void> {
  const url = generateApiUrl('/api/investor/create-kyc-document-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteKYCDocument(command: DeleteKYCDocumentCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/delete-kyc-document-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

// -- Queries

export function getKYCDocuments(): Promise<KYCDocument[]> {
  const url = generateApiUrl('/api/investor/get-kyc-documents-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getKYCDocumentFileForDownload(query: GetKYCDocumentFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-kyc-document-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
