import { render, staticRenderFns } from "./advisor-info.vue?vue&type=template&id=e35beb9a&scoped=true&"
import script from "./advisor-info.vue?vue&type=script&lang=ts&"
export * from "./advisor-info.vue?vue&type=script&lang=ts&"
import style0 from "./advisor-info.vue?vue&type=style&index=0&id=e35beb9a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e35beb9a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VCard,VMenu})
