import Vue from 'vue';
import { countrySelectItems } from '@/helpers/form-helpers';

export function countryName(shortCode: string): string {
  const country = countrySelectItems().find((language) => language.value === shortCode);
  if (!country) {
    return shortCode;
  }

  return country.text.toString();
}

Vue.filter('countryName', countryName);
