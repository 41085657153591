import { RouteConfig } from 'vue-router';
import Imprint from './components/imprint.vue';
import DataProtection from './components/data-protection.vue';

export const legalRoutes: RouteConfig[] = [
  {
    name: 'legal/data-protection',
    path: '/data-protection',
    component: DataProtection,
    meta: {
      alwaysAccessible: true,
    },
  },
  {
    name: 'legal/imprint',
    path: '/imprint',
    component: Imprint,
    meta: {
      alwaysAccessible: true,
    },
  },
  {
    name: 'legal/data-protection-localized',
    component: DataProtection,
    path: '/data-protection/:language',
    meta: {
      alwaysAccessible: true,
    },
  },
  {
    name: 'legal/imprint-localized',
    path: '/imprint/:language',
    component: Imprint,
    meta: {
      alwaysAccessible: true,
    },
  },
];
