import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { ConfirmSharedProductCommand, SharedProduct, SharedProductsStates } from '@/domain/brain/network-products/types';
import { confirmSharedProduct, getSharedProducts } from '@/domain/brain/network-products/service';

interface BrainNetworkProductsState {
  sharedProducts: SharedProduct[];

  getSharedProductsStatus: ActionStatus;
  confirmSharedProductStatus: ActionStatus;
}

function initialState(): BrainNetworkProductsState {
  return {
    sharedProducts: [],

    getSharedProductsStatus: ActionStatus.None,
    confirmSharedProductStatus: ActionStatus.None,
  };
}

export const useBrainNetworkProductsStore = defineStore('brainNetworkProducts', {
  state: (): BrainNetworkProductsState => initialState(),
  getters: {
    isGetSharedProductsProcessing: (state: BrainNetworkProductsState): boolean =>
      state.getSharedProductsStatus === ActionStatus.InProgress,
    isConfirmSharedProductProcessing: (state: BrainNetworkProductsState): boolean =>
      state.confirmSharedProductStatus === ActionStatus.InProgress,
    confirmedSharedProducts: (state: BrainNetworkProductsState): SharedProduct[] =>
      state.sharedProducts
        .filter((sharedProduct: SharedProduct) => sharedProduct.productSharingStatus === SharedProductsStates.CONFIRMED),
    pendingSharedProducts: (state: BrainNetworkProductsState): SharedProduct[] =>
      state.sharedProducts
        .filter((sharedProduct: SharedProduct) => sharedProduct.productSharingStatus === SharedProductsStates.PENDING),
  },
  actions: {

    // -- Queries

    getSharedProducts(): Promise<void> {
      const { getSharedProductsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSharedProductsStatus,
        () => getSharedProducts()
          .then((sharedProducts) => {
            this.sharedProducts = sharedProducts;
          })
      );
    },

    // -- Commands

    confirmSharedProduct(command: ConfirmSharedProductCommand): Promise<void> {
      const { confirmSharedProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmSharedProductStatus,
        () => confirmSharedProduct(command)
          .then(() => this.getSharedProducts())
      );
    },

  },
});
