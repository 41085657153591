import Vue from 'vue';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { useAppStore } from '@/application/common/app/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { Language } from '@/types/global';

const DATEPICKER_FORMAT = 'YYYY-MM-DD';

Vue.mixin({
  methods: {
    formatMonthPickerValue: (date: string, locale: Language | '' = '') => {
      if (date === '') {
        return '';
      }

      const language = useAppStore().selectedLanguage!;

      return dayjs.utc(date).locale(locale || language).format('MMMM YYYY');
    },
  },
});

export function formatDateForDatePickerDefault(date?: Dayjs): string {
  if (!date) {
    return '';
  }

  return date.format(DATEPICKER_FORMAT);
}

export function formatDatePickerValue(date: Dayjs, locale: Language | '' = ''): string {
  const language = useAppStore().selectedLanguage!;

  return dayjs.utc(date).locale(locale || language).format('L');
}

export function formatDate(date: Dayjs | string, locale: Language | '' = ''): string {
  const language = locale || useAppStore().selectedLanguage!;
  const timeZone = useAuthenticationStore().user?.timeZone;

  const dateToFormat = isDayjs(date) ? date : dayjs(date);
  return dateToFormat.tz(timeZone).locale(language).format('L');
}

export function formatMonth(date: Dayjs | string): string {
  const language = useAppStore().selectedLanguage!;
  const timeZone = useAuthenticationStore().user?.timeZone;

  const dateToFormat = isDayjs(date) ? date : dayjs(date);
  return dateToFormat.tz(timeZone).locale(language).format('MMM YYYY');
}

export function formatTime(time: Dayjs | string): string {
  const language = useAppStore().selectedLanguage!;
  const timeZone = useAuthenticationStore().user?.timeZone;

  const timeToFormat = isDayjs(time) ? time : dayjs(time);
  return timeToFormat.tz(timeZone).locale(language).format('LT');
}

export function formatDateTime(time: Dayjs | string): string {
  const language = useAppStore().selectedLanguage!;
  const timeZone = useAuthenticationStore().user?.timeZone;

  const timeToFormat = isDayjs(time) ? time : dayjs(time);
  return timeToFormat.tz(timeZone).locale(language).format('LLL');
}

export function formatRelativeTime(date: Dayjs | string): string {
  const language = useAppStore().selectedLanguage!;
  const timeZone = useAuthenticationStore().user?.timeZone;

  const dateToFormat = isDayjs(date) ? date : dayjs(date);
  return dateToFormat.tz(timeZone).locale(language).fromNow();
}

Vue.filter('formatDate', formatDate);
Vue.filter('formatMonth', formatMonth);
Vue.filter('formatTime', formatTime);
Vue.filter('formatDateTime', formatDateTime);
Vue.filter('formatRelativeTime', formatRelativeTime);
