import { Dayjs } from 'dayjs';
import { Command, Query } from '@/application/types';
import { InvestmentProfileControllingPersonType, InvestmentProfileEntityType, InvestmentProfileType, PhoneNumber, SourceOfFundsType } from '@/types/global';
import { Decimal } from '@/models/decimal';

export enum CreateInvestmentProfileStep {
  INVESTMENT_PROFILE_NAME = 1,
  GENERAL_INFORMATION = 2,
  PERSONS_AUTHORIZED_TO_SIGN = 3,
  CONTROLLING_PERSONS = 4,
  BANK_DETAILS = 5,
  CONTACT_DETAILS = 6,
  DONE = 7,
}

export interface InvestmentProfile {
  investmentProfileId: string;
  name: string;
  isComplete: boolean;
  updatedAt: Dayjs;
  type: InvestmentProfileType;
}

export interface InvestmentProfileDetails {
  id: string;
  name: string;
  type: InvestmentProfileType;
  generalInformation?: InvestmentProfileGeneralInformation;
  authorizedPersons?: InvestmentProfileAuthorizedPerson[];
  controllingPersons?: InvestmentProfileControllingPerson[];
  bankDetails?: InvestmentProfileBankDetails;
  contactDetails?: InvestmentProfileContactDetails;
  isComplete: boolean;
}

export interface InvestmentProfileGeneralInformation {
  entityType: EntityType;
  countryOfIncorporation: string;
  entityName: string;
  entityLegalForm: string;
  registrationNumber: string;
  dateOfIncorporation: Dayjs;
  cityOfIncorporation: string;
  natureOfBusiness: string;
  hasStockExchangeListing: boolean;
  isRegulatedEntity: boolean;
  licenceNumber?: string;
  regulator?: string;
  registeredAddress: Address;
  taxResidences: TaxResidence[];
}

export interface EntityType {
  type: InvestmentProfileEntityType;
  other?: string;
}

export interface Address {
  streetAndHouseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  unitOrSuiteOrFloorOrPostBox?: string;
}

export interface TaxResidence {
  country: string;
  taxId: string;
}

export interface InvestmentProfileAuthorizedPerson {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  dateOfBirth: Dayjs;
  countryOfBirth: string;
  placeOfBirth: string;
  nationality: string;
  passportNumber: string;
  passportExpiryDate: Dayjs;
  phoneNumber: PhoneNumber;
  emailAddress: string;
  address: Address;
}

export interface InvestmentProfileControllingPerson {
  id: string;
  type: ControllingPersonType;
  firstName: string;
  lastName: string;
  dateOfBirth: Dayjs;
  countryOfBirth: string;
  placeOfBirth: string;
  nationality: string;
  passportNumber: string;
  passportExpiryDate: Dayjs;
  isUSPerson: boolean;
  countryOfTaxResidence: string;
  taxId: string;
  percentageOfOwnership: Decimal;
  sourceOfFunds: SourceOfFunds;
  address: Address;
}

export interface ControllingPersonType {
  type: InvestmentProfileControllingPersonType;
  other?: string;
}

export interface SourceOfFunds {
  type: SourceOfFundsType;
  other?: string;
}

export interface InvestmentProfileBankDetails {
  bankName: string;
  address: Address;
  beneficiary: string;
  iban: string;
  bic: string;
  intermediaryBank?: string;
}

export interface InvestmentProfileContactDetails {
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
  emailAddress: string;
  address: Address;
}

// Commands

export interface CreateInvestmentProfileCommand extends Command {
  investmentProfileId: string;
  name: string;
  type: string;
}

export interface UpdateInvestmentProfileNameCommand extends Command {
  investmentProfileId: string;
  name: string;
}

export interface UpdateInvestmentProfileGeneralInformationCommand extends Command {
  investmentProfileId: string;
  generalInformation: InvestmentProfileGeneralInformation;
}

export interface UpdateInvestmentProfileAuthorizedPersonsCommand extends Command {
  investmentProfileId: string;
  authorizedPersons: InvestmentProfileAuthorizedPerson[];
}

export interface UpdateInvestmentProfileControllingPersonsCommand extends Command {
  investmentProfileId: string;
  controllingPersons: InvestmentProfileControllingPerson[];
}

export interface UpdateInvestmentProfileBankDetailsCommand extends Command {
  investmentProfileId: string;
  bankDetails: InvestmentProfileBankDetails;
}

export interface UpdateInvestmentProfileContactDetailsCommand extends Command {
  investmentProfileId: string;
  contactDetails: InvestmentProfileContactDetails;
}

export interface DeleteInvestmentProfileCommand extends Command {
  investmentProfileId: string;
}

// Queries

export interface GetInvestmentProfileQuery extends Query {
  investmentProfileId: string;
}
