var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.store.isGetSubscriptionProcessesForInvestorProcessing)?_c('div',{attrs:{"data-cy":"subscription-processes"}},[_c('a-headline',{attrs:{"medium":"","title":_vm.translate('title')}}),_c('v-data-table',{staticClass:"clickable-rows",attrs:{"disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","no-data-text":_vm.translate('emptyMessage'),"headers":_vm.tableHeaders,"items":_vm.store.subscriptionProcesses,"item-key":"subscriptionProcessId","loading":_vm.store.isGetSubscriptionProcessesForInvestorProcessing,"mobile-breakpoint":960},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-start my-3"},[_c('a-img',{staticClass:"product-image mt-3 mt-sm-0 mr-sm-4",attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(_vm._s(item.productName))]),_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"color":_vm.getPillColorForSubscriptionProcessStatus(item.status),"text":_vm.getStatus(item.status)}})],1)],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"body-2 black--text whitespace-nowrap"},[_vm._v(_vm._s(_vm.getType(item.type)))])])]}},{key:"item.requestedCommitment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-md-right"},[(item.investmentAmount)?_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.investmentAmount,item.currency)))]):_c('div',{staticClass:"body-2 black--text"},[_vm._v("-")])])]}},{key:"item.submittedOn",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"grey--text text--darken-4"},[_vm._v(_vm._s(_vm._f("formatDate")(item.startedAt)))])]}}],null,false,3145898077)}),_c('hr',{staticClass:"mt-12"}),(_vm.selectedSubscriptionProcessId && !_vm.selectedSubscriptionProcessIsForOnboardedProduct)?_c('product-subscription-process-dialog',{attrs:{"subscriptionProcessId":_vm.selectedSubscriptionProcessId},on:{"subscription-process-dialog-closed":_vm.subscriptionProcessDialogClosed}}):_vm._e(),(_vm.selectedSubscriptionProcessId && _vm.selectedSubscriptionProcessIsForOnboardedProduct)?_c('onboarded-product-subscription-process-dialog',{attrs:{"subscriptionProcessId":_vm.selectedSubscriptionProcessId},on:{"subscription-process-dialog-closed":_vm.subscriptionProcessDialogClosed}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }