import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { GetPublishedProductsQuery, Product } from './types';
import { getPublishedProducts } from './service';

interface InvestorProductsState {
  products: Product[];

  getProductsStatus: ActionStatus
}

function initialState(): InvestorProductsState {
  return {
    products: [],
    getProductsStatus: ActionStatus.None,
  };
}

export const useInvestorProductsStore = defineStore('investorProducts', {
  state: (): InvestorProductsState => initialState(),
  actions: {
    // -- Queries

    getProducts(): Promise<void> {
      const query: GetPublishedProductsQuery = {};

      const { getProductsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductsStatus,
        () => getPublishedProducts(query)
          .then((products) => {
            this.products = products;
          })
      );
    },
  },
});
