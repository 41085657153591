import { useAppStore } from '@/application/common/app/store';

export function appVersionGuard() {
  const appStore = useAppStore();

  if (appStore.isOutdated) {
    // Requires timeout to ensure that the reload happens on the new route
    setTimeout(() => appStore.refreshApp(), 100);
  }
}
