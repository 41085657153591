import { RouteConfig } from 'vue-router';
import TermSheetsTemplatesTabs from './components/term-sheets-templates-tabs.vue';
import TermSheetTemplateForAssetClass from './components/term-sheet-template-for-asset-class.vue';
import { URLParameterByAssetClass } from './types';

export const termSheetTemplatesRoutes: RouteConfig[] = [
  {
    path: '/term-sheets-templates',
    component: TermSheetsTemplatesTabs,
    children: [
      {
        name: 'term-sheets-templates',
        path: '',
        redirect: () => ({
          name: 'term-sheet-template-for-asset-class',
          params: { assetClassParameter: URLParameterByAssetClass.PRIVATE_EQUITY },
        }),
      }, {
        name: 'term-sheet-template-for-asset-class',
        path: ':assetClassParameter',
        component: TermSheetTemplateForAssetClass,
        meta: {
          requiresAuth: true,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  },
];
