export function formatStringArrayAsSingleStringWithBreaks(strings: string[]): string {
  let formattedString = '';

  let isFirstItem = true;
  for (const item of strings) {
    if (isFirstItem) {
      formattedString += item;
      isFirstItem = false;
      continue;
    }

    formattedString += `<br> ${item}`;
  }

  return formattedString;
}
