import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse, VuetifySelectItem } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { KPI, Language, Languages } from '@/types/global';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { getDueDiligenceFileForDownload, getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct, getInvestorsWithAccessForProduct, getProduct, getProductNewsArticleAttachmentForDownload, getProductPrintPDFForDownload, offboardProduct, onboardProduct, updateInvestorsWithAccessForProduct } from './service';
import { DueDiligenceFile, GeneralPartnerUserWithAccess, GetDueDiligenceFileForDownloadQuery, GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery, GetInvestorsWithAccessForProductQuery, GetProductNewsArticleAttachmentForDownloadQuery, GetProductPrintPDFForDownloadQuery, GetProductQuery, InvestorWithAccess, OffboardProductCommand, OnboardProductCommand, Product, ProductVideo, UpdateGeneralPartnerUsersAccessForProductCommand, UpdateInvestorsWithAccessForProductCommand } from './types';

interface GeneralPartnerOnboardedProductFromNetworkState {
  currentProductId: string | null;
  currentProduct: Product | null;
  selectedLanguage: Language;
  investorsWithAccess: InvestorWithAccess[];
  generalPartnerUsersWithAccess: GeneralPartnerUserWithAccess[];

  getCurrentProductStatus: ActionStatus;
  getDueDiligenceFileForDownloadStatus: ActionStatus;
  getProductPrintPDFForDownloadStatus: ActionStatus;
  getProductNewsArticleAttachmentForDownloadStatus: ActionStatus;
  onboardProductStatus: ActionStatus;
  offboardProductStatus: ActionStatus;
  getInvestorsWithAccessForProductStatus : ActionStatus;
  updateInvestorsWithAccessForProductStatus : ActionStatus;
  getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus: ActionStatus;
  updateGeneralPartnerUsersAccessForProductStatus : ActionStatus;
}

function initialState(): GeneralPartnerOnboardedProductFromNetworkState {
  return {
    currentProductId: null,
    currentProduct: null,
    selectedLanguage: Languages.en,
    investorsWithAccess: [],
    generalPartnerUsersWithAccess: [],

    getCurrentProductStatus: ActionStatus.None,
    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    getProductPrintPDFForDownloadStatus: ActionStatus.None,
    getProductNewsArticleAttachmentForDownloadStatus: ActionStatus.None,
    onboardProductStatus: ActionStatus.None,
    offboardProductStatus: ActionStatus.None,
    getInvestorsWithAccessForProductStatus: ActionStatus.None,
    updateInvestorsWithAccessForProductStatus: ActionStatus.None,
    getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus: ActionStatus.None,
    updateGeneralPartnerUsersAccessForProductStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerOnboardedProductFromNetworkStore = defineStore('generalPartnerOnboardedProductFromNetwork', {
  state: (): GeneralPartnerOnboardedProductFromNetworkState => initialState(),
  getters: {
    isGetCurrentProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.getCurrentProductStatus === ActionStatus.InProgress,
    isGetDueDiligenceFileForDownloadProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.getDueDiligenceFileForDownloadStatus === ActionStatus.InProgress,
    isGetProductPrintPDFForDownloadProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.getProductPrintPDFForDownloadStatus === ActionStatus.InProgress,
    isGetProductNewsArticleAttachmentForDownloadProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.getProductNewsArticleAttachmentForDownloadStatus === ActionStatus.InProgress,
    isOnboardProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.onboardProductStatus === ActionStatus.InProgress,
    isOffboardProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.offboardProductStatus === ActionStatus.InProgress,
    isGetInvestorsWithAccessForProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.getInvestorsWithAccessForProductStatus === ActionStatus.InProgress,
    isUpdateInvestorsWithAccessForProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.updateInvestorsWithAccessForProductStatus === ActionStatus.InProgress,
    isGetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductProcessing:
      (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
        state.getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerUsersAccessForProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkState): boolean =>
      state.updateGeneralPartnerUsersAccessForProductStatus === ActionStatus.InProgress,
    productName: (state: GeneralPartnerOnboardedProductFromNetworkState): string => {
      if (!state.currentProduct) {
        return '';
      }

      // @ts-ignore
      return state.currentProduct.productName[state.selectedLanguage]
        || state.currentProduct.productName.en;
    },
    productPinnedDueDiligenceFilesWithFallback: (state: GeneralPartnerOnboardedProductFromNetworkState): DueDiligenceFile[] => {
      if (!state.currentProduct) {
        return [];
      }

      // @ts-ignore
      return state.currentProduct.pinnedDueDiligenceFiles[state.selectedLanguage]
      || state.currentProduct.pinnedDueDiligenceFiles.en
      || [];
    },
    hasPinnedDueDiligenceFiles(): boolean {
      return this.productPinnedDueDiligenceFilesWithFallback.length > 0;
    },
    summaryKPIs(state: GeneralPartnerOnboardedProductFromNetworkState): KPI[] {
      if (!state.currentProduct) {
        return [];
      }

      return state.currentProduct.idsOfKPIsForSummary.map((kpiId) => this.productKPIs.find((kpi) => kpi.kpiId === kpiId)!);
    },
    hasProductVideo(state: GeneralPartnerOnboardedProductFromNetworkState): boolean {
      return !!state.currentProduct
        && !!state.currentProduct.productVideo;
    },
    productVideo(state: GeneralPartnerOnboardedProductFromNetworkState): ProductVideo|null {
      if (!state.currentProduct) {
        return null;
      }

      const productVideo = state.currentProduct.productVideo
        && (state.currentProduct.productVideo[state.selectedLanguage!]
          || state.currentProduct.productVideo.en);

      return productVideo || null;
    },
    hasManagers(state: GeneralPartnerOnboardedProductFromNetworkState): boolean {
      if (!state.currentProduct) {
        return false;
      }

      return !!this.currentProduct
        && this.currentProduct.managers.length > 0;
    },
    hasSummaryKPIs(state: GeneralPartnerOnboardedProductFromNetworkState): boolean {
      if (!state.currentProduct) {
        return false;
      }

      return (state.currentProduct.idsOfKPIsForSummary?.length || 0) > 0;
    },
    hasProductNewsArticles(state: GeneralPartnerOnboardedProductFromNetworkState): boolean {
      return state.currentProduct && state.currentProduct.productNewsArticles.length > 0 || false;
    },
    isProductAvailable(state: GeneralPartnerOnboardedProductFromNetworkState): boolean {
      return state.currentProduct !== null
        && state.currentProduct.productId === this.currentProductId;
    },
    productKPIs(state: GeneralPartnerOnboardedProductFromNetworkState): KPI[] {
      if (!state.currentProduct) {
        return [];
      }

      return getKPIsFromTermSheet(state.currentProduct.termSheet);
    },
    investorSelectItems(state: GeneralPartnerOnboardedProductFromNetworkState): VuetifySelectItem<string>[] {
      return state.investorsWithAccess.map((investorWithAccess) => ({
        value: investorWithAccess.investorId,
        text: investorWithAccess.name,
      }));
    },
    idsOfInvestorsWithAccessToProduct(state: GeneralPartnerOnboardedProductFromNetworkState): string[] {
      return state.investorsWithAccess
        .filter((investor) => investor.hasAccessToProduct)
        .map((investor) => investor.investorId);
    },
    idsOfGeneralPartnerUsersWithExplicitAccessToProduct(state: GeneralPartnerOnboardedProductFromNetworkState): string[] {
      return state.generalPartnerUsersWithAccess
        .filter((generalPartnerUser) => generalPartnerUser.hasExplicitAccessToProduct)
        .map((generalPartnerUser) => generalPartnerUser.id);
    },
  },
  actions: {

    // -- State management

    updateCurrentProductId(currentProductId: string): Promise<void> {
      this.currentProductId = currentProductId;

      return Promise.resolve();
    },

    updateLanguage(language: Language): Promise<void> {
      this.selectedLanguage = language;

      return Promise.resolve();
    },
    // -- Queries

    getCurrentProduct(): Promise<void> {
      const query: GetProductQuery = {
        productId: this.currentProductId!,
      };

      const { getCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentProductStatus,
        () => getProduct(query)
          .then((product) => {
            this.currentProduct = product;
          })
      );
    },

    getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceFileForDownloadStatus,
        () => getDueDiligenceFileForDownload(query)
      );
    },

    getProductPrintPDFForDownload(query: GetProductPrintPDFForDownloadQuery): Promise<FileResponse> {
      const { getProductPrintPDFForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductPrintPDFForDownloadStatus,
        () => getProductPrintPDFForDownload(query)
      );
    },

    getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
      const { getProductNewsArticleAttachmentForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductNewsArticleAttachmentForDownloadStatus,
        () => getProductNewsArticleAttachmentForDownload(query)
      );
    },

    getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct(): Promise<void> {
      const query: GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery = {
        productId: this.currentProductId!,
      };

      const { getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus,
        () => getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct(query)
          .then((generalPartnerUsers: GeneralPartnerUserWithAccess[]) => {
            this.generalPartnerUsersWithAccess = generalPartnerUsers;
          })
      );
    },

    getInvestorsWithAccessForProduct(): Promise<void> {
      const query: GetInvestorsWithAccessForProductQuery = {
        productId: this.currentProductId!,
      };

      const { getInvestorsWithAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorsWithAccessForProductStatus,
        () => getInvestorsWithAccessForProduct(query)
          .then((investors: InvestorWithAccess[]) => {
            this.investorsWithAccess = investors;
          })
      );
    },

    // -- Commands

    onboardProduct(): Promise<void> {
      const command: OnboardProductCommand = {
        productId: this.currentProductId!,
      };

      const { onboardProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        onboardProductStatus,
        () => onboardProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    offboardProduct(): Promise<void> {
      const command: OffboardProductCommand = {
        productId: this.currentProductId!,
      };

      const { offboardProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        offboardProductStatus,
        () => offboardProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    updateGeneralPartnerUsersAccessForProduct(command: UpdateGeneralPartnerUsersAccessForProductCommand): Promise<void> {
      const { updateGeneralPartnerUsersAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerUsersAccessForProductStatus,
        () => offboardProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    updateInvestorsWithAccessForProduct(command: UpdateInvestorsWithAccessForProductCommand): Promise<void> {
      const { updateInvestorsWithAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestorsWithAccessForProductStatus,
        () => updateInvestorsWithAccessForProduct(command)
      );
    },

  },
});
