import { render, staticRenderFns } from "./accept-invitation.vue?vue&type=template&id=68a09ab2&scoped=true&"
import script from "./accept-invitation.vue?vue&type=script&lang=ts&"
export * from "./accept-invitation.vue?vue&type=script&lang=ts&"
import style0 from "./accept-invitation.vue?vue&type=style&index=0&id=68a09ab2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a09ab2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
