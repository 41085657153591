import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachBrainiac } from '@/helpers/default-parameter-helper';
import { CreateKPICategoryCommand, CreateKPIDefinitionCommand, DeleteKPICategoryCommand, DeleteKPIDefinitionCommand, GetTermSheetTemplateForAssetClassQuery, MoveKPIDefinitionToCategoryCommand, TermSheetTemplate, UpdateKPICategoriesOrderCommand, UpdateKPICategoryCommand, UpdateKPIDefinitionCommand, UpdateKPIDefinitionsOrderCommand } from './types';

// -- Queries

export function getTermSheetTemplate(query: GetTermSheetTemplateForAssetClassQuery): Promise<TermSheetTemplate> {
  const url = generateApiUrl('/brain-api/get-term-sheet-template-for-asset-class-query');
  return performApiRequest<TermSheetTemplate>({ url, method: 'POST', data: attachBrainiac(query) });
}

// -- Commands

export function createKPICategory(command: CreateKPICategoryCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/create-kpi-category-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateKPICategory(command: UpdateKPICategoryCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-kpi-category-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateKPICategoriesOrder(command: UpdateKPICategoriesOrderCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-kpi-categories-order-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function deleteKPICategory(command: DeleteKPICategoryCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/delete-kpi-category-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function createKPIDefinition(command: CreateKPIDefinitionCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/create-kpi-definition-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateKPIDefinition(command: UpdateKPIDefinitionCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-kpi-definition-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function deleteKPIDefinition(command: DeleteKPIDefinitionCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/delete-kpi-definition-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateKPIDefinitionsOrder(command: UpdateKPIDefinitionsOrderCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-kpi-definitions-order-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function moveKPIDefinitionToCategory(command: MoveKPIDefinitionToCategoryCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/move-kpi-definition-to-category-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
