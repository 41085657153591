import { defineStore, storeToRefs } from 'pinia';
import { Decimal } from '@/models/decimal';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, VuetifySelectItem } from '@/application/types';
import { GetInvestmentsOfInvestorForProductQuery, Investment, UpdateCurrentInvestorIdAndProductIdPayload, InvestorDetails, ProductDetails, UpdateInvestmentForInvestorCommand, DeleteInvestmentForInvestorCommand, CreateTransactionCommand, Transaction, DeleteTransactionCommand, UpdateTransactionCommand, GetAccessiblePublishedProductsForInvestorQuery } from './types';
import { getInvestmentsOfInvestorForProduct, deleteInvestmentForInvestor, updateInvestmentForInvestor, getAccessiblePublishedProductsForInvestor, createTransactionForInvestor, updateTransactionForInvestor, deleteTransactionForInvestor } from './service';

interface GeneralPartnerInvestmentsOfInvestorForProductState {
  investorId: string | null;
  productId: string | null;
  investments: Investment[];
  accessiblePublishedProductsForInvestor: ProductDetails[];
  investorTransactions: Transaction[];
  averageEntryPrice: Decimal | null;
  totalCommittedCapital: Decimal | null;
  totalShares: Decimal | null;
  investorDetails: InvestorDetails | null;
  productDetails: ProductDetails | null;

  getInvestmentsOfInvestorForProductStatus: ActionStatus;
  deleteInvestmentForInvestorStatus: ActionStatus;
  updateInvestmentForInvestorStatus: ActionStatus;
  getAccessiblePublishedProductsForInvestorStatus: ActionStatus;
  createTransactionForInvestorStatus: ActionStatus;
  updateTransactionForInvestorStatus: ActionStatus;
  deleteTransactionForInvestorStatus: ActionStatus;
}

function initialState(): GeneralPartnerInvestmentsOfInvestorForProductState {
  return {
    investorId: null,
    productId: null,
    investments: [],
    accessiblePublishedProductsForInvestor: [],
    investorTransactions: [],
    averageEntryPrice: null,
    totalCommittedCapital: null,
    totalShares: null,
    investorDetails: null,
    productDetails: null,

    getInvestmentsOfInvestorForProductStatus: ActionStatus.None,
    deleteInvestmentForInvestorStatus: ActionStatus.None,
    updateInvestmentForInvestorStatus: ActionStatus.None,
    getAccessiblePublishedProductsForInvestorStatus: ActionStatus.None,
    createTransactionForInvestorStatus: ActionStatus.None,
    updateTransactionForInvestorStatus: ActionStatus.None,
    deleteTransactionForInvestorStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerInvestmentsOfInvestorForProductStore = defineStore(
  'generalPartnerInvestmentsOfInvestorForProduct',
  {
    state: (): GeneralPartnerInvestmentsOfInvestorForProductState => initialState(),
    getters: {
      isGetInvestmentsOfInvestorForProductProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.getInvestmentsOfInvestorForProductStatus === ActionStatus.InProgress,
      isDeleteInvestmentForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.deleteInvestmentForInvestorStatus === ActionStatus.InProgress,
      isUpdateInvestmentForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.updateInvestmentForInvestorStatus === ActionStatus.InProgress,
      isGetAccessiblePublishedProductsForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.getAccessiblePublishedProductsForInvestorStatus === ActionStatus.InProgress,
      isCreateTransactionForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.createTransactionForInvestorStatus === ActionStatus.InProgress,
      isUpdateTransactionForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.updateTransactionForInvestorStatus === ActionStatus.InProgress,
      isDeleteTransactionForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.deleteTransactionForInvestorStatus === ActionStatus.InProgress,

      publishedProductItems: (state: GeneralPartnerInvestmentsOfInvestorForProductState): VuetifySelectItem<string>[] =>
        state.accessiblePublishedProductsForInvestor.map((publishedProduct: ProductDetails) => ({
          text: publishedProduct.productName,
          value: publishedProduct.productId,
        })),
      hasTransactions: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        state.investorTransactions.length > 0,
      hasNoKPIs: (state: GeneralPartnerInvestmentsOfInvestorForProductState): boolean =>
        !!state.productDetails
        && state.productDetails.idsOfKPIsForProductInfo.length === 0,
    },
    actions: {

      // -- State management

      updateCurrentInvestorIdAndProductId(payload: UpdateCurrentInvestorIdAndProductIdPayload): Promise<void> {
        this.investorId = payload.investorId;
        this.productId = payload.productId;

        return Promise.resolve();
      },

      // -- Queries

      getInvestmentsOfInvestorForProduct(): Promise<void> {
        const query: GetInvestmentsOfInvestorForProductQuery = {
          investorId: this.investorId!,
          productId: this.productId!,
        };

        const { getInvestmentsOfInvestorForProductStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestmentsOfInvestorForProductStatus,
          () => getInvestmentsOfInvestorForProduct(query)
            .then((result) => {
              this.investments = result.investments;
              this.averageEntryPrice = result.averageEntryPrice;
              this.totalCommittedCapital = result.totalCommittedCapital;
              this.totalShares = result.totalShares;
              this.investorDetails = result.investorDetails;
              this.productDetails = result.productDetails;
              this.investorTransactions = result.investorTransactions;
            })
        );
      },

      getAccessiblePublishedProductsForInvestor(): Promise<void> {
        const query: GetAccessiblePublishedProductsForInvestorQuery = {
          investorId: this.investorId!,
        };

        const { getAccessiblePublishedProductsForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getAccessiblePublishedProductsForInvestorStatus,
          () => getAccessiblePublishedProductsForInvestor(query)
            .then((accessiblePublishedProducts) => {
              this.accessiblePublishedProductsForInvestor = accessiblePublishedProducts;
            })
        );
      },

      // -- Commands

      updateInvestmentForInvestor(command: UpdateInvestmentForInvestorCommand): Promise<void> {
        const { updateInvestmentForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          updateInvestmentForInvestorStatus,
          () => updateInvestmentForInvestor(command)
            .then(() => this.getInvestmentsOfInvestorForProduct())
        );
      },

      deleteInvestmentForInvestor(command: DeleteInvestmentForInvestorCommand): Promise<void> {
        const { deleteInvestmentForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteInvestmentForInvestorStatus,
          () => deleteInvestmentForInvestor(command)
            .then(() => this.getInvestmentsOfInvestorForProduct())
        );
      },

      createTransactionForInvestor(command: CreateTransactionCommand): Promise<void> {
        const { createTransactionForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          createTransactionForInvestorStatus,
          () => createTransactionForInvestor(command)
            .then(() => this.getInvestmentsOfInvestorForProduct())
        );
      },

      updateTransactionForInvestor(command: UpdateTransactionCommand): Promise<void> {
        const { updateTransactionForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          updateTransactionForInvestorStatus,
          () => updateTransactionForInvestor(command)
            .then(() => this.getInvestmentsOfInvestorForProduct())
        );
      },

      deleteTransactionForInvestor(command: DeleteTransactionCommand): Promise<void> {
        const { deleteTransactionForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          deleteTransactionForInvestorStatus,
          () => deleteTransactionForInvestor(command)
            .then(() => this.getInvestmentsOfInvestorForProduct())
        );
      },

    },
  }
);
