import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { Investor, FundraisingAnalytics, FundraisingOverview, GetFundraisingAnalyticsForProductQuery, GetFundraisingOverviewForProductQuery, GetTopTenInvestorsForProductQuery } from './types';

export function getFundraisingOverviewForProduct(query: GetFundraisingOverviewForProductQuery):
  Promise<FundraisingOverview> {
  const url = generateApiUrl('/api/general-partner/get-fundraising-overview-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getFundraisingAnalyticsForProduct(query: GetFundraisingAnalyticsForProductQuery):
  Promise<FundraisingAnalytics> {
  const url = generateApiUrl('/api/general-partner/get-fundraising-analytics-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getTopTenInvestorsForProduct(query: GetTopTenInvestorsForProductQuery):
  Promise<Investor[]> {
  const url = generateApiUrl('/api/general-partner/get-top-ten-investors-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
