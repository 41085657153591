import { defineStore, storeToRefs } from 'pinia';
import { Decimal } from '@/models/decimal';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, VuetifySelectItem } from '@/application/types';
import { GetInvestmentsOfInvestorForOnboardedProductQuery, Investment, UpdateCurrentInvestorIdAndProductIdPayload, InvestorDetails, ProductDetails, Transaction, GetAccessiblePublishedProductsForInvestorQuery } from './types';
import { getInvestmentsOfInvestorForOnboardedProduct, getAccessiblePublishedProductsForInvestor } from './service';

interface GeneralPartnerInvestmentsOfInvestorForOnboardedProductState {
  investorId: string | null;
  productId: string | null;
  investments: Investment[];
  accessiblePublishedProductsForInvestor: ProductDetails[];
  investorTransactions: Transaction[];
  averageEntryPrice: Decimal | null;
  totalCommittedCapital: Decimal | null;
  totalShares: Decimal | null;
  investorDetails: InvestorDetails | null;
  productDetails: ProductDetails | null;

  getInvestmentsOfInvestorForOnboardedProductStatus: ActionStatus;
  deleteInvestmentForInvestorStatus: ActionStatus;
  updateInvestmentForInvestorStatus: ActionStatus;
  getAccessiblePublishedProductsForInvestorStatus: ActionStatus;
  createTransactionForInvestorStatus: ActionStatus;
  updateTransactionForInvestorStatus: ActionStatus;
  deleteTransactionForInvestorStatus: ActionStatus;
}

function initialState(): GeneralPartnerInvestmentsOfInvestorForOnboardedProductState {
  return {
    investorId: null,
    productId: null,
    investments: [],
    accessiblePublishedProductsForInvestor: [],
    investorTransactions: [],
    averageEntryPrice: null,
    totalCommittedCapital: null,
    totalShares: null,
    investorDetails: null,
    productDetails: null,

    getInvestmentsOfInvestorForOnboardedProductStatus: ActionStatus.None,
    deleteInvestmentForInvestorStatus: ActionStatus.None,
    updateInvestmentForInvestorStatus: ActionStatus.None,
    getAccessiblePublishedProductsForInvestorStatus: ActionStatus.None,
    createTransactionForInvestorStatus: ActionStatus.None,
    updateTransactionForInvestorStatus: ActionStatus.None,
    deleteTransactionForInvestorStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerInvestmentsOfInvestorForOnboardedProductStore = defineStore(
  'generalPartnerInvestmentsOfInvestorForOnboardedProduct',
  {
    state: (): GeneralPartnerInvestmentsOfInvestorForOnboardedProductState => initialState(),
    getters: {
      isGetInvestmentsOfInvestorForOnboardedProductProcessing: (
        state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState
      ): boolean =>
        state.getInvestmentsOfInvestorForOnboardedProductStatus === ActionStatus.InProgress,
      isDeleteInvestmentForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        state.deleteInvestmentForInvestorStatus === ActionStatus.InProgress,
      isUpdateInvestmentForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        state.updateInvestmentForInvestorStatus === ActionStatus.InProgress,
      isGetAccessiblePublishedProductsForInvestorProcessing: (
        state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState
      ): boolean =>
        state.getAccessiblePublishedProductsForInvestorStatus === ActionStatus.InProgress,
      isCreateTransactionForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        state.createTransactionForInvestorStatus === ActionStatus.InProgress,
      isUpdateTransactionForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        state.updateTransactionForInvestorStatus === ActionStatus.InProgress,
      isDeleteTransactionForInvestorProcessing: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        state.deleteTransactionForInvestorStatus === ActionStatus.InProgress,

      publishedProductItems: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): VuetifySelectItem<string>[] =>
        state.accessiblePublishedProductsForInvestor.map((publishedProduct: ProductDetails) => ({
          text: publishedProduct.productName,
          value: publishedProduct.productId,
        })),
      hasTransactions: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        state.investorTransactions.length > 0,
      hasNoKPIs: (state: GeneralPartnerInvestmentsOfInvestorForOnboardedProductState): boolean =>
        !!state.productDetails
          && state.productDetails.idsOfKPIsForProductInfo.length === 0,
    },
    actions: {

      // -- State management

      updateCurrentInvestorIdAndProductId(payload: UpdateCurrentInvestorIdAndProductIdPayload): Promise<void> {
        this.investorId = payload.investorId;
        this.productId = payload.productId;

        return Promise.resolve();
      },

      // -- Queries

      getInvestmentsOfInvestorForOnboardedProduct(): Promise<void> {
        const query: GetInvestmentsOfInvestorForOnboardedProductQuery = {
          investorId: this.investorId!,
          productId: this.productId!,
        };

        const { getInvestmentsOfInvestorForOnboardedProductStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getInvestmentsOfInvestorForOnboardedProductStatus,
          () => getInvestmentsOfInvestorForOnboardedProduct(query)
            .then((result) => {
              this.investments = result.investments;
              this.averageEntryPrice = result.averageEntryPrice;
              this.totalCommittedCapital = result.totalCommittedCapital;
              this.totalShares = result.totalShares;
              this.investorDetails = result.investorDetails;
              this.productDetails = result.productDetails;
              this.investorTransactions = result.investorTransactions;
            })
        );
      },

      getAccessiblePublishedProductsForInvestor(): Promise<void> {
        const query: GetAccessiblePublishedProductsForInvestorQuery = {
          investorId: this.investorId!,
        };

        const { getAccessiblePublishedProductsForInvestorStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getAccessiblePublishedProductsForInvestorStatus,
          () => getAccessiblePublishedProductsForInvestor(query)
            .then((accessiblePublishedProducts) => {
              this.accessiblePublishedProductsForInvestor = accessiblePublishedProducts;
            })
        );
      },

    },
  }
);
