import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { Advisor, SendMessageToAdvisorCommand } from './types';

export function getAdvisor(): Promise<Advisor> {
  const url = generateApiUrl('/api/investor/get-advisor-contact-information-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function sendAdvisorMessage(command: SendMessageToAdvisorCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/send-message-to-advisor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
