import { Language, LanguageDependentData, Name, PhoneNumber, Salutation, TranslatedText } from '@/types/global';
import { Command, CommandWithFiles } from '@/application/types';

export type GeneralPartnerCreationStep =
  | 'DEFINE_GENERAL_PARTNER_MASTER_DATA'
  | 'DEFINE_GENERAL_PARTNER_CONFIGURATIONS'
  | 'DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY'
  | 'DEFINE_GENERAL_PARTNER_USER_DETAILS';
export const GeneralPartnerCreationSteps: Record<GeneralPartnerCreationStep, GeneralPartnerCreationStep> = {
  DEFINE_GENERAL_PARTNER_MASTER_DATA: 'DEFINE_GENERAL_PARTNER_MASTER_DATA',
  DEFINE_GENERAL_PARTNER_CONFIGURATIONS: 'DEFINE_GENERAL_PARTNER_CONFIGURATIONS',
  DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY: 'DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY',
  DEFINE_GENERAL_PARTNER_USER_DETAILS: 'DEFINE_GENERAL_PARTNER_USER_DETAILS',
};

export interface ColorScheme {
  primary: string;
  secondary: string;
  green: string;
  success: string;
  red: string;
  error: string;
  yellow: string;
  warning: string;
  grey: string;
  info: string;
  purple: string;
  cyan: string;
}

export interface GeneralPartnerFormValues {
  masterData: GeneralPartnerMasterData;
  configurations: GeneralPartnerConfigurations;
  legalAndPolicyData: GeneralPartnerLegalAndPolicyData;
  userDetailsData: GeneralPartnerUserDetailsData;
}

export interface GeneralPartnerMasterData {
  title: string | null;
  street: string | null;
  postal: string | null;
  city: string | null;
  country: string | null;
  host: string | null;
  logo: LanguageDependentData<File | null>|null;
  heroImage: LanguageDependentData<File | null>|null;
}

export interface GeneralPartnerConfigurations {
  defaultProductContactEmailAddress: string | null;
  isTwoFactorAuthenticationEnabled: boolean;
  isTwoFactorAuthenticationWithSMSEnabled: boolean;
  mailerSenderEmail: string | null;
  mailerDSN: string | null;
  colorScheme: ColorScheme | null;
}

export interface GeneralPartnerLegalAndPolicyData {
  imprint: TranslatedText|null;
  dataProtection: TranslatedText|null;
}

export interface GeneralPartnerUserDetailsData {
    salutation: Salutation | null,
    name: Name | null,
    emailAddress: string | null,
    phoneNumber: PhoneNumber | null,
    countryOfResidence: string | null,
    language: Language | null,
    timeZone: string | null,
}

// -- Commands

export interface CreateGeneralPartnerCommand extends CommandWithFiles {
  body: {
    generalPartnerId: string;
    title: string;
    street: string;
    postal: string;
    city: string;
    country: string;
    host: string;
    defaultProductContactEmailAddress: string;
    isTwoFactorAuthenticationEnabled: boolean;
    isTwoFactorAuthenticationWithSMSEnabled: boolean;
    mailerSenderEmail: string;
    mailerDSN: string;
    colorScheme: ColorScheme;
    imprint: TranslatedText;
    dataProtection: TranslatedText;
  },
  files: {
    logo: File;
    heroImage: File;
  }
}

export interface CreateGeneralPartnerUserAccountCommand extends Command {
  generalPartnerId: string;
  salutation: Salutation,
  name: {
    firstName: string;
    lastName: string;
  };
  language: Language;
  emailAddress: string;
  phoneNumber: PhoneNumber;
  countryOfResidence: string;
  timeZone: string;
  password: string;
}
