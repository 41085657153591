import * as i18nIsoCountries from 'i18n-iso-countries';
import { rawTimeZones } from '@vvo/tzdb';
import i18n from '@/plugins/vue-i18n';
import { FormField, VuetifySelectItem } from '@/application/types';
import { useAppStore } from '@/application/common/app/store';
import { AssetClass, AssetClasses, Currencies, Currency, DigitalAssetsSubAssetClasses, DynamicKPIType, DynamicKPITypes, Feature, Features, Geographies, Geography, HedgeFundSubAssetClasses, Industries, Industry, InfrastructureSubAssetClasses, InvestmentProfileControllingPersonType, InvestmentProfileControllingPersonTypes, InvestmentProfileEntityType, InvestmentProfileEntityTypes, InvestmentProfileType, InvestmentProfileTypes, InvestmentStructure, InvestmentStructures, Language, Languages, NaturalResourcesSubAssetClasses, PrivateDebtSubAssetClasses, PrivateEquitySubAssetClasses, ProductReportType, ProductReportTypes, RealEstateSubAssetClasses, Salutation, Salutations, SourceOfFundsType, SourceOfFundsTypes, SubAssetClass, TransactionType, TransactionTypes, VentureCapitalSubAssetClasses } from '@/types/global';
import { useGeneralPartnerStore } from '@/application/whitelabel/general-partner/store';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

export function isEditorTextFieldFilled(field: FormField<string>): boolean {
  return isTextFieldFilled(field) && field.value.trim() !== '<p></p>';
}

export function isTextFieldFilled(field: FormField<string>): boolean {
  return field.value.trim().length > 0;
}

export function isSelectFieldFilled(field: FormField<any>): boolean {
  return field.value !== null;
}

export function isFormFieldIndicatedAsRequired(field: FormField<any>): boolean {
  if (!field.isIndicatedAsRequired) {
    return false;
  }

  return typeof field.isIndicatedAsRequired === 'function'
    ? field.isIndicatedAsRequired()
    : field.isIndicatedAsRequired;
}

export function convertStringToNumber(valueAsString: string): number {
  const language = useAppStore().selectedLanguage || Languages.en;

  let cleanedValueAsString = language === Languages.en
    ? valueAsString.replace(/,/g, '')
    : valueAsString.replace(/\./g, '');

  if (language === Languages.de) {
    cleanedValueAsString = cleanedValueAsString.replace(/,/g, '.');
  }

  return parseFloat(cleanedValueAsString);
}

export function languageSelectItems(): VuetifySelectItem<Language>[] {
  return [
    {
      text: i18n.t('helpers.data.languages.en'),
      value: Languages.en,
    },
    {
      text: i18n.t('helpers.data.languages.de'),
      value: Languages.de,
    },
  ];
}

export function enabledLanguageSelectItems(): VuetifySelectItem<Language>[] {
  return useGeneralPartnerStore().generalPartner!.enabledLanguages.map((language) => ({
    text: i18n.t(`helpers.data.languages.${language}`),
    value: language,
  }));
}

export function enabledProductLanguageSelectItems(): VuetifySelectItem<Language>[] {
  return useGeneralPartnerStore().generalPartner!.enabledLanguagesForProducts.map((language) => ({
    text: i18n.t(`helpers.data.languages.${language}`),
    value: language,
  }));
}

export function countrySelectItems(): VuetifySelectItem<string>[] {
  const appStore = useAppStore();
  const countries = i18nIsoCountries.getNames(appStore.selectedLanguage || Languages.en);

  const countrySelectItems = [];
  for (const code in countries) {
    if (Object.prototype.hasOwnProperty.call(countries, code)) {
      countrySelectItems.push({
        text: countries[code],
        value: code,
      });
    }
  }

  return countrySelectItems;
}

export function industrySelectItems(locale: Language | '' = ''): VuetifySelectItem<Industry>[] {
  return [
    {
      text: i18n.t('helpers.data.industries.REAL_ESTATE', locale),
      value: Industries.REAL_ESTATE,
    },
    {
      text: i18n.t('helpers.data.industries.TECHNOLOGY_MEDIA_TELECOMMUNICATIONS', locale),
      value: Industries.TECHNOLOGY_MEDIA_TELECOMMUNICATIONS,
    },
    {
      text: i18n.t('helpers.data.industries.FINANCIAL_SERVICES', locale),
      value: Industries.FINANCIAL_SERVICES,
    },
    {
      text: i18n.t('helpers.data.industries.CONSUMER_INDUSTRIAL_PRODUCTS', locale),
      value: Industries.CONSUMER_INDUSTRIAL_PRODUCTS,
    },
    {
      text: i18n.t('helpers.data.industries.LIFE_SCIENCES_HEALTH_CARE', locale),
      value: Industries.LIFE_SCIENCES_HEALTH_CARE,
    },
  ];
}

export function investmentProfileControllingPersonTypeSelectItems(
  locale: Language | '' = ''
): VuetifySelectItem<InvestmentProfileControllingPersonType>[] {
  return Object.values(InvestmentProfileControllingPersonTypes)
    .map((investmentProfileControllingPersonType: InvestmentProfileControllingPersonType) => ({
      text: i18n.t(`helpers.data.investmentProfileControllingPersonTypes.${investmentProfileControllingPersonType}`, locale),
      value: investmentProfileControllingPersonType,
    }));
}

export function sourceOfFundsSelectItems(locale: Language | '' = ''): VuetifySelectItem<SourceOfFundsType>[] {
  return Object.values(SourceOfFundsTypes)
    .map((sourceOfFundsType: SourceOfFundsType) => ({
      text: i18n.t(`helpers.data.sourceOfFundsTypes.${sourceOfFundsType}`, locale),
      value: sourceOfFundsType,
    }));
}

export function investmentProfileTypeSelectItems(locale: Language | '' = ''): VuetifySelectItem<InvestmentProfileType>[] {
  return Object.values(InvestmentProfileTypes)
    .map((investmentProfileType: InvestmentProfileType) => ({
      text: i18n.t(`helpers.data.investmentProfileTypes.${investmentProfileType}`, locale),
      value: investmentProfileType,
    }));
}

export function investmentProfileEntityTypeSelectItems(locale: Language | '' = ''): VuetifySelectItem<InvestmentProfileEntityType>[] {
  return Object.values(InvestmentProfileEntityTypes)
    .map((investmentProfileEntityType: InvestmentProfileEntityType) => ({
      text: i18n.t(`helpers.data.investmentProfileEntityTypes.${investmentProfileEntityType}`, locale),
      value: investmentProfileEntityType,
    }));
}

export function geographySelectItems(locale: Language | '' = ''): VuetifySelectItem<Geography>[] {
  return [
    {
      text: i18n.t('helpers.data.geographies.GLOBAL', locale),
      value: Geographies.GLOBAL,
    },
    {
      text: i18n.t('helpers.data.geographies.EUROPE', locale),
      value: Geographies.EUROPE,
    },
    {
      text: i18n.t('helpers.data.geographies.ASIA', locale),
      value: Geographies.ASIA,
    },
    {
      text: i18n.t('helpers.data.geographies.NORTH_AMERICA', locale),
      value: Geographies.NORTH_AMERICA,
    },
    {
      text: i18n.t('helpers.data.geographies.SOUTH_AMERICA', locale),
      value: Geographies.SOUTH_AMERICA,
    },
    {
      text: i18n.t('helpers.data.geographies.AFRICA', locale),
      value: Geographies.AFRICA,
    },
    {
      text: i18n.t('helpers.data.geographies.AUSTRALIA_OCEANIA', locale),
      value: Geographies.AUSTRALIA_OCEANIA,
    },
  ];
}

export function currencySelectItems(): VuetifySelectItem<Currency>[] {
  return [
    {
      text: 'EUR',
      value: Currencies.EUR,
    },
    {
      text: 'USD',
      value: Currencies.USD,
    },
    {
      text: 'CHF',
      value: Currencies.CHF,
    },
  ];
}

export function timeZoneSelectItems(locale: Language | '' = ''): VuetifySelectItem<string>[] {
  return rawTimeZones.map((timeZone) => ({
    text: i18n.t(`helpers.data.timeZones.${timeZone.name}`, locale),
    value: timeZone.name,
  }));
}

export function productReportTypeSelectItems(): VuetifySelectItem<ProductReportType>[] {
  return [
    {
      text: i18n.t('helpers.data.productReportTypes.ANNUAL_REPORT'),
      value: ProductReportTypes.ANNUAL_REPORT,
    },
    {
      text: i18n.t('helpers.data.productReportTypes.QUARTERLY_REPORT'),
      value: ProductReportTypes.QUARTERLY_REPORT,
    },
    {
      text: i18n.t('helpers.data.productReportTypes.MONTHLY_REPORT'),
      value: ProductReportTypes.MONTHLY_REPORT,
    },
  ];
}

export function salutationSelectItems(): VuetifySelectItem<Salutation>[] {
  return [
    {
      text: i18n.t('helpers.data.salutations.MR'),
      value: Salutations.MR,
    },
    {
      text: i18n.t('helpers.data.salutations.MS'),
      value: Salutations.MS,
    },
    {
      text: i18n.t('helpers.data.salutations.MX'),
      value: Salutations.MX,
    },
  ];
}

export function productReportQuarterSelectItems(): VuetifySelectItem<string>[] {
  const quarterSelectItems: VuetifySelectItem<string>[] = [];

  for (let quarter = 1; quarter <= 4; quarter++) {
    quarterSelectItems.push({
      text: i18n.t(`helpers.data.productReportQuarters.QUARTER_${quarter}`),
      value: `${quarter}`,
    });
  }

  return quarterSelectItems;
}

export function productReportMonthSelectItems(): VuetifySelectItem<string>[] {
  const monthSelectItems: VuetifySelectItem<string>[] = [];

  for (let month = 1; month <= 12; month++) {
    monthSelectItems.push({
      text: i18n.t(`helpers.data.productReportMonths.MONTH_${month}`),
      value: `${month}`,
    });
  }

  return monthSelectItems;
}

export function transactionTypeSelectItems(): VuetifySelectItem<TransactionType>[] {
  return [
    {
      text: i18n.t('helpers.data.transactionTypes.INCOMING'),
      value: TransactionTypes.INCOMING,
    },
    {
      text: i18n.t('helpers.data.transactionTypes.OUTGOING'),
      value: TransactionTypes.OUTGOING,
    },
  ];
}

export function assetClassSelectItems(locale: Language | '' = ''): VuetifySelectItem<AssetClass>[] {
  const assetClassSelectItems: VuetifySelectItem<AssetClass>[] = [];

  for (const assetClass of Object.values(AssetClasses)) {
    assetClassSelectItems.push({
      text: i18n.t(`helpers.data.assetClasses.${assetClass}`, locale),
      value: assetClass,
    });
  }

  return assetClassSelectItems;
}

export function dynamicKPITypesSelectItems(): VuetifySelectItem<DynamicKPIType>[] {
  const dynamicKPITypesSelectItems: VuetifySelectItem<DynamicKPIType>[] = [];

  for (const KPIType of Object.values(DynamicKPITypes)) {
    dynamicKPITypesSelectItems.push({
      text: i18n.t(`helpers.data.KPITypes.${KPIType}`),
      value: KPIType,
    });
  }

  return dynamicKPITypesSelectItems;
}

export function investmentStructureSelectItems(locale: Language | '' = ''): VuetifySelectItem<InvestmentStructure>[] {
  const investmentStructuresSelectItems: VuetifySelectItem<InvestmentStructure>[] = [];

  for (const investmentStructure of Object.values(InvestmentStructures)) {
    investmentStructuresSelectItems.push({
      text: i18n.t(`helpers.data.investmentStructures.${investmentStructure}`, locale),
      value: investmentStructure,
    });
  }

  return investmentStructuresSelectItems;
}

export function subAssetClassSelectItems(assetClass: AssetClass, locale: Language | '' = ''): VuetifySelectItem<SubAssetClass>[] {
  const subAssetClassSelectItems: VuetifySelectItem<SubAssetClass>[] = [];

  let subAssetClasses: SubAssetClass[];
  switch (assetClass) {
    case AssetClasses.DIGITAL_ASSETS:
      subAssetClasses = Object.values(DigitalAssetsSubAssetClasses);
      break;
    case AssetClasses.HEDGE_FUNDS:
      subAssetClasses = Object.values(HedgeFundSubAssetClasses);
      break;
    case AssetClasses.INFRASTRUCTURE:
      subAssetClasses = Object.values(InfrastructureSubAssetClasses);
      break;
    case AssetClasses.NATURAL_RESOURCES:
      subAssetClasses = Object.values(NaturalResourcesSubAssetClasses);
      break;
    case AssetClasses.PRIVATE_DEBT:
      subAssetClasses = Object.values(PrivateDebtSubAssetClasses);
      break;
    case AssetClasses.PRIVATE_EQUITY:
      subAssetClasses = Object.values(PrivateEquitySubAssetClasses);
      break;
    case AssetClasses.REAL_ESTATE:
      subAssetClasses = Object.values(RealEstateSubAssetClasses);
      break;
    case AssetClasses.VENTURE_CAPITAL:
      subAssetClasses = Object.values(VentureCapitalSubAssetClasses);
      break;
    default:
      throw new Error('Invalid asset class');
  }

  for (const subAssetClass of subAssetClasses) {
    subAssetClassSelectItems.push({
      text: i18n.t(`helpers.data.subAssetClasses.${subAssetClass}`, locale),
      value: subAssetClass,
    });
  }

  return subAssetClassSelectItems;
}

export function booleanSelectItems(locale: Language | '' = ''): VuetifySelectItem<boolean>[] {
  return [
    {
      text: i18n.t('helpers.data.boolean.TRUE', locale),
      value: true,
    },
    {
      text: i18n.t('helpers.data.boolean.FALSE', locale),
      value: false,
    },
  ];
}

export function featureSelectItems(locale: Language | '' = ''): VuetifySelectItem<Feature>[] {
  const featureSelectItems: VuetifySelectItem<Feature>[] = [];

  for (const feature of Object.values(Features)) {
    featureSelectItems.push({
      text: i18n.t(`helpers.data.features.${feature}`, locale),
      value: feature,
    });
  }

  return featureSelectItems;
}
