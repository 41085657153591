import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { GetInvestmentsOfInvestorForOnboardedProductQuery, InvestmentsOfInvestorForOnboardedProductResult, GetAccessiblePublishedProductsForInvestorQuery, ProductDetails } from './types';

export function getInvestmentsOfInvestorForOnboardedProduct(
  query: GetInvestmentsOfInvestorForOnboardedProductQuery
): Promise<InvestmentsOfInvestorForOnboardedProductResult> {
  const url = generateApiUrl('/api/general-partner/get-investments-of-investor-for-onboarded-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getAccessiblePublishedProductsForInvestor(
  query: GetAccessiblePublishedProductsForInvestorQuery
): Promise<ProductDetails[]> {
  const url = generateApiUrl('/api/general-partner/get-accessible-published-products-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
