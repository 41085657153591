import { render, staticRenderFns } from "./holdings.vue?vue&type=template&id=4cf0ad62&scoped=true&"
import script from "./holdings.vue?vue&type=script&lang=ts&"
export * from "./holdings.vue?vue&type=script&lang=ts&"
import style0 from "./holdings.vue?vue&type=style&index=0&id=4cf0ad62&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cf0ad62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
