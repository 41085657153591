var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"subscription-agreement-templates-to-prefill pt-8",attrs:{"data-cy":"subscription-agreement-templates-to-prefill"}},[_c('a-headline',{staticClass:"mt-6",attrs:{"medium":"","title":_vm.translate('title')}}),_c('v-data-table',{attrs:{"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":"","loading-text":_vm.translate('table.loadingText'),"no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.store.subscriptionAgreementTemplatesToPrefill,"item-key":"subscriptionAgreementTemplateToPrefillId","loading":_vm.store.isGetSubscriptionAgreementTemplatesToPrefillProcessing,"mobile-breakpoint":960},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.productName))])]}},{key:"item.original",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-2 text-truncate"},[_vm._v(_vm._s(item.original.originalFileName))]),_c('span',{staticClass:"d-block body-3 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.uploadedAt(item)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("helpers.data.subscriptionAgreementTemplateToPrefillStatuses." + (item.status))))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"6","bottom":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a-icon-button',_vm._g({attrs:{"small":"","color":"grey","icon":['fas', 'ellipsis-h']}},on))]}}],null,true)},[_c('v-list',{attrs:{"dense":"","data-cy":"user-actions-menu"}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.downloadOriginalFileClicked(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('download'))+" ")])],1),_c('add-document-with-placeholders-to-subscription-agreement-template-to-prefill-dialog',{attrs:{"subscription-agreement-template-to-prefill":item}}),_c('v-list-item',{attrs:{"link":"","disabled":_vm.isDownloadSampleSubscriptionAgreementTemplateToPrefillDisabled(item)},on:{"click":function($event){return _vm.downloadSamplePrefilledFileClicked(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('releaseSubscriptionAgreementTemplateToPrefillDialog.downloadTestDocument'))+" ")])],1),_c('confirmation-dialog',{attrs:{"title":_vm.translate('releaseSubscriptionAgreementTemplateToPrefillDialog.dialogTitle'),"confirm-button-color":"warning","confirm-button-text":_vm.translate('releaseSubscriptionAgreementTemplateToPrefillDialog.dialogConfirmButton'),"disabled":_vm.isReleaseSubscriptionAgreementTemplateToPrefillDisabled(item)},on:{"confirmed":function($event){return _vm.releaseSubscriptionAgreementTemplateToPrefillConfirmed(item)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.translate('releaseSubscriptionAgreementTemplateToPrefillDialog.dialogContent'))+" ")]},proxy:true}],null,true)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.isReleaseSubscriptionAgreementTemplateToPrefillDisabled(item)}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('releaseSubscriptionAgreementTemplateToPrefillDialog.activator'))+" ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }