import { render, staticRenderFns } from "./fundraising-analytics.vue?vue&type=template&id=48a8d7a8&scoped=true&"
import script from "./fundraising-analytics.vue?vue&type=script&lang=ts&"
export * from "./fundraising-analytics.vue?vue&type=script&lang=ts&"
import style0 from "./fundraising-analytics.vue?vue&type=style&index=0&id=48a8d7a8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a8d7a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
