var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.store.isGetInvestmentsForInvestorProcessing)?_c('div',{staticClass:"investments",attrs:{"data-cy":"investments"}},[_c('a-headline',{attrs:{"medium":"","title":_vm.translate('title')}}),_c('v-data-table',{staticClass:"clickable-rows transparent",attrs:{"disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","no-data-text":_vm.translate('table.noDataText'),"headers":_vm.investmentsTableHeaders,"items":_vm.store.investments,"item-key":"investmentId","mobile-breakpoint":960},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-start my-3"},[_c('a-img',{staticClass:"product-image mt-3 mt-sm-0 mr-sm-4",attrs:{"src":item.imageUrl}}),_c('div',[_c('div',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(_vm._s(item.productName))]),_c('div',{staticClass:"body-3"},[_vm._v(" "+_vm._s(_vm.$t(("helpers.data.assetClasses." + (item.assetClass))))+" • "+_vm._s(_vm.$t(("helpers.data.subAssetClasses." + (item.subAssetClass))))+" "),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("• "+_vm._s(_vm.$t(("helpers.data.investmentStructures." + (item.investmentStructure)))))])])])],1)]}},{key:"item.committedCapital",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-md-right"},[_c('div',{staticClass:"body-3 grey--text text--darken-2"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.shares,null, true, _vm.DISPLAY_DECIMALS.INVESTMENT.shares))+" "+_vm._s(_vm.translate('table.shares')))]),_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.committedCapital,item.productCurrency)))])])]}},{key:"item.latestValuePerShare",fn:function(ref){
var item = ref.item;
return [(item.latestValuePerShare)?_c('div',{staticClass:"text-left text-md-right"},[_c('div',{staticClass:"body-3 grey--text text--darken-2"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.latestValuePerShare,item.productCurrency))+" "+_vm._s(_vm.translate('table.perShare')))]),_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.latestInvestmentValue,item.productCurrency)))])]):_vm._e()]}},{key:"item.placementAgent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.placementAgent)+" "),(!item.placementAgent)?_c('span',{staticClass:"d-sm-none"},[_vm._v("-")]):_vm._e()]}},{key:"item.closingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.closingDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isActionsMenuVisible(item))?_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"6","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a-icon-button',_vm._g({attrs:{"small":"","color":"grey","icon":['fas', 'ellipsis-h']}},on))]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.isUpdateInvestmentForInvestorVisible)?_c('update-investment-for-investor-dialog',{attrs:{"investment":item}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'pencil']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('table.updateInvestmentTooltip'))+" ")])],1)],1):_vm._e(),_c('investment-documents-dialog',{attrs:{"investment-id":item.investmentId}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'file']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('table.documents'))+" ")])],1)],1),(_vm.isDeleteInvestmentForInvestorVisible)?_c('confirmation-with-password-dialog',{attrs:{"title":_vm.translate('deleteInvestmentForInvestorDialog.dialogTitle'),"confirm-button-text":_vm.translate('deleteInvestmentForInvestorDialog.dialogConfirmButton'),"confirm-button-color":"error"},on:{"confirmed":function($event){return _vm.deleteInvestmentForInvestor(item, $event.currentPassword)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('v-alert',{attrs:{"icon":false,"color":"error"}},[_vm._v(" "+_vm._s(_vm.translate('deleteInvestmentForInvestorDialog.form.warning'))+" ")])]},proxy:true}],null,true)},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"red--text",attrs:{"icon":['fas', 'times']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('table.deleteInvestmentTooltip'))+" ")])],1)],1):_vm._e()],1)],1):_vm._e()]}}],null,false,873352686)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }