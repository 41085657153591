import { CommandWithFiles } from '@/application/types';
import { getLocalStorageItem, LOCAL_STORAGE_BRAINIAC_ID, LOCAL_STORAGE_GENERAL_PARTNER_ID, LOCAL_STORAGE_USER_ID } from '@/helpers/local-storage-helper';

export interface GeneralPartnerAndUserParameter {
  userId: string;
  generalPartnerId: string;
}

export interface GeneralPartnerParameter {
  generalPartnerId: string;
}

export interface UserParameter {
  userId: string;
}

export interface BrainiacParameter {
  brainiacId: string;
}

export interface GeneralPartnerAndUserParameterOnCommandWithFiles {
  body: {
    userId: string;
    generalPartnerId: string;
  }
}

export interface BrainiacParameterOnCommandWithFiles {
  body: {
    brainiacId: string;
  }
}

export function attachGeneralPartnerAndUser<T>(body: T): T & GeneralPartnerAndUserParameter {
  return {
    ...body,
    userId: getLocalStorageItem(LOCAL_STORAGE_USER_ID)!,
    generalPartnerId: getLocalStorageItem(LOCAL_STORAGE_GENERAL_PARTNER_ID)!,
  };
}

export function attachUser<T>(body: T): T & UserParameter {
  return {
    ...body,
    userId: getLocalStorageItem(LOCAL_STORAGE_USER_ID)!,
  };
}

export function attachGeneralPartner<T>(body: T): T & GeneralPartnerParameter {
  return {
    ...body,
    generalPartnerId: getLocalStorageItem(LOCAL_STORAGE_GENERAL_PARTNER_ID)!,
  };
}

export function attachBrainiac<T>(body: T): T & BrainiacParameter {
  return {
    ...body,
    brainiacId: getLocalStorageItem(LOCAL_STORAGE_BRAINIAC_ID)!,
  };
}

export function attachGeneralPartnerAndUserToCommandWithFiles<T extends CommandWithFiles>(command: T)
  : T & GeneralPartnerAndUserParameterOnCommandWithFiles {
  return {
    ...command,
    body: {
      ...command.body,
      userId: getLocalStorageItem(LOCAL_STORAGE_USER_ID)!,
      generalPartnerId: getLocalStorageItem(LOCAL_STORAGE_GENERAL_PARTNER_ID)!,
    },
  };
}

export function attachBrainiacToCommandWithFiles<T extends CommandWithFiles>(command: T)
  : T & BrainiacParameterOnCommandWithFiles {
  return {
    ...command,
    body: {
      ...command.body,
      brainiacId: getLocalStorageItem(LOCAL_STORAGE_BRAINIAC_ID)!,
    },
  };
}
