import { Currency } from '@/types/global';

export type DecimalValue = string | null | undefined;

export interface DecimalDto {
  value: DecimalValue;
  decimals: number;
}

export interface Money {
  amount: Decimal;
  currency: Currency;
}

export class Decimal {

  value: DecimalValue;
  decimals: number;

  constructor(value: DecimalValue, decimals: number) {
    this.value = value;
    this.decimals = decimals;
  }

  toDto(): DecimalDto {
    return {
      value: this.value,
      decimals: this.decimals,
    };
  }

  toNumber(): number {
    return Decimal.convertValueToNumber(this.value, this.decimals);
  }

  // With this method you can get the value by number casting with either `+decimal` or `Number(decimal)`
  valueOf(): number {
    return this.toNumber();
  }

  static convertValueToNumber(decimalValue: DecimalValue, decimals: number): number {
    if (!decimalValue) {
      return 0;
    }

    // divisions by 10^x seems to not run into the floating point precision problem
    return +decimalValue / (10 ** decimals);
  }

  static isDecimal(value: unknown): boolean {
    return value instanceof Decimal;
  }

  static isDecimalDto(value: unknown): boolean {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    const keys = Object.keys(value);

    return keys.length === 2
      && keys.includes('value')
      && keys.includes('decimals');
  }
}
