var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"pending-invitations-tab"}},[_c('v-data-table',{staticClass:"mb-0",attrs:{"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":"","loading-text":_vm.translate('table.loadingText'),"no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.store.pendingInvitations,"item-key":"userInviteId","loading":_vm.store.isGetUsersProcessing,"mobile-breakpoint":960,"must-sort":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-block d-md-flex"},[_c('div',[_c('a-pill',{staticClass:"mr-2 whitespace-nowrap",attrs:{"text":_vm.accountTypeLabel(item),"color":_vm.accountTypePillColor(item)}})],1),_c('div',[_c('div',{staticClass:"grey--text text--darken-4"},[_vm._v(" "+_vm._s(item.name.firstName)+" "+_vm._s(item.name.lastName)+" ")]),_c('div',{staticClass:"body-3"},[_vm._v(" "+_vm._s(item.emailAddress)+" ")])])])]}},{key:"item.investorName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.investorName(item.investor))+" ")]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(_vm._f("formatDateTime")(item.expiresAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isActionMenuVisible)?_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"6","bottom":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a-icon-button',_vm._g({attrs:{"small":"","color":"grey","icon":['fas', 'ellipsis-h']}},on))]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('confirmation-dialog',{attrs:{"confirm-button-color":"primary","title":_vm.translate('resendUserInviteDialog.confirmationTitle'),"confirm-button-text":_vm.translate('resendUserInviteDialog.confirmButtonText'),"disabled":_vm.areActionsDisabled},on:{"confirmed":function($event){return _vm.resendUserInviteConfirmed(item)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('resendUserInviteDialog.confirmationDescription', { name: ((item.name.firstName) + " " + (item.name.lastName)) }))}})]},proxy:true}],null,true)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.areActionsDisabled}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'paper-plane']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('table.actions.resendUserInvite'))+" ")])],1)],1),_c('confirmation-dialog',{attrs:{"confirm-button-color":"primary","title":_vm.translate('withdrawUserInviteDialog.confirmationTitle'),"confirm-button-text":_vm.translate('withdrawUserInviteDialog.confirmButtonText'),"disabled":_vm.areActionsDisabled},on:{"confirmed":function($event){return _vm.withdrawUserInviteConfirmed(item)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('withdrawUserInviteDialog.confirmationDescription', { name: ((item.name.firstName) + " " + (item.name.lastName)) }))}})]},proxy:true}],null,true)},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.areActionsDisabled}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"red--text",attrs:{"icon":['far', 'ban']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('table.actions.withdrawUserInvite'))+" ")])],1)],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }