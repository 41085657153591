import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { Authentication, LoginCommand, ConfirmTwoFactorAuthenticationCommand, DirectLoginResponse, TwoFactorAuthenticationLoginResponse } from './types';

export function login(command: LoginCommand): Promise<DirectLoginResponse|TwoFactorAuthenticationLoginResponse> {
  const url = generateApiUrl('/brain-api/unauthorized/application/authentication/login');
  return performApiRequest<DirectLoginResponse|TwoFactorAuthenticationLoginResponse>({ url, method: 'POST', data: command });
}

export function confirmTwoFactorAuthentication(command: ConfirmTwoFactorAuthenticationCommand): Promise<Authentication> {
  const url = generateApiUrl('/brain-api/unauthorized/application/authentication/confirm-two-factor-authentication');
  return performApiRequest<Authentication>({ url, method: 'POST', data: command });
}

export function logout(): Promise<void> {
  const url = generateApiUrl('/brain-api/unauthorized/application/authentication/logout');
  return performApiRequest({ url, method: 'POST' });
}

export function getAuthentication(): Promise<Authentication> {
  const url = generateApiUrl('/brain-api/application/authentication/authentication');
  return performApiRequest<Authentication>({ url, method: 'GET' });
}
