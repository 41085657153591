import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { InternalSnack, Snack } from '@/application/common/snackbar/types';

interface SnackbarState {
  snackQueue: InternalSnack[];
}

function initialState(): SnackbarState {
  return {
    snackQueue: [],
  };
}

export const useSnackbarStore = defineStore('snackbarStore', {
  state: (): SnackbarState => initialState(),
  getters: {
    firstSnackInQueue: (state: SnackbarState): InternalSnack | null =>
      state.snackQueue[0] || null,
    snackQueueLength: (state: SnackbarState): number =>
      state.snackQueue.length,
  },
  actions: {

    // -- State management

    showSuccess(payload: Snack): Promise<void> {
      this.snackQueue.push({
        id: uuidv4(),
        type: 'success',
        ...payload,
      });

      return Promise.resolve();
    },

    showInfo(payload: Snack): Promise<void> {
      this.snackQueue.push({
        id: uuidv4(),
        type: 'info',
        ...payload,
      });

      return Promise.resolve();
    },

    removeSnackFromQueue(snack: InternalSnack): Promise<void> {
      this.snackQueue = this.snackQueue.filter((currentSnack) => currentSnack.id !== snack.id);

      return Promise.resolve();
    },

    showError(payload: Snack): Promise<void> {
      this.snackQueue.push({
        id: uuidv4(),
        type: 'error',
        ...payload,
      });

      return Promise.resolve();
    },
  },
});
