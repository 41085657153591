import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { CreateGeneralPartnerCommand, CreateGeneralPartnerUserAccountCommand, GeneralPartnerConfigurations, GeneralPartnerCreationStep, GeneralPartnerCreationSteps, GeneralPartnerFormValues, GeneralPartnerLegalAndPolicyData, GeneralPartnerMasterData, GeneralPartnerUserDetailsData } from './types';
import { createGeneralPartner, createGeneralPartnerUserAccount } from './service';

interface BrainGeneralPartnerCreationState {
  formValues: GeneralPartnerFormValues;
  createGeneralPartnerStatus: ActionStatus;
  createGeneralPartnerUserAccountStatus: ActionStatus;

  currentGeneralPartnerCreationStep: GeneralPartnerCreationStep;
}

function initialState(): BrainGeneralPartnerCreationState {
  return {
    formValues: {
      masterData: {
        title: null,
        street: null,
        postal: null,
        city: null,
        country: null,
        host: null,
        logo: null,
        heroImage: null,
      },
      configurations: {
        defaultProductContactEmailAddress: null,
        isTwoFactorAuthenticationEnabled: false,
        isTwoFactorAuthenticationWithSMSEnabled: false,
        mailerSenderEmail: null,
        mailerDSN: null,
        colorScheme: null,
      },
      legalAndPolicyData: {
        imprint: null,
        dataProtection: null,
      },
      userDetailsData: {
        salutation: null,
        name: null,
        emailAddress: null,
        phoneNumber: null,
        countryOfResidence: null,
        language: null,
        timeZone: null,
      },
    },
    createGeneralPartnerStatus: ActionStatus.None,
    createGeneralPartnerUserAccountStatus: ActionStatus.None,

    currentGeneralPartnerCreationStep: GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_MASTER_DATA,
  };
}

export const useBrainGeneralPartnerCreationStore = defineStore('brainGeneralPartnerCreation', {
  state: (): BrainGeneralPartnerCreationState => initialState(),
  getters: {
    isCreateGeneralPartnerProcessing: (state: BrainGeneralPartnerCreationState): boolean =>
      state.createGeneralPartnerStatus === ActionStatus.InProgress,
    isCreateGeneralPartnerUserAccountProcessing: (state: BrainGeneralPartnerCreationState): boolean =>
      state.createGeneralPartnerUserAccountStatus === ActionStatus.InProgress,
    isDefineGeneralPartnerMasterDataCurrentStep: (state: BrainGeneralPartnerCreationState): boolean =>
      state.currentGeneralPartnerCreationStep === GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_MASTER_DATA,
    isDefineGeneralPartnerConfigurationsCurrentStep: (state: BrainGeneralPartnerCreationState): boolean =>
      state.currentGeneralPartnerCreationStep === GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_CONFIGURATIONS,
    isDefineGeneralPartnerLegalAndPolicyCurrentStep: (state: BrainGeneralPartnerCreationState): boolean =>
      state.currentGeneralPartnerCreationStep === GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY,
    isDefineGeneralPartnerUserDetailsCurrentStep: (state: BrainGeneralPartnerCreationState): boolean =>
      state.currentGeneralPartnerCreationStep === GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_USER_DETAILS,

  },
  actions: {
    // -- State management

    updateGeneralPartnerMasterDataFormValues(formValues: GeneralPartnerMasterData): Promise<void> {
      this.formValues.masterData = formValues;

      return Promise.resolve();
    },

    updateGeneralPartnerConfigurationsDataFormValues(formValues: GeneralPartnerConfigurations): Promise<void> {
      this.formValues.configurations = formValues;

      return Promise.resolve();
    },

    updateGeneralPartnerLegalAndPolicyDataFormValues(formValues: GeneralPartnerLegalAndPolicyData): Promise<void> {
      this.formValues.legalAndPolicyData = formValues;

      return Promise.resolve();
    },

    updateGeneralPartnerUserDataFormValues(formValues: GeneralPartnerUserDetailsData): Promise<void> {
      this.formValues.userDetailsData = formValues;

      return Promise.resolve();
    },

    goToNextStep(): Promise<void> {
      switch (this.currentGeneralPartnerCreationStep) {
        case GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_MASTER_DATA:
          this.currentGeneralPartnerCreationStep = GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_CONFIGURATIONS;
          break;
        case GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_CONFIGURATIONS:
          this.currentGeneralPartnerCreationStep = GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY;
          break;
        case GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY:
          this.currentGeneralPartnerCreationStep = GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_USER_DETAILS;
          break;
        default:
          throw new Error('Next general partner creation step wasn\'t found');
      }

      return Promise.resolve();
    },

    goToPreviousStep(): Promise<void> {
      switch (this.currentGeneralPartnerCreationStep) {
        case GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_CONFIGURATIONS:
          this.currentGeneralPartnerCreationStep = GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_MASTER_DATA;
          break;
        case GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY:
          this.currentGeneralPartnerCreationStep = GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_CONFIGURATIONS;
          break;
        case GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_USER_DETAILS:
          this.currentGeneralPartnerCreationStep = GeneralPartnerCreationSteps.DEFINE_GENERAL_PARTNER_LEGAL_AND_POLICY;
          break;
        default:
          throw new Error('Next general partner creation step wasn\'t found');
      }

      return Promise.resolve();
    },

    // -- Commands

    createGeneralPartner(command: CreateGeneralPartnerCommand): Promise<void> {
      const { createGeneralPartnerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createGeneralPartnerStatus,
        () => createGeneralPartner(command)
      );
    },

    createGeneralPartnerUserAccount(command: CreateGeneralPartnerUserAccountCommand): Promise<void> {
      const { createGeneralPartnerUserAccountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createGeneralPartnerUserAccountStatus,
        () => createGeneralPartnerUserAccount(command)
      );
    },

  },
});
