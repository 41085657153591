import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Utc from 'dayjs/plugin/utc';
import TimeZone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(Utc);
dayjs.extend(TimeZone);

dayjs.locale('en');
