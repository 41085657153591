import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { CreatePlatformNewsCommand, PlatformNews, UpdatePlatformNewsCommand } from './types';
import { createPlatformNews, getPlatformNews, updatePlatformNews } from './service';

interface BrainPlatformNewsState {
  platformNews: PlatformNews[];

  createPlatformNewsStatus: ActionStatus;
  updatePlatformNewsStatus: ActionStatus;
  getPlatformNewsStatus: ActionStatus;
}

function initialState(): BrainPlatformNewsState {
  return {
    platformNews: [],

    createPlatformNewsStatus: ActionStatus.None,
    updatePlatformNewsStatus: ActionStatus.None,
    getPlatformNewsStatus: ActionStatus.None,
  };
}

export const useBrainPlatformNewsStore = defineStore('brainPlatformNews', {
  state: (): BrainPlatformNewsState => initialState(),
  getters: {
    isCreatePlatformNewsProcessing: (state: BrainPlatformNewsState): boolean =>
      state.createPlatformNewsStatus === ActionStatus.InProgress,
    isUpdatePlatformNewsProcessing: (state: BrainPlatformNewsState): boolean =>
      state.updatePlatformNewsStatus === ActionStatus.InProgress,
    isGetPlatformNewsProcessing: (state: BrainPlatformNewsState): boolean =>
      state.getPlatformNewsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getPlatformNews(): Promise<void> {
      const { getPlatformNewsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPlatformNewsStatus,
        () => getPlatformNews()
          .then((platformNews) => {
            this.platformNews = platformNews;
          })
      );
    },

    // -- Commands

    createPlatformNews(command: CreatePlatformNewsCommand): Promise<void> {
      const { createPlatformNewsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPlatformNewsStatus,
        () => createPlatformNews(command)
          .then(() => this.getPlatformNews())
      );
    },

    updatePlatformNews(command: UpdatePlatformNewsCommand): Promise<void> {
      const { updatePlatformNewsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePlatformNewsStatus,
        () => updatePlatformNews(command)
          .then(() => this.getPlatformNews())
      );
    },

  },
});
