import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CreateDueDiligenceDirectoryCommand, CreateDueDiligenceFileCommand, DeleteDueDiligenceDirectoryCommand, DeleteDueDiligenceFileCommand, GetAvailableDueDiligenceFilesForProductQuery, GetBreadCrumbsForDueDiligenceDataRoomQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetPreviewForDueDiligenceFileAndDirectoryChangesQuery, GetUNodesForDueDiligenceDataRoomQuery, PinDueDiligenceFileCommand, UnpinDueDiligenceFileCommand, UpdateDueDiligenceFileCommand, UpdateProductPinnedDueDiligenceFilesOrderCommand, UploadDueDiligenceFileWithPathCommand, FileForChangesPreview, DirectoryForChangesPreview, BreadCrumb, UNode, DueDiligenceUNode, GetPreviewForDueDiligenceFileChangesQuery, CreateDueDiligenceFileWithVisibleLanguagesForClientCommand, UpdateDueDiligenceFileWithVisibleLanguagesForClientCommand, UpdateDueDiligenceDirectoryNameAndVisibleLanguagesForClientCommand, UpdateDueDiligenceFileVisibleLanguagesForClientCommand, UpdatePrivateDueDiligenceFileCommand, CreatePrivateDueDiligenceFileCommand, SendMessageToInvestorUsersCommand, UpdateUsersWithExplicitAccessForFileCommand, CreateProductNDACommand, DeleteProductNDACommand, GetProductNDAForDownloadQuery } from './types';

export function getAvailableDueDiligenceFilesForProduct(query: GetAvailableDueDiligenceFilesForProductQuery): Promise<DueDiligenceUNode[]> {
  const url = generateApiUrl('/api/general-partner/get-available-due-diligence-files-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-due-diligence-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function pinDueDiligenceFile(command: PinDueDiligenceFileCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/pin-product-due-diligence-file-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function unpinDueDiligenceFile(command: UnpinDueDiligenceFileCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/unpin-product-due-diligence-file-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductPinnedDueDiligenceFilesOrder(command: UpdateProductPinnedDueDiligenceFilesOrderCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-pinned-due-diligence-files-order-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getBreadCrumbsForDueDiligenceDataRoom(query: GetBreadCrumbsForDueDiligenceDataRoomQuery): Promise<BreadCrumb[]> {
  const url = generateApiUrl('/api/general-partner/get-bread-crumbs-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getUNodesForDueDiligenceDataRoom(query: GetUNodesForDueDiligenceDataRoomQuery): Promise<UNode[]> {
  const url = generateApiUrl('/api/general-partner/get-u-nodes-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-due-diligence-directory-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function createDueDiligenceDirectory(command: CreateDueDiligenceDirectoryCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-due-diligence-directory-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createDueDiligenceFile(
  command: CreateDueDiligenceFileCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-due-diligence-file-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function createDueDiligenceFileWithVisibleLanguagesForClient(
  command: CreateDueDiligenceFileWithVisibleLanguagesForClientCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-due-diligence-file-with-visible-languages-for-client-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function createPrivateDueDiligenceFile(
  command: CreatePrivateDueDiligenceFileCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-private-due-diligence-file-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function updateDueDiligenceFile(
  command: UpdateDueDiligenceFileCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-due-diligence-file-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function updateDueDiligenceFileWithVisibleLanguagesForClient(
  command: UpdateDueDiligenceFileWithVisibleLanguagesForClientCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-due-diligence-file-with-visible-languages-for-client-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function updatePrivateDueDiligenceFile(
  command: UpdatePrivateDueDiligenceFileCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-private-due-diligence-file-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function createProductNDA(
  command: CreateProductNDACommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-product-nda-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteProductNDA(command: DeleteProductNDACommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-nda-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getPreviewForDueDiligenceFileAndDirectoryChanges(
  query: GetPreviewForDueDiligenceFileAndDirectoryChangesQuery
): Promise<(FileForChangesPreview|DirectoryForChangesPreview)[]> {
  const url = generateApiUrl('/api/general-partner/get-preview-for-due-diligence-file-and-directory-changes-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getPreviewForDueDiligenceFileChanges(
  query: GetPreviewForDueDiligenceFileChangesQuery
): Promise<(FileForChangesPreview)[]> {
  const url = generateApiUrl('/api/general-partner/get-preview-for-due-diligence-file-changes-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductNDAForDownload(query: GetProductNDAForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-product-nda-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function uploadDueDiligenceFileWithPath(
  command: UploadDueDiligenceFileWithPathCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/upload-due-diligence-file-with-path-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function updateDueDiligenceDirectoryNameAndVisibleLanguagesForClient(
  command: UpdateDueDiligenceDirectoryNameAndVisibleLanguagesForClientCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-due-diligence-directory-name-and-visible-languages-for-client-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateDueDiligenceFileVisibibleLanguageForClient(
  command: UpdateDueDiligenceFileVisibleLanguagesForClientCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-due-diligence-file-visible-languages-for-client-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteDueDiligenceFile(command: DeleteDueDiligenceFileCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-due-diligence-file-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteDueDiligenceDirectory(command: DeleteDueDiligenceDirectoryCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-due-diligence-directory-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function sendMessageToInvestorUsers(command: SendMessageToInvestorUsersCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/send-message-to-investor-users-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateUsersWithExplicitAccessForFile(command: UpdateUsersWithExplicitAccessForFileCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-users-with-explicit-access-for-file-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
