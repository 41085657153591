import { generateApiUrl, performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { GetAvailableDueDiligenceFilesForProductQuery, GetBreadCrumbsForDueDiligenceDataRoomQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetUNodesForDueDiligenceDataRoomQuery, BreadCrumb, UNode, DueDiligenceUNode } from './types';

export function getAvailableDueDiligenceFilesForProduct(query: GetAvailableDueDiligenceFilesForProductQuery): Promise<DueDiligenceUNode[]> {
  const url = generateApiUrl('/api/general-partner/get-available-due-diligence-files-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-due-diligence-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getBreadCrumbsForDueDiligenceDataRoom(query: GetBreadCrumbsForDueDiligenceDataRoomQuery): Promise<BreadCrumb[]> {
  const url = generateApiUrl('/api/general-partner/get-bread-crumbs-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getUNodesForDueDiligenceDataRoom(query: GetUNodesForDueDiligenceDataRoomQuery): Promise<UNode[]> {
  const url = generateApiUrl('/api/general-partner/get-u-nodes-for-due-diligence-data-room-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-due-diligence-directory-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
