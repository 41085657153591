import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { CreateAccountStep, CreateAccountFormValues, CreateGeneralPartnerUserAccountCommand, GeneralPartnerUserInvite, GetGeneralPartnerUserInviteByTokenQuery, MoveToSecureAccountStepCommand } from './types';
import { createGeneralPartnerUserAccount, getGeneralPartnerUserInviteByToken } from './service';

interface GeneralPartnerUserAccountCreationState {
  currentCreateAccountStep: CreateAccountStep;
  createAccountFormValues: CreateAccountFormValues;
  generalPartnerUserInvite: GeneralPartnerUserInvite | null;

  getGeneralPartnerUserInviteByTokenStatus: ActionStatus;
  createGeneralPartnerUserAccountStatus: ActionStatus;
}

function initialState(): GeneralPartnerUserAccountCreationState {
  return {
    currentCreateAccountStep: CreateAccountStep.ACCEPT_INVITATION,
    createAccountFormValues: {},
    generalPartnerUserInvite: null,

    getGeneralPartnerUserInviteByTokenStatus: ActionStatus.None,
    createGeneralPartnerUserAccountStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerUserAccountCreationStore = defineStore('generalPartnerUserAccountCreation', {
  state: (): GeneralPartnerUserAccountCreationState => initialState(),
  getters: {
    isGetGeneralPartnerUserInviteByTokenProcessing: (state: GeneralPartnerUserAccountCreationState): boolean =>
      state.getGeneralPartnerUserInviteByTokenStatus === ActionStatus.InProgress,
    isCreateGeneralPartnerUserAccountProcessing: (state: GeneralPartnerUserAccountCreationState): boolean =>
      state.createGeneralPartnerUserAccountStatus === ActionStatus.InProgress,

    isAcceptInvitationStep: (state: GeneralPartnerUserAccountCreationState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.ACCEPT_INVITATION,
    isPersonalInformationStep: (state: GeneralPartnerUserAccountCreationState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.PERSONAL_INFORMATION,
    isSecureAccountStep: (state: GeneralPartnerUserAccountCreationState): boolean =>
      state.currentCreateAccountStep === CreateAccountStep.SECURE_ACCOUNT,
  },
  actions: {

    // -- State management

    moveToPersonalInformationStep(): Promise<void> {
      this.currentCreateAccountStep = CreateAccountStep.PERSONAL_INFORMATION;

      return Promise.resolve();
    },

    moveToSecureAccountStep(command: MoveToSecureAccountStepCommand): Promise<void> {
      this.currentCreateAccountStep = CreateAccountStep.SECURE_ACCOUNT;
      this.createAccountFormValues = {
        ...this.createAccountFormValues,
        ...command.createAccountFormValues,
      };

      return Promise.resolve();
    },

    // -- Queries

    getGeneralPartnerUserInviteByToken(query: GetGeneralPartnerUserInviteByTokenQuery): Promise<void> {
      const { getGeneralPartnerUserInviteByTokenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerUserInviteByTokenStatus,
        () => getGeneralPartnerUserInviteByToken(query)
          .then((userInvite) => {
            this.generalPartnerUserInvite = userInvite;
          })
      );
    },

    // -- Commands

    createGeneralPartnerUserAccount(command: CreateGeneralPartnerUserAccountCommand): Promise<void> {
      const { createGeneralPartnerUserAccountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createGeneralPartnerUserAccountStatus,
        () => createGeneralPartnerUserAccount(command)
      );
    },

  },
});
