import { languageName } from '@/helpers/language-helpers';
import { ProductReportdocument } from '@/types/global';

export function getFormattedProductReportsIntoLine(productReportDocuments: ProductReportdocument[]) {
  let formattedProductReports = '';

  productReportDocuments.forEach((productReportDocument: ProductReportdocument) => {
    const language = languageName(productReportDocument.language);
    formattedProductReports += `<br>${language}: ${productReportDocument.fileName}`;
  });

  return formattedProductReports;
}
