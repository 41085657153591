import { DropzoneFileWithRelativePath } from './types';
import { DropzoneFile } from './dropzone';

/**
 * The `relativePath` key is only set if it is a file from a directory upload.
 * Also, directory uploads are only allowed in due diligence data room.
 */
export function getFilesWithRelativePath(files: (DropzoneFileWithRelativePath|DropzoneFile)[]): DropzoneFileWithRelativePath[] {
  // eslint-disable-next-line max-len
  return files.filter((file) => Object.prototype.hasOwnProperty.call(file, 'relativePath')) as DropzoneFileWithRelativePath[];
}

/** Excludes all files from directory upload. */
export function getFilesWithoutRelativePath(files: (DropzoneFileWithRelativePath|DropzoneFile)[]): DropzoneFile[] {
  return files.filter((file) => !Object.prototype.hasOwnProperty.call(file, 'relativePath'));
}
