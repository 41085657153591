import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { Decimal } from '@/models/decimal';
import { DECIMALS } from '@/helpers/global-configs';
import { calculatePercentage, CalculatePercentageInputMap, CalculatePercentageOutputMap } from '@/helpers/calculate-percentage';
import { wrapActionWithProgress } from '@/store';
import { getInvestmentOverviewsByProduct, getInvestorForDashboard, getPublishedProducts, getSubscriptionProcessesForDashboard } from './service';
import { GetPublishedProductsQuery, InvestmentOverviewByProduct, Investor, Product, SubscriptionProcess } from './types';

interface InvestorDashboardState {
  hoveredInvestmentOverviewByProductId: string | null;
  selectedInvestmentOverviewByProductId: string | null;
  publishedProducts: Product[];
  investor: Investor | null;
  subscriptionProcesses: SubscriptionProcess[];
  investmentOverviewsByProduct: InvestmentOverviewByProduct[];

  getInvestorStatus: ActionStatus;
  getSubscriptionProcessesStatus: ActionStatus;
  getInvestmentOverviewsByProductStatus: ActionStatus;
  getPublishedProductsStatus: ActionStatus;
}

function initialState(): InvestorDashboardState {
  return {
    hoveredInvestmentOverviewByProductId: null,
    selectedInvestmentOverviewByProductId: null,
    publishedProducts: [],
    investor: null,
    subscriptionProcesses: [],
    investmentOverviewsByProduct: [],

    getInvestorStatus: ActionStatus.None,
    getSubscriptionProcessesStatus: ActionStatus.None,
    getInvestmentOverviewsByProductStatus: ActionStatus.None,
    getPublishedProductsStatus: ActionStatus.None,
  };
}

export const useInvestorDashboardStore = defineStore('investorDashboard', {
  state: (): InvestorDashboardState => initialState(),
  getters: {
    isGetInvestorProcessing: (state: InvestorDashboardState): boolean =>
      state.getInvestorStatus === ActionStatus.InProgress,
    isGetSubscriptionProcessesProcessing: (state: InvestorDashboardState): boolean =>
      state.getSubscriptionProcessesStatus === ActionStatus.InProgress,
    isGetInvestmentOverviewsByProductProcessing: (state: InvestorDashboardState): boolean =>
      state.getInvestmentOverviewsByProductStatus === ActionStatus.InProgress,
    isGetPublishedProductsProcessing: (state: InvestorDashboardState): boolean =>
      state.getPublishedProductsStatus === ActionStatus.InProgress,
    firstThreeProducts: (state: InvestorDashboardState): Product[] =>
      state.publishedProducts.slice(0, 3),
    investmentAmountsByCurrency(state: InvestorDashboardState): { [id: string]: number } {
      const investmentAmountsByCurrency: { [id: string]: number } = {};

      state.subscriptionProcesses.forEach((subscriptionProcess) => {
        investmentAmountsByCurrency[subscriptionProcess.currency]
          = (investmentAmountsByCurrency[subscriptionProcess.currency] || 0)
          + +(subscriptionProcess.investmentAmount?.value || 0);
      });

      return investmentAmountsByCurrency;
    },
    committedCapitalByCurrency(state: InvestorDashboardState): { [id: string]: number } {
      const result: { [id: string]: number } = {};
      const committedCapitalByCurrency: { [id: string]: any } = {};

      state.investmentOverviewsByProduct.forEach((investmentOverviewByProduct) => {
        const previousValue = committedCapitalByCurrency[investmentOverviewByProduct.currency] || BigInt(0);

        committedCapitalByCurrency[investmentOverviewByProduct.currency]
          = previousValue + BigInt(investmentOverviewByProduct.committedCapital.value || 0);
      });

      for (const key of Object.keys(committedCapitalByCurrency)) {
        result[key] = Decimal.convertValueToNumber(committedCapitalByCurrency[key].toString(), DECIMALS.INVESTMENT.committedCapital);
      }

      return result;
    },
    investmentOverviewPercentages(state: InvestorDashboardState): CalculatePercentageOutputMap {
      const calculatePercentageInputMap: CalculatePercentageInputMap = {};

      state.investmentOverviewsByProduct.forEach((productInvestmentOverview) => {
        calculatePercentageInputMap[productInvestmentOverview.productId] = +(productInvestmentOverview.committedCapital.value || 0);
      });

      let calculatePercentageOutputMap: CalculatePercentageOutputMap = {};
      if (Object.keys(calculatePercentageInputMap).length > 0) {
        calculatePercentageOutputMap = calculatePercentage(calculatePercentageInputMap);
      }

      return calculatePercentageOutputMap; // calculatePercentage(calculatePercentageInputMap);
    },
  },
  actions: {
    // -- State management

    updateSelectedInvestmentOverviewByProductId(id: string | null): Promise<void> {
      this.selectedInvestmentOverviewByProductId = id;

      return Promise.resolve();
    },

    updateHoveredInvestmentOverviewByProductId(id: string | null): Promise<void> {
      this.hoveredInvestmentOverviewByProductId = id;

      return Promise.resolve();
    },

    // -- Queries

    getInvestor(): Promise<void> {
      const { getInvestorStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorStatus,
        () => getInvestorForDashboard()
          .then((investor) => {
            this.investor = investor;
          })
      );
    },

    getSubscriptionProcesses(): Promise<void> {
      const { getSubscriptionProcessesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionProcessesStatus,
        () => getSubscriptionProcessesForDashboard()
          .then((subscriptionProcesses) => {
            this.subscriptionProcesses = subscriptionProcesses;
          })
      );
    },

    getInvestmentOverviewsByProduct(): Promise<void> {
      const { getInvestmentOverviewsByProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestmentOverviewsByProductStatus,
        () => getInvestmentOverviewsByProduct()
          .then((investmentOverviewsByProduct) => {
            this.investmentOverviewsByProduct = investmentOverviewsByProduct;
          })
      );
    },

    getPublishedProducts(): Promise<void> {
      const query: GetPublishedProductsQuery = {};

      const { getPublishedProductsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPublishedProductsStatus,
        () => getPublishedProducts(query)
          .then((publishedProducts) => {
            this.publishedProducts = publishedProducts;
          })
      );
    },

  },
});
