import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { useGeneralPartnerProductManagementGeneralStore } from '@/domain/general-partner/products/product-management/store';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { CreateControllingPersonTemplateToPrefillCommand, CreateSubscriptionDocumentTemplatesCommand, CreateSubscriptionAgreementTemplateToPrefillCommand, DeleteControllingPersonTemplateToPrefillCommand, DeleteSubscriptionAgreementTemplateToPrefillCommand, DeleteSubscriptionDocumentTemplateCommand, DisableDocumentManagementForSubscriptionProcessCommand, EnableDocumentManagementForSubscriptionProcessCommand, GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery, GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery, GetSubscriptionDocumentTemplateFileForDownloadQuery, UpdateKYCInstructionCommand, UpdateSubscriptionInstructionCommand } from './types';
import { getSubscriptionDocumentTemplateFileForDownload, createSubscriptionAgreementTemplateToPrefill, disableDocumentManagementForSubscriptionProcess, enableDocumentManagementForSubscriptionProcess, updateKYCInstruction, updateSubscriptionInstruction, deleteSubscriptionDocumentTemplate, createSubscriptionDocumentTemplate, createControllingPersonTemplateToPrefill, deleteControllingPersonTemplateToPrefill, getControllingPersonTemplateToPrefillOriginalFileForDownload, getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload, deleteSubscriptionAgreementTemplateToPrefill } from './service';

interface GeneralPartnerProductManagementSubscriptionTabState {
  createSubscriptionDocumentTemplatesStatus: ActionStatus;
  deleteSubscriptionDocumentTemplateStatus: ActionStatus;
  getSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus;
  updateSubscriptionInstructionStatus: ActionStatus;
  updateKYCInstructionStatus: ActionStatus;
  enableDocumentManagementForSubscriptionProcessStatus: ActionStatus;
  disableDocumentManagementForSubscriptionProcessStatus: ActionStatus;
  createSubscriptionAgreementTemplateToPrefillStatus: ActionStatus;
  deleteSubscriptionAgreementTemplateToPrefillStatus: ActionStatus;
  getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus: ActionStatus;
  createControllingPersonTemplateToPrefillStatus: ActionStatus;
  deleteControllingPersonTemplateToPrefillStatus: ActionStatus;
  getControllingPersonToPrefillOriginalFileForDownloadStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementSubscriptionTabState {
  return {
    createSubscriptionDocumentTemplatesStatus: ActionStatus.None,
    deleteSubscriptionDocumentTemplateStatus: ActionStatus.None,
    getSubscriptionDocumentTemplateFileForDownloadStatus: ActionStatus.None,
    updateSubscriptionInstructionStatus: ActionStatus.None,
    updateKYCInstructionStatus: ActionStatus.None,
    enableDocumentManagementForSubscriptionProcessStatus: ActionStatus.None,
    disableDocumentManagementForSubscriptionProcessStatus: ActionStatus.None,
    createSubscriptionAgreementTemplateToPrefillStatus: ActionStatus.None,
    deleteSubscriptionAgreementTemplateToPrefillStatus: ActionStatus.None,
    getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus: ActionStatus.None,
    createControllingPersonTemplateToPrefillStatus: ActionStatus.None,
    deleteControllingPersonTemplateToPrefillStatus: ActionStatus.None,
    getControllingPersonToPrefillOriginalFileForDownloadStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementSubscriptionTab = defineStore('generalPartnerProductManagementSubscriptionTab', {
  state: (): GeneralPartnerProductManagementSubscriptionTabState => initialState(),
  getters: {
    isCreateSubscriptionDocumentTemplatesProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.createSubscriptionDocumentTemplatesStatus === ActionStatus.InProgress,
    isDeleteSubscriptionDocumentTemplateProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.deleteSubscriptionDocumentTemplateStatus === ActionStatus.InProgress,
    isGetSubscriptionDocumentTemplateFileForDownloadProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.getSubscriptionDocumentTemplateFileForDownloadStatus === ActionStatus.InProgress,
    isUpdateSubscriptionInstructionProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.updateSubscriptionInstructionStatus === ActionStatus.InProgress,
    isUpdateKYCInstructionProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.updateKYCInstructionStatus === ActionStatus.InProgress,
    isEnableDocumentManagementForSubscriptionProcessProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.enableDocumentManagementForSubscriptionProcessStatus === ActionStatus.InProgress,
    isDisableDocumentManagementForSubscriptionProcessProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.disableDocumentManagementForSubscriptionProcessStatus === ActionStatus.InProgress,
    isCreateSubscriptionAgreementTemplateToPrefillProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.createSubscriptionAgreementTemplateToPrefillStatus === ActionStatus.InProgress,
    isDeleteSubscriptionAgreementTemplateToPrefillProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.deleteSubscriptionAgreementTemplateToPrefillStatus === ActionStatus.InProgress,
    isGetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadProcessing:
      (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
        state.getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus === ActionStatus.InProgress,
    isCreateControllingPersonTemplateToPrefillProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.createControllingPersonTemplateToPrefillStatus === ActionStatus.InProgress,
    isDeleteControllingPersonTemplateToPrefillProcessing: (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
      state.deleteControllingPersonTemplateToPrefillStatus === ActionStatus.InProgress,
    isGetControllingPersonToPrefillOriginalFileForDownloadProcessing:
      (state: GeneralPartnerProductManagementSubscriptionTabState): boolean =>
        state.getControllingPersonToPrefillOriginalFileForDownloadStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getSubscriptionDocumentTemplateFileForDownload(query: GetSubscriptionDocumentTemplateFileForDownloadQuery): Promise<FileResponse> {

      const { getSubscriptionDocumentTemplateFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionDocumentTemplateFileForDownloadStatus,
        () => getSubscriptionDocumentTemplateFileForDownload(query)
      );
    },

    getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload(
      query: GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery
    ): Promise<FileResponse> {

      const { getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus,
        () => getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload(query)
      );
    },

    getControllingPersonTemplateToPrefillOriginalFileForDownload(
      query: GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery
    ): Promise<FileResponse> {

      const { getControllingPersonToPrefillOriginalFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getControllingPersonToPrefillOriginalFileForDownloadStatus,
        () => getControllingPersonTemplateToPrefillOriginalFileForDownload(query)
      );
    },

    // -- Commands

    deleteSubscriptionAgreementTemplateToPrefill(command: DeleteSubscriptionAgreementTemplateToPrefillCommand): Promise<void> {
      const { deleteSubscriptionAgreementTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSubscriptionAgreementTemplateToPrefillStatus,
        () => deleteSubscriptionAgreementTemplateToPrefill(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    deleteSubscriptionDocumentTemplate(command: DeleteSubscriptionDocumentTemplateCommand): Promise<void> {
      const { deleteSubscriptionDocumentTemplateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSubscriptionDocumentTemplateStatus,
        () => deleteSubscriptionDocumentTemplate(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    deleteControllingPersonTemplateToPrefill(command: DeleteControllingPersonTemplateToPrefillCommand): Promise<void> {
      const { deleteControllingPersonTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteControllingPersonTemplateToPrefillStatus,
        () => deleteControllingPersonTemplateToPrefill(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    disableDocumentManagementForSubscriptionProcess(command: DisableDocumentManagementForSubscriptionProcessCommand): Promise<void> {
      const { disableDocumentManagementForSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableDocumentManagementForSubscriptionProcessStatus,
        () => disableDocumentManagementForSubscriptionProcess(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    enableDocumentManagementForSubscriptionProcess(command: EnableDocumentManagementForSubscriptionProcessCommand): Promise<void> {
      const { enableDocumentManagementForSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableDocumentManagementForSubscriptionProcessStatus,
        () => enableDocumentManagementForSubscriptionProcess(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    updateKYCInstruction(command: UpdateKYCInstructionCommand): Promise<void> {
      const { updateKYCInstructionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateKYCInstructionStatus,
        () => updateKYCInstruction(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    updateSubscriptionInstruction(command: UpdateSubscriptionInstructionCommand): Promise<void> {
      const { updateSubscriptionInstructionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateSubscriptionInstructionStatus,
        () => updateSubscriptionInstruction(command)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct())
      );
    },

    createSubscriptionAgreementTemplateToPrefill(files: File[]): Promise<void[]> {
      const fileUploadQueueStore = useFileUploadQueueStore();

      const queuedFileUploads = files.map((file) => {
        const command: CreateSubscriptionAgreementTemplateToPrefillCommand = {
          body: {
            productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
            language: useGeneralPartnerProductManagementGeneralStore().selectedLanguage,
          },
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createSubscriptionAgreementTemplateToPrefill,
          command,
        };

        return fileUploadQueueStore.addItem(payload)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct());
      });

      const { createSubscriptionAgreementTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSubscriptionAgreementTemplateToPrefillStatus,
        () => Promise.all(queuedFileUploads)
      );
    },

    createSubscriptionDocumentTemplates(files: File[]): Promise<void[]> {
      const fileUploadQueueStore = useFileUploadQueueStore();

      const queuedFileUploads = files.map((file) => {
        const command: CreateSubscriptionDocumentTemplatesCommand = {
          body: {
            productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
          },
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createSubscriptionDocumentTemplate,
          command,
        };

        return fileUploadQueueStore.addItem(payload)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct());
      });

      const { createSubscriptionDocumentTemplatesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSubscriptionDocumentTemplatesStatus,
        () => Promise.all(queuedFileUploads)
      );
    },

    createControllingPersonTemplateToPrefill(files: File[]): Promise<void[]> {
      const fileUploadQueueStore = useFileUploadQueueStore();

      const queuedFileUploads = files.map((file) => {
        const command: CreateControllingPersonTemplateToPrefillCommand = {
          body: {
            productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
            language: useGeneralPartnerProductManagementGeneralStore().selectedLanguage,
          },
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createControllingPersonTemplateToPrefill,
          command,
        };

        return fileUploadQueueStore.addItem(payload)
          .then(() => useGeneralPartnerProductManagementGeneralStore().getCurrentProduct());
      });

      const { createControllingPersonTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createControllingPersonTemplateToPrefillStatus,
        () => Promise.all(queuedFileUploads)
      );
    },

  },
});
