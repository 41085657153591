import { render, staticRenderFns } from "./platform-news-hints.vue?vue&type=template&id=dc1a2096&scoped=true&"
import script from "./platform-news-hints.vue?vue&type=script&lang=ts&"
export * from "./platform-news-hints.vue?vue&type=script&lang=ts&"
import style0 from "./platform-news-hints.vue?vue&type=style&index=0&id=dc1a2096&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc1a2096",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VContainer})
