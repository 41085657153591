import { ConfirmSharedProductCommand, SharedProduct } from '@/domain/brain/network-products/types';
import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachBrainiac } from '@/helpers/default-parameter-helper';

// -- Query

export function getSharedProducts(): Promise<SharedProduct[]> {
  const url = generateApiUrl('/brain-api/get-shared-products-query');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac({}) });
}

// -- Command

export function confirmSharedProduct(command: ConfirmSharedProductCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/confirm-shared-product-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
