import { defineStore } from 'pinia';
import { Product } from './types';

interface InvestorProductComparisonSelectMenuState {
  products: Product[];
}

function initialState(): InvestorProductComparisonSelectMenuState {
  return {
    products: [],
  };
}

export const useInvestorProductComparisonSelectMenuStore = defineStore('investorProductComparisonSelectMenu', {
  state: (): InvestorProductComparisonSelectMenuState => initialState(),
  getters: {
    isAtLeastOneProductSelected: (state: InvestorProductComparisonSelectMenuState): boolean =>
      state.products.length > 0,
    areAtLeastTwoProductsSelected: (state: InvestorProductComparisonSelectMenuState): boolean =>
      state.products.length >= 2,
  },
  actions: {
    // -- State management

    addProductToComparisonSelection(product: Product): Promise<void> {
      const existingProduct = this.products.find((existingProduct: Product) => existingProduct.id === product.id);
      if (!!existingProduct && existingProduct.id === product.id) {
        return Promise.reject();
      }

      if (this.products.length >= 3) {
        return Promise.reject();
      }

      this.products.push(product);

      return Promise.resolve();
    },

    removeProductFromComparisonSelection(productId: string): Promise<void> {
      this.products = this.products
        .filter((product) => product.id !== productId);

      return Promise.resolve();
    },
  },
});
