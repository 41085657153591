import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { AccountTypes } from '@/types/global';
import { PillColor } from '@/components/pill.vue';
import { GetPersonalUserDetailsQuery, GetUserActivityQuery, PersonalUserDetails, UserActivityLogEntry } from './types';
import { getPersonalUserDetails, getUserActivity } from './service';

interface GeneralPartnerUserDetailsState {
  userId: string|null;
  userDetails: PersonalUserDetails|null;
  userActivityLogEntries: UserActivityLogEntry[];
  userActivityLogEntriesTotalCount: number;

  getPersonalUserDetailsStatus: ActionStatus;
  getUserActivityStatus: ActionStatus;
}

function initialState(): GeneralPartnerUserDetailsState {
  return {
    userId: null,
    userDetails: null,
    userActivityLogEntries: [],
    userActivityLogEntriesTotalCount: 0,

    getPersonalUserDetailsStatus: ActionStatus.None,
    getUserActivityStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerUserDetailsStore = defineStore('GeneralPartnerUserDetails', {
  state: (): GeneralPartnerUserDetailsState => initialState(),
  getters: {
    isGetPersonalUserDetailsProcessing: (state: GeneralPartnerUserDetailsState): boolean =>
      state.getPersonalUserDetailsStatus === ActionStatus.InProgress,
    isGetUserActivityProcessing: (state: GeneralPartnerUserDetailsState): boolean =>
      state.getUserActivityStatus === ActionStatus.InProgress,
    isInvestorUser: (state: GeneralPartnerUserDetailsState): boolean =>
      !!state.userDetails
      && state.userDetails.accountType === AccountTypes.INVESTOR,
    userName: (state: GeneralPartnerUserDetailsState): string =>
      state.userDetails
        ? `${state.userDetails!.name.firstName} ${state.userDetails!.name.lastName}`
        : '',
    accountTypePillColor: (state: GeneralPartnerUserDetailsState): PillColor => {
      if (!state.userDetails) {
        return PillColor.PRIMARY_WHITE;
      }

      if (state.userDetails.isLocked) {
        return PillColor.RED;
      }

      if (state.userDetails.accountType === AccountTypes.INVESTOR) {
        return PillColor.PRIMARY_SLIGHTLY_DARKER;
      }

      return PillColor.PRIMARY_WHITE;
    },
  },
  actions: {

    // -- State management

    updateCurrentUserId(currentUserId: string): Promise<void> {
      this.userId = currentUserId;

      return Promise.resolve();
    },

    // -- Queries

    getPersonalUserDetails(): Promise<void> {
      const query: GetPersonalUserDetailsQuery = {
        targetUserId: this.userId!,
      };

      const { getPersonalUserDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonalUserDetailsStatus,
        () => getPersonalUserDetails(query)
          .then((personalUserDetails) => {
            this.userDetails = personalUserDetails;
          })
      );
    },

    getUserActivity(query: GetUserActivityQuery): Promise<void> {
      const { getUserActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserActivityStatus,
        () => getUserActivity(query)
          .then((userActivityLogEntries) => {
            this.userActivityLogEntries = userActivityLogEntries.entries;
            this.userActivityLogEntriesTotalCount = userActivityLogEntries.totalCount;
          })
      );
    },
  },
});
