var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"investment-profiles-list"}},[_c('a-headline',{staticClass:"my-6",attrs:{"large":"","title":_vm.translate('title')}},[_c('create-investment-profile-dialog',{on:{"close":_vm.loadInvestmentProfiles}},[_c('v-btn',{attrs:{"color":"primary","depressed":""}},[_vm._v(" "+_vm._s(_vm.translate('createButton'))+" ")])],1)],1),_c('v-data-table',{staticClass:"mt-4 clickable-rows",attrs:{"disable-pagination":"","disable-filtering":"","hide-default-footer":"","no-data-text":_vm.translate('table.noDataText'),"loading-text":_vm.translate('table.loadingText'),"headers":_vm.tableHeaders,"items":_vm.store.investmentProfiles,"item-key":"investmentProfileId","loading":_vm.store.isGetInvestmentProfilesProcessing,"mobile-breakpoint":960,"data-cy":"investment-profiles-table"},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(_vm._s(item.name))]),(!item.isComplete)?_c('a-pill',{staticClass:"ml-1 whitespace-nowrap",attrs:{"text":_vm.translate('table.incompletePill'),"color":"red"}}):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("helpers.data.investmentProfileTypes." + (item.type))))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"6","bottom":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a-icon-button',_vm._g({attrs:{"small":"","color":"grey","icon":['fas', 'ellipsis-h']}},on))]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(!item.isComplete)?_c('create-investment-profile-dialog',{attrs:{"investment-profile-id":item.investmentProfileId},on:{"close":_vm.loadInvestmentProfiles}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['far', 'pencil']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('completeProfileButton'))+" ")])],1)],1):_vm._e(),_c('confirmation-dialog',{attrs:{"title":_vm.translate('deleteInvestmentProfile.dialogTitle'),"confirm-button-text":_vm.translate('deleteInvestmentProfile.dialogConfirmButton'),"confirm-button-color":"error"},on:{"confirmed":function($event){return _vm.deleteInvestmentProfile(item.investmentProfileId)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.translate('deleteInvestmentProfile.dialogContent')))])]},proxy:true}],null,true)},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"grey--text text--darken-2",attrs:{"icon":['fas', 'times']}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate('deleteInvestmentProfile.openDialogButton'))+" ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }