var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"general-partner-users-tab"}},[_c('v-data-table',{staticClass:"mb-0 clickable-rows",attrs:{"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":"","loading-text":_vm.translate('table.loadingText'),"no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.store.generalPartnerUsers,"item-key":"userId","loading":_vm.store.isGetUsersProcessing,"mobile-breakpoint":960,"must-sort":true,"data-cy":"general-partner-users-tab-table"},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
          'grey--text text--darken-4': !item.isLocked,
          'grey--text text--lighten-1': item.isLocked,
        }},[_vm._v(" "+_vm._s(item.name.firstName)+" "+_vm._s(item.name.lastName)+" ")]),_c('div',{staticClass:"body-3",class:{
          'grey--text text--darken-2': !item.isLocked,
          'grey--text text--lighten-1': item.isLocked,
        }},[_vm._v(" "+_vm._s(item.emailAddress)+" ")]),(item.isLocked)?_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"color":"red","text":_vm.translate('table.lockedUser')}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('user-actions-menu',{attrs:{"user":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }