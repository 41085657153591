import { PillColor } from '@/components/pill.vue';
import { SubscriptionProcessStatus, SubscriptionProcessStatuses } from '@/types/global';

export function getPillColorForSubscriptionProcessStatus(status: SubscriptionProcessStatus): PillColor {
  switch (status) {
    case SubscriptionProcessStatuses.STARTED:
      return PillColor.CYAN;
    case SubscriptionProcessStatuses.IN_PROGRESS:
      return PillColor.PRIMARY;
    case SubscriptionProcessStatuses.IN_REVIEW:
      return PillColor.RED;
    default:
      return PillColor.PURPLE;
  }
}
