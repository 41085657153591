import { generateApiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachBrainiac, attachBrainiacToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { GeneralPartnerLegals, GeneralPartnerConfigurations, GeneralPartnerContactDetails, GetGeneralPartnerContactDetailsQuery, GetGeneralPartnerLegalsQuery, UpdateGeneralPartnerContactDetailsCommand, UpdateGeneralPartnerDefaultProductContactEmailAddressCommand, ToggleTwoFactorAuthenticationCommand, ToggleTwoFactorAuthenticationWithSMSCommand, UpdateGeneralPartnerColorSchemeCommand, UpdateGeneralPartnerDataProtectionCommand, UpdateGeneralPartnerImprintCommand, UpdateGeneralPartnerTitleCommand, UpdateGeneralPartnerLogoCommand, UpdateGeneralPartnerPublicHeroImageCommand, UpdateGeneralPartnerHostCommand, UpdateGeneralPartnerMailerDSNCommand, UpdateGeneralPartnerMailerSenderEmailCommand } from './types';

// -- Queries

export function getGeneralPartnerContactDetails(query: GetGeneralPartnerContactDetailsQuery): Promise<GeneralPartnerContactDetails> {
  const url = generateApiUrl('/brain-api/get-general-partner-contact-details-query');
  return performApiRequest<GeneralPartnerContactDetails>({ url, method: 'POST', data: attachBrainiac(query) });
}

export function getGeneralPartnerConfigurations(query: GetGeneralPartnerContactDetailsQuery): Promise<GeneralPartnerConfigurations> {
  const url = generateApiUrl('/brain-api/get-general-partner-configurations-query');
  return performApiRequest<GeneralPartnerConfigurations>({ url, method: 'POST', data: attachBrainiac(query) });
}

export function getGeneralPartnerLegals(query: GetGeneralPartnerLegalsQuery): Promise<GeneralPartnerLegals> {
  const url = generateApiUrl('/brain-api/get-general-partner-legals-query');
  return performApiRequest<GeneralPartnerLegals>({ url, method: 'POST', data: attachBrainiac(query) });
}

// -- Commands

export function updateGeneralPartnerContactDetails(command: UpdateGeneralPartnerContactDetailsCommand): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-contact-details-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerDefaultProductContactEmailAddress(
  command: UpdateGeneralPartnerDefaultProductContactEmailAddressCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-default-product-contact-email-address-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function toggleTwoFactorAuthentication(
  command: ToggleTwoFactorAuthenticationCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/toggle-two-factor-authentication-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function toggleTwoFactorAuthenticationWithSms(
  command: ToggleTwoFactorAuthenticationWithSMSCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/toggle-two-factor-authentication-with-sms-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerColorScheme(
  command: UpdateGeneralPartnerColorSchemeCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-color-scheme-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerDataProtection(
  command: UpdateGeneralPartnerDataProtectionCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-data-protection-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerImprint(
  command: UpdateGeneralPartnerImprintCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-imprint-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerTitle(
  command: UpdateGeneralPartnerTitleCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-title-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerLogo(
  command: UpdateGeneralPartnerLogoCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-logo-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachBrainiacToCommandWithFiles(command) });
}

export function updateGeneralPartnerPublicHeroImage(
  command: UpdateGeneralPartnerPublicHeroImageCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-public-hero-image-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachBrainiacToCommandWithFiles(command) });
}

export function updateGeneralPartnerHost(
  command: UpdateGeneralPartnerHostCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-host-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerMailerDSN(
  command: UpdateGeneralPartnerMailerDSNCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-mailer-dsn-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}

export function updateGeneralPartnerMailerSenderEmail(
  command: UpdateGeneralPartnerMailerSenderEmailCommand
): Promise<void> {
  const url = generateApiUrl('/brain-api/update-general-partner-mailer-sender-email-command');
  return performApiRequest({ url, method: 'POST', data: attachBrainiac(command) });
}
