import { render, staticRenderFns } from "./image-cropper-upload.vue?vue&type=template&id=3be70d1c&scoped=true&"
import script from "./image-cropper-upload.vue?vue&type=script&lang=ts&"
export * from "./image-cropper-upload.vue?vue&type=script&lang=ts&"
import style0 from "./image-cropper-upload.vue?vue&type=style&index=0&id=3be70d1c&lang=sass&scoped=true&"
import style1 from "cropperjs/dist/cropper.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be70d1c",
  null
  
)

export default component.exports