import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { useAppStore } from '@/application/common/app/store';
import { BreadCrumb, GetBreadCrumbsQuery, GetDueDiligenceDataRoomForDownloadQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetProductForDueDiligenceDataRoomQuery, GetUNodesQuery, Product, UNode, UpdateDirectory } from './types';
import { getBreadCrumbs, getDueDiligenceDataRoomForDownload, getProductForDueDiligenceDataRoom, getDueDiligenceFileForDownload, getUNodes, getDueDiligenceDirectoryForDownload } from './service';

interface InvestorDueDiligenceDataRoomState {
  breadCrumbs: BreadCrumb[];
  currentUNodes: UNode[];
  currentProduct: Product | null;
  currentProductId: string | null;
  currentDirectoryId: string | null;

  getCurrentProductStatus: ActionStatus;
  getBreadCrumbsStatus: ActionStatus;
  getUNodesStatus: ActionStatus;
  getDueDiligenceFileForDownloadStatus: ActionStatus;
  getDueDiligenceDirectoryForDownloadStatus: ActionStatus;
  getDueDiligenceDataRoomForDownloadStatus: ActionStatus;
}

function initialState(): InvestorDueDiligenceDataRoomState {
  return {
    breadCrumbs: [],
    currentUNodes: [],
    currentProduct: null,
    currentProductId: null,
    currentDirectoryId: null,

    getCurrentProductStatus: ActionStatus.None,
    getBreadCrumbsStatus: ActionStatus.None,
    getUNodesStatus: ActionStatus.None,
    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    getDueDiligenceDirectoryForDownloadStatus: ActionStatus.None,
    getDueDiligenceDataRoomForDownloadStatus: ActionStatus.None,
  };
}

export const useInvestorDueDiligenceDataRoomStore = defineStore('investorDueDiligenceDataRoom', {
  state: (): InvestorDueDiligenceDataRoomState => initialState(),
  actions: {

    // -- State management

    updateCurrentDirectory(data: UpdateDirectory): Promise<void> {
      this.currentProductId = data.productId;
      this.currentDirectoryId = data.directoryId || null;

      this.getBreadCrumbs();

      if (this.currentProductId) {
        return this.getUNodes();
      } else {
        return Promise.resolve();
      }
    },

    // -- Queries

    getBreadCrumbs(): Promise<void> {
      const query: GetBreadCrumbsQuery = {
        productId: this.currentProductId!,
      };

      if (this.currentDirectoryId) {
        query.directoryId = this.currentDirectoryId;
      }

      const { getBreadCrumbsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBreadCrumbsStatus,
        () => getBreadCrumbs(query)
          .then((breadCrumbs) => {
            this.breadCrumbs = breadCrumbs;
          })
      );
    },

    getUNodes(): Promise<void> {
      const query: GetUNodesQuery = {
        productId: this.currentProductId!,
        language: useAppStore().selectedLanguage!,
      };

      if (this.currentDirectoryId) {
        query.directoryId = this.currentDirectoryId;
      }

      const { getUNodesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUNodesStatus,
        () => getUNodes(query)
          .then((uNodes) => {
            this.currentUNodes = uNodes;
          })
      );
    },

    getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceFileForDownloadStatus,
        () => getDueDiligenceFileForDownload(query)
      );
    },

    getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceDirectoryForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceDirectoryForDownloadStatus,
        () => getDueDiligenceDirectoryForDownload(query)
      );
    },

    getDueDiligenceDataRoomForDownload(): Promise<FileResponse> {
      const query: GetDueDiligenceDataRoomForDownloadQuery = {
        productId: this.currentProductId!,
      };

      const { getDueDiligenceDataRoomForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceDataRoomForDownloadStatus,
        () => getDueDiligenceDataRoomForDownload(query)
      );
    },

    getCurrentProduct(): Promise<void> {
      const query: GetProductForDueDiligenceDataRoomQuery = {
        productId: this.currentProductId!,
      };

      const { getCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentProductStatus,
        () => getProductForDueDiligenceDataRoom(query)
          .then((product) => {
            this.currentProduct = product;
          })
      );
    },
  },
});
