import { Dayjs } from 'dayjs';
import { Command, CommandWithFiles, Query } from '@/application/types';
import { Decimal } from '@/models/decimal';
import { Currency, InvestmentProfileControllingPersonType, InvestmentProfileEntityType, InvestmentProfileType, PhoneNumber, SourceOfFundsType, SubscriptionProcessStatus, TermSheet } from '@/types/global';

export interface InvestmentProfileDetails {
  id: string;
  name: string;
  type: InvestmentProfileType;
  generalInformation?: InvestmentProfileGeneralInformation;
  authorizedPersons?: InvestmentProfileAuthorizedPerson[];
  controllingPersons?: InvestmentProfileControllingPerson[];
  bankDetails?: InvestmentProfileBankDetails;
  contactDetails?: InvestmentProfileContactDetails;
}

export interface InvestmentProfileGeneralInformation {
  entityType: EntityType;
  countryOfIncorporation: string;
  entityName: string;
  entityLegalForm: string;
  registrationNumber: string;
  dateOfIncorporation: Dayjs;
  natureOfBusiness: string;
  hasStockExchangeListing: boolean;
  isRegulatedEntity: boolean;
  licenceNumber?: string;
  regulator?: string;
  registeredAddress: Address;
  taxResidences: TaxResidence[];
}

export interface EntityType {
  type: InvestmentProfileEntityType;
  other?: string;
}

export interface Address {
  streetAndHouseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  unitOrSuiteOrFloorOrPostBox?: string;
}

export interface TaxResidence {
  country: string;
  taxId: string;
}

export interface InvestmentProfileAuthorizedPerson {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  dateOfBirth: Dayjs;
  countryOfBirth: string;
  placeOfBirth: string;
  nationality: string;
  passportNumber: string;
  phoneNumber: PhoneNumber;
  emailAddress: string;
  address: Address;
}

export interface InvestmentProfileControllingPerson {
  id: string;
  type: ControllingPersonType;
  firstName: string;
  lastName: string;
  dateOfBirth: Dayjs;
  countryOfBirth: string;
  placeOfBirth: string;
  nationality: string;
  passportNumber: string;
  isUSPerson: boolean;
  countryOfTaxResidence: string;
  taxId: string;
  percentageOfOwnership: Decimal;
  sourceOfFunds: SourceOfFunds;
  address: Address;
}

export interface ControllingPersonType {
  type: InvestmentProfileControllingPersonType;
  other?: string;
}

export interface SourceOfFunds {
  type: SourceOfFundsType;
  other?: string;
}

export interface InvestmentProfileBankDetails {
  bankName: string;
  address: Address;
  beneficiary: string;
  iban: string;
  bic: string;
  intermediaryBank?: string;
}

export interface InvestmentProfileContactDetails {
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
  emailAddress: string;
  address: Address;
}

export enum SubscriptionProcessStep {
  AMOUNT_SPECIFICATION = 1,
  INVESTMENT_PROFILE_SELECTION = 2,
  INVESTMENT_PROFILE_REVIEW = 3,
  KYC_DOCUMENT_ATTACHMENTS = 4,
  SUBSCRIPTION_DOCUMENTS = 5,
  REVIEW = 6,
}

export interface OnboardedProductSubscriptionProcess {
  investorId: string;
  investorName: string;
  investorHasKYCAccess: boolean;
  productName: string;
  productId: string;
  imageUrl?: string;
  productCurrency: Currency;
  minimumInvestmentAmount?: Decimal;
  investmentAmount?: Decimal;
  onboardedProductSubscriptionDocumentTemplates: SubscriptionDocumentTemplate[];
  onboardedProductSubscriptionDocuments: OnboardedProductSubscriptionDocument[];
  onboardedProductKYCDocumentAttachments: KYCDocumentAttachment[];
  kycInstruction?: string;
  subscriptionInstruction?: string;
  investmentSummary: InvestmentSummary;
  status: SubscriptionProcessStatus,
  onboardedProductInfo: ProductInfoForSubscriptionProcess,
  startedAt: Dayjs,
  isDocumentManagementForSubscriptionProcessEnabled: boolean,
  selectedInvestmentProfileId?: string;
  isInvestmentProfileReviewed: boolean;
  isNaturalPersonSelected: boolean;
}

export interface InvestmentForSummary {
  investmentId: string;
  committedCapital: Decimal;
  placementAgent: string;
  entryPricePerShare: Decimal;
  shares: Decimal;
  closingDate: Dayjs;
  isInitialInvestment: boolean;
}

export interface InvestmentSummary {
  investments: InvestmentForSummary[];
  averageEntryPrice: Decimal;
  totalCommittedCapital: Decimal;
  totalShares: Decimal;
}

export interface KYCDocumentAttachment {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface OnboardedProductSubscriptionDocument {
  id: string;
  name: string;
  uploadedAt: Dayjs;
  fileSize: Decimal;
}

export interface SubscriptionDocumentTemplate {
  id: string;
  name: string;
  fileSize: Decimal;
  uploadedAt: Dayjs;
}

export interface ProductInfoForSubscriptionProcess {
  productId: string;
  productName: string;
  imageUrl?: string;
  currency: Currency;
  idsOfKPIsForProductInfo: string[];
  idsOfKPIsForSummary: string[];
  termSheet: TermSheet;
}

export interface InvestmentProfile {
  investmentProfileId: string;
  name: string;
  isComplete: boolean;
  updatedAt: Dayjs;
  type: InvestmentProfileType;
}

// Commands

export interface SpecifyOnboardedProductSubscriptionInvestmentAmountCommand extends Command {
  subscriptionProcessId: string;
  investmentAmount: Decimal;
}

export interface SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
  investmentProfileId: string;
}

export interface ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
}

export interface ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand extends Command {
  subscriptionProcessId: string;
}

export interface CreateOnboardedProductSubscriptionDocumentForSubscriptionProcessCommand extends CommandWithFiles {
  body: {
    subscriptionProcessId: string;
  };
  files: {
    file: File;
  };
}

export interface CreateKYCDocumentAttachmentForOnboardedProductSubscriptionProcessCommand extends CommandWithFiles {
  body: {
    subscriptionProcessId: string;
  };
  files: {
    file: File;
  };
}

export interface ConfirmOnboardedProductSubscriptionCommand extends Command {
  subscriptionProcessId: string;
}

export interface CreateInvestmentForSubscriptionProcessCommand extends Command {
  subscriptionProcessId: string;
  placementAgent?: string;
  contact?: string;
  entryPricePerShare: Decimal;
  shares: Decimal;
  latestValuePerShare?: Decimal;
  closingDate: Dayjs;
}

export interface DeleteOnboardedProductSubscriptionDocumentCommand extends Command {
  documentId: string;
}

export interface DeleteOnboardedProductKYCDocumentAttachmentCommand extends Command {
  documentAttachmentId: string;
}

// Queries

export interface GetOnboardedProductSubscriptionProcessQuery extends Query {
  subscriptionProcessId: string;
}

export interface GetOnboardedProductSubscriptionDocumentFileForDownloadQuery extends Query {
  subscriptionDocumentId: string;
}

export interface GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery extends Query {
  fileId: string;
}

export interface GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery extends Query {
  kycDocumentAttachmentId: string;
}

export interface GetInvestmentProfilesForInvestorQuery extends Query {
  investorId: string;
}

export interface GetInvestmentProfileQuery extends Query {
  investmentProfileId: string;
}
