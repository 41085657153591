var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.documentsTabStore.isUploadDueDiligenceFilesWithPathProcessing},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',{attrs:{"data-cy":"upload-directory-confirmation-dialog"}},[_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.translate('dialogTitle'))}}),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.translate('dialogContent'))}}),_c('v-treeview',{ref:"treeView",attrs:{"open-all":"","items":_vm.previewForFileAndDirectoryChanges,"item-key":"name"},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(_vm.isDirectory(item))?_c('font-awesome-icon',{attrs:{"icon":_vm.getDirectoryIcon(open)}}):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"status-icon ml-2",attrs:{"icon":_vm.getStatusIcon(item.status)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.translate(("status." + (item.status)))))])]),(_vm.isFile(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('select-language-for-client-visibility-dialog',{attrs:{"fileForChangesPreview":_vm.getFileForRelativePathAndFileName(item)}},[_c('v-btn',{staticClass:"language-select-button ml-4",attrs:{"depressed":"","small":""}},[_c('font-awesome-icon',{staticClass:"language-select-icon mr-0 mb-1",attrs:{"icon":['far', 'language']}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.translate("file.selectLanguagesForClientVisibility")))])]):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","depressed":"","disabled":_vm.documentsTabStore.isUploadDueDiligenceFilesWithPathProcessing},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.translate('dialogCancelButton'))+" ")]),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.confirmButtonClicked}},[_vm._v(" "+_vm._s(_vm.translate('dialogConfirmButton'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }