import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { useGeneralPartnerOnboardedProductFromNetworkStore } from '@/domain/general-partner/products/onboarded-product-from-network/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { getAvailableDueDiligenceFilesForProduct, getBreadCrumbsForDueDiligenceDataRoom, getDueDiligenceDirectoryForDownload, getDueDiligenceFileForDownload, getUNodesForDueDiligenceDataRoom } from './services';
import { BreadCrumb, DueDiligenceUNode, GetAvailableDueDiligenceFilesForProductQuery, GetBreadCrumbsForDueDiligenceDataRoomQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetUNodesForDueDiligenceDataRoomQuery, UNode } from './types';

interface GeneralPartnerOnboardedProductFromNetworkDocumentsTabState {
  uNodesForAvailableDueDiligenceFiles: DueDiligenceUNode[];
  dueDiligenceDataRoomBreadCrumbs: BreadCrumb[];
  dueDiligenceDataRoomUNodes: UNode[];
  dueDiligenceDataRoomDirectoryId: string | null;

  getDueDiligenceFileForDownloadStatus: ActionStatus;
  getAvailableDueDiligenceFilesForProductStatus: ActionStatus;
  getBreadCrumbsForDueDiligenceDataRoomStatus: ActionStatus;
  getUNodesForDueDiligenceDataRoomStatus: ActionStatus;
  getDueDiligenceDirectoryForDownloadStatus: ActionStatus;
}

function initialState(): GeneralPartnerOnboardedProductFromNetworkDocumentsTabState {
  return {
    uNodesForAvailableDueDiligenceFiles: [],
    dueDiligenceDataRoomBreadCrumbs: [],
    dueDiligenceDataRoomUNodes: [],
    dueDiligenceDataRoomDirectoryId: null,

    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    getAvailableDueDiligenceFilesForProductStatus: ActionStatus.None,
    getBreadCrumbsForDueDiligenceDataRoomStatus: ActionStatus.None,
    getUNodesForDueDiligenceDataRoomStatus: ActionStatus.None,
    getDueDiligenceDirectoryForDownloadStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerOnboardedProductFromNetworkDocumentsTabStore
  = defineStore('generalPartnerOnboardedProductFromNetworkDocumentsTab', {
    state: (): GeneralPartnerOnboardedProductFromNetworkDocumentsTabState => initialState(),
    getters: {
      isGetDueDiligenceFileForDownloadProcessing: (state: GeneralPartnerOnboardedProductFromNetworkDocumentsTabState): boolean =>
        state.getDueDiligenceFileForDownloadStatus === ActionStatus.None,
      isGetAvailableDueDiligenceFilesForProductProcessing: (state: GeneralPartnerOnboardedProductFromNetworkDocumentsTabState): boolean =>
        state.getAvailableDueDiligenceFilesForProductStatus === ActionStatus.None,
      isGetBreadCrumbsForDueDiligenceDataRoomProcessing: (state: GeneralPartnerOnboardedProductFromNetworkDocumentsTabState): boolean =>
        state.getBreadCrumbsForDueDiligenceDataRoomStatus === ActionStatus.None,
      isGetUNodesForDueDiligenceDataRoomProcessing: (state: GeneralPartnerOnboardedProductFromNetworkDocumentsTabState): boolean =>
        state.getUNodesForDueDiligenceDataRoomStatus === ActionStatus.None,
      isGetDueDiligenceDirectoryForDownloadProcessing: (state: GeneralPartnerOnboardedProductFromNetworkDocumentsTabState): boolean =>
        state.getDueDiligenceDirectoryForDownloadStatus === ActionStatus.None,
      areAtLeastTwoBreadCrumbsAvailable: (state: GeneralPartnerOnboardedProductFromNetworkDocumentsTabState): boolean =>
        state.dueDiligenceDataRoomBreadCrumbs.length > 1,
    },
    actions: {

      // -- State management

      updateDueDiligenceDataRoomDirectory(directoryId?: string): Promise<void> {
        this.dueDiligenceDataRoomDirectoryId = directoryId || null;

        return this.getBreadCrumbsForDueDiligenceDataRoom()
          .then(() => this.getUNodesForDueDiligenceDataRoom());
      },

      // -- Queries

      getAvailableDueDiligenceFilesForProduct(): Promise<void> {
        const query: GetAvailableDueDiligenceFilesForProductQuery = {
          productId: useGeneralPartnerOnboardedProductFromNetworkStore().currentProductId!,
          language: useAuthenticationStore().user!.language!,
        };

        const { getAvailableDueDiligenceFilesForProductStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getAvailableDueDiligenceFilesForProductStatus,
          () => getAvailableDueDiligenceFilesForProduct(query)
            .then((files) => {
              this.uNodesForAvailableDueDiligenceFiles = files;
            })
        );

      },

      getBreadCrumbsForDueDiligenceDataRoom(): Promise<void> {
        const query: GetBreadCrumbsForDueDiligenceDataRoomQuery = {
          productId: useGeneralPartnerOnboardedProductFromNetworkStore().currentProductId!,
        };

        if (this.dueDiligenceDataRoomDirectoryId) {
          query.directoryId = this.dueDiligenceDataRoomDirectoryId;
        }

        const { getBreadCrumbsForDueDiligenceDataRoomStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getBreadCrumbsForDueDiligenceDataRoomStatus,
          () => getBreadCrumbsForDueDiligenceDataRoom(query)
            .then((breadCrumbs) => {
              this.dueDiligenceDataRoomBreadCrumbs = breadCrumbs;
            })
        );
      },

      getUNodesForDueDiligenceDataRoom(): Promise<void> {
        const query: GetUNodesForDueDiligenceDataRoomQuery = {
          productId: useGeneralPartnerOnboardedProductFromNetworkStore().currentProductId!,
        };

        if (this.dueDiligenceDataRoomDirectoryId) {
          query.directoryId = this.dueDiligenceDataRoomDirectoryId;
        }

        const { getUNodesForDueDiligenceDataRoomStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getUNodesForDueDiligenceDataRoomStatus,
          () => getUNodesForDueDiligenceDataRoom(query)
            .then((uNodes) => {
              this.dueDiligenceDataRoomUNodes = uNodes;
            })
        );
      },

      getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
        const { getDueDiligenceDirectoryForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getDueDiligenceDirectoryForDownloadStatus,
          () => getDueDiligenceDirectoryForDownload(query)
        );
      },

      getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
        const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
        return wrapActionWithProgress(
          getDueDiligenceFileForDownloadStatus,
          () => getDueDiligenceFileForDownload(query)
        );
      },

    },
  });
