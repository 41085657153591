import { RawLocation, Route, Location, RouteMeta } from 'vue-router';
import { useBrainAuthenticationStore } from '@/application/brain/authentication/store';
import { Brainiac } from '@/application/brain/authentication/types';

function getRedirectRouteForAuthenticatedUser(): Location {
  return { name: 'default' };
}

function getRedirectRouteForUnauthenticatedUser(): Location {
  return { name: 'root' };
}

export async function accessGuard(
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: any) => any) | void) => void
) {
  if (!to.meta) {
    throw Error('The route meta is undefined');
  }

  const brainAuthenticationStore = useBrainAuthenticationStore();

  if (!brainAuthenticationStore.wasInitialAuthenticationAttempted) {
    await brainAuthenticationStore.getInitialAuthentication()
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  const brainiac = brainAuthenticationStore.brainiac;

  const redirectLocation = nextPotentialRedirectLocation(
    to.meta,
    brainiac
  );
  if (redirectLocation === null) {
    next();
  } else {
    next(redirectLocation);
  }
}

export function nextPotentialRedirectLocation(
  meta: RouteMeta,
  brainiac: Brainiac | null
): Location | null {
  // Route is always accessible
  if (meta.alwaysAccessible) {
    return null;
  }

  const isAuthenticated = !!brainiac;

  // User is authenticated but route is just accessible as unauthenticated user
  if (isAuthenticated && !meta.requiresAuth) {
    return getRedirectRouteForAuthenticatedUser();
  }

  // User is unauthenticated but route is just accessible as authenticated user
  if (!isAuthenticated && meta.requiresAuth) {
    return getRedirectRouteForUnauthenticatedUser();
  }

  return null;
}
