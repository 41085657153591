var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-cy":"holdings"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-pagination":"","hide-default-footer":"","no-data-text":_vm.translate('noDataText'),"headers":_vm.holdingsTableHeaders,"items":_vm.store.currentSubscriptionProcess.investmentSummary.investments,"item-key":"investmentId","mobile-breakpoint":960},scopedSlots:_vm._u([{key:"item.closingDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(_vm._f("formatDate")(item.closingDate)))]),_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"text":_vm.getInvestmentTypeLabel(item),"color":_vm.getPillColorForInvestmentType(item)}})]}},{key:"item.committedCapital",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-right my-3"},[_c('div',{staticClass:"body-3 grey--text text--darken-2 whitespace-nowrap"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.shares,null, true, _vm.DISPLAY_DECIMALS.INVESTMENT.shares))+" "+_vm._s(_vm.translate('shares')))]),_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.committedCapital,_vm.store.currentSubscriptionProcess.productCurrency)))])])]}},{key:"item.entryPricePerShare",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.entryPricePerShare,_vm.store.currentSubscriptionProcess.productCurrency))+" ")]}},{key:"item.placementAgent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.placementAgent)+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"d-table-cell body-2 black--text"},[_vm._v(" "+_vm._s(_vm.translate('total'))+" ")]),_c('td',{staticClass:"d-table-cell text-right body-2 black--text py-3"},[_c('div',{staticClass:"body-3 grey--text text--darken-2"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.store.currentSubscriptionProcess.investmentSummary.totalShares,null, true, _vm.DISPLAY_DECIMALS.INVESTMENT.shares))+" "+_vm._s(_vm.translate('shares')))]),_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.store.currentSubscriptionProcess.investmentSummary.totalCommittedCapital,_vm.store.currentSubscriptionProcess.productCurrency))+" ")]),_c('td',{staticClass:"d-table-cell text-right body-2 black--text whitespace-nowrap"},[_vm._v(" ∅ "+_vm._s(_vm._f("formatCurrency")(_vm.store.currentSubscriptionProcess.investmentSummary.averageEntryPrice,_vm.store.currentSubscriptionProcess.productCurrency))+" ")]),_c('td',{staticClass:"d-table-cell"})])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }