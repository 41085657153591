import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartner, attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { ConfirmEmailAddressChangeCommand, ConfirmPhoneNumberForTwoFactorAuthenticationCommand, DeleteVerifiedPhoneNumberForTwoFactorAuthenticationCommand, Profile, RequestEmailAddressChangeCommand, UpdateCountryCommand, UpdateNameCommand, UpdatePasswordCommand, UpdatePhoneNumberCommand, StartPhoneNumberVerificationForTwoFactorAuthenticationCommand, CancelPhoneNumberVerificationForTwoFactorAuthenticationCommand } from './types';

// -- Queries

export function getProfile(): Promise<Profile> {
  const url = generateApiUrl('/api/profile/get-profile-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

// -- Commands

export function requestEmailAddressChange(command: RequestEmailAddressChangeCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/request-email-address-change-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function confirmEmailAddressChange(command: ConfirmEmailAddressChangeCommand): Promise<void> {
  const url = generateApiUrl('/api/unauthorized/profile/confirm-email-address-change-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(command) });
}

export function updatePassword(command: UpdatePasswordCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/update-password-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateName(command: UpdateNameCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/update-name-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updatePhoneNumber(command: UpdatePhoneNumberCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/update-phone-number-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateCountry(command: UpdateCountryCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/update-country-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function startPhoneNumberVerificationForTwoFactorAuthentication(
  command: StartPhoneNumberVerificationForTwoFactorAuthenticationCommand
): Promise<void> {
  const url = generateApiUrl('/api/profile/start-phone-number-verification-for-two-factor-authentication-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function confirmPhoneNumberForTwoFactorAuthentication(command: ConfirmPhoneNumberForTwoFactorAuthenticationCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/confirm-phone-number-for-two-factor-authentication-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteVerifiedPhoneNumberForTwoFactorAuthentication(
  command: DeleteVerifiedPhoneNumberForTwoFactorAuthenticationCommand
): Promise<void> {
  const url = generateApiUrl('/api/profile/delete-verified-phone-number-for-two-factor-authentication-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function cancelPhoneNumberVerificationForTwoFactorAuthentication(
  command: CancelPhoneNumberVerificationForTwoFactorAuthenticationCommand
): Promise<void> {
  const url = generateApiUrl('/api/profile/cancel-phone-number-verification-for-two-factor-authentication-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
