import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { GetSubscriptionDocumentsForInvestmentQuery, CreateSubscriptionDocumentForInvestmentCommand, GetSubscriptionDocumentFileForDownloadQuery, KYCDocumentAttachment, SubscriptionDocument, GetKYCDocumentAttachmentFileForDownloadQuery, GetKYCDocumentAttachmentsForInvestmentQuery, CreateKYCDocumentAttachmentForInvestmentCommand, DeleteKYCDocumentAttachmentForInvestmentCommand, DeleteSubscriptionDocumentForInvestmentCommand } from './types';
import { getKYCDocumentAttachmentFileForDownload, getSubscriptionDocumentsForInvestment, createSubscriptionDocumentForInvestment, getSubscriptionDocumentFileForDownload, getKYCDocumentAttachmentsForInvestment, createKYCDocumentAttachmentForInvestment, deleteKYCDocumentAttachmentForInvestment, deleteSubscriptionDocumentForInvestment } from './service';

interface GeneralPartnerInvestmentDocumentsState {
  investmentId: string | null;
  subscriptionDocuments: SubscriptionDocument[];
  kycDocumentAttachments: KYCDocumentAttachment[];

  createSubscriptionDocumentsForInvestmentStatus: ActionStatus;
  createKYCDocumentAttachmentsForInvestmentStatus: ActionStatus;
  getSubscriptionDocumentsForInvestmentStatus: ActionStatus;
  getSubscriptionDocumentFileForDownloadStatus: ActionStatus;
  getKYCDocumentAttachmentsForInvestmentStatus: ActionStatus;
  getKYCDocumentAttachmentFileForDownloadStatus: ActionStatus;
  deleteKYCDocumentAttachmentForInvestmentStatus: ActionStatus;
  deleteSubscriptionDocumentForInvestmentStatus: ActionStatus;
}

function initialState(): GeneralPartnerInvestmentDocumentsState {
  return {
    investmentId: null,
    subscriptionDocuments: [],
    kycDocumentAttachments: [],

    createSubscriptionDocumentsForInvestmentStatus: ActionStatus.None,
    createKYCDocumentAttachmentsForInvestmentStatus: ActionStatus.None,
    getSubscriptionDocumentsForInvestmentStatus: ActionStatus.None,
    getSubscriptionDocumentFileForDownloadStatus: ActionStatus.None,
    getKYCDocumentAttachmentsForInvestmentStatus: ActionStatus.None,
    getKYCDocumentAttachmentFileForDownloadStatus: ActionStatus.None,
    deleteKYCDocumentAttachmentForInvestmentStatus: ActionStatus.None,
    deleteSubscriptionDocumentForInvestmentStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerInvestmentDocumentsStore = defineStore('generalPartnerInvestmentDocuments', {
  state: (): GeneralPartnerInvestmentDocumentsState => initialState(),
  getters: {
    isCreateSubscriptionDocumentsForInvestmentProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.createSubscriptionDocumentsForInvestmentStatus === ActionStatus.InProgress,
    isCreateKYCDocumentAttachmentsForInvestmentProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.createKYCDocumentAttachmentsForInvestmentStatus === ActionStatus.InProgress,
    isGetSubscriptionDocumentsForInvestmentProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.getSubscriptionDocumentsForInvestmentStatus === ActionStatus.InProgress,
    isGetSubscriptionDocumentFileForDownloadProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.getSubscriptionDocumentFileForDownloadStatus === ActionStatus.InProgress,
    isGetKYCDocumentAttachmentsForInvestmentProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.getKYCDocumentAttachmentsForInvestmentStatus === ActionStatus.InProgress,
    isGetKYCDocumentAttachmentFileForDownloadProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.getKYCDocumentAttachmentFileForDownloadStatus === ActionStatus.InProgress,
    isDeleteKYCDocumentAttachmentForInvestmentProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.deleteKYCDocumentAttachmentForInvestmentStatus === ActionStatus.InProgress,
    isDeleteSubscriptionDocumentForInvestmentProcessing: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.deleteSubscriptionDocumentForInvestmentStatus === ActionStatus.InProgress,

    areSubscriptionDocumentsAvailable: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.subscriptionDocuments.length > 0,
    areKYCDocumentAttachmentsAvailable: (state: GeneralPartnerInvestmentDocumentsState): boolean =>
      state.kycDocumentAttachments.length > 0,
  },
  actions: {

    // -- State management

    updateInvestmentId(investmentId: string): Promise<void[]> {
      this.investmentId = investmentId;

      return Promise.all([
        this.getKYCDocumentAttachmentsForInvestment(),
        this.getSubscriptionDocumentsForInvestment(),
      ]);
    },

    // -- Queries

    getSubscriptionDocumentsForInvestment(): Promise<void> {
      const query: GetSubscriptionDocumentsForInvestmentQuery = {
        investmentId: this.investmentId!,
      };
      const { getSubscriptionDocumentsForInvestmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionDocumentsForInvestmentStatus,
        () => getSubscriptionDocumentsForInvestment(query)
          .then((subscriptionDocuments) => {
            this.subscriptionDocuments = subscriptionDocuments;
          })
      );
    },

    getSubscriptionDocumentFileForDownload(query: GetSubscriptionDocumentFileForDownloadQuery): Promise<FileResponse> {
      const { getSubscriptionDocumentFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionDocumentFileForDownloadStatus,
        () => getSubscriptionDocumentFileForDownload(query)
      );
    },

    getKYCDocumentAttachmentsForInvestment(): Promise<void> {
      const query: GetKYCDocumentAttachmentsForInvestmentQuery = {
        investmentId: this.investmentId!,
      };
      const { getKYCDocumentAttachmentsForInvestmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getKYCDocumentAttachmentsForInvestmentStatus,
        () => getKYCDocumentAttachmentsForInvestment(query)
          .then((kycDocumentAttachments) => {
            this.kycDocumentAttachments = kycDocumentAttachments;
          })
      );
    },

    getKYCDocumentAttachmentFileForDownload(query: GetKYCDocumentAttachmentFileForDownloadQuery): Promise<FileResponse> {
      const { getKYCDocumentAttachmentFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getKYCDocumentAttachmentFileForDownloadStatus,
        () => getKYCDocumentAttachmentFileForDownload(query)
      );
    },

    // -- Commands

    createSubscriptionDocumentsForInvestment(files: File[]): Promise<void[]> {
      const fileUploadQueueStore = useFileUploadQueueStore();

      const queuedFileUploads = files.map((file) => {
        const command: CreateSubscriptionDocumentForInvestmentCommand = {
          body: {
            investmentId: this.investmentId!,
          },
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createSubscriptionDocumentForInvestment,
          command,
        };

        return fileUploadQueueStore.addItem(payload)
          .then(() => this.getSubscriptionDocumentsForInvestment());
      });

      const { createSubscriptionDocumentsForInvestmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSubscriptionDocumentsForInvestmentStatus,
        () => Promise.all(queuedFileUploads)
      );
    },

    deleteSubscriptionDocumentForInvestment(command: DeleteSubscriptionDocumentForInvestmentCommand): Promise<void> {
      const { deleteSubscriptionDocumentForInvestmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSubscriptionDocumentForInvestmentStatus,
        () => deleteSubscriptionDocumentForInvestment(command)
          .then(() => this.getSubscriptionDocumentsForInvestment())
      );
    },

    createKYCDocumentAttachmentsForInvestment(files: File[]): Promise<void[]> {
      const fileUploadQueueStore = useFileUploadQueueStore();

      const queuedFileUploads = files.map((file) => {
        const command: CreateKYCDocumentAttachmentForInvestmentCommand = {
          body: {
            investmentId: this.investmentId!,
          },
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createKYCDocumentAttachmentForInvestment,
          command,
        };

        return fileUploadQueueStore.addItem(payload)
          .then(() => this.getKYCDocumentAttachmentsForInvestment());
      });

      const { createKYCDocumentAttachmentsForInvestmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createKYCDocumentAttachmentsForInvestmentStatus,
        () => Promise.all(queuedFileUploads)
      );
    },

    deleteKYCDocumentAttachmentForInvestment(command: DeleteKYCDocumentAttachmentForInvestmentCommand): Promise<void> {
      const { deleteKYCDocumentAttachmentForInvestmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteKYCDocumentAttachmentForInvestmentStatus,
        () => deleteKYCDocumentAttachmentForInvestment(command)
          .then(() => this.getKYCDocumentAttachmentsForInvestment())
      );
    },

  },
});
