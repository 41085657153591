import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { CreateInvestmentProfileCommand, CreateInvestmentProfileStep, DeleteInvestmentProfileCommand, GetInvestmentProfileQuery, InvestmentProfile, InvestmentProfileDetails, UpdateInvestmentProfileAuthorizedPersonsCommand, UpdateInvestmentProfileBankDetailsCommand, UpdateInvestmentProfileContactDetailsCommand, UpdateInvestmentProfileControllingPersonsCommand, UpdateInvestmentProfileGeneralInformationCommand, UpdateInvestmentProfileNameCommand } from './types';
import { createInvestmentProfile, deleteInvestmentProfile, getInvestmentProfile, getInvestmentProfiles, updateInvestmentProfileAuthorizedPersons, updateInvestmentProfileBankDetails, updateInvestmentProfileContactDetails, updateInvestmentProfileControllingPersons, updateInvestmentProfileGeneralInformation, updateInvestmentProfileName } from './service';

interface InvestorInvestmentProfilesState {
  currentStep: CreateInvestmentProfileStep;

  currentInvestmentProfileId: string | null;
  currentInvestmentProfile: InvestmentProfileDetails | null;
  investmentProfiles: InvestmentProfile[];

  createInvestmentProfileStatus: ActionStatus;
  updateInvestmentProfileNameStatus: ActionStatus;
  updateInvestmentProfileGeneralInformationStatus: ActionStatus;
  updateInvestmentProfileAuthorizedPersonsStatus: ActionStatus;
  updateInvestmentProfileControllingPersonsStatus: ActionStatus;
  updateInvestmentProfileBankDetailsStatus: ActionStatus;
  updateInvestmentProfileContactDetailsStatus: ActionStatus;
  getCurrentInvestmentProfileStatus: ActionStatus;
  getInvestmentProfilesStatus: ActionStatus;
  deleteInvestmentProfileStatus: ActionStatus;
}

function initialState(): InvestorInvestmentProfilesState {
  return {
    currentStep: CreateInvestmentProfileStep.INVESTMENT_PROFILE_NAME,

    currentInvestmentProfileId: null,
    currentInvestmentProfile: null,
    investmentProfiles: [],

    createInvestmentProfileStatus: ActionStatus.None,
    updateInvestmentProfileNameStatus: ActionStatus.None,
    updateInvestmentProfileGeneralInformationStatus: ActionStatus.None,
    updateInvestmentProfileAuthorizedPersonsStatus: ActionStatus.None,
    updateInvestmentProfileControllingPersonsStatus: ActionStatus.None,
    updateInvestmentProfileBankDetailsStatus: ActionStatus.None,
    updateInvestmentProfileContactDetailsStatus: ActionStatus.None,
    getCurrentInvestmentProfileStatus: ActionStatus.None,
    getInvestmentProfilesStatus: ActionStatus.None,
    deleteInvestmentProfileStatus: ActionStatus.None,
  };
}

export const useInvestorInvestmentProfilesStore = defineStore('investorInvestmentProfiles', {
  state: (): InvestorInvestmentProfilesState => initialState(),
  getters: {
    isCreateInvestmentProfileProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.createInvestmentProfileStatus === ActionStatus.InProgress,
    isUpdateInvestmentProfileNameProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.updateInvestmentProfileNameStatus === ActionStatus.InProgress,
    isUpdateInvestmentProfileGeneralInformationProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.updateInvestmentProfileGeneralInformationStatus === ActionStatus.InProgress,
    isUpdateInvestmentProfileAuthorizedPersonsProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.updateInvestmentProfileAuthorizedPersonsStatus === ActionStatus.InProgress,
    isUpdateInvestmentProfileControllingPersonsProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.updateInvestmentProfileControllingPersonsStatus === ActionStatus.InProgress,
    isUpdateInvestmentProfileBankDetailsProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.updateInvestmentProfileBankDetailsStatus === ActionStatus.InProgress,
    isUpdateInvestmentProfileContactDetailsProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.updateInvestmentProfileContactDetailsStatus === ActionStatus.InProgress,
    isGetCurrentInvestmentProfileProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.getCurrentInvestmentProfileStatus === ActionStatus.InProgress,
    isGetInvestmentProfilesProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.getInvestmentProfilesStatus === ActionStatus.InProgress,
    isDeleteInvestmentProfileProcessing: (state: InvestorInvestmentProfilesState): boolean =>
      state.deleteInvestmentProfileStatus === ActionStatus.InProgress,
    hasInvestmentProfiles: (state: InvestorInvestmentProfilesState): boolean =>
      state.investmentProfiles.length > 0,
    investmentProfileTitle: (state: InvestorInvestmentProfilesState): string =>
      state.currentInvestmentProfile?.name || '',
    isInvestmentProfileNameStepComplete: (state: InvestorInvestmentProfilesState): boolean =>
      !!state.currentInvestmentProfileId,
    isInvestmentProfileNameStepActive: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.INVESTMENT_PROFILE_NAME,
    isGeneralInformationStepComplete: (state: InvestorInvestmentProfilesState): boolean =>
      !!state.currentInvestmentProfile?.generalInformation,
    isGeneralInformationStepActive: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.GENERAL_INFORMATION,
    isAuthorizedPersonsStepComplete: (state: InvestorInvestmentProfilesState): boolean =>
      !!state.currentInvestmentProfile?.authorizedPersons,
    isAuthorizedPersonsStepActive: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.PERSONS_AUTHORIZED_TO_SIGN,
    isControllingPersonsStepComplete: (state: InvestorInvestmentProfilesState): boolean =>
      !!state.currentInvestmentProfile?.controllingPersons,
    isControllingPersonsStepActive: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.CONTROLLING_PERSONS,
    isBankDetailsStepComplete: (state: InvestorInvestmentProfilesState): boolean =>
      !!state.currentInvestmentProfile?.bankDetails,
    isBankDetailsStepActive: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.BANK_DETAILS,
    isContactDetailsStepComplete: (state: InvestorInvestmentProfilesState): boolean =>
      !!state.currentInvestmentProfile?.contactDetails,
    isContactDetailsStepActive: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.CONTACT_DETAILS,
    isProcessing: (state: InvestorInvestmentProfilesState): boolean => [
      state.createInvestmentProfileStatus,
      state.updateInvestmentProfileNameStatus,
      state.updateInvestmentProfileGeneralInformationStatus,
      state.updateInvestmentProfileAuthorizedPersonsStatus,
      state.updateInvestmentProfileControllingPersonsStatus,
      state.updateInvestmentProfileBankDetailsStatus,
      state.updateInvestmentProfileContactDetailsStatus,
    ].some((status) => status === ActionStatus.InProgress),
    isFirstStep: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.INVESTMENT_PROFILE_NAME,
    isDone: (state: InvestorInvestmentProfilesState): boolean =>
      state.currentStep === CreateInvestmentProfileStep.DONE,
  },
  actions: {
    // -- State management

    goToFirstUncompletedStep(): Promise<void> {
      if (!this.currentInvestmentProfile) {
        this.currentStep = CreateInvestmentProfileStep.INVESTMENT_PROFILE_NAME;
      } else if (!this.currentInvestmentProfile.generalInformation) {
        this.currentStep = CreateInvestmentProfileStep.GENERAL_INFORMATION;
      } else if (!this.currentInvestmentProfile.authorizedPersons) {
        this.currentStep = CreateInvestmentProfileStep.PERSONS_AUTHORIZED_TO_SIGN;
      } else if (!this.currentInvestmentProfile.controllingPersons) {
        this.currentStep = CreateInvestmentProfileStep.CONTROLLING_PERSONS;
      } else if (!this.currentInvestmentProfile.bankDetails) {
        this.currentStep = CreateInvestmentProfileStep.BANK_DETAILS;
      } else if (!this.currentInvestmentProfile.contactDetails) {
        this.currentStep = CreateInvestmentProfileStep.CONTACT_DETAILS;
      }

      return Promise.resolve();
    },

    goToNextStep(): Promise<void> {
      this.currentStep++;

      return Promise.resolve();
    },

    goToPreviousStep(): Promise<void> {
      this.currentStep--;

      return Promise.resolve();
    },

    setCurrentInvestmentProfileId(id: string): Promise<void> {
      this.currentInvestmentProfileId = id;

      return Promise.resolve();
    },

    resetCurrentInvestmentProfile(): Promise<void> {
      this.currentInvestmentProfileId = null;
      this.currentInvestmentProfile = null;

      return Promise.resolve();
    },

    // -- Queries

    getCurrentInvestmentProfile(): Promise<void> {
      const query: GetInvestmentProfileQuery = {
        investmentProfileId: this.currentInvestmentProfileId!,
      };

      const { getCurrentInvestmentProfileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentInvestmentProfileStatus,
        () => getInvestmentProfile(query)
          .then((investmentProfile) => {
            this.currentInvestmentProfile = investmentProfile;
          })
      );
    },

    getInvestmentProfiles(): Promise<void> {
      const { getInvestmentProfilesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestmentProfilesStatus,
        () => getInvestmentProfiles()
          .then((investmentProfiles) => {
            this.investmentProfiles = investmentProfiles;
          })
      );
    },

    // -- Commands

    createInvestmentProfile(command: CreateInvestmentProfileCommand): Promise<void> {
      const { createInvestmentProfileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createInvestmentProfileStatus,
        () => createInvestmentProfile(command)
          .then(() => this.setCurrentInvestmentProfileId(command.investmentProfileId))
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    updateInvestmentProfileName(command: UpdateInvestmentProfileNameCommand): Promise<void> {
      const { updateInvestmentProfileNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentProfileNameStatus,
        () => updateInvestmentProfileName(command)
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    updateInvestmentProfileGeneralInformation(command: UpdateInvestmentProfileGeneralInformationCommand): Promise<void> {
      const { updateInvestmentProfileGeneralInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentProfileGeneralInformationStatus,
        () => updateInvestmentProfileGeneralInformation(command)
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    updateInvestmentProfileAuthorizedPersons(command: UpdateInvestmentProfileAuthorizedPersonsCommand): Promise<void> {
      const { updateInvestmentProfileAuthorizedPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentProfileAuthorizedPersonsStatus,
        () => updateInvestmentProfileAuthorizedPersons(command)
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    updateInvestmentProfileControllingPersons(command: UpdateInvestmentProfileControllingPersonsCommand): Promise<void> {
      const { updateInvestmentProfileControllingPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentProfileControllingPersonsStatus,
        () => updateInvestmentProfileControllingPersons(command)
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    updateInvestmentProfileBankDetails(command: UpdateInvestmentProfileBankDetailsCommand): Promise<void> {
      const { updateInvestmentProfileBankDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentProfileBankDetailsStatus,
        () => updateInvestmentProfileBankDetails(command)
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    updateInvestmentProfileContactDetails(command: UpdateInvestmentProfileContactDetailsCommand): Promise<void> {
      const { updateInvestmentProfileContactDetailsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestmentProfileContactDetailsStatus,
        () => updateInvestmentProfileContactDetails(command)
          .then(() => this.getCurrentInvestmentProfile())
      );
    },

    deleteInvestmentProfile(command: DeleteInvestmentProfileCommand): Promise<void> {
      const { deleteInvestmentProfileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteInvestmentProfileStatus,
        () => deleteInvestmentProfile(command)
          .then(() => this.getInvestmentProfiles())
      );
    },

  },
});
