import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAppStore } from '@/application/common/app/store';

function validateAppVersion(serverVersion: string): Promise<void> {
  return useAppStore().validateAppVersion(serverVersion);
}

function validateAppVersionOnResponse(response: AxiosResponse): Promise<AxiosResponse> {
  return validateAppVersion(response.headers['x-app-version'])
    .then(() => response);
}

function validateAppVersionOnError(error: AxiosError): Promise<AxiosError> {
  const isMaintenanceMode = error.response?.status === 503;

  if (isMaintenanceMode) {
    return Promise.reject(error);
  }

  if (error.response) {
    return validateAppVersion(error.response.headers['x-app-version'])
      .then(() => Promise.reject(error));
  }

  return Promise.reject(error);
}

export default function initAppVersionInterceptor(): void {
  axios.interceptors.response.use(
    validateAppVersionOnResponse,
    validateAppVersionOnError
  );
}
