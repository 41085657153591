import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { GetInAppNotificationByIdQuery, GetInAppNotificationsQuery, GetInAppNotificationsQueryResponse, InAppNotification, MarkInAppNotificationAsSeenCommand, UnseenPlatformNews } from './types';

export function getInAppNotificationById(query: GetInAppNotificationByIdQuery): Promise<InAppNotification> {
  const url = generateApiUrl('/api/common/get-in-app-notification-by-id-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInAppNotifications(query: GetInAppNotificationsQuery): Promise<GetInAppNotificationsQueryResponse> {
  const url = generateApiUrl('/api/common/get-in-app-notifications-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function markAllInAppNotificationsAsSeen(): Promise<void> {
  const url = generateApiUrl('/api/common/mark-all-in-app-notifications-as-seen-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function markInAppNotificationAsSeen(command: MarkInAppNotificationAsSeenCommand): Promise<void> {
  const url = generateApiUrl('/api/common/mark-in-app-notification-as-seen-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getUnseenPlatformNews(): Promise<UnseenPlatformNews[]> {
  const url = generateApiUrl('/api/general-partner/get-unseen-platform-news-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}
