import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { useGeneralPartnerProductFromNetworkStore } from '../../store';
import { BreadCrumb, DueDiligenceUNode, GetAvailableDueDiligenceFilesForProductQuery, GetBreadCrumbsForDueDiligenceDataRoomQuery, GetDueDiligenceDirectoryForDownloadQuery, GetDueDiligenceFileForDownloadQuery, GetUNodesForDueDiligenceDataRoomQuery, UNode } from './types';
import { getAvailableDueDiligenceFilesForProduct, getDueDiligenceFileForDownload, getBreadCrumbsForDueDiligenceDataRoom, getUNodesForDueDiligenceDataRoom, getDueDiligenceDirectoryForDownload } from './services';

interface GeneralPartnerProductFromNetworkDocumentsTabState {
  uNodesForAvailableDueDiligenceFiles: DueDiligenceUNode[];
  dueDiligenceDataRoomBreadCrumbs: BreadCrumb[];
  dueDiligenceDataRoomUNodes: UNode[];
  dueDiligenceDataRoomDirectoryId: string | null;

  getDueDiligenceFileForDownloadStatus: ActionStatus;
  getAvailableDueDiligenceFilesForProductStatus: ActionStatus;
  getBreadCrumbsForDueDiligenceDataRoomStatus: ActionStatus;
  getUNodesForDueDiligenceDataRoomStatus: ActionStatus;
  getDueDiligenceDirectoryForDownloadStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductFromNetworkDocumentsTabState {
  return {
    uNodesForAvailableDueDiligenceFiles: [],
    dueDiligenceDataRoomBreadCrumbs: [],
    dueDiligenceDataRoomUNodes: [],
    dueDiligenceDataRoomDirectoryId: null,

    getDueDiligenceFileForDownloadStatus: ActionStatus.None,
    getAvailableDueDiligenceFilesForProductStatus: ActionStatus.None,
    getBreadCrumbsForDueDiligenceDataRoomStatus: ActionStatus.None,
    getUNodesForDueDiligenceDataRoomStatus: ActionStatus.None,
    getDueDiligenceDirectoryForDownloadStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductFromNetworkDocumentsTabStore = defineStore('generalPartnerProductFromNetworkDocumentsTab', {
  state: (): GeneralPartnerProductFromNetworkDocumentsTabState => initialState(),
  getters: {
    isGetDueDiligenceFileForDownloadProcessing: (state: GeneralPartnerProductFromNetworkDocumentsTabState): boolean =>
      state.getDueDiligenceFileForDownloadStatus === ActionStatus.InProgress,
    isGetAvailableDueDiligenceFilesForProductProcessing: (state: GeneralPartnerProductFromNetworkDocumentsTabState): boolean =>
      state.getAvailableDueDiligenceFilesForProductStatus === ActionStatus.InProgress,
    isGetBreadCrumbsForDueDiligenceDataRoomProcessing: (state: GeneralPartnerProductFromNetworkDocumentsTabState): boolean =>
      state.getBreadCrumbsForDueDiligenceDataRoomStatus === ActionStatus.InProgress,
    isGetUNodesForDueDiligenceDataRoomProcessing: (state: GeneralPartnerProductFromNetworkDocumentsTabState): boolean =>
      state.getUNodesForDueDiligenceDataRoomStatus === ActionStatus.InProgress,
    isGetDueDiligenceDirectoryForDownloadProcessing: (state: GeneralPartnerProductFromNetworkDocumentsTabState): boolean =>
      state.getDueDiligenceDirectoryForDownloadStatus === ActionStatus.InProgress,

    areAtLeastTwoBreadCrumbsAvailable: (state: GeneralPartnerProductFromNetworkDocumentsTabState): boolean =>
      state.dueDiligenceDataRoomBreadCrumbs.length > 1,
  },
  actions: {

    // -- State management

    updateDueDiligenceDataRoomDirectory(directoryId?: string): Promise<void> {
      this.dueDiligenceDataRoomDirectoryId = directoryId || null;

      return this.getBreadCrumbsForDueDiligenceDataRoom()
        .then(() => this.getUNodesForDueDiligenceDataRoom());
    },

    // -- Queries

    getAvailableDueDiligenceFilesForProduct(): Promise<void> {
      const query: GetAvailableDueDiligenceFilesForProductQuery = {
        productId: useGeneralPartnerProductFromNetworkStore().currentProductId!,
      };

      const { getAvailableDueDiligenceFilesForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAvailableDueDiligenceFilesForProductStatus,
        () => getAvailableDueDiligenceFilesForProduct(query)
          .then((files) => {
            this.uNodesForAvailableDueDiligenceFiles = files;
          })
      );
    },

    getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceFileForDownloadStatus,
        () => getDueDiligenceFileForDownload(query)
      );
    },

    getBreadCrumbsForDueDiligenceDataRoom(): Promise<void> {
      const query: GetBreadCrumbsForDueDiligenceDataRoomQuery = {
        productId: useGeneralPartnerProductFromNetworkStore().currentProductId!,
      };

      if (this.dueDiligenceDataRoomDirectoryId) {
        query.directoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const { getBreadCrumbsForDueDiligenceDataRoomStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBreadCrumbsForDueDiligenceDataRoomStatus,
        () => getBreadCrumbsForDueDiligenceDataRoom(query)
          .then((breadCrumbs) => {
            this.dueDiligenceDataRoomBreadCrumbs = breadCrumbs;
          })
      );
    },

    getUNodesForDueDiligenceDataRoom(): Promise<void> {
      const query: GetUNodesForDueDiligenceDataRoomQuery = {
        productId: useGeneralPartnerProductFromNetworkStore().currentProductId!,
      };

      if (this.dueDiligenceDataRoomDirectoryId) {
        query.directoryId = this.dueDiligenceDataRoomDirectoryId;
      }

      const { getUNodesForDueDiligenceDataRoomStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUNodesForDueDiligenceDataRoomStatus,
        () => getUNodesForDueDiligenceDataRoom(query)
          .then((uNodes) => {
            this.dueDiligenceDataRoomUNodes = uNodes;
          })
      );
    },

    getDueDiligenceDirectoryForDownload(query: GetDueDiligenceDirectoryForDownloadQuery): Promise<FileResponse> {
      const { getDueDiligenceDirectoryForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDueDiligenceDirectoryForDownloadStatus,
        () => getDueDiligenceDirectoryForDownload(query)
      );
    },

  },
});
