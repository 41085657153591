import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { UpdateImageCommand } from './types';
import { updateImage } from './service';

interface GeneralPartnerUploadImageState {
  updateImageStatus: ActionStatus;
}

function initialState(): GeneralPartnerUploadImageState {
  return {
    updateImageStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerUploadImageStore = defineStore('generalPartnerUploadImage', {
  state: (): GeneralPartnerUploadImageState => initialState(),
  getters: {
    isUpdateImageProcessing: (state: GeneralPartnerUploadImageState): boolean =>
      state.updateImageStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Commands

    updateImage(command: UpdateImageCommand): Promise<void> {
      const { updateImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateImageStatus,
        () => updateImage(command)
      );
    },

  },
});
