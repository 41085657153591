import dayjs, { Dayjs } from 'dayjs';

export function currentYear(): number {
  return dayjs().year();
}

export function getEarliestDate(dates: Dayjs[]): Dayjs {
  const datesAsTimestamps = dates.map((date) => date.unix());
  const smallestTimestamp = Math.min(...datesAsTimestamps);
  const indexOfSmallestDate = datesAsTimestamps.indexOf(smallestTimestamp);

  return dates[indexOfSmallestDate];
}

export function sortByDates(itemA: Dayjs, itemB: Dayjs): number {
  return itemA.isAfter(itemB) ? 1 : -1;
}
