import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, VuetifySelectItem } from '@/application/types';
import { getNameStringFromName } from '@/helpers/name-helpers';
import { getAdvisorReferences, getGeneralPartnerPermissionGroupReferences, getGeneralPartnerUserWithoutAccessToAllProductsReferences, getInvestorReferences, getInvestorUserReferences, getProductReferences } from './service';
import { AdvisorReference, GeneralPartnerPermissionGroupReference, GeneralPartnerUserReference, InvestorReference, InvestorUserReference, ProductReference } from './types';

interface GeneralPartnerReferencesState {
  productReferences: ProductReference[];
  investorReferences: InvestorReference[];
  investorUserReferences: InvestorUserReference[];
  advisorReferences: AdvisorReference[];
  generalPartnerPermissionGroupReferences: GeneralPartnerPermissionGroupReference[];
  generalPartnerUserWithoutAccessToAllProductsReferences: GeneralPartnerUserReference[];

  getProductReferencesStatus: ActionStatus;
  getInvestorReferencesStatus: ActionStatus;
  getInvestorUserReferencesStatus: ActionStatus;
  getGeneralPartnerPermissionGroupReferencesStatus: ActionStatus;
  getGeneralPartnerUserWithoutAccessToAllProductsReferencesStatus: ActionStatus;
  getAdvisorReferencesStatus: ActionStatus;
}

function initialState(): GeneralPartnerReferencesState {
  return {
    productReferences: [],
    investorReferences: [],
    investorUserReferences: [],
    advisorReferences: [],
    generalPartnerPermissionGroupReferences: [],
    generalPartnerUserWithoutAccessToAllProductsReferences: [],

    getProductReferencesStatus: ActionStatus.None,
    getInvestorReferencesStatus: ActionStatus.None,
    getInvestorUserReferencesStatus: ActionStatus.None,
    getGeneralPartnerPermissionGroupReferencesStatus: ActionStatus.None,
    getGeneralPartnerUserWithoutAccessToAllProductsReferencesStatus: ActionStatus.None,
    getAdvisorReferencesStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerReferencesStore = defineStore('generalPartnerReferencesState', {
  state: (): GeneralPartnerReferencesState => initialState(),
  getters: {
    isGetProductReferencesProcessing: (state: GeneralPartnerReferencesState): boolean =>
      state.getProductReferencesStatus === ActionStatus.InProgress,
    isGetInvestorReferencesProcessing: (state: GeneralPartnerReferencesState): boolean =>
      state.getInvestorReferencesStatus === ActionStatus.InProgress,
    isGetInvestorUserReferencesProcessing: (state: GeneralPartnerReferencesState): boolean =>
      state.getInvestorUserReferencesStatus === ActionStatus.InProgress,
    isGetGeneralPartnerPermissionGroupReferencesProcessing: (state: GeneralPartnerReferencesState): boolean =>
      state.getGeneralPartnerPermissionGroupReferencesStatus === ActionStatus.InProgress,
    isGetGeneralPartnerUserWithoutAccessToAllProductsReferencesProcessing: (state: GeneralPartnerReferencesState): boolean =>
      state.getGeneralPartnerUserWithoutAccessToAllProductsReferencesStatus === ActionStatus.InProgress,
    isGetAdvisorReferencesProcessing: (state: GeneralPartnerReferencesState): boolean =>
      state.getAdvisorReferencesStatus === ActionStatus.InProgress,
    investorSelectItems(state: GeneralPartnerReferencesState): VuetifySelectItem<string>[] {
      return state.investorReferences.map((investorReference) => ({
        value: investorReference.investorId,
        text: investorReference.name,
      }));
    },
    investorUserSelectItems(state: GeneralPartnerReferencesState): VuetifySelectItem<string>[] {
      return state.investorUserReferences.map((investorUserReference) => ({
        value: investorUserReference.id,
        text: getNameStringFromName(investorUserReference.name),
      }));
    },
    productSelectItems(state: GeneralPartnerReferencesState): VuetifySelectItem<string>[] {
      return state.productReferences.map((productReference) => ({
        value: productReference.productId,
        text: productReference.name,
      }));
    },
  },
  actions: {

    // -- Queries

    getProductReferences(): Promise<void> {
      const { getProductReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductReferencesStatus,
        () => getProductReferences()
          .then((productReferences) => {
            this.productReferences = productReferences;
          })
      );
    },

    getInvestorReferences(): Promise<void> {
      const { getInvestorReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorReferencesStatus,
        () => getInvestorReferences()
          .then((investorReferences) => {
            this.investorReferences = investorReferences;
          })
      );
    },

    getInvestorUserReferences(): Promise<void> {
      const { getInvestorUserReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorUserReferencesStatus,
        () => getInvestorUserReferences()
          .then((investorUserReferences) => {
            this.investorUserReferences = investorUserReferences;
          })
      );
    },

    getGeneralPartnerPermissionGroupReferences(): Promise<void> {
      const { getGeneralPartnerPermissionGroupReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerPermissionGroupReferencesStatus,
        () => getGeneralPartnerPermissionGroupReferences()
          .then((generalPartnerPermissionGroupReferences) => {
            this.generalPartnerPermissionGroupReferences = generalPartnerPermissionGroupReferences;
          })
      );
    },

    getGeneralPartnerUserWithoutAccessToAllProductsReferences(): Promise<void> {
      const { getGeneralPartnerUserWithoutAccessToAllProductsReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerUserWithoutAccessToAllProductsReferencesStatus,
        () => getGeneralPartnerUserWithoutAccessToAllProductsReferences()
          .then((generalPartnerUserWithoutAccessToAllProductsReferences) => {
            this.generalPartnerUserWithoutAccessToAllProductsReferences = generalPartnerUserWithoutAccessToAllProductsReferences;
          })
      );
    },

    getAdvisorReferences(): Promise<void> {
      const { getAdvisorReferencesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAdvisorReferencesStatus,
        () => getAdvisorReferences()
          .then((advisors) => {
            this.advisorReferences = advisors;
          })
      );
    },

  },
});
