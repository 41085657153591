import { performApiRequest, performApiRequestForFile, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { UpdateProductNameCommand, PublishProductUpdateCommand, DiscardProductUpdateCommand, Product, GetProductForProductManagementQuery, GetProductPrintPDFForDownloadQuery, InvestorWithAccess, GetInvestorsWithAccessForProductQuery, UpdateInvestorsWithAccessForProductCommand, UpdateProductPreviewCommand, DeleteProductCommand, DisableProductInvestabilityCommand, EnableProductInvestabilityCommand, PublishProductCommand, UnpublishProductCommand, UpdateGeneralPartnerUsersAccessForProductCommand, GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery, GeneralPartnerUserWithAccess, ShareProductCommand, UnshareProductCommand } from './types';

export function getProductForProductManagement(query: GetProductForProductManagementQuery): Promise<Product> {
  const url = generateApiUrl('/api/general-partner/get-product-for-product-management-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function publishProductUpdate(command: PublishProductUpdateCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/publish-product-update-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function discardProductUpdate(command: DiscardProductUpdateCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/discard-product-update-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getProductPrintPDFForDownload(query: GetProductPrintPDFForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-product-print-pdf-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestorsWithAccessForProduct(query: GetInvestorsWithAccessForProductQuery): Promise<InvestorWithAccess[]> {
  const url = generateApiUrl('/api/general-partner/get-investors-with-access-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct(
  query: GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery
): Promise<GeneralPartnerUserWithAccess[]> {
  // eslint-disable-next-line max-len
  const url = generateApiUrl('/api/general-partner/get-general-partner-users-without-access-for-all-products-with-access-for-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function updateInvestorsWithAccessForProduct(command: UpdateInvestorsWithAccessForProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-investors-with-access-for-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateGeneralPartnerUsersAccessForProduct(command: UpdateGeneralPartnerUsersAccessForProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-general-partner-users-access-for-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductName(command: UpdateProductNameCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-name-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductPreview(command: UpdateProductPreviewCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-preview-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function publishProduct(command: PublishProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/publish-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function unpublishProduct(command: UnpublishProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/unpublish-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function enableProductInvestability(command: EnableProductInvestabilityCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/enable-product-investability-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function disableProductInvestability(command: DisableProductInvestabilityCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/disable-product-investability-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function shareProduct(command: ShareProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/share-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteProduct(command: DeleteProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function unshareProduct(command: UnshareProductCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/unshare-product-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
