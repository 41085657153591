import { RouteConfig } from 'vue-router';
import { Layout } from '@/application/brain/app/types';
import Login from './components/login.vue';

export const authenticationRoutes: RouteConfig[] = [
  {
    name: 'authentication/login',
    path: '/login/:token?/:code?',
    component: Login,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
      layout: Layout.PUBLIC,
    },
  },
];
