import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CreateProductNewsArticleAttachmentCommand, CreateProductNewsArticleCommand, DeleteProductNewsArticleAttachmentsCommand, DeleteProductNewsArticleCommand, GetProductNewsArticleAttachmentForDownloadQuery, UpdateProductNewsArticleCommand, GetProductNewsArticlesQuery, ProductNewsArticle } from './types';

export function getProductNewsArticles(query: GetProductNewsArticlesQuery): Promise<ProductNewsArticle[]> {
  const url = generateApiUrl('/api/general-partner/get-product-news-articles-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function createProductNewsArticle(command: CreateProductNewsArticleCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-product-news-article-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function deleteProductNewsArticle(command: DeleteProductNewsArticleCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-news-article-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateProductNewsArticle(command: UpdateProductNewsArticleCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-product-news-article-command');
  return performApiRequestWithFiles({ url, method: 'POST', data: attachGeneralPartnerAndUserToCommandWithFiles(command) });
}

export function getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-product-news-article-attachment-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function createProductNewsArticleAttachment(
  command: CreateProductNewsArticleAttachmentCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-product-news-article-attachment-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteProductNewsArticleAttachments(command: DeleteProductNewsArticleAttachmentsCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-product-news-article-attachments-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
