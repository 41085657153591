import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { GetPersonalUserDetailsQuery, PersonalUserDetails, GetUserActivityQuery, UserActivityLogEntries } from './types';

// -- Queries

export function getPersonalUserDetails(query: GetPersonalUserDetailsQuery): Promise<PersonalUserDetails> {
  const url = generateApiUrl('/api/general-partner/get-personal-user-details-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getUserActivity(query: GetUserActivityQuery): Promise<UserActivityLogEntries> {
  const url = generateApiUrl('/api/general-partner/get-user-activity-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
