import { Command, Query } from '@/application/types';
import { Language, Name, PhoneNumber, Salutation } from '@/types/global';

export interface CreateAccountFormValues {
  salutation?: Salutation;
  firstName?: string;
  lastName?: string;
  phoneNumber?: PhoneNumber;
  countryOfResidence?: string;
  timeZone?: string;
  password?: string;
}

export enum CreateAccountStep {
  ACCEPT_INVITATION,
  PERSONAL_INFORMATION,
  SECURE_ACCOUNT,
  ACCEPT_AGREEMENTS
}

export interface InvestorUserInvite {
  salutation: Salutation;
  name: Name;
  emailAddress: string;
  investorName: string;
  phoneNumber?: PhoneNumber;
  countryOfResidence?: string;
  timeZone?: string;
}

// -- Commands

export interface CreateInvestorUserAccountCommand extends Command {
  token: string;
  salutation: Salutation;
  name: {
    firstName: string;
    lastName: string;
  };
  phoneNumber: PhoneNumber;
  countryOfResidence: string;
  timeZone: string;
  password: string;
  language: Language;
}

export interface MoveToSecureAccountStepCommand extends Command {
  createAccountFormValues: CreateAccountFormValues
}

export interface MoveToAcceptAgreementsStepCommand extends Command {
  createAccountFormValues: CreateAccountFormValues
}

// -- Queries

export interface GetInvestorUserInviteByTokenQuery extends Query {
  token: string;
}
