import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { useGeneralPartnerProductManagementGeneralStore } from '@/domain/general-partner/products/product-management/store';
import { CreateProductReportCommand, DeleteProductReportCommand, GetProductReportForDownloadQuery, GetProductReportsQuery, ProductReport, UpdateProductReportCommand } from './types';
import { createProductReport, deleteProductReport, getProductReports, updateProductReport, getProductReportForDownload } from './service';

interface GeneralPartnerProductManagementReportingTabState {
  productReports: ProductReport[]

  getProductReportsStatus: ActionStatus;
  getProductReportForDownloadStatus: ActionStatus;
  createProductReportStatus: ActionStatus;
  updateProductReportStatus: ActionStatus;
  deleteProductReportStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementReportingTabState {
  return {
    productReports: [],

    getProductReportsStatus: ActionStatus.None,
    getProductReportForDownloadStatus: ActionStatus.None,
    createProductReportStatus: ActionStatus.None,
    updateProductReportStatus: ActionStatus.None,
    deleteProductReportStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementReportingTabStore = defineStore('generalPartnerProductManagementReportingTab', {
  state: (): GeneralPartnerProductManagementReportingTabState => initialState(),
  getters: {
    isGetProductReportsProcessing: (state: GeneralPartnerProductManagementReportingTabState): boolean =>
      state.getProductReportsStatus === ActionStatus.InProgress,
    isGetProductReportForDownloadProcessing: (state: GeneralPartnerProductManagementReportingTabState): boolean =>
      state.getProductReportForDownloadStatus === ActionStatus.InProgress,
    isCreateProductReportProcessing: (state: GeneralPartnerProductManagementReportingTabState): boolean =>
      state.createProductReportStatus === ActionStatus.InProgress,
    isUpdateProductReportProcessing: (state: GeneralPartnerProductManagementReportingTabState): boolean =>
      state.updateProductReportStatus === ActionStatus.InProgress,
    isDeleteProductReportProcessing: (state: GeneralPartnerProductManagementReportingTabState): boolean =>
      state.deleteProductReportStatus === ActionStatus.InProgress,
    hasProductReports: (state: GeneralPartnerProductManagementReportingTabState): boolean =>
      state.productReports.length > 0,
  },
  actions: {

    // -- Queries

    getProductReports(): Promise<void> {
      const query: GetProductReportsQuery = {
        productId: useGeneralPartnerProductManagementGeneralStore().currentProductId!,
      };

      const { getProductReportsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductReportsStatus,
        () => getProductReports(query)
          .then((productReports) => {
            this.productReports = productReports;
          })
      );
    },

    getProductReportForDownload(query: GetProductReportForDownloadQuery): Promise<FileResponse> {
      const { getProductReportForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductReportForDownloadStatus,
        () => getProductReportForDownload(query)
      );
    },

    // -- Commands

    createProductReport(command: CreateProductReportCommand): Promise<void> {
      const { createProductReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createProductReportStatus,
        () => createProductReport(command)
          .then(() => this.getProductReports())
      );
    },

    updateProductReport(command: UpdateProductReportCommand): Promise<void> {
      const { updateProductReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductReportStatus,
        () => updateProductReport(command)
          .then(() => this.getProductReports())
      );
    },

    deleteProductReport(command: DeleteProductReportCommand): Promise<void> {
      const { deleteProductReportStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductReportStatus,
        () => deleteProductReport(command)
          .then(() => this.getProductReports())
      );
    },

  },
});
