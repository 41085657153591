import { FileResponse } from '@/application/types';
import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { CreateSubscriptionDocumentTemplatesCommand, DeleteSubscriptionDocumentTemplateCommand, DisableDocumentManagementForSubscriptionProcessCommand, EnableDocumentManagementForSubscriptionProcessCommand, GetSubscriptionDocumentTemplateFileForDownloadQuery, UpdateKYCInstructionCommand, UpdateSubscriptionInstructionCommand, CreateControllingPersonTemplateToPrefillCommand, DeleteControllingPersonTemplateToPrefillCommand, GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery, GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery, DeleteSubscriptionAgreementTemplateToPrefillCommand, CreateSubscriptionAgreementTemplateToPrefillCommand } from './types';

// -- Queries

export function getSubscriptionDocumentTemplateFileForDownload(
  query: GetSubscriptionDocumentTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-subscription-document-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload(
  query: GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-subscription-agreement-template-to-prefill-original-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getControllingPersonTemplateToPrefillOriginalFileForDownload(
  query: GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-controlling-person-template-to-prefill-original-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

// -- Commands

export function createSubscriptionDocumentTemplate(
  command: CreateSubscriptionDocumentTemplatesCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-subscription-document-template-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteSubscriptionDocumentTemplate(command: DeleteSubscriptionDocumentTemplateCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-subscription-document-template-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateSubscriptionInstruction(command: UpdateSubscriptionInstructionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-subscription-instruction-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateKYCInstruction(command: UpdateKYCInstructionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/update-kyc-instruction-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function enableDocumentManagementForSubscriptionProcess(
  command: EnableDocumentManagementForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/enable-document-management-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function disableDocumentManagementForSubscriptionProcess(
  command: DisableDocumentManagementForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/disable-document-management-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createSubscriptionAgreementTemplateToPrefill(
  command: CreateSubscriptionAgreementTemplateToPrefillCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-subscription-agreement-template-to-prefill-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function createControllingPersonTemplateToPrefill(
  command: CreateControllingPersonTemplateToPrefillCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-controlling-person-template-to-prefill-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteSubscriptionAgreementTemplateToPrefill(
  command: DeleteSubscriptionAgreementTemplateToPrefillCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-subscription-agreement-template-to-prefill-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteControllingPersonTemplateToPrefill(
  command: DeleteControllingPersonTemplateToPrefillCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-controlling-person-template-to-prefill-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
