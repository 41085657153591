import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { AssetClasses, KPI, KPIsByKPICategoryId } from '@/types/global';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { useGeneralPartnerProductRestrictionHintStore } from '@/domain/general-partner/product-restriction-hint/store';
import { createProduct, getTermSheetTemplateForAssetClass } from './service';
import {
  AddCustomKPICommand,
  CreateProductCommand,
  GetTermSheetTemplateForAssetClassQuery,
  KPIDefinition,
  MasterDataFormValues,
  ProductCreationStep,
  ProductCreationSteps,
  ProductDataFormValues,
  TermSheetFormValues,
  TermSheetTemplate
} from './types';

interface GeneralPartnerProductCreationState {
  termSheet: TermSheetTemplate | null;
  formValues: ProductDataFormValues
  customKPIs: KPIsByKPICategoryId;
  currentProductCreationStep: ProductCreationStep;

  createProductStatus: ActionStatus;
  getTermSheetForAssetClassStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductCreationState {
  return {
    termSheet: null,
    formValues: {
      masterData: {
        productName: null,
        assetClass: AssetClasses.DIGITAL_ASSETS,
      },
      termSheet: {
        kpiCategories: [],
      },
      idsOfInvestorsWithAccess: [],
      idsOfGeneralPartnerUsersWithExplicitAccess: [],
      kpiIdsForProductInfo: [],
    },
    customKPIs: {},
    currentProductCreationStep: ProductCreationSteps.DEFINE_MASTER_DATA,

    createProductStatus: ActionStatus.None,
    getTermSheetForAssetClassStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductCreationStore = defineStore('generalPartnerProductCreation', {
  state: (): GeneralPartnerProductCreationState => initialState(),
  getters: {
    isCreateProductProcessing: (state: GeneralPartnerProductCreationState): boolean =>
      state.createProductStatus === ActionStatus.InProgress,
    isGetTermSheetForAssetClassProcessing: (state: GeneralPartnerProductCreationState): boolean =>
      state.getTermSheetForAssetClassStatus === ActionStatus.InProgress,
    isDefineMasterDataCurrentStep: (state: GeneralPartnerProductCreationState): boolean =>
      state.currentProductCreationStep === ProductCreationSteps.DEFINE_MASTER_DATA,
    isDefineApplicationMetricsCurrentStep: (state: GeneralPartnerProductCreationState): boolean =>
      state.currentProductCreationStep === ProductCreationSteps.DEFINE_TERM_SHEET,
    isDefineGeneralPartnerUserAccessCurrentStep: (state: GeneralPartnerProductCreationState): boolean =>
      state.currentProductCreationStep === ProductCreationSteps.DEFINE_GENERAL_PARTNER_USER_ACCESS,
    isDefineInvestorAccessCurrentStep: (state: GeneralPartnerProductCreationState): boolean =>
      state.currentProductCreationStep === ProductCreationSteps.DEFINE_INVESTOR_ACCESS,
    isDefineProductInfoCurrentStep: (state: GeneralPartnerProductCreationState): boolean =>
      state.currentProductCreationStep === ProductCreationSteps.DEFINE_PRODUCT_INFO,
    kpiDefinitionsForCategory: (state: GeneralPartnerProductCreationState):
      (kpiCategoryId: string) => KPIDefinition[] => (kpiCategoryId) => {
      if (!state.termSheet) {
        return [];
      }

      return state.termSheet.kpiDefinitions
        .filter((kpiDefinition) => kpiDefinition.kpiCategoryId === kpiCategoryId);
    },
    kpiDefinitionById: (state: GeneralPartnerProductCreationState) => (kpiDefinitionId: string) => {
      const kpiDefinition = state.termSheet
        && state.termSheet.kpiDefinitions.find((kpiDefinition) => kpiDefinition.kpiDefinitionId === kpiDefinitionId);

      if (!kpiDefinition) {
        throw new Error('KPI definition wasn\'t found');
      }

      return kpiDefinition;
    },
    kpis: (state: GeneralPartnerProductCreationState): KPI[] =>
      getKPIsFromTermSheet(state.formValues.termSheet),
  },
  actions: {

    // -- State management

    goToNextStep(): Promise<void> {
      switch (this.currentProductCreationStep) {
        case ProductCreationSteps.DEFINE_MASTER_DATA:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_TERM_SHEET;
          break;
        case ProductCreationSteps.DEFINE_TERM_SHEET:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_GENERAL_PARTNER_USER_ACCESS;
          break;
        case ProductCreationSteps.DEFINE_GENERAL_PARTNER_USER_ACCESS:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_INVESTOR_ACCESS;
          break;
        case ProductCreationSteps.DEFINE_INVESTOR_ACCESS:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_PRODUCT_INFO;
          break;
        default:
          throw new Error('Next product creation step wasn\'t found');
      }

      return Promise.resolve();
    },

    goToPreviousStep(): Promise<void> {
      switch (this.currentProductCreationStep) {
        case ProductCreationSteps.DEFINE_TERM_SHEET:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_MASTER_DATA;
          break;
        case ProductCreationSteps.DEFINE_GENERAL_PARTNER_USER_ACCESS:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_TERM_SHEET;
          break;
        case ProductCreationSteps.DEFINE_INVESTOR_ACCESS:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_GENERAL_PARTNER_USER_ACCESS;
          break;
        case ProductCreationSteps.DEFINE_PRODUCT_INFO:
          this.currentProductCreationStep = ProductCreationSteps.DEFINE_INVESTOR_ACCESS;
          break;
        default:
          throw new Error('Previous product creation step wasn\'t found');
      }

      return Promise.resolve();
    },

    updateMasterDataFormValues(formValues: MasterDataFormValues): Promise<void> {
      // We need to reset the term sheet form values when the asset class changes
      if (this.formValues.masterData.assetClass !== formValues.assetClass) {
        this.formValues.termSheet.kpiCategories = [];
      }

      this.formValues.masterData = formValues;

      return Promise.resolve();
    },

    updateTermSheet(termSheet: TermSheetFormValues): Promise<void> {
      this.formValues.termSheet = termSheet;

      return Promise.resolve();
    },

    updateIdsOfInvestorsWithAccess(idsOfInvestorsWithAccess: string[]): Promise<void> {
      this.formValues.idsOfInvestorsWithAccess = idsOfInvestorsWithAccess;

      return Promise.resolve();
    },

    updateIdsOfGeneralPartnerUsersWithExplicitAccess(idsOfGeneralPartnerUsersWithExplicitAccess: string[]): Promise<void> {
      this.formValues.idsOfGeneralPartnerUsersWithExplicitAccess = idsOfGeneralPartnerUsersWithExplicitAccess;

      return Promise.resolve();
    },

    updateKPIIdsForProductInfo(kpiIdsForProductInfo: string[]): Promise<void> {
      this.formValues.kpiIdsForProductInfo = kpiIdsForProductInfo;

      return Promise.resolve();
    },

    addCustomKPI(command: AddCustomKPICommand): Promise<void> {
      this.customKPIs = {
        ...this.customKPIs,
        [command.kpiCategoryId]: this.customKPIs[command.kpiCategoryId]
          ? [...this.customKPIs[command.kpiCategoryId], command.customKPI]
          : [command.customKPI],
      };

      return Promise.resolve();
    },

    updateCustomKPI(updatedCustomKPI: KPI): Promise<void> {
      const newCustomKPIs = { ...this.customKPIs };

      for (const [categoryId, kpis] of Object.entries(this.customKPIs)) {
        if (kpis.find((kpi) => kpi.kpiId === updatedCustomKPI.kpiId)) {
          newCustomKPIs[categoryId] = this.customKPIs[categoryId]
            .map((kpi) => kpi.kpiId === updatedCustomKPI.kpiId ? updatedCustomKPI : kpi);
        }
      }

      this.customKPIs = newCustomKPIs;

      return Promise.resolve();
    },

    removeCustomKPI(customKPIToRemove: KPI): Promise<void> {
      const newCustomKPIs = { ...this.customKPIs };

      for (const [categoryId, kpis] of Object.entries(this.customKPIs)) {
        if (kpis.find((kpi) => kpi.kpiId === customKPIToRemove.kpiId)) {
          newCustomKPIs[categoryId] = this.customKPIs[categoryId]
            .filter((kpi) => kpi.kpiId !== customKPIToRemove.kpiId);
        }
      }

      this.customKPIs = newCustomKPIs;

      return Promise.resolve();
    },

    // -- Queries

    getTermSheetForAssetClass(query: GetTermSheetTemplateForAssetClassQuery): Promise<void> {
      const { getTermSheetForAssetClassStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTermSheetForAssetClassStatus,
        () => getTermSheetTemplateForAssetClass(query)
          .then((termSheet) => {
            this.termSheet = termSheet;
          })
      );
    },

    // -- Commands

    createProduct(command: CreateProductCommand): Promise<void> {
      const { createProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createProductStatus,
        () => createProduct(command)
          .then(useGeneralPartnerProductRestrictionHintStore().getProductAccessForUser)
      );
    },

  },
});
