import axios from 'axios';
import i18n from '@/plugins/vue-i18n';
import { Snack } from '@/application/common/snackbar/types';
import { useSnackbarStore } from '@/application/common/snackbar/store';

export function showSuccess(snack: Snack): void {
  useSnackbarStore().showSuccess(snack);
}

export function showInfo(snack: Snack): void {
  useSnackbarStore().showInfo(snack);
}

export function showError(snack: Snack): void {
  useSnackbarStore().showError(snack);
}

export function showErrorResponse(error: any): void {
  useSnackbarStore().showSuccess({ message: error.response?.data.message || i18n.t('errors.defaultFallbackMessage') });
}

export function showErrorResponseExcludingCanceled(error: any): void {
  if (!axios.isCancel(error)) {
    showErrorResponse(error);
  }
}
