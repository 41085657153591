import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { AddDocumentWithPlaceholdersToControllingPersonTemplatesToPrefillCommand, ControllingPersonTemplateToPrefill, GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery, ReleaseControllingPersonTemplateToPrefillCommand, GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery, SubscriptionAgreementTemplateToPrefill, ReleaseSubscriptionAgreementTemplateToPrefillCommand, AddDocumentWithPlaceholdersToSubscriptionAgreementTemplatesToPrefillCommand, GetSamplePrefilledSubscriptionAgreementTemplateFileForDownloadQuery, GetSamplePrefilledControllingPersonTemplateFileForDownloadQuery } from './types';
import { addDocumentWithPlaceholdersToControllingPersonTemplateToPrefill, getControllingPersonTemplatesToPrefill, getControllingPersonTemplateToPrefillOriginalFileForDownload, getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload, getSubscriptionAgreementTemplatesToPrefill, releaseControllingPersonTemplateToPrefill, releaseSubscriptionAgreementTemplateToPrefill, addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefill, getSamplePrefilledSubscriptionAgreementTemplateFileForDownload, getSamplePrefilledControllingPersonTemplateFileForDownload } from './service';

interface BrainSubscriptionDocumentTemplatesState {
  subscriptionAgreementTemplatesToPrefill: SubscriptionAgreementTemplateToPrefill[];
  controllingPersonTemplatesToPrefill: ControllingPersonTemplateToPrefill[];

  getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus: ActionStatus;
  getSubscriptionAgreementTemplatesToPrefillStatus: ActionStatus;
  getControllingPersonTemplatesToPrefillStatus: ActionStatus;
  getControllingPersonTemplateToPrefillOriginalFileForDownloadStatus: ActionStatus;
  addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefillStatus: ActionStatus;
  addDocumentWithPlaceholdersToControllingPersonTemplateToPrefillStatus: ActionStatus;
  releaseSubscriptionAgreementTemplateToPrefillStatus: ActionStatus;
  releaseControllingPersonTemplateToPrefillStatus: ActionStatus;
  getPrefilledSubscriptionAgreementTemplateFileForDownloadStatus: ActionStatus;
  getPrefilledControllingPersonTemplateFileForDownloadStatus: ActionStatus;
}

function initialState(): BrainSubscriptionDocumentTemplatesState {
  return {
    subscriptionAgreementTemplatesToPrefill: [],
    controllingPersonTemplatesToPrefill: [],

    getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus: ActionStatus.None,
    getSubscriptionAgreementTemplatesToPrefillStatus: ActionStatus.None,
    getControllingPersonTemplatesToPrefillStatus: ActionStatus.None,
    getControllingPersonTemplateToPrefillOriginalFileForDownloadStatus: ActionStatus.None,
    addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefillStatus: ActionStatus.None,
    addDocumentWithPlaceholdersToControllingPersonTemplateToPrefillStatus: ActionStatus.None,
    releaseSubscriptionAgreementTemplateToPrefillStatus: ActionStatus.None,
    releaseControllingPersonTemplateToPrefillStatus: ActionStatus.None,
    getPrefilledSubscriptionAgreementTemplateFileForDownloadStatus: ActionStatus.None,
    getPrefilledControllingPersonTemplateFileForDownloadStatus: ActionStatus.None,
  };
}

export const useBrainSubscriptionDocumentTemplatesStore = defineStore('brainSubscriptionDocumentTemplates', {
  state: (): BrainSubscriptionDocumentTemplatesState => initialState(),
  getters: {
    isGetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadProcessing:
      (state: BrainSubscriptionDocumentTemplatesState): boolean =>
        state.getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus === ActionStatus.InProgress,
    isGetSubscriptionAgreementTemplatesToPrefillProcessing:
      (state: BrainSubscriptionDocumentTemplatesState): boolean =>
        state.getSubscriptionAgreementTemplatesToPrefillStatus === ActionStatus.InProgress,
    isAddDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefillProcessing:
      (state: BrainSubscriptionDocumentTemplatesState): boolean =>
        state.addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefillStatus === ActionStatus.InProgress,
    isReleaseSubscriptionAgreementTemplateToPrefillProcessing:
      (state: BrainSubscriptionDocumentTemplatesState): boolean =>
        state.releaseSubscriptionAgreementTemplateToPrefillStatus === ActionStatus.InProgress,
    isGetControllingPersonTemplatesToPrefillProcessing:
      (state: BrainSubscriptionDocumentTemplatesState): boolean =>
        state.getControllingPersonTemplatesToPrefillStatus === ActionStatus.InProgress,
    isAddDocumentWithPlaceholdersToControllingPersonTemplateToPrefillProcessing:
      (state: BrainSubscriptionDocumentTemplatesState): boolean =>
        state.addDocumentWithPlaceholdersToControllingPersonTemplateToPrefillStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getSubscriptionAgreementTemplatesToPrefill(): Promise<void> {
      const { getSubscriptionAgreementTemplatesToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionAgreementTemplatesToPrefillStatus,
        () => getSubscriptionAgreementTemplatesToPrefill()
          .then((subscriptionAgreementTemplatesToPrefill) => {
            this.subscriptionAgreementTemplatesToPrefill = subscriptionAgreementTemplatesToPrefill;
          })
      );
    },

    getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload(
      query: GetSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionAgreementTemplateToPrefillOriginalFileForDownloadStatus,
        () => getSubscriptionAgreementTemplateToPrefillOriginalFileForDownload(query)
      );
    },

    getControllingPersonTemplatesToPrefill(): Promise<void> {
      const { getControllingPersonTemplatesToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getControllingPersonTemplatesToPrefillStatus,
        () => getControllingPersonTemplatesToPrefill()
          .then((controllingPersonTemplatesToPrefill) => {
            this.controllingPersonTemplatesToPrefill = controllingPersonTemplatesToPrefill;
          })
      );
    },

    getControllingPersonTemplateToPrefillOriginalFileForDownload(
      query: GetControllingPersonTemplateToPrefillOriginalFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getControllingPersonTemplateToPrefillOriginalFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getControllingPersonTemplateToPrefillOriginalFileForDownloadStatus,
        () => getControllingPersonTemplateToPrefillOriginalFileForDownload(query)
      );
    },

    getSamplePrefilledSubscriptionAgreementTemplateFileForDownloadQuery(
      query: GetSamplePrefilledSubscriptionAgreementTemplateFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getPrefilledSubscriptionAgreementTemplateFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPrefilledSubscriptionAgreementTemplateFileForDownloadStatus,
        () => getSamplePrefilledSubscriptionAgreementTemplateFileForDownload(query)
      );
    },

    getSamplePrefilledControllingPersonTemplateFileForDownloadQuery(
      query: GetSamplePrefilledControllingPersonTemplateFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getPrefilledControllingPersonTemplateFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPrefilledControllingPersonTemplateFileForDownloadStatus,
        () => getSamplePrefilledControllingPersonTemplateFileForDownload(query)
      );
    },

    // -- Commands

    addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefill(
      command: AddDocumentWithPlaceholdersToSubscriptionAgreementTemplatesToPrefillCommand
    ): Promise<void> {
      const { addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefillStatus,
        () => addDocumentWithPlaceholdersToSubscriptionAgreementTemplateToPrefill(command)
          .then(() => this.getSubscriptionAgreementTemplatesToPrefill())
      );
    },

    releaseSubscriptionAgreementTemplateToPrefill(
      command: ReleaseSubscriptionAgreementTemplateToPrefillCommand
    ): Promise<void> {
      const { releaseSubscriptionAgreementTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        releaseSubscriptionAgreementTemplateToPrefillStatus,
        () => releaseSubscriptionAgreementTemplateToPrefill(command)
          .then(() => this.getSubscriptionAgreementTemplatesToPrefill())
      );
    },

    addDocumentWithPlaceholdersToControllingPersonTemplateToPrefill(
      command: AddDocumentWithPlaceholdersToControllingPersonTemplatesToPrefillCommand
    ): Promise<void> {
      const { addDocumentWithPlaceholdersToControllingPersonTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addDocumentWithPlaceholdersToControllingPersonTemplateToPrefillStatus,
        () => addDocumentWithPlaceholdersToControllingPersonTemplateToPrefill(command)
          .then(() => this.getControllingPersonTemplatesToPrefill())
      );
    },

    releaseControllingPersonTemplateToPrefill(
      command: ReleaseControllingPersonTemplateToPrefillCommand
    ): Promise<void> {
      const { releaseControllingPersonTemplateToPrefillStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        releaseControllingPersonTemplateToPrefillStatus,
        () => releaseControllingPersonTemplateToPrefill(command)
          .then(() => this.getControllingPersonTemplatesToPrefill())
      );
    },

  },
});
