import { render, staticRenderFns } from "./product-nda.vue?vue&type=template&id=fb167f52&scoped=true&"
import script from "./product-nda.vue?vue&type=script&lang=ts&"
export * from "./product-nda.vue?vue&type=script&lang=ts&"
import style0 from "./product-nda.vue?vue&type=style&index=0&id=fb167f52&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb167f52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard})
