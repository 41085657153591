import { Language } from '@/types/global';

export interface Authentication {
  brainiac: Brainiac;
}

export interface Brainiac {
  brainiacId: string;
  language: Language;
  timeZone: string;
}

export type LoginType = 'DIRECT' | 'TWO_FACTOR_AUTHENTICATION';
export const LoginTypes: Record<LoginType, LoginType> = {
  DIRECT: 'DIRECT',
  TWO_FACTOR_AUTHENTICATION: 'TWO_FACTOR_AUTHENTICATION',
};

export interface LoginResponse {
  loginType: LoginType;
}

export interface DirectLoginResponse extends LoginResponse {
  loginType: 'DIRECT';
  authentication: Authentication;
}

export interface TwoFactorAuthenticationLoginResponse extends LoginResponse {
  loginType: 'TWO_FACTOR_AUTHENTICATION';
  token: string;
}

export function isTwoFactorAuthenticationLoginResponse(
  loginResponse: DirectLoginResponse|TwoFactorAuthenticationLoginResponse
): loginResponse is TwoFactorAuthenticationLoginResponse {
  return loginResponse.loginType === LoginTypes.TWO_FACTOR_AUTHENTICATION;
}

// -- Commands

export interface LoginCommand {
  emailAddress: string;
  password: string;
}

export interface ConfirmTwoFactorAuthenticationCommand {
  token: string;
  code: string;
}
