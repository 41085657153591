import { RouteConfig } from 'vue-router';
import NetworkProducts from './components/network-products.vue';

export const networkProductsRoutes: RouteConfig[] = [
  {
    name: 'network-products',
    path: '/network-products',
    component: NetworkProducts,
    meta: {
      requiresAuth: true,
    },
  },
];
