import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { CurrencySymbols, KPI, KPITypes, SubscriptionProcessStatuses } from '@/types/global';
import { formatCurrencyWithoutCentDigits } from '@/filters/currencies';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { wrapActionWithProgress } from '@/store';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { agreeToSubscriptionDataProcessing, confirmSelectedInvestmentProfileForSubscriptionProcess, confirmSubscription, continueSubscriptionProcessAsNaturalPerson, copyInvestorKYCDocumentsToKYCDocumentAttachments, createSubscriptionDocument, deleteKYCDocumentAttachment, deleteSubscriptionDocument, getControllingPersonTemplatesToPrefillForProduct, getInvestorKYCDocuments, getKYCDocumentAttachmentFileForDownload, getKYCDocumentAttachmentsForSubscriptionProcess, getPrefilledControllingPersonTemplateForDownload, getProductForCurrentSubscriptionProcess, getSubscriptionDocumentFileForDownload, getSubscriptionDocumentsForSubscriptionProcess, getSubscriptionAgreementTemplatesToPrefillForProduct, getSubscriptionProcessForCurrentProduct, selectInvestmentProfileForSubscriptionProcess, specifySubscriptionInvestmentAmount, startSubscriptionProcess, getPrefilledSubscriptionAgreementTemplateForDownload, getSubscriptionAgreementTemplateFileForDownload } from './service';
import { AgreeToSubscriptionDataProcessingCommand, ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand, ConfirmSubscriptionCommand, ContinueSubscriptionProcessAsNaturalPersonCommand, ControllingPersonTemplateToPrefill, CopyInvestorKYCDocumentsToKYCDocumentAttachmentsCommand, CreateSubscriptionDocumentCommand, DeleteKYCDocumentAttachmentCommand, DeleteSubscriptionDocumentCommand, GetControllingPersonTemplatesToPrefillForProductQuery, GetKYCDocumentAttachmentFileForDownloadQuery, GetKYCDocumentAttachmentsForSubscriptionProcessQuery, GetPrefilledControllingPersonTemplateForDownloadQuery, GetProductForCurrentSubscriptionProcessQuery, GetSubscriptionDocumentFileForDownloadQuery, GetSubscriptionDocumentsForSubscriptionProcessQuery, GetSubscriptionAgreementTemplatesToPrefillForProductQuery, GetSubscriptionProcessForCurrentProductQuery, KYCDocument, KYCDocumentAttachment, ProductForCurrentSubscriptionProcess, SelectInvestmentProfileForSubscriptionProcessCommand, SpecifySubscriptionInvestmentAmountCommand, StartSubscriptionProcessCommand, SubscriptionDocument, SubscriptionAgreementTemplateToPrefill, SubscriptionProcess, SubscriptionProcessStep, GetPrefilledSubscriptionAgreementTemplateForDownloadQuery, GetSubscriptionAgreementTemplateFileForDownloadQuery } from './types';

interface InvestorSubscriptionProcessDialogState {
  currentProductId:string | null;
  currentSubscriptionProcessId:string | null;
  currentSubscriptionProcessStep:SubscriptionProcessStep | null;
  currentSubscriptionProcess:SubscriptionProcess | null;
  subscriptionDocuments:SubscriptionDocument[];
  subscriptionAgreementTemplatesToPrefill:SubscriptionAgreementTemplateToPrefill[];
  controllingPersonTemplatesToPrefill:ControllingPersonTemplateToPrefill[];
  currentProduct:ProductForCurrentSubscriptionProcess | null;
  investorKYCDocuments:KYCDocument[];
  kycDocumentAttachments:KYCDocumentAttachment[];

  getProductForCurrentSubscriptionProcessStatus: ActionStatus;
  getSubscriptionProcessForCurrentProductStatus: ActionStatus;
  startSubscriptionProcessStatus: ActionStatus;
  specifySubscriptionInvestmentAmountStatus: ActionStatus;
  selectInvestmentProfileForSubscriptionProcessStatus: ActionStatus;
  confirmSelectedInvestmentProfileForSubscriptionProcessStatus: ActionStatus;
  agreeToSubscriptionDataProcessingStatus: ActionStatus;
  confirmSubscriptionStatus: ActionStatus;
  getSubscriptionDocumentFileForDownloadStatus: ActionStatus;
  getSubscriptionAgreementTemplateFileForDownloadStatus: ActionStatus;
  createSubscriptionDocumentsStatus: ActionStatus;
  getSubscriptionDocumentsForSubscriptionProcessStatus: ActionStatus;
  getKYCDocumentAttachmentsForSubscriptionProcessStatus: ActionStatus;
  getInvestorKYCDocumentsStatus: ActionStatus;
  copyInvestorKYCDocumentsToKYCDocumentAttachmentsStatus: ActionStatus;
  getKYCDocumentAttachmentFileForDownloadStatus: ActionStatus;
  deleteKYCDocumentAttachmentStatus: ActionStatus;
  deleteSubscriptionDocumentStatus: ActionStatus;
  continueSubscriptionProcessAsNaturalPersonStatus: ActionStatus;
  getSubscriptionAgreementTemplatesToPrefillForProductStatus: ActionStatus;
  getPrefilledSubscriptionAgreementTemplateForDownloadStatus: ActionStatus;
  getControllingPersonTemplatesToPrefillForProductStatus: ActionStatus;
  getPrefilledControllingPersonTemplateForDownloadStatus: ActionStatus;
}

function initialState(): InvestorSubscriptionProcessDialogState {
  return {
    currentProductId: null,
    currentSubscriptionProcessId: null,
    currentSubscriptionProcessStep: SubscriptionProcessStep.AMOUNT_SPECIFICATION,
    currentSubscriptionProcess: null,
    subscriptionDocuments: [],
    subscriptionAgreementTemplatesToPrefill: [],
    controllingPersonTemplatesToPrefill: [],
    currentProduct: null,
    investorKYCDocuments: [],
    kycDocumentAttachments: [],

    getProductForCurrentSubscriptionProcessStatus: ActionStatus.None,
    getSubscriptionProcessForCurrentProductStatus: ActionStatus.None,
    startSubscriptionProcessStatus: ActionStatus.None,
    specifySubscriptionInvestmentAmountStatus: ActionStatus.None,
    selectInvestmentProfileForSubscriptionProcessStatus: ActionStatus.None,
    confirmSelectedInvestmentProfileForSubscriptionProcessStatus: ActionStatus.None,
    agreeToSubscriptionDataProcessingStatus: ActionStatus.None,
    confirmSubscriptionStatus: ActionStatus.None,
    getSubscriptionDocumentFileForDownloadStatus: ActionStatus.None,
    getSubscriptionAgreementTemplateFileForDownloadStatus: ActionStatus.None,
    createSubscriptionDocumentsStatus: ActionStatus.None,
    getSubscriptionDocumentsForSubscriptionProcessStatus: ActionStatus.None,
    getKYCDocumentAttachmentsForSubscriptionProcessStatus: ActionStatus.None,
    getInvestorKYCDocumentsStatus: ActionStatus.None,
    copyInvestorKYCDocumentsToKYCDocumentAttachmentsStatus: ActionStatus.None,
    getKYCDocumentAttachmentFileForDownloadStatus: ActionStatus.None,
    deleteKYCDocumentAttachmentStatus: ActionStatus.None,
    deleteSubscriptionDocumentStatus: ActionStatus.None,
    continueSubscriptionProcessAsNaturalPersonStatus: ActionStatus.None,
    getSubscriptionAgreementTemplatesToPrefillForProductStatus: ActionStatus.None,
    getPrefilledSubscriptionAgreementTemplateForDownloadStatus: ActionStatus.None,
    getControllingPersonTemplatesToPrefillForProductStatus: ActionStatus.None,
    getPrefilledControllingPersonTemplateForDownloadStatus: ActionStatus.None,
  };
}

export const useInvestorSubscriptionProcessDialogStore = defineStore('investorSubscriptionProcessDialog', {
  state: (): InvestorSubscriptionProcessDialogState => initialState(),
  getters: {
    hasSubscriptionDocumentTemplates: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.subscriptionAgreementTemplates
      && state.currentSubscriptionProcess.subscriptionAgreementTemplates.length > 0,
    isPrefilledSubscriptionDocumentTemplatesVisible: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.selectedInvestmentProfileId
      && state.subscriptionAgreementTemplatesToPrefill.length > 0,
    isPrefilledControllingPersonTemplatesVisible: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.selectedInvestmentProfileId
      && state.controllingPersonTemplatesToPrefill.length > 0,
    currencySymbol: (state: InvestorSubscriptionProcessDialogState): string =>
      state.currentProduct !== null
        ? CurrencySymbols[state.currentProduct.currency]
        : '',
    isNaturalPersonSubscriptionProcess: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && state.currentSubscriptionProcess.isNaturalPersonSelected,
    hasKYCDocumentAttachments: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.kycDocumentAttachments
      && state.kycDocumentAttachments.length > 0,
    isInvestmentAmountSpecified: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.investmentAmount,
    hasUploadedDocuments: (state: InvestorSubscriptionProcessDialogState): boolean =>
      state.subscriptionDocuments.length > 0,
    hasUploadedKYCDocumentAttachments: (state: InvestorSubscriptionProcessDialogState): boolean =>
      state.kycDocumentAttachments.length > 0,
    isProductAndSubscriptionProcessAvailable: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentProduct && !!state.currentSubscriptionProcess,
    isSubscriptionProcessInReview: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && state.currentSubscriptionProcess.status === SubscriptionProcessStatuses.IN_REVIEW,
    isDocumentManagementForSubscriptionProcessEnabled: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.isDocumentManagementForSubscriptionProcessEnabled || false,
    isInvestmentAmountDefined: (state: InvestorSubscriptionProcessDialogState): boolean =>
      !!state.currentSubscriptionProcess
      && !!state.currentSubscriptionProcess.investmentAmount,
    formattedInvestmentAmount: (state: InvestorSubscriptionProcessDialogState): string =>
      state.currentSubscriptionProcess
      && state.currentSubscriptionProcess.investmentAmount
      && state.currentProduct
        ? formatCurrencyWithoutCentDigits(
          state.currentSubscriptionProcess.investmentAmount!,
          state.currentProduct.currency
        )
        : '',
    nextSubscriptionProcessStep: (state: InvestorSubscriptionProcessDialogState): SubscriptionProcessStep =>
      calculateNextSubscriptionProcessStep(state.currentSubscriptionProcess),
    currentProductMinimumInvestmentSizeKPI: (state: InvestorSubscriptionProcessDialogState): KPI | null => {
      if (state.currentProduct) {
        const kpis = getKPIsFromTermSheet(state.currentProduct.termSheet);
        return kpis.find((kpi) => kpi.kpiType === KPITypes.MINIMUM_INVESTMENT_SIZE) || null;
      }
      return null;
    },
    hasSelectedInvestmentProfile(state: InvestorSubscriptionProcessDialogState): boolean {
      return !!state.currentSubscriptionProcess && !!state.currentSubscriptionProcess.selectedInvestmentProfileId;
    },
  },
  actions: {
    // -- State management

    updateCurrentProductId(currentProductId: string): Promise<void> {
      this.currentProductId = currentProductId;

      return Promise.resolve();
    },

    updateCurrentSubscriptionProcessStep(currentStep: SubscriptionProcessStep): Promise<void> {
      this.currentSubscriptionProcessStep = currentStep;

      return Promise.resolve();
    },

    // -- Queries

    getProductForCurrentSubscriptionProcess(): Promise<void> {
      const query: GetProductForCurrentSubscriptionProcessQuery = {
        productId: this.currentProductId!,
      };

      const { getProductForCurrentSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductForCurrentSubscriptionProcessStatus,
        () => getProductForCurrentSubscriptionProcess(query)
          .then((product) => {
            this.currentProduct = product;
          })
      );
    },

    getSubscriptionDocumentsForSubscriptionProcess(): Promise<void> {
      const query : GetSubscriptionDocumentsForSubscriptionProcessQuery = {
        subscriptionProcessId: this.currentSubscriptionProcessId!,
      };

      const { getSubscriptionDocumentsForSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionDocumentsForSubscriptionProcessStatus,
        () => getSubscriptionDocumentsForSubscriptionProcess(query)
          .then((subscriptionDocuments) => {
            this.subscriptionDocuments = subscriptionDocuments;
          })
      );
    },

    getSubscriptionProcessForCurrentProduct(): Promise<void> {
      const query: GetSubscriptionProcessForCurrentProductQuery = {
        productId: this.currentProductId!,
      };

      const { getSubscriptionProcessForCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionProcessForCurrentProductStatus,
        () => getSubscriptionProcessForCurrentProduct(query)
          .then((subscriptionProcess) => {
            this.currentSubscriptionProcessId = subscriptionProcess.subscriptionProcessId;
            this.currentSubscriptionProcess = subscriptionProcess;
            this.currentSubscriptionProcessStep = calculateNextSubscriptionProcessStep(subscriptionProcess);
          }).catch((error) => {
            if (error.response?.status === 404) {
              return this.startSubscriptionProcess()
                .then(() => this.getSubscriptionProcessForCurrentProduct());
            }

            return Promise.reject(error);
          })
      );
    },

    getPrefilledSubscriptionAgreementTemplateForDownload(
      query: GetPrefilledSubscriptionAgreementTemplateForDownloadQuery
    ): Promise<FileResponse> {
      const { getPrefilledSubscriptionAgreementTemplateForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPrefilledSubscriptionAgreementTemplateForDownloadStatus,
        () => getPrefilledSubscriptionAgreementTemplateForDownload(query)
      );
    },

    getSubscriptionDocumentFileForDownload(
      query: GetSubscriptionDocumentFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getSubscriptionDocumentFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionDocumentFileForDownloadStatus,
        () => getSubscriptionDocumentFileForDownload(query)
      );
    },

    getSubscriptionAgreementTemplateFileForDownload(
      query: GetSubscriptionAgreementTemplateFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getSubscriptionAgreementTemplateFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionAgreementTemplateFileForDownloadStatus,
        () => getSubscriptionAgreementTemplateFileForDownload(query)
      );
    },

    getKYCDocumentAttachmentsForSubscriptionProcess(): Promise<void> {
      const query: GetKYCDocumentAttachmentsForSubscriptionProcessQuery = {
        subscriptionProcessId: this.currentSubscriptionProcessId!,
      };

      const { getKYCDocumentAttachmentsForSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getKYCDocumentAttachmentsForSubscriptionProcessStatus,
        () => getKYCDocumentAttachmentsForSubscriptionProcess(query)
          .then((kycDocumentAttachments) => {
            this.kycDocumentAttachments = kycDocumentAttachments;
          })
      );
    },

    getInvestorKYCDocuments(): Promise<void> {
      const { getInvestorKYCDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorKYCDocumentsStatus,
        () => getInvestorKYCDocuments()
          .then((kycDocuments) => {
            this.investorKYCDocuments = kycDocuments;
          })
      );
    },

    getKYCDocumentAttachmentFileForDownload(
      query: GetKYCDocumentAttachmentFileForDownloadQuery
    ): Promise<FileResponse> {
      const { getKYCDocumentAttachmentFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getKYCDocumentAttachmentFileForDownloadStatus,
        () => getKYCDocumentAttachmentFileForDownload(query)
      );
    },

    getSubscriptionAgreementTemplatesToPrefillForProduct(
      query: GetSubscriptionAgreementTemplatesToPrefillForProductQuery
    ): Promise<void> {
      const { getSubscriptionAgreementTemplatesToPrefillForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSubscriptionAgreementTemplatesToPrefillForProductStatus,
        () => getSubscriptionAgreementTemplatesToPrefillForProduct(query)
          .then((subscriptionAgreementTemplatesToPrefill) => {
            this.subscriptionAgreementTemplatesToPrefill = subscriptionAgreementTemplatesToPrefill;
          })
      );
    },

    getControllingPersonTemplatesToPrefillForProduct(
      query: GetControllingPersonTemplatesToPrefillForProductQuery
    ): Promise<void> {
      const { getControllingPersonTemplatesToPrefillForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getControllingPersonTemplatesToPrefillForProductStatus,
        () => getControllingPersonTemplatesToPrefillForProduct(query)
          .then((controllingPersonTemplatesToPrefill) => {
            this.controllingPersonTemplatesToPrefill = controllingPersonTemplatesToPrefill;
          })
      );
    },

    // -- Commands

    startSubscriptionProcess(): Promise<void> {
      const command: StartSubscriptionProcessCommand = {
        productId: this.currentProductId!,
      };

      const { startSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        startSubscriptionProcessStatus,
        () => startSubscriptionProcess(command)
      );
    },

    specifySubscriptionInvestmentAmount(command: SpecifySubscriptionInvestmentAmountCommand): Promise<void> {
      const { specifySubscriptionInvestmentAmountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        specifySubscriptionInvestmentAmountStatus,
        () => specifySubscriptionInvestmentAmount(command)
          .then(() => this.getSubscriptionProcessForCurrentProduct())
      );
    },

    selectInvestmentProfileForSubscriptionProcess(command: SelectInvestmentProfileForSubscriptionProcessCommand): Promise<void> {
      const { selectInvestmentProfileForSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        selectInvestmentProfileForSubscriptionProcessStatus,
        () => selectInvestmentProfileForSubscriptionProcess(command)
          .then(() => this.getSubscriptionProcessForCurrentProduct())
      );
    },

    confirmSelectedInvestmentProfileForSubscriptionProcess(
      command: ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand
    ): Promise<void> {
      const { confirmSelectedInvestmentProfileForSubscriptionProcessStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmSelectedInvestmentProfileForSubscriptionProcessStatus,
        () => confirmSelectedInvestmentProfileForSubscriptionProcess(command)
          .then(() => this.getSubscriptionProcessForCurrentProduct())
      );
    },

    agreeToSubscriptionDataProcessing(): Promise<void> {
      const command: AgreeToSubscriptionDataProcessingCommand = {
        subscriptionProcessId: this.currentSubscriptionProcessId!,
      };

      const { agreeToSubscriptionDataProcessingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        agreeToSubscriptionDataProcessingStatus,
        () => agreeToSubscriptionDataProcessing(command)
          .then(() => this.getSubscriptionProcessForCurrentProduct())
      );
    },

    confirmSubscription(command: ConfirmSubscriptionCommand): Promise<void> {
      const { confirmSubscriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmSubscriptionStatus,
        () => confirmSubscription(command)
          .then(() => this.getSubscriptionProcessForCurrentProduct())
      );
    },

    continueSubscriptionProcessAsNaturalPerson(): Promise<void> {
      const command: ContinueSubscriptionProcessAsNaturalPersonCommand = {
        subscriptionProcessId: this.currentSubscriptionProcessId!,
      };

      const { continueSubscriptionProcessAsNaturalPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        continueSubscriptionProcessAsNaturalPersonStatus,
        () => continueSubscriptionProcessAsNaturalPerson(command)
          .then(() => this.getSubscriptionProcessForCurrentProduct())
      );
    },

    copyInvestorKYCDocumentsToKYCDocumentAttachments(command: CopyInvestorKYCDocumentsToKYCDocumentAttachmentsCommand): Promise<void> {

      const { copyInvestorKYCDocumentsToKYCDocumentAttachmentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        copyInvestorKYCDocumentsToKYCDocumentAttachmentsStatus,
        () => copyInvestorKYCDocumentsToKYCDocumentAttachments(command)
          .then(() => this.getKYCDocumentAttachmentsForSubscriptionProcess())
      );
    },

    deleteKYCDocumentAttachment(command: DeleteKYCDocumentAttachmentCommand): Promise<void> {

      const { deleteKYCDocumentAttachmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteKYCDocumentAttachmentStatus,
        () => deleteKYCDocumentAttachment(command)
          .then(() => this.getKYCDocumentAttachmentsForSubscriptionProcess())
      );
    },

    deleteSubscriptionDocument(command: DeleteSubscriptionDocumentCommand): Promise<void> {

      const { deleteSubscriptionDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSubscriptionDocumentStatus,
        () => deleteSubscriptionDocument(command)
          .then(() => this.getSubscriptionDocumentsForSubscriptionProcess())
      );
    },

    createSubscriptionDocuments(files: File[]): Promise<void> {
      this.createSubscriptionDocumentsStatus = ActionStatus.InProgress;

      const queuedFileUploads = files.map((file) => {
        const command: CreateSubscriptionDocumentCommand = {
          body: {
            subscriptionProcessId: this.currentSubscriptionProcessId!,
          },
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createSubscriptionDocument,
          command,
        };

        return useFileUploadQueueStore().addItem(payload)
          .then(() => this.getSubscriptionDocumentsForSubscriptionProcess());
      });

      return Promise.all(queuedFileUploads)
        .then(() => {
          this.createSubscriptionDocumentsStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createSubscriptionDocumentsStatus = ActionStatus.Failed;
          return Promise.reject(error);
        });
    },

    getPrefilledControllingPersonTemplateForDownload(
      query: GetPrefilledControllingPersonTemplateForDownloadQuery
    ): Promise<FileResponse> {
      const { getPrefilledControllingPersonTemplateForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPrefilledControllingPersonTemplateForDownloadStatus,
        () => getPrefilledControllingPersonTemplateForDownload(query)
      );
    },

  },
});
function calculateNextSubscriptionProcessStep(subscriptionProcess: SubscriptionProcess | null): SubscriptionProcessStep {
  if (!subscriptionProcess) {
    return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
  }

  if (subscriptionProcess.status === SubscriptionProcessStatuses.IN_REVIEW) {
    return SubscriptionProcessStep.REVIEW;
  }

  if (subscriptionProcess.isInvestmentProfileReviewed || subscriptionProcess.isNaturalPersonSelected) {
    return SubscriptionProcessStep.REVIEW;
  }

  if (subscriptionProcess.selectedInvestmentProfileId) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_REVIEW;
  }

  if (subscriptionProcess.investmentAmount) {
    return SubscriptionProcessStep.INVESTMENT_PROFILE_SELECTION;
  }

  return SubscriptionProcessStep.AMOUNT_SPECIFICATION;
}
