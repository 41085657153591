import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { GetInAppNotificationsQuery, InAppNotification, MarkInAppNotificationAsSeenCommand, UnseenPlatformNews } from '@/application/whitelabel/in-app-notifications/types';
import { wrapActionWithProgress } from '@/store';
import { getInAppNotificationById, getInAppNotifications, getUnseenPlatformNews, markAllInAppNotificationsAsSeen, markInAppNotificationAsSeen } from '@/application/whitelabel/in-app-notifications/service';
import { Decimal } from '@/models/decimal';
import { DECIMALS } from '@/helpers/global-configs';

interface InAppNotificationsState {
  stepSize: number;
  defaultLimit: number;
  notifications: InAppNotification[];
  currentNotificationId: string | null;
  currentNotification: InAppNotification | null;
  unseenPlatformNews: UnseenPlatformNews[];
  unseenCount: number
  totalCount: number;
  limit: number;

  getUnseenPlatformNewsStatus: ActionStatus;
  getInAppNotificationsStatus: ActionStatus;
  markAllInAppNotificationsAsSeenStatus: ActionStatus;
  markInAppNotificationAsSeenStatus: ActionStatus;
  getCurrentNotificationStatus: ActionStatus;
}

function initialState(): InAppNotificationsState {
  return {
    stepSize: 10,
    defaultLimit: 10,
    notifications: [],
    currentNotificationId: null,
    currentNotification: null,
    unseenPlatformNews: [],
    unseenCount: 0,
    totalCount: 0,
    limit: 10,

    getUnseenPlatformNewsStatus: ActionStatus.None,
    getInAppNotificationsStatus: ActionStatus.None,
    markAllInAppNotificationsAsSeenStatus: ActionStatus.None,
    markInAppNotificationAsSeenStatus: ActionStatus.None,
    getCurrentNotificationStatus: ActionStatus.None,
  };
}

export const useInAppNotificationsStore = defineStore('inAppNotificationsStore', {
  state: (): InAppNotificationsState => initialState(),
  getters: {
    firstFiveNotifications: (state: InAppNotificationsState): InAppNotification[] =>
      state.notifications.slice(0, 5),
    hasNotifications: (state: InAppNotificationsState): boolean =>
      state.notifications.length > 0,
    hasMoreNotifications: (state: InAppNotificationsState): boolean =>
      state.totalCount > state.limit,
    hasUnseenNotifications: (state: InAppNotificationsState): boolean =>
      state.unseenCount > 0,
  },
  actions: {

    // -- State management
    removeUnseenPlatformNews(platformNewsNotificationId: string): Promise<void> {
      this.unseenPlatformNews = this.unseenPlatformNews
        .filter((unseenPlatformNews: UnseenPlatformNews) => unseenPlatformNews.notificationId !== platformNewsNotificationId);

      return Promise.resolve();
    },

    loadMore(): Promise<void> {
      this.loadMoreSucceeded(this.limit + this.stepSize);

      return Promise.resolve();
    },

    loadMoreSucceeded(newLimit: number): void {
      this.limit = newLimit;
    },

    resetCurrentNotification(): Promise<void> {
      this.currentNotification = null;

      return Promise.resolve();
    },

    updateCurrentNotificationId(currentNotificationId: string): Promise<void> {
      this.currentNotificationId = currentNotificationId;

      return Promise.resolve();
    },

    // -- Queries

    getUnseenPlatformNews(): Promise<void> {
      const { getUnseenPlatformNewsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUnseenPlatformNewsStatus,
        () => getUnseenPlatformNews()
          .then((unseenPlatformNews) => {
            this.unseenPlatformNews = unseenPlatformNews;
          })
      );
    },

    getCurrentNotification(): Promise<void> {
      const { getCurrentNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentNotificationStatus,
        () => getInAppNotificationById({ inAppNotificationId: this.currentNotificationId! })
          .then((inAppNotification) => {
            this.currentNotification = inAppNotification;
          })
      );
    },

    getInAppNotifications(): Promise<void> {
      const query: GetInAppNotificationsQuery = {
        limit: new Decimal(this.limit.toString(), DECIMALS.IN_APP_NOTIFICATIONS.limit),
      };

      const { getInAppNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInAppNotificationsStatus,
        () => getInAppNotifications(query)
          .then((response) => {
            this.notifications = response.notifications;
            this.unseenCount = response.unseenCount.toNumber();
            this.totalCount = response.totalCount.toNumber();
          })
      );
    },

    // -- Commands

    markAllInAppNotificationsAsSeen(): Promise<void> {
      const { markAllInAppNotificationsAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markAllInAppNotificationsAsSeenStatus,
        () => markAllInAppNotificationsAsSeen()
          .then(() => this.getInAppNotifications())
      );
    },

    markInAppNotificationAsSeen(command: MarkInAppNotificationAsSeenCommand): Promise<void> {
      const { markInAppNotificationAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markInAppNotificationAsSeenStatus,
        () => markInAppNotificationAsSeen(command)
          .then(() => this.removeUnseenPlatformNews(command.notificationId))
          .then(() => this.getInAppNotifications())
      );
    },

  },
});
