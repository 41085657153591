import { RouteConfig } from 'vue-router';
import ConfirmEmailAddressChange from './components/confirm-email-address-change.vue';

export const commonProfileRoutes: RouteConfig[] = [
  {
    name: 'common/profile/confirm-email-address-change',
    path: '/profile/confirm-email-address-change/:language/:token',
    component: ConfirmEmailAddressChange,
    meta: {
      requiresAuth: false,
      alwaysAccessible: true,
    },
  },
];
