import { performApiRequest, generateApiUrl, performApiRequestForFile } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { Investor, CreateInvestorCommand, PublishedProduct, GetInvestorsQuery, DeleteInvestorCommand, LockInvestorCommand, UnlockInvestorCommand, ExportShortInvestorsVersionAsXLSXQuery, ExportFullInvestorsVersionAsXLSXQuery } from './types';

export function getInvestors(query: GetInvestorsQuery): Promise<Investor[]> {
  const url = generateApiUrl('/api/general-partner/get-investors-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
export function getPublishedProducts(): Promise<PublishedProduct[]> {
  const url = generateApiUrl('/api/general-partner/get-published-products-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function exportShortInvestorsVersionAsXLSX(query: ExportShortInvestorsVersionAsXLSXQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/export-short-investors-version-as-xlsx-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function exportFullInvestorsVersionAsXLSX(query: ExportFullInvestorsVersionAsXLSXQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/export-full-investors-version-as-xlsx-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function createInvestor(command: CreateInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteInvestor(command: DeleteInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function lockInvestor(command: LockInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/lock-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function unlockInvestor(command: UnlockInvestorCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/unlock-investor-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
