import Vue from 'vue';
import { useAppStore } from '@/application/common/app/store';
import { Language, Languages } from '@/types/global';
import { Decimal } from '@/models/decimal';

export function formatNumber(
  value: Decimal|number,
  locale: Language|null = null,
  withThousandsSeparators = true,
  maximumFractionDigits: number|null = null
): string {
  const valueAsNumber = Number(value);
  const formatLanguage = locale || useAppStore().selectedLanguage || Languages.en;

  return new Intl.NumberFormat(formatLanguage, {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits ?? (Decimal.isDecimal(value) ? (value as Decimal).decimals : 6),
    useGrouping: withThousandsSeparators,
  })
    .format(valueAsNumber);
}

Vue.filter('formatNumber', formatNumber);
