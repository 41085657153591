import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartner, attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { isMobileApplication } from '@/helpers/detection-helpers';
import { ApplicationTypes } from '@/types/global';
import { Authentication, LoginCommand, UpdateUserLanguageCommand, RequestPasswordResetCommand, ResetPasswordCommand, ConfirmTwoFactorAuthenticationCommand, DirectLoginResponse, TwoFactorAuthenticationLoginResponse } from './types';

export function login(command: LoginCommand): Promise<DirectLoginResponse|TwoFactorAuthenticationLoginResponse> {
  const url = generateApiUrl('/api/unauthorized/application/authentication/login');
  return performApiRequest<DirectLoginResponse|TwoFactorAuthenticationLoginResponse>({
    url,
    method: 'POST',
    data: command,
    headers: { 'X-APPLICATION-TYPE': isMobileApplication() ? ApplicationTypes.NATIVE : ApplicationTypes.WEB },
  });
}

export function confirmTwoFactorAuthentication(command: ConfirmTwoFactorAuthenticationCommand): Promise<Authentication> {
  const url = generateApiUrl('/api/unauthorized/application/authentication/confirm-two-factor-authentication');
  return performApiRequest<Authentication>({
    url,
    method: 'POST',
    data: command,
    headers: { 'X-APPLICATION-TYPE': isMobileApplication() ? ApplicationTypes.NATIVE : ApplicationTypes.WEB },
  });
}

export function requestPasswordReset(command: RequestPasswordResetCommand): Promise<void> {
  const url = generateApiUrl('/api/unauthorized/authentication/request-password-reset-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(command) });
}

export function resetPassword(command: ResetPasswordCommand): Promise<void> {
  const url = generateApiUrl('/api/unauthorized/authentication/reset-password-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartner(command) });
}

export function logout(): Promise<void> {
  const url = generateApiUrl('/api/unauthorized/application/authentication/logout');
  return performApiRequest({ url, method: 'POST' });
}

export function getAuthentication(): Promise<Authentication> {
  const url = generateApiUrl('/api/application/authentication/authentication');
  return performApiRequest<Authentication>({
    url,
    method: 'GET',
    headers: { 'X-APPLICATION-TYPE': isMobileApplication() ? ApplicationTypes.NATIVE : ApplicationTypes.WEB },
  });
}

export function updateUserLanguage(command: UpdateUserLanguageCommand): Promise<void> {
  const url = generateApiUrl('/api/profile/update-user-language-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
