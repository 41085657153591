import { RouteConfig } from 'vue-router';
import GeneralPartnerOverview from './general-partner-overview/components/general-partner-overview.vue';
import GeneralPartnerDetailsTabs from './general-partner-details/components/general-partner-details-tabs.vue';
import GeneralPartnerContactTab from './general-partner-details/components/general-partner-contact-tab/general-partner-contact-tab.vue';
import GeneralPartnerConfigurationTab from './general-partner-details/components/general-partner-configuration-tab/general-partner-configuration-tab.vue';
import GeneralPartnerLegalTab from './general-partner-details/components/general-partner-legal-tab/general-partner-legal-tab.vue';
import CreateGeneralPartner from './create-general-partner/components/create-general-partner.vue';

export const generalPartnersRoutes: RouteConfig[] = [
  {
    name: 'general-partner-overview',
    path: '/general-partners/overview',
    component: GeneralPartnerOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'create-general-partner',
    path: '/general-partners/create-general-partner',
    component: CreateGeneralPartner,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'general-partner-details',
    path: '/general-partner/details/:generalPartnerId',
    redirect: { name: 'general-partner-details-contact' },
  },
  {
    path: '/general-partner/details/:generalPartnerId',
    component: GeneralPartnerDetailsTabs,
    children: [
      {
        path: '',
        redirect: 'contact',
      },
      {
        name: 'general-partner-details-contact',
        path: 'contact',
        component: GeneralPartnerContactTab,
        meta: {
          requiresAuth: true,
          styleClass: 'has-sticky-tab-header',
        },
      },
      {
        name: 'general-partner-details-configuration',
        path: 'configuration',
        component: GeneralPartnerConfigurationTab,
        meta: {
          requiresAuth: true,
          styleClass: 'has-sticky-tab-header',
        },
      },
      {
        name: 'general-partner-details-legal',
        path: 'legal',
        component: GeneralPartnerLegalTab,
        meta: {
          requiresAuth: true,
          styleClass: 'has-sticky-tab-header',
        },
      },
    ],
  },
];
