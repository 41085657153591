import Values from 'values.js';
import { ColorScheme, ColorSchemeItem } from '@/types/color-scheme';

export function getAppColorSchemeByBaseColors(
  colorPrimary: string,
  colorSecondary: string,
  colorGreen: string,
  colorSuccess: string,
  colorRed: string,
  colorError: string,
  colorYellow: string,
  colorWarning: string,
  colorGrey: string,
  colorInfo: string,
  colorPurple: string,
  colorCyan: string
): ColorScheme {
  return {
    primary: getColorSchemeItemByBaseColor(colorPrimary),
    secondary: getColorSchemeItemByBaseColor(colorSecondary),
    green: getColorSchemeItemByBaseColor(colorGreen),
    success: getColorSchemeItemByBaseColor(colorSuccess),
    red: getColorSchemeItemByBaseColor(colorRed),
    error: getColorSchemeItemByBaseColor(colorError),
    yellow: getColorSchemeItemByBaseColor(colorYellow),
    warning: getColorSchemeItemByBaseColor(colorWarning),
    grey: getColorSchemeItemByBaseColor(colorGrey),
    info: getColorSchemeItemByBaseColor(colorInfo),
    purple: getColorSchemeItemByBaseColor(colorPurple),
    cyan: getColorSchemeItemByBaseColor(colorCyan),
  };
}

function getColorSchemeItemByBaseColor(baseColor: string): ColorSchemeItem {
  const colorTints = new Values(baseColor).tints(18)
    .map((color: any) => `#${color.hex}`)
    .reverse();
  const colorShades = new Values(baseColor).shades(22)
    .map((color: any) => `#${color.hex}`);

  return {
    lighten5: colorTints[0],
    lighten4: colorTints[1],
    lighten3: colorTints[2],
    lighten2: colorTints[3],
    lighten1: colorTints[4],
    base: baseColor,
    darken1: colorShades[0],
    darken2: colorShades[1],
    darken3: colorShades[2],
    darken4: colorShades[3],
  };
}
