import { defineStore } from 'pinia';

interface RefreshedSessionState {
  lastAuthenticatedUserId: string | null;
  lastAuthenticatedBrainiacId: string | null;
}

function initialState(): RefreshedSessionState {
  return {
    lastAuthenticatedUserId: null,
    lastAuthenticatedBrainiacId: null,
  };
}

export const useRefreshedSessionStore = defineStore('refreshedSessionStore', {
  state: (): RefreshedSessionState => initialState(),
  actions: {

    // -- State management

    updateLastAuthenticatedUserId(userId: string): Promise<void> {
      this.lastAuthenticatedUserId = userId;

      return Promise.resolve();
    },

    updateLastAuthenticatedBrainiacId(brainiacId: string): Promise<void> {
      this.lastAuthenticatedBrainiacId = brainiacId;

      return Promise.resolve();
    },

  },
});
