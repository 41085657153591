var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.dialogMaxWidth,"persistent":_vm.isCancelDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":""}},on),[_vm._v(" "+_vm._s(_vm.translate('openDialogButton'))+" ")])]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[(_vm.store.investorKYCDocuments)?_c('v-card',{attrs:{"data-cy":"select-kyc-document-attachment-dialog"}},[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.form)?_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],on:{"submit":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}},model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_vm._v(" "+_vm._s(_vm.translate('kycDocuments.title'))+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"fixed-layout",attrs:{"disable-filtering":"","disable-pagination":"","hide-default-footer":"","item-key":"id","headers":_vm.kycDocumentsTableHeaders,"items":_vm.attachableKYCDocuments,"show-select":"","mobile-breakpoint":960},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"black--text text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatBytes")(item.fileSize))+" ")]}},{key:"item.uploadedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.uploadedAt))+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"lighten-5 d-flex flex-column align-center pa-6"},[_c('font-awesome-icon',{staticClass:"file-icon mb-4 hidden-xs-only",attrs:{"icon":['fal', 'file-alt']}}),_c('h3',[_vm._v(_vm._s(_vm.translate('kycDocuments.noDataTitle')))]),_c('v-btn',{attrs:{"text":"","small":"","to":{ name: 'investor-kyc-management' }}},[_vm._v(" "+_vm._s(_vm.translate('kycDocuments.noDataText'))+" ")])],1)]},proxy:true}],null,false,765992322),model:{value:(_vm.form.state.selectedKYCDocuments),callback:function ($$v) {_vm.$set(_vm.form.state, "selectedKYCDocuments", $$v)},expression:"form.state.selectedKYCDocuments"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","depressed":"","disabled":_vm.isCancelDisabled},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.translate('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","depressed":"","color":"primary","disabled":_vm.isSubmitDisabled,"loading":_vm.isProcessing}},[_vm._v(" "+_vm._s(_vm.translate('buttons.submit'))+" ")])],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }