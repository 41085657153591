/* eslint-disable */
import Vue from 'vue';
import VueI18n, { LocaleMessages, Values } from 'vue-i18n';
import merge from 'lodash-es/merge';

Vue.use(VueI18n);

function loadLocaleInfo(): { id: string, messages: LocaleMessages } {
  const locales = require.context('..', true, /[A-Za-z0-9-_,\s]+\.i18n\.json$/i);

  const messages: any = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\.([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[2];
      messages[locale] = merge(messages[locale], locales(key));
    }
  });

  return { id: locales.id, messages };
}

const { id, messages } = loadLocaleInfo();

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: process.env.SILENCE_CLIENT_TRANSLATION_WARNINGS === 'true',
});

if (module.hot) {
  module.hot.accept(id, () => {
    const { messages } = loadLocaleInfo();
    Object.keys(messages).forEach((locale) => {
      i18n.setLocaleMessage(locale, messages[locale]);
    });
  });
}

export default i18n;

export function namespacedTranslate(namespace: string): (key: string, values?: Values) => string {
  return (key, values) => i18n.t(`${namespace}.${key}`, values) as string;
}
