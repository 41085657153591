import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { GetUserActivityLogEntriesForProductQuery, ProductUserActivityLogEntry } from './types';
import { getUserActivityLogEntriesForProduct } from './service';

interface GeneralPartnerProductManagementActivityTabState {
  productUserActivityLogEntries: ProductUserActivityLogEntry[];
  productActivityLogEntriesTotalCount: number;

  getUserActivityLogEntriesForProductStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementActivityTabState {
  return {
    productUserActivityLogEntries: [],
    productActivityLogEntriesTotalCount: 0,

    getUserActivityLogEntriesForProductStatus: ActionStatus.None,
  };
}

export const useGeneralPartnerProductManagementActivityTabStore = defineStore('generalPartnerProductManagementActivityTab', {
  state: (): GeneralPartnerProductManagementActivityTabState => initialState(),
  getters: {
    isGetUserActivityLogEntriesForProductProcessing: (state: GeneralPartnerProductManagementActivityTabState): boolean =>
      state.getUserActivityLogEntriesForProductStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUserActivityLogEntriesForProduct(query: GetUserActivityLogEntriesForProductQuery): Promise<void> {
      const { getUserActivityLogEntriesForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserActivityLogEntriesForProductStatus,
        () => getUserActivityLogEntriesForProduct(query)
          .then((productActivityLogEntries) => {
            this.productUserActivityLogEntries = productActivityLogEntries.entries;
            this.productActivityLogEntriesTotalCount = productActivityLogEntries.totalCount;
          })
      );
    },

  },
});
