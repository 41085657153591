import { TranslatedText } from '@/types/global';
import { Command } from '@/application/types';

export interface SharedProduct {
  productId: string;
  productName: TranslatedText;
  generalPartnerTitle: string;
  productSharingStatus: string;
}

export type SharedProductsState =
  | 'PENDING'
  | 'CONFIRMED';
export const SharedProductsStates: Record<SharedProductsState, Lowercase<SharedProductsState>> = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
};

export interface ConfirmSharedProductCommand extends Command {
  productId: string,
}
