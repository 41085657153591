import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { AddItemToUploadQueuePayload } from '@/application/whitelabel/file-upload-queue/types';
import { useFileUploadQueueStore } from '@/application/whitelabel/file-upload-queue/store';
import { wrapActionWithProgress } from '@/store';
import { CreateKYCDocumentCommand, DeleteKYCDocumentCommand, GetKYCDocumentFileForDownloadQuery, KYCDocument } from './types';
import { createKYCDocument, deleteKYCDocument, getKYCDocumentFileForDownload, getKYCDocuments } from './service';

interface InvestorKYCDocumentsState {
  kycDocuments: KYCDocument[];

  getKYCDocumentsStatus: ActionStatus;
  createKYCDocumentsStatus: ActionStatus;
  deleteKYCDocumentStatus: ActionStatus;
  getKYCDocumentFileForDownloadStatus: ActionStatus;
}

function initialState(): InvestorKYCDocumentsState {
  return {
    kycDocuments: [],

    getKYCDocumentsStatus: ActionStatus.None,
    createKYCDocumentsStatus: ActionStatus.None,
    deleteKYCDocumentStatus: ActionStatus.None,
    getKYCDocumentFileForDownloadStatus: ActionStatus.None,
  };
}

export const useInvestorKYCDocumentsStore = defineStore('investorKYCDocuments', {
  state: (): InvestorKYCDocumentsState => initialState(),
  getters: {
    isGetKYCDocumentsProcessing: (state: InvestorKYCDocumentsState): boolean =>
      state.getKYCDocumentsStatus === ActionStatus.InProgress,
    isCreateKYCDocumentsProcessing: (state: InvestorKYCDocumentsState): boolean =>
      state.createKYCDocumentsStatus === ActionStatus.InProgress,
    isDeleteKYCDocumentProcessing: (state: InvestorKYCDocumentsState): boolean =>
      state.deleteKYCDocumentStatus === ActionStatus.InProgress,
    isGetKYCDocumentFileForDownloadProcessing: (state: InvestorKYCDocumentsState): boolean =>
      state.getKYCDocumentFileForDownloadStatus === ActionStatus.InProgress,
    hasKYCDocuments: (state: InvestorKYCDocumentsState): boolean =>
      state.kycDocuments.length > 0,
  },
  actions: {
    // -- Queries

    getKYCDocuments(): Promise<void> {
      const { getKYCDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getKYCDocumentsStatus,
        () => getKYCDocuments()
          .then((kycDocuments) => {
            this.kycDocuments = kycDocuments;
          })
      );
    },

    getKYCDocumentFileForDownload(query: GetKYCDocumentFileForDownloadQuery): Promise<FileResponse> {
      const { getKYCDocumentFileForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getKYCDocumentFileForDownloadStatus,
        () => getKYCDocumentFileForDownload(query)
      );
    },

    // -- Commands

    createKYCDocuments(files: File[]): Promise<void> {
      this.createKYCDocumentsStatus = ActionStatus.InProgress;

      const queuedFileUploads = files.map((file) => {
        const command: CreateKYCDocumentCommand = {
          body: {},
          files: { file },
        };

        const payload: AddItemToUploadQueuePayload = {
          fileName: file.name,
          serviceFunction: createKYCDocument,
          command,
        };

        return useFileUploadQueueStore().addItem(payload)
          .then(() => this.getKYCDocuments());
      });

      return Promise.all(queuedFileUploads)
        .then(() => {
          this.createKYCDocumentsStatus = ActionStatus.Successful;
        })
        .catch((error) => {
          this.createKYCDocumentsStatus = ActionStatus.Failed;
          return Promise.reject(error);
        });
    },

    deleteKYCDocument(command: DeleteKYCDocumentCommand): Promise<void> {
      const { deleteKYCDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteKYCDocumentStatus,
        () => deleteKYCDocument(command)
          .then(() => this.getKYCDocuments())
      );
    },

  },
});
