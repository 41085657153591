import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { InvestmentOverviewByProduct, Investor, SubscriptionProcess, Product, GetPublishedProductsQuery } from './types';

export function getInvestorForDashboard(): Promise<Investor> {
  const url = generateApiUrl('/api/investor/get-investor-for-dashboard-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getSubscriptionProcessesForDashboard(): Promise<SubscriptionProcess[]> {
  const url = generateApiUrl('/api/investor/get-subscription-processes-for-dashboard-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getInvestmentOverviewsByProduct(): Promise<InvestmentOverviewByProduct[]> {
  const url = generateApiUrl('/api/investor/get-investment-overviews-by-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getPublishedProducts(query: GetPublishedProductsQuery): Promise<Product[]> {
  const url = generateApiUrl('/api/investor/get-published-products-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
