import { performApiRequest, performApiRequestForFile, performApiRequestWithFiles, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CreateOnboardedProductSubscriptionDocumentCommand, GetOnboardedProductSubscriptionDocumentsForSubscriptionProcessQuery, GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery, OnboardedProductSubscriptionDocument, GetOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessQuery, OnboardedProductKYCDocumentAttachment, KYCDocument, CopyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsCommand, GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery, DeleteOnboardedProductSubscriptionDocumentCommand, SpecifyOnboardedProductSubscriptionInvestmentAmountCommand, ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand, SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand, ConfirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessCommand, GetOnboardedProductSubscriptionProcessForCurrentProductQuery, StartOnboardedProductSubscriptionProcessCommand, DeleteOnboardedProductKYCDocumentAttachmentCommand, GetOnboardedProductSubscriptionDocumentFileForDownloadQuery, ConfirmOnboardedProductSubscriptionCommand, OnboardedProductSubscriptionProcess, OnboardedProductForCurrentSubscriptionProcess, GetOnboardedProductForCurrentSubscriptionProcessQuery, GetOnboardedProductSubscriptionAgreementTemplatesToPrefillQuery, GetPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadQuery, OnboardedProductSubscriptionAgreementTemplateToPrefill, GetOnboardedProductControllingPersonTemplatesToPrefillForProductQuery, OnboardedProductControllingPersonTemplateToPrefill, GetPrefilledOnboardedProductControllingPersonTemplateForDownloadQuery } from './types';

// -- Queries

export function getOnboardedProductSubscriptionProcessForCurrentProduct(
  query: GetOnboardedProductSubscriptionProcessForCurrentProductQuery
): Promise<OnboardedProductSubscriptionProcess> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionDocumentFileForDownload(
  query: GetOnboardedProductSubscriptionDocumentFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-subscription-document-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionDocumentTemplateFileForDownload(
  query: GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-subscription-document-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionDocumentsForSubscriptionProcess(
  query: GetOnboardedProductSubscriptionDocumentsForSubscriptionProcessQuery
): Promise<OnboardedProductSubscriptionDocument[]> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-subscription-documents-for-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductForCurrentSubscriptionProcess(
  query: GetOnboardedProductForCurrentSubscriptionProcessQuery
): Promise<OnboardedProductForCurrentSubscriptionProcess> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-for-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductKYCDocumentAttachmentsForSubscriptionProcess(
  query: GetOnboardedProductKYCDocumentAttachmentsForSubscriptionProcessQuery
): Promise<OnboardedProductKYCDocumentAttachment[]> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-kyc-document-attachments-for-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getInvestorKYCDocuments(): Promise<KYCDocument[]> {
  const url = generateApiUrl('/api/investor/get-kyc-documents-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getOnboardedProductKYCDocumentAttachmentFileForDownload(
  query: GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-kyc-document-attachment-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionAgreementTemplatesToPrefill(
  query: GetOnboardedProductSubscriptionAgreementTemplatesToPrefillQuery
): Promise<OnboardedProductSubscriptionAgreementTemplateToPrefill[]> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-subscription-agreement-templates-to-prefill-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductControllingPersonTemplatesToPrefillForProduct(
  query: GetOnboardedProductControllingPersonTemplatesToPrefillForProductQuery
): Promise<OnboardedProductControllingPersonTemplateToPrefill[]> {
  const url = generateApiUrl('/api/investor/get-onboarded-product-controlling-person-templates-to-prefill-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getPrefilledOnboardedProductSubscriptionAgreementTemplateForDownload(
  query: GetPrefilledOnboardedProductSubscriptionAgreementTemplateForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-prefilled-onboarded-product-subscription-agreement-template-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

// -- Commands

export function startOnboardedProductSubscriptionProcess(query: StartOnboardedProductSubscriptionProcessCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/start-onboarded-product-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function specifyOnboardedProductSubscriptionInvestmentAmount(
  command: SpecifyOnboardedProductSubscriptionInvestmentAmountCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/specify-onboarded-product-subscription-investment-amount-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function selectInvestmentProfileForOnboardedProductSubscriptionProcess(
  command: SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/select-investment-profile-for-onboarded-product-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function confirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcess(
  command: ConfirmSelectedInvestmentProfileForOnboardedProductSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/confirm-selected-investment-profile-for-onboarded-product-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createOnboardedProductSubscriptionDocument(
  command: CreateOnboardedProductSubscriptionDocumentCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/investor/create-onboarded-product-subscription-document-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function confirmOnboardedProductSubscription(command: ConfirmOnboardedProductSubscriptionCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/confirm-onboarded-product-subscription-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function copyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachments(
  command: CopyInvestorKYCDocumentsToOnboardedProductKYCDocumentAttachmentsCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/copy-kyc-documents-to-onboarded-product-kyc-document-attachments-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteOnboardedProductKYCDocumentAttachment(command: DeleteOnboardedProductKYCDocumentAttachmentCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/delete-onboarded-product-kyc-document-attachment-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteOnboardedProductSubscriptionDocument(command: DeleteOnboardedProductSubscriptionDocumentCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/delete-onboarded-product-subscription-document-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function continueOnboardedProductSubscriptionProcessAsNaturalPerson(
  command: ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand
): Promise<void> {
  const url = generateApiUrl('/api/investor/continue-onboarded-product-subscription-process-as-natural-person-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getPrefilledOnboardedProductControllingPersonTemplateForDownload(
  query: GetPrefilledOnboardedProductControllingPersonTemplateForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-prefilled-onboarded-product-controlling-person-template-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
