import { render, staticRenderFns } from "./general-partner-legal-tab.vue?vue&type=template&id=8728ae18&scoped=true&"
import script from "./general-partner-legal-tab.vue?vue&type=script&lang=ts&"
export * from "./general-partner-legal-tab.vue?vue&type=script&lang=ts&"
import style0 from "./general-partner-legal-tab.vue?vue&type=style&index=0&id=8728ae18&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8728ae18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDivider,VSelect})
