import dayjs from 'dayjs';
import { Currency, KPI, KPIType, KPITypes, Language, NumberRange, TermSheet, TranslatedText } from '@/types/global';
import { formatCurrency, formatCurrencyWithoutCentDigits, formatLargeCurrency } from '@/filters/currencies';
import { Decimal } from '@/models/decimal';
import i18n from '@/plugins/vue-i18n';
import { formatNumber } from '@/filters/number';
import { useAppStore } from '@/application/common/app/store';
import { translateWithFallback } from '@/filters/translation';

export function isKPITypeWithDecimalValue(kpiType: KPIType): boolean {
  return [
    KPITypes.TARGET_SIZE,
    KPITypes.MINIMUM_INVESTMENT_SIZE,
    KPITypes.NUMBER,
    KPITypes.NUMBER_CURRENCY,
    KPITypes.NUMBER_PERCENTAGE,
  ].includes(kpiType);
}

export function isKPITypeWithRangeValue(kpiType: KPIType): boolean {
  return kpiType === KPITypes.NUMBER_CURRENCY_RANGE;
}

export function isKPITypeWithTranslatedValue(kpiType: KPIType): boolean {
  return kpiType === KPITypes.TEXT;
}

export function isCustomKPIType(kpiType: KPIType): boolean {
  return kpiType === KPITypes.CUSTOM;
}

export function isAssetClassKPIType(kpiType: KPIType): boolean {
  return kpiType === KPITypes.ASSET_CLASS;
}

export function isYearMonthKPIType(kpiType: KPIType): boolean {
  return kpiType === KPITypes.YEAR_MONTH;
}

export function isKPIValueInLanguageAvailable(kpi: KPI, language: Language): boolean {
  switch (kpi.kpiType) {
    case KPITypes.TARGET_SIZE:
    case KPITypes.MINIMUM_INVESTMENT_SIZE:
    case KPITypes.NUMBER_CURRENCY:
    case KPITypes.NUMBER_CURRENCY_RANGE:
    case KPITypes.NUMBER:
    case KPITypes.NUMBER_PERCENTAGE:
    case KPITypes.GEOGRAPHY:
    case KPITypes.INVESTMENT_STRUCTURE:
    case KPITypes.INDUSTRY:
    case KPITypes.ASSET_CLASS:
    case KPITypes.SUB_ASSET_CLASS:
    case KPITypes.YEAR_MONTH:
    case KPITypes.BOOLEAN:
    case KPITypes.CURRENCY:
      return true;
    case KPITypes.TEXT:
    case KPITypes.CUSTOM:
      return !!(kpi.kpiValue as TranslatedText)[language];
    default:
      throw new Error('Invalid KPI type');
  }
}

export function getKPIById(kpiId: string, kpis: KPI[]): KPI {
  const kpi = kpis.find((kpi) => kpi.kpiId === kpiId);

  if (!kpi) {
    throw new Error(`KPI with ID "${kpiId}" was not found by ID`);
  }

  return kpi;
}

export function getKPIByType(kpiType: KPIType, kpis: KPI[]): KPI {
  const kpi = kpis.find((kpi) => kpi.kpiType === kpiType);

  if (!kpi) {
    throw new Error(`KPI with type "${kpiType}" was not found`);
  }

  return kpi;
}

export function getKPIsFromTermSheet(termSheet: TermSheet): KPI[] {
  return termSheet.kpiCategories.map((kpiCategory) => kpiCategory.kpis).flat();
}

export function getFormattedTargetSizeKPIValue(
  kpi: KPI,
  currency: Currency,
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale: Language
): string {
  const formattedKPIValue = formatLargeCurrencyValuesWithAbbreviation
    ? formatLargeCurrency(kpi.kpiValue as Decimal, currency, locale)
    : formatCurrencyWithoutCentDigits(kpi.kpiValue as Decimal, currency, false, locale);

  return addPrefixAndSuffixToKPIValue(kpi, formattedKPIValue, locale);
}

export function getFormattedMinimumInvestmentSizeKPIValue(
  kpi: KPI,
  currency: Currency,
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale: Language
): string {
  const formattedKPIValue = formatLargeCurrencyValuesWithAbbreviation
    ? formatLargeCurrency(kpi.kpiValue as Decimal, currency, locale)
    : formatCurrencyWithoutCentDigits(kpi.kpiValue as Decimal, currency, false, locale);

  return addPrefixAndSuffixToKPIValue(kpi, formattedKPIValue, locale);
}

export function getFormattedNumberCurrencyKPIValue(
  kpi: KPI,
  currency: Currency,
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale: Language
): string {
  const formattedKPIValue = formatLargeCurrencyValuesWithAbbreviation
    ? formatLargeCurrency(kpi.kpiValue as Decimal, currency, locale)
    : formatCurrency(kpi.kpiValue as Decimal, currency, false, locale);

  return addPrefixAndSuffixToKPIValue(kpi, formattedKPIValue, locale);
}

export function getFormattedNumberCurrencyRangeKPIValue(
  kpi: KPI,
  currency: Currency,
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale: Language
): string {
  const formattedFromValue = formatLargeCurrencyValuesWithAbbreviation
    ? formatLargeCurrency((kpi.kpiValue as NumberRange).from!, currency, locale)
    : formatCurrency((kpi.kpiValue as NumberRange).from!, currency, false, locale);
  const formattedToValue = formatLargeCurrencyValuesWithAbbreviation
    ? formatLargeCurrency((kpi.kpiValue as NumberRange).to!, currency, locale)
    : formatCurrency((kpi.kpiValue as NumberRange).to!, currency, false, locale);

  return addPrefixAndSuffixToKPIValue(kpi, `${formattedFromValue} - ${formattedToValue}`, locale);
}

export function getFormattedNumberKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    formatNumber(kpi.kpiValue as Decimal, locale),
    locale
  );
}

export function getFormattedNumberPercentageKPIValue(
  kpi: KPI,
  locale: Language
): string {
  const formattedKPIValue = formatNumber(kpi.kpiValue as Decimal, locale);
  return `${addPrefixAndSuffixToKPIValue(kpi, formattedKPIValue, locale)} %`;
}

export function getFormattedGeographyKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    i18n.t(`helpers.data.geographies.${kpi.kpiValue as string}`, locale) as string,
    locale
  );
}

export function getFormattedInvestmentStructureKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    i18n.t(`helpers.data.investmentStructures.${kpi.kpiValue as string}`, locale) as string,
    locale
  );
}

export function getFormattedIndustryKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    i18n.t(`helpers.data.industries.${kpi.kpiValue as string}`, locale) as string,
    locale
  );
}

export function getFormattedAssetClassKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    i18n.t(`helpers.data.assetClasses.${kpi.kpiValue as string}`, locale) as string,
    locale
  );
}

export function getFormattedSubAssetClassKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    i18n.t(`helpers.data.subAssetClasses.${kpi.kpiValue as string}`, locale) as string,
    locale
  );
}

export function getFormattedBooleanKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    i18n.t(`helpers.data.boolean.${kpi.kpiValue ? 'TRUE' : 'FALSE'}`, locale) as string,
    locale
  );
}

export function getFormattedYearMonthKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    dayjs(kpi.kpiValue as string).format('MM/YYYY'),
    locale
  );
}

export function getFormattedCurrencyKPIValue(
  kpi: KPI,
  locale: Language
): string {
  return addPrefixAndSuffixToKPIValue(
    kpi,
    kpi.kpiValue!.toString(),
    locale
  );
}

export function getFormattedTextKPIValue(
  kpi: KPI,
  locale: Language
): string {
  const formattedKPIValue = ((kpi.kpiValue as TranslatedText)[locale] || (kpi.kpiValue as TranslatedText).en)?.toString();
  return addPrefixAndSuffixToKPIValue(
    kpi,
    formattedKPIValue,
    locale
  );
}

export function getFormattedCustomKPIValue(
  kpi: KPI,
  locale: Language
): string {
  const formattedKPIValue = ((kpi.kpiValue as TranslatedText)[locale] || (kpi.kpiValue as TranslatedText).en)?.toString();
  return addPrefixAndSuffixToKPIValue(
    kpi,
    formattedKPIValue,
    locale
  );
}

export function getFormattedKPIValue(
  kpi: KPI,
  kpis: KPI[],
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale?: Language
): string {
  return getFormattedKPIValueForCurrency(
    kpi,
    getKPIByType(KPITypes.CURRENCY, kpis).kpiValue as Currency,
    formatLargeCurrencyValuesWithAbbreviation,
    locale
  );
}

export function getFormattedKPIValueForCurrency(
  kpi: KPI,
  currency: Currency,
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale?: Language
): string {
  const localeWithFallback = locale || useAppStore().selectedLanguage!;

  switch (kpi.kpiType) {
    case KPITypes.TARGET_SIZE:
      return getFormattedTargetSizeKPIValue(
        kpi,
        currency,
        formatLargeCurrencyValuesWithAbbreviation,
        localeWithFallback
      );
    case KPITypes.MINIMUM_INVESTMENT_SIZE: {
      return getFormattedMinimumInvestmentSizeKPIValue(
        kpi,
        currency,
        formatLargeCurrencyValuesWithAbbreviation,
        localeWithFallback
      );
    }
    case KPITypes.NUMBER_CURRENCY: {
      return getFormattedNumberCurrencyKPIValue(
        kpi,
        currency,
        formatLargeCurrencyValuesWithAbbreviation,
        localeWithFallback
      );
    }
    case KPITypes.NUMBER_CURRENCY_RANGE: {
      return getFormattedNumberCurrencyRangeKPIValue(
        kpi,
        currency,
        formatLargeCurrencyValuesWithAbbreviation,
        localeWithFallback
      );
    }
    case KPITypes.NUMBER:
      return getFormattedNumberKPIValue(
        kpi,
        localeWithFallback
      );
    case KPITypes.NUMBER_PERCENTAGE:
      return getFormattedNumberPercentageKPIValue(
        kpi,
        localeWithFallback
      );
    case KPITypes.GEOGRAPHY:
      return getFormattedGeographyKPIValue(kpi, localeWithFallback);
    case KPITypes.INVESTMENT_STRUCTURE:
      return getFormattedInvestmentStructureKPIValue(kpi, localeWithFallback);
    case KPITypes.INDUSTRY:
      return getFormattedIndustryKPIValue(kpi, localeWithFallback);
    case KPITypes.ASSET_CLASS:
      return getFormattedAssetClassKPIValue(kpi, localeWithFallback);
    case KPITypes.SUB_ASSET_CLASS:
      return getFormattedSubAssetClassKPIValue(kpi, localeWithFallback);
    case KPITypes.YEAR_MONTH:
      return getFormattedYearMonthKPIValue(kpi, localeWithFallback);
    case KPITypes.BOOLEAN:
      return getFormattedBooleanKPIValue(kpi, localeWithFallback);
    case KPITypes.CURRENCY:
      return getFormattedCurrencyKPIValue(kpi, localeWithFallback);
    case KPITypes.TEXT:
      return getFormattedTextKPIValue(kpi, localeWithFallback);
    case KPITypes.CUSTOM:
      return getFormattedCustomKPIValue(kpi, localeWithFallback);
    default:
      throw new Error('Invalid KPI type');
  }
}

export function getFormattedKPIValueById(
  kpiId: string,
  kpis: KPI[],
  formatLargeCurrencyValuesWithAbbreviation: boolean,
  locale?: Language
): string {
  const kpi = getKPIById(kpiId, kpis);
  return getFormattedKPIValue(kpi, kpis, formatLargeCurrencyValuesWithAbbreviation, locale);
}

function addPrefixAndSuffixToKPIValue(
  kpi: KPI,
  formattedKPIValue: string,
  locale: Language
): string {
  let formattedKPIValueWithPrefixAndSuffix = formattedKPIValue;

  const prefix = translateWithFallback(kpi.prefix, locale);
  if (prefix.length > 0) {
    formattedKPIValueWithPrefixAndSuffix = `${prefix} ${formattedKPIValueWithPrefixAndSuffix}`;
  }

  const suffix = translateWithFallback(kpi.suffix, locale);
  if (suffix.length > 0) {
    formattedKPIValueWithPrefixAndSuffix = `${formattedKPIValueWithPrefixAndSuffix} ${suffix}`;
  }

  return formattedKPIValueWithPrefixAndSuffix;
}
