import { RouteConfig } from 'vue-router';
import { AccountTypes, GeneralPartnerPermissions } from '@/types/global';
import InvestorDetailsTabs from './investor-details/components/investor-details-tabs.vue';
import InvestorsOverview from './investors-overview/components/investors-overview.vue';
import InvestmentsTab from './investor-details/components/investments-tab.vue';
import TeamTab from './investor-details/components/team-tab.vue';
import ContactsTab from './investor-details/components/contacts-tab/contacts-tab.vue';
import ActivityTab from './investor-details/components/activity-tab.vue';
import InvestmentsOfInvestorForProduct from './investments-of-investor-for-product/components/investments-of-investor-for-product.vue';
import InvestmentsOfInvestorForOnboardedProduct from './investments-of-investor-for-onboarded-product/components/investments-of-investor-for-onboarded-product.vue';

export const generalPartnerInvestorsRoutes: RouteConfig[] = [
  {
    name: 'general-partner-investors-overview',
    path: '/general-partner/investors/overview',
    component: InvestorsOverview,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.GENERAL_PARTNER,
      requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
    },
  },
  {
    name: 'general-partner-investor-details',
    path: '/general-partner/investors/investor/:investorId',
    redirect: { name: 'general-partner-investor-details-investments' },
  },
  {
    path: '/general-partner/investors/investor/:investorId',
    component: InvestorDetailsTabs,
    children: [
      {
        path: '',
        redirect: 'investments',
      },
      {
        name: 'general-partner-investor-details-investments-subscription-process',
        path: 'investments/subscription-processes/:subscriptionProcessId/:watcherTrigger',
        component: InvestmentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTMENT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-investor-details-investments',
        path: 'investments',
        component: InvestmentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
      {
        name: 'general-partner-investor-details-team',
        path: 'team',
        component: TeamTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
      {
        name: 'general-partner-investor-details-contacts',
        path: 'contacts',
        component: ContactsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
      {
        name: 'general-partner-investor-details-activity',
        path: 'activity',
        component: ActivityTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  },
  {
    name: 'general-partner-investors-investments-of-investor-for-product',
    path: '/general-partner/investors/investor/:investorId/investments-for-product/:productId',
    component: InvestmentsOfInvestorForProduct,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.GENERAL_PARTNER,
      requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
    },
  },
  {
    name: 'general-partner-investors-investments-of-investor-for-onboarded-product',
    path: '/general-partner/investors/investor/:investorId/investments-for-onboarded-product/:productId',
    component: InvestmentsOfInvestorForOnboardedProduct,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.GENERAL_PARTNER,
      requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
    },
  },
];
