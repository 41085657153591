export enum UploadStatus {
  Waiting,
  Uploading,
  Failed,
}

export interface UploadQueueItem {
  id: string;
  fileName: string;
  status: UploadStatus;
  uploadFile: () => void;
  uploadProgressInPercent?: number;
}

export interface AddItemToUploadQueuePayload {
  fileName: string;
  serviceFunction: (command: any, onUploadProgress: (progressEvent: any) => void) => Promise<void>;
  command: any;
}

export interface AddChunkedItemToUploadQueuePayload {
  fileName: string;
  serviceFunction: (command: any, onUploadProgress: (progressEvent: any, chunkOrder: number) => void) => Promise<void>;
  commands: any[];
}
