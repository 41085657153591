import { generateApiUrl, performApiRequest } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { CreateInvestmentProfileCommand, GetInvestmentProfileQuery, InvestmentProfile, InvestmentProfileDetails, UpdateInvestmentProfileAuthorizedPersonsCommand, UpdateInvestmentProfileBankDetailsCommand, UpdateInvestmentProfileContactDetailsCommand, UpdateInvestmentProfileControllingPersonsCommand, UpdateInvestmentProfileGeneralInformationCommand, UpdateInvestmentProfileNameCommand, DeleteInvestmentProfileCommand } from './types';

// -- Queries

export function getInvestmentProfile(command: GetInvestmentProfileQuery): Promise<InvestmentProfileDetails> {
  const url = generateApiUrl('/api/investor/get-investment-profile-query');
  return performApiRequest<InvestmentProfileDetails>({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestmentProfiles(): Promise<InvestmentProfile[]> {
  const url = generateApiUrl('/api/investor/get-investment-profiles-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

// -- Commands

export function createInvestmentProfile(command: CreateInvestmentProfileCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/create-investment-profile-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentProfileName(command: UpdateInvestmentProfileNameCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/update-investment-profile-name-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentProfileGeneralInformation(command: UpdateInvestmentProfileGeneralInformationCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/update-investment-profile-general-information-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentProfileAuthorizedPersons(command: UpdateInvestmentProfileAuthorizedPersonsCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/update-investment-profile-authorized-persons-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentProfileControllingPersons(command: UpdateInvestmentProfileControllingPersonsCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/update-investment-profile-controlling-persons-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentProfileBankDetails(command: UpdateInvestmentProfileBankDetailsCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/update-investment-profile-bank-details-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function updateInvestmentProfileContactDetails(command: UpdateInvestmentProfileContactDetailsCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/update-investment-profile-contact-details-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteInvestmentProfile(command: DeleteInvestmentProfileCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/delete-investment-profile-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
