import { performApiRequest, performApiRequestForFile, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { Product, GetProductQuery, GetDueDiligenceFileForDownloadQuery, ContactProductContactPersonCommand, GetProductReportForDownloadQuery, GetAllProductReportsForProductForDownloadQuery, GetProductPrintPDFForDownloadQuery, GetProductNewsArticleAttachmentForDownloadQuery, AcceptNDACommand } from './types';

export function getProduct(query: GetProductQuery): Promise<Product> {
  const url = generateApiUrl('/api/investor/get-product-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getDueDiligenceFileForDownload(query: GetDueDiligenceFileForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-due-diligence-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function contactProductContactPerson(command: ContactProductContactPersonCommand): Promise<void> {
  const url = generateApiUrl('/api/investor/contact-product-contact-person-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getProductReportForDownload(query: GetProductReportForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-product-report-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getAllProductReportsForProductForDownload(
  query: GetAllProductReportsForProductForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-all-product-reports-for-product-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductPrintPDFForDownload(query: GetProductPrintPDFForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-product-print-pdf-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getProductNewsArticleAttachmentForDownload(query: GetProductNewsArticleAttachmentForDownloadQuery): Promise<FileResponse> {
  const url = generateApiUrl('/api/investor/get-product-news-article-attachment-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function acceptNDA(command: AcceptNDACommand): Promise<void> {
  const url = generateApiUrl('/api/investor/accept-nda-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}
