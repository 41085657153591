import Vue from 'vue';
import { useAppStore } from '@/application/common/app/store';
import { Currency, Language, Languages } from '@/types/global';
import { Decimal } from '@/models/decimal';

export function formatCurrency(
  value: Decimal|number = 0,
  currency: string,
  dashForZero = false,
  locale?: Language
): string {
  const valueAsNumber = Number(value);

  if (dashForZero && valueAsNumber === 0) {
    return '–';
  }

  const fractionDigits = Decimal.isDecimal(value) ? (value as Decimal).decimals : 2;

  return new Intl.NumberFormat(
    locale || useAppStore().selectedLanguage!,
    {
      style: 'currency',
      currency,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }
  )
    .format(valueAsNumber);
}

export function formatCurrencyWithoutCentDigits(
  value: Decimal|number = 0,
  currency: string,
  dashForZero = false,
  locale?: Language
): string {
  const valueAsNumber = Number(value);

  if (dashForZero && valueAsNumber === 0) {
    return '–';
  }

  const appStore = useAppStore();

  return new Intl.NumberFormat(
    locale || appStore.selectedLanguage!,
    { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }
  )
    .format(valueAsNumber);
}

export function formatLargeCurrency(
  value: Decimal|number = 0,
  currency: Currency,
  locale?: Language
): string {
  const appStore = useAppStore();
  const language = locale || appStore.selectedLanguage!;
  const valueAsNumber = Number(value);

  const numberFormat = new Intl.NumberFormat(
    language,
    { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }
  );

  // Anything less than a million
  if (valueAsNumber < 1_000_000) {
    return numberFormat.format(valueAsNumber);
  }

  let currencyString;
  let unit;

  // Anything less than a billion
  if (valueAsNumber < 1_000_000_000) {
    const valueInMillions = valueAsNumber / 1_000_000;
    currencyString = numberFormat.format(valueInMillions);
    unit = language === Languages.en ? 'M' : 'Mio';
  } else {
    // More than a billion
    const valueInBillions = valueAsNumber / 1_000_000_000;
    currencyString = numberFormat.format(valueInBillions);
    unit = language === Languages.en ? 'B' : 'Mrd';
  }

  const lastDigitUsedIndex = /(?!.*\d)/.exec(currencyString)!.index;

  return `${currencyString.substring(0, lastDigitUsedIndex)}${unit}${currencyString.substring(lastDigitUsedIndex)}`;
}

Vue.filter('formatCurrency', formatCurrency);
Vue.filter('formatCurrencyWithoutCentDigits', formatCurrencyWithoutCentDigits);
Vue.filter('formatLargeCurrency', formatLargeCurrency);
