import Vue from 'vue';
import { useAppStore } from '@/application/common/app/store';
import { Decimal } from '@/models/decimal';

export function formatBytes(value: number|Decimal): string {
  const valueAsNumber = Number(value);
  const asMegabyte = valueAsNumber / 1024 / 1024;
  const asFormattedString = asMegabyte.toLocaleString(useAppStore().selectedLanguage!, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${asFormattedString} MB`;
}

Vue.filter('formatBytes', formatBytes);
