import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse, VuetifySelectItem } from '@/application/types';
import { FileReference, KPI, KPITypes, Language, Languages, TermSheet, TranslatedText } from '@/types/global';
import { translateWithFallback } from '@/filters/translation';
import { getKPIsFromTermSheet } from '@/helpers/kpi-helpers';
import { DeleteProductCommand, DisableProductInvestabilityCommand, DiscardProductUpdateCommand, EnableProductInvestabilityCommand, GeneralPartnerUserWithAccess, GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery, GetInvestorsWithAccessForProductQuery, GetProductForProductManagementQuery, GetProductPrintPDFForDownloadQuery, InvestorWithAccess, KPIDefinition, Manager, PinnedDueDiligenceFile, Product, ProductVideo, PublishProductCommand, PublishProductUpdateCommand, UnpublishedProductUpdate, UnpublishProductCommand, UpdateGeneralPartnerUsersAccessForProductCommand, UpdateInvestorsWithAccessForProductCommand, UpdateProductNameCommand, UpdateProductPreviewCommand, ShareProductCommand, UnshareProductCommand, ProductUpdatedSectionsListTypes, ControllingPersonTemplateToPrefill, SubscriptionAgreementTemplateToPrefill } from './types';
import { deleteProduct, disableProductInvestability, discardProductUpdate, enableProductInvestability, getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct, getInvestorsWithAccessForProduct, getProductForProductManagement, getProductPrintPDFForDownload, publishProduct, publishProductUpdate, unpublishProduct, updateGeneralPartnerUsersAccessForProduct, updateInvestorsWithAccessForProduct, updateProductName, updateProductPreview, shareProduct, unshareProduct } from './service';

interface GeneralPartnerProductManagementGeneralState {
  currentProductId: string | null;
  product: Product | null;
  isPreviewVisible: boolean;
  selectedLanguage: Language;
  investorsWithAccess: InvestorWithAccess[];
  generalPartnerUsersWithAccess: GeneralPartnerUserWithAccess[];

  getCurrentProductStatus: ActionStatus;
  publishProductUpdateStatus: ActionStatus;
  discardProductUpdateStatus: ActionStatus;
  getProductPrintPDFForDownloadStatus: ActionStatus;
  getInvestorsWithAccessForProductStatus: ActionStatus;
  getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus: ActionStatus;
  updateInvestorsWithAccessForProductStatus: ActionStatus;
  updateGeneralPartnerUsersAccessForProductStatus: ActionStatus;
  updateProductNameStatus: ActionStatus;
  updateProductPreviewStatus: ActionStatus;
  publishProductStatus: ActionStatus;
  unpublishProductStatus: ActionStatus;
  enableProductInvestabilityStatus: ActionStatus;
  disableProductInvestabilityStatus: ActionStatus;
  shareProductStatus: ActionStatus;
  deleteProductStatus: ActionStatus;
  unshareProductStatus: ActionStatus;
}

function initialState(): GeneralPartnerProductManagementGeneralState {
  return {
    currentProductId: null,
    product: null,
    isPreviewVisible: true,
    selectedLanguage: Languages.en,
    investorsWithAccess: [],
    generalPartnerUsersWithAccess: [],

    getCurrentProductStatus: ActionStatus.None,
    publishProductUpdateStatus: ActionStatus.None,
    discardProductUpdateStatus: ActionStatus.None,
    getProductPrintPDFForDownloadStatus: ActionStatus.None,
    getInvestorsWithAccessForProductStatus: ActionStatus.None,
    getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus: ActionStatus.None,
    updateInvestorsWithAccessForProductStatus: ActionStatus.None,
    updateGeneralPartnerUsersAccessForProductStatus: ActionStatus.None,
    updateProductNameStatus: ActionStatus.None,
    updateProductPreviewStatus: ActionStatus.None,
    publishProductStatus: ActionStatus.None,
    unpublishProductStatus: ActionStatus.None,
    enableProductInvestabilityStatus: ActionStatus.None,
    disableProductInvestabilityStatus: ActionStatus.None,
    shareProductStatus: ActionStatus.None,
    deleteProductStatus: ActionStatus.None,
    unshareProductStatus: ActionStatus.None,
  };
}
function previewProductVersionWithFallback(product: Product, isPreviewVisible: boolean): UnpublishedProductUpdate | Product {
  return isPreviewVisible && hasUnpublishedProductUpdate(product)
    ? product.unpublishedProductUpdate
    : product;
}

function latestProductVersion(product: Product): UnpublishedProductUpdate | Product {
  return product.unpublishedProductUpdate ?? product;
}

function isChangingProductDisabled(product: Product, isPreviewVisible: boolean): boolean {
  return hasUnpublishedProductUpdate(product)
    && !isPreviewVisible;
}

function hasUnpublishedProductUpdate(product: Product): boolean {
  return !!product.unpublishedProductUpdate;
}

function productPinnedDueDiligenceFilesWithFallback(
  product: Product,
  isPreviewVisible: boolean,
  selectedLanguage: Language
): PinnedDueDiligenceFile[] {
  const productVersion = previewProductVersionWithFallback(product, isPreviewVisible);

  return productVersion.pinnedDueDiligenceFiles[selectedLanguage]
    ?? productVersion.pinnedDueDiligenceFiles.en
    ?? [];
}

function productPinnedDueDiligenceFiles(product: Product, isPreviewVisible: boolean, selectedLanguage: Language): PinnedDueDiligenceFile[] {
  const productVersion = previewProductVersionWithFallback(product, isPreviewVisible);

  return productVersion.pinnedDueDiligenceFiles[selectedLanguage] ?? [];
}

function productVideoWithFallback(product: Product, isPreviewVisible: boolean, selectedLanguage: Language): ProductVideo | null {
  const productVersion = previewProductVersionWithFallback(product, isPreviewVisible);

  return productVersion.productVideo
    ? productVersion.productVideo[selectedLanguage]
      ?? productVersion.productVideo.en
    : null;
}

function productKPIs(product: Product): KPI[] {
  return getKPIsFromTermSheet(latestProductVersion(product).termSheet);
}

function unselectedSummaryItems(product: Product, isPreviewVisible: boolean): KPI[] {
  const idsOfKPIsForSummary = previewProductVersionWithFallback(product, isPreviewVisible).idsOfKPIsForSummary;

  return productKPIs(product)
    .filter((kpi) => !idsOfKPIsForSummary.includes(kpi.kpiId));
}

function productSummaryItems(product: Product, isPreviewVisible: boolean): KPI[] {
  const idsOfKPIsForSummary = previewProductVersionWithFallback(product, isPreviewVisible).idsOfKPIsForSummary;

  return productKPIs(product)
    .filter((kpi) => idsOfKPIsForSummary.includes(kpi.kpiId));
}

function productName(product: Product, isPreviewVisible: boolean): TranslatedText {
  return previewProductVersionWithFallback(product, isPreviewVisible).productName;
}

function productManagers(product: Product, isPreviewVisible: boolean): Manager[] {
  return previewProductVersionWithFallback(product, isPreviewVisible).managers;
}

function productContactEmailAddress(product: Product, isPreviewVisible: boolean): string | null {
  return previewProductVersionWithFallback(product, isPreviewVisible).contactEmailAddress ?? null;
}

function productPresentationUrlWithFallback(product: Product, isPreviewVisible: boolean, selectedLanguage: Language): string | null {
  const productVersion = previewProductVersionWithFallback(product, isPreviewVisible);

  return productVersion.productPresentationUrl
    ? productVersion.productPresentationUrl[selectedLanguage]
      ?? productVersion.productPresentationUrl.en
    : null;
}

export const useGeneralPartnerProductManagementGeneralStore = defineStore('generalPartnerProductManagementGeneral', {
  state: (): GeneralPartnerProductManagementGeneralState => initialState(),
  getters: {
    isGetCurrentProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.getCurrentProductStatus === ActionStatus.InProgress,
    isPublishProductUpdateProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.publishProductUpdateStatus === ActionStatus.InProgress,
    isDiscardProductUpdateProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.discardProductUpdateStatus === ActionStatus.InProgress,
    isGetProductPrintPDFForDownloadProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.getProductPrintPDFForDownloadStatus === ActionStatus.InProgress,
    isGetInvestorsWithAccessForProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.getInvestorsWithAccessForProductStatus === ActionStatus.InProgress,
    isGetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductProcessing: (
      state: GeneralPartnerProductManagementGeneralState
    ): boolean =>
      state.getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus === ActionStatus.InProgress,
    isUpdateInvestorsWithAccessForProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.updateInvestorsWithAccessForProductStatus === ActionStatus.InProgress,
    isUpdateGeneralPartnerUsersAccessForProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.updateGeneralPartnerUsersAccessForProductStatus === ActionStatus.InProgress,
    isUpdateProductNameProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.updateProductNameStatus === ActionStatus.InProgress,
    isUpdateProductPreviewProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.updateProductPreviewStatus === ActionStatus.InProgress,
    isPublishProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.publishProductStatus === ActionStatus.InProgress,
    isUnpublishProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.unpublishProductStatus === ActionStatus.InProgress,
    isEnableProductInvestabilityProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.enableProductInvestabilityStatus === ActionStatus.InProgress,
    isDisableProductInvestabilityProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.disableProductInvestabilityStatus === ActionStatus.InProgress,
    isShareProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.shareProductStatus === ActionStatus.InProgress,
    isDeleteProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.deleteProductStatus === ActionStatus.InProgress,
    isUnshareProductProcessing: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.unshareProductStatus === ActionStatus.InProgress,

    hasSubscriptionDocumentTemplates: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      (state.product?.subscriptionDocumentTemplates.length ?? 0) > 0,
    isDocumentManagementForSubscriptionProcessEnabled: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.product?.isDocumentManagementForSubscriptionProcessEnabled ?? false,
    investorSelectItems: (state: GeneralPartnerProductManagementGeneralState): VuetifySelectItem<string>[] =>
      state.investorsWithAccess.map((investorWithAccess) => ({
        value: investorWithAccess.investorId,
        text: investorWithAccess.name,
      })),
    isActivatorButtonDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return true;
      }

      return isChangingProductDisabled(state.product, state.isPreviewVisible)
        || (state.selectedLanguage !== Languages.en
          && productPinnedDueDiligenceFilesWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage).length === 0
        );
    },
    arePinnedDueDiligenceFileActionsDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return true;
      }

      return isChangingProductDisabled(state.product, state.isPreviewVisible)
        || productPinnedDueDiligenceFiles(state.product, state.isPreviewVisible, state.selectedLanguage).length === 0;
    },
    isLanguageSelectDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return true;
      }

      return !productVideoWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage);
    },
    areUnselectedSummaryItemsAvailable: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return unselectedSummaryItems(state.product, state.isPreviewVisible).length === 0;
    },
    hasProductSummaryItems: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return productSummaryItems(state.product, state.isPreviewVisible).length > 0;
    },
    isMaximumNumberOfManagersAdded: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return latestProductVersion(state.product).managers.length >= 4;
    },
    isDeleteTrackRecordImageDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return true;
      }

      const productVersion = previewProductVersionWithFallback(state.product, state.isPreviewVisible);
      return productVersion.trackRecordImageUrl
        ? !productVersion.trackRecordImageUrl[state.selectedLanguage]
        : true;
    },
    isDeleteProductPresentationDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return true;
      }

      const productVersion = previewProductVersionWithFallback(state.product, state.isPreviewVisible);
      return productVersion.productPresentationUrl
        ? !productVersion.productPresentationUrl[state.selectedLanguage]
        : true;
    },
    isDeleteProductVideoDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return true;
      }

      const productVersion = previewProductVersionWithFallback(state.product, state.isPreviewVisible);
      return productVersion.productVideo
        ? !productVersion.productVideo[state.selectedLanguage]
        : true;
    },
    hasProductUnpublishedChanges: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      !!state.product
        && !!state.product!.unpublishedProductUpdate,
    isProductAvailable: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      !!state.product
        && state.product.productId === state.currentProductId,
    customKPIsOfCategory: (state: GeneralPartnerProductManagementGeneralState): (kpiCategoryId: string) => KPI[] =>
      (kpiCategoryId) => {
        const kpiCategory = latestProductVersion(state.product!).termSheet.kpiCategories
          .find((kpiCategory) => kpiCategory.kpiCategoryId === kpiCategoryId);
        if (!kpiCategory) {
          return [];
        }

        return kpiCategory.kpis.filter((kpi) => kpi.kpiType === KPITypes.CUSTOM);
      },
    kpiById: (state: GeneralPartnerProductManagementGeneralState): (kpiId: string) => KPI =>
      (kpiId) => {
        const kpi = getKPIsFromTermSheet(latestProductVersion(state.product!).termSheet)
          .find((kpi) => kpi.kpiId === kpiId);

        if (!kpi) {
          throw new Error('KPI not found');
        }

        return kpi;
      },
    latestProduct: (state: GeneralPartnerProductManagementGeneralState): UnpublishedProductUpdate | Product | null =>
      state.product
        ? latestProductVersion(state.product)
        : null,
    latestProductKPIs: (state: GeneralPartnerProductManagementGeneralState): KPI[] => {
      if (!state.product) {
        return [];
      }

      return getKPIsFromTermSheet(latestProductVersion(state.product).termSheet);
    },
    kpiDefinitionsForCategory: (state: GeneralPartnerProductManagementGeneralState): (kpiCategoryId: string) => KPIDefinition[] =>
      (kpiCategoryId) => state.product!.termSheetTemplate.kpiDefinitions
        .filter((kpiDefinition) => kpiDefinition.kpiCategoryId === kpiCategoryId)
        .sort((a, b) => a.position - b.position),
    kpiDefinitionById: (state: GeneralPartnerProductManagementGeneralState): (kpiDefinitionId: string) => KPIDefinition =>
      (kpiDefinitionId) => {
        const kpiDefinition = state.product && state.product.termSheetTemplate.kpiDefinitions
          .find((kpiDefinition) => kpiDefinition.kpiDefinitionId === kpiDefinitionId);

        if (!kpiDefinition) {
          throw new Error('KPI definition wasn\'t found');
        }

        return kpiDefinition;
      },
    hasUnpublishedProductUpdate: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      !!state.product
        && !!state.product!.unpublishedProductUpdate,
    isChangingProductDisabled: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      !!state.product
        && hasUnpublishedProductUpdate(state.product)
        && !state.isPreviewVisible,
    productTermSheet: (state: GeneralPartnerProductManagementGeneralState): TermSheet | null => {
      if (!state.product) {
        return null;
      }

      return previewProductVersionWithFallback(state.product, state.isPreviewVisible).termSheet;
    },
    productName: (state: GeneralPartnerProductManagementGeneralState): TranslatedText | null =>
      state.product
        ? productName(state.product, state.isPreviewVisible)
        : null,
    isProductNameTranslatedInSelectedLanguage: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return state.selectedLanguage === Languages.en
        || !!productName(state.product, state.isPreviewVisible)[state.selectedLanguage];
    },
    productDescription: (state: GeneralPartnerProductManagementGeneralState): string | null => {
      if (!state.product) {
        return null;
      }

      const description = previewProductVersionWithFallback(state.product, state.isPreviewVisible).description;

      return description
        ? translateWithFallback(description, state.selectedLanguage)
        : null;
    },
    isProductDescriptionTranslatedInSelectedLanguage: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (state.selectedLanguage === Languages.en) {
        return true;
      }
      if (!state.product) {
        return false;
      }

      const description = previewProductVersionWithFallback(state.product, state.isPreviewVisible).description;

      return description
        ? !!description[state.selectedLanguage]
        : false;
    },
    isProductTrackRecordImageTranslatedInSelectedLanguage: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (state.selectedLanguage === Languages.en) {
        return true;
      }
      if (!state.product) {
        return false;
      }

      const trackRecordImageUrl = previewProductVersionWithFallback(state.product, state.isPreviewVisible).trackRecordImageUrl;

      return trackRecordImageUrl
        ? !!trackRecordImageUrl[state.selectedLanguage]
        : false;
    },
    isProductPresentationTranslatedInSelectedLanguage: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (state.selectedLanguage === Languages.en) {
        return true;
      }
      if (!state.product) {
        return false;
      }

      const presentationUrl = previewProductVersionWithFallback(state.product, state.isPreviewVisible).productPresentationUrl;

      return presentationUrl
        ? !!presentationUrl[state.selectedLanguage]
        : false;
    },
    isProductVideoTranslatedInSelectedLanguage: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (state.selectedLanguage === Languages.en) {
        return true;
      }
      if (!state.product) {
        return false;
      }

      const productVideo = previewProductVersionWithFallback(state.product, state.isPreviewVisible).productVideo;

      return productVideo
        ? !!productVideo[state.selectedLanguage]
        : false;
    },
    isProductPinnedDueDiligenceFilesTranslatedInSelectedLanguage: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (state.selectedLanguage === Languages.en) {
        return true;
      }
      if (!state.product) {
        return false;
      }

      const pinnedDueDiligenceFiles = previewProductVersionWithFallback(state.product, state.isPreviewVisible).pinnedDueDiligenceFiles;

      return pinnedDueDiligenceFiles
        ? !!pinnedDueDiligenceFiles[state.selectedLanguage]
        : false;
    },
    productImageUrl: (state: GeneralPartnerProductManagementGeneralState): string | null => {
      if (!state.product) {
        return null;
      }

      return latestProductVersion(state.product).imageUrl ?? null;
    },
    productKPIs: (state: GeneralPartnerProductManagementGeneralState): KPI[] => {
      if (!state.product) {
        return [];
      }

      return getKPIsFromTermSheet(latestProductVersion(state.product).termSheet);
    },
    productSummaryItems: (state: GeneralPartnerProductManagementGeneralState): KPI[] => {
      if (!state.product) {
        return [];
      }

      return productSummaryItems(state.product, state.isPreviewVisible);
    },
    productPinnedDueDiligenceFiles: (state: GeneralPartnerProductManagementGeneralState): PinnedDueDiligenceFile[] => {
      if (!state.product) {
        return [];
      }

      return latestProductVersion(state.product).pinnedDueDiligenceFiles[state.selectedLanguage!] ?? [];
    },
    productPinnedDueDiligenceFilesWithFallback: (state: GeneralPartnerProductManagementGeneralState): PinnedDueDiligenceFile[] => {
      if (!state.product) {
        return [];
      }

      return productPinnedDueDiligenceFilesWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage);
    },
    productManagers: (state: GeneralPartnerProductManagementGeneralState): Manager[] => {
      if (!state.product) {
        return [];
      }

      return productManagers(state.product, state.isPreviewVisible);
    },
    isProductManagerJobTitleTranslatedInSelectedLanguage:
      (state: GeneralPartnerProductManagementGeneralState): (managerId: string) => boolean =>
        (managerId) => {
          const manager = productManagers(state.product!, state.isPreviewVisible)
            .find((manager) => manager.id === managerId);

          return manager
            ? !!manager.jobTitle[state.selectedLanguage]
            : false;
        },
    isProductManagerDescriptionTranslatedInSelectedLanguage:
      (state: GeneralPartnerProductManagementGeneralState): (managerId: string) => boolean =>
        (managerId) => {
          const manager = productManagers(state.product!, state.isPreviewVisible)
            .find((manager) => manager.id === managerId);

          return manager
            ? !!manager.description[state.selectedLanguage]
            : false;
        },
    productContactEmailAddress: (state: GeneralPartnerProductManagementGeneralState): string | null =>
      state.product
        ? productContactEmailAddress(state.product, state.isPreviewVisible)
        : null,
    hasProductContactEmailAddress: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return !!productContactEmailAddress(state.product, state.isPreviewVisible);
    },
    productPresentation: (state: GeneralPartnerProductManagementGeneralState): string | null =>
      state.product
        ? productPresentationUrlWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage)
        : null,
    hasProductPresentation: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return !!productPresentationUrlWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage);
    },
    productTrackRecordImageUrl: (state: GeneralPartnerProductManagementGeneralState): string | null => {
      if (!state.product) {
        return null;
      }

      const productVersion = previewProductVersionWithFallback(state.product, state.isPreviewVisible);
      return productVersion.trackRecordImageUrl
        ? productVersion.trackRecordImageUrl[state.selectedLanguage]
          ?? productVersion.trackRecordImageUrl.en
        : null;
    },
    productPresentationUrl: (state: GeneralPartnerProductManagementGeneralState): string | null => {
      if (!state.product) {
        return null;
      }

      return productPresentationUrlWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage);
    },
    productVideo: (state: GeneralPartnerProductManagementGeneralState): ProductVideo | null => {
      if (!state.product) {
        return null;
      }

      return productVideoWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage);
    },
    subscriptionInstruction: (state: GeneralPartnerProductManagementGeneralState): string | null =>
      state.product
        && state.product.subscriptionInstruction
        ? state.product.subscriptionInstruction[state.selectedLanguage] ?? null
        : null,
    kycInstruction: (state: GeneralPartnerProductManagementGeneralState): string | null =>
      state.product
        && state.product.kycInstruction
        ? state.product.kycInstruction[state.selectedLanguage] ?? null
        : null,
    hasProductVideo: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      return !!productVideoWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage);
    },
    isProductVideoUploadAndTranscodeCompleted: (state: GeneralPartnerProductManagementGeneralState): boolean =>
      state.product
        ? productVideoWithFallback(state.product, state.isPreviewVisible, state.selectedLanguage)?.uploadAndTranscodeCompleted ?? false
        : false,
    unselectedSummaryItems: (state: GeneralPartnerProductManagementGeneralState): KPI[] =>
      state.product
        ? unselectedSummaryItems(state.product, state.isPreviewVisible)
        : [],
    idsOfInvestorsWithAccessToProduct: (state: GeneralPartnerProductManagementGeneralState): string[] =>
      state.investorsWithAccess
        .filter((investor) => investor.hasAccessToProduct)
        .map((investor) => investor.investorId),
    idsOfGeneralPartnerUsersWithExplicitAccessToProduct: (state: GeneralPartnerProductManagementGeneralState): string[] =>
      state.generalPartnerUsersWithAccess
        .filter((generalPartnerUser) => generalPartnerUser.hasExplicitAccessToProduct)
        .map((generalPartnerUser) => generalPartnerUser.id),
    isKPIInProductSummary: (state: GeneralPartnerProductManagementGeneralState): (kpiId: string) => boolean =>
      (kpiId) => productSummaryItems(state.product!, state.isPreviewVisible)
        .some((productSummaryItem) => productSummaryItem.kpiId === kpiId),
    hasProductNDA: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product) {
        return false;
      }

      const productVersion = previewProductVersionWithFallback(state.product, state.isPreviewVisible);
      return productVersion.productNDA
        ? !!productVersion.productNDA[state.selectedLanguage]
        : false;
    },
    productNDA: (state: GeneralPartnerProductManagementGeneralState): FileReference | null => {
      if (!state.product) {
        return null;
      }

      const productVersion = previewProductVersionWithFallback(state.product, state.isPreviewVisible);
      return productVersion.productNDA
        ? productVersion.productNDA[state.selectedLanguage]
          ?? productVersion.productNDA.en
        : null;
    },
    hasProductDescriptionUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_DESCRIPTION_UPDATED)
        : false;
    },
    hasProductSummaryItemsUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_SUMMARY_ITEM_UPDATED)
        : false;
    },
    hasProductManagersUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_MANAGERS_LIST_UPDATED)
        : false;
    },
    hasProductTrackRecordImageUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_TRACK_RECORD_IMAGE_UPDATED)
        : false;
    },
    hasProductPresentationUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_PRESENTATION_UPDATED)
        : false;
    },
    hasProductVideoUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_VIDEO_UPDATED)
        : false;
    },
    hasProductEmailAddressUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_EMAIL_ADDRESS_UPDATED)
        : false;
    },
    hasProductNDAUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_NDA_UPDATED)
        : false;
    },
    hasProductPinnedDueDiligenceFileUpdated: (state: GeneralPartnerProductManagementGeneralState): boolean => {
      if (!state.product
        || !hasUnpublishedProductUpdate(state.product)
      ) {
        return false;
      }

      return state.product
        ? state.product.unpublishedProductUpdate.updatedProductSectionsList
          .includes(ProductUpdatedSectionsListTypes.PRODUCT_PINNED_DUE_DILIGENCE_FILE_UPDATED)
        : false;
    },
    subscriptionAgreementTemplatesToPrefillForSelectedLanguage:
      (state: GeneralPartnerProductManagementGeneralState): SubscriptionAgreementTemplateToPrefill[] => {
        if (!state.product) {
          return [];
        }

        return state.product
          .subscriptionAgreementTemplatesToPrefill
          .filter((subscriptionAgreementTemplateToPrefill) => subscriptionAgreementTemplateToPrefill.language === state.selectedLanguage);
      },
    controllingPersonTemplatesToPrefillForSelectedLanguage:
      (state: GeneralPartnerProductManagementGeneralState): ControllingPersonTemplateToPrefill[] => {
        if (!state.product) {
          return [];
        }

        return state.product
          .controllingPersonTemplatesToPrefill
          .filter((controllingPersonTemplatesToPrefill) => controllingPersonTemplatesToPrefill.language === state.selectedLanguage);
      },
  },
  actions: {

    // -- State management

    updateLanguage(language: Language): Promise<void> {
      this.selectedLanguage = language;

      return Promise.resolve();
    },

    updateCurrentProductId(currentProductId: string): Promise<void> {
      this.currentProductId = currentProductId;

      return this.getCurrentProduct();
    },

    updateIsPreviewVisible(isPreviewVisible: boolean): Promise<void> {
      if (isPreviewVisible
        && !this.hasUnpublishedProductUpdate
      ) {
        return Promise.resolve();
      }

      if (isPreviewVisible
        && !this.product
      ) {
        return Promise.reject(new Error('Product does not have unpublished product update.'));
      }

      this.isPreviewVisible = isPreviewVisible;

      return Promise.resolve();
    },

    // -- Queries

    getCurrentProduct(): Promise<void> {
      const query: GetProductForProductManagementQuery = {
        productId: this.currentProductId!,
        language: this.selectedLanguage,
      };

      const { getCurrentProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentProductStatus,
        () => getProductForProductManagement(query)
          .then((product) => {
            this.product = product;
          })
      );
    },

    getProductPrintPDFForDownload(): Promise<FileResponse> {
      const query: GetProductPrintPDFForDownloadQuery = {
        productId: this.currentProductId!,
        language: this.selectedLanguage,
      };

      const { getProductPrintPDFForDownloadStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProductPrintPDFForDownloadStatus,
        () => getProductPrintPDFForDownload(query)
      );
    },

    getInvestorsWithAccessForProduct(): Promise<void> {
      const query: GetInvestorsWithAccessForProductQuery = {
        productId: this.currentProductId!,
      };

      const { getInvestorsWithAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvestorsWithAccessForProductStatus,
        () => getInvestorsWithAccessForProduct(query)
          .then((investors) => {
            this.investorsWithAccess = investors;
          })
      );
    },

    getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct(): Promise<void> {
      const query: GetGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductQuery = {
        productId: this.currentProductId!,
      };

      const { getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProductStatus,
        () => getGeneralPartnerUsersWithoutAccessForAllProductsWithAccessForProduct(query)
          .then((generalPartnerUsersWithAccess) => {
            this.generalPartnerUsersWithAccess = generalPartnerUsersWithAccess;
          })
      );
    },

    // -- Commands

    publishProduct(command: PublishProductCommand): Promise<void> {
      const { publishProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        publishProductStatus,
        () => publishProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    unpublishProduct(command: UnpublishProductCommand): Promise<void> {
      const { unpublishProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpublishProductStatus,
        () => unpublishProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    enableProductInvestability(command: EnableProductInvestabilityCommand): Promise<void> {
      const { enableProductInvestabilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableProductInvestabilityStatus,
        () => enableProductInvestability(command)
          .then(() => this.getCurrentProduct())
      );
    },

    disableProductInvestability(command: DisableProductInvestabilityCommand): Promise<void> {
      const { disableProductInvestabilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableProductInvestabilityStatus,
        () => disableProductInvestability(command)
          .then(() => this.getCurrentProduct())
      );
    },

    shareProduct(command: ShareProductCommand): Promise<void> {
      const { shareProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        shareProductStatus,
        () => shareProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    deleteProduct(command: DeleteProductCommand): Promise<void> {
      const { deleteProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteProductStatus,
        () => deleteProduct(command)
      );
    },

    unshareProduct(command: UnshareProductCommand): Promise<void> {
      const { unshareProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unshareProductStatus,
        () => unshareProduct(command)
          .then(() => this.getCurrentProduct())
      );
    },

    publishProductUpdate(): Promise<void> {
      const command: PublishProductUpdateCommand = {
        productId: this.currentProductId!,
      };

      const { publishProductUpdateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        publishProductUpdateStatus,
        () => publishProductUpdate(command)
          .then(() => this.updateIsPreviewVisible(false))
          .then(() => this.getCurrentProduct())
      );
    },

    discardProductUpdate(): Promise<void> {
      const command: DiscardProductUpdateCommand = {
        productId: this.currentProductId!,
      };

      const { discardProductUpdateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        discardProductUpdateStatus,
        () => discardProductUpdate(command)
          .then(() => this.updateIsPreviewVisible(false))
          .then(() => this.getCurrentProduct())
      );
    },

    updateInvestorsWithAccessForProduct(command: UpdateInvestorsWithAccessForProductCommand): Promise<void> {
      const { updateInvestorsWithAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvestorsWithAccessForProductStatus,
        () => updateInvestorsWithAccessForProduct(command)
      );
    },

    updateGeneralPartnerUsersAccessForProduct(command: UpdateGeneralPartnerUsersAccessForProductCommand): Promise<void> {
      const { updateGeneralPartnerUsersAccessForProductStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGeneralPartnerUsersAccessForProductStatus,
        () => updateGeneralPartnerUsersAccessForProduct(command)
      );
    },

    updateProductName(command: UpdateProductNameCommand): Promise<void> {
      const { updateProductNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductNameStatus,
        () => updateProductName(command)
          .then(() => this.getCurrentProduct())
          .then(() => this.updateIsPreviewVisible(true))
      );
    },

    updateProductPreview(command: UpdateProductPreviewCommand): Promise<void> {
      const { updateProductPreviewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateProductPreviewStatus,
        () => updateProductPreview(command)
          .then(() => this.getCurrentProduct())
          .then(() => this.updateIsPreviewVisible(true))
      );
    },

  },
});
