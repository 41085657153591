import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import { router as whitelabelRouter } from '@/router/whitelabel';

export function initMatomo(): void {
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: process.env.MATOMO_URL,
    siteId: process.env.MATOMO_SITE_ID,
    trackerFileName: 'matomo',
    router: whitelabelRouter,
    enableHeartBeatTimer: true,
  });
}
