import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/src/locale/en';
import { ClickOutside } from 'vuetify/es5/directives';
import { ColorScheme } from '@/types/color-scheme';
import { getAppColorSchemeByBaseColors } from '@/helpers/color-helpers';

Vue.use(Vuetify);

Vue.directive('click-outside', ClickOutside);

const defaultColorScheme = getAppColorSchemeByBaseColors(
  '#1E69AE',
  '#F5F5F5',
  '#0D8E73',
  '#0D8E73',
  '#EE3125',
  '#EE3125',
  '#F9D44F',
  '#F9D44F',
  '#8B8B8B',
  '#E1E1E1',
  '#7d3ed6',
  '#34c0eb'
);

/**
 * We don't use options.customProperties, because they aren't updated when the colors change. Instead, we generate the css variables
 * ourselves. This means that we need to update cssVariablesFromColorScheme when we add new color options to the theme.
 */
export const vuetify: Vuetify = new Vuetify({
  lang: {
    locales: { en },
    current: 'en',
  },
  icons: {
    iconfont: 'faSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: defaultColorScheme.primary,
        secondary: defaultColorScheme.secondary,
        green: defaultColorScheme.green,
        success: defaultColorScheme.success,
        red: defaultColorScheme.red,
        error: defaultColorScheme.error,
        yellow: defaultColorScheme.yellow,
        warning: defaultColorScheme.warning,
        grey: defaultColorScheme.grey,
        info: defaultColorScheme.info,
        purple: defaultColorScheme.purple,
        cyan: defaultColorScheme.cyan,
      },
    },
  },
});

export function updateColorScheme(context: Vue, colorScheme: ColorScheme): void {
  context.$vuetify.theme.themes.light.primary = colorScheme.primary;
  context.$vuetify.theme.themes.light.secondary = colorScheme.secondary;
  context.$vuetify.theme.themes.light.green = colorScheme.green;
  context.$vuetify.theme.themes.light.success = colorScheme.success;
  context.$vuetify.theme.themes.light.red = colorScheme.red;
  context.$vuetify.theme.themes.light.error = colorScheme.error;
  context.$vuetify.theme.themes.light.yellow = colorScheme.yellow;
  context.$vuetify.theme.themes.light.warning = colorScheme.warning;
  context.$vuetify.theme.themes.light.grey = colorScheme.grey;
  context.$vuetify.theme.themes.light.info = colorScheme.info;
  context.$vuetify.theme.themes.light.purple = colorScheme.purple;
  context.$vuetify.theme.themes.light.cyan = colorScheme.cyan;
}
