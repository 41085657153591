import { generateApiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser, attachGeneralPartnerAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CreateInvestmentForSubscriptionProcessCommand, OnboardedProductSubscriptionProcess, CreateOnboardedProductSubscriptionDocumentForSubscriptionProcessCommand, DeleteOnboardedProductSubscriptionDocumentCommand, SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand, InvestmentProfile, GetInvestmentProfilesForInvestorQuery, ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand, GetInvestmentProfileQuery, InvestmentProfileDetails, ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand, GetOnboardedProductSubscriptionProcessQuery, SpecifyOnboardedProductSubscriptionInvestmentAmountCommand, CreateKYCDocumentAttachmentForOnboardedProductSubscriptionProcessCommand, DeleteOnboardedProductKYCDocumentAttachmentCommand, GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery, GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery, GetOnboardedProductSubscriptionDocumentFileForDownloadQuery, ConfirmOnboardedProductSubscriptionCommand } from './types';

export function specifyOnboardedProductSubscriptionInvestmentAmount(
  command: SpecifyOnboardedProductSubscriptionInvestmentAmountCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/specify-onboarded-product-subscription-investment-amount-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestmentProfiles(query: GetInvestmentProfilesForInvestorQuery): Promise<InvestmentProfile[]> {
  const url = generateApiUrl('/api/general-partner/get-investment-profiles-for-investor-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function selectInvestmentProfileForOnboardedProductSubscriptionProcess(
  command: SelectInvestmentProfileForOnboardedProductSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/select-investment-profile-for-onboarded-product-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getInvestmentProfile(command: GetInvestmentProfileQuery): Promise<InvestmentProfileDetails> {
  const url = generateApiUrl('/api/general-partner/get-investment-profile-query');
  return performApiRequest<InvestmentProfileDetails>({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function confirmSelectedInvestmentProfileForSubscriptionProcess(
  command: ConfirmSelectedInvestmentProfileForSubscriptionProcessCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/confirm-selected-investment-profile-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createOnboardedProductSubscriptionDocumentForSubscriptionProcess(
  command: CreateOnboardedProductSubscriptionDocumentForSubscriptionProcessCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-onboarded-product-subscription-document-for-subscription-process-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function confirmOnboardedProductSubscription(command: ConfirmOnboardedProductSubscriptionCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/confirm-onboarded-product-subscription-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createInvestmentForSubscriptionProcess(command: CreateInvestmentForSubscriptionProcessCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-investment-for-subscription-process-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function deleteOnboardedProductSubscriptionDocument(
  command: DeleteOnboardedProductSubscriptionDocumentCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-onboarded-product-subscription-document-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function createKYCDocumentAttachmentForOnboardedProductSubscriptionProcess(
  command: CreateKYCDocumentAttachmentForOnboardedProductSubscriptionProcessCommand,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/create-kyc-document-attachment-for-onboarded-product-subscription-process-command');
  return performApiRequestWithFiles({
    url,
    method: 'POST',
    data: attachGeneralPartnerAndUserToCommandWithFiles(command),
    onUploadProgress,
  });
}

export function deleteOnboardedProductKYCDocumentAttachment(command: DeleteOnboardedProductKYCDocumentAttachmentCommand): Promise<void> {
  const url = generateApiUrl('/api/general-partner/delete-onboarded-product-kyc-document-attachment-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function continueOnboardedProductSubscriptionProcessAsNaturalPerson(
  command: ContinueOnboardedProductSubscriptionProcessAsNaturalPersonCommand
): Promise<void> {
  const url = generateApiUrl('/api/general-partner/continue-onboarded-product-subscription-process-as-natural-person-command');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(command) });
}

export function getOnboardedProductSubscriptionProcess(
  query: GetOnboardedProductSubscriptionProcessQuery
): Promise<OnboardedProductSubscriptionProcess> {
  const url = generateApiUrl('/api/general-partner/get-onboarded-product-subscription-process-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionDocumentFileForDownload(
  query: GetOnboardedProductSubscriptionDocumentFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-onboarded-product-subscription-document-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductSubscriptionDocumentTemplateFileForDownload(
  query: GetOnboardedProductSubscriptionDocumentTemplateFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-onboarded-product-subscription-document-template-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}

export function getOnboardedProductKYCDocumentAttachmentFileForDownload(
  query: GetOnboardedProductKYCDocumentAttachmentFileForDownloadQuery
): Promise<FileResponse> {
  const url = generateApiUrl('/api/general-partner/get-onboarded-product-kyc-document-attachment-file-for-download-query');
  return performApiRequestForFile({ url, method: 'POST', data: attachGeneralPartnerAndUser(query) });
}
