import { RouteConfig } from 'vue-router';
import { AccountTypes, Features, GeneralPartnerPermissions } from '@/types/global';
import CreateProduct from './create-product/components/create-product.vue';
import Products from './products/components/products.vue';
import ProductManagement from './product-management/components/product-management.vue';
import ProductAboutTab from './product-management/components/product-about-tab/product-about-tab.vue';
import ProductDetailsTab from './product-management/components/product-details-tab/product-details-tab.vue';
import ProductDocumentsTab from './product-management/components/product-documents-tab/product-documents-tab.vue';
import ProductReportingTab from './product-management/components/product-reporting-tab/product-reporting-tab.vue';
import ProductNewsTab from './product-management/components/product-news-tab/product-news-tab.vue';
import ProductAnalyticsTab from './product-management/components/product-analytics-tab/product-analytics-tab.vue';
import SubscriptionTab from './product-management/components/subscription-tab/subscription-tab.vue';
import OnboardedProduct from './onboarded-product-from-network/components/onboarded-product-from-network.vue';
import AboutOnboardedProductTab from './onboarded-product-from-network/components/about-onboarded-product-tab.vue';
import OnboardedProductDetailsTab from './onboarded-product-from-network/components/onboarded-product-details-tab.vue';
import OnboardedProductDocumentsTab from './onboarded-product-from-network/components/onboarded-product-documents-tab/onboarded-product-documents-tab.vue';
import OnboardedProductNewsTab from './onboarded-product-from-network/components/onboarded-product-news-tab.vue';
import ProductActivityTab from './product-management/components/product-activity-tab/product-activity-tab.vue';

export const generalPartnerProductsRoutes: RouteConfig[] = [
  {
    name: 'general-partner-create-product',
    path: '/general-partner/create-product',
    component: CreateProduct,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.GENERAL_PARTNER,
      requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
    },
  }, {
    name: 'general-partner-products',
    path: '/general-partner/products',
    component: Products,
    meta: {
      requiresAuth: true,
      requiresAccountType: AccountTypes.GENERAL_PARTNER,
      requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
    },
  }, {
    path: '/general-partner/onboarded-product-from-network/:productId',
    component: OnboardedProduct,
    children: [
      {
        path: '',
        redirect: 'about',
      }, {
        name: 'general-partner-onboarded-product-from-network-about',
        path: 'about',
        component: AboutOnboardedProductTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-onboarded-product-from-network-details',
        path: 'details',
        component: OnboardedProductDetailsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-onboarded-product-from-network-documents',
        path: 'documents',
        component: OnboardedProductDocumentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-onboarded-product-from-network-news',
        path: 'news',
        component: OnboardedProductNewsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          requiresFeatures: [Features.USE_PRODUCTS_FROM_NETWORK],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  }, {
    path: '/general-partner/product-management/:productId',
    component: ProductManagement,
    children: [
      {
        path: '',
        redirect: 'about',
      }, {
        name: 'general-partner-product-management-about',
        path: 'about',
        component: ProductAboutTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-details',
        path: 'details',
        component: ProductDetailsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-documents',
        path: 'documents',
        component: ProductDocumentsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-reporting',
        path: 'reporting',
        component: ProductReportingTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-news',
        path: 'news',
        component: ProductNewsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-analytics',
        path: 'analytics',
        component: ProductAnalyticsTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_INVESTOR,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-subscription',
        path: 'subscription',
        component: SubscriptionTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          requiresFeatures: [Features.SUBSCRIPTION_PROCESS],
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      }, {
        name: 'general-partner-product-management-activity',
        path: 'activity',
        component: ProductActivityTab,
        meta: {
          requiresAuth: true,
          requiresAccountType: AccountTypes.GENERAL_PARTNER,
          requiresPermission: GeneralPartnerPermissions.READ_PRODUCT,
          styleClass: 'has-sticky-tab-header',
          needsReferrer: true,
        },
      },
    ],
  },
];
