import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { Decimal, DecimalDto } from '@/models/decimal';

// Regular expression to pre-check if strings contain an ISO-8601 date.
// Based on http://dotat.at/tmp/ISO_8601-2004_E.pdf, implementation from https://stackoverflow.com/a/3143231
// eslint-disable-next-line max-len
const isoDateTimeRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;
// Regular expression to pre-check if strings contain an DIN-5008 date.
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export function fromJSON<T>(json: string): T {
  return JSON.parse(json, dayjsAndDecimalReviver);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function toJSON(data: object): string {
  return JSON.stringify(data, dayjsAndDecimalReplacer);
}

function isDateString(value: unknown): boolean {
  // Check if string contains a DIN-5008 date before parsing to avoid warnings.
  return typeof value === 'string' && dateRegex.test(value);
}

function isDateTimeString(value: unknown): boolean {
  // Check if string contains ISO-8601 date time before parsing to avoid warnings.
  return typeof value === 'string' && isoDateTimeRegex.test(value);
}

export function dayjsAndDecimalReviver(key: string, value: unknown): Dayjs | unknown {
  if (isDateTimeString(value)) {
    return dayjs(value as string);
  } else if (isDateString(value)) {
    return dayjs.utc(value as string);
  } else if (Decimal.isDecimalDto(value)) {
    return new Decimal((value as DecimalDto).value, (value as DecimalDto).decimals);
  } else {
    return value;
  }
}

export function dayjsAndDecimalReplacer(this: any, key: string, value: unknown): string | unknown {
  if (isDayjs(this[key])) {
    return (this[key] as Dayjs).toISOString();
  } else if (Decimal.isDecimal(this[key])) {
    return (this[key] as Decimal).toDto();
  } else {
    return value;
  }
}
