import { performApiRequest, generateApiUrl } from '@/infrastructure/api-requests';
import { attachGeneralPartnerAndUser } from '@/helpers/default-parameter-helper';
import { ProductReference, AdvisorReference, GeneralPartnerPermissionGroupReference, InvestorReference, GeneralPartnerUserReference, InvestorUserReference } from './types';

export function getInvestorReferences(): Promise<InvestorReference[]> {
  const url = generateApiUrl('/api/general-partner/get-investor-references-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getInvestorUserReferences(): Promise<InvestorUserReference[]> {
  const url = generateApiUrl('/api/general-partner/get-investor-user-references-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getProductReferences(): Promise<ProductReference[]> {
  const url = generateApiUrl('/api/general-partner/get-product-references-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getGeneralPartnerPermissionGroupReferences(): Promise<GeneralPartnerPermissionGroupReference[]> {
  const url = generateApiUrl('/api/general-partner/get-general-partner-permission-group-references-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getGeneralPartnerUserWithoutAccessToAllProductsReferences(): Promise<GeneralPartnerUserReference[]> {
  const url = generateApiUrl('/api/general-partner/get-general-partner-user-without-access-to-all-products-references-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}

export function getAdvisorReferences(): Promise<AdvisorReference[]> {
  const url = generateApiUrl('/api/general-partner/get-advisor-references-query');
  return performApiRequest({ url, method: 'POST', data: attachGeneralPartnerAndUser({}) });
}
