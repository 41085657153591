import { render, staticRenderFns } from "./onboarded-product-kyc-document-attachments.vue?vue&type=template&id=fa69fd88&scoped=true&"
import script from "./onboarded-product-kyc-document-attachments.vue?vue&type=script&lang=ts&"
export * from "./onboarded-product-kyc-document-attachments.vue?vue&type=script&lang=ts&"
import style0 from "./onboarded-product-kyc-document-attachments.vue?vue&type=style&index=0&id=fa69fd88&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa69fd88",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCard,VDivider,VProgressCircular})
