var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({attrs:{"data-cy":"onboarded-product-subscription-processes-tab"}},'div',_vm.$attrs,false),[_c('div',{staticClass:"d-flex mt-4 px-2 px-sm-0 mb-2 mb-sm-0"},[_c('v-btn-toggle',{staticClass:"d-flex flex-wrap d-sm-inline-flex flex-sm-nowrap w-100",attrs:{"color":"primary","borderless":""},model:{value:(_vm.processStatusFilterToggle),callback:function ($$v) {_vm.processStatusFilterToggle=$$v},expression:"processStatusFilterToggle"}},[_c('v-btn',{staticClass:"w-100 w-sm-auto mb-1",attrs:{"small":"","value":_vm.subscriptionProcessStatuses.STARTED}},[_vm._v(" "+_vm._s(_vm.translate(("processStatus." + (_vm.subscriptionProcessStatuses.STARTED))))+" ")]),_c('v-btn',{staticClass:"w-100 w-sm-auto mb-1",attrs:{"small":"","value":_vm.subscriptionProcessStatuses.IN_PROGRESS}},[_vm._v(" "+_vm._s(_vm.translate(("processStatus." + (_vm.subscriptionProcessStatuses.IN_PROGRESS))))+" ")]),_c('v-btn',{staticClass:"w-100 w-sm-auto mb-1",attrs:{"small":"","value":_vm.subscriptionProcessStatuses.IN_REVIEW}},[_vm._v(" "+_vm._s(_vm.translate(("processStatus." + (_vm.subscriptionProcessStatuses.IN_REVIEW))))+" ")])],1)],1),_c('v-data-table',{staticClass:"mb-0 clickable-rows transparent",attrs:{"disable-pagination":"","disable-filtering":"","hide-default-footer":"","loading-text":_vm.translate('table.loadingText'),"no-data-text":_vm.translate('table.noDataText'),"headers":_vm.tableHeaders,"items":_vm.filteredSubscriptionProcesses,"item-key":"onboardedProductSubscriptionProcessId","loading":_vm.store.isGetOnboardedProductSubscriptionProcessesProcessing,"mobile-breakpoint":960,"data-cy":"onboarded-product-subscription-processes-table"},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [(_vm.isInitialSubscriptionProcess(item))?_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-start my-3"},[_c('a-img',{staticClass:"onboarded-product-image mt-3 mt-sm-0 mr-sm-4",attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"text-left w-100"},[_c('div',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"text":_vm.translate(("processStatus." + (item.status))),"color":_vm.getPillColorForSubscriptionProcessStatus(item.status)}})],1)],1):_c('div',{staticClass:"d-flex align-start my-3"},[_c('a-img',{staticClass:"onboarded-product-image mr-4",attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"body-2 clickable-row-highlight"},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('a-pill',{staticClass:"mt-1 whitespace-nowrap",attrs:{"text":_vm.translate(("processStatus." + (item.status))),"color":_vm.getPillColorForSubscriptionProcessStatus(item.status)}})],1)],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"body-2 black--text"},[_vm._v(_vm._s(_vm.getType(item.type)))])])]}},{key:"item.investorName",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"grey--text text--darken-4"},[_vm._v(_vm._s(item.investorName))]),_c('small',[_vm._v(_vm._s(item.contact))])])]}},{key:"item.investmentAmount",fn:function(ref){
var item = ref.item;
return [(item.investmentAmount)?_c('div',{staticClass:"grey--text text--darken-4"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.investmentAmount,item.currency))+" ")]):_c('div',{staticClass:"grey--text text--darken-4"},[_vm._v("-")])]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"grey--text text--darken-4"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startedAt))+" ")])]}}])}),(_vm.selectedOnboardedProductSubscriptionProcessId)?_c('onboarded-product-subscription-process-dialog',{attrs:{"subscriptionProcessId":_vm.selectedOnboardedProductSubscriptionProcessId},on:{"subscription-process-dialog-closed":_vm.subscriptionProcessDialogClosed}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }